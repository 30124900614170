import EEntity from '../graphql-lib/enums/entity'
import IEntity from 'graphql-lib/interfaces/entity'
import { ChartColors } from 'utils/chart'

/**
 * Created for calculating the color of each chart series in crop compare.
 *
 * Returns a chart color based on the order the user selects the
 * metric to chart, and based on the order the crop inventory groups
 * are chosen for comparison.
 *
 * For example, the first color is always purple.  So if a user
 * selects CO2 on crop A and then C02 on crop B, then crop A will
 * have Purple and Crop B by will have the corresponding Light Purple.
 *
 * This process continues in a round-robin sort of coming back to
 * the first set of colors once all colors have been used.
 *
 * @param selectedEntities the selected entities, state array from the url
 * @param inventoryId - inventoryId of the selected metric
 * @param chartKey - the metric name
 * @returns HEX color string
 */
const getChartColor = (
  selectedEntities?: Array<IEntity>,
  inventoryId?: string | number,
  chartKey?: string
): string => {
  const defaultColor = ChartColors[0][0]
  if (selectedEntities == null || inventoryId == null || chartKey == null) {
    return defaultColor
  }

  const inventoryPos = new Map<string, number>() // <inventoryId, idx>
  const metricPos = new Map<string, number>() // <chartKey, idx>
  let curInvPos = -1; let curMetricPos = -1

  for (let i = 0; i < selectedEntities.length; i++) {
    const { type, id } = selectedEntities[i]
    if (type === EEntity.Chart) {
      const [invId, key] = String(id).split(':')
      if (!inventoryPos.has(invId)) {
        inventoryPos.set(invId, ++curInvPos)
      }
      if (!metricPos.has(key)) {
        metricPos.set(key, ++curMetricPos)
      }

      if (String(inventoryId) === invId && chartKey === key) {
        const fallback = 0 // For compiler, but shouldn't happen
        const iPos = inventoryPos.get(invId) ?? fallback
        const mPos = metricPos.get(key) ?? fallback
        const seriesColors = ChartColors[mPos % ChartColors.length]
        //
        return seriesColors[iPos % seriesColors.length]
      }
    } else {
      continue
    }
  }

  return defaultColor // Didn't find it
}

export default getChartColor

/**
 * @author Miras Absar <mabsar@iunu.com>
 */

// GraphQL
import { gql } from '@apollo/client'

const QueryTask = gql`
  query($id: ID) {
    task(id: $id) {
      id
      
      name
      priority
      complete

      createdOn
      dueDate

      calendarEvent {
        id,
        nextDueTime
      }

      completion {
        id
      }

      taskAssignee {
        customerUser {
          id

          name
          username
          email
        }
      }

      flag {
        id

        icon
        color

        title
        description

        flagCategory {
          id
          name
        }
      }

      locationProfile {
        id

        spaceId
        inventoryId
        positionX
        positionY
      }
    }
  }
`

const SendEmailReport = gql`
  query EmailReport(
    $vendorId: ID
    $facilityId: ID
    $code: String
    $to: [Email]
    $cc: [Email]
    $bcc: [Email]
    $configuration: JSON
  ) {
    emailReport(
      vendorId: $vendorId
      facilityId: $facilityId
      code: $code
      to: $to
      cc: $cc
      bcc: $bcc
      configuration: $configuration
    )
  }
`

const QueryConfiguration = gql`
  query facilityConfiguration($id: ID!) {
    facility(id: $id) {
      configuration {
        id
        name
        customerId
        configuration {
          distributions {
            channelName
            to
            cc
            bcc
          }
        }
      }
    }
  }
`

export { QueryTask, SendEmailReport, QueryConfiguration }

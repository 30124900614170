// Types
import { EStorage } from 'utils/storage'
import EInputStatus from 'ui-lib/enums/input-status'

// Config
import config from 'config'

// Utils
import Has from 'utils/has'
import Columns from './columns'
import Formatters from './formatters'
import GoTo from 'react-lib/go-to'

// Libs
import { v4 } from 'uuid'

// React
import React, {
  useCallback,
  useContext,
  useEffect,
  useState,
  MouseEvent
} from 'react'

// React Libs
import { toast } from 'react-toastify'
import { useDropzone } from 'react-dropzone'
import {
  useIter,
  useAsyncIter
} from 'react-lib/use-iter'
import useStorage from 'react-lib/use-storage'
import ReactDataSheet from 'react-datasheet'

// GraphQL
import client from 'graphql-lib/index'
import { CreateCrop } from './queries'

// Font Awesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faUpload,
  faSave,
  faSpinnerThird
} from '@fortawesome/pro-solid-svg-icons'

// Contexts
import NavigationContext from 'context/navigation'

// Actions & Navigation
import {
  MapRoute,
  FacilityRoute,
  CropsRoute,
  TasksRoute,
  Report
} from 'utils/routes'
import { SwitchCustomer } from 'utils/actions'

// Effects
import {
  InitData,
  ValidateData
} from './effects'

// Routes
import { ImportCrops as ImportCropsRoute } from '../../utils/Routes'

import IInputStatus from 'ui-lib/interfaces/IInputStatus'
import { FetchResult } from '@apollo/client'
import ICrop from 'graphql-lib/interfaces/ICrop'
const papaparse = require('papaparse');
const { parse } = papaparse;

interface CropCell {
  readOnly: boolean;
  value: string;
  status?: IInputStatus;
  className?: string;
}
const Routes = [ImportCropsRoute]
const ImportCrops = (): JSX.Element => {
  // Context ///////////////////////////////////////////////////////////////////

  const {
    setPrimaryAction,
    setSecondaryActions,

    setRoutes
  } = useContext(NavigationContext)

  // State /////////////////////////////////////////////////////////////////////

  const [facilities,, facilitiesUUID] = useStorage('facilities', EStorage.EphemeralStorage)
  const supportsMapView = Has(facilities[0].geojson) &&
    Has(facilities[0].dcs) &&
    facilities[0].dcs.environments?.includes(config.code)

  const [uploading, setUploading] = useState<boolean>()
  const [saving, setSaving] = useState<boolean>()

  const [data, setData] = useState<Array<CropCell[]>>()
  const [status, setStatus] = useState<EInputStatus>()

  const [dataUUID, setDataUUID] = useState<string>()
  const [statusUUID, setStatusUUID] = useState<string>()

  // Effects ///////////////////////////////////////////////////////////////////
  useEffect(
    (): void => {
      const newRoutes = [
        FacilityRoute,
        CropsRoute,
        TasksRoute
      ]

      if (supportsMapView) {
        newRoutes.unshift(MapRoute)
      }
      newRoutes.unshift(Report)

      setPrimaryAction(SwitchCustomer)
      setSecondaryActions([])

      setRoutes(newRoutes)
    },
    []
  )

  useIter(
    InitData,

    { setData,
      setDataUUID },
    []
  )

  useAsyncIter(
    ValidateData,

    { data,

      setData,
      setStatus,
      setStatusUUID },

    [dataUUID]
  )

  // Callbacks /////////////////////////////////////////////////////////////////

  const $drop = useCallback(
    (files): void => {
      const file = files[0]

      if (!file) {
        return
      }

      if (!file.path.endsWith('.csv')) {
        toast.error('File must be a CSV.')
      }

      const fileReader = new FileReader();
      fileReader.onload = (ev: ProgressEvent<FileReader>): void => {
        setUploading(true)

        const {
          data: csvData,
          errors: csvErrors
        } = parse(
          ev.target.result,
          { header: true }
        )

        if (csvErrors.length > 0) {
          toast.warn('CSV is malformed.')
        }

        if (csvData.length === 0) {
          return
        }

        const newData = [
          [
            { readOnly: true, value: '' },
            { readOnly: true, value: 'Product Code' },
            { readOnly: true, value: 'Area' },
            { readOnly: true, value: 'Zone' },
            { readOnly: true, value: 'Sub Zone' },
            { readOnly: true, value: 'Name' },
            { readOnly: true, value: 'Code' },
            { readOnly: true, value: 'Container Type' },
            { readOnly: true, value: 'Containers per Group' },
            { readOnly: true, value: 'Start Date' },
            { readOnly: true, value: 'Transplant Date' },
            { readOnly: true, value: 'End Date' },
            { readOnly: true, value: 'Description' }
          ]
        ]

        csvData.forEach((row: Record<string, string>, ri: number): void => {
          newData.push([
            { readOnly: true, value: (ri + 1).toString() },
            { readOnly: false, value: row['Product Code'] },
            { readOnly: false, value: row.Area },
            { readOnly: false, value: row.Zone },
            { readOnly: false, value: row['Sub Zone'] },
            { readOnly: false, value: row.Name },
            { readOnly: false, value: row.Code },
            { readOnly: false, value: row['Container Type'] },
            { readOnly: false, value: row['Containers per Group'] },
            { readOnly: false, value: row['Start Date'] },
            { readOnly: false, value: row['Transplant Date'] },
            { readOnly: false, value: row['End Date'] },
            { readOnly: false, value: row.Description }
          ])
        })

        for (let row = newData.length; row < 101; row++) {
          newData.push([
            { readOnly: true, value: row.toString() },
            { readOnly: false, value: '' },
            { readOnly: false, value: '' },
            { readOnly: false, value: '' },
            { readOnly: false, value: '' },
            { readOnly: false, value: '' },
            { readOnly: false, value: '' },
            { readOnly: false, value: '' },
            { readOnly: false, value: '' },
            { readOnly: false, value: '' },
            { readOnly: false, value: '' },
            { readOnly: false, value: '' },
            { readOnly: false, value: '' }
          ])
        }

        setUploading(false)
        setData(newData)
        setDataUUID(v4())
      }

      fileReader.readAsText(file)
    },
    [dataUUID]
  )

  const datasheetContainer$click = useCallback(
    (ev: MouseEvent<HTMLDivElement>): void => {
      ev.preventDefault()
      ev.stopPropagation()
    },

    [ dataUUID,
      statusUUID ]
  )

  const datasheet$valueRender = useCallback(
    (cell: any): string => {
      return cell.value
    },

    [ dataUUID,
      statusUUID ]
  )

  const datasheet$cellsChange = useCallback(
    (changes: Array<{ row: number, col: number, value: string }>): void => {
      if (!data) {
        return
      }

      const newData = [...data]
      changes.forEach((change): void => {
        const { row, col, value } = change
        newData[row][col].value = value
      })

      setData(newData)
      setDataUUID(v4())
    },

    [ dataUUID,
      statusUUID ]
  )

  const $upload = useCallback(
    (ev: MouseEvent<HTMLButtonElement>): void => {
      // ev.preventDefault()
      // ev.stopPropagation()
    },

    [ dataUUID,
      statusUUID ]
  )

  const $save = useCallback(
    async (ev: MouseEvent<HTMLButtonElement>): Promise<void> => {
      ev.preventDefault()
      ev.stopPropagation()

      if (
        !data ||
        status === EInputStatus.Uninitialized
      ) {
        toast.warn('No data to save.')
        return
      }

      if (status === EInputStatus.Error) {
        toast.error('Fix errors before saving.')
        return
      }

      setSaving(true)
      const cropCreators = data
        .map((row, rowIndex): any => {
          const isXAxis = rowIndex === 0
          if (isXAxis) {
            return null
          }

          const isEmptyRow = row
            .reduce(
              (isEmptyRow: boolean, cell: { value: string }, cellIndex: number): boolean => {
                const isYAxis = cellIndex === 0
                if (isYAxis) {
                  return true
                }

                return isEmptyRow && /^\s*$/.test(cell.value)
              },
              true
            )

          if (isEmptyRow) {
            return null
          }

          const crop: Record<string, string> = row.reduce(
            (crop, cell: { value: string }, cellIndex: number): any => {
              const isYAxis = cellIndex === 0
              if (isYAxis) {
                return null
              }

              const column = data[0][cellIndex].value as keyof typeof Columns;
              const _column = Columns[column]
              if (!_column) {
                throw new Error(`No column for column: ${column}`)
              }

              const formatter = Formatters[column]
              if (!formatter) {
                throw new Error(`No formatter for column: ${formatter}`)
              }

              return {
                ...crop,
                [_column]: formatter(cell.value)
              }
            },
            {}
          );

          crop.facilityId = String(facilities?.[0]?.id)

          const cropCreator = async (): Promise<{ data: ICrop, error: Error }> => {
            let errorVal
            let data
            try {
              const response: FetchResult<ICrop> = await client.mutate({
                mutation: CreateCrop,
                variables: crop
              })
              data = response.data
            } catch (_error) {
              errorVal = _error as Error;
            }

            return {
              error: errorVal,
              data
            }
          }

          return cropCreator()
        })
        .filter((cropCreator): boolean => !!cropCreator)

      if (cropCreators.length === 0) {
        toast.warn('No data to save.')
        setSaving(false)
        return
      }

      const responses = await Promise.all(cropCreators)
      const hasData = !!responses
        .find((response): boolean => !!response.data)

      const hasErrors = !!responses
        .find((response): boolean => !!response.error)

      if (
        hasData &&
        !hasErrors
      ) {
        toast.info('Saved data.')
        GoTo(CropsRoute.route)
      }

      if (
        hasData &&
        hasErrors
      ) {
        toast.warn('Saved data with errors.')
      }

      if (
        !hasData &&
        hasErrors
      ) {
        toast.error('Could not save data.')
      }

      setSaving(false)
    },

    [ facilitiesUUID,
      dataUUID,
      statusUUID ]
  )

  // Render ////////////////////////////////////////////////////////////////////
  const {
    getRootProps,
    getInputProps
  } = useDropzone({ onDrop: $drop })

  const uploadIcon = uploading
    ? faSpinnerThird
    : faUpload

  const saveIcon = saving
    ? faSpinnerThird
    : faSave

  return (
    <main
      className='Theme__Light ImportCrops'
      {...getRootProps()}
    >
      <input {...getInputProps()} />

      { data && (
        <div
          className='DatasheetContainer'
          onClick={datasheetContainer$click}
        >
          <ReactDataSheet
            data={data}
            valueRenderer={datasheet$valueRender}
            onCellsChanged={datasheet$cellsChange}
          />
        </div>
      )}

      <div className='Fabs'>
        <button
          id="upload"
          className='Fab Animated'
          onClick={$upload}
        >
          <FontAwesomeIcon icon={uploadIcon} spin={uploading} />
        </button>

        <button
          id="save"
          className='Fab Animated'
          onClick={$save}
        >
          <FontAwesomeIcon icon={saveIcon} spin={saving} />
        </button>
      </div>
    </main>
  )
}

export { Routes as ImportCropsRoutes }
export default ImportCrops

// React
import React, { CSSProperties } from 'react';

// UI Lib
import IGroup from 'arrayview/interfaces/IGroup';
import ICrop from 'graphql-lib/interfaces/ICrop';
export interface IListGroupProps<M> {
  style: CSSProperties;
  group: IGroup;
};

const ListGroup = ({
  style,
  group
}: IListGroupProps<ICrop>): JSX.Element => (
  <div
    style={style}
    className='ListGroup'
  >
    {group.name}
  </div>
);

export default ListGroup;

// React
import React, {
  useCallback,
  useContext
} from 'react'

// Font Awesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faChartArea,
  faSpinnerThird,
  faTimes
} from '@fortawesome/pro-regular-svg-icons'

// Map View
import Mobius from 'map-view/contexts/mobius'

interface ISensorChartsButtonProps {
  outlined?: boolean;
  resting?: boolean
  raised?: boolean;
  animated?: boolean;
}

const SensorChartsButton = ({
  outlined,
  resting,
  raised,
  animated
}: ISensorChartsButtonProps): JSX.Element => {
  // Context ///////////////////////////////////////////////////////////////////

  const {
    searchParams: {
      searchParams,
      setSearchParams,
      setSearchParam,
      searchParamsUUID
    },

    spaceCharts: { spaceChartsLoading },
    inventoryCharts: { inventoryChartsLoading },
    containerCharts: { containerChartsLoading }
  } = useContext(Mobius)

  // Callbacks /////////////////////////////////////////////////////////////////

  const $click = useCallback(
    (): void => {
      const newVal = searchParams.sensorChartsCarouselOpen
        ? undefined
        : true

      setSearchParam('sensorChartsCarouselOpen', newVal)
    },
    [setSearchParams, searchParamsUUID]
  )

  // Render ////////////////////////////////////////////////////////////////////

  let className = 'SensorCharts'
  if (outlined) className += ' Outlined'
  if (resting) className += ' Resting'
  if (raised) className += ' Raised'
  if (animated) className += ' Animated'

  const isLoading =
    spaceChartsLoading &&
    inventoryChartsLoading &&
    containerChartsLoading

  let icon = searchParams.sensorChartsCarouselOpen
    ? faTimes
    : faChartArea

  icon = isLoading
    ? faSpinnerThird
    : icon

  return (
    <button
      className={className}
      onClick={$click}
    >
      <FontAwesomeIcon
        icon={icon}
        spin={isLoading}
      />
    </button>
  )
}

export default SensorChartsButton

// React
import React, {
  forwardRef, MouseEventHandler, Ref
} from 'react'

const TimeTravelInput = (
  {
    onClick,
    value
  }: {
    onClick: MouseEventHandler<HTMLDivElement>
    value?: string
  },
  ref: Ref<any>
): JSX.Element => {
  return (
    <div
      ref={ref}
      className='TimeTravelInput'
      onClick={onClick}
    >
      {value}
    </div>
  )
}

export default forwardRef(TimeTravelInput)

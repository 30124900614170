/**
 * @author Miras Absar <mabsar@iunu.com>
 */

// Types
import IEntity from 'graphql-lib/interfaces/entity'
import ISpace from 'graphql-lib/interfaces/ISpace'
import ICrop from 'graphql-lib/interfaces/ICrop'
import IInventory from 'graphql-lib/interfaces/IInventory'
import IInventoryLocation from 'graphql-lib/interfaces/IInventoryLocation'
import IInventoryStitch from 'graphql-lib/interfaces/IInventoryStitch'
import IEnvironmentalZone from 'graphql-lib/interfaces/IEnvironmentalZone'
import {
  ISensorRecord,
  ICO2SensorRecord
} from 'graphql-lib/interfaces/ISensorDevice'

// Utils
import { EStorage } from 'utils/storage'

// React
import React, {
  useEffect,
  useRef,
  useState
} from 'react'

// React Libs
import { useIter } from 'react-lib/use-iter'
import useStorage from 'react-lib/use-storage'

// GraphQL
import { useQuery } from '@apollo/client'
import {
  QuerySpaces,
  QueryCrop,
  QueryInventories,
  QueryInventoryLocations,
  QueryInventoryStitches,
  QueryEnvironmentalZones,
  QuerySensorRecords,
  QueryCO2SensorRecords
} from './queries'

// Effects
import {
  UpdateSpaces,
  UpdateCrop,
  UpdateInventories,
  UpdateInventoryLocations,
  UpdateInventoryStitches,
  UpdateEnvironmentalZones,
  UpdateSensorRecords,
  UpdateInventoryChartsCache,
  CallOnLoaded
} from './effects'

// UI Lib
import InventoryItem from './inventory-item'

import { createDate } from 'utils/dates'

import ID from 'graphql-lib/interfaces/ID';
export interface IInventoryListProps {
  show?: boolean;
  animated?: boolean;


  id: ID;
  readOnly?: boolean;
  selectedEntities?: IEntity[];
  inventoryLocationsLoading: boolean;
  setAllowCompareButton: (val: boolean) => void;
  setInventoryChartsLoading: (val: boolean) => void;
  setInventoryLocationsLoading: (val: boolean) =>void;
  onLoaded?: (
    crop: ICrop,
    inventories: IInventory[],
    inventoryStitchesCache: Record<ID, IInventoryStitch[]>,
    inventoryChartsCache: Record<ID, any>
  ) => void;

  onSelect?: (cropID: ID, inventoryID: ID) => void;
  onSelectChart?: (cropID: ID, inventoryID: ID, chartKey: string) => void;
  onEdit?: (cropID: ID, inventoryID: ID) => void;
}

const InventoryList = ({
  show,
  animated,

  id,
  readOnly,
  selectedEntities,
  inventoryLocationsLoading,
  setAllowCompareButton,
  setInventoryChartsLoading,
  setInventoryLocationsLoading,
  onLoaded,
  onSelect,
  onSelectChart,
  onEdit
}: IInventoryListProps): JSX.Element | null => {
  // State /////////////////////////////////////////////////////////////////////
  const [[facility]] = useStorage('facilities', EStorage.EphemeralStorage)
  const [inventorySpaceIdMap, setInventorySpaceIdMap] = useState<{[key: string]: number[]}>()
  // Loading State
  const [spacesLoading, setSpacesLoading] = useState<boolean>()
  const [environmentalZonesLoading, setEnvironmentalZonesLoading] = useState<boolean>()
  const [sensorRecordsLoading, setSensorRecordsLoading] = useState<boolean>()
  const [co2SensorRecordsLoading, setCO2SensorRecordsLoading] = useState<boolean>()

  const [cropLoading, setCropLoading] = useState<boolean>()
  const [inventoriesLoading, setInventoriesLoading] = useState<boolean>()
  const [inventoryStitchesLoading, setInventoryStitchesLoading] = useState<boolean>()
 
  // Data State
  const [spaces, setSpaces] = useState<ISpace[]>()
  const [environmentalZones, setEnvironmentalZones] = useState<IEnvironmentalZone[]>()
  const [sensorRecords, setSensorRecords] = useState<ISensorRecord[]>()
  const [co2SensorRecords, setCO2SensorRecords] = useState<ICO2SensorRecord[]>()

  const [crop, setCrop] = useState<ICrop>()
  const [inventories, setInventories] = useState<IInventory[]>()
  const [inventoryLocations, setInventoryLocations] = useState<IInventoryLocation[]>()
  const [inventoryStitches, setInventoryStitches] = useState<IInventoryStitch[]>()

  // ID State
  const [spaceIDs, setSpaceIDs] = useState<ID[]>()
  const [inventoryIDs, setInventoryIDs] = useState<ID[]>()
  const [environmentalZoneIDs, setEnvironmentalZoneIDs] = useState<ID[]>()

  // Cache State
  const environmentalZonesCache = useRef<Record<ID, IEnvironmentalZone[]>>()
  const sensorRecordsCache = useRef<Record<ID, ISensorRecord>>()
  const co2SensorRecordsCache = useRef<Record<ID, ICO2SensorRecord>>()

  const inventoryLocationsCache = useRef<Record<ID, IInventoryLocation[]>>()
  const inventoryStitchesCache = useRef<Record<ID, IInventoryStitch[]>>()
  const inventoryChartsCache = useRef<Record<ID, any>>()
  const calledOnLoaded = useRef<boolean>()

  // UUID State
  const [spacesUUID, setSpacesUUID] = useState<string>()
  const [environmentalZonesUUID, setEnvironmentalZonesUUID] = useState<string>()
  const [sensorRecordsUUID, setSensorRecordsUUID] = useState<string>()
  const [co2SensorRecordsUUID, setCO2SensorRecordsUUID] = useState<string>()

  const [cropUUID, setCropUUID] = useState<string>()
  const [inventoriesUUID, setInventoriesUUID] = useState<string>()
  const [inventoryLocationsUUID, setInventoryLocationsUUID] = useState<string>()
  const [inventoryStitchesUUID, setInventoryStitchesUUID] = useState<string>()
  const [inventoryChartsUUID, setInventoryChartsUUID] = useState<string>()
  // Queries ///////////////////////////////////////////////////////////////////

  const hasSpaceIDs = spaceIDs && spaceIDs.length > 0
  const hasEnvironmentalIDs = environmentalZoneIDs && environmentalZoneIDs.length > 0
  const hasInventoryIDs = inventoryIDs && inventoryIDs.length > 0

  const {
    loading: _spacesLoading,
    error: spacesError,
    data: spacesData
  } = useQuery(QuerySpaces, {
    skip: !hasSpaceIDs,
    variables: { ids: spaceIDs },
    fetchPolicy: 'no-cache'
  })

  const {
    loading: _environmentalZonesLoading,
    error: environmentalZonesError,
    data: environmentalZonesData
  } = useQuery(QueryEnvironmentalZones, {
    skip: !crop || !hasSpaceIDs,
    variables: { spaceIds: spaceIDs },
    fetchPolicy: 'no-cache'
  })

  const {
    loading: _sensorRecordsLoading,
    error: sensorRecordsError,
    data: sensorRecordsData
  } = useQuery(QuerySensorRecords, {
    skip: !crop || !hasEnvironmentalIDs,
    variables: {
      environmentalZoneIds: environmentalZoneIDs,
      startDate: crop?.startDate && createDate(crop.startDate),
      endDate: crop?.endDate && createDate(crop.endDate)
    },
    fetchPolicy: 'no-cache'
  })

  const {
    loading: _co2SensorRecordsLoading,
    error: co2SensorRecordsError,
    data: co2SensorRecordsData
  } = useQuery(QueryCO2SensorRecords, {
    skip: !crop || !hasEnvironmentalIDs,
    variables: {
      environmentalZoneIds: environmentalZoneIDs,
      startDate: crop?.startDate && createDate(crop.startDate),
      endDate: crop?.endDate && createDate(crop.endDate)
    },
    fetchPolicy: 'no-cache'
  })

  const {
    loading: _cropLoading,
    error: cropError,
    data: cropData
  } = useQuery(QueryCrop, {
    skip: !id || !facility,
    variables: { id, facilityIds: [facility.id] },
    fetchPolicy: 'no-cache'
  })

  const {
    loading: _inventoriesLoading,
    error: inventoriesError,
    data: inventoriesData,
    refetch: refetchInventories
  } = useQuery(QueryInventories, {
    skip: !id,
    variables: { cropIds: [id] },
    fetchPolicy: 'no-cache'
  })

  const {
    loading: _inventoryLocationsLoading,
    error: inventoryLocationsError,
    data: inventoryLocationsData
  } = useQuery(QueryInventoryLocations, {
    skip: !crop || !hasInventoryIDs || !facility,
    variables: { inventoryIds: inventoryIDs, facilityIds: [facility.id] },
    fetchPolicy: 'no-cache'
  })

  const {
    loading: _inventoryStitchesLoading,
    error: inventoryStitchesError,
    data: inventoryStitchesData
  } = useQuery(QueryInventoryStitches, {
    skip: !crop || !hasInventoryIDs,
    variables: {
      inventoryIds: inventoryIDs,
      startDate: crop?.startDate && createDate(crop.startDate),
      endDate: crop?.endDate && createDate(crop.endDate)
    },
    fetchPolicy: 'no-cache'
  })

  // Effects ///////////////////////////////////////////////////////////////////
  useEffect(() => {
    if (inventorySpaceIdMap && inventoryIDs) {
      const inventoriesIdsWithSpaceIds = Object.values(inventorySpaceIdMap).flat()
      const matchingInventories = inventoryIDs.filter(invId => inventoriesIdsWithSpaceIds.includes(Number(invId)))
      setAllowCompareButton(matchingInventories.length > 0)
    }
  }, [inventorySpaceIdMap, inventoryIDs]);

  useEffect(() => {
    setInventoryLocationsLoading(_inventoryLocationsLoading)
  }, [_inventoryLocationsLoading, crop, hasInventoryIDs])

  useIter(
    UpdateSpaces,

    { spacesLoading: _spacesLoading,
      spacesData,

      setSpacesLoading,
      setSpaces,
      setSpacesUUID },

    [ _spacesLoading,
      spacesData ]
  )

  useIter(
    UpdateEnvironmentalZones,

    { environmentalZonesLoading: _environmentalZonesLoading,
      environmentalZonesData,

      setEnvironmentalZonesLoading,
      setEnvironmentalZones,
      setEnvironmentalZoneIDs,
      environmentalZonesCache,
      setEnvironmentalZonesUUID },

    [ _environmentalZonesLoading,
      environmentalZonesData ]
  )

  useIter(
    UpdateSensorRecords,

    { co2: false,
      sensorRecordsLoading: _sensorRecordsLoading,
      sensorRecordsData,

      setSensorRecordsLoading,
      setSensorRecords,
      sensorRecordsCache,
      setSensorRecordsUUID },

    [ _sensorRecordsLoading,
      sensorRecordsData ]
  )

  useIter(
    UpdateSensorRecords,

    { co2: true,
      sensorRecordsLoading: _co2SensorRecordsLoading,
      sensorRecordsData: co2SensorRecordsData,

      setSensorRecordsLoading: setCO2SensorRecordsLoading,
      setSensorRecords: setCO2SensorRecords,
      sensorRecordsCache: co2SensorRecordsCache,
      setSensorRecordsUUID: setCO2SensorRecordsUUID },

    [ _co2SensorRecordsLoading,
      co2SensorRecordsData ]
  )

  useIter(
    UpdateCrop,

    { cropLoading: _cropLoading,
      cropData,

      setCropLoading,
      setCrop,
      setCropUUID },

    [ _cropLoading,
      cropData ]
  )

  useIter(
    UpdateInventories,

    { inventoriesLoading: _inventoriesLoading,
      inventoriesData,

      setInventoriesLoading,
      setInventories,
      setInventoryIDs,
      setInventoriesUUID },

    [ _inventoriesLoading,
      inventoriesData ]
  )

  useIter(
    UpdateInventoryLocations,

    { inventoryLocationsLoading: _inventoryLocationsLoading,
      inventoryLocationsData,
      inventoryIDs,
      inventories,
      setInventoryLocationsLoading,
      setInventoryLocations,
      setSpaceIDs,
      inventoryLocationsCache,
      setInventoryLocationsUUID,
      setInventorySpaceIdMap,
    },

    [ _inventoryLocationsLoading,
      inventoryLocationsData,
      inventoriesUUID ]
  )

  useIter(
    UpdateInventoryStitches,

    { inventoryStitchesLoading: _inventoryStitchesLoading,
      inventoryStitchesData,

      spacesLoading: _spacesLoading,
      spacesData,

      setInventoryStitchesLoading,
      setInventoryStitches,
      inventoryStitchesCache,
      setInventoryStitchesUUID },

    [ _inventoryStitchesLoading,
      inventoryStitchesData,
      _spacesLoading,
      spacesData ]
  )

  useIter(
    UpdateInventoryChartsCache,

    { environmentalZonesCache: environmentalZonesCache.current,
      sensorRecordsCache: sensorRecordsCache.current,
      co2SensorRecordsCache: co2SensorRecordsCache.current,
      crop,
      inventories,
      inventoryLocationsCache: inventoryLocationsCache.current,

      inventoryChartsCache,
      setInventoryChartsUUID,
      setInventoryChartsLoading
    },

    [ spacesUUID,
      environmentalZonesUUID,
      sensorRecordsUUID,
      co2SensorRecordsUUID,
      inventoriesUUID,
      inventoryLocationsUUID ]
  )

  useIter(
    CallOnLoaded,

    { crop,
      inventories,
      inventoryStitchesCache: inventoryStitchesCache.current,
      inventoryChartsCache: inventoryChartsCache.current,
      calledOnLoaded,

      onLoaded },

    [ cropUUID,
      inventoriesUUID,
      inventoryStitchesUUID,
      inventoryChartsUUID,

      onLoaded ]
  )

  // Render ////////////////////////////////////////////////////////////////////

  if (!show) {
    return null
  }

  if (!inventories) {
    return null
  }

  return (
    <section className='InventoryList'>
      {inventories.map((i): JSX.Element => (
        <InventoryItem
          key={i.id}

          animated={animated}

          readOnly={readOnly}
          spacesLoading={_spacesLoading}
          inventoryLocationsLoading={_inventoryLocationsLoading}
          inventoryStitchesLoading={inventoryStitchesLoading}
          spaces={spaces}
          inventory={i}
          refetchInventories={refetchInventories}
          inventoryLocations={inventoryLocationsCache.current}
          inventoryStitches={inventoryStitchesCache.current}
          inventoryCharts={inventoryChartsCache.current}
          selectedEntities={selectedEntities}

          onSelect={onSelect}
          onSelectChart={onSelectChart}
          onEdit={onEdit}
        />
      ))}
    </section>
  )
}

export default InventoryList

/**
 * @author Miras Absar <mabsar@iunu.com>
 * @file ...
 */

// React
import React from 'react'

// UI Lib
import Tab, {
  ITab
} from '../tab'

interface ITabsProps {
  animated?: boolean;

  tabs: ITab[];
  selectedTab?: ITab;
  onSelect?: (tab: ITab) => void;
}

const Tabs = ({
  animated,

  tabs,
  selectedTab,
  onSelect
}: ITabsProps): JSX.Element => {
  // Render ////////////////////////////////////////////////////////////////////
  let className = 'Tabs'
  if (animated) className += ' Animated'

  return (
    <div className={className}>
      {tabs.map((tab): JSX.Element => (
        <Tab
          key={tab.id}
          animated={animated}
          selected={selectedTab?.id == tab.id}

          tab={tab}
          onSelect={onSelect}
        />
      ))}
    </div>
  )
}

export default Tabs

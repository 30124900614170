import React, {
  KeyboardEvent
} from 'react'

import IProduct from '../../../interfaces/IProduct'
import IItemFacadeProps from 'ui-lib/interfaces/IItemFacadeProps'

function ProductItem(props: IItemFacadeProps<IProduct>): JSX.Element {
  const { animated, value, onSelect } = props;
  const className = animated
    ? 'ProductItem Animated'
    : 'ProductItem'
  const title = value.nickname ? `${value.code} - ${value.nickname}` : `${value.code}`

  const $keyPress = (ev: KeyboardEvent): void => {
    if (ev.key === 'Enter') {
      onSelect(value)
    }
  }

  const $click = (): void => onSelect(value)

  return (
    <div
      className={className}
      onKeyPress={$keyPress}
      onClick={$click}
      tabIndex={0}
    >
      <p className='Title'>{title}</p>
      <p className='Subtitle'>{value.species} {value.variety}</p>
    </div>
  )
}

export default ProductItem;
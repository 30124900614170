// Types
import ISpace from 'graphql-lib/interfaces/ISpace'
import { ICO2SensorRecord, ISensorRecord, ISensorRecordByType } from 'graphql-lib/interfaces/ISensorDevice'
import IGreenAutomationWaterSystemRecord from 'graphql-lib/interfaces/IGreenAutomationWaterSystemRecord'

// React
import React, {
  useContext,
  useEffect,
  useState,
  CSSProperties
} from 'react'

// FontAwesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faWarehouseAlt } from '@fortawesome/pro-solid-svg-icons'

// Context
import MetronContext from 'grid-view/contexts/metron'

import { EStorage } from 'utils/storage'
import useStorage from 'react-lib/use-storage'
import { ChartAccuracy } from 'utils/chart'

const ConvertTemperature = (temperatureMetric: string, temperature: number): number => {
  if (temperatureMetric === 'celcius') {
    return temperature
  } else {
    return (temperature * 9 / 5) + 32
  }
}

export interface ISpaceProps {
  style?: CSSProperties;

  outlined?: boolean;
  resting?: boolean;
  raised?: boolean;
  animated?: boolean;

  data: ISpace;
  selectedId?: number | string;
  onClick: (data: any) => void;
}

interface ISensorValue {
  name: string;
  order?: number;
  value: number;
  units: string;
}

const Space = ({
  style,

  outlined,
  resting,
  raised,
  animated,

  data,
  selectedId,
  onClick
}: ISpaceProps): JSX.Element => {
  // Contexts //////////////////////////////////////////////////////////////////

  const [customer] = useStorage('customer', EStorage.EphemeralStorage)
  const temperatureMetric = customer.temperatureMetric

  const {
    environmentalZones,
    sensors,
    greenAutomationWaterSystemRecords,

    environmentalZonesLoading,
    sensorsLoading,
    greenAutomationWaterSystemRecordsLoading,

    environmentalZonesUUID,
    sensorsUUID,
    greenAutomationWaterSystemRecordsUUID
  } = useContext(MetronContext)

  // State /////////////////////////////////////////////////////////////////////

  const [sensorValues, setSensorValues] = useState<ISensorValue[]>()
  const [greenAutomationWaterSystemValues, setGreenAutomationWaterSystemValues] = useState<ISensorValue[]>()

  // Effects ///////////////////////////////////////////////////////////////////

  useEffect((): void => {
    if (environmentalZones && sensors &&
        !environmentalZonesLoading && !sensorsLoading) {
      const myEnvironmentalZones = environmentalZones.filter((e: any): boolean =>
        Number(e.spaceId) === Number(data.id))

      const mySensors = sensors.filter((s): boolean =>
        !!myEnvironmentalZones.find((e: any): boolean =>
          Number(s.environmentalZoneId) === Number(e.id)))

      const newSensorValues = mySensors.reduce(
        (acc, cur: ISensorRecordByType): ISensorValue[] => {
          // There are cases in which the data is "packed" and multiple sensors data with the same timestamp
          // are contained within the same object.
          // To ensure we include them all, first we filter by `createdOn` of the first entry (which includes the date we want)
          // then we reduce all the entries into one merged object.
          const createdOn = cur.sensorRecords[0].createdOn;
          const newSensorValues: ISensorRecord | ICO2SensorRecord = cur.sensorRecords
            .filter(
              (record: { createdOn: string }) => record.createdOn === createdOn
            )
            .reduce(
              (acc, cur) => ({
                ...cur,
                ...acc
              }),
              null
            );

          return [
            ...acc,
            // If it's a normal sensor.
            ...('umol' in newSensorValues ?
                [
                  {
                    order: 0,
                    name: 'Temp',
                    value: ConvertTemperature(
                      temperatureMetric,
                      newSensorValues.temperature
                    ).toFixed(ChartAccuracy.temperature),
                    units: temperatureMetric === 'celcius' ? '°C' : '°F',
                  },
                  {
                    order: 1,
                    name: 'Hum',
                    value: newSensorValues.humidity.toFixed(ChartAccuracy.humidity),
                    units: '%',
                  },
                  {
                    order: 2,
                    name: 'VPD',
                    value: newSensorValues.vpd.toFixed(ChartAccuracy.vpd),
                    units: 'hPa',
                  },
                ]
              : []),
            // If it's a CO2 sensor.
            ...('co2' in newSensorValues ?
                [
                  {
                    order: 3,
                    name: 'CO2',
                    value: newSensorValues.co2.toFixed(ChartAccuracy.co2),
                    units: 'ppm',
                  },
                ]
              : []),
          ];
        },
        []
      );

      newSensorValues.sort((a: ISensorValue, b: ISensorValue): number =>
        a.order - b.order)

      setSensorValues(newSensorValues)
    }
  }, [data, environmentalZonesUUID, sensorsUUID])

  useEffect((): void => {
    const { vendorCustomerFacilityIds } = data
    if (!(
      vendorCustomerFacilityIds?.length > 0 &&
      greenAutomationWaterSystemRecords?.length > 0 &&
      !greenAutomationWaterSystemRecordsLoading
    )) { return }

    // there should only be one water system per space, so find the first match among
    // the space's vendorCustomerFacilityIds
    const waterSystem = greenAutomationWaterSystemRecords.find((waterSystemRecord: IGreenAutomationWaterSystemRecord): boolean =>
      vendorCustomerFacilityIds.includes(waterSystemRecord.vendorCustomerFacilityId)
    )

    // it's possible there is water system data but not for this space, so check again
    if (!waterSystem) { return }

    const {
      ec1,
      ec2,
      ph1,
      ph2,
      dissolvedO2,
      waterTemp
    } = waterSystem

    setGreenAutomationWaterSystemValues([
      {
        name: 'EC1',
        value: ec1 && Number(ec1.toFixed(1)),
        units: 'mS/cm'
      },
      {
        name: 'EC2',
        value: ec2 && Number(ec2.toFixed(1)),
        units: 'mS/cm'
      },
      {
        name: 'pH1',
        value: ph1 && Number(ph1.toFixed(1)),
        units: ''
      },
      {
        name: 'pH2',
        value: ph2 && Number(ph2.toFixed(1)),
        units: ''
      },
      {
        name: 'Dissolved O2',
        value: dissolvedO2 && Number(dissolvedO2.toFixed(1)),
        units: 'mg/L' // this is a guess, I don't have any sample data or documentation to confirm this
      },
      {
        name: 'Water Temp',
        value: waterTemp && Number(ConvertTemperature(temperatureMetric, waterTemp).toFixed(1)),
        units: temperatureMetric === 'celcius' ? '°C' : '°F'
      }
    ])
  }, [data, greenAutomationWaterSystemRecordsUUID])

  // Callbacks /////////////////////////////////////////////////////////////////

  const $click = (): void =>
    onClick(data)

  // Render ////////////////////////////////////////////////////////////////////

  let className = 'Theme__Light GridView__SpaceCard'
  if (outlined) className += ' Outlined'
  if (resting) className += ' Resting'
  if (raised) className += ' Raised'
  if (animated) className += ' Animated'

  return (
    <article
      style={style}
      className={className}
      onClick={$click}
    >
      <div className='IconContainer'>
        <div className='Theme__Dark Icon'>
          <FontAwesomeIcon icon={faWarehouseAlt} />
        </div>
      </div>

      <div className='Body'>
        <div className='Head'>
          <h6 className='Primary'>{data.name}</h6>
        </div>

        <div className='Subhead'>
          {sensorValues && sensorValues.map((sensorValue: ISensorValue): JSX.Element => (
            <div
              key={sensorValue.name}
              className='SensorValue'
            >
              <p className='Name'>{sensorValue.name}</p>
              <p className='Value'>{sensorValue.value} {sensorValue.units}</p>
            </div>
          ))}
          {greenAutomationWaterSystemValues && greenAutomationWaterSystemValues.map((waterSystemValue: ISensorValue): JSX.Element => (
            <div
              key={waterSystemValue.name}
              className='SensorValue'
            >
              <p className='Name'>{waterSystemValue.name}</p>
              <p className='Value'>
                { waterSystemValue.value ? <span>{waterSystemValue.value} {waterSystemValue.units}</span> : <span>-</span>}
              </p>
            </div>
          ))}
        </div>
      </div>
    </article>
  )
}

export default Space

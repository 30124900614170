import React from 'react'
import { ITab } from 'ui-lib/components/tab'
import IEntity from 'src/graphql-lib/interfaces/entity'
import ISetStateType from 'graphql-lib/interfaces/ISetStateType'

interface ITaskIssueListContextProps {
  viewingTaskIssue?: number
  creatingTaskIssue: boolean
  taskIssuesTab: ITab
  setViewingTaskIssue: (viewingTask?: number) => void;
  setCreatingTaskIssue: (creatingTask: boolean) => void;
  setTabButNotSearchParams: (newTab: ITab) => void;
  setSelectedEntities: (newVal: IEntity[]) => void;
}

export const TaskIssueListContext = React.createContext<ITaskIssueListContextProps>({
  creatingTaskIssue: false,
  taskIssuesTab: { id: '', label: '' },
  setViewingTaskIssue: () => null,
  setCreatingTaskIssue: () => null,
  setTabButNotSearchParams: () => null,
  setSelectedEntities: () => null

})
export const TaskIssueListProvider = TaskIssueListContext.Provider

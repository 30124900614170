import {
  AxisOptions,
  SeriesOptionsType as HighChartsSeriesOptions,
  Options as HighChartsOptions,
  XAxisOptions,
  YAxisOptions
} from 'highcharts'

// Types
import EChart from 'map-view/enums/chart'
import ISetStateType from 'graphql-lib/interfaces/ISetStateType'

// Utils
import {
  ChartName,
  ChartAccuracy,
  ChartUnit,
  ChartType,
  ChartColor
} from 'map-view/utils/chart'

// Libs
import { format } from 'date-fns'
import { max, uniq } from 'lodash'
import IChart from 'map-view/interfaces/chart'
import { createDate } from 'utils/dates'
import m from 'moment-timezone'

const DateFormat = 'M/d/yy p'

interface UpdateHighchartsSizeType {
  highchartsRef: any
}
const UpdateHighchartsSize = (props: UpdateHighchartsSizeType): void => {
  const { highchartsRef } = props;
  if (!highchartsRef) {
    return
  }
  setTimeout(
    () => highchartsRef.chart && highchartsRef.chart.reflow(),
    333
  )
}

interface IUpdateOptions {
  charts: IChart[];
  setOptions: ISetStateType<HighChartsOptions>;
  setXAxis: ISetStateType<XAxisOptions>
}

const UpdateOptions = ({
  charts,
  setOptions,
  setXAxis
}: IUpdateOptions): void => {
  if (!charts) {
    return
  }
  const dynamicUnitMap = new Map<EChart, string>()
  const series: Array<HighChartsSeriesOptions> = charts
    .map(chart => {
      // Handling dynamic units and making sure all charts
      // of the same type have the same unit
      const unit = chart.dynamicUnit || ChartUnit[chart.chart]
      const metricTypeUnit = dynamicUnitMap.get(chart.chart)
      if (
        metricTypeUnit === undefined
      ) {
        // Set the dynamic unit for this series
        dynamicUnitMap.set(chart.chart, unit)
      } else if (metricTypeUnit !== unit) {
        // Catching the case where the same series is using
        // a different unit, and so we will remove the unit
        // ... not that this is explicitly expected.
        dynamicUnitMap.set(chart.chart, '')
      }

      return {
        type: ChartType[chart.chart],
        id: `${chart.parentType}.${chart.parentID}.${chart.chart}`,

        color: chart.color || ChartColor[chart.chart],
        custom: chart,
        data: chart.value,
        yAxis: `y-axis.${unit}`
      } as HighChartsSeriesOptions;
    })

  const xAxis: Pick<XAxisOptions, 'ordinal' | 'type' | 'labels' | 'title' | 'minTickInterval' | 'events'> = {
    type: 'datetime',
    title: { text: 'Time' },
    minTickInterval: 1000 * 60 * 60 * 24, // 1 day
    ordinal: false,
    events: {
      setExtremes: function (e: {min: number, max: number}) {
        setXAxis({ min: e.min, max: e.max })
      }
    }
  }

  const chartsByUnit: Record<string, any> = {}
  for (const [chart, unit] of dynamicUnitMap.entries()) {
    chartsByUnit[unit] ??= []
    chartsByUnit[unit].push(chart)
  }

  // build yAxes per unit, using the highest "accuracy" needed by the charts in view
  const yAxis: Array<YAxisOptions> = Object.entries(chartsByUnit)
    .map(([unit, charts]: [string, EChart[]]) => {
      const chartYAxis: Pick<YAxisOptions, 'id'|'labels'|'visible'> & { custom: { accuracy: number }} = {
        id: `y-axis.${unit}`,
        custom: {
          accuracy: max(charts.map((chart) => ChartAccuracy[chart]))
        },
        labels: {
          formatter: function (): string {
            const value = this.value as unknown as number
            const options = this.axis.options as AxisOptions & { custom: { accuracy: number }}
            const { accuracy } = options.custom
            const chartValue = value.toFixed(accuracy)
            const chartUnit = unit

            return `${chartValue} ${chartUnit}`
          },
          reserveSpace: true
        }
      }

      chartYAxis.visible = !!series
        .find(_series => _series.yAxis === chartYAxis.id)

      return chartYAxis
    })
    .filter(yAxis => yAxis.visible);

  const newOptions: HighChartsOptions = {
    boost: {
      seriesThreshold: 1,
      useGPUTranslations: true,
      usePreallocated: true
    },
    navigator: { xAxis },
    rangeSelector: { enabled: false },
    series,
    time: {
      getTimezoneOffset: (timestamp) => -m(timestamp).utcOffset()
    },
    title: {},
    tooltip: {
      formatter: function (): string[] {
        const tooltips = this.points
          .map((point) => {
            const parentName = point.series.options.custom.meta.parentName
            const chart: EChart = point.series.options.custom.chart
            const chartName = ChartName[chart]
            const chartAccuracy = ChartAccuracy[chart]
            const chartUnit = dynamicUnitMap.get(chart) ?? ChartUnit[chart]
            const pointValue = point.y.toFixed(chartAccuracy)
            const dateTime = format(createDate(point.x), DateFormat)

            return `
              <div>
                <p><b>${parentName}</b></p>
                <br>
                <p><b>${chartName}:</b> ${pointValue} ${chartUnit}</p>
                <br>
                <p><b>${dateTime}</b></p>
              </div>
            `
          })

        return tooltips
      },
      split: true
    },
    xAxis,
    yAxis
  }

  setOptions(newOptions)
}

export {
  UpdateHighchartsSize,
  UpdateOptions
}

// Types
import ITask from 'graphql-lib/interfaces/ITask'
import IFlag from 'graphql-lib/interfaces/IFlag'
import INotification from 'graphql-lib/interfaces/INotification'

// Libs
import { format } from 'date-fns'

// React
import React, {
  CSSProperties,
  ReactChildren,
  useContext,
  useEffect,
  useState
} from 'react'

// React Libs
import useAsyncEffect from 'use-async-effect'

// GraphQL
import { gql, FetchResult } from '@apollo/client/index'
import client from 'graphql-lib/index'

// FontAwesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faCheckCircle,
  faExclamationCircle
} from '@fortawesome/pro-solid-svg-icons'

// UI Lib
import NotificationContext from 'context/notification'
import Flag from 'ui-lib/components/flag'
import { createDate } from 'utils/dates'

import ID from 'graphql-lib/interfaces/ID';

const QueryNotification = gql`
  query {
    notification {
      id
      createdOn

      seen
      acknowledged

      name
      body

      objectType
      objectId

      meta
    }
  }
`

const UpdateNotification = gql`
  mutation(
    $id: ID!,
    $seen: Boolean,
    $acknowledged: Boolean
  ) {
    Notification {
      update(input: {
        id: $id,
        seen: $seen,
        acknowledged: $acknowledged
      }) {
        id
      }
    }
  }
`

export interface ITaskProps {
  outlined?: boolean
  resting?: boolean
  raised?: boolean
  animated?: boolean
  data: ITask
  selectedId: ID
  style: CSSProperties
  onClick: (data: any) => void
}

const Task = ({
  outlined,
  resting,
  raised,
  animated,
  data: task,
  selectedId,
  style,
  onClick
}: ITaskProps) => {
  // Context ///////////////////////////////////////////////////////////////////
  const {
    notifications,
    setNotifications
  } = useContext(NotificationContext)

  // State /////////////////////////////////////////////////////////////////////

  const [flag, setFlag] = useState<IFlag>()
  const [assignedTo, setAssignedTo] = useState<string>()
  const [dueBy, setDueBy] = useState<string>()
  const [complete, setComplete] = useState<boolean>()
  const [urgent, setUrgent] = useState<boolean>()
  const [notification, setNotification] = useState<boolean>()

  // Callbacks /////////////////////////////////////////////////////////////////

  const $click = (): void => {
    onClick(task)
  }

  // Effects ///////////////////////////////////////////////////////////////////

  useEffect((): void => {
    if (task) {
      const newFlag = task.flag[0]
      setFlag(newFlag)
    }
  }, [task])

  useEffect((): void => {
    if (task) {
      let newAssignedTo: string

      const assignees = task.taskAssignee
        .map(a => a.customerUser[0])
        .filter(a => !!a)

      if (assignees.length > 0) {
        const assignee = assignees[0]
        newAssignedTo =
          assignee.name ||
          assignee.username ||
          assignee.email

        if (assignees.length > 1) {
          newAssignedTo += '...'
        }
      } else {
        newAssignedTo = 'No Assignees'
      }

      setAssignedTo(newAssignedTo)
    }
  }, [task])

  useEffect((): void => {
    if (task) {
      let newDueBy: string

      if (task.dueDate) {
        newDueBy = format(createDate(task.dueDate), 'P p')
      } else {
        newDueBy = 'No Due Date'
      }

      setDueBy(newDueBy)
    }
  }, [task])

  useEffect((): void => {
    if (task) {
      const newComplete = task.complete
      const newUrgent = task.priority > 0

      setComplete(newComplete)
      setUrgent(newUrgent)
    }
  }, [task])

  useAsyncEffect(async (): Promise<void> => {
    if (notifications && task) {
      const taskNotifications = notifications.filter(n =>
        n.objectType === 'task' &&
        Number(n.objectId) === Number(task.id))

      if (taskNotifications.length > 0) {
        const unackNotifs = taskNotifications.filter(n => !n.acknowledged)
        const unseenNotifs = taskNotifications.filter(n => !n.seen)

        if (unackNotifs.length > 0) {
          setNotification(true)
        }

        if (unseenNotifs.length > 0) {
          const promises = unseenNotifs.map(
            async (notification): Promise<FetchResult<INotification>> =>
              client.mutate({
                mutation: UpdateNotification,
                variables: {
                  id: notification.id,
                  seen: true
                }
              })
          )

          await Promise.all(promises)
        }
      }
    }
  }, [task])

  // Render ////////////////////////////////////////////////////////////////////

  let className = 'Theme__Light TaskCard'
  if (outlined) className += ' Outlined'
  if (resting) className += ' Resting'
  if (raised) className += ' Raised'
  if (animated) className += ' Animated'
  if (Number(task.id) === Number(selectedId)) className += ' Selected'
  return (
    <article
      style={style}
      className={className}
      onClick={$click}
    >
      <div className='FlagContainer'>
        {flag && (
          <Flag flag={flag} status={'current'} onClick={() => null} />
        )}

        {!flag && (
          <div className='FlagPlaceholder' />
        )}
      </div>

      <div className='Body'>
        <div className='Head'>
          <h6 className='Primary'>{task.name}</h6>
        </div>

        <div className='Subhead'>
          <p>{assignedTo}</p>
        </div>

        <div className='Subhead'>
          <p>{dueBy}</p>
        </div>
      </div>

      <div className='Icons'>
        {notification && (
          <FontAwesomeIcon
            className='Blue'
            icon={faExclamationCircle}
          />
        )}

        {complete && (
          <FontAwesomeIcon
            className='Green'
            icon={faCheckCircle}
          />
        )}

        {urgent && (
          <FontAwesomeIcon
            className='Red'
            icon={faExclamationCircle}
          />
        )}
      </div>
    </article>
  )
};

export default Task;

// Types
import IEntity from 'graphql-lib/interfaces/entity'
import IInventory from 'graphql-lib/interfaces/IInventory'
import getChartColor from 'utils/chose-chart-entity-color'

// Utils
import {
  Chartable,
  ChartName,
  ChartAccuracy,
  ChartUnit
} from 'utils/chart'

// React
import React, {
  MouseEvent,
  useCallback,
  useState
} from 'react'

// React Libs
import { useIter } from 'react-lib/use-iter'

// Effects
import { UpdateSelected } from './effects'
import ID from 'graphql-lib/interfaces/ID'
export interface IInventoryChartProps {
  animated?: boolean;

  inventory: IInventory;
  chart: [string, Array<{ value: number }>]; // TODO: Below expects second value to be { value: number, ... } instead of number[][]
  selectedEntities?: IEntity[];

  onSelect?: (cropID: ID, inventoryID: ID, chartKey: string) => void;
}

const InventoryChart = ({
  animated,

  inventory,
  chart,
  selectedEntities,

  onSelect
}: IInventoryChartProps): JSX.Element => {
  // Props /////////////////////////////////////////////////////////////////////

  const [
    chartKey,
    chartValues
  ] = chart

  // State /////////////////////////////////////////////////////////////////////

  const [selected, setSelected] = useState<boolean>()

  // Effects ///////////////////////////////////////////////////////////////////

  useIter(
    UpdateSelected,

    { inventory,
      chart,
      selectedEntities,

      setSelected },

    [ inventory,
      chart,
      selectedEntities ]
  )

  // Callbacks /////////////////////////////////////////////////////////////////

  const $select = useCallback(
    (ev: MouseEvent<HTMLDivElement, any>): void => {
      ev.preventDefault()
      ev.stopPropagation()

      if (!Chartable[chartKey]) {
        return
      }

      onSelect?.(inventory.cropId, inventory.id, chartKey)
    },
    [inventory, inventory?.id, chart, onSelect]
  )

  // Render ////////////////////////////////////////////////////////////////////

  let className = 'InventoryChart'
  if (animated) className += ' Animated'
  if (selected) className += ' Theme__Dark Selected'

  const style: Record<string, any> = {}
  if (selected) {
    style.background = getChartColor(selectedEntities, inventory?.id, chartKey)
  }

  return (
    <div
      style={style}
      id={chartKey}
      className={className}
      onClick={(ev) => $select(ev)}
    >
      <p className='Key'>{ChartName[chartKey]}</p>
      <p className='Value'>{chartValues[chartValues.length - 1].value?.toFixed(ChartAccuracy[chartKey])} {ChartUnit[chartKey]}</p>
    </div>
  )
}

export default InventoryChart

/**
 * @author Miras Absar <mabsar@iunu.com>
 */

// Types
import IInventory from 'graphql-lib/interfaces/IInventory'
import IInventoryLocation from 'graphql-lib/interfaces/IInventoryLocation';
import ISetStateType from 'graphql-lib/interfaces/ISetStateType';
import ID from 'graphql-lib/interfaces/ID';

const UpdateInventories = ({
  inventoriesLoading,
  inventoriesData,

  setInventories,
  setInventoryIDs
}: {
  inventoriesLoading: boolean;
  inventoriesData: any;

  setInventories: (newVal: IInventory[]) => void;
  setInventoryIDs: (newVal: ID[]) => void;
}): void => {
  if (!inventoriesLoading && inventoriesData) {
    const newInventories = inventoriesData.crop[0].inventory
    const newInventoryIDs = newInventories.map((i: IInventory): ID => i.id)

    setInventories(newInventories)
    setInventoryIDs(newInventoryIDs)
  }
}

const UpdateInventoryLocations = ({
  inventoryLocationsLoading,
  inventoryLocationsData,
  setInventoryLocations
}: {
  inventoryLocationsLoading: boolean
  inventoryLocationsData: any;
  setInventoryLocations: ISetStateType<Array<IInventoryLocation>>
}): void => {
  if (!inventoryLocationsLoading && inventoryLocationsData) {
    const newInventoryLocations = inventoryLocationsData.inventoryLocation

    setInventoryLocations(newInventoryLocations)
  }
}

export {
  UpdateInventories,
  UpdateInventoryLocations
}

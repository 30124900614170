/**
 * @author Miras Absar <mabsar@iunu.com>
 */

import { gql } from '@apollo/client'

const QuerySpaces = gql`
  query($ids: [ID]) {
    space(ids: $ids) {
      id

      code
      name

      dcs
    }
  }
`

const QueryCrop = gql`
  query($id: ID, $facilityIds: [ID]) {
    crop(id: $id, facilityIds: $facilityIds) {
      id
      createdOn

      code
      name

      startDate
      transplantDate
      endDate

      speciesCode
      speciesName
      speciesIcon

      varietyCode
      varietyName
      varietyColor
      varietyPattern
    }
  }
`

const QueryInventories = gql`
  query($cropIds: [ID]) {
    inventory(cropIds: $cropIds) {
      id
      createdOn
      cropId
      zoneId

      code
      favorite
      inventoryLocator {
        id

        locator {
          id
          serial
        }
      }

      budDetection(limit: 1) {
        id

        data
        delta
      }

      canopyCoverage(limit: 1) {
        id

        data
        delta
      }

      flowerCoverage(limit: 1) {
        id

        data
        delta
      }

      germinationRate(limit: 1) {
        id
        count
      }
    }
  }
`

const QueryInventoryLocations = gql`
  query($inventoryIds: [ID], $facilityIds: [ID]) {
    inventoryLocation(inventoryIds: $inventoryIds, facilityIds: $facilityIds) {
      inventoryId

      locatorCode
      detectedOn

      seenOn
      facilityId
      spaceId
      spaceIdFromStitch
      positionX
      positionY

      width
      height
      thumbUrl
      legacyUrl
      url
    }
  }
`

const QueryInventoryStitches = gql`
  query(
    $inventoryIds: [ID],
    $startDate: DateTime,
    $endDate: DateTime
  ) {
    inventoryStitch(
      inventoryIds: $inventoryIds,
      startDate: $startDate,
      endDate: $endDate
    ) {
      id
      lastImageCreatedOn
      spaceId
      inventoryId

      width
      height
      tilesUrl

      locatorCode
    }
  }
`

const QueryEnvironmentalZones = gql`
  query($spaceIds: [ID]) {
    environmentalZone(spaceIds: $spaceIds) {
      id
      spaceId
    }
  }
`

const QuerySensorRecords = gql`
  query(
    $environmentalZoneIds: [ID],
    $startDate: DateTime,
    $endDate: DateTime
  ) {
    sensorRecord(
      environmentalZoneIds: $environmentalZoneIds,
      startDate: $startDate,
      endDate: $endDate
    ) {
      id
      createdOn
      environmentalZoneId

      umol
      humidity
      temperature
      vpd
      dli
      absoluteHumidity
    }
  }
`

const QueryCO2SensorRecords = gql`
  query(
    $environmentalZoneIds: [ID],
    $startDate: DateTime,
    $endDate: DateTime
  ) {
    co2SensorRecord(
      environmentalZoneIds: $environmentalZoneIds,
      startDate: $startDate,
      endDate: $endDate
    ) {
      id
      createdOn
      environmentalZoneId

      co2
    }
  }
`


export {
  QuerySpaces,
  QueryCrop,
  QueryInventories,
  QueryInventoryLocations,
  QueryInventoryStitches,
  QueryEnvironmentalZones,
  QuerySensorRecords,
  QueryCO2SensorRecords
}

/**
 * @author Miras Absar <mabsar@iunu.com>
 */

import { gql } from '@apollo/client'

const QueryCrops = gql`
  query($query: String!, $facilityIds: [Int]) {
    search(query: $query, facilityIds: $facilityIds, limit: 25) {
      crop
    }
  }
`

export { QueryCrops }

export default class BlobUtils {
  public static toArrayBuffer (blob: File): Promise<ArrayBuffer> {
    return new Promise((resolve): void => {
      const fileReader = new FileReader()

      fileReader.onload = (): void => {
        resolve(fileReader.result as ArrayBuffer)
      }

      fileReader.readAsArrayBuffer(blob)
    })
  }

  public static toDataUrl (blob: Blob): Promise<string> {
    return new Promise((resolve): void => {
      const fileReader = new FileReader()

      fileReader.onload = (): void => {
        resolve(fileReader.result as string)
      }

      fileReader.readAsDataURL(blob)
    })
  }
}

import React, { useCallback, useEffect } from 'react'

// Import Custom Components
import Button from 'ui-lib/components/button'
import { EStorage, GetItem } from 'utils/storage'

// Queries
import { SendEmailReport, QueryConfiguration } from '../queries'
import { useLazyQuery } from '@apollo/client'

// Error Handling
import { toast } from 'react-toastify'

const EmailReportButton = () => {
  // Queries
  const [queryConfiguration, {
    loading: configLoading,
    error: configError,
    data: configData,
  }] = useLazyQuery(QueryConfiguration)

  const [sendEmailReport, {
    loading: emailLoading,
    error: emailError,
    data: emailData,
  }] = useLazyQuery(SendEmailReport)

  // Use the recipient addresses in configData to send the email
  const prepareEmailsAndSend = useCallback(() => {
    if (configData?.facility[0]?.configuration[0] == null) return
    const configDistributions = configData.facility[0]?.configuration[0].configuration.distributions
    // Search the distrubitions we got back for the issue report config
    const issueReportConfig = configDistributions
      .find((d: {channelName: string}) => d.channelName === 'daily-issue-report')
    const {
      to: emailRecipients,
      cc: ccRecipients,
      bcc: bccRecipients
    } = issueReportConfig

    const [{ id: facilityId }] = GetItem('facilities', EStorage.EphemeralStorage)
    const emailQueryVariables = {
      facilityId,
      code: "daily-issue-report",
      to: emailRecipients,
      cc: ccRecipients,
      bcc: bccRecipients
    }

    // Send the email to the users specified in the facility's config
    const confirmText = 'Are you sure you are ready to send the notification emails?'
    if (confirm(confirmText)) {
      sendEmailReport({variables: emailQueryVariables})
    }
  }, [configData, sendEmailReport])

  // When button is clicked, get the configuration that has 
  // the emails the customer set as recipients for issue detection
  const sendEmail = () => {
    const [{ id: facilityId }] = GetItem('facilities', EStorage.EphemeralStorage)
    configData ? prepareEmailsAndSend() 
      : queryConfiguration({ variables: { id: facilityId } })
  }

  // Once we have the recipient addresses, parse the response and send the emails
  useEffect(() => prepareEmailsAndSend(), [configData, prepareEmailsAndSend])

  // Throw error handling messages if email fails
  useEffect(() => {
    if (!emailError && !configError) return
    const errorMessage = 'There was an issue sending an email. Please try again in a few moments.'
    toast.error(errorMessage)
  }, [emailError, configError])
  
  let buttonText = emailLoading || configLoading ? 'Sending...'
    : (emailData ? 'Email Report Sent' : 'Send Email Report')

  return (
    <div style={{display: 'flex', flexDirection: 'column'}}>
      <Button 
        outlined
        loading={emailLoading || configLoading}
        showTextWhileLoading
        propClassNames='text--white bgr--blueAI mx-s my-m'
        onClick={sendEmail}
      >
        {buttonText}
      </Button>
    </div>
  )
}

export default EmailReportButton
/**
 * @author Miras Absar <mabsar@iunu.com>
 * @file ...
 */

// Types
import EInputStatus from 'ui-lib/enums/input-status'
import IInventory from 'graphql-lib/interfaces/IInventory';

// Libs
import { v4 } from 'uuid'

// GraphQL Lib & Queries
import client from 'graphql-lib/index'
import { QueryInventory } from './queries'

interface IUpdateInventory {
  tagState: [ tag: string, tagStatus: { status: EInputStatus } ];
  setInventory: (i: IInventory) => void;
  setInventoryError: (err: Error) => void;
  setInventoryUUID: (uuid: string) => void;
}

const UpdateInventory = async ({
  tagState: [
    tag,
    tagStatus
  ],

  setInventory,
  setInventoryError,
  setInventoryUUID
}: IUpdateInventory): Promise<void> => {
  if (
    !/^[0-9a-zA-Z]{6}$/.test(tag) ||
    tagStatus?.status !== EInputStatus.OK
  ) {
    return
  }

  const {
    error,
    data
  } = await client.query({
    query: QueryInventory,
    variables: { serial: tag }
  })

  if (error) {
    setInventoryError(error)
    setInventoryUUID(v4())

    return
  }

  const newInventory = data
    .locator[0]
    .inventoryLocator[0]
    .inventory[0]

  setInventory(newInventory)
  setInventoryUUID(v4())
}

export { UpdateInventory }

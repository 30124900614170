/**
 * @author Miras Absar <mabsar@iunu.com>
 */

// React
import React, {
  useCallback,
  useRef,
  ChangeEvent
} from 'react'

// Font Awesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faExclamationCircle,
  faExclamationTriangle
} from '@fortawesome/pro-solid-svg-icons'

// Types
import EInputStatus from 'ui-lib/enums/input-status'
import IInputStatus from 'ui-lib/interfaces/IInputStatus'

interface IOutlinedInputProps<T> {
  animated?: boolean;
  label: string;
  inputProps: any;
  state: [
    T,
    IInputStatus,
    string,

    (newVal: T) => void,
    (reinitVal: T) => void,

    boolean,
    boolean,
    (newVal: boolean) => void,
    (newVal: boolean) => void
  ]
}

const OutlinedInput = ({
  animated,
  label,
  inputProps,
  state: [
    value,
    status,
    uuid,

    setValue,
    resetValue,

    touched,
    pristine,
    setTouched,
    setPristine
  ]
}: IOutlinedInputProps<any>): JSX.Element => {
  // State /////////////////////////////////////////////////////////////////////

  const inputRef = useRef<HTMLInputElement>()

  // Callbacks /////////////////////////////////////////////////////////////////

  const $click = useCallback(
    (): void => {
      if (inputRef.current) {
        inputRef.current.focus()
        setTouched(true)
      }
    },
    [touched]
  )

  const $change = useCallback(
    (ev: ChangeEvent<HTMLInputElement>): void => {
      const newValue = ev.target.value
      setValue(newValue)
    },
    [uuid]
  )

  // Render ////////////////////////////////////////////////////////////////////

  let className = 'OutlinedInput'
  let icon: JSX.Element | undefined
  let message: JSX.Element | undefined

  if (animated) className += ' Animated'
  if (value.length > 0) className += ' HasValue'
  if (!pristine) className += ' ShowStatus'
  if (status?.status === EInputStatus.None) {
    className += ' None'
    icon = undefined

    message = status.message.length === 0
      ? undefined
      : <p>{status.message}</p>
  }

  if (status?.status === EInputStatus.OK) {
    className += ' OK'
    icon = undefined

    message = status.message.length === 0
      ? undefined
      : <p>{status.message}</p>
  }

  if (status?.status === EInputStatus.Warning) {
    className += ' Warning'
    icon = (
      <FontAwesomeIcon icon={faExclamationTriangle} />
    )

    message = status.message.length === 0
      ? undefined
      : <p>{status.message}</p>
  }

  if (status?.status === EInputStatus.Error) {
    className += ' Error'
    icon = (
      <FontAwesomeIcon icon={faExclamationCircle} />
    )

    message = status.message.length === 0
      ? undefined
      : <p>{status.message}</p>
  }

  return (
    <div
      className={className}
      onClick={$click}
    >
      <div>
        <input
          {...inputProps}
          ref={inputRef}
          value={value}
          onChange={$change}
        />

        {icon}

        <div>
          <div />

          <div>
            <label>{label}</label>
          </div>

          <div />
        </div>
      </div>

      {message}
    </div>
  )
}

export default OutlinedInput

// Libs
import m, { Moment } from 'moment'
import { v4 } from 'uuid'
import {
  addDays,
  isAfter,
  isSameSecond,
  subDays
} from 'date-fns'

// React
import React, {
  useEffect,
  useState
} from 'react'

// React Libs
import { toast } from 'react-toastify'
import DatetimePicker from 'react-datetime-picker'
import 'react-datetime-picker/dist/DateTimePicker.css'
import { useIter } from 'react-lib/use-iter'

// FontAwesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faArrowLeft,
  faArrowRight,
  faCheck
} from '@fortawesome/pro-regular-svg-icons'

// Effects
import {
  UpdateNow,
  UpdateMetadata
} from './effects'

export interface ITimeTravelProps {
  outlined?: boolean;
  resting?: boolean;
  raised?: boolean;
  animated?: boolean;

  time: Moment;
  onTimeTravel: (newTime: Moment) => void;
}

const TimeTravel = ({
  outlined,
  resting,
  raised,
  animated,

  time,
  onTimeTravel
}: ITimeTravelProps): JSX.Element => {
  // State /////////////////////////////////////////////////////////////////////

  const [now, setNow] = useState<Date>()
  const [isPast, setIsPast] = useState<boolean>()
  const [isDifferent, setIsDifferent] = useState<boolean>()
  const [isFuture, setIsFuture] = useState<boolean>()
  const [uuid, setUUID] = useState<string>(v4())

  // Effects ///////////////////////////////////////////////////////////////////

  useIter(
    UpdateNow,

    { time,
      setNow },

    [time]
  )

  useIter(
    UpdateMetadata,

    { time,
      now,
      setIsPast,
      setIsDifferent,
      setIsFuture },

    [time, now]
  )

  // Callbacks /////////////////////////////////////////////////////////////////

  const $timeTravel = (): void => {
    if (
      now &&
      isDifferent &&
      !isFuture
    ) {
      onTimeTravel(m(now))

      /*
      const newURLParams = Object.assign(
        {},
        urlParams,
        { time: now.toISOString() }
      )

      setProgrammaticTransaction(true)
      setURLParams(newURLParams)
      */
    }

    if (
      now &&
      isDifferent &&
      isFuture
    ) {
      toast.error("Can't time travel to the future.")
    }
  }

  const $prev = (): void => {
    const _now = subDays(new Date(now), 1)
    const realNow = new Date()
    const _isPast = isAfter(realNow, time.toDate())
    const _isDifferent = !isSameSecond(time.toDate(), _now)
    const _isFuture = isAfter(_now, realNow)

    if (
      _now &&
      _isDifferent &&
      !_isFuture
    ) {
      onTimeTravel(m(_now))

      /*
      const newURLParams = Object.assign(
        {},
        urlParams,
        { time: _now.toISOString() }
      )

      setProgrammaticTransaction(true)
      setURLParams(newURLParams)
      */
    }

    if (
      _now &&
      _isDifferent &&
      _isFuture
    ) {
      toast.error("Can't time travel to the future.")
    }
  }

  const $next = (): void => {
    const _now = addDays(new Date(now), 1)
    const realNow = new Date()
    const _isPast = isAfter(realNow, time.toDate())
    const _isDifferent = !isSameSecond(time.toDate(), _now)
    const _isFuture = isAfter(_now, realNow)

    if (
      _now &&
      _isDifferent &&
      !_isFuture
    ) {
      onTimeTravel(m(_now))
    }

    if (
      _now &&
      _isDifferent &&
      _isFuture
    ) {
      toast.error("Can't time travel to the future.")
    }
  }

  // Render ////////////////////////////////////////////////////////////////////

  let className = 'GVTimeTravel'
  if (outlined) className += ' Outlined'
  if (resting) className += ' Resting'
  if (raised) className += ' Raised'
  if (animated) className += ' Animated'

  return (
    <div className={className}>
      <div className='Content'>
        <DatetimePicker
          key={uuid}

          calendarIcon={null}
          clearIcon={null}
          disableCalendar
          disableClock

          value={now}
          onChange={setNow}
        />

        <button onClick={$prev}>
          <FontAwesomeIcon icon={faArrowLeft} />
        </button>

        <button onClick={$next}>
          <FontAwesomeIcon icon={faArrowRight} />
        </button>

        <button onClick={$timeTravel}>
          <FontAwesomeIcon icon={faCheck} />
        </button>
      </div>
    </div>
  )
}

export default TimeTravel

import React, { useState } from "react";
import { useRouteMatch } from 'react-router-dom'

// Components
import Tabs from 'ui-lib/components/tabs'
import TasksView, {ITasksViewProps} from 'tasks/components/tasks-view'
import TasksIssuesView from 'tasks/components/tasks-issue-view';

import Has from "utils/has";
import useStorage from "react-lib/use-storage";
import { EStorage } from "utils/storage";

// Routes
import {
  Tasks,
  CreateTask,
  ViewTask,
  TasksIssue,
  CreateTaskIssue,
  ViewTaskIssue
} from '../../utils/routes'
import GoTo from "react-lib/go-to";
import { ITab } from "ui-lib/components/tab";

const TasksRoutes = [
  Tasks,
  CreateTask,
  ViewTask
]

const TasksIssueRoutes = [
  TasksIssue,
  CreateTaskIssue,
  ViewTaskIssue
]

export const TasksAndIssuesViewRoutes = [
  ...TasksRoutes, ...TasksIssueRoutes
]

interface TabsType extends ITab {
  home: string,
  selected: boolean
}

export default function TaskAndIssues(props: ITasksViewProps) {
  const routeMatch = useRouteMatch();
  console.log(routeMatch)
  const [ user ] = useStorage('user', EStorage.EphemeralStorage);
  const admin = Has(user.admin);
  const tabs: TabsType[] = [
    {
      id: 1,
      label: 'Tasks',
      loading: false,
      home: Tasks,
      selected: TasksRoutes.includes(routeMatch.path)
    }
  ];

  if (admin) {
    tabs.push(
      {
        id: 2,
        label: 'Issues',
        loading: false,
        home: TasksIssue,
        selected: TasksIssueRoutes.includes(routeMatch.path)
      }
    );
  }
  const selectedTab = tabs.find(t => t.selected)
  console.log(selectedTab)
  const [ taskOrIssue, setTaskOrIssue ] = useState(selectedTab || tabs[0]);

  return (
    <div>
      <div id={'tabs-container'} className='tabs-container p-ms'>
        <Tabs 
          animated ={true}
          tabs={tabs}
          selectedTab={taskOrIssue}
          onSelect={(e) => {
            const tabsToChoose: TabsType = tabs.find(t => t.id === e.id) || tabs[0]
            GoTo(tabsToChoose.home)
            setTaskOrIssue(tabsToChoose)
          }} />
      </div>
      <div className="px-ms" style={{
        height: `calc(100vh - 120px)`
      }}>
        {taskOrIssue.label === 'Tasks' ? <TasksView setTaskOrIssue={setTaskOrIssue} tabs={tabs} {...props} /> : <TasksIssuesView tabs={tabs} setTaskOrIssue={setTaskOrIssue} {...props} />}
      </div>
    </div>
  )
}
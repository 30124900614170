/**
 * @author Miras Absar <mabsar@iunu.com>
 */

// React Libs
import { gql } from '@apollo/client'

const QueryCustomers = gql`
  query {
    customer(all: true) {
      id
      name
    }
  }
`

export { QueryCustomers }

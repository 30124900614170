import React, { useContext, useEffect, useState } from 'react';
import NavigationContext from 'context/navigation';
import { useLocation, useHistory } from 'react-router-dom';

import { EStorage } from 'utils/storage';
import useStorage from 'react-lib/use-storage';

// Components
import Tabs from 'ui-lib/components/tabs';

type IReports = Array<{id: string, label: string, url: string}>;

const ReportView = () => {
  const [customer] = useStorage('customer', EStorage.EphemeralStorage);
  const reports: IReports = customer.appConfig?.report || [];
  const [selectedReport, setSelectedReport] = useState(reports[0]);

  const { setSecondaryActions } = useContext(NavigationContext);

  const history = useHistory();
  const { search } = useLocation(); 

  const searchParams = new URLSearchParams(search);

  const updateUrlSelection = (val: {label: string}) => {
    searchParams.set('reportLabel', val.label);
    history.push({ search: searchParams.toString() });
  }

  const spaceForReport = document.getElementById('spaceForReport');
  const tabsContainerHeight = document.getElementById('tabs-container')?.clientHeight;

  const topNavigationHeight = document.getElementsByClassName('TopNavigation')[0]?.clientHeight;

  // Calculates the height the reports container should have.
  const heightForContainer = topNavigationHeight && spaceForReport && tabsContainerHeight ? window.innerHeight - tabsContainerHeight - topNavigationHeight - (parseInt(getComputedStyle(spaceForReport).paddingTop) + parseInt(getComputedStyle(spaceForReport).paddingBottom)) : window.innerHeight;
  useEffect(() => {
    if (reports?.length > 0) {
      const report = reports.find((report) => report.label === searchParams.get('reportLabel')) ?? reports[0]
      setSelectedReport(report)
    }
  }, [searchParams])

  useEffect((): void => {
    if (reports?.length > 0 && !searchParams.get('reportLabel')) updateUrlSelection(reports[0]);
    setSecondaryActions([])
  }, [])

  return <div className='py-ms'> { selectedReport
    ? <div>
        <div id={'tabs-container'} className='tabs-container p-ms'>
          <Tabs
            animated ={true}
            tabs={reports}
            selectedTab={selectedReport}
            onSelect={updateUrlSelection} />
        </div>
        <div id='spaceForReport'
          style={{ height: `${heightForContainer}px` }}
          className='mx-center w-90 border-box'
          dangerouslySetInnerHTML={
            {
              __html: `<iframe allowtransparency="true" style="background: #FFFFFF;" width="100%" height="${heightForContainer}" src="${reports.find(r => r.id === selectedReport.id ).url }" frameborder="0" style="border:0" allowfullscreen></iframe>`
            }
          }>
        </div>
      </div>
    : <article className={'Theme__Light CropCard Resting Animated px-ms'}><h6>There are no reports</h6></article>
      }</div>
};

export default ReportView;
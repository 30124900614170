/**
 * @author Miras Absar <mabsar@iunu.com>
 */

import m from 'moment'
import React from 'react'

export interface IAreacamTileProps {
  outlined?: boolean;
  resting?: boolean;
  raised?: boolean;
  animated?: boolean;

  areacam: any;
  onClick: (areacam: any) => void;
}

const AreacamTile = ({
  outlined,
  resting,
  raised,
  animated,

  areacam,
  onClick
}: IAreacamTileProps): JSX.Element => {
  let className = 'AreacamTile'
  if (outlined) className += ' Outlined'
  if (resting) className += ' Resting'
  if (raised) className += ' Raised'
  if (animated) className += ' Animated'

  const { areaImageRecord } = areacam
  const $click = () => onClick(areacam)

  return (
    <article
      className={className}
      onClick={$click}
      tabIndex={0}
    >
      {areaImageRecord.length > 0
        ? <img className='Image' src={areaImageRecord[0].thumbUrl} />
        : <div className='Placeholder' />}

      <div className='Body'>
        <p className='Body1 Primary'>{areacam.code.toUpperCase()}</p>
        {areaImageRecord.length > 0
          ? <p className='Caption Secondary'>{m(areaImageRecord[0].createdOn).format('l')}</p>
          : <p className='Caption Secondary'>No Image Available</p>}
      </div>
    </article>
  )
}

export default AreacamTile

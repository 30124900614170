/**
 * Choreographs a state.
 * @param {Object} ctx A context.
 * @param {Array.<Array<Object|Number>>} states An array of states and delays.
 *
 * `choreographState` applies a set of state changes at a set of delays.
 * Refer to the following example...
 *
 * ```javascript
 *
 * choreographState(this, [
 *   [ { showOverlay: true }, 0 ]
 *   [ { animateOutOverlay: true }, 1000 ],
 *   [ {
 *       showOverlay: false,
 *       animateOutOverlay: false,
 *   }, 0 ]
 * ]);
 *
 * ```
 *
 * `{ showOverlay: true }` is applied at a delay of 0ms,
 * `{ animateOutOverlay: true }` is applied at a delay of 1000ms,
 * `{ showOverlay: false, animateOutOverlay: false, } is applied at a delay of 0ms.
 *
 * Each state change is guaranteed to be applied after the previous,
 * making this useful for choreographing animations.
 *
 */
export default function choreographState(ctx, states) {
  const currentState = states[0][0];
  const currentDelay = states[0][1];

  setTimeout(() => {
    ctx.setState(currentState, () => {
      states.shift();

      if (states.length > 0) {
        choreographState(ctx, states);
      }
    });
  }, currentDelay);
}

// Utils
import { EStorage, GetItem } from 'utils/storage';

// React
import React, {
  useEffect,
  useRef,
  useState
} from 'react';

import { isNil } from 'utils/ts-utils';

// React Libs
import { toast } from 'react-toastify';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/pro-regular-svg-icons';
import useSearchParams from 'react-lib/use-search-params';

// GraphQL
import { gql, useQuery, useMutation } from '@apollo/client';

// Types
import ETab from 'map-view/enums/tab';
import EInputStatus from 'ui-lib/enums/input-status';
import IFlag from 'graphql-lib/interfaces/IFlag';
import ICrop from 'graphql-lib/interfaces/ICrop';
import ITaskIssue, { ApplicationView } from 'graphql-lib/interfaces/ITaskIssue';
import ISpace from 'graphql-lib/interfaces/ISpace';
import IInputStatus from 'ui-lib/interfaces/IInputStatus';

// UI Lib
import Input from 'ui-lib/components/Input/Input';
import SearchSelect from 'ui-lib/components/search-select';
// import Chips from 'ui-lib/components/chips';
import Checkbox from 'ui-lib/components/Checkbox/Checkbox';
import Button from 'ui-lib/components/button';
import TextFacade from 'ui-lib/facades/text-input';

// UI Lib Codes
import FlagCore from 'graphql-lib/cores/search-select/flag';
import SpaceCore from 'graphql-lib/cores/search-select/space';
import CropCore from 'graphql-lib/cores/search-select/crop';
import { DateInputCore as DateCore } from 'ui-lib/cores/date-input-core';

// UI Lib Facades
import SelectFacade from 'ui-lib/facades/select';
import FlagFacade from 'graphql-lib//facades/search-select/flag-item';
import SpaceFacade from 'graphql-lib/facades/search-select/space-item';
import CropFacade from 'graphql-lib/facades/search-select/crop-item';
import DateTimeFacade from 'ui-lib/facades/datetime-input';

const QueryFlag = gql`
  query($id: ID) {
    flag(id: $id) {
      id
      icon
      color
      title
      description
    }
  }
`

const QuerySpace = gql`
  query($id: ID) {
    space(id: $id) {
      id
      code
      name
      nickname
    }
  }
`

const QueryCrop = gql`
  query($id: ID) {
    crop(id: $id) {
      id
      name
      code
      species
      variety
      enabled
    }
  }
`

const CreateLocationProfile = gql`
  mutation(
    $facilityId: Int,
    $spaceId: Int,
    $cropId: Int,
    $inventoryId: Int,
    $containerId: Int,
    $positionX: Float,
    $positionY: Float
  ) {
    LocationProfile {
      create(input: {
        facilityId: $facilityId,
        spaceId: $spaceId,
        cropId: $cropId,
        inventoryId: $inventoryId,
        containerId: $containerId,
        positionX: $positionX,
        positionY: $positionY
      }) {
        id
      }
    }
  }
`
const CreateTaskIssue = gql`
  mutation (
    $appliedToView: EnumApplicationView!,
    $customerTaskCategoryId: Int!,
    $locationProfileId: Int!,
    $priority: Int,
  ) {
    TaskIssue {
      create(
        input: {
          appliedToView: $appliedToView,
          customerTaskCategoryId: $customerTaskCategoryId,
          locationProfileId: $locationProfileId,
          priority: $priority
        }
      ) {
        id
        createdAt
        createdBy
        locationProfileId

        description
        priority
        convertedToTaskAt
        rejectedAt
        customerTaskCategory {
          id
          createdAt

          icon
          color
          name
          description

          flagCategory {
            id
            createdOn

            name
          }
        }

        locationProfile {
          id
          createdOn
          customerId

          inventoryId
          containerId

          positionX
          positionY
        }

      }
    }
  }
`

export interface ICreateTaskIssueCardProps {
  outlined?: boolean;
  resting?: boolean;
  raised?: boolean;
  animated?: boolean;
  appliedToView: ApplicationView;
  customerTaskCategoryId?: number | string;
  zoneId?: string | string[];
  batchGroupId?: number | string;
  batchId?: number | string;
  containerId?: number | string;
  positionX?: number | string | string[];
  positionY?: number | string| string[];
  onBack: () => void;
  onSave: (createTaskIssueData: {TaskIssue: {create: ITaskIssue}}) => void;
}

const ALLOWED_VIEWS_FOR_CREATE = new Set([ 'map' ]);

interface InitialValueType {
  type?: IFlag
  location?: ISpace
  crop?: ICrop
  urgent?: boolean
};

interface StatusType {
  title: {
    status: EInputStatus,
    message: string
  }
}

const CreateTaskIssueCard = ({
  outlined,
  resting,
  raised,
  animated,
  appliedToView,
  customerTaskCategoryId,
  zoneId,
  batchGroupId,
  batchId,
  containerId,
  positionX,
  positionY,
  onBack,
  onSave
}: ICreateTaskIssueCardProps): JSX.Element => {
  const [searchParams] = useSearchParams()
  // Only admin users can create issues when long clicking on map view (for our horticulturalists)
  // Get info on the current logged in user.
  const user = GetItem('user', EStorage.EphemeralStorage)
  const canCreateIssue = user.admin && ALLOWED_VIEWS_FOR_CREATE.has(appliedToView) && searchParams.navigationTab === ETab.Issues

  // Refs
  const flagCore = useRef(new FlagCore(true, canCreateIssue))
  const cropCore = useRef(new CropCore(true))
  const spaceCore = useRef(new SpaceCore(true))

  // State
  const [initialValue, setInitialValue] = useState<InitialValueType>({});
  const [showStatusUntouched, setShowStatusUntouched] = useState(false);
  const value = useRef<Partial<InitialValueType & Record<string, any>>>({});
  const status = useRef<Partial<IInputStatus & Record<keyof IInputStatus, any>>>({});

  // Query a Flag based on Params
  const { data: flagData } = useQuery(QueryFlag, {
    variables: { id: customerTaskCategoryId },
    skip: isNil(customerTaskCategoryId)
  })

  // Query a Space based on Params
  const { data: spaceData } = useQuery(QuerySpace, {
    variables: { id: zoneId },
    skip: isNil(zoneId)
  })

  // Query a Crop based on Params
  const { data: cropData } = useQuery(QueryCrop, {
    variables: { id: batchGroupId },
    skip: isNil(batchGroupId)
  })

  // Create a Location Profile
  const [
    createLocProf,
    {
      error: createLocProfError,
      data: createLocProfData
    }
  ] = useMutation(CreateLocationProfile)

  // Create a Task
  const [
    createTaskIssue,
    {
      error: createTaskIssueError,
      data: createTaskIssueData
    }
  ] = useMutation(CreateTaskIssue)

  useEffect((): void => {
    if (createTaskIssueData) {
      // Once a task-issue is created, cause TasksList and MapCanvas in browser to rerender with updated tasks
      onSave(createTaskIssueData)
    }
  }, [createTaskIssueData])

  // Update Initial Value based on...
  // - Flag
  // - Space
  // - Crop
  useEffect((): void => {
    const nextInitialValue = Object.assign({}, initialValue)

    if (flagData) {
      const { flag } = flagData
      nextInitialValue.type = flag[0]
      value.current.type = flag[0]
    }

    if (spaceData) {
      const { space } = spaceData
      nextInitialValue.location = space[0]
      value.current.location = space[0]
    }

    if (cropData) {
      const { crop } = cropData
      nextInitialValue.crop = crop[0]
      value.current.crop = crop[0]
    }

    setInitialValue(nextInitialValue)
  }, [flagData, spaceData, cropData])

  useEffect((): void => {
    if (createLocProfError) {
      toast.error("Couldn't create a location profile, please try again in a few seconds.")
    }
  }, [createLocProfError])

  useEffect((): void => {
    if (createTaskIssueError) {
      toast.error("Couldn't create a task issue, please try again in a few seconds.")
    }
  }, [createTaskIssueError])

  // Create a Task based on Value,
  // once a Location Profile has been created.
  useEffect((): void => {
    if (createLocProfData) {
      const locProfId = createLocProfData.LocationProfile.create.id
      createTaskIssue({
        variables: {
          appliedToView,
          customerTaskCategoryId: Number(value.current.type.id),
          locationProfileId: Number(locProfId),
          priority: value.current.urgent ? 1 : 0
        }
      })
      onBack();
    }
  }, [createLocProfData])

  const $value = (name: string, newValue: any): void => {
    if (!isNil(newValue) && !Number.isNaN(newValue)) {
      value.current[name] = newValue;
    }
  }

  const $status = (name: keyof IInputStatus, newStatus: IInputStatus): void => {
    status.current[name] = newStatus
    // Remove title requirement for issues created by admins/horticulturalists
    if (canCreateIssue && status.current.title?.message === 'Required') {
      status.current.title = {
        status: EInputStatus.None,
        message: ''
      }
    }
  }

  const $save = (): void => {
    const hasError = Object.values(status.current)
      .reduce(
        (acc: boolean, cur: IInputStatus): boolean =>
          acc || cur.status === EInputStatus.Error,
        false
      )

    if (hasError) {
      setShowStatusUntouched(true)
    } else {
      const [{ id: facilityId }] = GetItem('facilities', EStorage.EphemeralStorage)

      const _spaceId = value.current.location
        ? Number(value.current.location.id)
        : undefined

      const _cropId = value.current.crop
        ? Number(value.current.crop.id)
        : undefined

      const _inventoryId = batchId
        ? Number(batchId)
        : undefined

      const _containerId = containerId
        ? Number(containerId)
        : undefined

      const _positionX = positionX
        ? Number(positionX)
        : undefined

      const _positionY = positionY
        ? Number(positionY)
        : undefined

      createLocProf({
        variables: {
          facilityId: Number(facilityId),
          spaceId: _spaceId,
          cropId: _cropId,
          inventoryId: _inventoryId,
          containerId: _containerId,
          positionX: _positionX,
          positionY: _positionY
        }
      });
    }
  }

  let className = 'Theme__Light Card CreateTaskCard'
  if (outlined) className += ' Outlined'
  if (resting) className += ' Resting'
  if (raised) className += ' Raised'
  if (animated) className += ' Animated'

  return (
    <article className={className}>
      <div className='Head'>
        <div className='Row'>
          <button type='button' onClick={onBack}>
            <FontAwesomeIcon icon={faTimes} />
          </button>

    <h6 className='Primary'>Create Issue</h6>
        </div>
      </div>

      <div className='Body'>
        <SearchSelect
          animated={animated}
          label='Type *'
          inputFacade={TextFacade}
          selectFacade={SelectFacade}
          itemFacade={FlagFacade}
          readOnly={false}
          name='type'
          initialValue={initialValue.type}
          showStatusUntouched={showStatusUntouched}
          onValueChange={$value}
          onStatusChange={$status}
          core={flagCore.current}
        />

        {zoneId && (
          <SearchSelect
            animated={animated}
            label='Location *'
            inputFacade={TextFacade}
            selectFacade={SelectFacade}
            itemFacade={SpaceFacade}
            readOnly={false}
            name='location'
            initialValue={initialValue.location}
            showStatusUntouched={showStatusUntouched}
            onValueChange={$value}
            onStatusChange={$status}
            core={spaceCore.current}
          />
        )}

        {batchGroupId && (
          <SearchSelect
            animated={animated}
            label='Crop *'
            inputFacade={TextFacade}
            selectFacade={SelectFacade}
            itemFacade={CropFacade}
            readOnly={false}
            name='crop'
            initialValue={initialValue.crop}
            showStatusUntouched={showStatusUntouched}
            onValueChange={$value}
            onStatusChange={$status}
            core={cropCore.current}
          />
        )}

        <Input
          animated={animated}
          label='Created At'
          facade={DateTimeFacade}
          name='createdAt'
          initialValue={new Date()}
          showStatusUntouched={showStatusUntouched}
          onValueChange={$value}
          onStatusChange={$status}
          core={DateCore}
          readOnly
        />

        <div className='Row'>
          <p className='Body1 Secondary'>Urgent</p>
          <div className='Space' />
          <Checkbox
            animated
            name='urgent'
            readOnly={false}
            initialValue={initialValue.urgent}
            onInput={$value}
          />
        </div>
      </div>

      <div style={{ flex: '1 0 auto' }} />

      <div className='Actions'>
        <Button
          animated={animated}
          outlined
          onClick={$save}
        >
          Save
        </Button>
      </div>
    </article>
  )
}

export default CreateTaskIssueCard

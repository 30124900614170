// Types
import IUser from 'graphql-lib/interfaces/IUser'

// Libs
import { v4 } from 'uuid'

// React
import {
  useCallback,
  useRef,
  useState
} from 'react'

// React Libs
import {
  useIter,
  useAsyncIter
} from 'react-lib/use-iter'

import { useEvent } from 'react-lib/use-event'

// GraphQL
import client from 'graphql-lib/index'
import { UpdateUser } from './queries'

// Effects
import {
  Init,
  QueryUser
} from './effects'
import { ApolloQueryResult, FetchResult, QueryResult } from '@apollo/client'

import ID from 'graphql-lib/interfaces/ID';
import { ICustomEvent } from 'crops/components/crops-view'

const useUser = (id: ID, columns: string[]): {
  loading: boolean;
  error: Error;
  user: IUser;
  uuid: string;

  update: (update: Partial<IUser>) => void;
} => {
  // State /////////////////////////////////////////////////////////////////////

  const hookID = useRef<string>()
  const [loading, setLoading] = useState<boolean>()
  const [error, setError] = useState<Error>()
  const [user, setUser] = useState<IUser>()
  const [uuid, setUUID] = useState<string>()

  // Effects ///////////////////////////////////////////////////////////////////

  useIter(
    Init,
    { hookID },
    [id]
  )

  useAsyncIter(
    QueryUser,

    { id,
      columns,

      setLoading,
      setError,
      setUser,
      setUUID },

    [ id,
      ...columns ]
  )

  useEvent(
    window,
    'update_user',
    (ev: ICustomEvent<IUser>): void => {
      if (Number(ev.detail.update.id) === Number(id) &&
        ev.detail.dispatcherID !== hookID.current) {
        const newUser = Object.assign(
          {},
          user,
          ev.detail.update
        )

        setError(undefined)
        setUser(newUser)
        setUUID(v4())
      }
    },
    [uuid]
  )

  // Callbacks /////////////////////////////////////////////////////////////////

  const update = useCallback(
    async (update: Partial<IUser>): Promise<{ error?: Error; user?: IUser; }> => {
      setLoading(true)
      setUUID(v4())
      update.id = id;
      let error: Error;
      let data: {CustomerUser: IUser & { update: Record<string, any> }};
      try {
        const response: FetchResult< typeof data > = await client.query({
          fetchPolicy: 'no-cache',
          query: UpdateUser,
          variables: update
        });
        data = response.data
      } catch (err) {
        error = err as Error;
      }

      setLoading(false)
      if (error) {
        setError(error)
        setUUID(v4())
        return { error }
      } else if (data) {
        const userUpdate = Object.keys(update)
          .map((key: string): [string, any] =>
            [key, data.CustomerUser.update[key]])
          .reduce(
            (userUpdate, [key, value]) =>
              Object.assign(
                userUpdate,
                { [key]: value }
              ),
            {}
          )

        const newUser = Object.assign(
          {},
          user,
          userUpdate
        )

        setError(undefined)
        setUser(newUser)
        setUUID(v4())

        const ev = new CustomEvent(
          'update_user',
          {
            detail: {
              dispatcherID: hookID.current,
              update
            }
          }
        )

        window.dispatchEvent(ev)

        return { user: newUser }
      }

      return {}
    },
    [uuid]
  )

  // Return ////////////////////////////////////////////////////////////////////

  return {
    loading,
    error,
    user,
    uuid,

    update
  }
}

export default useUser

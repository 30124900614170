/* eslint
curly: ['error', 'multi'],
default-case: 'off',
implicit-arrow-linebreak: 'off',
no-else-return: 'off',
no-extra-boolean-cast: 'off',
nonblock-statement-body-position: ['error', 'any'],
object-curly-spacing: ['error', 'never'],

@typescript-eslint/interface-name-prefix: ['error', 'always'],

react/jsx-indent: 'off',
react/no-unescaped-entities: 'off',
react/prop-types: 'off',

jsx-a11y/control-has-associated-label: 'off',
jsx-a11y/label-has-associated-control: 'off',
jsx-a11y/label-has-for: 'off',
*/

/**
 * @author Miras Absar <mabsar@iunu.com>
 */

import React, {
  KeyboardEvent,
  useState
} from 'react';

import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faSquare} from '@fortawesome/pro-regular-svg-icons';
import {faCheckSquare} from '@fortawesome/pro-solid-svg-icons';

import ICheckboxProps from 'ui-lib/interfaces/ICheckboxProps';

export default ({
  animated,
  name,
  initialValue,
  onInput,
  readOnly,
}: ICheckboxProps): JSX.Element => {
  const [value, setValue] = useState(initialValue);
  const $click = (): void => {
    if (!readOnly) {
      const newValue = !value;
      setValue(newValue);
      if (name && onInput) onInput(name, newValue);
    }
  };

  const $keyPress = ({key}: KeyboardEvent): void => {
    if (!readOnly && key === ' ') {
      const newValue = !value;
      setValue(newValue);
      if (name && onInput) onInput(name, newValue);
    }
  };

  const className = animated ? 'Checkbox Animated' : 'Checkbox';
  const icon = value ? faCheckSquare : faSquare;
  return (
    <div
      className={className}
      onKeyPress={$keyPress}
      onClick={$click}
      role='checkbox'
      aria-checked={value}
      tabIndex={0}
    >
      <FontAwesomeIcon icon={icon} />
    </div>
  );
};

import { format } from 'date-fns'

import IColumn from 'arrayview/interfaces/IColumn'
import IGroup from 'arrayview/interfaces/IGroup'
import ITask from '../interfaces/ITask'

import {
  StringIncludes,
  StringCompare,
  DateIncludes,
  DateCompare
} from '../utils/safe'

import { createDate } from 'utils/dates'

const CreatedOnColumn: IColumn<ITask> = Object.freeze({
  id: 'task:created-on',
  name: 'Created On',
  searchFunc: (_: IColumn<ITask>, search: string, data: ITask): boolean =>
    DateIncludes(data.createdOn, search),

  ascendingSortFunc: (_: IColumn<ITask>, first: ITask, second: ITask): number =>
    DateCompare(first.createdOn, second.createdOn),

  descendingSortFunc: (_: IColumn<ITask>, first: ITask, second: ITask): number =>
    DateCompare(second.createdOn, first.createdOn),

  groupFunc: (_: IColumn<ITask>, data: ITask): IGroup => ({
    id: `${_.id}::${data.createdOn}`,
    name: format(createDate(data.createdOn), 'PPP'),
    data: []
  })
})

const UpdatedOnColumn: IColumn<ITask> = Object.freeze({
  id: 'task:updated-on',
  name: 'Updated On',
  searchFunc: (_: IColumn<ITask>, search: string, data: ITask): boolean =>
    DateIncludes(data.updatedOn, search),

  ascendingSortFunc: (_: IColumn<ITask>, first: ITask, second: ITask): number =>
    DateCompare(first.updatedOn, second.updatedOn),

  descendingSortFunc: (_: IColumn<ITask>, first: ITask, second: ITask): number =>
    DateCompare(second.updatedOn, first.updatedOn),

  groupFunc: (_: IColumn<ITask>, data: ITask): IGroup => ({
    id: `${_.id}::${data.updatedOn}`,
    name: format(createDate(data.updatedOn), 'PPP'),
    data: []
  })
})

// Priority Column
// Complete Column
// Tags Column

const NameColumn: IColumn<ITask> = Object.freeze({
  id: 'task:name',
  name: 'Name',
  searchFunc: (_: IColumn<ITask>, search: string, data: ITask): boolean =>
    StringIncludes(data.name, search),

  ascendingSortFunc: (_: IColumn<ITask>, first: ITask, second: ITask): number =>
    StringCompare(first.name, second.name),

  descendingSortFunc: (_: IColumn<ITask>, first: ITask, second: ITask): number =>
    StringCompare(second.name, first.name),

  groupFunc: (_: IColumn<ITask>, data: ITask): IGroup => ({
    id: `${_.id}::${data.name}`,
    name: data.name,
    data: []
  })
})

// Body Column

const DueDateColumn: IColumn<ITask> = Object.freeze({
  id: 'task:due-date',
  name: 'Due Date',
  searchFunc: (_: IColumn<ITask>, search: string, data: ITask): boolean =>
    DateIncludes(data.dueDate, search),

  ascendingSortFunc: (_: IColumn<ITask>, first: ITask, second: ITask): number =>
    DateCompare(first.dueDate, second.dueDate),

  descendingSortFunc: (_: IColumn<ITask>, first: ITask, second: ITask): number =>
    DateCompare(second.dueDate, first.dueDate),

  groupFunc: (_: IColumn<ITask>, data: ITask): IGroup => ({
    id: `${_.id}::${data.dueDate}`,
    name: format(createDate(data.dueDate), 'PPP'),
    data: []
  })
})

const FlagColumn: IColumn<ITask> = Object.freeze({
  id: 'task:flag',
  name: 'Flag',
  searchFunc: (_: IColumn<ITask>, search: string, data: ITask): boolean =>
    StringIncludes(data.flag[0].title, search),

  ascendingSortFunc: (_: IColumn<ITask>, first: ITask, second: ITask): number =>
    StringCompare(first.flag[0].title, second.flag[0].title),

  descendingSortFunc: (_: IColumn<ITask>, first: ITask, second: ITask): number =>
    StringCompare(second.flag[0].title, first.flag[0].title),

  groupFunc: (_: IColumn<ITask>, data: ITask): IGroup => ({
    id: `${_.id}::${data.flag[0] ? data.flag[0].title : ''}`,
    name: data.flag[0] ? data.flag[0].title : '',
    data: []
  })
})

const AssigneeColumn: IColumn<ITask> = Object.freeze({
  id: 'task:assignee',
  name: 'Assignee',
  searchFunc: (_: IColumn<ITask>, search: string, data: ITask): boolean =>
    StringIncludes(getAssignee(data), search),

  ascendingSortFunc: (_: IColumn<ITask>, first: ITask, second: ITask): number =>
    StringCompare(getAssignee(first), getAssignee(second)),

  descendingSortFunc: (_: IColumn<ITask>, first: ITask, second: ITask): number =>
    StringCompare(getAssignee(second), getAssignee(first)),

  groupFunc: (_: IColumn<ITask>, data: ITask): IGroup => {
    const assignee = data.taskAssignee[0].customerUser[0]
    const assigneeName = assignee.name || assignee.username || assignee.email
    return {
      id: `${_.id}::${data.taskAssignee[0] ? assignee : null}`,
      name: data.taskAssignee[0] ? assigneeName : null,
      data: []
    }
  }
})

const getAssignee = (task:ITask) => {
  if (!task.taskAssignee[0]?.customerUser[0]) {
    return 'no assignee'
  }
  const assignee = task.taskAssignee[0].customerUser[0]
  return `${assignee?.name} ${assignee?.username} ${assignee?.email}`
}

export {
  CreatedOnColumn,
  UpdatedOnColumn,
  // Priority Column
  // Complete Column
  NameColumn,
  // Body Column
  DueDateColumn,
  FlagColumn,
  AssigneeColumn
}

// Utils
import { EStorage, GetItem } from 'utils/storage'

// React
import React, {
  useEffect,
  useRef,
  useState
} from 'react'

// React Libs
import { toast } from 'react-toastify'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimes } from '@fortawesome/pro-regular-svg-icons'
import useSearchParams from 'react-lib/use-search-params'

// GraphQL
import { gql, useQuery, useMutation } from '@apollo/client'

// Types
import ICrop from 'graphql-lib/interfaces/ICrop';
import ISpace from 'graphql-lib/interfaces/ISpace';
import ITask from 'graphql-lib/interfaces/ITask';
import ITaskAssignee from 'graphql-lib/interfaces/ITaskAssignee';
import EInputStatus from 'ui-lib/enums/input-status';
import IInputStatus from 'ui-lib/interfaces/IInputStatus';

// UI Lib
import Input from 'ui-lib/components/Input/Input'
import SearchSelect from 'ui-lib/components/search-select'
// import Chips from 'ui-lib/components/chips';
import Checkbox from 'ui-lib/components/Checkbox/Checkbox';
import Button from 'ui-lib/components/button';

// UI Lib Codes
import FlagCore from 'graphql-lib/cores/search-select/flag'
import SpaceCore from 'graphql-lib/cores/search-select/space'
import CropCore from 'graphql-lib/cores/search-select/crop'
import CustomerUserCore from 'graphql-lib/cores/search-select/customer-user'
import {
  TextInputCore as TextCore,
  RequiredTextInputCore as RequiredTextCore
} from 'ui-lib/cores/text-input-core'
import { DateInputCore as DateCore } from 'ui-lib/cores/date-input-core'

// UI Lib Facades
import SelectFacade from 'ui-lib/facades/select'
import FlagFacade from 'graphql-lib//facades/search-select/flag-item'
import SpaceFacade from 'graphql-lib/facades/search-select/space-item'
import CropFacade from 'graphql-lib/facades/search-select/crop-item'
import CustomerUserFacade from 'graphql-lib/facades/search-select/customer-user'
import TextFacade from 'ui-lib/facades/text-input'
import DateTimeFacade from 'ui-lib/facades/datetime-input'
import TextAreaFacade from 'ui-lib/facades/textarea-input'
import { ApplicationView } from 'graphql-lib/interfaces/ITaskIssue'
import IFlag from 'graphql-lib/interfaces/IFlag'

const QueryFlag = gql`
  query($id: ID) {
    flag(id: $id) {
      id
      icon
      color
      title
      description
    }
  }
`

const QuerySpace = gql`
  query($id: ID) {
    space(id: $id) {
      id
      code
      name
      nickname
    }
  }
`

const QueryCrop = gql`
  query($id: ID) {
    crop(id: $id) {
      id
      name
      code
      species
      variety
      enabled
    }
  }
`

const CreateLocationProfile = gql`
  mutation(
    $facilityId: Int,
    $spaceId: Int,
    $cropId: Int,
    $inventoryId: Int,
    $containerId: Int,
    $positionX: Float,
    $positionY: Float
  ) {
    LocationProfile {
      create(input: {
        facilityId: $facilityId,
        spaceId: $spaceId,
        cropId: $cropId,
        inventoryId: $inventoryId,
        containerId: $containerId,
        positionX: $positionX,
        positionY: $positionY
      }) {
        id
      }
    }
  }
`

const CreateTask = gql`
  mutation (
    $appliedToView: EnumApplicationView,
    $flagId: Int!,
    $name: String!,
    $locationProfileId: Int!,
    $dueDate: DateTime,
    $priority: Int,
    $body: String
  ) {
    Task {
      create(
        input: {
          appliedToView: $appliedToView,
          flagId: $flagId,
          name: $name,
          locationProfileId: $locationProfileId,
          dueDate: $dueDate,
          priority: $priority,
          body: $body
        }
      ) {
        id
        createdOn
        customerId
        customerUserId
        locationProfileId

        name
        priority
        dueDate
        complete

        flag {
          id
          createdOn

          icon
          color
          title
          description

          flagCategory {
            id
            createdOn

            name
          }
        }

        locationProfile {
          id
          createdOn
          customerId

          inventoryId
          containerId

          positionX
          positionY
        }

        taskAssignee {
          user {
            id
            createdOn
            customerId

            title
            name
            username
            email
          }
        }
      }
    }
  }
`

const CreateTaskAssignee = gql`
  mutation($customerUserId: ID!, $taskId: ID!) {
    TaskAssignee {
      create(input: {customerUserId: $customerUserId, taskId: $taskId}) {
        id
      }
    }
  }
`

interface InitialValueType {
  type?: IFlag
  location?: ISpace
  crop?: ICrop
  urgent?: boolean
  title?: string
  assignee?: ITaskAssignee
  due?: Date
  description: string
};

export interface ICreateTaskCardProps {
  outlined?: boolean;
  resting?: boolean;
  raised?: boolean;
  animated?: boolean;
  appliedToView: ApplicationView;
  flagId?: number | string | string[];
  spaceId?: number | string | string[];
  cropId?: number | string | string[];
  inventoryId?: number | string | string[];
  containerId?: number | string | string[];
  positionX?: number | string | string[];
  positionY?: number | string | string[];
  isMapView?: boolean;

  onBack: () => void;
  onSave: (createTaskData: {Task: {create: ITask}}) => void;
}

const CreateTaskCard = ({
  outlined,
  resting,
  raised,
  animated,

  appliedToView,
  flagId,
  spaceId,
  cropId,
  inventoryId,
  containerId,
  positionX,
  positionY,

  onBack,
  onSave
}: ICreateTaskCardProps): JSX.Element => {
  const [searchParams] = useSearchParams()

  // Only admin users can create issues when long clicking on map view (for our horticulturalists)
  // Get info on the current logged in user.
  const user = GetItem('user', EStorage.EphemeralStorage)

  // Refs
  const flagCore = useRef(new FlagCore(true))
  const cropCore = useRef(new CropCore(true))
  const spaceCore = useRef(new SpaceCore(true))
  const customerUserCore = useRef(new CustomerUserCore(true));

  // State
  const [initialValue, setInitialValue] = useState<Partial<InitialValueType>>({})
  const [showStatusUntouched, setShowStatusUntouched] = useState(false)
  const value = useRef<Partial<InitialValueType & Record<string, any>>>({})
  const status = useRef<Partial<IInputStatus & Record<keyof IInputStatus, any> >>({})

  // Query a Flag based on Params
  const { data: flagData } = useQuery(QueryFlag, {
    variables: { id: flagId },
    skip: flagId === undefined || flagId === null
  })

  // Query a Space based on Params
  const { data: spaceData } = useQuery(QuerySpace, {
    variables: { id: spaceId },
    skip: spaceId === undefined || spaceId === null
  })

  // Query a Crop based on Params
  const { data: cropData } = useQuery(QueryCrop, {
    variables: { id: cropId },
    skip: cropId === undefined || cropId === null
  })

  // Create a Location Profile
  const [
    createLocProf,
    {
      error: createLocProfError,
      data: createLocProfData
    }
  ] = useMutation(CreateLocationProfile)

  // Create a Task
  const [
    createTask,
    {
      error: createTaskError,
      data: createTaskData
    }
  ] = useMutation(CreateTask)

  // Create a Task Assignee
  const [
    createTaskAsgn,
    {
      error: createTaskAsgnError,
      data: createTaskAsgnData
    }
  ] = useMutation(CreateTaskAssignee)

  // Update Initial Value based on...
  // - Flag
  // - Space
  // - Crop
  useEffect((): void => {
    const nextInitialValue = Object.assign({}, initialValue)

    if (flagData) {
      const { flag } = flagData
      nextInitialValue.type = flag[0]
      value.current.type = flag[0]
    }

    if (spaceData) {
      const { space } = spaceData
      nextInitialValue.location = space[0]
      value.current.location = space[0]
    }

    if (cropData) {
      const { crop } = cropData
      nextInitialValue.crop = crop[0]
      value.current.crop = crop[0]
    }

    setInitialValue(nextInitialValue)
  }, [flagData, spaceData, cropData])

  useEffect((): void => {
    if (createLocProfError) {
      toast.error("Couldn't create a task; please try again in a few seconds.")
    }
  }, [createLocProfError])

  useEffect((): void => {
    if (createTaskError) {
      toast.error("Couldn't create a task; please try again in a few seconds.")
    }
  }, [createTaskError])

  useEffect((): void => {
    if (createTaskAsgnError) {
      toast.warn("Couldn't create a task assignee.")
    }
  }, [createTaskAsgnError])

  // Create a Task based on Value,
  // once a Location Profile has been created.
  useEffect((): void => {
    if (createLocProfData) {
      const locProfId = createLocProfData.LocationProfile.create.id
      createTask({
        variables: {
          appliedToView,
          flagId: Number(value.current.type.id),
          name: value.current.title,
          locationProfileId: Number(locProfId),
          dueDate: value.current.due,
          priority: value.current.urgent ? 1 : 0,
          body: value.current.description
        }
      })
    }
  }, [createLocProfData])

  // Create a Task Assignee based on Value (if one has been selected),
  // once a Task has been created.
  useEffect((): void => {
    if (createTaskData && value.current.assignee) {
      const taskId = createTaskData.Task.create.id
      createTaskAsgn({
        variables: {
          customerUserId: value.current.assignee.id,
          taskId
        }
      })
    }
  }, [createTaskData])

  // ...
  useEffect((): void => {
    if (createTaskData && !value.current.assignee) {
      // Once a task is created, cause TasksList and MapCanvas in browser to rerender with updated tasks
      onSave(createTaskData)
    }
  }, [createTaskData])

  // ...
  useEffect((): void => {
    if (createTaskAsgnData) {
      /* If a task has an assignee, cause TasksList and MapCanvas in browser to rerender
      with updated tasks after the assignee is created */
      onSave(createTaskData)
    }
  }, [createTaskAsgnData])

  const $value = (name: string, newValue: any): void => {
    if (newValue !== undefined &&
      newValue !== null &&
      !Number.isNaN(newValue)) {
      value.current[name] = newValue;
    }
  }

  const $status = (name: keyof IInputStatus, newStatus: IInputStatus) => {
    status.current[name] = newStatus
  }

  const $save = (): void => {
    const hasError = Object.values(status.current).some((value) => value.status === EInputStatus.Error)

    if (hasError) {
      setShowStatusUntouched(true)
    } else {
      const [{ id: facilityId }] = GetItem('facilities', EStorage.EphemeralStorage)

      const _spaceId = value.current.location
        ? Number(value.current.location.id)
        : undefined

      const _cropId = value.current.crop
        ? Number(value.current.crop.id)
        : undefined

      const _inventoryId = inventoryId
        ? Number(inventoryId)
        : undefined

      const _containerId = containerId
        ? Number(containerId)
        : undefined

      const _positionX = positionX
        ? Number(positionX)
        : undefined

      const _positionY = positionY
        ? Number(positionY)
        : undefined

      createLocProf({
        variables: {
          facilityId: Number.parseInt(facilityId, 10),
          spaceId: _spaceId,
          cropId: _cropId,
          inventoryId: _inventoryId,
          containerId: _containerId,
          positionX: _positionX,
          positionY: _positionY
        }
      })
    }
  }

  let className = 'Theme__Light Card CreateTaskCard'
  if (outlined) className += ' Outlined'
  if (resting) className += ' Resting'
  if (raised) className += ' Raised'
  if (animated) className += ' Animated'

  return (
    <article className={className}>
      <div className='Head'>
        <div className='Row'>
          <button type='button' onClick={onBack}>
            <FontAwesomeIcon icon={faTimes} />
          </button>
          <h6 className='Primary'>Create Task</h6>
        </div>
      </div>
      <div className='Body'>
        <SearchSelect
          animated={animated}
          label='Type *'
          inputFacade={TextFacade}
          selectFacade={SelectFacade}
          itemFacade={FlagFacade}
          readOnly={false}
          name='type'
          initialValue={initialValue.type}
          showStatusUntouched={showStatusUntouched}
          onValueChange={$value}
          onStatusChange={$status}
          core={flagCore.current}
        />

        <Input
          animated={animated}
          label='Title *'
          facade={TextFacade}
          readOnly={false}
          name='title'
          initialValue={initialValue.title}
          showStatusUntouched={showStatusUntouched}
          onValueChange={$value}
          onStatusChange={$status}
          core={RequiredTextCore}
        />

        {spaceId && (
          <SearchSelect
            animated={animated}
            label='Location *'
            inputFacade={TextFacade}
            selectFacade={SelectFacade}
            itemFacade={SpaceFacade}
            name='location'
            readOnly={false}
            initialValue={initialValue.location}
            showStatusUntouched={showStatusUntouched}
            onValueChange={$value}
            onStatusChange={$status}
            core={spaceCore.current}
          />
        )}

        {cropId && (
          <SearchSelect
            animated={animated}
            label='Crop *'
            inputFacade={TextFacade}
            selectFacade={SelectFacade}
            itemFacade={CropFacade}
            name='crop'
            readOnly={false}
            initialValue={initialValue.crop}
            showStatusUntouched={showStatusUntouched}
            onValueChange={$value}
            onStatusChange={$status}
            core={cropCore.current}
          />
        )}

        <SearchSelect
          animated={animated}
          label='Assignee'
          inputFacade={TextFacade}
          selectFacade={SelectFacade}
          itemFacade={CustomerUserFacade}
          name='assignee'
          readOnly={false}
          initialValue={initialValue.assignee}
          showStatusUntouched={showStatusUntouched}
          onValueChange={$value}
          onStatusChange={$status}
          core={customerUserCore.current}
        />

        <Input
          animated={animated}
          label='Created On'
          facade={DateTimeFacade}
          name='createdOn'
          initialValue={new Date()}
          showStatusUntouched={showStatusUntouched}
          onValueChange={$value}
          onStatusChange={$status}
          core={DateCore}
          readOnly
        />

        <Input
          animated={animated}
          label='Due'
          facade={DateTimeFacade}
          readOnly={false}
          name='due'
          initialValue={initialValue.due}
          showStatusUntouched={showStatusUntouched}
          onValueChange={$value}
          onStatusChange={$status}
          core={DateCore}
        />

        <div className='Row'>
          <p className='Body1 Secondary'>Urgent</p>
          <div className='Space' />
          <Checkbox
            animated
            name='urgent'
            initialValue={initialValue.urgent}
            onInput={$value}
            readOnly={false}
          />
        </div>

        <Input
          animated={animated}
          label='Description'
          facade={TextAreaFacade}
          readOnly={false}
          name='description'
          initialValue={initialValue.description}
          showStatusUntouched={showStatusUntouched}
          onValueChange={$value}
          onStatusChange={$status}
          core={TextCore}
        />
      </div>

      <div style={{ flex: '1 0 auto' }} />

      <div className='Actions'>
        <Button
          animated={animated}
          outlined
          onClick={$save}
        >
          Save
        </Button>
      </div>
    </article>
  )
}

export default CreateTaskCard

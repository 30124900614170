// GraphQL
import { gql } from '@apollo/client'

const UpdateTaskIssue = gql`
  mutation(
    $id: ID!,
    $priority: Int,
    $convertToTask: Boolean,
    $rejectIssue: Boolean
  ) {
    TaskIssue {
      update(input: {
        id: $id,
        priority: $priority,
        convertToTask: $convertToTask,
        rejectIssue: $rejectIssue
      }) {
        id
        updatedAt

        priority
        createdBy
        convertedToTaskAt
        rejectedAt
        customerTaskCategory {
          id
          icon
          color
          title
          description
        }
        
        locationProfile {
          id
          updatedOn

          spaceId
          cropId
          inventoryId
        }

        createdByUser {
          id

          name
          email
          username
        }
        
      }
    }
  }
`

export { UpdateTaskIssue }

// Types
import IAction from 'arrayview/interfaces/IAction';

// Utils
import { GetItem, EStorage, clearStorage } from 'utils/storage';

// React Libs
import GoTo from 'react-lib/go-to';
import history from 'react-lib/history';

// Font Awesome
import {
  faArrowLeft,
  faSignOut
} from '@fortawesome/pro-light-svg-icons';
import iunuLogo from 'ui-lib/font-awesome/iunu-logo-2';

// Routes
import { SelectCustomerRoutes } from 'authorization/components/select-customer';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import IRoute from 'ui-lib/interfaces/IRoute';

const SwitchCustomer: IAction<IRoute> = {
  id: 'action:switch-customer',
  icon: iunuLogo as IconProp,
  name: 'Switch Customer',
  color: null,
  applicableFunc: () => true,
  func: async () => {
    const user = GetItem('user', EStorage.EphemeralStorage)
    const admin = user.admin

    if (admin) {
      GoTo(SelectCustomerRoutes[0])
    }
  }
}

const Back: IAction<IRoute> = {
  id: 'action:back',
  icon: faArrowLeft,
  name: 'Back',
  color: null,
  applicableFunc: () => true,
  func: async () => history.goBack()
}

const SignOut: IAction<IRoute> = {
  id: 'action:sign-out',
  icon: faSignOut,
  name: 'Sign Out',
  color: null,
  applicableFunc: () => true,
  func: async () => {
    const signOut = confirm('Are you sure you want to sign out?')
    if (signOut) {
      clearStorage()
      history.push('/')
      // For clearing in memory cache
      window.location.href = window.location.origin
      console.log('signing out')
    }
  }
}

export {
  SwitchCustomer,
  Back,
  SignOut
}

import React, { useEffect, useContext } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faComment } from '@fortawesome/pro-light-svg-icons';

import GoTo from 'react-lib/go-to';
import IAction from 'arrayview/interfaces/IAction';
import IRoute from '../../interfaces/IRoute';
import FacilitySwitcher from '../facility-switcher';
import { SignOut, SwitchCustomer } from 'utils/actions';

import {
  MapRoute,
  FacilityRoute,
  CropsRoute,
  TasksRoute,
  Report
} from 'utils/routes';
import Teletransport from '../unified-app/Teletransport';

import Has from 'utils/has';
import { EStorage } from 'utils/storage';
import useStorage from 'react-lib/use-storage';

// Config
import config from 'config';
import NavigationContext from 'context/navigation';
import INotification from 'graphql-lib/interfaces/INotification';
interface ITopNavigationProps {
  animated: boolean;

  primaryAction: IAction<IRoute>;
  secondaryActions: IAction<IRoute>[];

  title: string;
  routes: IRoute[];
  notifications: Array<INotification>;
}

const TopNavigation = ({
  animated,

  primaryAction,
  secondaryActions,

  title,
  routes,
  notifications
}: ITopNavigationProps): JSX.Element => {
  const className = animated ? 'TopNavigation Animated' : 'TopNavigation'

  const numTaskAssgnNotifs = (notifications || []).filter(
    (n): boolean => !n.acknowledged && n.objectType === 'task'
  ).length

  const taskAssgnNotifsStr = numTaskAssgnNotifs > 9 ? '9+' : numTaskAssgnNotifs

  const handleZenDeskToggler = () => {
    if (config.zenDeskId && global.zE) {
      const isHidden = global.zE('webWidget:get', 'display') === 'hidden'
      global.zE.activate()
      global.zE('webWidget', isHidden ? 'show' : 'hide')
    }
  }

  const [customer] = useStorage('customer', EStorage.EphemeralStorage)
  const [facilities] = useStorage('facilities', EStorage.EphemeralStorage);
  const [user] = useStorage('user', EStorage.EphemeralStorage);
  const isAdmin = Has(user.admin);
  const supportsMapView =
    Has(facilities[0].geojson) &&
    Has(facilities[0].dcs) &&
    facilities[0].dcs.environments?.includes(config.code)

  const supportsReport =
    Has(customer.appConfig.report) &&
    customer.appConfig.report.length > 0 &&
    Array.isArray(customer.appConfig.report)

  const {
    setPrimaryAction,
    setSecondaryActions,

    setTitle,
    setRoutes
  } = useContext(NavigationContext)

  useEffect((): void => {
    const newRoutes = [FacilityRoute, CropsRoute, TasksRoute]

    if (supportsMapView) {
      newRoutes.unshift(MapRoute)
    }

    if (supportsReport) {
      newRoutes.unshift(Report)
    }

    setPrimaryAction(SwitchCustomer)
    setSecondaryActions([])
    setRoutes(newRoutes)
  }, [])

  return (
    <div className={className}>
      {primaryAction && (
        <button
          key={primaryAction.id}
          className="Action Main"
          onClick={() => {
            primaryAction.func(primaryAction, [])
          }}
        >
          <FontAwesomeIcon icon={primaryAction.icon} />
        </button>
      )}

      <FacilitySwitcher title={title} />

      <div className="Space" />

      {routes &&
        routes.map((route): JSX.Element => {
          let _className = 'Route'
          if (route.active) _className += ' Active'
          if (route.disabled) _className += ' Disabled'

          const $click = (): void => {
            if (!route.disabled) {
              GoTo(route.route)
            }
          }
          const linkName = (route.id === TasksRoute.id) && (typeof route.name !== 'string') ? route.name(isAdmin) : route.name;
          return (
            <div key={route.id} className={_className} onClick={$click}>
              <FontAwesomeIcon icon={route.icon} />
              <p className="Name">{linkName}</p>
              {route.id === TasksRoute.id && numTaskAssgnNotifs > 0 && (
                <div className="BlueDot">{taskAssgnNotifsStr}</div>
              )}
              {route.beta && <label className="Beta">Beta</label>}
            </div>
          )
        })}

      {secondaryActions &&
        secondaryActions.map(
          (action): JSX.Element => (
            <button
              key={action.id}
              className="Action"
              onClick={() => {
                action.func(action, [])
              }}
            >
              <FontAwesomeIcon icon={action.icon} />
            </button>
          )
        )}

      <div className="Route" onClick={handleZenDeskToggler}>
        <FontAwesomeIcon icon={faComment} />
        <p className="Name">Chat</p>
      </div>

      <button
        key={SignOut.id}
        className="Action"
        onClick={() => {
          SignOut.func(SignOut)
        }}
      >
        <FontAwesomeIcon icon={SignOut.icon} />
      </button>

      <Teletransport />
    </div>
  )
}

export type { ITopNavigationProps }
export default TopNavigation

// Utils
import { EStorage } from 'utils/storage'

// Libs
import { v4 } from 'uuid'
import { parse } from 'query-string'

// React
import React, {
  CSSProperties,
  useContext,
  useEffect,
  useState
} from 'react'

// React Libs
import { useDebounce } from 'use-debounce'
import { toast } from 'react-toastify'
import { VariableSizeList as List } from 'react-window'
import useStorage from 'react-lib/use-storage'

// GraphQL
import { gql, useQuery } from '@apollo/client'

// Contexts
import NavigationContext from 'context/navigation'

// Actions & Navigation
import {
  CreateTask as CreateTaskAction,
  ExportTasks as ExportTasksAction
} from '../../actions/navigation'

// Routes
import {
  Tasks,
  CreateTask,
  ViewTask
} from '../../utils/routes'

// Columns
import {
  CreatedOnColumn,
  UpdatedOnColumn,
  NameColumn,
  DueDateColumn,
  FlagColumn,
  AssigneeColumn
} from 'graphql-lib/columns/task'

// Filters
import {
  All as AllFilter,
  Alert as AlertFilter,
  CreatedByMe as CreatedByMeFilter,
  AssignedToMe as AssignedToMeFilter,
  Open as OpenFilter,
  Completed as CompletedFilter
} from '../../filters/task'

// Utils
import GoTo from 'react-lib/go-to'

// UI Lib
import DetailsView from 'ui-lib/components/details-view';
import ListHeader from 'ui-lib/components/list-header';
import TaskCard from '../task-card';
import CreateTaskCard from '../create-task-card';
import ViewTaskCard from '../view-task-card';
import ITask from 'graphql-lib/interfaces/ITask';
import { ITab } from 'ui-lib/components/tab';
import IFilter from 'arrayview/interfaces/IFilter';
import IColumn from 'arrayview/interfaces/IColumn';
import AutoSizer from 'react-virtualized-auto-sizer';

const QueryTasks = gql`
  query($facilityIds: [ID], $offset: Int, $limit: Int) {
    task(facilityIds: $facilityIds, offset: $offset, limit: $limit) {
      id

      name
      priority
      complete

      createdOn
      dueDate
      flagId

      calendarEvent {
        id,
        nextDueTime
      }
      completion {
        id
      }
      taskAssignee {
        customerUser {
          id

          name
          username
          email
        }
      }

      flag {
        id

        icon
        color

        title
        description

        flagCategory {
          id
          name
        }
      }

      locationProfile {
        id

        inventoryId
        positionX
        positionY
      }
    }
  }
`

const Routes = [
  Tasks,
  CreateTask,
  ViewTask
]

const Columns = [
  CreatedOnColumn,
  UpdatedOnColumn,
  NameColumn,
  DueDateColumn,
  FlagColumn,
  AssigneeColumn
]

const Filters = [
  AllFilter,
  AlertFilter,
  CreatedByMeFilter,
  AssignedToMeFilter,
  OpenFilter,
  CompletedFilter
]

export interface ITasksViewProps {
  match: {
    params: {
      id: number
    },
    path: string
  },
  tabs: ITab[],
  setTaskOrIssue: () => void | undefined
}

const TasksView = ({
  match: {
    params: { id },
    path
  }
}: ITasksViewProps): JSX.Element => {
  // ┌─────────────────────────────────────────────────────────────────────────┐
  // │ CONTEXT                                                                 │
  // └─────────────────────────────────────────────────────────────────────────┘

  // Navigation Context
  const {
    setSecondaryActions,
    setTitle
  } = useContext(NavigationContext)

  // ┌─────────────────────────────────────────────────────────────────────────┐
  // │ PARAMS                                                                  │
  // └─────────────────────────────────────────────────────────────────────────┘

  const {
    flagId,
    spaceId,
    cropId,
    positionX,
    positionY
  } = parse(location.search)

  // ┌─────────────────────────────────────────────────────────────────────────┐
  // │ STATE                                                                   │
  // └─────────────────────────────────────────────────────────────────────────┘

  const [facilities] = useStorage('facilities', EStorage.EphemeralStorage)

  // GQL Data
  const [offset, setOffset] = useState(0)
  const [limit, setLimit] = useState(100)
  const [atEnd, setAtEnd] = useState(false)
  const [data, setData] = useState<Array<ITask>>()

  const [search, setSearch] = useState<string>('')
  const [debouncedSearch] = useDebounce(search, 300)
  const [filter, setFilter] = useState<IFilter<ITask>>(AllFilter)
  const [column, setColumn] = useState<IColumn<ITask>>(CreatedOnColumn)
  const [descending, setDescending] = useState<boolean>(true)

  const [searchedData, setSearchedData] = useState<Array<ITask>>()
  const [filteredData, setFilteredData] = useState<Array<ITask>>()
  const [sortedData, setSortedData] = useState<Array<ITask>>([])

  const [_, rerender] = useState<string>()

  // ┌─────────────────────────────────────────────────────────────────────────┐
  // │ GRAPHQL                                                                 │
  // └─────────────────────────────────────────────────────────────────────────┘

  const {
    loading: gqlLoading,
    error: gqlError,
    data: gqlData,
    variables: {
      offset: gqlOffset,
      limit: gqlLimit
    }
  } = useQuery(QueryTasks, {
    skip: !facilities || facilities.length === 0,
    variables: {
      facilityIds: [facilities?.[0]?.id],
      offset,
      limit
    },
    fetchPolicy: 'no-cache'
  })

  // ┌─────────────────────────────────────────────────────────────────────────┐
  // │ EFFECTS                                                                 │
  // └─────────────────────────────────────────────────────────────────────────┘

  // Setup Navigation Context
  useEffect((): void => {
    setSecondaryActions([
      ExportTasksAction,
      CreateTaskAction
    ])
    setTitle(null)
  }, [])

  // Display Error from GQL Error
  useEffect((): void => {
    if (gqlError) {
      toast.error("Couldn't load tasks; please try again in a few seconds.")
    }
  }, [gqlError])

  // Update Data from GQL Data
  useEffect((): void => {
    if (!gqlLoading && gqlData) {
      const {
        task: newData
      } = gqlData

      // If offset is 0 overwrite data,
      // else append to data.
      if (gqlOffset === 0) {
        setData(newData)
      } else {
        const _newData = [...data, ...newData]
        setData(_newData)
      }

      if (newData.length === 0) {
        setAtEnd(true)
      }
    }
  }, [gqlData])

  // Update Searched Data from Data and/or Search
  useEffect((): void => {
    if (data) {
      const newSearchedData = data.filter((d): boolean =>
        Columns.some((c): boolean =>
          c.searchFunc(c, debouncedSearch, d)))

      setSearchedData(newSearchedData)
    }
  }, [data, debouncedSearch])

  // Update Filtered Data from Searched Data and/or Filter
  useEffect((): void => {
    if (searchedData) {
      const newFilteredData = searchedData.filter((d): boolean =>
        filter.func(filter, d))

      setFilteredData(newFilteredData)
    }
  }, [searchedData, filter])

  // Update Sorted Data from Filtered Data and/or Column
  useEffect((): void => {
    if (filteredData) {
      const sortFunc = descending
        ? column.descendingSortFunc
        : column.ascendingSortFunc

      const newSortedData = [...filteredData].sort((a, b): number =>
        sortFunc(column, a, b))

      setSortedData(newSortedData)
      rerender(v4())
    }
  }, [filteredData, column, descending])

  // ┌─────────────────────────────────────────────────────────────────────────┐
  // │ CALLBACKS                                                               │
  // └─────────────────────────────────────────────────────────────────────────┘

  const $back = (): void => GoTo(Tasks)

  const $save = (createTaskData: {Task: {create: ITask}}): void => {
    // Update the local task view with the new task
    const newTask: ITask = createTaskData.Task.create
    const updatedTasks = [newTask, ...data]
    setData(updatedTasks)

    // Select the task and open the task card details card
    GoTo(ViewTask.replace(':id', String(newTask.id)))
  }

  const $nextPage = (pageLength?: number): void => {
    const newLimit = pageLength || limit
    const newOffset = offset + limit

    if (!atEnd && !gqlLoading) {
      setOffset(newOffset)
      setLimit(newLimit)
    }
  }

  const $search = (newSearch: string): void =>
    setSearch(newSearch)

  const $filter = (newFilter: any): void =>
    setFilter(newFilter)

  const $column = (newColumn: any): void =>
    setColumn(newColumn)

  const $descending = (newDescending: boolean): void =>
    setDescending(newDescending)

  const $click = (data: any): void => {
    const to = ViewTask.replace(':id', data.id)
    GoTo(to)
  }

  const $itemSize = (index: number): number => {
    const margin = index === 0
      ? 16
      : 8

    return 54 + 16 + margin
  }

  const $item = (params: { index: number, style: CSSProperties }): JSX.Element => {
    const { index, style } = params;

    if (!sortedData) {
      return;
    }

    const item = sortedData[index];

    const width = 'calc(100% - 32px)'
    const margin = index === 0
      ? '8px'
      : '0px 8px 8px'

    const _style = Object.assign(
      {},
      style,
      {
        width,
        height: '54px',
        margin
      }
    )
    return (
      <TaskCard
        outlined
        animated
        data={item}
        selectedId={id}
        style={_style}
        onClick={$click}
      />
    )
  }

  const $itemsRendered = (param: {visibleStopIndex: number}): void => {
    if (!sortedData) {
      return;
    }
    const { visibleStopIndex } = param
    if (visibleStopIndex === sortedData.length - 1) {
      $nextPage()
    }
  }

  // Updates the flags view with the updated task on save
  const updateFlagsViewTask = (updatedTask: ITask): void => {
    const selectedTaskIndex = data.findIndex((task: ITask) => task.id === id)

    if (selectedTaskIndex > -1) {
      const updatedTasks = [...data]
      updatedTasks[selectedTaskIndex] = updatedTask
      setData(updatedTasks)
    }
  }

  // ┌─────────────────────────────────────────────────────────────────────────┐
  // │ RENDER                                                                  │
  // └─────────────────────────────────────────────────────────────────────────┘

  const isCreateTask = path === CreateTask
  const isViewTask = path === ViewTask

  const showDetails =
    isCreateTask ||
    isViewTask

  let details = null
  if (isCreateTask) {
    details = (
      <CreateTaskCard
        resting
        animated

        flagId={flagId}
        spaceId={spaceId}
        cropId={cropId}
        positionX={positionX}
        positionY={positionY}
        appliedToView={'map'}
        onBack={$back}
        onSave={$save}
      />
    )
  }

  if (isViewTask) {
    details = (
      <ViewTaskCard
        resting
        animated

        id={id}
        onBack={$back}
        updateFlagsViewTask={updateFlagsViewTask}
      />
    )
  }

  return (
    <DetailsView
      animated
      showDetails={showDetails}

      main={
        <div className='TasksView'>
          <ListHeader
            animated

            filters={Filters}
            columns={Columns}

            search={search}
            filter={filter}
            column={column}
            descending={descending}

            onSearch={$search}
            onFilter={$filter}
            onColumn={$column}
            onDescending={$descending}
          />

          {sortedData && (
            <div className='AutoSizerParent'>
              <AutoSizer>
                {({ width, height }: { width: number, height: number }): JSX.Element => (
                  <List
                    width={width}
                    height={height}
                    itemData={sortedData}
                    itemCount={sortedData.length}
                    itemSize={$itemSize}
                    onItemsRendered={$itemsRendered}
                  >
                    {$item}
                  </List>
                )}
              </AutoSizer>
            </div>
          )}
        </div>
      }

      details={details}
    />
  )
}

export {
  Routes as TasksViewRoutes
}

export default TasksView

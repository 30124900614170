import m from 'moment-timezone'

/* React */
import React, {
  useContext,
  useEffect,
  useRef,
  useState
} from 'react'

import { toast } from 'react-toastify'

/* GraphQL */
import { gql, useQuery } from '@apollo/client'

import NavigationContext from 'context/navigation'
import {
  FacilityRoute as _FacilityRoute,
} from 'utils/routes'

import { EStorage } from 'utils/storage'
import useStorage from 'react-lib/use-storage'
import IFacility from 'graphql-lib/interfaces/IFacility'
import IWeather from 'graphql-lib/interfaces/IWeather'
import IWeatherForecast from 'graphql-lib/interfaces/IWeatherForecast'
import ID from 'graphql-lib/interfaces/ID'

const QueryFacility = gql`
  query($id: ID) {
    facility(id: $id) {
      name
      timezone
      imageUrl
    }
  }
`

const QueryWeather = gql`
  query(
    $id: ID,
    $startDate: DateTime,
    $endDate: DateTime
  ) {
    facility(id: $id) {
      id

      weather(
        startDate: $startDate,
        endDate: $endDate,
        limit: 1
      ) {
        humidity
        temperature
        windSpeed
        precipitation
        uvIndex
      }
    }
  }
`

const QueryWeatherForecast = gql`
  query(
    $id: ID,
    $startDate: DateTime,
    $endDate: DateTime
  ) {
    facility(id: $id) {
      timezone

      weatherForecast(
        startDate: $startDate,
        endDate: $endDate
      ) {
        id
        forecastOn
        
        icon
        tempMin
        tempMax
      }
    }
  }
`

export interface IFacilityHeaderProps {
  id: ID
}

const FacilityHeader = ({
  id
}: IFacilityHeaderProps): JSX.Element => {
  const [customer] = useStorage('customer', EStorage.EphemeralStorage)
  const temperatureMetric = customer.temperatureMetric

  const isF = temperatureMetric === 'fahrenheit'

  /**
   * Navigation Context
   */
  const {
    setTitle,
    setSecondaryActions
  } = useContext(NavigationContext)

  const now = useRef<string>(m().toISOString())
  const yesterday = useRef<string>(m().subtract(1, 'day').toISOString())
  const next3Days = useRef<string>(m().add(3, 'day').toISOString())

  const [facility, setFacility] = useState<IFacility>()
  const [weather, setWeather] = useState<IWeather>()
  const [weatherForecast, setWeatherForecast] = useState<Array<IWeatherForecast>>()

  /**
   * Query a facility
   */
  const {
    error: facilityError,
    data: facilityData
  } = useQuery(QueryFacility, { variables: { id } })

  /**
   * Query weather
   */
  const {
    error: weatherError,
    data: weatherData
  } = useQuery(QueryWeather, {
    variables: {
      id,
      startDate: yesterday.current,
      endDate: now.current
    }
  })

  /**
   * Query weather forecast
   */
  const {
    error: weatherForecastError,
    data: weatherForecastData
  } = useQuery(QueryWeatherForecast, {
    variables: {
      id,
      startDate: now.current,
      endDate: next3Days.current
    }
  })

  useEffect((): void => {
    setSecondaryActions([]);
  }, [])

  useEffect((): void => {
    if (facility) {
      setTitle(facility.name)
    }
  }, [facility])

  useEffect((): void => {
    if (facilityData && !facility) {
      const newFacility = facilityData.facility[0]
      setFacility(newFacility)
    }
  }, [facilityData])

  useEffect((): void => {
    if (weatherData && !weather) {
      const newFacility = weatherData.facility[0]
      const newWeather = { ...newFacility.weather[0] }
      if (isF) {
        newWeather.temperature = (newWeather.temperature * (9 / 5) + 32).toFixed(1)
      }
      setWeather(newWeather)
    }
  }, [weatherData])

  useEffect((): void => {
    if (weatherForecastData) {
      const newFacility = weatherForecastData.facility[0]
      const newWeatherForecast = newFacility.weatherForecast
        .map((forecast: IWeatherForecast) => {
          if (isF) {
            const retVal = Object.assign(
              {},
              forecast
            )

            retVal.tempMin = retVal.tempMin * (9 / 5) + 32
            retVal.tempMax = retVal.tempMax * (9 / 5) + 32

            return retVal
          } else {
            return forecast
          }
        })
        .reduce((acc: Array<Partial<IWeatherForecast>>, cur: IWeatherForecast) => {
          const retVal = [...acc]

          const day = m.tz(cur.forecastOn, newFacility.timezone).startOf('day')
          const dayUNIX = day.valueOf()

          let dayForecast = acc.find(a => Number(a.forecastOnUNIX) === Number(dayUNIX))
          if (dayForecast) {
            if (cur.tempMin < dayForecast.tempMin) {
              dayForecast.tempMin = cur.tempMin
            }

            if (cur.tempMax > dayForecast.tempMax) {
              dayForecast.tempMax = cur.tempMax
            }
          } else {
            dayForecast = {
              forecastOn: day.toDate(),
              forecastOnUNIX: dayUNIX,
              icon: cur.icon,
              tempMin: cur.tempMin,
              tempMax: cur.tempMax
            }

            retVal.push(dayForecast)
          }

          return retVal
        }, [])
        .reverse()

      setWeatherForecast(newWeatherForecast)
    }
  }, [weatherForecastData])

  /**
   * TODO: Write Documentation
   */
  useEffect((): void => {
    if (facilityError) {
      toast.error("Couldn't load facility, please try again in a few seconds.")
    }
  }, [facilityError])

  /**
   * TODO: Write Documentation
   */
  useEffect((): void => {
    if (weatherError) {
      toast.error("Couldn't load weather, please try again in a few seconds.")
    }
  }, [weatherError])

  /**
   * TODO: Write Documentation
   */
  useEffect((): void => {
    if (weatherForecastError) {
      toast.error("Couldn't load weather forecast, please try again in a few seconds.")
    }
  }, [weatherForecastError])

  if (facility) {
    const style = {
      background: `url(${facility.imageUrl}) center / cover`
    }

    return (
      <article
        style={style}
        className='FacilityHeader'
      >
        <div className='Content'>
          <div className='Section'>
            <p className='Heading6 Primary'>Local Time</p>
            <p className='Body1 Primary'>
              {facility ? m.tz(facility.timezone).format('LT') : '-'}
            </p>
          </div>

          <div className='Section'>
            <p className='Heading6 Primary'>Current Weather</p>
            <div className='Columns'>
              <div className='Column'>
                <p className='Caption Primary'>UV Index</p>
                <p className='Body1 Primary'>
                  {weather ? weather.uvIndex : '-'} / 10
                </p>

                <p className='Caption Primary'>Temperature</p>
                <p className='Body1 Primary'>
                  {weather ? weather.temperature : '-'} °{isF ? 'F' : 'C'}
                </p>
              </div>

              <div className='Column'>
                <p className='Caption Primary'>Wind Speed</p>
                <p className='Body1 Primary'>
                  {weather ? weather.windSpeed : '-'} km/h
                </p>

                <p className='Caption Primary'>Humidity</p>
                <p className='Body1 Primary'>
                  {weather ? weather.humidity : '-'} %
                </p>
              </div>

              <div className='Column'>
                <p className='Caption Primary'>Precipitation</p>
                <p className='Body1 Primary'>
                  {weather ? weather.precipitation : '-'} mm
                </p>
              </div>
            </div>
          </div>

          <div className='Section'>
            <p className='Heading6 Primary'>Weather Forecast</p>
            <div className='Columns'>
              {weatherForecast && weatherForecast.map((forecast): JSX.Element => (
                <div key={forecast.forecastOnUNIX} className='Column WeatherForecast'>
                  <p className='Body1 Primary'>
                    {m.tz(forecast.forecastOn, facility.timezone).format('ddd')}
                  </p>

                  <img
                    className={`i${forecast.icon}`}
                    src={`https://openweathermap.org/img/wn/${forecast.icon}@2x.png`}
                  />

                  <p className='Body1 Primary'>
                    {forecast.tempMin.toFixed(1)} / {forecast.tempMax.toFixed(1)} °{isF ? 'F' : 'C'}
                  </p>
                </div>
              ))}
            </div>
          </div>
        </div>
      </article>
    )
  }

  return <article className='FacilityHeader Loading' />
}

export default FacilityHeader

/**
 * @author Miras Absar <mabsar@iunu.com>
 */

// Types
import EEntity from 'map-view/enums/entity'
import EChart from 'map-view/enums/chart'

// Utils
import Has from 'utils/has'
import { ChartOrder } from 'map-view/utils/chart'

// Libs
import { format } from 'date-fns'
import { createDate } from 'utils/dates'
import IEntity from 'map-view/interfaces/entity'
import ISpace from 'graphql-lib/interfaces/ISpace'
import IContainer from 'graphql-lib/interfaces/IContainer'
import IContainerStich from 'graphql-lib/interfaces/IContainerStitch'
import IChart from 'map-view/interfaces/chart'

const DateFormat = 'M/d/yy'

const UpdateState = ({
  selectedEntities,
  spaces,
  container,
  containerStitches,
  containerCharts,
  setSelected,
  setSeen,
  setTag,
  setCharts
}: {
  selectedEntities: Array<IEntity>
  spaces: Array<ISpace>
  container: IContainer
  containerStitches: Array<IContainerStich>
  containerCharts: Array<IChart>
  setSelected: (value: boolean) => void
  setSeen: (value: string) => void
  setTag: (value: string) => void
  setCharts: (value: Array<IChart>) => void
}): void => {
  if (
    !selectedEntities ||
    !spaces ||
    !container ||
    !containerStitches ||
    !containerCharts
  ) {
    return
  }

  const newSelected = !!selectedEntities
    .find(entity =>
      entity.type === EEntity.Container &&
      String(entity.id) === String(container.id))

  const containerStitch = containerStitches
    .find(containerStitch =>
      String(containerStitch.inventoryId) === String(container.inventoryId) &&
      String(containerStitch.containerId) === String(container.id) &&
      Has(containerStitch.width) &&
      Has(containerStitch.height) &&
      Has(containerStitch.tilesUrl))

  const space = spaces
    .find(space => String(space.id) === String(containerStitch?.spaceId))

  let newSeen = 'Never Seen'
  let newTag = 'No Tag'
  if (
    containerStitch &&
    space
  ) {
    newSeen = `Last Seen ${format(createDate(containerStitch.lastImageCreatedOn), DateFormat)} - ${space.name}`;

    newTag = containerStitch.locatorCode
  }

  const newCharts = containerCharts
    .filter(chart =>
      (
        chart.parentType === EEntity.Space &&
        String(chart.parentID) === String(space?.id)
      ) ||
      (
        chart.parentType === EEntity.Container &&
        String(chart.parentID) === String(container.id)
      )
    )
    .sort((chartA, chartB) =>
      ChartOrder[chartA.chart] - ChartOrder[chartB.chart])

  setSelected(newSelected)
  setSeen(newSeen)
  setTag(newTag)
  setCharts(newCharts)
}

export { UpdateState }

// Styles
import 'ol/ol.css'

// Types
import EEntity from '../../enums/entity'
import ETab from '../../enums/tab'

// Libs
import Debug from 'debug'
import { v4 } from 'uuid'
import { ScalePT, UnscalePT } from './utils'

// React
import React, { useCallback, useContext, useRef, useState, useMemo, useEffect } from 'react'

// React Libs
import { useEvent } from 'react-lib/use-event'
import { useIter } from 'react-lib/use-iter'

// OpenLayers
import OLView from '../ol-view'
import Map from 'ol/Map'
import Collection from 'ol/Collection'
import LayerGroup from 'ol/layer/Group'
import VectorLayer from 'ol/layer/Vector'
import View from 'ol/View'

// Effects
import {
  InitOpenLayers,
  UpdateOpenLayersSize,
  UpdateOpenLayersExtent,
  UpdateFacilityLayers,
  UpdateBuildingLayers,
  UpdateSpaceLayers,
  UpdateInventoryVectorLayers,
  UpdateInventoryRasterLayers,
  UpdateInventoryHeatmapLayers,
  UpdateContainerVectorLayers,
  UpdateContainerRasterLayers,
  UpdateContainerHeatmapLayers,
  UpdateOverlays,
  UpdateVisibleEntities,
  UpdateInventoryRasterLayersVisibility,
  UpdateInventoryHeatmapLayersVisibility,
  UpdateContainerRasterLayersVisibility,
  UpdateContainerLayersByAge,
  UpdateOverlaysVisibility,
  UpdateHighlight,
  SelectEntityBySearchParams
} from './effects'

// Hooks
import useUser from 'graphql-lib/states/use-user'

// MapView
import Mobius from '../../contexts/mobius'
import { createPortal } from 'react-dom'
import { sortDates } from 'utils/dates'
import { differenceInDays } from 'date-fns'
import { SkeletonLoader } from 'ui-lib/utils/Placeholder'
import { ChartUnit } from 'map-view/utils/chart'
import EChart from 'map-view/enums/chart'
import { format, utcToZonedTime } from 'date-fns-tz'
import { IYieldPredictionAverageDataArrayProperty } from 'graphql-lib/interfaces/IYieldPredictionAverage'
import { Coordinate } from 'ol/coordinate'
import { Interaction } from 'ol/interaction'
import Control from 'ol/control/Control'
import { Overlay } from 'ol'
import Layer from 'ol/layer/Layer'
import BaseLayer from 'ol/layer/Base'
import ISpaceMeta from 'map-view/interfaces/space-meta'
import { IInventoryMeta } from 'map-view/interfaces/metas'
import IContainerMeta from 'map-view/interfaces/container-meta'
import ISpace from 'graphql-lib/interfaces/ISpace'
import ID from 'graphql-lib/interfaces/ID'
import Style from 'ol/style/Style'

export interface IMapCanvasProps {
  width: number
  height: number
}
export interface MouseEventForMap extends MouseEvent {
  originalEvent: MouseEvent
  coordinate: Coordinate
}

type BatchTileType = {
  type: EEntity.Facility | EEntity.Building | EEntity.Space | EEntity.Inventory | EEntity.Container
  id: ID
  parentType: string
  parentID: string
  meta: Record<string, any>
  zIndex: number
  spaceId: ID
}

type YieldForecastDataType = { value: number; totalWeight: number }

type InfoTipContentType = {
  batchId: ID
  varietyName: string
  serial: string
  cropStartDate: React.ReactNode
  cropTransplantDate: React.ReactNode
  cropEndDate: React.ReactNode
  daysOnSystem: React.ReactNode
  yieldForecast: YieldForecastDataType | undefined
  yieldForecastIsLoading: boolean
  yieldForecastTitle: string
  spaceName: string
  cropContainersTitle: string
  containersPerLocator: number
}

export interface BaseLayerForMap extends BaseLayer{
  setStyle?: any
  getSource?: any
}
export interface LayersForMap extends LayerGroup {
  type?: EEntity
  getSource?: () => string
  setStyle?: (param: Record<string, Style>) => Style
  getLayers: () => Collection<BaseLayerForMap>
}

const debug = Debug('MapCanvas:Index')

const repositionMapInfoTip = (mapInfoTip: HTMLDivElement, event: MouseEvent) => {
  const { width, height } = mapInfoTip.getBoundingClientRect()
  const offset = 15

  const bounds = {
    top: event.clientY,
    left: event.clientX,
    height: height,
    width: width
  }

  mapInfoTip.style.top = `${bounds.top + offset}px`
  mapInfoTip.style.left = `${bounds.left + offset}px`

  if (bounds.top + height > window.innerHeight) {
    mapInfoTip.style.top = `${bounds.top - offset - bounds.height}px`
  }

  if (bounds.left + width > window.innerWidth) {
    mapInfoTip.style.left = `${bounds.left - offset - bounds.width}px`
  }
}

const YieldForecastData = (
  props: React.HTMLAttributes<HTMLSpanElement> & { loading: boolean; data?: YieldForecastDataType }
): JSX.Element => {
  const { loading, data } = props
  const { value, totalWeight } = data ?? { value: 0, totalWeight: 0 }

  if (loading) {
    return <SkeletonLoader width={150} height={16} />
  }

  if (!value && !totalWeight) {
    return <span>N/A</span>
  }

  return (
    <>
      <span>
        {Math.round(value)} {ChartUnit[EChart.YieldPrediction]}
      </span>
      <span>
        {Math.round(totalWeight)} {ChartUnit[EChart.TotalWeight]}
      </span>
    </>
  )
}

const DaysOnSystem = (props: React.HTMLAttributes<HTMLSpanElement> & { days: number }): JSX.Element => {
  const { days } = props
  const daysSuffix = `${days === 1 ? 'day' : 'days'}`

  return (
    <span {...props}>
      {days >= 0
        ? (
        <>
          <span>
            {days} {daysSuffix} on system
          </span>
        </>
          )
        : (
        <span>N/A</span>
          )}
    </span>
  )
}

const MapCanvas = ({ width, height }: IMapCanvasProps): JSX.Element => {
  // Context ///////////////////////////////////////////////////////////////////

  const {
    searchParams: { searchParams, setSearchParams, searchParamsUUID },
    time,
    selectedEntities,
    visibleEntities,
    overlays: _overlays,
    zoom,

    facility,
    buildings,
    spaces,

    sensors,
    areacams,

    gridPredictions,
    inventories,
    containers,
    inventoryStitches,
    containerStitches,
    heatmaps,
    yieldPredictionAverages,

    tasks,
    taskIssues
  } = useContext(Mobius)
  // State /////////////////////////////////////////////////////////////////////

  const pointerDownTime = useRef<number>(Infinity)

  const mapRef = useRef<Map>()
  const view = useRef<View>()

  const interactions = useRef<Collection<Interaction>>(new Collection())
  const controls = useRef<Collection<Control>>(new Collection())
  const overlays = useRef<Collection<Overlay>>(new Collection())
  const layers = useRef<Collection<Layer>>(new Collection())
  const spaceMetas = useRef<ISpaceMeta>({})
  const inventoryMetas = useRef<IInventoryMeta>({})
  const containerMetas = useRef<IContainerMeta>({})

  const facilityLayers = useRef<LayersForMap>(new LayerGroup())
  const buildingLayers = useRef<LayersForMap>(new LayerGroup())
  const spaceLayers = useRef<LayersForMap>(new LayerGroup())
  const inventoryVectorLayers = useRef<LayersForMap>(new LayerGroup())
  const inventoryRasterLayers = useRef<LayersForMap>(new LayerGroup())
  const containerVectorLayers = useRef<LayersForMap>(new LayerGroup())
  const containerRasterLayers = useRef<LayersForMap>(new LayerGroup())
  const heatmapLayers = useRef<LayersForMap>(new LayerGroup())

  const [pointerDownTimeUUID, setPointerDownTimeUUID] = useState<string>()
  const [showMapInfoTip, setShowMapInfoTip] = useState<boolean>(false)
  const [infoTipContent, setInfoTipContent] = useState<InfoTipContentType>()
  const pointerIdleTimeout = useRef<ReturnType<typeof setTimeout> | null>(null)
  const mapInfoTip = useRef<HTMLDivElement>(null)

  const [mapRefUUID, setMapRefUUID] = useState<string>()
  const [viewUUID, setViewUUID] = useState<string>()

  const [interactionsUUID, setInteractionsUUID] = useState<string>()
  const [controlsUUID, setControlsUUID] = useState<string>()
  const [overlaysUUID, setOverlaysUUID] = useState<string>()
  const [layersUUID, setLayersUUID] = useState<string>()

  const [facilityLayersUUID, setFacilityLayersUUID] = useState<string>()
  const [buildingLayersUUID, setBuildingLayersUUID] = useState<string>()
  const [spaceLayersUUID, setSpaceLayersUUID] = useState<string>()
  const [inventoryVectorLayersUUID, setInventoryVectorLayersUUID] = useState<string>()
  const [inventoryRasterLayersUUID, setInventoryRasterLayersUUID] = useState<string>()
  const [containerVectorLayersUUID, setContainerVectorLayersUUID] = useState<string>()
  const [containerRasterLayersUUID, setContainerRasterLayersUUID] = useState<string>()
  const [heatmapLayersUUID, setHeatmapLayersUUID] = useState<string>()

  // Only admin users can create issues when long clicking (for our horticulturalists)
  // Get info on the current logged in user.
  const userId = useMemo(() => localStorage.getItem('userID'), []) as string
  const { loading, error, user, uuid } = useUser(userId, ['id', 'isAdmin'])

  const CropDate = (props: React.HTMLAttributes<HTMLSpanElement> & { date: Date }): JSX.Element => {
    const { date } = props

    const month = format(utcToZonedTime(date, 'UTC'), 'MMM')
    const day = format(utcToZonedTime(date, 'UTC'), 'd')

    return (
      <span {...props}>
        { date ? <span>{month} {day} </span> : <span>N/A</span>}
      </span>
    )
  }

  const getYieldPredictionInfoTipData = (inventoryId: ID) => {
    const yieldPredictionAverage = yieldPredictionAverages?.yieldPredictionAverages?.find(
      (yieldPredictionAverage) => String(yieldPredictionAverage.inventoryId) === String(inventoryId)
    )

    // The yieldPredictionAverage data comes in a set of 8 items, we only want the oldest
    // non-zero set available.
    const data = yieldPredictionAverage?.data as IYieldPredictionAverageDataArrayProperty
    return data
      ? data.filter((item) => item.value && item.totalWeight).pop()
      : undefined
  }

  // Events ////////////////////////////////////////////////////////////////////

  useEvent(
    window,
    'MapCanvas:ResetZoom',
    (): void => {
      if (!facilityLayers.current || !view.current) {
        return
      }

      const facilityLayer = facilityLayers.current
        .getLayers()
        .getArray()
        .find((layer) => layer instanceof VectorLayer)

      const extent = facilityLayer.getSource().getExtent()

      view.current.fit(extent, { duration: 300 })
    },

    [viewUUID, facilityLayersUUID]
  )

  // This add a listener when a key is pressed
  useEffect(() => {
    const keyDownHandler = (event: KeyboardEvent) => {
      if (event.key === 'Escape') {
        event.preventDefault()
        selectedEntities.setSelectedEntities([])
      }
    }

    interface EventTargetWithTagName extends EventTarget {
      tagName?: string
    }

    interface MouseEventWithTagName extends MouseEvent {
      target: EventTargetWithTagName
    }

    const mouseMoveHandler = (ev: MouseEventWithTagName) => {
      if (ev.target.tagName !== 'CANVAS') {
        setShowMapInfoTip(false)
        clearTimeout(pointerIdleTimeout.current as ReturnType<typeof setTimeout>)
      }
    }

    document.addEventListener('keydown', keyDownHandler)
    document.addEventListener('mousemove', mouseMoveHandler)
    return () => {
      document.removeEventListener('keydown', keyDownHandler)
      document.removeEventListener('mousemove', mouseMoveHandler)
    }
  }, [])

  useEffect(() => {
    const batchId = infoTipContent?.batchId
    if (batchId) {
      const yieldForecast = getYieldPredictionInfoTipData(String(batchId))

      setInfoTipContent({
        ...infoTipContent,
        yieldForecast: yieldForecast,
        yieldForecastIsLoading: yieldPredictionAverages?.yieldPredictionAveragesLoading
      })
    }
  }, [yieldPredictionAverages.yieldPredictionAveragesLoading])

  // Callbacks /////////////////////////////////////////////////////////////////
  const updateMapInfoTipWithIdle = ({ event, batchTile }:
    { event: MouseEventForMap
      batchTile: BatchTileType
    }) => {
    if (pointerIdleTimeout.current) {
      setShowMapInfoTip(false)
      clearTimeout(pointerIdleTimeout.current)
    }

    pointerIdleTimeout.current = setTimeout(() => {
      if (mapInfoTip.current) {
        repositionMapInfoTip(mapInfoTip.current, event.originalEvent)

        const inventory = inventories?.inventories?.find(
          (inventory) => batchTile.parentType === EEntity.Inventory && batchTile.parentID === inventory.id
        )
        const space: Partial<ISpace> = spaces?.spaces.find((space) => Number(space.id) === Number(batchTile.spaceId)) ?? {}

        if (inventory) {
          // Dates could be out of order if plans get introduced at germination time or transplant time. Because of this inconsistency we are going to sort the dates chronologically
          const cropDates = sortDates(
            [inventory.cropStartDate, inventory.cropTransplantDate, inventory.cropEndDate]
              .filter(Boolean)
              .map((date) => new Date(date))
          )

          if (!cropDates.length) {
            console.error(`No crop dates found for inventory ${inventory.id}`)
            return
          }

          const hasTransplantDate = cropDates.length === 3
          const daysOnSystem = differenceInDays(
            new Date(),
            // some customers don't have / use transplant date, so we use start date as a fallback
            cropDates[0]
          )
          const yieldForecast = getYieldPredictionInfoTipData(inventory.id)

          setShowMapInfoTip(true)

          setInfoTipContent({
            batchId: inventory?.id,
            varietyName: inventory?.varietyName,
            serial: inventory?.currentLocator?.serial,
            ...(hasTransplantDate
              ? {
                  cropStartDate: <CropDate date={cropDates[0]} className="InfoTip-DateValue" />,
                  cropTransplantDate: <CropDate date={cropDates[1]} className="InfoTip-DateValue" />,
                  cropEndDate: (
                    <CropDate date={cropDates[2]} className="InfoTip-DateValue" />
                  )
                }
              : {
                  cropStartDate: <CropDate date={cropDates[0]} className="InfoTip-DateValue" />,
                  cropTransplantDate: null,
                  cropEndDate: <CropDate date={cropDates[1]} className="InfoTip-DateValue" />
                }),
            daysOnSystem: <DaysOnSystem days={daysOnSystem} className="InfoTip-DateValue" />,
            yieldForecast: yieldForecast,
            yieldForecastIsLoading: yieldPredictionAverages?.yieldPredictionAveragesLoading ?? true,
            yieldForecastTitle: 'Yield Forecast',
            spaceName: space?.name ?? '',
            // TODO: This requires proper translation but we don't have all the necessary data to utilize `formatSeedingQuantity`
            // https://github.com/iunu/portal/pull/8400/files#r1207239701
            cropContainersTitle: 'Containers',
            containersPerLocator: inventory?.containersPerLocator
          })
        }
      }
    }, 1000)
  }

  const $mapRef = useCallback(
    (newVal: Map): void => {
      if (newVal && !mapRef.current) {
        mapRef.current = newVal
        setMapRefUUID(v4())
      }
    },
    [mapRefUUID]
  )

  const $pointerDown = useCallback(
    (ev: Event): void => {
      pointerDownTime.current = Date.now()
      setPointerDownTimeUUID(v4())
    },
    [pointerDownTimeUUID]
  )

  const $nullifyLongClick = useCallback((): void => {
    // pointerDownTime.current = undefined
    setPointerDownTimeUUID(v4())
  }, [pointerDownTimeUUID])

  const $pointerMove = useCallback(
    (event: MouseEventForMap): void => {
      const batchTile = [
        ...containerVectorLayers.current.getLayers().getArray(),
        ...spaceLayers.current.getLayers().getArray()
      ]
        .filter((layer) => (layer instanceof VectorLayer) && (layer.getSource().getFeaturesAtCoordinate(event.coordinate).length > 0))
        .sort((a, b) => b.getZIndex() - a.getZIndex())

      if (batchTile.length >= 2) {
        const containerTile = batchTile.filter((tile) => tile.get('type') === EEntity.Container)
        const spaceTile = batchTile.filter((tile) => tile.get('type') === EEntity.Space)

        updateMapInfoTipWithIdle({
          event,
          batchTile: {
            type: containerTile[0].get('type'),
            id: containerTile[0].get('id'),
            parentType: containerTile[0].get('parentType'),
            parentID: containerTile[0].get('parentID'),
            meta: containerTile[0].get('meta'),
            zIndex: containerTile[0].getZIndex(),
            spaceId: spaceTile[0].get('id')
          }
        })
      } else {
        setShowMapInfoTip(false)

        clearTimeout(pointerIdleTimeout.current as ReturnType<typeof setTimeout>)
      }
    },
    [containers.containersUUID, inventories.inventoriesUUID, yieldPredictionAverages.yieldPredictionAveragesUUID]
  )

  const $singleClick = useCallback(
    (ev: MouseEventForMap): void => {
      const longClick = Date.now() - pointerDownTime.current > 750
      pointerDownTime.current = Infinity
      setPointerDownTimeUUID(v4())

      const clickedEntities = [
        ...facilityLayers.current.getLayers().getArray(),
        ...buildingLayers.current.getLayers().getArray(),
        ...spaceLayers.current.getLayers().getArray(),
        ...inventoryVectorLayers.current.getLayers().getArray(),
        ...containerVectorLayers.current.getLayers().getArray()
      ]
        .filter((layer) => layer instanceof VectorLayer)
        .filter((layer) => {
          const clicked = layer.getSource().getFeaturesAtCoordinate(ev.coordinate).length > 0

          return clicked
        })
        .map((layer) => {
          const type = layer.get('type')
          const id = layer.get('id')
          const parentType = layer.get('parentType')
          const parentID = layer.get('parentID')
          const meta = layer.get('meta')
          const zIndex = layer.getZIndex()

          return {
            type,
            id,
            parentType,
            parentID,
            meta,
            zIndex
          }
        })
        .sort((a, b) => b.zIndex - a.zIndex)

      const space = clickedEntities.find((entity) => entity.type === EEntity.Space)

      const inventory = clickedEntities.find((entity) => entity.type === EEntity.Inventory)

      const container = clickedEntities.find((entity) => entity.type === EEntity.Container)

      console.log(`Coordinate: ${ev.coordinate}`)
      if (space) {
        const spaceMeta = spaceMetas.current[space.id]
        const spaceCoordinate = spaceMeta.projectiveTransformation.inverseTransform(ev.coordinate).map(UnscalePT)

        console.log(`Space Coordinate: ${spaceCoordinate}`)
      }

      if (inventory) {
        const inventoryMeta = inventoryMetas.current[inventory.id]
        const inventoryCoordinate = inventoryMeta.projectiveTransformation
          .inverseTransform(ev.coordinate)
          .map(UnscalePT)

        console.log(`Inventory Coordinate: ${inventoryCoordinate}`)
      }

      /*
      if (container) {
        const containerMeta = containerMetas.current[container.id]
        const containerCoordinate = containerMeta.projectiveTransformation.inverseTransform(ev.coordinate)
          .map(UnscalePT)

        console.log(`Container Coordinate: ${containerCoordinate}`)
      }
      */

      clickedEntities.forEach((entity) => {
        let inventory
        let container
        let gridPrediction
        let inventoryStitch
        let containerStitch
        switch (entity.type) {
          case EEntity.Inventory:
            inventory = inventories.inventories.find((inventory) => String(inventory.id) === String(entity.id))

            gridPrediction = gridPredictions.gridPredictions.find(
              (gridPrediction) => String(gridPrediction.id) === String(entity.meta.gridPredictionID)
            )

            if (inventoryMetas.current[entity.id].inventoryStitchID) {
              inventoryStitch = inventoryStitches.inventoryStitches.find(
                (inventoryStitch) => String(inventoryStitch.id) === String(inventoryMetas.current[entity.id].inventoryStitchID)
              )
            } else {
              inventoryStitch = undefined
            }

            debug('-- Inventory ----', inventory)
            debug('-- Grid Prediction ----', gridPrediction)
            if (inventoryStitch) {
              debug('-- Inventory Stitch ----', inventoryStitch)
            }
            break
          case EEntity.Container:
            container = containers.containers.find((container) => String(container.id) === String(entity.id))

            gridPrediction = gridPredictions.gridPredictions.find(
              (gridPrediction) => String(gridPrediction.id) === String(entity.meta.gridPredictionID)
            )

            if (containerMetas.current[entity.id].containerStitchID) {
              containerStitch = containerStitches.containerStitches.find(
                (containerStitch) =>
                  String(containerStitch.id) === String(containerMetas.current[entity.id].containerStitchID)
              )
            } else {
              containerStitch = undefined
            }

            debug('-- Container ----', container)
            debug('-- Grid Prediction ----', gridPrediction)
            if (containerStitch) {
              debug('-- Container Stitch ----', containerStitch)
            }
            break
        }
      })

      const clickedInventoryContainer = clickedEntities.find(
        (entity) => entity.type === EEntity.Inventory || entity.type === EEntity.Container
      )

      if (clickedInventoryContainer) {
        let meta
        if (clickedInventoryContainer.type === EEntity.Inventory) {
          meta = inventoryMetas.current[clickedInventoryContainer.id]
        } else {
          meta = containerMetas.current[clickedInventoryContainer.id]
        }

        const projectiveTransformation = meta.projectiveTransformation
        const coords = projectiveTransformation.inverseTransform(ev.coordinate).map(UnscalePT)

        if (longClick) {
          const onIssueTab = user.isAdmin && searchParams.navigationTab === 'Tab.Issues'
          const navigationTab = onIssueTab ? ETab.Issues : ETab.Flags
          const newSearchParams = {
            ...searchParams,
            positionX: coords[0],
            positionY: coords[1],

            navigationOpen: true,
            navigationTab: navigationTab
          }
          const buildParamsCreateTask = onIssueTab ? { creatingTaskIssue: true } : { creatingTask: true }
          Object.assign(newSearchParams, buildParamsCreateTask)
          if (clickedInventoryContainer.type === EEntity.Inventory) {
            newSearchParams.inventoryId = clickedInventoryContainer.id
          } else {
            newSearchParams.containerId = clickedInventoryContainer.id
          }

          setSearchParams(newSearchParams)
        } else {
          selectedEntities.selectEntity(clickedInventoryContainer, ev.originalEvent.ctrlKey || ev.originalEvent.metaKey)
        }
      }
    },

    [
      searchParamsUUID,
      inventories.inventoriesUUID,
      containers.containersUUID,
      inventoryStitches.inventoryStitchesUUID,
      containerStitches.containerStitchesUUID,
      selectedEntities.selectEntity
    ]
  )

  const $moveEnd = useCallback((): void => {
    UpdateVisibleEntities({
      visibleEntities,
      zoom,

      view: view.current,

      facilityLayers: facilityLayers.current,
      buildingLayers: buildingLayers.current,
      spaceLayers: spaceLayers.current,
      inventoryVectorLayers: inventoryVectorLayers.current,
      containerVectorLayers: containerVectorLayers.current,
      overlays: overlays.current
    })
  }, [
    viewUUID,
    facilityLayersUUID,
    buildingLayersUUID,
    spaceLayersUUID,
    inventoryVectorLayersUUID,
    containerVectorLayersUUID,
    overlaysUUID
  ])

  // Effects ///////////////////////////////////////////////////////////////////

  useIter(
    InitOpenLayers,

    {
      interactions: interactions.current,
      layers: layers.current,

      facilityLayers: facilityLayers.current,
      buildingLayers: buildingLayers.current,
      spaceLayers: spaceLayers.current,
      inventoryVectorLayers: inventoryVectorLayers.current,
      inventoryRasterLayers: inventoryRasterLayers.current,
      containerVectorLayers: containerVectorLayers.current,
      containerRasterLayers: containerRasterLayers.current,
      heatmapLayers: heatmapLayers.current,
      setInteractionsUUID,
      setLayersUUID
    },

    []
  )

  useIter(
    UpdateOpenLayersSize,

    { mapRef: mapRef.current, carouselActive: !!searchParams.viewAreacamTiles },

    [width, height]
  )

  useIter(
    UpdateOpenLayersExtent,

    {
      selectedEntities,

      view: view.current,

      facilityLayers: facilityLayers.current,
      buildingLayers: buildingLayers.current,
      spaceLayers: spaceLayers.current,
      inventoryVectorLayers: inventoryVectorLayers.current,
      containerVectorLayers: containerVectorLayers.current,
      overlays: overlays.current
    },

    [
      selectedEntities.selectedEntitiesUUID,

      viewUUID,

      facilityLayersUUID,
      buildingLayersUUID,
      spaceLayersUUID,
      inventoryVectorLayersUUID,
      containerVectorLayersUUID,
      overlaysUUID
    ]
  )

  useIter(
    UpdateFacilityLayers,

    {
      facility,

      view,
      facilityLayers: facilityLayers.current,
      setFacilityLayersUUID
    },

    [facility.facilityUUID]
  )

  useIter(
    UpdateBuildingLayers,

    {
      buildings,

      buildingLayers: buildingLayers.current,
      setBuildingLayersUUID
    },

    [buildings.buildingsUUID]
  )

  useIter(
    UpdateSpaceLayers,

    {
      spaces,

      spaceMetas,
      spaceLayers: spaceLayers.current,
      setSpaceLayersUUID
    },

    [spaces.spacesUUID]
  )

  useIter(
    UpdateInventoryVectorLayers,

    {
      time: time,
      gridPredictions,

      spaceMetas: spaceMetas.current,
      inventoryMetas,
      inventoryVectorLayers: inventoryVectorLayers.current,
      setInventoryVectorLayersUUID
    },

    [gridPredictions.gridPredictionsUUID]
  )

  useIter(
    UpdateInventoryRasterLayers,

    {
      searchParams,
      time,
      facility,
      gridPredictions,
      inventoryStitches,

      spaceMetas: spaceMetas.current,
      inventoryMetas,
      inventoryRasterLayers: inventoryRasterLayers.current,
      setInventoryRasterLayersUUID
    },

    [gridPredictions.gridPredictionsUUID, inventoryStitches.inventoryStitchesUUID]
  )

  useIter(
    UpdateInventoryHeatmapLayers,

    {
      time,
      facility,
      gridPredictions,
      heatmaps,

      spaceMetas: spaceMetas.current,
      inventoryMetas,
      heatmapLayers: heatmapLayers.current,
      setHeatmapLayersUUID
    },

    [gridPredictions.gridPredictionsUUID, heatmaps.heatmapsUUID]
  )

  useIter(
    UpdateContainerVectorLayers,

    {
      time: time,
      gridPredictions,

      spaceMetas: spaceMetas.current,
      containerMetas,
      containerVectorLayers: containerVectorLayers.current,
      setContainerVectorLayersUUID
    },

    [gridPredictions.gridPredictionsUUID]
  )

  useIter(
    UpdateContainerRasterLayers,

    {
      searchParams,
      time,
      facility,
      gridPredictions,
      containerStitches,

      spaceMetas: spaceMetas.current,
      containerMetas,
      containerRasterLayers: containerRasterLayers.current,
      setContainerRasterLayersUUID
    },

    [gridPredictions.gridPredictionsUUID, containerStitches.containerStitchesUUID]
  )

  useIter(
    UpdateContainerHeatmapLayers,

    {
      time,
      facility,
      gridPredictions,
      heatmaps,

      spaceMetas: spaceMetas.current,
      containerMetas,
      heatmapLayers: heatmapLayers.current,
      setHeatmapLayersUUID
    },

    [gridPredictions.gridPredictionsUUID, heatmaps.heatmapsUUID]
  )

  useIter(
    UpdateOverlays,

    {
      user,
      time,
      _overlays,
      spaces,
      sensors,
      areacams,
      inventories,
      containers,
      inventoryStitches,
      containerStitches,
      heatmaps,
      searchParams,
      tasks,
      taskIssues,

      spaceMetas: spaceMetas.current,
      inventoryMetas: inventoryMetas.current,
      containerMetas: containerMetas.current,
      heatmapLayers: heatmapLayers.current,
      overlays: overlays.current,

      setHeatmapLayersUUID,
      setOverlaysUUID
    },

    [
      tasks.tasksUUID,
      _overlays.overlaysUUID,
      spaces.spacesUUID,
      sensors.sensorsUUID,
      areacams.areacamsUUID,
      inventories.inventoriesUUID,
      containers.containersUUID,
      inventoryStitches.inventoryStitchesUUID,
      containerStitches.containerStitchesUUID,
      heatmaps.heatmapsUUID,
      searchParamsUUID,

      spaceLayersUUID,
      inventoryVectorLayersUUID,
      containerVectorLayersUUID
    ]
  )

  useIter(
    UpdateVisibleEntities,

    {
      visibleEntities,
      zoom,

      view: view.current,
      facilityLayers: facilityLayers.current,
      buildingLayers: buildingLayers.current,
      spaceLayers: spaceLayers.current,
      inventoryVectorLayers: inventoryVectorLayers.current,
      containerVectorLayers: containerVectorLayers.current,
      overlays: overlays.current
    },

    [
      viewUUID,
      facilityLayersUUID,
      buildingLayersUUID,
      spaceLayersUUID,
      inventoryVectorLayersUUID,
      containerVectorLayersUUID,
      overlaysUUID
    ]
  )

  useIter(
    UpdateInventoryRasterLayersVisibility,

    {
      searchParams,
      zoom,
      inventoryRasterLayers: inventoryRasterLayers.current
    },

    [searchParamsUUID, zoom.zoomUUID, inventoryRasterLayersUUID]
  )

  useIter(
    UpdateInventoryHeatmapLayersVisibility,

    { _overlays, zoom, heatmapLayers: heatmapLayers.current },

    [_overlays.overlaysUUID, zoom.zoomUUID, inventoryRasterLayersUUID]
  )

  useIter(
    UpdateContainerRasterLayersVisibility,

    {
      searchParams,
      zoom,
      containerRasterLayers: containerRasterLayers.current
    },

    [searchParamsUUID, zoom.zoomUUID, containerRasterLayersUUID]
  )

  useIter(
    UpdateContainerLayersByAge,

    {
      time,
      searchParams,
      containerMetas: containerMetas.current,
      containerStitches,
      containerVectorLayers: containerVectorLayers.current,
      containerRasterLayers: containerRasterLayers.current,
      selectedEntities,
      user
    },

    // ultimately this should update after container stitches update, but we want to ensure
    // UpdateContainerRasterLayers has completed first
    [searchParams.navigationTab, containerRasterLayersUUID]
  )

  useIter(
    UpdateOverlaysVisibility,
    { searchParams, zoom, overlays: overlays.current },

    [searchParamsUUID, zoom.zoomUUID, overlaysUUID]
  )

  useIter(
    UpdateHighlight,

    {
      selectedEntities,

      facilityLayers: facilityLayers.current,
      buildingLayers: buildingLayers.current,
      spaceLayers: spaceLayers.current,
      inventoryVectorLayers: inventoryVectorLayers.current,
      containerVectorLayers: containerVectorLayers.current
    },

    [
      selectedEntities.selectedEntitiesUUID,

      facilityLayersUUID,
      buildingLayersUUID,
      spaceLayersUUID,
      inventoryVectorLayersUUID,
      containerVectorLayersUUID
    ]
  )

  useEffect(() => {
    if (!searchParams.selectInventoryId || !gridPredictions.gridPredictions || !containers.containers) return

    SelectEntityBySearchParams({
      searchParams,
      gridPredictions: gridPredictions.gridPredictions,
      setSearchParams
    })
  }, [searchParams, gridPredictions.gridPredictions, setSearchParams, containers.containers])

  // Debug /////////////////////////////////////////////////////////////////////

  // debug('Render')

  // Render ////////////////////////////////////////////////////////////////////

  const style = { width: '0px', height: '0px' }
  if (width && height) {
    style.width = `${width}px`
    style.height = `${height}px`
  }

  return (
    <>
      <div style={style} className="MapCanvas">
        <OLView
          view={view.current}
          interactions={interactions.current}
          controls={controls.current}
          overlays={overlays.current}
          layers={layers.current}
          pixelRatio={1}
          moveTolerance={10}
          maxTilesLoading={256}
          onMapRef={$mapRef}
          onDblClick={$nullifyLongClick}
          onSingleClick={$singleClick}
          onPointerDown={$pointerDown}
          onPointerMove={(event) => {
            $nullifyLongClick()
            $pointerMove(event)
          }}
          onMoveEnd={$moveEnd}
        />
      </div>
      {createPortal(
        <div ref={mapInfoTip} className={`MapInfoTip ${!showMapInfoTip ? 'hidden' : ''}`}>
          <h5 className="InfoTip-Title">
            {infoTipContent?.varietyName}
            <span className="InfoTip-Serial">({infoTipContent?.serial})</span>
          </h5>
          <div className="InfoTip-Content">
            <div className="divider"></div>

            <div className="InfoTip-Data">
              <div className="InfoTip-Value mt-0">
                <span>
                  {infoTipContent?.containersPerLocator} {infoTipContent?.cropContainersTitle}
                </span>
                <span className="InfoTip-Badge">{infoTipContent?.spaceName}</span>
              </div>
            </div>

            <div className="divider"></div>

            <div className="InfoTip-Data">
              <div className="InfoTip-Label">Date</div>
              <div className="InfoTip-Value">
                {infoTipContent?.cropStartDate}
                {infoTipContent?.cropTransplantDate}
                {infoTipContent?.cropEndDate}
              </div>
              <div className="InfoTip-Value mt-ms mb-xxs">{infoTipContent?.daysOnSystem}</div>
            </div>

            <div className="divider"></div>

            <div className="InfoTip-Data">
              <div className="InfoTip-Label">{infoTipContent?.yieldForecastTitle}</div>
              <div className="InfoTip-Value">
                <YieldForecastData
                  loading={infoTipContent?.yieldForecastIsLoading ?? true}
                  data={infoTipContent?.yieldForecast}
                />
              </div>
            </div>
          </div>

          <div className="InfoTip-BatchId">
            <span>Batch ID: {infoTipContent?.batchId}</span>
          </div>
        </div>,
        document.body
      )}
    </>
  )
}

export default MapCanvas

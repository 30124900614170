// Types
import ISpace from 'graphql-lib/interfaces/ISpace';
import EChart from 'map-view/enums/chart';
import EEntity from 'map-view/enums/entity';
import IChart from 'map-view/interfaces/chart';
import IEntity from 'map-view/interfaces/entity';
import IGreenAutomationWaterSystemRecord, { IGreenAutomationWaterSystemRecordData } from 'graphql-lib/interfaces/IGreenAutomationWaterSystemRecord';

// Utils
import {
  NewChart,
  SensorCharts,
  WaterSystemCharts
} from 'map-view/utils/chart';

import {
  canBuildChart,
  filterSensorRecords,
  filterWaterSystemRecords,
  buildSpaceSensor
} from './utils';

// Libs
import { v4 } from 'uuid';
import { ISensorRecordByType } from 'graphql-lib/interfaces/ISensorDevice';
import ITime from 'map-view/interfaces/time';
import ISetStateType from 'graphql-lib/interfaces/ISetStateType';
import { LazyQueryExecFunction, OperationVariables, QueryResult } from '@apollo/client';

interface UpdateChartsPropsType {
  selectedEntities: Array<IEntity>;
  spaceCharts: Array<IChart>;
  inventoryCharts: Array<IChart>;
  containerCharts: Array<IChart>;
  needsHistoricalData: boolean;
  setCharts: (param: Array<IChart>) => void;
  setChartsUUID: (param: string) => void;
};

export const UpdateCharts = ({
  selectedEntities,
  spaceCharts,
  inventoryCharts,
  containerCharts,
  needsHistoricalData,
  setCharts,
  setChartsUUID
}: UpdateChartsPropsType): void => {
  if (!selectedEntities || needsHistoricalData) {
    return
  }
  const newCharts: Array<IChart> = []

  if (spaceCharts) {
    spaceCharts
      .filter(chart => !!selectedEntities
        .find(entity =>
          entity.id === chart.chart &&
          entity.parentType === chart.parentType &&
          entity.parentID === chart.parentID))
      .forEach(chart => newCharts.push(chart))
  }

  if (inventoryCharts) {
    inventoryCharts
      .filter(chart => !!selectedEntities
        .find(entity =>
          entity.id === chart.chart &&
          entity.parentType === chart.parentType &&
          entity.parentID === chart.parentID))
      .forEach(chart => newCharts.push(chart))
  }

  if (containerCharts) {
    containerCharts
      .filter(chart => !!selectedEntities
        .find(entity =>
          entity.id === chart.chart &&
          entity.parentType === chart.parentType &&
          entity.parentID === chart.parentID))
      .forEach(chart => newCharts.push(chart))
  }

  setCharts(newCharts)
  setChartsUUID(v4())
};

interface QueryHistoricalChartDataType {
  selectedEntities: Array<IEntity>
  time: ITime
  getSensorRecords: LazyQueryExecFunction<ISensorRecordByType, Record<string, any>>
  getCO2SensorRecords: LazyQueryExecFunction<ISensorRecordByType, Record<string, any>>
  getWaterSystemRecords: LazyQueryExecFunction<{ greenAutomationWaterSystemRecord: Array<IGreenAutomationWaterSystemRecord> }, Record<string, any>>
  setNeedsHistoricalData: ISetStateType<boolean>
};

export const QueryHistoricalChartData = ({
  selectedEntities,
  time,
  getSensorRecords,
  getCO2SensorRecords,
  getWaterSystemRecords,
  setNeedsHistoricalData
}: QueryHistoricalChartDataType) => {
  // Make queries for space charts that are selected on page load directly through the URL
  const selectedSpaceCharts = selectedEntities
    .filter((e: IEntity) =>
      e.type === EEntity.Chart &&
      e.parentType === EEntity.Space
    )

  // Check what kind of chart queries we need to make
  const needsCO2RecordData = selectedSpaceCharts.some((c: IEntity) => c.id === EChart.CO2)
  const needsSensorRecordData = selectedSpaceCharts.some((c: IEntity) => SensorCharts.includes(c.id as EChart))
  const needsWaterSystemRecordData = selectedSpaceCharts.find((c: IEntity) => WaterSystemCharts.includes(c.id as EChart))

  // Get the space IDs needed for the query from the selected charts
  const chartSpaceIds = selectedSpaceCharts.map((chart: IEntity) => chart.parentID)

  if (needsCO2RecordData) {
    // Make the query to get historical data for the space's CO2 sensors
    getCO2SensorRecords({
      variables: {
        spaceIds: chartSpaceIds,
        startDate: time?.twoWeekLookback,
        endDate: time?.now
      },
      fetchPolicy: 'no-cache'
    })
  }
  if (needsSensorRecordData) {
    // Make the query to get historical data for the space's sensors
    getSensorRecords({
      variables: {
        spaceIds: chartSpaceIds,
        startDate: time?.twoWeekLookback,
        endDate: time?.now
      },
      fetchPolicy: 'no-cache'
    })
  }
  if (needsWaterSystemRecordData) {
    // Make the query to get historical data for the space's water system sensors
    getWaterSystemRecords({
      variables: {
        spaceIds: chartSpaceIds,
        startDate: time?.twoWeekLookback,
        endDate: time?.now
      },
      fetchPolicy: 'no-cache'
    })
  }
  if (!selectedSpaceCharts.length) setNeedsHistoricalData(false)
};
interface UpdateChartsFromHistoricalDataPropsType {
  spaceCharts: Array<IChart>;
  spaces: Array<ISpace>;
  sensorRecordData: QueryResult<ISensorRecordByType, Record<string, any>>
  CO2sensorRecordData: QueryResult<ISensorRecordByType, Record<string, any>>
  waterSystemRecordData: QueryResult<{ greenAutomationWaterSystemRecord: Array<IGreenAutomationWaterSystemRecord>}, OperationVariables>
  setSpaceCharts: (param: Array<IChart>) => void;
  setNeedsHistoricalData: (param: boolean) => void;
};

export const UpdateChartsFromHistoricalData = ({
  spaceCharts,
  spaces,
  sensorRecordData,
  CO2sensorRecordData,
  waterSystemRecordData,
  setSpaceCharts,
  setNeedsHistoricalData
}: UpdateChartsFromHistoricalDataPropsType) => {
  // Set the newly queried historical sensor record data
  const newSpaceCharts = spaceCharts.map((chart: IChart) => {
    // Get the space
    const space = spaces.find((space: ISpace) => space.id === chart.parentID)

    // Get only the sensor records that match the space ID
    const sensorRecords = filterSensorRecords(space, sensorRecordData)
    const CO2sensorRecords = filterSensorRecords(space, CO2sensorRecordData)
    const waterSystemRecords = filterWaterSystemRecords(space, waterSystemRecordData)

    // Build sensors on the space
    buildSpaceSensor(space, sensorRecords)
    buildSpaceSensor(space, CO2sensorRecords)
    space.waterSystemRecords = waterSystemRecords || []

    // Create the new chart with historical data and replace the values of the current chart
    if (canBuildChart({ chart, sensorRecords, CO2sensorRecords, waterSystemRecords })) {
      const newChart = NewChart(space, chart.chart)
      chart.value = newChart?.value
    }

    return chart
  })

  setSpaceCharts(newSpaceCharts)
  if (
    !sensorRecordData.loading ||
    !CO2sensorRecordData.loading ||
    !waterSystemRecordData.loading
  ) {
    setNeedsHistoricalData(false)
  }
};
/**
 * @author Miras Absar <mabsar@iunu.com>
 */

// Types
import IFacility from 'graphql-lib/interfaces/IFacility';

// React
import React, { useCallback, useState } from 'react';

// React Libs
import Fit from 'react-fit';
import history from 'react-lib/history';

// GraphQL Lib
import useFacilities from 'graphql-lib/states/use-facilities';
import config from 'config';
import { clearStorage } from 'utils/storage';
// UI Lib
import Button from 'ui-lib/components/button';

interface IFacilitySwitcherProps {
  title: string;
}

const FacilitySwitcher = ({ title }: IFacilitySwitcherProps): JSX.Element => {
  // State /////////////////////////////////////////////////////////////////////

  const [open, setOpen] = useState<boolean>(false)
  const {
    loading: facilitiesLoading,
    error: facilitiesError,
    facilities,
    uuid: facilitiesUUID
  } = useFacilities(['id', 'customerId', 'code', 'name', 'nickname'])

  // Callbacks /////////////////////////////////////////////////////////////////

  const $click = useCallback((): void => {
    if (facilities?.length > 1) {
      setOpen(!open);
    }
  }, [open, facilitiesUUID])

  const $signOut = useCallback((): void => {
    setOpen(false);
    clearStorage();
    history.push('/');
    heap.resetIdentity();

    if (config.zenDeskId && global.zE) {
      global.zE('webWidget', 'logout');
    }
  }, [])

  const $switch = useCallback((facility: IFacility): void => {
    setOpen(false);
    window.location.href = `${window.location.origin}?cid=${facility.customerId}&fid=${facility.id}`
  }, [])

  // Render ////////////////////////////////////////////////////////////////////

  let className = 'FacilitySwitcher'

  return (
    <div className={className}>
      <h6 className="Title" onClick={$click}>
        {title}
      </h6>

      {open && (
        <Fit>
          <div className="FacilityList">
            {facilities?.map((f) => (
              <Button key={f.id} onClick={() => $switch(f)}>
                {f.name}
              </Button>
            ))}
          </div>
        </Fit>
      )}
    </div>
  )
}

export default FacilitySwitcher

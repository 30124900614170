import ITag from 'graphql-lib/interfaces/ITag';
import {
  html,
  define,
  render
} from 'hybrids';

import { IOverlayRender } from 'map-view/overlays/types';

const TagOverlay: IOverlayRender<{ tag: ITag }> = {
  tag: null,
  render: render(({
    tag
  }: { tag: string }) => {
    return html`
      <div class='TagOverlay'>
        <div>${tag as string}</div>
      </div>
    `
  }, { shadowRoot: false })
}

define('tag-overlay', TagOverlay)

/**
 * @author Miras Absar
 */
import React from 'react'

export interface IMinimapProps {
  outlined?: boolean;
  resting?: boolean;
  raised?: boolean;
  animated?: boolean;

  spaceCols: number;
  spaceRows: number;

  firstCol: number;
  firstRow: number;

  lastCol: number;
  lastRow: number;
}

const Minimap = ({
  outlined,
  resting,
  raised,
  animated,

  spaceCols,
  spaceRows,

  firstCol,
  firstRow,

  lastCol,
  lastRow
}: IMinimapProps): JSX.Element => {
  let className = 'Space'
  if (outlined) className += ' Outlined'
  if (resting) className += ' Resting'
  if (raised) className += ' Raised'
  if (animated) className += ' Animated'

  const width = 32
  const height = 264
  const viewportWidth = ((lastCol - firstCol) / spaceCols) * width
  const viewportHeight = ((lastRow - firstRow) / spaceRows) * height
  const viewportLeft = (firstCol / spaceCols) * width
  const viewportTop = (firstRow / spaceRows) * height

  const spaceStyle = {
    width: `${width}px`,
    height: `${height}px`
  }

  const viewportStyle = {
    width: `${viewportWidth}px`,
    height: `${viewportHeight}px`,
    marginLeft: `${viewportLeft}px`,
    marginTop: `${viewportTop}px`
  }

  return (
    <div
      style={spaceStyle}
      className={className}
    >
      <div
        style={viewportStyle}
        className='Viewport'
      />
    </div>
  )
}

export default Minimap

/**
 * @author Miras Absar <mabsar@iunu.com>
 */

import { gql } from '@apollo/client'

const UpdateUser = gql`
  mutation(
    $id: ID!,

    $title: String,
    $name: String!
    $username: String!,
    $email: String!,
    $password: String!
    $isTest: boolean!
  ) {
    CustomerUser {
      update(input: {
        id: $id,

        title: $title,
        name: $name,
        username: $username,
        email: $email,
        password: $password,
        isTest: $isTest
      }) {
        id
        createdOn
        updatedOn
        customerId

        title
        name
        username
        email
        isTest
      }
    }
  }
`

export { UpdateUser }

/**
 * @author Miras Absar <mabsar@iunu.com>
 * @file ...
 */

// GraphQL
import { gql } from '@apollo/client'

const QueryStaticData = gql`
  query($facilityId: ID) {
    facility(id: $facilityId) {
      id
      vendorIds

      code
      name
      nickname

      timezone
      defaultTimestamp

      # Geospatial Data
      mapType
      mapRotation
      dcs

      # Legacy Geospatial Data
      projectionCode
      projectionDefinition
      geojson

      building {
        id

        code
        name
        nickname

        # Geospatial Data
        dcs

        # Legacy Geospatial Data
        geojson

        space {
          id
          customerId
          vendorCustomerFacilityIds

          code
          name
          nickname

          # Geospatial Data
          dcs

          # Legacy Geospatial Data
          geojson
        }
      }
    }
  }
`

const QuerySensors = gql`
  query($sensorIds: [ID]) {
    sensor(ids: $sensorIds) {
      id

      code
      relativeX
      relativeY
    }
  }
`

const QueryAreacams = gql`
  query($areacamIds: [ID]) {
    areacam(ids: $areacamIds) {
      id
      spaceId

      code
      latitude
      longitude
    }
  }
`

const QuerySensorRecords = gql`
  query(
    $spaceIds: [ID],
    $startDate: DateTime,
    $endDate: DateTime,
    $getMostRecentPerDevice: Boolean
  ) {
    sensorRecord(
      spaceIds: $spaceIds,
      startDate: $startDate,
      endDate: $endDate
      getMostRecentPerDevice: $getMostRecentPerDevice
    ) {
      id
      createdOn
      spaceId
      sensorDeviceId

      absoluteHumidity
      dli
      humidity
      temperature
      umol
      vpd
    }
  }
`

const QueryCO2SensorRecords = gql`
  query(
    $facilityIds: [ID],
    $startDate: DateTime,
    $endDate: DateTime,
    $getMostRecentPerDevice: Boolean
  ) {
    co2SensorRecord(
      facilityIds: $facilityIds,
      startDate: $startDate,
      endDate: $endDate,
      getMostRecentPerDevice: $getMostRecentPerDevice
    ) {
      id
      createdOn
      spaceId
      environmentalZoneId
      sensorDeviceId

      co2
    }
  }
`

const QueryAreaImageRecords = gql`
  query(
    $facilityIds: [ID],
    $startDate: DateTime,
    $endDate: DateTime
  ) {
    areaImageRecord(
      facilityIds: $facilityIds,
      startDate: $startDate,
      endDate: $endDate
    ) {
      id
      createdOn
      spaceId
      camDeviceId

      thumbUrl
      url
    }
  }
`

const QueryGridPredictions = gql`
  query(
    $spaceIds: [ID],
    $startDate: DateTime,
    $endDate: DateTime,
    $onlyInventories: Boolean,
    $onlyContainers: Boolean
  ) {
    gridPrediction(
      spaceIds: $spaceIds,
      startDate: $startDate,
      endDate: $endDate,

      onlyInventories: $onlyInventories,
      onlyContainers: $onlyContainers,
      distinct: true
    ) {
      id
      createdOn
      spaceId

      grid
    }
  }
`

const QueryInventories = gql`
  query($inventoryIds: [ID]) {
    inventory(ids: $inventoryIds) {
      id
      code
      favorite
      cropId
      cropCode
      cropName
      cropStartDate
      cropTransplantDate
      cropEndDate

      productId
      productCode
      productNickname

      speciesId
      speciesName
      speciesIcon

      varietyId
      varietyName
      varietyColor

      containersPerLocator
      cropContainersPerLocator
      productSeedsPerContainer


      currentLocator{
        id
        serial
      }
    }
  }
`

const QueryContainers = gql`
  query($containerIds: [ID]) {
    container(ids: $containerIds) {
      id
      inventoryId

      code
    }
  }
`

const QueryGreenAutomationWaterSystemRecords = gql`
  query(
    $facilityIds: [ID]
    $startDate: DateTime
    $endDate: DateTime
    $getMostRecentPerSystem: Boolean
  ) {
    greenAutomationWaterSystemRecord (
      facilityIds: $facilityIds
      startDate: $startDate
      endDate: $endDate
      getMostRecentPerSystem: $getMostRecentPerSystem
    ) {
      id
      vendorCustomerFacilityId
      createdOn
      updatedOn
      status
      waterSystem
      ec1
      ec2
      ph1
      ph2
      dissolvedO2
      waterTemp
    }
  }
`


const QueryInventoryStitches = gql`
  query(
    $inventoryIds: [ID],
    $startDate: DateTime,
    $endDate: DateTime
  ) {
    inventoryStitch(
      inventoryIds: $inventoryIds,
      startDate: $startDate,
      endDate: $endDate,

      distinct: false,
      renderable: true
    ) {
      id
      createdOn
      updatedOn
      lastImageCreatedOn

      customerId
      facilityId
      spaceId
      inventoryId

      width
      height
      tilesUrl

      locatorCode
    }
  }
`

const QueryContainerStitches = gql`
  query(
    $containerIds: [ID],
    $startDate: DateTime,
    $endDate: DateTime
  ) {
    containerStitch(
      containerIds: $containerIds,
      startDate: $startDate,
      endDate: $endDate,

      distinct: false,
      renderable: true
    ) {
      id
      createdOn
      updatedOn
      lastImageCreatedOn

      customerId
      facilityId
      spaceId
      inventoryId
      containerId
      containerUid

      width
      height
      tilesUrl
      tileSize

      locatorCode
    }
  }
`

const QueryCV = gql`
  query(
    $inventoryIds: [ID],
    $containerIds: [ID],
    $allInventoryIds: [ID],
    $startDate: DateTime,
    $endDate: DateTime
  ) {
    heatmap(
      inventoryIds: $inventoryIds,
      containerIds: $containerIds,
      startDate: $startDate,
      endDate: $endDate,
      distinct: false
    ) {
      id
      createdOn
      inventoryId
      containerId

      url
      width
      height
    }

    budDetection(
      inventoryIds: $inventoryIds,
      containerIds: $containerIds,
      distinct: true
    ) {
      id
      createdOn
      inventoryId
      containerId

      data
      delta
    }

    canopyCoverage(
      inventoryIds: $inventoryIds,
      containerIds: $containerIds,
      endDate: $endDate,
      distinct: true
    ) {
      id
      createdOn
      inventoryId
      containerId

      data
      delta
    }

    canopyHeight(
      inventoryIds: $inventoryIds,
      containerIds: $containerIds,
      distinct: true
    ) {
      id
      createdOn
      inventoryId
      containerId

      data
      delta
    }

    flowerCoverage(
      inventoryIds: $inventoryIds,
      containerIds: $containerIds,
      distinct: true
    ) {
      id
      createdOn
      inventoryId
      containerId

      data
      delta
    }

    germinationRate(
      inventoryIds: $inventoryIds,
      containerIds: $containerIds,
      distinct: true
    ) {
      id
      createdOn
      inventoryId
      containerId

      count
    }

    topCanopyCoverage(inventoryIds: $allInventoryIds) {
      inventoryId
      data
      delta
    }

    topGerminationRate(inventoryIds: $allInventoryIds) {
      inventoryId
      count
    }

    potCount(
      inventoryIds: $inventoryIds,
      containerIds: $containerIds,
      distinct: true
    ) {
      id
      createdOn
      inventoryId
      containerId

      count
    }

    readinessCoverage(
      inventoryIds: $inventoryIds,
      containerIds: $containerIds,
      distinct: true
    ) {
      id
      createdOn
      inventoryId
      containerId

      coverage
    }

    yieldPrediction (
      inventoryIds: $allInventoryIds,
      currentDate: $endDate
    ) {
      id
      createdOn
      updatedOn
      predictedOn
      harvestDate
      customerId
      inventoryId
      containerId
      state
      data
    }

    boardCount(
      inventoryIds: $allInventoryIds
    ) { 
      id
      customerId
      inventoryId
      containerId
      createdOn
      updatedOn
      count
    }
  }
`

const QueryTasks = gql`
  query(
    $inventoryIds: [ID],
    $containerIds: [ID],
    $startDate: DateTime,
    $endDate: DateTime,
  ) {
    task(
      isGridview: false,
      inventoryIds: $inventoryIds,
      containerIds: $containerIds,
      startDate: $startDate,
      endDate: $endDate
    ) {
      id
      createdOn
      customerId
      customerUserId
      locationProfileId

      name
      priority
      dueDate
      complete

      calendarEvent {
        id,
        nextDueTime
      }

      completion {
        id
      }
      flag {
        id
        createdOn

        icon
        color
        title
        description

        flagCategory {
          id
          createdOn

          name
        }
      }

      locationProfile {
        id
        createdOn
        customerId

        inventoryId
        containerId

        positionX
        positionY
      }

      taskAssignee {
        user {
          id
          createdOn
          customerId

          title
          name
          username
          email
        }
      }
    }
  }
`
const QueryTaskIssues = gql`
  query(
    $batchIds: [ID],
    $containerIds: [ID],
    $startDate: DateTime,
    $endDate: DateTime,
  ) {
    taskIssue(
      appliedToView: map,
      batchIds: $batchIds,
      containerIds: $containerIds,
      startDate: $startDate,
      endDate: $endDate
    ) {
      id
      createdAt
      customerId
      createdBy
      locationProfileId
      convertedToTaskAt
      rejectedAt
      priority

      customerTaskCategory {
        id
        createdOn

        icon
        color
        title
        description

        flagCategory {
          id
          createdOn

          name
        }
      }

      locationProfile {
        id
        createdOn
        customerId

        inventoryId
        containerId

        positionX
        positionY
      }
    }
  }
`

const QueryHarvestGroups = gql`
  query($inventoryIds: [ID]) {
    harvestGroup(inventoryIds: $inventoryIds) {
      id
      createdOn
      updatedOn
      attributedOn
      customerId
      facilityId
      inventoryId
      productId
      containerUid
      code
      name
      containers
      disableAutoAttribution
      totals {
        id
        start
        end
        harvestGroupId
        weightUnit
        weightMethod
        totalWeighs
        totalWeight
      }
    }
  }
`

export {
  QueryStaticData,
  QuerySensors,
  QueryAreacams,
  QuerySensorRecords,
  QueryCO2SensorRecords,
  QueryAreaImageRecords,
  QueryGridPredictions,
  QueryInventories,
  QueryContainers,
  QueryInventoryStitches,
  QueryContainerStitches,
  QueryCV,
  QueryTasks,
  QueryTaskIssues,
  QueryHarvestGroups,
  QueryGreenAutomationWaterSystemRecords
}

import Bugsnag from 'utils/bugsnag'

/**
 * TODO: create a fallback so we display a helpful message rather than white space.
 */
const ErrorBoundary = ({ children }: { children: JSX.Element }) => {
  Bugsnag.getPlugin('react')
  return children;
}

export default ErrorBoundary

/* eslint-disable camelcase */
// Utils
import has from 'utils/has'
import {
  EStorage,
  GetItem
} from 'utils/storage'

// Libs
import { startOfDay, parseISO } from 'date-fns'
import {
  format,
  utcToZonedTime
} from 'date-fns-tz'

import { createDate } from 'utils/dates'

import IColumn from 'arrayview/interfaces/IColumn'
import IGroup from 'arrayview/interfaces/IGroup'

import ICrop from '../interfaces/ICrop'
import IInventory from 'graphql-lib/interfaces/IInventory'

const String_SafeIncludes = (a: string, b: string): boolean => {
  a = a || ''
  b = b || ''

  return a.toLowerCase().includes(b.toLowerCase())
}

const arrayOfSerials = (data: { inventory: Array<IInventory> }) => {
  const serialValues: Array<string> = [];
  data.inventory?.forEach(item => item.currentLocator?.serial && serialValues.push(item.currentLocator.serial));
  return serialValues;
}

const String_SafeCompare = (a: string, b: string): number => {
  a = a || ''
  b = b || ''

  return a.localeCompare(b)
}

const searchOnArray = (a: Array<string>, b: string) => {
  let returnValue = false
  a.forEach(v => {
    if (v.includes(b)) returnValue = true
  })
  return returnValue
}

const Date_SafeIncludes = (date: string | Date, search: string): boolean => {
  if (date === undefined ||
    date === null ||
    (typeof date === 'string' && Number.isNaN(parseISO(date).getTime())) ||
    (date instanceof Date && Number.isNaN(date.getTime()))) {
    return false
  }

  const dateString = format(createDate(date), 'PPpp')
  return String_SafeIncludes(dateString, search)
}

const Date_SafeCompare = (a: string | Date, b: string | Date): number => {
  let aTime: number
  let bTime: number

  if (a === undefined ||
    a === null ||
    (typeof a === 'string' && Number.isNaN(parseISO(a).getTime())) ||
    (a instanceof Date && Number.isNaN(a.getTime()))) {
    aTime = Number.MIN_SAFE_INTEGER
  } else {
    aTime = createDate(a).getTime()
  }

  if (b === undefined ||
    b === null ||
    (typeof b === 'string' && Number.isNaN(parseISO(b).getTime())) ||
    (b instanceof Date && Number.isNaN(b.getTime()))) {
    bTime = Number.MAX_SAFE_INTEGER
  } else {
    bTime = createDate(b).getTime()
  }

  return aTime - bTime
}

// TODO: Rewrite this
const StartDateColumn: IColumn<ICrop> = Object.freeze({
  id: 'crop::start-date',
  name: 'Start Date',
  searchFunc: (_: IColumn<ICrop>, search: string, data: ICrop): boolean =>
    Date_SafeIncludes(data.startDate, search),

  ascendingSortFunc: (_: IColumn<ICrop>, first: ICrop, second: ICrop): number =>
    Date_SafeCompare(first.startDate, second.startDate),

  descendingSortFunc: (_: IColumn<ICrop>, first: ICrop, second: ICrop): number =>
    Date_SafeCompare(second.startDate, first.startDate),

  groupFunc: (_: IColumn<ICrop>, data: ICrop): IGroup => {
    const [facility] = GetItem('facilities', EStorage.EphemeralStorage)
    const zonedDate = utcToZonedTime(createDate(data.startDate), facility.timezone)
    const day = startOfDay(zonedDate)
    const formattedDay = format(
      zonedDate,
      'PP',
      { timeZone: facility.timezone }
    )

    return {
      id: `${_.id}::${day}`,
      name: formattedDay,
      data: []
    }
  }
})

// TODO: Rewrite this
const EndDateColumn: IColumn<ICrop> = Object.freeze({
  id: 'crop::end_date',
  name: 'End Date',
  searchFunc: (_: IColumn<ICrop>, search: string, data: ICrop): boolean =>
    Date_SafeIncludes(data.endDate, search),

  ascendingSortFunc: (_: IColumn<ICrop>, first: ICrop, second: ICrop): number =>
    Date_SafeCompare(first.endDate, second.endDate),

  descendingSortFunc: (_: IColumn<ICrop>, first: ICrop, second: ICrop): number =>
    Date_SafeCompare(second.endDate, first.endDate),

  groupFunc: (_: IColumn<ICrop>, data: ICrop): IGroup => {
    const [facility] = GetItem('facilities', EStorage.EphemeralStorage)
    const zonedDate = utcToZonedTime(createDate(data.endDate), facility.timezone)
    const day = startOfDay(zonedDate)
    const formattedDay = format(
      zonedDate,
      'PP',
      { timeZone: facility.timezone }
    )

    return {
      id: `${_.id}::${day}`,
      name: formattedDay,
      data: []
    }
  }
})

// TODO: Rewrite this
const TransplantDateColumn: IColumn<ICrop> = Object.freeze({
  id: 'crop::transplant_date',
  name: 'Transplant Date',
  searchFunc: (_: IColumn<ICrop>, search: string, data: ICrop): boolean =>
    Date_SafeIncludes(data.transplantDate, search),

  ascendingSortFunc: (_: IColumn<ICrop>, first: ICrop, second: ICrop): number =>
    Date_SafeCompare(first.transplantDate, second.transplantDate),

  descendingSortFunc: (_: IColumn<ICrop>, first: ICrop, second: ICrop): number => Date_SafeCompare(second.transplantDate, first.transplantDate),

  groupFunc: (_: IColumn<ICrop>, data: ICrop): IGroup => {
    const [facility] = GetItem('facilities', EStorage.EphemeralStorage)
    const zonedDate = utcToZonedTime(createDate(data.transplantDate), facility.timezone)
    const day = startOfDay(zonedDate)
    const formattedDay = format(
      zonedDate,
      'PP',
      { timeZone: facility.timezone }
    )

    return {
      id: `${_.id}::${day}`,
      name: formattedDay,
      data: []
    }
  }
})

const NameColumn: IColumn<ICrop> = Object.freeze({
  id: 'crop::name',
  name: 'Name',
  searchFunc: (_: IColumn<ICrop>, search: string, data: ICrop): boolean =>
    String_SafeIncludes(data.name, search),

  ascendingSortFunc: (_: IColumn<ICrop>, first: ICrop, second: ICrop): number =>
    String_SafeCompare(first.name, second.name),

  descendingSortFunc: (_: IColumn<ICrop>, first: ICrop, second: ICrop): number =>
    String_SafeCompare(second.name, first.name),

  groupFunc: (_: IColumn<ICrop>, data: ICrop): IGroup => ({
    id: `${_.id}::${data.name}`,
    name: data.name,
    data: []
  })
})

const CodeColumn: IColumn<ICrop> = Object.freeze({
  id: 'crop::code',
  name: 'Code',
  searchFunc: (_: IColumn<ICrop>, search: string, data: ICrop): boolean =>
    String_SafeIncludes(data.code, search),

  ascendingSortFunc: (_: IColumn<ICrop>, first: ICrop, second: ICrop): number =>
    String_SafeCompare(first.code, second.code),

  descendingSortFunc: (_: IColumn<ICrop>, first: ICrop, second: ICrop): number =>
    String_SafeCompare(second.code, first.code),

  groupFunc: (_: IColumn<ICrop>, data: ICrop): IGroup => ({
    id: `${_.id}::${data.code}`,
    name: data.code,
    data: []
  })
})

/**
 * TODO: Implement columns...
 * - maturityDays
 * - productCode
 */

const SpeciesColumn: IColumn<ICrop> = Object.freeze({
  id: 'crop::species',
  name: 'Species',
  searchFunc: (_: IColumn<ICrop>, search: string, data: ICrop): boolean =>
    String_SafeIncludes(data.speciesName, search),

  ascendingSortFunc: (_: IColumn<ICrop>, first: ICrop, second: ICrop): number =>
    String_SafeCompare(first.speciesName, second.speciesName),

  descendingSortFunc: (_: IColumn<ICrop>, first: ICrop, second: ICrop): number =>
    String_SafeCompare(second.speciesName, first.speciesName),

  groupFunc: (_: IColumn<ICrop>, data: ICrop): IGroup => ({
    id: `${_.id}::${data.speciesName}`,
    name: data.speciesName,
    data: []
  })
})

const VarietyColumn: IColumn<ICrop> = Object.freeze({
  id: 'crop::variety',
  name: 'Variety',
  searchFunc: (_: IColumn<ICrop>, search: string, data: ICrop): boolean =>
    String_SafeIncludes(data.varietyName, search),

  ascendingSortFunc: (_: IColumn<ICrop>, first: ICrop, second: ICrop): number =>
    String_SafeCompare(first.varietyName, second.varietyName),

  descendingSortFunc: (_: IColumn<ICrop>, first: ICrop, second: ICrop): number =>
    String_SafeCompare(second.varietyName, first.varietyName),

  groupFunc: (_: IColumn<ICrop>, data: ICrop): IGroup => ({
    id: `${_.id}::${data.varietyName}`,
    name: data.varietyName,
    data: []
  })
})

const NicknameColumn: IColumn<ICrop> = Object.freeze({
  id: 'crop::nickname',
  name: 'Nickname',
  searchFunc: (_: IColumn<ICrop>, search: string, data: ICrop): boolean =>
    String_SafeIncludes(data.nickname, search),

  ascendingSortFunc: (_: IColumn<ICrop>, first: ICrop, second: ICrop): number =>
    String_SafeCompare(first.nickname, second.description),

  descendingSortFunc: (_: IColumn<ICrop>, first: ICrop, second: ICrop): number =>
    String_SafeCompare(second.nickname, first.description),

  groupFunc: (_: IColumn<ICrop>, data: ICrop): IGroup => ({
    id: `${_.id}::${data.nickname}`,
    name: data.nickname,
    data: []
  })
})

const DescriptionColumn: IColumn<ICrop> = Object.freeze({
  id: 'crop::description',
  name: 'Description',
  searchFunc: (_: IColumn<ICrop>, search: string, data: ICrop): boolean =>
    String_SafeIncludes(data.description, search),

  ascendingSortFunc: (_: IColumn<ICrop>, first: ICrop, second: ICrop): number =>
    String_SafeCompare(first.description, second.description),

  descendingSortFunc: (_: IColumn<ICrop>, first: ICrop, second: ICrop): number =>
    String_SafeCompare(second.description, first.description),

  groupFunc: (_: IColumn<ICrop>, data: ICrop): IGroup => ({
    id: `${_.id}::${data.description}`,
    name: data.description,
    data: []
  })
})

const ContainerSizeColumn: IColumn<ICrop> = Object.freeze({
  id: 'crop::container-size',
  name: 'Container Size',
  searchFunc: (_: IColumn<ICrop>, search: string, data: ICrop): boolean =>
    String_SafeIncludes(data.containerSize, search),

  ascendingSortFunc: (_: IColumn<ICrop>, first: ICrop, second: ICrop): number =>
    String_SafeCompare(first.containerSize, second.containerSize),

  descendingSortFunc: (_: IColumn<ICrop>, first: ICrop, second: ICrop): number =>
    String_SafeCompare(second.containerSize, first.containerSize),

  groupFunc: (_: IColumn<ICrop>, data: ICrop): IGroup => ({
    id: `${_.id}::${data.containerSize}`,
    name: data.containerSize,
    data: []
  })
})

const SerialColumn: IColumn<ICrop> = Object.freeze({
  id: 'crop::serial',
  name: 'Serial',
  searchFunc: (_: IColumn<ICrop>, search: string, data: ICrop): boolean =>
    searchOnArray(arrayOfSerials(data), search),
  ascendingSortFunc: (_: IColumn<ICrop>, first: ICrop, second: ICrop): number =>
    1,

  descendingSortFunc: (_: IColumn<ICrop>, first: ICrop, second: ICrop): number =>
    1,

  groupFunc: (_: IColumn<ICrop>, data: ICrop): IGroup => ({
    id: `${_.id}::${data.name}`,
    name: data.name,
    data: []
  })
})

export {
  StartDateColumn,
  TransplantDateColumn,
  EndDateColumn,
  NameColumn,
  CodeColumn,
  SpeciesColumn,
  VarietyColumn,
  NicknameColumn,
  DescriptionColumn,
  ContainerSizeColumn,
  SerialColumn
}

import { Options as HighChartsOptions } from 'highcharts';

// Types
import EEntity from 'graphql-lib/enums/entity';
import IEntity from 'graphql-lib/interfaces/entity';
import ISpace from 'graphql-lib/interfaces/ISpace';
import ICrop from 'graphql-lib/interfaces/ICrop';
import IInventory from 'graphql-lib/interfaces/IInventory';
import ID from 'graphql-lib/interfaces/ID';

// Libs
import { saveAs } from 'file-saver';

// React
import React, {
  useCallback,
  useState
} from 'react';

// React Libs
import { useIter } from 'react-lib/use-iter';

// UI Libs
import Highcharts from 'highcharts/highstock';
import HighchartsReact from 'highcharts-react-official';
import Button from 'ui-lib/components/button';

// Effects
import { UpdateOptions } from './effects';

import { createDate } from 'utils/dates';

const papaparse = require('papaparse');
const { unparse } = papaparse;
export interface IChartsCanvasProps {
  relativeCharting?: boolean;
  selectedEntities?: IEntity[];
  spaceIDs?: ID[];
  cropIDs?: ID[];

  spaces?: ISpace[];
  crops?: ICrop[];
  inventories?: IInventory[];

  spacesCache?: Record<ID, ISpace>;
  spaceChartsCache?: Record<ID, any>;

  cropsCache?: Record<ID, ICrop>;
  inventoriesCache?: Record<ID, IInventory[]>;
  inventoryChartsCache?: Record<ID, any>;

  selectedEntitiesUUID?: string;
  spacesUUID?: string;
  inventoriesUUID?: string;
}

const ChartsCanvas = ({
  relativeCharting,
  selectedEntities,
  crops,
  inventories,
  cropIDs,
  cropsCache,
  inventoriesCache,
  inventoryChartsCache,
  selectedEntitiesUUID,
  inventoriesUUID
}: IChartsCanvasProps): JSX.Element => {
  // State /////////////////////////////////////////////////////////////////////

  const [options, setOptions] = useState<HighChartsOptions>()

  // Effects ///////////////////////////////////////////////////////////////////

  useIter(
    UpdateOptions,
    {
      relativeCharting,
      selectedEntities,
      cropIDs,
      crops,
      inventories,
      cropsCache,
      inventoriesCache,
      inventoryChartsCache,
      setOptions
    },
    [
      selectedEntitiesUUID,
      inventoriesUUID
    ]
  )

  // Callbacks /////////////////////////////////////////////////////////////////

  const $downloadCSV = useCallback(
    (): void => {
      const selectedInventoryCharts = selectedEntities
        .filter((e): boolean => e.type === EEntity.Chart)
        .map((e): any => {
          const inventoryChart = inventoryChartsCache[e.metadata.inventoryID][e.metadata.chartKey]

          const _ = {
            ...e.metadata,
            values: inventoryChart
          }

          return _
        })
      type CsvColsObjType = Record<string, any>;
      const csvColsObj: CsvColsObjType = { createdOn: true }
      const csvObj: CsvColsObjType = {}
      selectedInventoryCharts.forEach((c: IEntity): void => {
        const crop = cropsCache[c.cropID]
        const inventory = inventories.find((i): boolean => Number(i.id) === Number(c.inventoryID))

        c.values.forEach((v): void => {
          csvColsObj[`${crop.name} - ${inventory.code} - ${c.chartKey}`] = true
          let timeslot = csvObj[v.createdOn]
          if (timeslot) {
            Object.assign(
              timeslot,
              {
                [`${crop.name} - ${inventory.code} - ${c.chartKey}`]: v.value
              }
            )
          } else {
            timeslot = {
              createdOn: v.createdOn,
              [`${crop.name} - ${inventory.code} - ${c.chartKey}`]: v.value
            }

            csvObj[v.createdOn] = timeslot
          }
        })
      })
  
      const csvColsArr: Array<keyof CsvColsObjType> = Object.keys(csvColsObj)
      const csvArr = Object.values(csvObj)
        .sort((a, b) => a.createdOn - b.createdOn)
        .map((a): any => ({
          ...a,
          createdOn: createDate(a.createdOn)
        }))

      const csvStr = unparse(csvArr, { columns: csvColsArr })
      const csvBlob = new Blob([csvStr], { type: 'text/plain;charset=utf-8' });
      saveAs(csvBlob, `LUNA CSV Export - ${new Date().toISOString()}.csv`)
    },
    [selectedEntitiesUUID, inventoriesUUID]
  )

  // Render ////////////////////////////////////////////////////////////////////

  if (!options || options.series.length === 0) {
    return null
  }

  return (
    <div className='ChartsCanvas'>
      <HighchartsReact
        options={options}
        highcharts={Highcharts}
        constructorType='stockChart'
        containerProps={{ className: 'Highcharts' }}
      />

      <div className='Actions'>
        <Button
          animated
          outlined
          onClick={$downloadCSV}
        >
          Download CSV
        </Button>
      </div>
    </div>
  )
};

export default ChartsCanvas

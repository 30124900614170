/**
 * @author Miras Absar <mabsar@iunu.com>
 */

// Utils
import Has from 'utils/has'
import {
  EStorage,
  EStorageAction,
  GetItem,
  SetItem,
  RemoveItem
} from 'utils/storage'

// Libs
import { v4 } from 'uuid'

// React & React Libs
import {
  useCallback,
  useState
} from 'react'
import { useEvent } from 'react-lib/use-event'
import { useIter } from 'react-lib/use-iter'

/**
 * Update data based on storage.
 */
const Storage2Data = ({
  type,
  key,
  setData2Storage,
  setData,
  setDataUUID
}: {
  type: EStorage;
  key: string;

  setData2Storage: (newVal: boolean) => void;
  setData: (newVal: any) => void;
  setDataUUID: (newVal: string) => void;
}): void => {
  const newData = GetItem(key, type)
  const newDataUUID = v4()

  setData2Storage(false)
  setData(newData)
  setDataUUID(newDataUUID)
}

/**
 * Update storage based on data
 */
const Data2Storage = ({
  type,
  key,
  data2Storage,
  data
}: {
  type: EStorage;
  key: string;
  data2Storage: boolean;
  data: any;
}): void => {
  if (data2Storage) {
    if (Has(data)) {
      SetItem(key, data, type)
    } else {
      RemoveItem(key, type)
    }
  }
}

/**
 * Use LocalStorage, SessionStorage, or EphemeralStorage as state.
 *
 * @param key - A `DOMString` containing the name of the key you want to remove.
 * @param type - An enumeration of a `Storage`.
 *
 * @example
 * const [count, setCount, countUUID] = useStorage('count')
 *
 * const incrementCount = useCallback(
 *   (): void => {
 *     const newCount = count + 1
 *     setCount(newCount)
 *   },
 *   [countUUID]
 * )
 */
const useStorage = (key: string, type: EStorage = EStorage.LocalStorage): [
  any,
  (newVal: any) => void,
  string | undefined
] => {
  // State /////////////////////////////////////////////////////////////////////

  const [data2Storage, setData2Storage] = useState<boolean>()
  const [data, setData] = useState<any>(GetItem(key, type))
  const [dataUUID, setDataUUID] = useState<string>()

  // Effects ///////////////////////////////////////////////////////////////////

  // Update data based on storage (on SetItem events).
  useEvent(
    window,
    EStorageAction.SetItem,
    (): void => {
      Storage2Data({
        type,
        key,
        setData2Storage,
        setData,
        setDataUUID
      })
    },
    [dataUUID]
  )

  // Update data based on storage (on RemoveItem events).
  useEvent(
    window,
    EStorageAction.RemoveItem,
    (): void => {
      Storage2Data({
        type,
        key,
        setData2Storage,
        setData,
        setDataUUID
      })
    },
    [dataUUID]
  )

  // Update data based on storage (on Clear events).
  useEvent(
    window,
    EStorageAction.Clear,
    (): void => {
      Storage2Data({
        type,
        key,
        setData2Storage,
        setData,
        setDataUUID
      })
    },
    [dataUUID]
  )

  // Update data based on storage (initially).
  useIter(
    Storage2Data,

    { type,
      key,
      setData2Storage,
      setData,
      setDataUUID },

    []
  )

  // Update storage based on data
  useIter(
    Data2Storage,

    { type,
      key,
      data2Storage,
      data },

    [data]
  )

  // Callbacks /////////////////////////////////////////////////////////////////

  const _setData = useCallback(
    (newVal: any): void => {
      setData2Storage(true)
      setData(newVal)
    },
    [dataUUID]
  )

  // Return ////////////////////////////////////////////////////////////////////

  return [
    data,
    _setData,
    dataUUID
  ]
}

export default useStorage

import Flag from '!raw-loader!@fortawesome/fontawesome-pro/svgs/solid/flag.svg'

// hybrids
import { html, define, render } from 'hybrids'
import { IOverlayRender } from '../types';

const TaskRollupOverlay: IOverlayRender<null> = {
  render: render((params: { count: Record<'value', number>, urgent: Record<'value', number> }) => {
    const { count, urgent } = params;

    const countString = Number(count.value) > 9
      ? '9+'
      : count.value

    const countContainerClass = urgent.value
      ? ['CountContainer', 'Urgent']
      : ['CountContainer']

    return html`
      <div
        class='TaskRollupOverlay Raised Animated'
      >
        <div class='${countContainerClass}'>
          <div>${countString}</div>
        </div>

        <div class='IconContainer' innerHTML='${Flag}'>/div>
      </div>
    `
  }, { shadowRoot: false })
}

define('task-rollup-overlay', TaskRollupOverlay)

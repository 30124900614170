/**
 * @author Miras Absar <mabsar@iunu.com>
 */

// Types
import EInputStatus from 'ui-lib/enums/input-status'
import IInputStatus from 'ui-lib/interfaces/IInputStatus'

// GraphQL
import { gql } from '@apollo/client'
import client from 'graphql-lib/index'

// Queries
const QueryProduct = gql`
  query($code: String) {
    product(code: $code) {
      id
    }
  }
`

const ProductCode = async (val: string): Promise<IInputStatus> => {
  if (/^$/.test(val)) {
    return {
      status: EInputStatus.OK,
      message: ''
    }
  }

  const {
    error,
    data
  } = await client.query({
    query: QueryProduct,
    variables: { code: val }
  })

  if (
    error ||
    data.product.length === 0
  ) {
    return {
      status: EInputStatus.Error,
      message: 'Invalid'
    }
  }

  return {
    status: EInputStatus.OK,
    message: ''
  }
}

const RequiredProductCode = async (val: string): Promise<IInputStatus> => {
  if (/^\s*$/.test(val)) {
    return {
      status: EInputStatus.Error,
      message: 'Required'
    }
  }

  const {
    error,
    data
  } = await client.query({
    query: QueryProduct,
    variables: { code: val }
  })

  if (
    error ||
    data.product.length === 0
  ) {
    return {
      status: EInputStatus.Error,
      message: 'Invalid'
    }
  }

  return {
    status: EInputStatus.OK,
    message: ''
  }
}

export {
  ProductCode,
  RequiredProductCode
}

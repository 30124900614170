// Types
import ICrop from 'graphql-lib/interfaces/ICrop';
import ID from 'graphql-lib/interfaces/ID';
import { ICustomEvent } from 'crops/components/crops-view';
import { FetchResult } from '@apollo/client';

// Libs
import { v4 } from 'uuid'

// React
import {
  useCallback,
  useRef,
  useState
} from 'react'

// React Libs
import {
  useIter,
  useAsyncIter
} from 'react-lib/use-iter'

import { useEvent } from 'react-lib/use-event'

// GraphQL
import client from 'graphql-lib/index'
import { UpdateCrop } from './queries'

// Effects
import {
  Init,
  QueryCrop
} from './effects'

const useCrop = (id: ID, columns: string[]): {
  loading: boolean;
  error: Error;
  crop: ICrop;
  uuid: string;
  update: (update: Partial<ICrop>) => void;
} => {
  // State /////////////////////////////////////////////////////////////////////

  const hookID = useRef<string>()
  const [loading, setLoading] = useState<boolean>()
  const [error, setError] = useState<Error>()
  const [crop, setCrop] = useState<ICrop>()
  const [uuid, setUUID] = useState<string>()

  // Effects ///////////////////////////////////////////////////////////////////

  useIter(
    Init,
    { hookID },
    [id]
  )

  useAsyncIter(
    QueryCrop,

    { id,
      columns,

      setLoading,
      setError,
      setCrop,
      setUUID },

    [ id,
      ...columns ]
  )

  useEvent(
    window,
    'update_crop',
    (ev: ICustomEvent<ICrop>): void => {
      if (Number(ev.detail.update.id) === Number(id) &&
          ev.detail.dispatcherID !== hookID.current) {
        const newCrop = Object.assign(
          {},
          crop,
          ev.detail.update
        )

        setError(undefined)
        setCrop(newCrop)
        setUUID(v4())
      }
    },
    [uuid]
  )

  // Callbacks /////////////////////////////////////////////////////////////////

  const update = useCallback(
    async (update: any): Promise<{ error?: Error; crop?: ICrop; }> => {
      setLoading(true)
      setUUID(v4())

      update.id = id
      let error: Error
      let data: { Crop: { update: ICrop } };
      try {
        const response: FetchResult<{ Crop: { update: ICrop } }> = await client.query({
          fetchPolicy: 'no-cache',
          query: UpdateCrop,
          variables: update
        })

        data = response.data
      } catch (err) {
        error = err as Error
      }

      setLoading(false)
      if (error) {
        setError(error)
        setUUID(v4())

        return { error }
      } else if (data) {
        const cropUpdate = (Object.keys(update) as (keyof ICrop)[])
          .map((key): [keyof ICrop, any] =>
            [key, data.Crop.update[key]])

          .reduce(
            (cropUpdate, [key, value]) =>
              Object.assign(
                cropUpdate,
                { [key]: value }
              ),
            {}
          )

        const newCrop = Object.assign(
          {},
          crop,
          cropUpdate
        )

        setError(undefined)
        setCrop(newCrop)
        setUUID(v4())

        const ev = new CustomEvent(
          'update_crop',
          {
            detail: {
              dispatcherID: hookID.current,
              update
            }
          }
        )

        window.dispatchEvent(ev)

        return { crop: newCrop }
      }

      return {}
    },
    [uuid]
  )

  // Return ////////////////////////////////////////////////////////////////////

  return {
    loading,
    error,
    crop,
    uuid,

    update
  }
}

export default useCrop

/**
 * @author Miras Absar <mabsar@iunu.com>
 */

import { createContext } from 'react'

export interface INotificationContext {
  notifications: any[];
  setNotifications: (newVal: any[]) => void;
}

const NotificationContext = createContext<INotificationContext>({
  notifications: null,
  setNotifications: (): void => {}
})

export default NotificationContext

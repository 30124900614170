// React
import React, {
  CSSProperties,
  MouseEvent,
  useCallback,
  useState
} from 'react';

// React Libs
import { useIter } from 'react-lib/use-iter';

// Effects
import {
  SetRatingColor,
  SetSpeciesIcon,
  SetVarietyColor
} from './effects';

// FontAwesome
import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStar as faStarSolid } from '@fortawesome/pro-solid-svg-icons';
import { faStar as faStarOutline } from '@fortawesome/pro-regular-svg-icons';

// Types
import ICrop from 'graphql-lib/interfaces/ICrop';
import ID from 'graphql-lib/interfaces/ID';
export interface IProductProps {
  crop: Partial<ICrop>;
  readOnly?: boolean;
  onChangeRating?: (cropId: ID, newVal: number) => void;
}

const Product = ({
  crop,
  readOnly,
  onChangeRating
}: IProductProps): JSX.Element => {
  // State /////////////////////////////////////////////////////////////////////

  const [ratingColor, setRatingColor] = useState<string>()
  const [speciesIcon, setSpeciesIcon] = useState<IconDefinition>()
  const [varietyColor, setVarietyColor] = useState<string>()

  // Effects ///////////////////////////////////////////////////////////////////

  useIter(
    SetRatingColor,
    { crop, setRatingColor },
    [crop]
  )

  useIter(
    SetSpeciesIcon,
    { crop, setSpeciesIcon },
    [crop]
  )

  useIter(
    SetVarietyColor,
    { crop, setVarietyColor },
    [crop]
  )

  // Callbacks /////////////////////////////////////////////////////////////////

  const rating$change = useCallback(
    (ev: MouseEvent<SVGElement>): void => {
      if (!readOnly && onChangeRating) {
        ev.preventDefault()
        ev.stopPropagation()

        const newRating = !crop.rating
          ? 5
          : 0

        onChangeRating(crop.id, newRating)
      }
    },

    [ crop,
      readOnly,
      onChangeRating ]
  )

  // Render ////////////////////////////////////////////////////////////////////

  let ratingIconClassName = 'RatingIcon'
  if (!readOnly && onChangeRating) ratingIconClassName += ' Clickable'

  const stackStyle: CSSProperties = { position: 'absolute' }

  return (
    <div className="Theme__Dark CropTile">
      <div className="RatingContainer">
        {!readOnly || !!crop.rating
          ? (crop.rating > 0
              ? <FontAwesomeIcon
                className={ratingIconClassName}
                icon={faStarSolid}
                color='gold'
                onClick={rating$change}
              />
              : <div style={{
                position: 'absolute',
                transform: 'translate(21px, -5px)'
              }}>
                <FontAwesomeIcon
                  className={ratingIconClassName}
                  icon={faStarOutline}
                  color='gray'
                  style={{ ...stackStyle, zIndex: 2 }}
                  onClick={rating$change}
                />
                <FontAwesomeIcon
                  className={ratingIconClassName}
                  icon={faStarSolid}
                  color='#AAAAAA'
                  style={{ ...stackStyle, zIndex: 1 }}
                  onClick={rating$change}
                />
              </div>
            )
          : null}
      </div>

      {speciesIcon && varietyColor && (
        <div className='SpeciesVarietyContainer'>
          <FontAwesomeIcon
            className='SpeciesVarietyIcon'
            icon={speciesIcon}
            color={varietyColor}
          />
        </div>
      )}
    </div>
  );
}

export default Product

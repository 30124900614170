/**
 * @author Miras Absar <mabsar@iunu.com>
 */

import React from 'react'
import DatetimePicker from 'react-datetime-picker'

import EInputStatus from '../../enums/input-status'
import IInputFacadeProps from '../../interfaces/IInputFacadeProps'
import Status2ClassName from '../../utils/Status2ClassName'

export default ({
  /* Visual Properties */
  animated,
  label,

  /* Behavioral Properties */
  name,
  value,
  status,
  touched,
  dirty,
  showStatusUntouched,
  onBlur,
  onChange,
  readOnly
}: IInputFacadeProps<Date>): JSX.Element => {
  const className = animated ? 'Input Animated' : 'Input'
  const calendarClassName = animated ? 'Raised Animated' : 'Raised'
  const showingStatus = (touched || dirty || showStatusUntouched) &&
    status.status !== EInputStatus.Uninitialized &&
    status.status !== EInputStatus.None

  const statusClassName = Status2ClassName[status.status]

  const $blur = (): void => {
    if (name && onBlur) {
      onBlur(name)
    }
  }

  const $input = (newValue: Date): void => {
    if (name && onChange) {
      onChange(name, newValue)
    }
  }

  return (
    <div className={className}>
      <label>{label}</label>

      <DatetimePicker
        calendarClassName={calendarClassName}
        name={name}
        value={value}
        onBlur={$blur}
        onChange={$input}
        disabled={readOnly}
        disableClock
      />

      {showingStatus
        ? <p className={statusClassName}>{status.message}</p>
        : null}
    </div>
  )
}

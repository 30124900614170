// Utils
import { EStorage, GetItem } from 'utils/storage'

// Types
import ETab from '../../enums/tab'
import { ITab } from 'ui-lib/components/tab'

// Libs
import { v4 } from 'uuid'

// React
import React, {
  useCallback,
  useContext,
  useEffect,
  useState,
  useMemo
} from 'react'

// React Libs
import { useIter } from 'react-lib/use-iter'

// Effects
import { UpdateTab } from './effects'

// Contexts
import Mobius from '../../contexts/mobius'
import { TaskListProvider } from './navigation/tasks-list/contexts/TaskListContext'
import { TaskIssueListProvider } from './navigation/task-issues-list/contexts/TaskIssueListContext'

// UI Lib
import Navigation from 'ui-lib/components/navigation'
import Tabs from 'ui-lib/components/tabs'
import FacilityList from './navigation/facility-list'
import PlantsList from './navigation/plants-list'
import TasksList from './navigation/tasks-list'
import TaskIssueList from './navigation/task-issues-list'
import Overlays from './overlays'
import ResetZoom from './reset-zoom'
import TimeTravel from './time-travel'
import Areacams from './areacams'
import SensorChartsButton from './sensor-charts-button'
// import SensorChartsCarousel from './sensor-charts-carousel'

const facilityTab: ITab = {
  id: ETab.Facility,
  label: 'Facility'
}

const plantsTab: ITab = {
  id: ETab.Plants,
  label: 'Crops'
}

const tasksTab: ITab = {
  id: ETab.Flags,
  label: 'Tasks'
}

const taskIssuesTab: ITab = {
  id: ETab.Issues,
  label: 'Issues'
}

export const tabs = [
  facilityTab,
  plantsTab,
  tasksTab,
  taskIssuesTab
]

const MapControls = (): JSX.Element => {
  // Context ///////////////////////////////////////////////////////////////////

  const {
    searchParams: {
      searchParams,
      setSearchParams,
      searchParamsUUID
    },
    selectedEntities: {
      setSelectedEntities
    }
  } = useContext(Mobius)

  // State /////////////////////////////////////////////////////////////////////

  const [tab, setTab] = useState<ITab>()
  const [creatingTaskIssue, setCreatingTaskIssue] = useState<boolean>(false) // Turns issue into flag
  const [creatingTask, setCreatingTask] = useState<boolean>(false)
  const [viewingTask, setViewingTask] = useState<number|undefined>(undefined)
  const [viewingTaskIssue, setViewingTaskIssue] = useState<number|undefined>(undefined)

  // Effects ///////////////////////////////////////////////////////////////////

  const customer = GetItem('customer', EStorage.EphemeralStorage)

  useIter(
    UpdateTab,
    { defaultTab: plantsTab,
      tabs,
      searchParams,

      setSearchParams,
      setTab },
    [searchParamsUUID]
  );

  // Callbacks /////////////////////////////////////////////////////////////////

  const toggleNavigation = useCallback(
    (): void => {
      const newNavigationOpen = !searchParams.navigationOpen
        ? true
        : undefined

      const newSearchParams = Object.assign(
        {},
        searchParams,
        { navigationOpen: newNavigationOpen }
      )

      setSearchParams(newSearchParams)
    },
    [searchParamsUUID]
  )

  const setTabWithSearchParams = useCallback(
    (newVal: ITab): void => {
      const newSearchParams = Object.assign(
        {},
        searchParams,
        { navigationTab: newVal.id }
      )

      setSearchParams(newSearchParams)
    },
    [searchParamsUUID]
  )

  // Render ////////////////////////////////////////////////////////////////////

  const head = (
    <Tabs
      animated

      tabs={tabs}
      selectedTab={tab}
      onSelect={setTabWithSearchParams}
    />
  )

  const body = (
    <>
      <FacilityList
        animated
        invisible={String(tab?.id) !== String(facilityTab.id)}
      />

      <PlantsList
        animated
        invisible={String(tab?.id) !== String(plantsTab.id)}
      />

      {/* TaskListProvider was brought up to top level for possible issueDetection v2 changes (may split flags/issues into separate tasks lists).
      Currently reduced to one task list due to state management issues since they share the same data structure. */}
      <TaskListProvider value={{
        creatingTask,
        viewingTask,
        tasksTab,
        setViewingTask,
        setCreatingTask,
        setTabButNotSearchParams: setTab
      }}>
        {/* Flags & Issues */}
        <TasksList
          animated
          invisible={String(tab?.id) !== tasksTab.id}
        />
      </TaskListProvider>

      <TaskIssueListProvider value={{
        creatingTaskIssue,
        viewingTaskIssue,
        taskIssuesTab,
        setCreatingTaskIssue,
        setViewingTaskIssue,
        setTabButNotSearchParams: setTab,
        setSelectedEntities
      }}>
        <TaskIssueList
          animated
          invisible={tab?.id !== taskIssuesTab.id}
          tabs={tabs}
        />
      </TaskIssueListProvider>
    </>
  )

  return (
    <div className='MapControls'>
      <Navigation
        raised
        animated

        open={searchParams.navigationOpen}
        toggleOpen={toggleNavigation}

        head={head}
        body={body}
      />

      <div className='Padded'>
        <Overlays
          raised
          animated
        />
      </div>

      <div className='Space' />

      <div className='Padded'>
        <ResetZoom
          raised
          animated
        />
      </div>

      <div className='Padded'>
        <TimeTravel
          raised
          animated
        />
      </div>

      <div className='Padded'>
        <Areacams
          raised
          animated
        />

        <div className='Padding' />

        <SensorChartsButton
          raised
          animated
        />
      </div>

      {/*
      <SensorChartsCarousel
        raised
        animated
      />
      */}
    </div>
  )
}
export default MapControls

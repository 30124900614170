/**
 * @author Miras Absar <mabsar@iunu.com>
 */

// Types
import EEntity from 'map-view/enums/entity'
import EChart from 'map-view/enums/chart'

// Utils
import { ChartOrder } from 'map-view/utils/chart'
import IEntity from 'map-view/interfaces/entity'
import ISpace from 'graphql-lib/interfaces/ISpace'
import IChart from 'map-view/interfaces/chart'

const UpdateState = ({
  selectedEntities,
  space,
  spaceCharts,
  setSelected,
  setCharts
}: {
  selectedEntities: Array<IEntity>
  space: ISpace
  spaceCharts: Array<IChart>
  setSelected: (value: boolean) => void
  setCharts: (value:Array<IChart>) => void
}): void => {
  if (
    !selectedEntities ||
    !space ||
    !spaceCharts
  ) {
    return
  }

  const newSelected = !!selectedEntities
    .find(entity =>
      entity.type === EEntity.Space &&
      String(entity.id) === String(space.id))

  const newCharts = spaceCharts
    .filter(chart =>
      chart.parentType === EEntity.Space &&
      String(chart.parentID) === String(space.id))
    .sort((chartA, chartB) =>
      ChartOrder[chartA.chart] - ChartOrder[chartB.chart]);

  setSelected(newSelected);
  setCharts(newCharts);
}

export { UpdateState }

/**
 * @author Miras Absar <mabsar@iunu.com>
 */

// Libs
import {
  isAfter,
  isSameSecond
} from 'date-fns'

const UpdateNow = ({
  time,
  setNow
}: {
  time: Date,
  setNow: (newVal: Date) => void;
}): void => {
  if (time) {
    setNow(time)
  }
}

const UpdateMetadata = ({
  time,
  now,
  setIsPast,
  setIsDifferent,
  setIsFuture
}: {
  time: Date;
  now: Date;
  setIsPast: (newVal: boolean) => void;
  setIsDifferent: (newVal: boolean) => void;
  setIsFuture: (newVal: boolean) => void;
}): void => {
  if (time && now) {
    const realNow = new Date()
    const newIsPast = isAfter(realNow, time)
    const newIsDifferent = !isSameSecond(time, now)
    const newIsFuture = isAfter(now, realNow)

    setIsPast(newIsPast)
    setIsDifferent(newIsDifferent)
    setIsFuture(newIsFuture)
  }
}

export {
  UpdateNow,
  UpdateMetadata
}

/**
 * @author Miras Absar <mabsar@iunu.com>
 */

// Styles

// Types
import ICustomer from 'graphql-lib/interfaces/ICustomer'

// React
import React, {
  useCallback,
  useState
} from 'react'

// React Libs
import { useQuery } from '@apollo/client'
import { useIter } from 'react-lib/use-iter'

// Queries & Effects
import { QueryCustomers } from './queries'
import { UpdateState } from './effects'

interface ISelectCustomerCardProps {
  animated?: boolean;
  outlined?: boolean;
  resting?: boolean;
  raised?: boolean;

  onSelect?: (newCustomer: ICustomer) => void;
}

const SelectCustomerCard = ({
  animated,
  outlined,
  resting,
  raised,

  onSelect
}: ISelectCustomerCardProps): JSX.Element | null => {
  // State /////////////////////////////////////////////////////////////////////

  const [loading, setLoading] = useState<boolean>()
  const [error, setError] = useState<Error>()
  const [customers, setCustomers] = useState<ICustomer[]>()

  // API ///////////////////////////////////////////////////////////////////////

  const {
    loading: customersLoading,
    error: customersError,
    data: customersData
  } = useQuery(QueryCustomers)

  // Effects ///////////////////////////////////////////////////////////////////

  useIter(
    UpdateState,

    { customersLoading,
      customersError,
      customersData,

      setLoading,
      setError,
      setCustomers },

    [ customersLoading,
      customersError,
      customersData ]
  )  

  // Callbacks /////////////////////////////////////////////////////////////////

  const $select = useCallback(
    (newCustomer: ICustomer): void => onSelect?.(newCustomer),
    [onSelect]
  )

  // Render ////////////////////////////////////////////////////////////////////

  let className = 'Card SelectCustomerCard'
  if (animated) className += ' Animated'
  if (outlined) className += ' Outlined'
  if (resting) className += ' Resting'
  if (raised) className += ' Raised'

  if (loading || error) {
    return (
      <article className={className}>
        <div className='Head'>
          <h6 className='Primary Loading' />
        </div>

        <div className='Body'>
          <div className='Customer Loading' />
          <div className='Customer Loading' />
          <div className='Customer Loading' />
        </div>
      </article>
    )
  }

  if (customers) {
    return (
      <article className={className}>
        <div className='Head'>
          <h6 className='Primary'>Select a Customer</h6>
        </div>

        <div className='Body'>
          {customers.map((c): JSX.Element => (
            <div
              key={c.id}
              className='Customer'
              onClick={(): void => $select(c)}
            >
              {c.name}
            </div>
          ))}
        </div>
      </article>
    )
  }

  return null
}

export default SelectCustomerCard

// Types
import EDiff from '../../enums/diff'
import EEntity from '../../enums/entity'
import EOverlay from '../../enums/overlay'
import EVisibility from '../../enums/visibility'
import EOverlayThresholds from '../../enums/overlay-thresholds'

// Utils
import has from 'utils/has'
import isVoid from 'utils/is-void'
import {
  ScalePT,
  CreateAreacamOverlays,
  DeleteAreacamOverlays,
  CreateSensorOverlays,
  DeleteSensorOverlays,
  CreateTaskOverlays,
  DeleteTaskOverlays,
  CreateTaskIssueOverlays,
  DeleteTaskIssueOverlays,
  CreateTagOverlays,
  DeleteTagOverlays
} from './utils'

// Libs
import { isEqual } from 'date-fns'
import moment from 'moment'
import { v4 } from 'uuid'

// Proj4
import proj4 from 'proj4'
import { register } from 'ol/proj/proj4'
import Projection from 'ol/proj/Projection'
import { addProjection, addCoordinateTransforms } from 'ol/proj'
import ProjectiveTransformation from '../../utils/projective-transformation'

// OpenLayers - Sources
import OSMSource from 'ol/source/OSM'
import BingMaps from 'ol/source/BingMaps'
import VectorSource from 'ol/source/Vector'
import ZoomifySource from '../../utils/zoomify'
import ImageSource from 'ol/source/ImageStatic'

// OpenLayers - Layers
import LayerGroup from 'ol/layer/Group'
import TileLayer from 'ol/layer/Tile'
import VectorLayer from 'ol/layer/Vector'
import ImageLayer from 'ol/layer/Image'
import { Options as VectorLayerOptions } from 'ol/layer/BaseVector'
import { Options as TileLayerOptions } from 'ol/layer/BaseTile'
import { Options as ImageLayerOptions } from 'ol/layer/BaseImage'

// OpenLayers - Extent
import {
  extend,
  containsExtent,
  intersects as intersectsExtent,
  containsXY,
  Extent,
  getCenter
} from 'ol/extent'

// OpenLayers - Styles
import {
  Facility as FacilityStyles,
  Building as BuildingStyles,
  Space as SpaceStyles,
  Inventory as InventoryStyles,
  Container as ContainerStyles
} from './styles'

// OpenLayers - Misc
import {
  defaults as DefaultInteractions,
  Interaction,
  PinchZoom
} from 'ol/interaction';

import LineString from 'ol/geom/LineString'
import Polygon from 'ol/geom/Polygon'
import Feature from 'ol/Feature'
import GeoJSON from 'ol/format/GeoJSON'
import Style from 'ol/style/Style'
import View from 'ol/View'
import tileLoadFunction from '../../utils/tile-load-function'
import imageLoadFunction from '../../utils/image-load-function'
import IGridPrediction, { IGridPredictionWithoutContainer } from 'graphql-lib/interfaces/IGridPrediction'
import { GraphQLError } from 'graphql'
import IFacility from 'graphql-lib/interfaces/IFacility'
import IBuilding from 'graphql-lib/interfaces/IBuilding'
import ISpace from 'graphql-lib/interfaces/ISpace'
import IInventoryStitch from 'graphql-lib/interfaces/IInventoryStitch'
import IHeatmap from 'graphql-lib/interfaces/IHeatmap'
import IContainerStitch from 'graphql-lib/interfaces/IContainerStitch'
import IUser from 'graphql-lib/interfaces/IUser'
import { ISensor } from 'graphql-lib/interfaces/ISensorDevice'
import IAreacam from 'graphql-lib/interfaces/IAreacam'
import IInventory from 'graphql-lib/interfaces/IInventory'
import IContainer from 'map-view/interfaces/container'
import ITask from 'graphql-lib/interfaces/ITask'
import ITaskIssue from 'graphql-lib/interfaces/ITaskIssue'
import { Collection, Map, Overlay } from 'ol'
import IDiff from 'map-view/interfaces/diff'
import IEntity from 'map-view/interfaces/entity'
import IContainerMeta from 'map-view/interfaces/container-meta'
import { LayersForMap } from '.'
import ITime from 'map-view/interfaces/time'
import ISetStateType from 'graphql-lib/interfaces/ISetStateType';
import ISpaceMeta from 'map-view/interfaces/space-meta'
import { IInventoryMeta } from 'map-view/interfaces/metas'

export type IStyleObject = Record<string, Style>

const InitOpenLayers = ({
  interactions,
  layers,
  facilityLayers,
  buildingLayers,
  spaceLayers,
  inventoryVectorLayers,
  inventoryRasterLayers,
  containerVectorLayers,
  containerRasterLayers,
  heatmapLayers,
  setInteractionsUUID,
  setLayersUUID
}: {
  interactions: Collection<Interaction>
  layers: Collection<LayersForMap>
  facilityLayers: LayersForMap
  buildingLayers: LayersForMap
  spaceLayers: LayersForMap
  inventoryVectorLayers: LayersForMap
  inventoryRasterLayers: LayersForMap
  containerVectorLayers: LayersForMap
  containerRasterLayers: LayersForMap
  heatmapLayers: LayersForMap
  setInteractionsUUID: ISetStateType<string>
  setLayersUUID: ISetStateType<string>
}): void => {
  interactions.extend([
    ...DefaultInteractions().getArray(),
    new PinchZoom()
  ])

  layers.extend([
    facilityLayers,
    buildingLayers,
    spaceLayers,
    inventoryVectorLayers,
    inventoryRasterLayers,
    containerVectorLayers,
    containerRasterLayers,
    heatmapLayers
  ])

  setInteractionsUUID(v4())
  setLayersUUID(v4())
}

const UpdateOpenLayersSize = ({
  mapRef,
  carouselActive
}: {
  mapRef: Map
  carouselActive: boolean
}): void => {
  if (mapRef && !carouselActive) {
    setTimeout(
      (): void => {
        mapRef.updateSize()
      },
      17
    )
  }
}

const UpdateOpenLayersExtent = ({
  selectedEntities: { selectedEntitiesDiff },
  view,
  facilityLayers,
  buildingLayers,
  spaceLayers,
  inventoryVectorLayers,
  containerVectorLayers,
  overlays
}:
{
  selectedEntities: {
    selectedEntitiesDiff: Array<IDiff<IEntity>>
  }
  view: View
  facilityLayers: LayersForMap
  buildingLayers: LayersForMap
  spaceLayers: LayersForMap
  inventoryVectorLayers: LayersForMap
  containerVectorLayers: LayersForMap
  overlays: Collection<Overlay>
}
): void => {
  if (
    !selectedEntitiesDiff ||
    !view ||
    !facilityLayers ||
    !buildingLayers ||
    !spaceLayers ||
    !inventoryVectorLayers ||
    !containerVectorLayers ||
    !overlays
  ) {
    return
  }

  const newExtent = selectedEntitiesDiff
    .filter((diff) => diff.type === EDiff.Add)
    .map((diff): Extent => {
      if (diff.value.type === EEntity.Facility) {
        const vectorLayer = facilityLayers
          .getLayers()
          .getArray()
          .filter(l => l instanceof VectorLayer)
          .find(l =>
            l.get('type') === diff.value.type &&
            String(l.get('id')) === String(diff.value.id))

        if (vectorLayer) {
          const newExtent = vectorLayer
            .getSource()
            .getExtent()

          return newExtent
        }

        return
      }

      if (diff.value.type === EEntity.Building) {
        const vectorLayer = buildingLayers
          .getLayers()
          .getArray()
          .filter(l => l instanceof VectorLayer)
          .find(l =>
            l.get('type') === diff.value.type &&
            String(l.get('id')) === String(diff.value.id))

        if (vectorLayer) {
          const newExtent = vectorLayer
            .getSource()
            .getExtent()

          return newExtent
        }

        return
      }

      if (diff.value.type === EEntity.Space) {
        const vectorLayer = spaceLayers
          .getLayers()
          .getArray()
          .filter(l => l instanceof VectorLayer)
          .find(l =>
            l.get('type') === diff.value.type &&
            String(l.get('id')) === String(diff.value.id))

        if (vectorLayer) {
          const newExtent = vectorLayer
            .getSource()
            .getExtent();
          return getCenter(newExtent) as Extent;
        }

        return
      }

      if (diff.value.type === EEntity.Inventory) {
        const vectorLayer = inventoryVectorLayers
          .getLayers()
          .getArray()
          .filter(l => l instanceof VectorLayer)
          .find(l =>
            l.get('type') === diff.value.type &&
            String(l.get('id')) === String(diff.value.id))

        if (vectorLayer) {
          const newExtent = vectorLayer
            .getSource()
            .getExtent()

          return newExtent
        }

        return
      }

      if (diff.value.type === EEntity.Container) {
        const vectorLayer = containerVectorLayers
          .getLayers()
          .getArray()
          .filter(l => l instanceof VectorLayer)
          .find(l =>
            l.get('type') === diff.value.type &&
            String(l.get('id')) === String(diff.value.id))

        if (vectorLayer) {
          const newExtent = vectorLayer
            .getSource()
            .getExtent()

          return newExtent
        }

        return
      }

      if (diff.value.type === EEntity.Task || diff.value.type === EEntity.TaskIssue) {
        const overlay = overlays.getArray()
          .find((overlay) => {
            const type = overlay.get('type')
            const id = overlay.get('id')

            return diff.value.type === type &&
              diff.value.id === id
          })
        if (overlay) {
          const point = overlay.get('point');
          // NOTE: to increase by 200%, we shrink to 1/3 of the original extent size
          const xMid = (point[0] + 1 + point[0] - 1) / 2
          const yMid = (point[1] + 1 + point[1] - 1) / 2
          const newExtent: Extent = [xMid - 0.33, yMid - 0.33, xMid + 0.33, yMid + 0.33]
          return newExtent
        }

        return
      }
    })
    .filter(newExtent => !!newExtent)
    .reduce(
      (newExtent, currentExtent): Extent => {
        if (!newExtent) {
          return currentExtent
        }

        extend(newExtent, currentExtent)

        return newExtent
      },
      undefined
    )
    const disabledZoomEntities = selectedEntitiesDiff
                                .filter(action => action.type === 'Diff.Add')
                                .some(entityAction => [EEntity.Space].includes(entityAction.value.type));

    if(!disabledZoomEntities){
      setTimeout(
        (): void => {
          if (newExtent) {
            view.fit(
              newExtent,
              { duration: 300 }
            )
          }
        },
        17
      )
    } else {
      view.setCenter(newExtent);
    }
}

const UpdateFacilityLayers = ({
  facility: {
    facilityLoading,
    facilityError,
    facility
  },

  view,
  facilityLayers,
  setFacilityLayersUUID
}: {
  facility: {
    facilityLoading: boolean
    facilityError: GraphQLError
    facility: IFacility & { projectionCode: string, projectionDefinition: string, mapType: string, geojson: string, mapRotation: number }
  },

  view: any,
  facilityLayers: any,
  setFacilityLayersUUID: (value: string) => void
}): void => {
  if (
    facilityLoading ||
    facilityError ||
    !facility
  ) {
    return
  }

  // Dispose old Facility layers & view.
  // Dispose layers.
  let index = facilityLayers
    .getLayers()
    .getLength() - 1

  while (index >= 0) {
    facilityLayers
      .getLayers()
      .removeAt(index)
      .dispose()

    index--
  }

  // Dispose view.
  if (view.current) {
    view.current.dispose()
    view.current = null
  }

  if (
    isVoid(facility.projectionCode) ||
    isVoid(facility.projectionDefinition) ||
    isVoid(facility.mapType) ||
    isVoid(facility.geojson) ||
    isVoid(facility.mapRotation)
  ) {
    throw new Error('Facility does not support Map View.')
  }

  // Create new Facility layers & view.
  proj4.defs(facility.projectionCode, facility.projectionDefinition)
  register(proj4)

  // Create layers.
  let newBackgroundLayer: TileLayer
  switch (facility.mapType) {
    case 'None':
      break
    case 'Vector':
      newBackgroundLayer = new TileLayer({
        zIndex: 0,
        source: new OSMSource({
          crossOrigin: null,
          url: 'https://tile.openstreetmap.org/{z}/{x}/{y}.png'
        })
      })
      break
    case 'Raster':
      newBackgroundLayer = new TileLayer({
        zIndex: 0,
        source: new BingMaps({
          key: 'As6-AAfHsWLgejnpdAgA3cbHqXNgn7hah9GiPYMFsszVzecZj1i-ascO03-yyr_Y',
          imagerySet: 'Aerial',
          maxZoom: 19
        })
      })
      break
    default:
      throw new Error(`Unknown Facility Map Type: ${facility.mapType}.`)
      // break
  }

  const newSource = new VectorSource({
    features: new GeoJSON().readFeatures(facility.geojson)
  })

  const newLayer = new VectorLayer({
    type: EEntity.Facility,
    id: facility.id,

    zIndex: 1,
    source: newSource,
    style: FacilityStyles.Default
  } as VectorLayerOptions)

  const newExtent: Extent = [...newSource.getExtent()]
  newExtent[0] = newExtent[0] - 10
  newExtent[2] = newExtent[2] + 10
  newExtent[1] = newExtent[1] - 10
  newExtent[3] = newExtent[3] + 10

  // Create view.
  const newView = new View({
    maxZoom: 31,
    showFullExtent: true,
    projection: facility.projectionCode,
    rotation: facility.mapRotation
  })

  view.current = newView
  if (newBackgroundLayer) {
    facilityLayers
      .getLayers()
      .extend([newBackgroundLayer])
  }

  facilityLayers
    .getLayers()
    .extend([newLayer])

  setFacilityLayersUUID(v4())

  setTimeout(
    () => {
      newView.fit(newExtent)

      const calculatedExtent = newView.calculateExtent()
      const updatedOptions = newView.getUpdatedOptions_({
        extent: calculatedExtent
      })

      newView.applyOptions_(updatedOptions)
    },
    17
  )
}

const UpdateBuildingLayers = ({
  buildings: {
    buildingsLoading,
    buildingsError,
    buildings
  },
  buildingLayers,
  setBuildingLayersUUID
}:{
  buildings: {
    buildingsLoading: boolean
    buildingsError: Error
    buildings: Array<IBuilding>
  },
  buildingLayers: LayersForMap,
  setBuildingLayersUUID: ISetStateType<string>
}): void => {
  if (
    buildingsLoading ||
    buildingsError ||
    !buildings
  ) {
    return
  }

  // Dispose old Building layers.
  let index = buildingLayers
    .getLayers()
    .getLength() - 1

  while (index >= 0) {
    buildingLayers
      .getLayers()
      .removeAt(index)
      .dispose()

    index--
  }

  // Create new Building layers.
  buildings
    .filter(b => has(b.geojson))
    .filter(b => Object.keys(b.geojson).length !== 0)
    .forEach(b => {
      const newSource = new VectorSource({
        features: new GeoJSON().readFeatures(b.geojson)
      })

      const newLayer = new VectorLayer({
        type: EEntity.Building,
        id: b.id,

        zIndex: 2,
        source: newSource,
        style: BuildingStyles.Default
      } as VectorLayerOptions)

      buildingLayers
        .getLayers()
        .push(newLayer)
    })

  setBuildingLayersUUID(v4())
}

const UpdateSpaceLayers = ({
  spaces: {
    spacesLoading,
    spacesError,
    spaces
  },
  spaceMetas,
  spaceLayers,
  setSpaceLayersUUID
}: {
  spaces: {
    spacesLoading: boolean
    spacesError: Error
    spaces: Array<ISpace>
  },
  spaceMetas: { current: ISpaceMeta }
  spaceLayers: LayersForMap
  setSpaceLayersUUID: (value: string) => void
}): void => {
  if (
    spacesLoading ||
    spacesError ||
    !spaces
  ) {
    return
  }

  // Dispose old Space metas & layers.

  // Dispose metas.
  spaceMetas.current = {}

  // Dispose layers.
  let index = spaceLayers
    .getLayers()
    .getLength() - 1

  while (index >= 0) {
    spaceLayers
      .getLayers()
      .removeAt(index)
      .dispose()

    index--
  }

  // Create new Space layers.
  spaces
    .filter(b => has(b.geojson))
    .filter(b => Object.keys(b.geojson).length !== 0)
    .forEach(s => {
      // Create meta.
      const width =
        new LineString(s.geojson.features[0].coordinates[0].slice(0, 2))
          .getLength()

      const height =
        new LineString(s.geojson.features[0].coordinates[0].slice(1, 3))
          .getLength()

      const heightScale = height / width

      const srcPts = [
        [ScalePT(0), ScalePT(0 * heightScale)],
        [ScalePT(1), ScalePT(0 * heightScale)],
        [ScalePT(1), ScalePT(1 * heightScale)],
        [ScalePT(0), ScalePT(1 * heightScale)]
      ]

      const dstPts: Array<number[]> = s.geojson.features[0].coordinates[0].slice(0, 4)

      const projectiveTransformation =
        new ProjectiveTransformation(srcPts, dstPts)

      // Create layer.
      const newSource = new VectorSource({
        features: new GeoJSON().readFeatures(s.geojson)
      })

      const newLayer = new VectorLayer({
        type: EEntity.Space,
        id: s.id,

        zIndex: 3,
        source: newSource,
        style: SpaceStyles.Default
      } as VectorLayerOptions)

      const extent = newSource.getExtent()

      spaceMetas.current[s.id] = {
        width,
        height,
        heightScale,
        srcPts,
        dstPts,
        projectiveTransformation,
        extent
      }

      spaceLayers
        .getLayers()
        .push(newLayer)
    })

  setSpaceLayersUUID(v4())
}

const UpdateInventoryVectorLayers = ({
  time: {
    time
  },
  gridPredictions: {
    gridPredictionsLoading,
    gridPredictionsError,
    gridPredictions,
    gridPredictionsTime
  },
  spaceMetas,
  inventoryMetas,
  inventoryVectorLayers,
  setInventoryVectorLayersUUID
}: {
  time: {
    time: ITime
  },
  gridPredictions: {
    gridPredictionsLoading: boolean
    gridPredictionsError: Error
    gridPredictions: Array<IGridPredictionWithoutContainer>
    gridPredictionsTime: Date
  },
  spaceMetas: ISpaceMeta
  inventoryMetas: { current: IInventoryMeta }
  inventoryVectorLayers: LayersForMap
  setInventoryVectorLayersUUID: ISetStateType<string>
}): void => {
  if (
    !time ||
    gridPredictionsLoading ||
    gridPredictionsError ||
    !gridPredictions ||
    !isEqual(time.now, gridPredictionsTime) ||
    Object.entries(spaceMetas).length === 0
  ) {
    return
  }

  // Dispose old Inventory metas & layers.

  // Dispose metas.
  inventoryMetas.current = {}

  // Dispose layers.
  let index = inventoryVectorLayers
    .getLayers()
    .getLength() - 1

  while (index >= 0) {
    inventoryVectorLayers
      .getLayers()
      .removeAt(index)
      .dispose()

    index--
  }

  // Create new Inventory metas & layers.
  gridPredictions
    .filter(gridPrediction => 'tl' in Object.values(gridPrediction.grid)[0])
    .forEach(gridPrediction => {
      const spaceMeta = spaceMetas[gridPrediction.spaceId]

      Object
        .entries(gridPrediction.grid)
        .forEach(([inventoryId, cell]) => {
          // Create meta.
          const {
            tl: [l, t],
            br: [r, b]
          } = cell

          const srcPts = [
            [ScalePT(0), ScalePT(0)],
            [ScalePT(1), ScalePT(0)],
            [ScalePT(1), ScalePT(1)],
            [ScalePT(0), ScalePT(1)]
          ]

          const dstPts = [
            [ScalePT(l), ScalePT(t * spaceMeta.heightScale)],
            [ScalePT(r), ScalePT(t * spaceMeta.heightScale)],
            [ScalePT(r), ScalePT(b * spaceMeta.heightScale)],
            [ScalePT(l), ScalePT(b * spaceMeta.heightScale)]
          ]
            .reverse()
            .map(pt => spaceMeta.projectiveTransformation.transform(pt))

          const width =
            new LineString(dstPts.slice(0, 2))
              .getLength()

          const height =
            new LineString(dstPts.slice(1, 3))
              .getLength()

          const aspectRatio = width / height

          const projectiveTransformation =
            new ProjectiveTransformation(srcPts, dstPts)

          // Create layer.
          const newPolygon = new Polygon([dstPts])
          const newFeature = new Feature(newPolygon)
          const newSource = new VectorSource({
            features: [newFeature]
          })

          const newLayer = new VectorLayer({
            type: EEntity.Inventory,
            id: inventoryId,

            zIndex: 4,
            source: newSource,
            style: InventoryStyles.Default
          } as VectorLayerOptions)

          newLayer.set('meta', { gridPredictionID: gridPrediction.id })

          inventoryMetas.current[inventoryId] = {
            srcPts,
            dstPts,
            width,
            height,
            aspectRatio,
            projectiveTransformation,
            extent: newSource.getExtent()
          }

          inventoryVectorLayers
            .getLayers()
            .push(newLayer)
        })
    })

  setInventoryVectorLayersUUID(v4())
}

const UpdateInventoryRasterLayers = ({
  searchParams: {
    noarm
  },
  time: {
    time
  },
  facility: {
    facility
  },
  gridPredictions: {
    gridPredictionsLoading,
    gridPredictionsError,
    gridPredictions,
    gridPredictionsTime
  },
  inventoryStitches: {
    inventoryStitchesLoading,
    inventoryStitchesError,
    inventoryStitches,
    inventoryStitchesTime
  },
  spaceMetas,
  inventoryMetas,
  inventoryRasterLayers,
  setInventoryRasterLayersUUID
}: {
  searchParams: {
    noarm: string
  },
  time: {
    time: ITime
  },
  facility: {
    facility: IFacility
  },
  gridPredictions: {
    gridPredictionsLoading: boolean
    gridPredictionsError: Error
    gridPredictions: Array<IGridPredictionWithoutContainer>
    gridPredictionsTime: Date
  },
  inventoryStitches: {
    inventoryStitchesLoading: boolean
    inventoryStitchesError: Error
    inventoryStitches: Array<IInventoryStitch>
    inventoryStitchesTime: Date
  },
  spaceMetas: ISpaceMeta
  inventoryMetas: { current: IInventoryMeta }
  inventoryRasterLayers: LayersForMap
  setInventoryRasterLayersUUID: ISetStateType<string>
}): void => {
  if (
    !time ||
    gridPredictionsLoading ||
    gridPredictionsError ||
    !gridPredictions ||
    inventoryStitchesLoading ||
    inventoryStitchesError ||
    !inventoryStitches ||
    !isEqual(time.now, gridPredictionsTime) ||
    !isEqual(time.now, inventoryStitchesTime) ||
    Object.entries(spaceMetas).length === 0 ||
    Object.entries(inventoryMetas).length === 0
  ) {
    return
  }

  // Dispose old Inventory layers.
  let index = inventoryRasterLayers
    .getLayers()
    .getLength() - 1

  while (index >= 0) {
    inventoryRasterLayers
      .getLayers()
      .removeAt(index)
      .dispose()

    index--
  }

  // Create new Inventory layers.
  gridPredictions
    .filter(gridPrediction => 'tl' in Object.values(gridPrediction.grid)[0])
    .forEach(gridPrediction => {
      const spaceMeta = spaceMetas[gridPrediction.spaceId]

      Object
        .entries(gridPrediction.grid)
        .forEach(([inventoryId, cell]) => {
          const inventoryMeta = inventoryMetas.current[inventoryId]
          const inventoryStitch = inventoryStitches
            .find(inventoryStitch => {
              const match =
                String(inventoryStitch.inventoryId) === String(inventoryId) &&
                has(inventoryStitch.width) &&
                has(inventoryStitch.height) &&
                has(inventoryStitch.tilesUrl)

              const aspectRatio = inventoryStitch.width / inventoryStitch.height
              const aspectRatioMismatch = (
                inventoryMeta.aspectRatio > aspectRatio
                  ? inventoryMeta.aspectRatio / aspectRatio
                  : aspectRatio / inventoryMeta.aspectRatio
              ) * 100 - 100

              return match && (aspectRatioMismatch <= 75 || noarm || Number(inventoryStitch.customerId) === 1214)
            })

          if (!inventoryStitch) {
            return
          }

          const even = Number(inventoryStitch.id) % 2 === 0
          const {
            tl: [l],
            br: [r]
          } = cell

          const projectionCode =
            `Grid Prediction ID: ${gridPrediction.id}, Inventory Stitch ID: ${inventoryStitch.id}`

          const projectionMetersPerUnit =
            (r - l) * spaceMeta.width / inventoryStitch.width

          const srcPts = [
            [0, 0],
            [inventoryStitch.width, 0],
            [inventoryStitch.width, inventoryStitch.height],
            [0, inventoryStitch.height]
          ]

          const projection = new Projection({
            code: projectionCode,
            units: 'pixels',
            extent: [
              0,
              0,
              inventoryStitch.width,
              inventoryStitch.height
            ],
            metersPerUnit: projectionMetersPerUnit,
            getPointResolution: (ptRes: any) => ptRes
          })

          const projectiveTransformation =
            new ProjectiveTransformation(srcPts, inventoryMeta.dstPts)

          addProjection(projection)
          addCoordinateTransforms(
            facility.projectionCode,
            projectionCode,
            pt => projectiveTransformation.inverseTransform(pt),
            pt => projectiveTransformation.transform(pt)
          )

          let tilesUrl = inventoryStitch.tilesUrl
          if (even) {
            const split = tilesUrl
              .replace('https://', '')
              .replace('http://', '')
              .split('/')

            const domain = 'https://' + split
              .splice(0, 2)
              .reverse()
              .join('.')

            const path = split
              .join('/')

            tilesUrl = domain + '/' + path
          }

          const newSource = new ZoomifySource({
            crossOrigin: 'anonymous',
            projection: projectionCode,
            url: `${tilesUrl}/{TileGroup}/{z}-{x}-{y}.webp`,
            size: [
              inventoryStitch.width,
              inventoryStitch.height
            ],
            extent: [
              0,
              0,
              inventoryStitch.width,
              inventoryStitch.height
            ]
          })

          if (!window.supportsWebp) {
            newSource.setTileLoadFunction(tileLoadFunction)
          }

          const newLayer = new TileLayer({
            type: EEntity.Inventory,
            id: inventoryId,

            extent: inventoryMeta.extent,
            zIndex: 5,
            source: newSource
          } as TileLayerOptions)

          newLayer.set('meta', {
            gridPredictionID: gridPrediction.id,
            inventoryStitchID: inventoryStitch.id
          })

          inventoryMetas.current[inventoryId] = {
            ...inventoryMeta,
            inventoryStitchID: inventoryStitch.id
          }

          inventoryRasterLayers
            .getLayers()
            .push(newLayer)
        })
    })

  setInventoryRasterLayersUUID(v4())
}

const UpdateInventoryHeatmapLayers = ({
  time: {
    time
  },
  facility: {
    facility
  },
  gridPredictions: {
    gridPredictionsLoading,
    gridPredictionsError,
    gridPredictions,
    gridPredictionsTime
  },
  heatmaps: {
    heatmapsLoading,
    heatmapsError,
    heatmaps,
    heatmapsTime
  },
  spaceMetas,
  inventoryMetas,
  heatmapLayers,
  setHeatmapLayersUUID
}: {
  time: {
    time: ITime
  },
  facility: {
    facility: IFacility
  },
  gridPredictions: {
    gridPredictionsLoading: boolean
    gridPredictionsError: Error
    gridPredictions: Array<IGridPredictionWithoutContainer>
    gridPredictionsTime: Date
  },
  heatmaps: {
    heatmapsLoading: boolean
    heatmapsError: Error
    heatmaps: Array<IHeatmap>
    heatmapsTime: Date
  },
  spaceMetas: ISpaceMeta
  inventoryMetas: { current: IInventoryMeta }
  heatmapLayers: LayersForMap
  setHeatmapLayersUUID: ISetStateType<string>
}): void => {
  if (
    !time ||
    gridPredictionsLoading ||
    gridPredictionsError ||
    !gridPredictions ||
    heatmapsLoading ||
    heatmapsError ||
    !heatmaps ||
    !isEqual(time.now, gridPredictionsTime) ||
    !isEqual(time.now, heatmapsTime) ||
    Object.entries(spaceMetas).length === 0 ||
    Object.entries(inventoryMetas).length === 0
  ) {
    return
  }

  // Dispose old Inventory layers.
  let index = heatmapLayers
    .getLayers()
    .getLength() - 1

  while (index >= 0) {
    heatmapLayers
      .getLayers()
      .removeAt(index)
      .dispose()

    index--
  }

  // Create new Inventory layers.
  gridPredictions
    .filter(gridPrediction => 'tl' in Object.values(gridPrediction.grid)[0])
    .forEach(gridPrediction => {
      const spaceMeta = spaceMetas[gridPrediction.spaceId]

      Object
        .entries(gridPrediction.grid)
        .forEach(([inventoryId, cell]) => {
          const heatmap = heatmaps
            .find(heatmap =>
              heatmap.inventoryId === inventoryId &&
              has(heatmap.url) &&
              has(heatmap.width) &&
              has(heatmap.height))

          if (!heatmap) {
            return
          }

          const inventoryMeta = inventoryMetas.current[String(inventoryId)]
          const even = Number(heatmap.id) % 2 === 0
          const {
            tl: [l, t],
            br: [r, b]
          } = cell

          const projectionCode =
            `Grid Prediction ID: ${gridPrediction.id}, Heatmap ID: ${heatmap.id}`

          const projectionMetersPerUnit =
            (r - l) * spaceMeta.width / heatmap.width

          const srcPts = [
            [0, 0],
            [heatmap.width, 0],
            [heatmap.width, heatmap.height],
            [0, heatmap.height]
          ]

          const projection = new Projection({
            code: projectionCode,
            units: 'pixels',
            extent: [
              0,
              0,
              heatmap.width,
              heatmap.height
            ],
            metersPerUnit: projectionMetersPerUnit,
            getPointResolution: (ptRes: any) => ptRes
          })

          const projectiveTransformation =
            new ProjectiveTransformation(srcPts, inventoryMeta.dstPts)

          addProjection(projection)
          addCoordinateTransforms(
            facility.projectionCode,
            projectionCode,
            pt => projectiveTransformation.inverseTransform(pt),
            pt => projectiveTransformation.transform(pt)
          )

          let url = heatmap.url
          if (even) {
            const split = url
              .replace('https://', '')
              .replace('http://', '')
              .split('/')

            const domain = 'https://' + split
              .splice(0, 2)
              .reverse()
              .join('.')

            const path = split
              .join('/')

            url = domain + '/' + path
          }

          const newSource = new ImageSource({
            crossOrigin: 'anonymous',
            projection,
            url,
            imageExtent: [
              0,
              0,
              heatmap.width,
              heatmap.height
            ],
            imageSize: [
              heatmap.width,
              heatmap.height
            ],
            imageLoadFunction
          })

          const newLayer = new ImageLayer({
            type: EEntity.Inventory,
            id: inventoryId,

            extent: inventoryMeta.extent,
            zIndex: 6,
            source: newSource,
            visible: false
          } as ImageLayerOptions)

          newLayer.set('meta', {
            gridPredictionID: gridPrediction.id,
            inventoryStitchID: heatmap.id
          })

          inventoryMetas.current[String(inventoryId)] = {
            ...inventoryMeta,
            heatmapID: heatmap.id
          }

          heatmapLayers
            .getLayers()
            .push(newLayer)
        })
    })

  setHeatmapLayersUUID(v4())
}

const UpdateContainerVectorLayers = ({
  time: {
    time
  },
  gridPredictions: {
    gridPredictionsLoading,
    gridPredictionsError,
    gridPredictions,
    gridPredictionsTime
  },
  spaceMetas,
  containerMetas,
  containerVectorLayers,
  setContainerVectorLayersUUID
}: {
  time: {
    time: ITime
  },
  gridPredictions: {
    gridPredictionsLoading: boolean
    gridPredictionsError: Error
    gridPredictions: Array<IGridPrediction>
    gridPredictionsTime: Date
  },
  spaceMetas: ISpaceMeta
  containerMetas: { current: IContainerMeta }
  containerVectorLayers: LayersForMap
  setContainerVectorLayersUUID: ISetStateType<string>
}): void => {
  if (
    !time ||
    gridPredictionsLoading ||
    gridPredictionsError ||
    !gridPredictions ||
    !isEqual(time.now, gridPredictionsTime) ||
    Object.entries(spaceMetas).length === 0
  ) {
    return
  }

  // Dispose old Container metas & layers.

  // Dispose metas.
  containerMetas.current = {}

  // Dispose layers.
  let index = containerVectorLayers
    .getLayers()
    .getLength() - 1

  while (index >= 0) {
    containerVectorLayers
      .getLayers()
      .removeAt(index)
      .dispose()

    index--
  }

  // Create new Container metas & layers
  gridPredictions
    .filter(gridPrediction => !('tl' in Object.values(gridPrediction.grid)[0]))
    .forEach(gridPrediction => {
      const spaceMeta = spaceMetas[gridPrediction.spaceId]

      Object
        .entries(gridPrediction.grid)
        .forEach(([inventoryId, grid]) => {
          Object
            .entries(grid)
            .forEach(([containerId, cell]) => {
              // Create meta.
              const {
                tl: [l, t],
                br: [r, b]
              } = cell

              const srcPts = [
                [ScalePT(0), ScalePT(0)],
                [ScalePT(1), ScalePT(0)],
                [ScalePT(1), ScalePT(1)],
                [ScalePT(0), ScalePT(1)]
              ]

              const dstPts = [
                [ScalePT(l), ScalePT(t * spaceMeta.heightScale)],
                [ScalePT(r), ScalePT(t * spaceMeta.heightScale)],
                [ScalePT(r), ScalePT(b * spaceMeta.heightScale)],
                [ScalePT(l), ScalePT(b * spaceMeta.heightScale)]
              ]
                .reverse()
                .map(pt => spaceMeta.projectiveTransformation.transform(pt))

              const width =
                new LineString(dstPts.slice(0, 2))
                  .getLength()

              const height =
                new LineString(dstPts.slice(1, 3))
                  .getLength()

              const aspectRatio = width / height

              const projectiveTransformation =
                new ProjectiveTransformation(srcPts, dstPts)

              // Create layer.
              const newPolygon = new Polygon([dstPts])
              const newFeature = new Feature(newPolygon)
              const newSource = new VectorSource({
                features: [newFeature]
              })

              const newLayer = new VectorLayer({
                type: EEntity.Container,
                id: containerId,

                zIndex: 4,
                source: newSource,
                style: ContainerStyles.Default
              } as VectorLayerOptions)

              newLayer.set('parentType', EEntity.Inventory)
              newLayer.set('parentID', inventoryId)
              newLayer.set('meta', { gridPredictionID: gridPrediction.id })

              containerMetas.current[containerId] = {
                srcPts,
                dstPts,
                width,
                height,
                aspectRatio,
                projectiveTransformation,
                extent: newSource.getExtent(),
                vectorLayerIndex: containerVectorLayers.getLayers().getLength()
              }

              containerVectorLayers
                .getLayers()
                .push(newLayer)
            })
        })
    })

  setContainerVectorLayersUUID(v4())
}

const UpdateContainerRasterLayers = ({
  searchParams: {
    noarm
  },
  time: {
    time
  },
  facility: {
    facility
  },
  gridPredictions: {
    gridPredictionsLoading,
    gridPredictionsError,
    gridPredictions,
    gridPredictionsTime
  },
  containerStitches: {
    containerStitchesLoading,
    containerStitchesError,
    containerStitches,
    containerStitchesTime
  },
  spaceMetas,
  containerMetas,
  containerRasterLayers,
  setContainerRasterLayersUUID
}: {
  searchParams: {
    noarm: string
  },
  time: {
    time: {
      now: Date
    }
  },
  facility: {
    facility: IFacility
  },
  gridPredictions: {
    gridPredictionsLoading: boolean
    gridPredictionsError: Error
    gridPredictions: Array<IGridPrediction>
    gridPredictionsTime: Date
  },
  containerStitches: {
    containerStitchesLoading: boolean
    containerStitchesError: Error
    containerStitches: Array<IContainerStitch>
    containerStitchesTime: Date
  },
  spaceMetas: ISpaceMeta
  containerMetas: { current: IContainerMeta }
  containerRasterLayers: LayersForMap
  setContainerRasterLayersUUID: ISetStateType<string>
}): void => {
  if (
    !time ||
    gridPredictionsLoading ||
    gridPredictionsError ||
    !gridPredictions ||
    containerStitchesLoading ||
    containerStitchesError ||
    !containerStitches ||
    !isEqual(time.now, gridPredictionsTime) ||
    !isEqual(time.now, containerStitchesTime) ||
    Object.entries(spaceMetas).length === 0 ||
    Object.entries(containerMetas).length === 0
  ) {
    return
  }

  // Dispose old Container layers.
  let index = containerRasterLayers
    .getLayers()
    .getLength() - 1

  while (index >= 0) {
    containerRasterLayers
      .getLayers()
      .removeAt(index)
      .dispose()

    index--
  }

  // Create new Container layers.
  gridPredictions
    .filter(gridPrediction => !('tl' in Object.values(gridPrediction.grid)[0]))
    .forEach(gridPrediction => {
      const spaceMeta = spaceMetas[gridPrediction.spaceId]

      Object
        .entries(gridPrediction.grid)
        .forEach(([inventoryId, grid]) => {
          Object
            .entries(grid)
            .forEach(([containerId, cell]) => {
              const containerMeta = containerMetas.current[containerId]
              const containerStitch = containerStitches
                .find(containerStitch => {
                  const match =
                    String(containerStitch.inventoryId) === String(inventoryId) &&
                    String(containerStitch.containerId) === String(containerId) &&
                    has(containerStitch.width) &&
                    has(containerStitch.height) &&
                    has(containerStitch.tilesUrl)

                  const aspectRatio = containerStitch.width / containerStitch.height
                  const aspectRatioMismatch = (
                    containerMeta.aspectRatio > aspectRatio
                      ? containerMeta.aspectRatio / aspectRatio
                      : aspectRatio / containerMeta.aspectRatio
                  ) * 100 - 100

                  return match && (aspectRatioMismatch <= 75 || noarm || containerStitch.customerId == 1214)
                })

              if (!containerStitch) {
                return
              }

              const even = Number(containerStitch.id) % 2 === 0
              const {
                tl: [l, t],
                br: [r, b]
              } = cell

              const projectionCode =
                `Grid Prediction ID: ${gridPrediction.id}, Container Stitch ID: ${containerStitch.id}`

              const projectionMetersPerUnit =
                (r - l) * spaceMeta.width / containerStitch.width

              const srcPts = [
                [0, 0],
                [containerStitch.width, 0],
                [containerStitch.width, containerStitch.height],
                [0, containerStitch.height]
              ]

              const projection = new Projection({
                code: projectionCode,
                units: 'pixels',
                extent: [
                  0,
                  0,
                  containerStitch.width,
                  containerStitch.height
                ],
                metersPerUnit: projectionMetersPerUnit,
                getPointResolution: (ptRes: any) => ptRes
              })

              const projectiveTransformation =
                new ProjectiveTransformation(srcPts, containerMeta.dstPts)

              addProjection(projection)
              addCoordinateTransforms(
                facility.projectionCode,
                projectionCode,
                pt => projectiveTransformation.inverseTransform(pt),
                pt => projectiveTransformation.transform(pt)
              )

              let tilesUrl = containerStitch.tilesUrl
              if (even) {
                const split = tilesUrl
                  .replace('https://', '')
                  .replace('http://', '')
                  .split('/')

                const domain = 'https://' + split
                  .splice(0, 2)
                  .reverse()
                  .join('.')

                const path = split
                  .join('/')

                tilesUrl = domain + '/' + path
              }

              const newSource = new ZoomifySource({
                crossOrigin: 'anonymous',
                projection: projectionCode,
                url: tilesUrl + '/{TileGroup}/{z}-{x}-{y}.webp',
                tileSize: containerStitch.tileSize,
                size: [
                  containerStitch.width,
                  containerStitch.height
                ],
                extent: [
                  0,
                  0,
                  containerStitch.width,
                  containerStitch.height
                ]
              })

              if (!window.supportsWebp) {
                newSource.setTileLoadFunction(tileLoadFunction)
              }

              const newLayer = new TileLayer({
                type: EEntity.Container,
                id: containerId,

                extent: containerMeta.extent,
                zIndex: 5,
                source: newSource
              } as TileLayerOptions)

              newLayer.set('parentType', EEntity.Inventory)
              newLayer.set('parentID', inventoryId)
              newLayer.set('meta', {
                gridPredictionID: gridPrediction.id,
                containerStitchID: containerStitch.id
              })

              containerMetas.current[containerId] = {
                ...containerMeta,
                rasterLayerIndex: containerRasterLayers.getLayers().getLength(),
                containerStitchID: containerStitch.id
              }

              containerRasterLayers
                .getLayers()
                .push(newLayer)
            })
        })
    })

  setContainerRasterLayersUUID(v4())
}

const UpdateContainerHeatmapLayers = ({
  time: {
    time
  },
  facility: {
    facility
  },
  gridPredictions: {
    gridPredictionsLoading,
    gridPredictionsError,
    gridPredictions,
    gridPredictionsTime
  },
  heatmaps: {
    heatmapsLoading,
    heatmapsError,
    heatmaps,
    heatmapsTime
  },
  spaceMetas,
  containerMetas,
  heatmapLayers,
  setHeatmapLayersUUID
}: {
  time: {
    time: ITime
  },
  facility: {
    facility: IFacility
  },
  gridPredictions: {
    gridPredictionsLoading: boolean
    gridPredictionsError: Error
    gridPredictions: Array<IGridPrediction>
    gridPredictionsTime: Date
  },

  heatmaps: {
    heatmapsLoading: boolean
    heatmapsError: Error
    heatmaps: Array<IHeatmap>
    heatmapsTime: Date
  },
  spaceMetas: ISpaceMeta
  containerMetas: { current: IContainerMeta }
  heatmapLayers: LayersForMap
  setHeatmapLayersUUID: ISetStateType<string>
}): void => {
  if (
    !time ||
    gridPredictionsLoading ||
    gridPredictionsError ||
    !gridPredictions ||
    heatmapsLoading ||
    heatmapsError ||
    !heatmaps ||
    !isEqual(time.now, gridPredictionsTime) ||
    !isEqual(time.now, heatmapsTime) ||
    Object.entries(spaceMetas).length === 0 ||
    Object.entries(containerMetas).length === 0
  ) {
    return
  }

  // Dispose old Inventory layers.
  let index = heatmapLayers
    .getLayers()
    .getLength() - 1

  while (index >= 0) {
    heatmapLayers
      .getLayers()
      .removeAt(index)
      .dispose()

    index--
  }

  // Create new Container layers.
  gridPredictions
    .filter(gridPrediction => !('tl' in Object.values(gridPrediction.grid)[0]))
    .forEach(gridPrediction => {
      const spaceMeta = spaceMetas[gridPrediction.spaceId]

      Object
        .entries(gridPrediction.grid)
        .forEach(([inventoryId, grid]) => {
          Object
            .entries(grid)
            .forEach(([containerId, cell]) => {
              const heatmap = heatmaps
                .find(heatmap =>
                  String(heatmap.inventoryId) === String(inventoryId) &&
                  String(heatmap.containerId) === String(containerId) &&
                  has(heatmap.url) &&
                  has(heatmap.width) &&
                  has(heatmap.height))

              if (!heatmap) {
                return
              }

              const containerMeta = containerMetas.current[containerId]
              const even = Number(heatmap.id) % 2 === 0
              const {
                tl: [l, t],
                br: [r, b]
              } = cell

              const projectionCode =
                `Grid Prediction ID: ${gridPrediction.id}, Heatmap ID: ${heatmap.id}`

              const projectionMetersPerUnit =
                (r - l) * spaceMeta.width / heatmap.width

              const srcPts = [
                [0, 0],
                [heatmap.width, 0],
                [heatmap.width, heatmap.height],
                [0, heatmap.height]
              ]

              const projection = new Projection({
                code: projectionCode,
                units: 'pixels',
                extent: [
                  0,
                  0,
                  heatmap.width,
                  heatmap.height
                ],
                metersPerUnit: projectionMetersPerUnit,
                getPointResolution: (ptRes: any) => ptRes
              })

              const projectiveTransformation =
                new ProjectiveTransformation(srcPts, containerMeta.dstPts)

              addProjection(projection)
              addCoordinateTransforms(
                facility.projectionCode,
                projectionCode,
                pt => projectiveTransformation.inverseTransform(pt),
                pt => projectiveTransformation.transform(pt)
              )

              let url = heatmap.url
              if (even) {
                const split = url
                  .replace('https://', '')
                  .replace('http://', '')
                  .split('/')

                const domain = 'https://' + split
                  .splice(0, 2)
                  .reverse()
                  .join('.')

                const path = split
                  .join('/')

                url = domain + '/' + path
              }

              const newSource = new ImageSource({
                crossOrigin: 'anonymous',
                projection,
                url,
                imageExtent: [
                  0,
                  0,
                  heatmap.width,
                  heatmap.height
                ],
                imageSize: [
                  heatmap.width,
                  heatmap.height
                ],
                imageLoadFunction
              })

              const newLayer = new ImageLayer({
                type: EEntity.Container,
                id: containerId,

                extent: containerMeta.extent,
                zIndex: 6,
                source: newSource,
                visible: false
              } as ImageLayerOptions)

              newLayer.set('meta', {
                gridPredictionID: gridPrediction.id,
                inventoryStitchID: heatmap.id
              })

              containerMetas.current[containerId] = {
                ...containerMeta,
                heatmapID: heatmap.id
              }

              heatmapLayers
                .getLayers()
                .push(newLayer)
            })
        })
    })

  setHeatmapLayersUUID(v4())
}

const UpdateOverlays = ({
  user,
  time: { time },
  _overlays: { overlaysDiff },
  spaces: {
    spacesLoading,
    spacesError,
    spaces
  },
  sensors: {
    sensorsLoading,
    sensorsError,
    sensors,
    sensorsTime
  },
  areacams: {
    areacamsLoading,
    areacamsError,
    areacams,
    areacamsTime
  },
  inventories: {
    inventoryWOCIDs,
    inventoriesLoading,
    inventoriesError,
    inventoriesWOC
  },
  containers: {
    containerIDs,
    containersLoading,
    containersError,
    containers
  },
  inventoryStitches: {
    inventoryStitches
  },
  containerStitches: {
    containerStitches
  },
  heatmaps: {
    heatmapsLoading,
    heatmapsError,
    heatmaps,
    heatmapsTime
  },
  tasks: {
    tasksLoading,
    tasksError,
    tasks,
    tasksTime
  },
  taskIssues: {
    taskIssuesLoading,
    taskIssuesError,
    taskIssues,
    taskIssuesTime
  },
  searchParams,
  spaceMetas,
  inventoryMetas,
  containerMetas,
  overlays,
  setOverlaysUUID
}: {
  user: IUser
  time: { time: ITime },
  _overlays: { overlaysDiff: Array<IDiff<EOverlay>> },
  spaces: {
    spacesLoading: boolean
    spacesError: Error
    spaces: Array<ISpace>
    spacesTime: Date
  },
  sensors: {
    sensorsLoading: true
    sensorsError: Error
    sensors: Array<ISensor>
    sensorsTime: Date
  },
  areacams: {
    areacamsLoading: boolean
    areacamsError: Error
    areacams: Array<IAreacam>
    areacamsTime: Date
  },
  inventories: {
    inventoryWOCIDs: Array<number>
    inventoriesLoading: boolean
    inventoriesError: Error
    inventoriesWOC: Array<IInventory>
  },
  containers: {
    containerIDs: Array<number>
    containersLoading: boolean
    containersError: Error
    containers: Array<IContainer>
  },
  inventoryStitches: {
    inventoryStitches: Array<IInventoryStitch>
  },
  containerStitches: {
    containerStitches: Array<IContainerStitch>
  },
  heatmaps: {
    heatmapsLoading: boolean
    heatmapsError: Error
    heatmaps: Array<IHeatmap>
    heatmapsTime: Date
  },
  tasks: {
    tasksLoading: boolean
    tasksError: Error
    tasks: Array<ITask>
    tasksTime: Date
  },
  taskIssues: {
    taskIssuesLoading: boolean
    taskIssuesError: Error
    taskIssues: Array<ITaskIssue>
    taskIssuesTime: Date
  },
  searchParams: { selectedEntities: string }
  spaceMetas: ISpaceMeta
  inventoryMetas: IInventoryMeta
  containerMetas: IContainerMeta
  overlays: Collection<Overlay>
  setOverlaysUUID: ISetStateType<string>
}): void => {
  if (
    !time ||
    !overlaysDiff ||

    // Check Spaces
    spacesLoading ||
    spacesError ||
    !spaces ||

    // Check Sensors
    sensorsLoading ||
    sensorsError ||
    !sensors ||
    !isEqual(time.now, sensorsTime) ||

    // Check Areacams
    areacamsLoading ||
    areacamsError ||
    !areacams ||
    !isEqual(time.now, areacamsTime) ||

    // Check Inventories
    inventoriesLoading ||
    inventoriesError ||
    (
      inventoryWOCIDs &&
      inventoryWOCIDs.length > 0 &&
      (
        !inventoriesWOC ||
        !inventoryStitches
      )
    ) ||

    // Check Containers
    containersLoading ||
    containersError ||
    (
      containerIDs &&
      containerIDs.length > 0 &&
      (
        !containers ||
        !containerStitches
      )
    ) ||

    // Check Heatmaps
    heatmapsLoading ||
    heatmapsError ||
    !heatmaps ||
    !isEqual(time.now, heatmapsTime) ||

    // Check Tasks
    tasksLoading ||
    tasksError ||
    !tasks ||
    !isEqual(time.now, tasksTime) ||

    // Check Tasks Issues
    taskIssuesLoading ||
    taskIssuesError ||
    !taskIssues ||
    !isEqual(time.now, taskIssuesTime) ||

    Object.entries(spaceMetas).length === 0 ||
    Object.entries(inventoryMetas).length !== inventoryWOCIDs.length ||
    Object.entries(containerMetas).length !== containerIDs.length
  ) {
    return
  }
  overlaysDiff
    .forEach(d => {
      switch (d.value) {
        case EOverlay.Areacam:
          if (d.type === EDiff.Add) {
            CreateAreacamOverlays(
              areacams,
              overlays
            )
          } else if (d.type === EDiff.Remove) {
            DeleteAreacamOverlays(overlays)
          }
          break
        case EOverlay.CO2:
        case EOverlay.DLI:
        case EOverlay.Humidity:
        case EOverlay.MMol:
        case EOverlay.Temperature:
        case EOverlay.VPD:
          if (d.type === EDiff.Add) {
            CreateSensorOverlays(
              d,
              spaces,
              sensors,
              spaceMetas,
              overlays
            )
          } else if (d.type === EDiff.Remove) {
            DeleteSensorOverlays(
              d,
              overlays
            )
          }
          break
        case EOverlay.Tag:
          if (d.type === EDiff.Add) {
            CreateTagOverlays(
              inventoriesWOC,
              containers,
              inventoryStitches,
              containerStitches,
              inventoryMetas,
              containerMetas,
              overlays
            )
          } else if (d.type === EDiff.Remove) {
            DeleteTagOverlays(overlays)
          }
          break
        case EOverlay.Heatmap:
          if (d.type === EDiff.Add) {
            // ...
          } else if (d.type === EDiff.Remove) {
            // ...
          }
          break
        case EOverlay.Task:
          if (d.type === EDiff.Add) {
            // Delete overlays before rendering them to prevent overlapping icons
            DeleteTaskOverlays(overlays)
            CreateTaskOverlays(
              spaces,
              containers,
              tasks,
              spaceMetas,
              inventoryMetas,
              containerMetas,
              overlays,
              searchParams
            )
          } else if (d.type === EDiff.Remove) {
            DeleteTaskOverlays(overlays)
          }

          break;
        case EOverlay.TaskIssue:
          if (d.type === EDiff.Add) {
            // Delete overlays before rendering them to prevent overlapping icons
            DeleteTaskIssueOverlays(overlays)
            CreateTaskIssueOverlays(
              spaces,
              containers,
              taskIssues,
              spaceMetas,
              inventoryMetas,
              containerMetas,
              overlays,
              searchParams,
              user
            )
          } else if (d.type === EDiff.Remove) {
            DeleteTaskIssueOverlays(overlays)
          }
          break;
        default:
          break;
      }
    })

  setOverlaysUUID(v4())
}

const UpdateVisibleEntities = ({
  visibleEntities: { setVisibleEntities },
  zoom: { setZoom },
  view,
  facilityLayers,
  buildingLayers,
  spaceLayers,
  inventoryVectorLayers,
  containerVectorLayers,
  overlays
}: {
  visibleEntities: { setVisibleEntities: ISetStateType<Array<IEntity>> },
  zoom: { setZoom: ISetStateType<number> },
  view: View
  facilityLayers: LayersForMap
  buildingLayers: LayersForMap
  spaceLayers: LayersForMap
  inventoryVectorLayers: LayersForMap
  containerVectorLayers: LayersForMap
  overlays: Collection<Overlay>
}): void => {
  if (
    !view ||
    !facilityLayers ||
    !buildingLayers ||
    !spaceLayers ||
    !inventoryVectorLayers ||
    !containerVectorLayers ||
    !overlays
  ) {
    return
  }

  let viewExtent: Extent
  try {
    viewExtent = view.calculateExtent()
  } catch (err) {
    return
  }

  const visibleParents = new Set<string>()

  const newVisibleEntities: Array<IEntity> = [
    ...facilityLayers.getLayers().getArray(),
    ...buildingLayers.getLayers().getArray(),
    ...spaceLayers.getLayers().getArray(),
    ...inventoryVectorLayers.getLayers().getArray(),
    ...containerVectorLayers.getLayers().getArray()
  ]
    .filter(l => l instanceof VectorLayer)
    .map(l => {
      const type = l.get('type')
      const id = l.get('id')
      const parentType = l.get('parentType')
      const parentID = l.get('parentID')
      const meta = l.get('meta')

      const layerExtent: Extent = l
        .getSource()
        .getExtent()

      const includes = containsExtent(viewExtent, layerExtent)
      const contains = containsExtent(layerExtent, viewExtent)
      const intersects = intersectsExtent(viewExtent, layerExtent)

      let visibility = EVisibility.None
      if (includes) {
        visibility = EVisibility.ViewportContains
      } else if (contains) {
        visibility = EVisibility.ContainsViewport
      } else if (intersects) {
        visibility = EVisibility.IntersectsViewport
      }

      return {
        type,
        id,
        parentType,
        parentID,
        meta,
        visibility
      }
    })
    .filter(ve => ve.visibility !== EVisibility.None)

  overlays.getArray()
    .map(overlay => {
      const type = overlay.get('type')
      const id = overlay.get('id')
      const parentType = overlay.get('parentType')
      const parentID = overlay.get('parentID')
      const meta = overlay.get('meta')
      const point = overlay.get('point')
      const rollup = overlay.get('rollup')

      const includes = containsXY(viewExtent, point[0], point[1])

      let visibility = EVisibility.None
      if (!rollup && includes) {
        visibility = EVisibility.ViewportContains
      }

      return {
        type,
        id,
        parentType,
        parentID,
        meta,
        visibility
      }
    })
    .filter(ve => ve.visibility !== EVisibility.None)
    .forEach(ve => newVisibleEntities.push(ve))

  newVisibleEntities
    .forEach(e => {
      if (
        e.parentType &&
        e.parentID
      ) {
        visibleParents.add(`${e.parentType}:${e.parentID}`)
      }
    })

  visibleParents
    .forEach(vp => {
      const arrayVP = vp.split(':')
      const type = arrayVP[0] as EEntity;
      const id = arrayVP[1];

      const newVisibleEntity = {
        type,
        id,
        visibility: EVisibility.Other
      }

      newVisibleEntities.push(newVisibleEntity)
    })

  const newZoom = view.getResolution()

  setVisibleEntities(newVisibleEntities)
  setZoom(newZoom)
}

const UpdateInventoryRasterLayersVisibility = ({
  searchParams: { showStitches },
  zoom: { zoom },
  inventoryRasterLayers
}: {
  searchParams: {
    showStitches: boolean
  },
  zoom: { zoom: number },
  inventoryRasterLayers: LayerGroup
}): void => {
  if (
    !zoom ||
    !inventoryRasterLayers
  ) {
    return
  }

  const metersPerPixel = 3 / 80
  const newVisibility = zoom < metersPerPixel ||
    showStitches

  inventoryRasterLayers
    .getLayers()
    .getArray()
    .forEach(tileLayer => {
      tileLayer.setVisible(newVisibility)
    })
}

const UpdateInventoryHeatmapLayersVisibility = ({
  _overlays: { overlays },
  zoom: { zoom },
  heatmapLayers
}:{
  _overlays: { overlays: Array<EOverlay> },
  zoom: { zoom: number },
  heatmapLayers: LayerGroup
}): void => {
  if (
    !overlays ||
    !zoom ||
    !heatmapLayers
  ) {
    return
  }

  const metersPerPixel = 3 / 80
  const newVisibility =
    !!overlays.find(overlay => overlay === EOverlay.Heatmap) /* &&
    (zoom < metersPerPixel) */

  heatmapLayers
    .getLayers()
    .getArray()
    .forEach(tileLayer => {
      tileLayer.setVisible(newVisibility)
    })
}

const UpdateContainerRasterLayersVisibility = ({
  searchParams: { showStitches },
  zoom: { zoom },
  containerRasterLayers
}: {
  searchParams: { showStitches: boolean },
  zoom: { zoom: number },
  containerRasterLayers: LayerGroup
}): void => {
  if (
    !zoom ||
    !containerRasterLayers
  ) {
    return
  }

  const metersPerPixel = 3 / 80
  const newVisibility = zoom < metersPerPixel ||
    showStitches

  containerRasterLayers
    .getLayers()
    .getArray()
    .forEach(tileLayer => {
      tileLayer.setVisible(newVisibility)
    })
}

// If the most recent container stitch is older than 24 hours and we're on the issues tab,
// identifty the containers for our horticultuarlist team (admin users) with a distinct border
const UpdateContainerLayersByAge = ({
  time: { time },
  searchParams: { navigationTab },
  containerMetas,
  containerStitches: { containerStitches },
  containerVectorLayers,
  containerRasterLayers,
  selectedEntities: { selectedEntities },
  user
}:
{
  time: { time: ITime },
  searchParams: { navigationTab: string },
  containerMetas: IContainerMeta,
  containerStitches: { containerStitches: Array<IContainerStitch> },
  containerVectorLayers: LayersForMap,
  containerRasterLayers: LayersForMap,
  selectedEntities: { selectedEntities: Array<IEntity> },
  user: IUser
}): void => {
  if (
    !user?.isAdmin ||
    !containerVectorLayers.getLayers().getLength() ||
    !containerRasterLayers.getLayers().getLength()
  ) {
    return
  }

  Object.entries(containerMetas).forEach(([containerId, meta]) => {
    const { containerStitchID, rasterLayerIndex, vectorLayerIndex } = meta
    const vectorLayer = containerVectorLayers.getLayers().item(vectorLayerIndex)
    const rasterLayer = containerRasterLayers.getLayers().item(rasterLayerIndex)
    const containerStitch = containerStitches.find((stitch) => stitch.id === containerStitchID)
    if (!vectorLayer || !rasterLayer || !containerStitch) { return }

    const oldStitch = moment(containerStitch.lastImageCreatedOn).isBefore(moment(time.dayLookback))

    const setDefaultLayerOrder = () => {
      vectorLayer.setZIndex(4)
      rasterLayer.setZIndex(5)
    }

    // swap the z-index of the vector and raster layers so that we can draw the border on top of
    // the image. This makes it much more apparent for admins to detect out-of-date stitches
    const setOutOfDateLayerOrder = () => {
      vectorLayer.setZIndex(5)
      rasterLayer.setZIndex(4)
    }

    const onSelect = () => {
      if (navigationTab === 'Tab.Issues' && oldStitch) {
        setOutOfDateLayerOrder()
        vectorLayer.setStyle(ContainerStyles.OutOfDateSelected)
        return
      }
      setDefaultLayerOrder()
      vectorLayer.setStyle(ContainerStyles.Selected)
    }
    const onUnselect = () => {
      if (navigationTab === 'Tab.Issues' && oldStitch) {
        setOutOfDateLayerOrder()
        vectorLayer.setStyle(ContainerStyles.OutOfDate)
        return
      }
      setDefaultLayerOrder()
      vectorLayer.setStyle(ContainerStyles.Default)
    }

    vectorLayer.setProperties({
      onSelect,
      onUnselect
    })

    const selected = !!selectedEntities
      .find((entity) =>
        entity.type === EEntity.Container &&
        String(entity.id) === String(containerId))

    if (selected) {
      onSelect()
    } else {
      onUnselect()
    }
  })
}

interface IUpdateOverlaysVisibility {
  searchParams: {
    showOverlays: boolean
    navigationTab: string
  }
  zoom: { zoom: number}
  overlays: Collection<Overlay>
}

const UpdateOverlaysVisibility = (props: IUpdateOverlaysVisibility): void => {
  const {
    searchParams: { showOverlays },
    zoom: { zoom },
    overlays
  } = props;

  if (
    !zoom ||
    !overlays
  ) {
    return
  }

  // const isIssuesTab = navigationTab === ETab.Issues
  const otherTabOverlays: Array<Overlay> = []
  // Get both the correct tab overlays and rollups
  const taskAndTaskIssueRollupOverlays = overlays
    .getArray()
    .filter((overlay) => {
      // Flag tab is always filtered when overlays are built
      // Issues tab needs to be filtered here to show both flags and issues for our horticulturalists

      // Ask >>> This confuses me. Shouldn't only display the issues when the
      // issues are selected?
      const isRollup = overlay.get('spaceRollup') || overlay.get('containerRollup')
      if ([EEntity.Task, EEntity.TaskIssue].includes(overlay.get('type')) && (isRollup)) {
        return true
      }
      // If the overlay doesn't match the correct tab, add to array of overlays to hide
      if ([EEntity.Task, EEntity.TaskIssue].includes(overlay.get('type'))) {
        otherTabOverlays.push(overlay);
      }
      return false;
    });

  // Controls when overlay icons are bunched together into rollups
  const showTaskAndTaskIssueOverlays = showOverlays || zoom < EOverlayThresholds.ZoomedIn;
  const showContainerRollups = !showOverlays && zoom >= EOverlayThresholds.ZoomedIn && zoom <= EOverlayThresholds.ZoomedOut;
  const showSpaceRollups = !showOverlays && zoom > EOverlayThresholds.ZoomedOut;

  // Hide the other tab overlays when the rollups are showing
  otherTabOverlays.forEach(overlay => {
    if ((showSpaceRollups || showContainerRollups) && !showOverlays) {
      overlay.setPosition(undefined)
    } else {
      const point = overlay.get('point')
      overlay.setPosition(point)
    }
  })
  // Set the current tab's overlays and rollups
  taskAndTaskIssueRollupOverlays.forEach(overlay => {
    const point = overlay.get('point')
    const spaceRollup = overlay.get('spaceRollup')
    const containerRollup = overlay.get('containerRollup')

    switch (true) {
      case showTaskAndTaskIssueOverlays:
        // Hide all the rollups and show the task/taskIssue overlay
        if (spaceRollup || containerRollup) {
          overlay.setPosition(undefined)
        } else {
          overlay.setPosition(point)
        }
        break
      case showContainerRollups:
        // Show the container rollup and hide everything else
        if (containerRollup) {
          overlay.setPosition(point)
        } else {
          overlay.setPosition(undefined)
        }
        break
      case showSpaceRollups:
        // Show the space rollups and hide everything else
        if (spaceRollup) {
          overlay.setPosition(point)
        } else {
          overlay.setPosition(undefined)
        }
        break
    }
  })
}

const UpdateHighlight = ({
  selectedEntities: {
    selectedEntitiesDiff
  },
  facilityLayers,
  buildingLayers,
  spaceLayers,
  inventoryVectorLayers,
  containerVectorLayers
}: {
  selectedEntities: {
    selectedEntitiesDiff: Array<IDiff<IEntity>>
  },
  facilityLayers: LayersForMap
  buildingLayers: LayersForMap
  spaceLayers: LayersForMap
  inventoryVectorLayers: LayersForMap
  containerVectorLayers: LayersForMap
}): void => {
  if (
    !selectedEntitiesDiff ||
    !facilityLayers ||
    !buildingLayers ||
    !spaceLayers ||
    !inventoryVectorLayers ||
    !containerVectorLayers
  ) {
    return
  }

  selectedEntitiesDiff
    .forEach(d => {
      let layers: LayersForMap
      let styles: IStyleObject
      switch (d.value.type) {
        case EEntity.Facility:
          layers = facilityLayers
          styles = FacilityStyles
          break
        case EEntity.Building:
          layers = buildingLayers
          styles = BuildingStyles
          break
        case EEntity.Space:
          layers = spaceLayers
          styles = SpaceStyles
          break
        case EEntity.Inventory:
          layers = inventoryVectorLayers
          styles = InventoryStyles
          break
        case EEntity.Container:
          layers = containerVectorLayers
          styles = ContainerStyles
          break
        default:
          return
          // throw new Error(`Unhandled Entity Type: ${d.value.type}.`)
          // break
      }

      const layer = layers
        .getLayers()
        .getArray()
        .find(l =>
          l.get('type') === d.value.type &&
          String(l.get('id')) === String(d.value.id))

      if (!layer) return

      let style: any
      if (d.type === EDiff.Add) {
        if (layer.getProperties().onSelect) {
          layer.getProperties().onSelect()
        } else {
          style = styles.Selected
        }
      } else if (d.type === EDiff.Remove) {
        if (layer.getProperties().onUnselect) {
          layer.getProperties().onUnselect()
        } else {
          style = styles.Default
        }
      } else {
        return
        // throw new Error(`Unhandled Diff Type: ${d.type}.`)
      }

      if (style) layer.setStyle(style)
    })
}

interface ISelectEntityBySearchParams {
  searchParams: {
    selectInventoryId?: string
    selectSpaceId?: string
    selectedEntities?: string
  }
  gridPredictions: IGridPrediction[]

  setSearchParams: (newSearchParams: Object) => void
}
/**
 * Allows for selecting entities on the map view from
 * components that don't have access to map view's Mobius Context
 * @param {Object} ISelectEntityBySearchParams
 * @param {Object} ISelectEntityBySearchParams.searchParams
 * @param {Array} ISelectEntityBySearchParams.gridPredictions
 * @param {Function} ISelectEntityBySearchParams.setSearchParams
 * @returns {void}
 */
const SelectEntityBySearchParams = ({
  searchParams,
  gridPredictions,

  setSearchParams
}: ISelectEntityBySearchParams): void => {
  const inventoryId = searchParams.selectInventoryId as keyof IGridPrediction['grid']
  const spaceId = searchParams.selectSpaceId
  const newSearchParams = { ...searchParams }
  delete newSearchParams.selectInventoryId
  delete newSearchParams.selectSpaceId

  // Look for the grid.inventory.container relationships. This is needed to set it as selected
  const prediction = gridPredictions
    .find(({ grid }) => {
      // Find a grid that contains the selected inventory
      return inventoryId in grid
    })

  // Format the grid.inventory.container data into a selectable entity
  if (prediction) {
    const inventory = prediction.grid[inventoryId]
    const container = {
      type: EEntity.Container,
      id: Object.keys(inventory)[0], // Use the first container on the inventory
      parentType: EEntity.Inventory,
      parentID: inventoryId,
      meta: { gridPredictionID: prediction.id },
      zIndex: 4
    }

    // Set the container as selected
    newSearchParams.selectedEntities = JSON.stringify([container])
    setSearchParams(newSearchParams)
    return
  }

  // If no related container was found, set the parent space as selected
  if (spaceId) {
    const space = {
      type: EEntity.Space,
      id: spaceId
    }
    newSearchParams.selectedEntities = JSON.stringify([space])
    setSearchParams(newSearchParams)
  }
}

export {
  InitOpenLayers,
  UpdateOpenLayersSize,
  UpdateOpenLayersExtent,
  UpdateFacilityLayers,
  UpdateBuildingLayers,
  UpdateSpaceLayers,
  UpdateInventoryVectorLayers,
  UpdateInventoryRasterLayers,
  UpdateInventoryHeatmapLayers,
  UpdateContainerVectorLayers,
  UpdateContainerRasterLayers,
  UpdateContainerHeatmapLayers,
  UpdateOverlays,
  UpdateVisibleEntities,
  UpdateInventoryRasterLayersVisibility,
  UpdateInventoryHeatmapLayersVisibility,
  UpdateContainerRasterLayersVisibility,
  UpdateContainerLayersByAge,
  UpdateOverlaysVisibility,
  UpdateHighlight,
  SelectEntityBySearchParams
}

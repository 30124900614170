const config = {
  code: process.env.CODE,

  googleAnalyticsId: process.env.GOOGLE_ANALYTICS_ID,
  heapId: process.env.HEAP_ID,
  zenDeskId: process.env.ZENDESK_ID,
  bugsnagId: process.env.BUGSNAG_ID,
  releaseStage: process.env.NODE_ENV,

  graphQlV1Url: process.env.GRAPHQL_V1_URL,
};

export default config;

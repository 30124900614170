/**
 * @author Miras Absar <mabsar@iunu.com>
 */

// Types
import EInputStatus from 'ui-lib/enums/input-status'
import IInputStatus from 'ui-lib/interfaces/IInputStatus'

// Utils
import { isValid } from 'date-fns'
import { createDate } from 'utils/dates'

const Dat = async (val: string): Promise<IInputStatus> => {
  if (/^$/.test(val)) {
    return {
      status: EInputStatus.OK,
      message: ''
    }
  }

  if (!isValid(createDate(val))) {
    return {
      status: EInputStatus.Error,
      message: 'Invalid'
    }
  }

  return {
    status: EInputStatus.OK,
    message: ''
  }
}

const RequiredDat = async (val: string): Promise<IInputStatus> => {
  if (/^\s*$/.test(val)) {
    return {
      status: EInputStatus.Error,
      message: 'Required'
    }
  }

  if (!isValid(createDate(val))) {
    return {
      status: EInputStatus.Error,
      message: 'Invalid'
    }
  }

  return {
    status: EInputStatus.OK,
    message: ''
  }
}

export {
  Dat,
  RequiredDat
}

/**
 * @author Miras Absar <mabsar@iunu.com>
 */

import m from 'moment'

import IColumn from 'arrayview/interfaces/IColumn'
import ICrop from 'graphql-lib/interfaces/ICrop'
import IGroup from 'arrayview/interfaces/IGroup'

import ISpace from '../interfaces/ISpace'

const SafeIncludes = (a: string, b: string): boolean => {
  a = a || ''
  b = b || ''

  return a.toLowerCase().includes(b.toLowerCase())
}

const SafeCompare = (a: string, b: string): number => {
  a = a || ''
  b = b || ''

  return a.localeCompare(b)
}

/* TODO: Implement other columns. */

const CodeColumn: IColumn<ISpace> = Object.freeze({
  id: 'space::code',
  name: 'Code',
  searchFunc: (_: IColumn<ISpace>, search: string, data: ISpace): boolean =>
    SafeIncludes(data.code, search),

  ascendingSortFunc: (_: IColumn<ISpace>, first: ISpace, second: ISpace): number =>
    SafeCompare(first.code, second.code),

  descendingSortFunc: (_: IColumn<ISpace>, first: ISpace, second: ISpace): number =>
    SafeCompare(second.code, first.code),

  groupFunc: (_: IColumn<ISpace>, data: ISpace): IGroup => ({
    id: `${_.id}::${data.code}`,
    name: data.code,
    data: []
  })
})

const NameColumn: IColumn<ISpace> = Object.freeze({
  id: 'space::name',
  name: 'Name',
  searchFunc: (_: IColumn<ISpace>, search: string, data: ISpace): boolean =>
    SafeIncludes(data.name, search),

  ascendingSortFunc: (_: IColumn<ISpace>, first: ISpace, second: ISpace): number =>
    SafeCompare(first.name, second.name),

  descendingSortFunc: (_: IColumn<ISpace>, first: ISpace, second: ISpace): number =>
    SafeCompare(second.name, first.name),

  groupFunc: (_: IColumn<ISpace>, data: ISpace): IGroup => ({
    id: `${_.id}::${data.name}`,
    name: data.name,
    data: []
  })
})

const NicknameColumn: IColumn<ISpace> = Object.freeze({
  id: 'space::nickname',
  nickname: 'Nickname',
  searchFunc: (_: IColumn<ISpace>, search: string, data: ISpace): boolean =>
    SafeIncludes(data.nickname, search),

  ascendingSortFunc: (_: IColumn<ISpace>, first: ISpace, second: ISpace): number =>
    SafeCompare(first.nickname, second.nickname),

  descendingSortFunc: (_: IColumn<ISpace>, first: ISpace, second: ISpace): number =>
    SafeCompare(second.nickname, first.nickname),

  groupFunc: (_: IColumn<ISpace>, data: ISpace): IGroup => ({
    id: `${_.id}::${data.nickname}`,
    name: data.nickname,
    data: []
  })
})

export {
  CodeColumn,
  NameColumn,
  NicknameColumn
}

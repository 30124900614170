// Types
import IColumn from 'arrayview/interfaces/IColumn';
import IFilter from 'arrayview/interfaces/IFilter';
import ICrop from 'graphql-lib/interfaces/ICrop';
import ID from 'graphql-lib/interfaces/ID';

// Utils
import { EStorage } from 'utils/storage';

// React
import React, {
  CSSProperties,
  Ref,
  useContext,
  useEffect,
  useRef,
  useState
} from 'react';

// React Libs
import { useDebounce } from 'use-debounce';
import { toast } from 'react-toastify';
import AutoSizer from 'react-virtualized-auto-sizer';
import { VariableSizeList as List, VariableSizeList } from 'react-window';
import { useEvent } from 'react-lib/use-event';
import useStorage from 'react-lib/use-storage';

// GraphQL
import { QueryResult, useQuery } from '@apollo/client';
import { QueryCrops } from './queries';

// Contexts
import NavigationContext from 'context/navigation';

// Actions & Navigation
import {
  ExportCrops as ExportCropsAction,
  ImportCrops as ImportCropsAction,
  CreateCrop as CreateCropAction
} from '../../actions/navigation';

// Routes
import {
  Crops,
  CreateCrop,
  ViewCrop,
  EditCrop,
  Tag,
  CloseoutInventory
} from '../../utils/Routes';

// Columns
import {
  NameColumn,
  CodeColumn,
  EndDateColumn,
  SpeciesColumn,
  StartDateColumn,
  TransplantDateColumn,
  SerialColumn
} from 'graphql-lib/columns/crop'

// Filters
import {
  All as AllFilter,
  Active as ActiveFilter,
  Inactive as InactiveFilter,
  Archived as ArchivedFilter,
  HasInventory as HasInventoryFilter
} from '../../filters/crop'

// Effects
import {
  UpdateData,
  SearchData,
  FilterData,
  SortData,
  GroupData
} from './effects'

// Utils
import GoTo from 'react-lib/go-to'

// UI Lib
import DetailsView from 'ui-lib/components/details-view'
import ListHeader from 'ui-lib/components/list-header'
import ListGroup from 'ui-lib/components/list-group'
import CropCard from '../crop-card'
import CreateCropCard from '../create-crop-card'
import ViewCropCard from '../view-crop-card'
import TagCard from '../tag-card'
import InventoryCloseoutCard from '../inventory-closeout-card'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSpinnerThird } from '@fortawesome/pro-solid-svg-icons'
import CropRollupCard from '../crop-rollup-card'
import IGroup from 'arrayview/interfaces/IGroup';

const Routes = [
  Crops,
  CreateCrop,
  CloseoutInventory,
  ViewCrop,
  EditCrop,
  Tag
]

const Columns = [
  NameColumn,
  CodeColumn,
  EndDateColumn,
  SpeciesColumn,
  StartDateColumn,
  TransplantDateColumn,
  SerialColumn
]

const Filters = [
  AllFilter,
  ActiveFilter,
  InactiveFilter,
  ArchivedFilter,
  HasInventoryFilter
]

export interface ICropsViewProps {
  match: {
    params: { id: number },
    path: string
  }
}
export interface ICustomEvent<M> extends Event {
  detail?: {
    dispatcherID: ID,
    create?: M,
    update?: M
  }
}

const CropsView = ({
  match: {
    params: { id },
    path
  }
}: ICropsViewProps): JSX.Element => {
  // Contexts //////////////////////////////////////////////////////////////////

  // Navigation Context
  const {
    setSecondaryActions,
    setTitle
  } = useContext(NavigationContext)

  // State /////////////////////////////////////////////////////////////////////

  const [facilities] = useStorage('facilities', EStorage.EphemeralStorage)
  const facility = facilities?.[0]

  const ref = useRef<List>()

  // GQL Data
  const [offset, setOffset] = useState<number>(0)
  const [limit, setLimit] = useState<number>(100)
  const [archived, setArchived] = useState<boolean>(false)
  const [atEnd, setAtEnd] = useState<boolean>(false)
  const [data, setData] = useState<Array<ICrop>>()

  const [search, setSearch] = useState<string>('')
  const [debouncedSearch] = useDebounce<string>(search, 300)
  const [filter, setFilter] = useState<IFilter<ICrop>>(HasInventoryFilter)
  const [column, setColumn] = useState<IColumn<ICrop>>(StartDateColumn)
  const [descending, setDescending] = useState<boolean>(true)

  const [searchedData, setSearchedData] = useState<Array<ICrop>>()
  const [filteredData, setFilteredData] = useState<ICrop[]>()
  const [sortedData, setSortedData] = useState<ICrop[]>()
  const [hasInventoryOnly, setHasInventoryOnly] = useState<Boolean>(filter.id === 'crop:filter:hasInventory')
  const [groupedData, setGroupedData] = useState<Array<IGroup>>()

  // GraphQL ///////////////////////////////////////////////////////////////////
  const {
    loading: gqlLoading,
    error: gqlError,
    data: gqlData
  }: QueryResult = useQuery<{crop: Array<ICrop>}>(QueryCrops, {
    skip: (facilities ?? []).length === 0,
    variables: {
      facilityIds: [facility?.id],
      offset,
      limit,
      archived,
      hasInventoryOnly
    },
    fetchPolicy: 'no-cache'
  })

  // Effects ///////////////////////////////////////////////////////////////////

  // Setup Navigation Context
  useEffect((): void => {
    setSecondaryActions([
      ExportCropsAction,
      ImportCropsAction,
      CreateCropAction
    ])
    setTitle(null)
  }, [])

  useEffect((): void => {
    UpdateData({
      offset,
      limit,
      data,

      gqlLoading,
      gqlData,
      gqlKey: 'crop',

      setData,
      setAtEnd
    })
  }, [gqlData])

  useEffect((): void => {
    SearchData({
      columns: Columns,
      data,
      search: debouncedSearch,
      setSearchedData
    })
  }, [data, debouncedSearch])

  useEffect((): void => {
    FilterData({
      searchedData,
      filter,

      setFilteredData
    })
  }, [searchedData, filter])

  useEffect((): void => {
    SortData({
      filteredData,
      column,
      descending,

      setSortedData
    })
  }, [filteredData, column, descending])

  useEffect((): void => {
    GroupData({
      sortedData,
      column,

      setGroupedData
    })
  }, [sortedData, column])

  useEffect((): void => {
    const newArchived = Number(filter.id) === Number(ArchivedFilter.id)
    const newHasInventoryOnly = Number(filter.id) === Number(HasInventoryFilter.id)
    // This reset the UI list of crops to display the loading
    // When necesary
    if ((newHasInventoryOnly !== hasInventoryOnly) || (newArchived !== archived)) {
      setGroupedData([])
      setData([])
    }
    setHasInventoryOnly(newHasInventoryOnly)
    setArchived(newArchived)
    setOffset(0)
  }, [filter])

  useEffect((): void => {
    if (ref.current) {
      ref.current.resetAfterIndex(0)
    }
  }, [groupedData])

  // Subspace Update
  useEvent(
    window,
    'create_crop',
    (ev: ICustomEvent<ICrop>) => {
      const newCrop = ev.detail.create
      const newCrops = [...data, newCrop]
      setData(newCrops)
    },
    [data]
  )

  // Subspace Update
  useEvent(
    window,
    'update_crop',
    (ev: ICustomEvent<ICrop>): void => {
      const cropPatch = ev.detail
      const targetCrop = data.find((c): boolean => Number(c.id) === Number(cropPatch.update.id))
      Object.assign(
        targetCrop,
        cropPatch.update
      )

      setData([...data])
    },
    [data]
  )

  // Effects ///////////////////////////////////////////////////////////////////

  const $ref = (newRef: VariableSizeList<any>): void => {
    ref.current = newRef
  }

  const $nextPage = (pageLength?: number): void => {
    const newLimit = pageLength || limit
    const newOffset = offset + limit

    if (!atEnd && !gqlLoading) {
      setOffset(newOffset)
      setLimit(newLimit)
    }
  }

  const $search = (newSearch: string): void =>
    setSearch(newSearch)

  const $filter = (newFilter: IFilter<ICrop>): void =>
    setFilter(newFilter)

  const $column = (newColumn: IColumn<ICrop>): void =>
    setColumn(newColumn)

  const $descending = (newDescending: boolean): void =>
    setDescending(newDescending)

  const $itemSize = (index: number): number => {
    const cur = groupedData[index]
    const prev = groupedData[index - 1]

    if (cur.__typename === 'Group') {
      return 50
    } else {
      if (prev?.__typename === 'Group') {
        return 80
      } else {
        return 80 + 8
      }
    }
  }

  const $item = ({
    index,
    style
  }: {
    index: number;
    style: CSSProperties;
  }): JSX.Element => {
    const _data = groupedData[index]

    if (_data.__typename === 'Group') {
      const _style = Object.assign(
        {},
        style,
        {
          width: 'calc(100% - 32px)',
          height: '18px'
        }
      )

      return (
        <ListGroup
          style={_style}
          group={_data}
        />
      )
    } else {
      const _style = Object.assign(
        {},
        style,
        {
          width: 'calc(100% - 16px)',
          height: '80px'
        }
      )

      const $click = (): void => {
        const to = ViewCrop.replace(':id', String(_data.id))
        GoTo(to)
      }

      return (
        <CropCard
          style={_style}

          resting
          animated

          data={_data}
          selectedId={id}
          onClick={$click}
        />
      )
    }
  }

  const $itemsRendered = ({
    visibleStopIndex
  }: {
    overscanStartIndex: number;
    overscanStopIndex: number;
    visibleStartIndex: number;
    visibleStopIndex: number;
  }): void => {
    if (visibleStopIndex === groupedData.length - 1) {
      $nextPage()
    }
  }

  // Errors ////////////////////////////////////////////////////////////////////

  useEffect((): void => {
    if (!gqlLoading && gqlError) {
      toast.error("Couldn't load Crops, please try again in a few seconds.")
    }
  }, [gqlError])

  // Render ////////////////////////////////////////////////////////////////////

  const isCreateCrop = path === CreateCrop
  const isViewCrop = path === ViewCrop
  const isEditCrop = path === EditCrop
  const isTag = path === Tag
  const isCloseoutInventory = path === CloseoutInventory

  const showDetails =
    isCreateCrop ||
    isViewCrop ||
    isEditCrop ||
    isTag ||
    isCloseoutInventory
  let contents: JSX.Element = <div className='ListGroup'><FontAwesomeIcon icon={faSpinnerThird} spin={true} /></div>
  if (!gqlLoading) {
    contents = (
      <div className='AutoSizerParent'>
        <AutoSizer>
          {({
            width,
            height
          }: {
            width: number;
            height: number;
          }): JSX.Element => (
            <List
              ref={$ref}
              width={width}
              height={height}
              itemData={groupedData}
              itemCount={groupedData.length}
              itemSize={$itemSize}
              onItemsRendered={$itemsRendered}
            >
              {$item}
            </List>
          )}
        </AutoSizer>
      </div>
    )
  }

  const main = (
    <div className='CropsView'>
      <ListHeader
        animated

        filters={Filters}
        columns={Columns}

        search={search}
        filter={filter}
        column={column}
        descending={descending}

        onSearch={$search}
        onFilter={$filter}
        onColumn={$column}
        onDescending={$descending}
      />

      {contents}
    </div>
  )

  let details: JSX.Element | undefined
  if (isCreateCrop) {
    details = (
      <CreateCropCard
        resting
        animated
      />
    )
  }

  if (isViewCrop) {
    details = (
      <CropRollupCard
        key={id}

        resting
        animated

        id={id}

        onClose={() => GoTo(Crops)}
        onEditCrop={(id: ID): void => {
          const to = EditCrop.replace(':id', String(id))
          GoTo(to)
        }}
      />
    )
  }

  if (isEditCrop) {
    details = (
      <ViewCropCard
        resting
        animated

        id={id}
      />
    )
  }

  if (isTag) {
    details = (
      <TagCard
        resting
        animated

        id={id}
        facilityId={facility?.id}
      />
    )
  }

  if (isCloseoutInventory) {
    details = (
      <InventoryCloseoutCard
        resting
        animated
      />
    )
  }

  return (
    <DetailsView
      animated
      showDetails={showDetails}

      main={main}
      details={details}
    />
  )
}

export { Routes as CropsViewRoutes }
export default CropsView

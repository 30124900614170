import ID from 'graphql-lib/interfaces/ID';
import { ITab } from 'ui-lib/components/tab';
import ISetStateType from 'graphql-lib/interfaces/ISetStateType';

const UpdateTab = ({
  defaultTab,
  tabs,
  searchParams,
  setSearchParams,
  setTab
}: {
  defaultTab: ITab
  tabs: Array<ITab>
  searchParams: {
    [key: string]: string,
  }
  setSearchParams: ISetStateType<{
    [key: string]: string,
  }>
  setTab: ISetStateType<ITab>
}): void => {
  if (
    !tabs ||
    !searchParams
  ) {
    return
  }

  const newTab = tabs
    .find((t) => t.id === searchParams.navigationTab)

  if (newTab) {
    setTab(newTab)
  } else {
    const newSearchParams = Object.assign(
      {},
      searchParams,
      { navigationTab: defaultTab.id }
    )

    setSearchParams(newSearchParams)
  }
}

export { UpdateTab }

// hybrids
import { html, define, render } from 'hybrids'
import ra from '!raw-loader!public/assets/img/flag/icon/ra.svg';

import { IOverlayRender } from '../types';

const IssueRollupOverlay: IOverlayRender<null> = {
  render: render((params: { count: { value: number }, urgent: { value: number } }) => {
    const { count, urgent } = params;
    const countString = Number(count.value) > 9
      ? '9+'
      : count.value

    const countContainerClass = urgent.value
      ? ['CountContainer', 'Urgent']
      : ['CountContainer']

    return html`
      <div
        class='TaskRollupOverlay Raised Animated'
      >
        <div class='${countContainerClass}'>
          <div>${countString}</div>
        </div>

        <div class='IconContainer' innerHTML='${ra}'></div>
      </div>
    `
  }, { shadowRoot: false })
}

define('issue-rollup-overlay', IssueRollupOverlay)

// Types
import ISpace from 'graphql-lib/interfaces/ISpace'
import IAreacam from 'graphql-lib//interfaces/IAreacam'

// Libs
import { format } from 'date-fns'
import { createDate } from 'utils/dates'
import ISetStateType from 'graphql-lib/interfaces/ISetStateType'

const UpdateCam = ({
  areacams,
  spaces,
  searchParams,
  setAreacam,
  setSpace,
  setTime
}: {
  areacams: Array<IAreacam>
  spaces: Array<ISpace>,
  searchParams: {
    viewAreacam: boolean
  }
  setAreacam: ISetStateType<IAreacam>,
  setSpace: ISetStateType<ISpace>,
  setTime: ISetStateType<string>
}): void => {
  if (!areacams || !searchParams.viewAreacam) return

  const areacam = areacams.find((a: IAreacam): boolean =>
    String(a.id) === searchParams.viewAreacam?.toString())

  setAreacam(areacam)

  setSpace(spaces.find((s: ISpace): boolean => String(s.id) === String(areacam.spaceId)))

  setTime(format(createDate(areacam.areaImageRecord[0].createdOn), 'P p'))
}

export {
  UpdateCam
}

/**
 * @author Miras Absar <mabsar@iunu.com>
 */

// Libs
import isMobile from 'is-mobile'
import { v4 } from 'uuid'

// React & React Libs
import { useState } from 'react'
import { useDebouncedEvent } from 'react-lib/use-event'
import { useIter } from 'react-lib/use-iter'

/**
 * Update state.
 */
const UpdateState = ({
  setMobile,
  setTablet,
  setDesktop,

  setWidth,
  setHeight,

  setUUID
}: {
  setMobile: (newVal: boolean) => void;
  setTablet: (newVal: boolean) => void;
  setDesktop: (newVal: boolean) => void;

  setWidth: (newVal: number) => void;
  setHeight: (newVal: number) => void;

  setUUID: (newVal: string) => void;
}): void => {
  const newMobile = isMobile()
  const newTablet = !newMobile && isMobile({ tablet: true })
  const newDesktop = !newMobile && !newTablet

  const newWidth = window.innerWidth
  const newHeight = window.innerHeight

  const newUUID = v4()


  setMobile(newMobile)
  setTablet(newTablet)
  setDesktop(newDesktop)
  setWidth(newWidth)
  setHeight(newHeight)
  setUUID(newUUID)
}

/**
 * Use the device's information as state.
 *
 * @example
 * const {
 *   mobile,
 *   tablet,
 *   desktop,
 *
 *   width,
 *   height,
 *
 *   uuid
 * } = useDevice()
 */
const useDevice = (): {
  mobile?: boolean;
  tablet?: boolean;
  desktop?: boolean;

  width?: number;
  height?: number;

  uuid?: string;
} => {
  // State /////////////////////////////////////////////////////////////////////

  const [mobile, setMobile] = useState<boolean>()
  const [tablet, setTablet] = useState<boolean>()
  const [desktop, setDesktop] = useState<boolean>()

  const [width, setWidth] = useState<number>()
  const [height, setHeight] = useState<number>()

  const [uuid, setUUID] = useState<string>()

  // Effects ///////////////////////////////////////////////////////////////////

  // Update state (on resize events).
  useDebouncedEvent(
    window,
    'resize',
    (): void => {
      UpdateState({
        setMobile,
        setTablet,
        setDesktop,

        setWidth,
        setHeight,

        setUUID
      })
    },
    [uuid],
    300
  )

  // Update state (initially).
  useIter(
    UpdateState,

    { setMobile,
      setTablet,
      setDesktop,

      setWidth,
      setHeight,

      setUUID },

    []
  )

  // Return ////////////////////////////////////////////////////////////////////

  return {
    mobile,
    tablet,
    desktop,

    width,
    height,

    uuid
  }
}

export default useDevice

/**
 * @author Miras Absar <mabsar@iunu.com>
 */

// Styles
import lunaLogo from 'public/assets/img/luna-logo.png'

// Types
import EInputStatus from 'ui-lib/enums/input-status'
import ICustomer from 'graphql-lib/interfaces/ICustomer'
import ID from 'graphql-lib/interfaces/ID'

// Utils
import { EStorage } from 'utils/storage'

// React
import React, {
  KeyboardEvent,
  useCallback,
  useState
} from 'react'

// React Libs
import GoTo from 'react-lib/go-to'
import useSearchParams from 'react-lib/use-search-params'
import useStorage from 'react-lib/use-storage'
import { useIter } from 'react-lib/use-iter'
import createRefreshToken from 'graphql-lib/states/create-refresh-token'
import createAccessToken from 'graphql-lib/states/create-access-token'
import useUser from 'graphql-lib/states/use-user'
import useCustomer from 'graphql-lib/states/use-customer'

// UI Lib
import { useInput } from 'ui-lib/hooks/use-input'
import { RequiredEmail } from 'ui-lib/validators/email'
import { RequiredPassword } from 'ui-lib/validators/password'
import OutlinedInput from 'ui-lib/components/input/outlined-input'
import Button from 'ui-lib/components/button'
import Overlay from 'zinnia/components/atoms/general/overlay'
import SelectCustomerCard from 'authorization/components/select-customer-card'

// Effects
import {
  SignInWithToken,
  SignInWithAccessToken,
  UpdateRefreshToken,
  CreateAccessToken,
  UpdateAccessToken,
  DecodeRefreshToken,
  DecodeAccessToken,
  UpdateUser,
  UpdateCustomer
} from './effects'

import { ForgotPasswordRoutes } from 'authorization/components/forgot-password'
import IAccessToken from 'graphql-lib/interfaces/IAccessToken'
import IRefreshToken from 'graphql-lib/interfaces/IRefreshToken'

// Routes
const SignInRoutes = [
  '/sign-in',
  '/'
]

const SignIn = (): JSX.Element => {
  // State /////////////////////////////////////////////////////////////////////

  const [
    searchParams,
    setSearchParams,
    searchParamsUUID
  ] = useSearchParams()

  const [refreshToken, setRefreshToken] = useStorage('refreshToken')
  const [accessToken, setAccessToken] = useStorage('accessToken', EStorage.EphemeralStorage)
  const [decodedRefreshToken, setDecodedRefreshToken] = useState<IRefreshToken>()
  const [decodedAccessToken, setDecodedAccessToken] = useState<IAccessToken>()

  const [userID, setUserID] = useStorage('userID')
  const [customerID, setCustomerID] = useStorage('customerID')
  const [user, setUser] = useStorage('user', EStorage.EphemeralStorage)
  const [customer, setCustomer] = useStorage('customer', EStorage.EphemeralStorage)
  const [facilities, setFacilities] = useStorage('facilities', EStorage.EphemeralStorage)

  const emailState = useInput('', RequiredEmail)
  const passwordState = useInput('', RequiredPassword)

  const [loading, setLoading] = useState<boolean>()
  const [error, setError] = useState<Error>()

  const {
    token,
    accessToken: urlAccessToken,
    cid
  } = searchParams

  const [
    email,
    emailStatus,
    emailUUID
  ] = emailState

  const [
    password,
    passwordStatus,
    passwordUUID
  ] = passwordState

  // API ///////////////////////////////////////////////////////////////////////

  const authorized =
    (
      (
        urlAccessToken &&
        accessToken
      ) ||
      (
        refreshToken &&
        accessToken
      )
    ) &&
    decodedAccessToken?.customerId &&
    userID &&
    customerID

  const {
    loading: refreshTokenLoading,
    error: refreshTokenError,
    refreshToken: newRefreshToken,
    uuid: refreshTokenUUID,

    create: refreshTokenCreate
  } = createRefreshToken()

  const {
    loading: accessTokenLoading,
    error: accessTokenError,
    accessToken: newAccessToken,
    uuid: accessTokenUUID,

    create: accessTokenCreate
  } = createAccessToken()

  const {
    loading: userLoading,
    error: userError,
    user: newUser,
    uuid: userUUID
  } = useUser(
    authorized
      ? userID
      : undefined,

    [ 'id',
      'customerId',
      'title',
      'name',
      'username',
      'email',
      'isApi',
      'isTest' ]
  )

  const {
    loading: customerLoading,
    error: customerError,
    customer: newCustomer,
    uuid: customerUUID
  } = useCustomer(
    authorized
      ? customerID
      : undefined,

    [ 'id',
      'code',
      'name',
      'segment',
      'temperatureMetric',
      'weightUnit',
      'areaUnit',
      'facility { id code name nickname timezone geojson dcs building {id, name} space {id, name, buildingId} subZone {id, name, code, zoneId} }',
      'appConfig'
    ]
  )

  // Effects ///////////////////////////////////////////////////////////////////

  useIter(
    SignInWithToken,

    { token,
      refreshTokenCreate },

    [searchParamsUUID]
  )

  useIter(
    SignInWithAccessToken,

    { accessToken: urlAccessToken,
      setAccessToken,
      setUserID,
      setCustomerID },

    [searchParamsUUID]
  )

  useIter(
    UpdateRefreshToken,

    { refreshTokenLoading,
      refreshTokenError,
      newRefreshToken,

      setLoading,
      setError,
      setRefreshToken,
      setUserID,
      setCustomerID },

    [refreshTokenUUID]
  )

  useIter(
    CreateAccessToken,

    { cid,
      refreshToken,
      customerID,

      accessTokenCreate },

    [ refreshToken,
      customerID ]
  )

  useIter(
    UpdateAccessToken,

    { accessTokenLoading,
      accessTokenError,
      newAccessToken,

      setLoading,
      setError,
      setAccessToken,
      setUserID,
      setCustomerID },

    [accessTokenUUID]
  )

  useIter(
    DecodeRefreshToken,

    { refreshToken,
      setDecodedRefreshToken },

    [refreshToken]
  )

  useIter(
    DecodeAccessToken,

    { accessToken,
      setDecodedAccessToken },

    [accessToken]
  )

  useIter(
    UpdateUser,

    { accessToken,
      userLoading,
      userError,
      newUser,

      setLoading,
      setError,
      setUser },

    [userUUID]
  )

  useIter(
    UpdateCustomer,

    { searchParams,
      customerLoading,
      customerError,
      newCustomer,

      setLoading,
      setError,
      setCustomer,
      setFacilities },

    [customerUUID]
  )

  // Callbacks /////////////////////////////////////////////////////////////////

  const $signIn = (): void => {
    if (emailStatus?.status === EInputStatus.OK &&
        passwordStatus?.status === EInputStatus.OK) {
      refreshTokenCreate({ email, password })
    }
  }

  const $forgotPassword = (): void => {
    GoTo(ForgotPasswordRoutes[0])
  }

  const $selectCustomer = (newCustomer: ICustomer): void => {
    // Reloading to make sure in memory caches
    // are cleared.
    const newHref = `${window.location.origin}?cid=${newCustomer.id}`
    window.location.href = newHref
  }

  const $keyPress = (ev: KeyboardEvent): void => {
    if (ev.key === 'Enter') {
      $signIn()
    }
  }

  // Render ////////////////////////////////////////////////////////////////////
  const showSelectCustomerCard =
    refreshToken &&
    accessToken &&
    userID &&
    !customerID

  return (
    <main
      className='SignIn'
      onKeyPress={$keyPress}
    >
      <section className='SignInCard'>
        <div className='Image'>
          <img src={lunaLogo} />
        </div>

        <OutlinedInput
          animated
          label='Email'
          inputProps={{ type: 'email' }}
          state={emailState}
        />

        <OutlinedInput
          animated
          label='Password'
          inputProps={{ type: 'password' }}
          state={passwordState}
        />

        <Button
          loading={loading}
          animated
          resting
          onClick={$signIn}
        >
          Sign In
        </Button>

        <Button
          animated
          flush
          onClick={$forgotPassword}
        >
          Forgot Password
        </Button>
      </section>

      <Overlay
        show={showSelectCustomerCard}
        animate
      >
        <div className='Overlay__Layout'>
          <SelectCustomerCard
            animated
            raised

            onSelect={$selectCustomer}
          />
        </div>
      </Overlay>
    </main>
  )
}

export { SignInRoutes }
export default SignIn

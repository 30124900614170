/* eslint-disable */
import React, {
  useEffect,
  useRef,
  useState,
} from 'react';

import Fit from 'react-fit';
import useAsyncEffect from 'use-async-effect';
import {useDebouncedCallback} from 'use-debounce';

import EInputStatus from '../../enums/input-status';
import IInputStatus from '../../interfaces/IInputStatus';
import ISearchSelectProps from '../../interfaces/ISearchSelectProps';

const FallbackInputStatus = Object.freeze({
  status: EInputStatus.Uninitialized,
  message: '',
});

export default ({
  /* Visual Properties */
  animated,
  label,
  hint,
  inputFacade: InputFacade,
  selectFacade: SelectFacade,
  itemFacade,

  /* Behavioral Properties */
  name,
  initialValue,
  showStatusUntouched,
  onValueChange,
  onStatusChange,
  readOnly = false,
  core: {
    inputType,
    getValue,
    getUIValue,
    getStatus,
    getValues,
  },
}: ISearchSelectProps<any, any>): JSX.Element => {

  /**
   * Initialize State
   */
  const initialValueRef = useRef(initialValue);
  const [value, setValue] = useState(initialValue);
  const [uiValue, setUIValue] = useState(getUIValue(initialValue));
  const [status, setStatus] = useState<IInputStatus>();
  const [touched, setTouched] = useState(false);
  const [dirty, setDirty] = useState(false);
  const [values, setValues] = useState<Array<any>>();
  const [showSelect, setShowSelect] = useState(false);

  /**
   * If `initialValue` has changes,
   * reinitialize state.
   *
   * This is useful if you want to programmatically change an input.
   */
  useEffect((): void => {
    if (initialValue !== initialValueRef.current) {
      initialValueRef.current = initialValue;
      setValue(initialValue);
      setUIValue(getUIValue(initialValue));
      setStatus(null);
      setTouched(false);
      setDirty(false);
      setValues(null);
      setShowSelect(false);
    }
  });

  /**
   * If `status` hasn't been computed,
   * compute it.
   */
  useAsyncEffect(async (): Promise<void> => {
    if (!status) {
      const newStatus = await getStatus(initialValue);
      setStatus(newStatus);
      if (name && onStatusChange) onStatusChange(name as keyof IInputStatus, newStatus);
    }
  });

  /**
   * If `values` hasn't been computed,
   * compute it.
   */
  useAsyncEffect(async (): Promise<void> => {
    if (!values) {
      const newValues = await getValues(uiValue);
      setValues(newValues);
    }
  });

  const [d$updateStatus] = useDebouncedCallback(
    async (newValue): Promise<void> => {
      const newStatus = await getStatus(newValue);
      setStatus(newStatus);
      if (name && onStatusChange) onStatusChange(name as keyof IInputStatus, newStatus);
    },
    300,
  );

  const [d$updateValues] = useDebouncedCallback(
    async (newValue): Promise<void> => {
      // const newValues = ;
      setValues(await getValues(newValue));
    },
    300,
  );

  const $focus = (): void => setShowSelect(true);
  const [$blur] = useDebouncedCallback(
    (): void => setShowSelect(false),
    300,
  );

  const input$blur = (): void =>
    setTouched(true);

  const input$change = (_name: string, newUIValue: string): void => {
    const newValue = getValue(newUIValue);

    setValue(newValue);
    setUIValue(newUIValue);
    d$updateStatus(newValue);
    setDirty(true);
    d$updateValues(newUIValue);

    if (name && onValueChange) onValueChange(name, newValue);
  };

  const select$select = (newValue: string): void => {
    const newUIValue = getUIValue(newValue);

    setValue(newValue);
    setUIValue(newUIValue);
    d$updateStatus(newValue);
    setDirty(true);
    d$updateValues(newUIValue);
    setShowSelect(false);

    if (name && onValueChange) onValueChange(name, newValue);
  };

  return (
    <div className='SearchSelect' onFocus={$focus} onBlur={$blur}>
      <InputFacade
        animated={animated}
        label={label}
        hint={hint}

        inputType={inputType}
        name={name}
        value={uiValue}
        status={status || FallbackInputStatus}
        touched={touched}
        dirty={dirty}
        showStatusUntouched={showStatusUntouched}
        onBlur={input$blur}
        onChange={input$change}
        readOnly={readOnly}
      />
      {!readOnly && showSelect && (
        <Fit>
          <SelectFacade
            animated={animated}
            itemFacade={itemFacade}
            values={values || []}
            onSelect={select$select}
          />
        </Fit>
      )}
    </div>
  );
};

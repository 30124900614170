/**
 * @author Miras Absar <mabsar@iunu.com>
 */

// Libs
import { debounce } from 'lodash'

// React
import { useEffect } from 'react'

// Types
interface IDebounceOptions {
  leading?: boolean;
  maxWait?: number;
  trailing?: boolean;
}

/**
 * Attach an event listener to an event target.
 *
 * @param target - The event target
 * @param type - The event type
 * @param listener - The event listener
 *
 * @example
 * useEvent(window, 'resize', (): void => console.log('resize'))
 */
const useEvent = (
  target: EventTarget,
  type: string,
  listener: EventListenerOrEventListenerObject,
  dependencies: any[]
): void => {
  useEffect(
    (): () => void => {
      target.addEventListener(type, listener)

      return (): void => {
        target.removeEventListener(type, listener)
      }
    },

    [ target,
      type,
      listener,
      ...dependencies ]
  )
}

/**
 * Attach a debounced event listener to an event target.
 *
 * @param target - The event target
 * @param type - The event type
 * @param listener - The event listener
 * @param wait - The number of milliseconds to delay
 * @param options - The options object
 *
 * @param [options.leading=false] - Specify invoking on the leading edge of the timeout
 * @param options.maxWait - The maximum time func is allowed to be delayed before it's invoked
 * @param [options.trailing=true] - Specify invoking on the trailing edge of the timeout
 *
 * @example
 * useDebouncedEvent(window, 'resize', (): void => console.log('resize'), 300)
 */
const useDebouncedEvent = (
  target: EventTarget,
  type: string,
  listener: (...args: any) => any,
  dependencies: any[],
  wait: number,
  options?: IDebounceOptions
): void => {
  useEffect(
    (): () => void => {
      const debouncedListener = debounce(listener, wait, options)
      target.addEventListener(type, debouncedListener)

      return (): void => {
        target.removeEventListener(type, debouncedListener)
      }
    },

    [ target,
      type,
      listener,
      ...dependencies,
      wait,
      options ]
  )
}

export {
  useEvent,
  useDebouncedEvent
}

/* eslint-disable */

import React, { Component } from 'react';;

import choreographState from '../../../../../utils/choreographState';

const ANIMATE_IN_DURATION = 150;
const ANIMATE_OUT_DURATION = 75;

export interface OverlayProps {
  show: boolean;
  animate: boolean;
};

export interface OverlayState {
  showing: boolean;
  animatingIn: boolean;
  animatingOut: boolean;
};

export class Overlay extends Component<OverlayProps, OverlayState> {

  state: OverlayState = {
    showing: false,
    animatingIn: false,
    animatingOut: false,
  }

  UNSAFE_componentWillMount = () => {
    this.UNSAFE_componentWillReceiveProps(this.props);
  }

  UNSAFE_componentWillReceiveProps = (nextProps: OverlayProps) => {
    if (!!nextProps.animate) {

      if (!!nextProps.show && !this.state.showing) {
        choreographState(this, [
          [ { showing: true, animatingIn: true }, 0 ],
          [ { animatingIn: false }, ANIMATE_IN_DURATION ]
        ]);
      }

      if (!nextProps.show && !!this.state.showing) {
        choreographState(this, [
          [ { animatingOut: true }, 0 ],
          [ { showing: false, animatingOut: false }, ANIMATE_OUT_DURATION ],
        ]);
      }

    } else {
      const nextState: Partial<OverlayState> = {};

      nextState.showing = !!nextProps.show;
      nextState.animatingIn = false;
      nextState.animatingOut = false;

      this.setState(nextState as OverlayState);
    }
  }

  render = () => {
    let className = 'Overlay';
    if (!this.state.showing) className += ' hidden';
    if (this.state.animatingIn) className += ' animateIn';
    else if (this.state.animatingOut) className += ' animateOut';

    if (this.state.showing) return <div className={className}>{this.props.children}</div>;
    else return <div className={className} />;
  }
}

export default Overlay;

// Types
import ITaskIssue from 'graphql-lib/interfaces/ITaskIssue';
import IFlag from 'graphql-lib/interfaces/IFlag';
import ID from 'graphql-lib/interfaces/ID';
import EEntity from 'map-view/enums/entity';

// Libs
import { cloneDeep } from 'lodash';

// React
import React, {
  MouseEvent,
  useContext,
  useEffect,
  useState,
  CSSProperties
} from 'react';

// FontAwesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationCircle } from '@fortawesome/pro-solid-svg-icons';

// Components
import PlaceTaskButtons from './../place-task-buttons';

// Contexts
import Mobius, { ITaskOrTaskIssueModel } from 'map-view/contexts/mobius';

// UI Lib
import Flag from 'ui-lib/components/flag';
import { createDate } from 'utils/dates';

// GraphQL
import { useMutation } from '@apollo/client';
import { UpdateTaskIssue } from './queries';
import { EStorage, GetItem } from 'utils/storage';

export interface ITaskIssueProps {
  outlined?: boolean;
  resting?: boolean;
  raised?: boolean;
  animated?: boolean;
  selected?: boolean;
  searchedTaskIssuesIndex: number;
  taskIssue: ITaskIssue;
  style?: CSSProperties;
}

const TaskIssue = ({
  outlined,
  resting,
  raised,
  animated,
  searchedTaskIssuesIndex,
  taskIssue,
  style
}: ITaskIssueProps): JSX.Element => {
  // Get info on the current logged in user.
  const user = GetItem('user', EStorage.EphemeralStorage)

  // Contexts //////////////////////////////////////////////////////////////////

  const {
    selectedEntities: {
      selectedEntities,
      selectEntity,
      selectedEntitiesUUID
    },
    taskIssues: {
      setTaskIssues,
      taskIssuesUUID
    }
  } = useContext(Mobius)

  // GraphQL Mutations /////////////////////////////////////////////////////////
  const [
    updateTaskIssue,
    {
      error: updateTaskIssueError,
      data: updateTaskIssueData
    }
  ] = useMutation(UpdateTaskIssue)

  // State /////////////////////////////////////////////////////////////////////

  const [customerTaskCategory, setCustomerTaskCategory] = useState<IFlag>()
  const [urgent, setUrgent] = useState<boolean>()
  const [selected, setSelected] = useState<boolean>()
  const [isNewIssue, setIsNewIssue] = useState<boolean>(!!taskIssue.reviewedAt)

  // Callbacks /////////////////////////////////////////////////////////////////

  const $click = (ev: MouseEvent<HTMLElement>): void => {
    selectEntity({
      type: EEntity.TaskIssue,
      id: taskIssue.id
    })
  }

  // Effects ///////////////////////////////////////////////////////////////////

  useEffect((): void => {
    if (taskIssue) {
      const newTaskIssue = taskIssue.customerTaskCategory[0]
      setCustomerTaskCategory(newTaskIssue)
    }
  }, [taskIssue])

  useEffect((): void => {
    if (taskIssue) {
      const newUrgent = taskIssue.priority > 0
      setUrgent(newUrgent)
    }
  }, [taskIssue, taskIssuesUUID])

  useEffect(
    (): void => {
      const newSelected = selectedEntities
        .some(entity =>
          entity.type === EEntity.TaskIssue &&
          String(entity.id) === String(taskIssue?.id));

      setSelected(newSelected)
    },

    [selectedEntitiesUUID, taskIssue?.id]
  )

  // Keep track of when a task is clicked on and update the field for the seen/not seen notification
  useEffect((): void => {
    // Only update reviewed field if the logged in user is a customer
    if (user.admin) return

    if (selected && isNewIssue) {
      // Stop showing this component as a new issue
      setIsNewIssue(false)

      // Make call to update issueReviewedOn date when user selects a new issue
      const currentDateTime = new Date().toISOString()
      updateTaskIssue({
        variables: {
          id: taskIssue.id,
          issueReviewedOn: currentDateTime
        }
      })

      // Update our react context's tasks object locally on the frontend
      setTaskIssues((prevState) => {
        const currentTaskIssues = cloneDeep(prevState)
        const updatedCurrentTaskIssues = currentTaskIssues.map((currentTaskIssue: ITaskOrTaskIssueModel) => {
          const currentTaskIssueType = currentTaskIssue as ITaskIssue
          if (String(currentTaskIssueType.id) === String(taskIssue.id)) {
            currentTaskIssueType.reviewedAt = createDate(currentDateTime)
          }
          return currentTaskIssue
        })
        return updatedCurrentTaskIssues
      })
    }
  }, [taskIssue, selected])

  // Render ////////////////////////////////////////////////////////////////////

  let className = 'TaskItem Outlined'
  if (resting) className += ' Resting'
  if (raised) className += ' Raised'
  if (animated) className += ' Animated'
  if (selected) className += ' Selected'

  const status = taskIssue.rejectedAt ? 'rejected' : 'current'
  return (
    <>
      <section
        style={style}
        className={className}
        onClick={$click}
      >
      <div className='FlagContainer'>
        {customerTaskCategory && (
          <Flag flag={customerTaskCategory} status={status} />
        )}

        {!customerTaskCategory && (
          <div className='FlagPlaceholder' />
        )}
      </div>

      <div className='Body'>
        <div className='Head'>
          {
            urgent && (<FontAwesomeIcon
                  className='Red'
                  icon={faExclamationCircle}
                />)
          }
          {/* Show issue types for issues. Otherwise show flag names */}
          <h6 className='Primary'>{`Issue detected. ${customerTaskCategory?.title}`}</h6>
        </div>
      </div>

      <div className='Icons'>

      </div>
      </section>
      { selected && status !== 'rejected' && taskIssue.convertedToTaskAt && <PlaceTaskButtons taskIssue={taskIssue} searchedTaskIssuesIndex={searchedTaskIssuesIndex} /> }
    </>
  )
}

export default TaskIssue

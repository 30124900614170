/**
 * @author Miras Absar <mabsar@iunu.com>
 */

// React
import React, {
  useEffect,
  useMemo,
  useRef,
  useState
} from 'react'

// React Libs
import { toast } from 'react-toastify'
import GoTo from 'react-lib/go-to'

// GraphQL
import { gql, useQuery, useMutation } from '@apollo/client'

// UI Enums & Interfaces
import EInputStatus from 'ui-lib/enums/input-status'
import IInputStatus from 'ui-lib/interfaces/IInputStatus'

// UI Components
import Input from 'ui-lib/components/Input/Input'
import SearchSelect from 'ui-lib/components/search-select'
import Checkbox from 'ui-lib/components/Checkbox/Checkbox'
import Button from 'ui-lib/components/button'

// UI Cores
import ContainerTypeSearchCore from '../../../cores/search-select/container-type'
import { NumberInputCore as NumberCore } from 'ui-lib/cores/number-input-core'
import { TextInputCore as TextCore } from 'ui-lib/cores/text-input-core'
import {
  DateInputCore as DateCore,
  RequiredDateInputCore as RequiredDateCore
} from 'ui-lib/cores/date-input-core'

// UI Facades
import SelectFacade from 'ui-lib/facades/select'
import ContainerTypeFacade from '../../../facades/search-select/container-type'
import TextFacade from 'ui-lib/facades/text-input'
import TextareaFacade from 'ui-lib/facades/textarea-input'
import DateFacade from 'ui-lib/facades/date-input'

import { createDate } from 'utils/dates'
import { EStorage, GetItem } from 'utils/storage'
import { utcToZonedTime } from 'date-fns-tz'
import ICrop from 'graphql-lib/interfaces/ICrop'
import IProduct from 'graphql-lib/interfaces/IProduct'
import ID from 'graphql-lib/interfaces/ID'

const QueryCrop = gql`
  query($id: ID) {
    crop(id: $id) {
      # Universal Properties
      id
      createdOn
      updatedOn

      # Crop Properties
      startDate
      transplantDate
      endDate
      enabled
      archived
      code
      name
      description
      containerSize
      containersPerLocator
      rating

      product {
        # Universal Properties
        id
        createdOn
        updatedOn

        # Product Properties
        code
        species
        variety
        nickname
      }
    }
  }
`

const UpdateCrop = gql`
  mutation(
    $id: ID!,
    $startDate: DateTime,
    $endDate: DateTime,
    $transplantDate: DateTime,
    $enabled: Boolean,
    $numberUnits: Int,
    $numberUnitsHarvested: Int,
    $name: String,
    $code: String,
    $description: String,
    $containerSize: String,
    $containersPerLocator: Int
  ) {
    Crop {
      update(input: {
        id: $id,
        startDate: $startDate,
        endDate: $endDate,
        transplantDate: $transplantDate,
        enabled: $enabled,
        numberUnits: $numberUnits,
        numberUnitsHarvested: $numberUnitsHarvested,
        name: $name,
        code: $code,
        description: $description,
        containerSize: $containerSize,
        containersPerLocator: $containersPerLocator
      }) {
        # Universal Properties
        id
        createdOn
        updatedOn

        # Crop Properties
        startDate
        transplantDate
        endDate
        enabled
        archived
        code
        name
        description
        containerSize
        containersPerLocator
        rating
      }
    }
  }
`

export interface IDetailsProps {
  animated?: boolean
  id: ID
}

const Details = ({
  animated,
  id
}: IDetailsProps): JSX.Element => {
  // State
  const [crop, setCrop] = useState<ICrop>()
  const [product, setProduct] = useState<IProduct>()
  const [pristine, setPristine] = useState<boolean>()
  const [showStatusUntouched, setShowStatusUntouched] = useState<boolean>()
  const [facility] = GetItem('facilities', EStorage.EphemeralStorage) ?? []

  // Refs
  const containerTypeSearchCore = useRef(new ContainerTypeSearchCore(false))
  const cropPatch = useRef<Partial<ICrop & Record<string, any>>>({})
  const status = useRef<Record<string, IInputStatus>>({})

  // Query a Crop
  const {
    error: cropError,
    data: cropData
  } = useQuery(QueryCrop, { variables: { id } })

  // Update a Crop
  const [
    updateCrop,
    {
      error: updateCropError,
      data: updateCropData
    }
  ] = useMutation(UpdateCrop)

  // If a Crop has been queried,
  // set state.
  useEffect((): void => {
    if (cropData) {
      const newCrop = { ...cropData.crop[0] }
      const newProduct = { ...newCrop.product[0] }

      newCrop.startDate = createDate(newCrop.startDate)
      newCrop.endDate = createDate(newCrop.endDate)
      if (newCrop.transplantDate) {
        newCrop.transplantDate = createDate(newCrop.transplantDate)
      }

      setCrop(newCrop)
      setProduct(newProduct)
      setPristine(true)
      setShowStatusUntouched(false)
    }
  }, [cropData])

  // If a Crop has been updated,
  // set state.
  useEffect((): void => {
    if (updateCropData) {
      const newCrop = updateCropData.Crop.update

      newCrop.startDate = createDate(newCrop.startDate)
      newCrop.endDate = createDate(newCrop.endDate)
      if (newCrop.transplantDate) {
        newCrop.transplantDate = createDate(newCrop.transplantDate)
      }

      setCrop(newCrop)
      setPristine(true)
      setShowStatusUntouched(false)

      cropPatch.current ={}
      status.current = {}

      // Subspace Update
      const detail = {}
      Object.assign(
        detail,
        updateCropData.Crop.update
      )

      Object.freeze(detail)
      const ev = new CustomEvent(
        'update_crop',
        { detail }
      )

      window.dispatchEvent(ev)

      setTimeout((): void => {
        GoTo(`/crops/${id}`)
      }, 16)
    }
  }, [updateCropData])

  useEffect((): void => {
    if (cropError) {
      toast.error("Couldn't load crop; please try again in a few seconds.")
    }
  }, [cropError])

  useEffect((): void => {
    if (updateCropError) {
      toast.error("Couldn't update crop; please try again in a few seconds.")
    }
  }, [updateCropData])

  const $value = (name: string, newValue: any): void => {
    if (name?.includes('Date')) {
      newValue =
        facility?.timezone != null && newValue != null
          ? utcToZonedTime(newValue, facility.timezone)
          : newValue
    }

    cropPatch.current[name] = newValue
    setPristine(false)
  }

  const $status = (name: string, newStatus: IInputStatus): void => {
    status.current[name] = newStatus
  }

  const $save = (): void => {
    const hasError = Object.values(status.current).reduce(
      (acc: boolean, cur: IInputStatus): boolean =>
        cur.status === EInputStatus.Error || acc,
      false
    )

    if (hasError) {
      setShowStatusUntouched(true)
    } else {
      cropPatch.current.id = id
      updateCrop({ variables: cropPatch.current })
    }
  }

  // Render crop dates based on facility timezone and not the users
  const [cropStartDate, cropTransplantDate, cropEndDate] = useMemo(() => {
    if (crop != null && facility?.timezone != null) {
      return [
        utcToZonedTime(crop.startDate, facility.timezone),
        crop.transplantDate !== null ? utcToZonedTime(crop.transplantDate, facility.timezone) : null,
        utcToZonedTime(crop.endDate, facility.timezone)
      ]
    } else if (crop != null) {
      return [crop.startDate, crop.transplantDate, crop.endDate]
    } else {
      return [undefined, undefined, undefined]
    }
  }, [crop, facility])
  if (crop && product) {
    return (
      <>
        <div className='Body Details'>
          <Input
            animated
            label='Species'
            facade={TextFacade}

            initialValue={product.species}
            readOnly
            core={TextCore}
          />

          <Input
            animated
            label='Variety'
            facade={TextFacade}

            initialValue={product.species}
            readOnly
            core={TextCore}
          />

          <Input
            animated
            label='Product Code'
            facade={TextFacade}

            initialValue={product.code}
            readOnly
            core={TextCore}
          />

          <SearchSelect
            animated
            label='Container Type'
            inputFacade={TextFacade}
            selectFacade={SelectFacade}
            itemFacade={ContainerTypeFacade}

            name='containerSize'
            initialValue={crop.containerSize}
            showStatusUntouched={showStatusUntouched}
            onValueChange={$value}
            onStatusChange={$status}
            core={containerTypeSearchCore.current}
          />

          <Input
            animated
            label='Containers per Group'
            facade={TextFacade}

            name='containersPerLocator'
            initialValue={crop.containersPerLocator}
            showStatusUntouched={showStatusUntouched}
            onValueChange={$value}
            onStatusChange={$status}
            readOnly={false}
            core={NumberCore}
          />

          <Input
            animated
            label='Start Date'
            facade={DateFacade}

            name='startDate'
            initialValue={cropStartDate}
            showStatusUntouched={showStatusUntouched}
            onValueChange={$value}
            onStatusChange={$status}
            readOnly={false}
            core={RequiredDateCore}
          />

          <Input
            animated
            label='Transplant Date'
            facade={DateFacade}

            name='transplantDate'
            initialValue={cropTransplantDate}
            showStatusUntouched={showStatusUntouched}
            onValueChange={$value}
            onStatusChange={$status}
            readOnly={false}
            core={DateCore}
          />

          <Input
            animated
            label='Finish Date'
            facade={DateFacade}

            name='endDate'
            initialValue={cropEndDate}
            showStatusUntouched={showStatusUntouched}
            onValueChange={$value}
            onStatusChange={$status}
            readOnly={false}
            core={RequiredDateCore}
          />

          <div className='Row'>
            <p className='Body1 Secondary'>Active</p>
            <div className='Space' />
            <Checkbox
              animated
              name='enabled'
              readOnly={false}
              initialValue={crop.enabled}
              onInput={$value}
            />
          </div>

          <Input
            animated
            label='Description'
            facade={TextareaFacade}

            name='description'
            initialValue={crop.description || ''}
            showStatusUntouched={showStatusUntouched}
            onValueChange={$value}
            onStatusChange={$status}
            readOnly={false}
            core={TextCore}
          />
        </div>

        <div style={{ flex: '1 0 auto' }} />

        {!pristine && (
          <div className='Actions'>
            <Button
              animated={animated}
              outlined
              onClick={$save}
            >
              Save
            </Button>
          </div>
        )}
      </>
    )
  }

  return (
    <div className='Body Details'>
      <div className='Input Loading' />
      <div className='Input Loading' />
      <div className='Input Loading' />
      <div className='Input Loading' />
      <div className='Input Loading' />
      <div className='Input Loading' />
      <div className='Input Loading' />
      <div className='Input Loading' />
      <div className='Input Loading' />
      <div className='Input Loading' />
    </div>
  )
}

export default Details

/**
 * @author Miras Absar <mabsar@iunu>
 */

import {
  faMap,
  faWarehouseAlt,
  faSeedling,
  faTasks,
  faCommentsAlt,
} from '@fortawesome/pro-light-svg-icons'
import { faChartPie } from '@fortawesome/pro-solid-svg-icons'

import isMobile from 'is-mobile'
import IRoute from 'ui-lib/interfaces/IRoute'
import { GetItem, EStorage } from 'utils/storage'

const MapRoute: IRoute = {
  id: 'route::map',
  get route (): string {
    const facilities = GetItem('facilities', EStorage.EphemeralStorage)
    const defaultFacilityId = facilities[0].id
    if (isMobile()) {
      return `/map/${defaultFacilityId}`
    }
    return `/map/${defaultFacilityId}`
  },

  get active (): boolean {
    return location.pathname.startsWith('/map')
  },
  disabled: false,

  icon: faMap,
  name: 'Map'
}

const Report: IRoute = {
  id: 'route::report',
  get route (): string {
    return `/report`
  },

  get active (): boolean {
    return location.pathname.startsWith('/report')
  },
  disabled: false,

  icon: faChartPie,
  name: 'Reports'
}

const FacilityRoute: IRoute = {
  id: 'route::facility',
  get route (): string {
    const customer = GetItem('customer', EStorage.EphemeralStorage)
    const facilities = GetItem('facilities', EStorage.EphemeralStorage)
    const defaultFacilityId = facilities[0].id

    if (customer.id == 1216) {
      return '/static-camera'
    }

    return `/grid/facility/${defaultFacilityId}`
  },

  get active (): boolean {
    return location.pathname.startsWith('/grid') ||
      location.pathname.startsWith('/static-camera')
  },
  disabled: false,

  icon: faWarehouseAlt,
  name: 'Facility'
}

const CropsRoute: IRoute = {
  id: 'route::crops',
  route: '/crops',
  get active (): boolean {
    return location.pathname.startsWith('/crops')
  },
  disabled: false,

  icon: faSeedling,
  name: 'Crops'
}

const TasksRoute: IRoute = {
  id: 'route::tasks',
  route: '/tasks/task',
  get active (): boolean {
    return location.pathname.startsWith('/tasks')
  },
  disabled: false,
  icon: faTasks,
  name(admin) { 
    return admin ? 'Tasks & Issues' : 'Tasks';
  }
}

const MessagingRoute: IRoute = {
  id: 'route::messaging',
  route: null,
  active: false,
  disabled: true,

  icon: faCommentsAlt,
  name: 'Messaging'
}

export {
  MapRoute,
  FacilityRoute,
  CropsRoute,
  TasksRoute,
  MessagingRoute,
  Report
}

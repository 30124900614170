import IColumn from 'arrayview/interfaces/IColumn'
import IGroup from 'arrayview/interfaces/IGroup'

import IFlag from '../interfaces/IFlag'

const SafeIncludes = (a: string, b: string): boolean => {
  a = a || ''
  b = b || ''

  return a.toLowerCase().includes(b.toLowerCase())
}

const SafeCompare = (a: string, b: string): number => {
  a = a || ''
  b = b || ''

  return a.localeCompare(b)
}

const TitleColumn: IColumn<IFlag> = Object.freeze({
  id: 'flag::title',
  title: 'Title',
  searchFunc: (_: IColumn<IFlag>, search: string, data: IFlag): boolean =>
    SafeIncludes(data.title, search),

  ascendingSortFunc: (_: IColumn<IFlag>, first: IFlag, second: IFlag): number =>
    SafeCompare(first.title, second.title),

  descendingSortFunc: (_: IColumn<IFlag>, first: IFlag, second: IFlag): number =>
    SafeCompare(second.title, first.title),

  groupFunc: (_: IColumn<IFlag>, data: IFlag): IGroup => ({
    id: `${_.id}::${data.title}`,
    name: data.title,
    data: []
  })
})

const DescriptionColumn: IColumn<IFlag> = Object.freeze({
  id: 'flag::description',
  description: 'Email',
  searchFunc: (_: IColumn<IFlag>, search: string, data: IFlag): boolean =>
    SafeIncludes(data.description, search),

  ascendingSortFunc: (_: IColumn<IFlag>, first: IFlag, second: IFlag): number =>
    SafeCompare(first.description, second.description),

  descendingSortFunc: (_: IColumn<IFlag>, first: IFlag, second: IFlag): number =>
    SafeCompare(second.description, first.description),

  groupFunc: (_: IColumn<IFlag>, data: IFlag): IGroup => ({
    id: `${_.id}::${data.description}`,
    name: data.description,
    data: []
  })
})

export {
  TitleColumn,
  DescriptionColumn
}

import React from 'react'
import DatePicker from 'react-date-picker'

import EInputStatus from '../../enums/input-status'
import IInputFacadeProps from '../../interfaces/IInputFacadeProps'
import Status2ClassName from '../../utils/Status2ClassName'
/* eslint-disable */
export default function DateInputFacade({
  /* Visual Properties */
  animated,
  label,

  /* Behavioral Properties */
  name,
  value,
  status,
  touched,
  dirty,
  showStatusUntouched,
  onBlur,
  onChange,
  readOnly
}: IInputFacadeProps<Date | Date[]>): JSX.Element {
  const className = animated ? 'Input Animated' : 'Input'
  const calendarClassName = animated ? 'Raised Animated' : 'Raised'
  const showingStatus = (touched || dirty || showStatusUntouched) &&
    status.status !== EInputStatus.Uninitialized &&
    status.status !== EInputStatus.None

  const statusClassName = Status2ClassName[status.status]

  const $input = (newValue: Date | Date[]) => {
    if (name && onChange) {
      onChange(name, newValue)
    }
  }

  return (
    <div className={className}>
      <label>{label}</label>

      <DatePicker
        calendarClassName={calendarClassName}
        name={name}
        value={value}
        onChange={$input}
        disabled={readOnly}
      />

      {showingStatus
        ? <p className={statusClassName}>{status.message}</p>
        : null}
    </div>
  )
}

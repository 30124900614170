// Types
import ID from 'graphql-lib/interfaces/ID';

// Libs
import { v4 } from 'uuid'
import {
  parse,
  stringify
} from 'query-string'

// React
import React, {
  useEffect,
  useState
} from 'react'

// React Libs
import { toast } from 'react-toastify'

// GraphQL
import { gql, useQuery } from '@apollo/client'

// Font Awesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimes } from '@fortawesome/pro-regular-svg-icons'

// Utils
import Has from 'utils/has'
import IsVoid from 'utils/is-void'
import GoTo from 'react-lib/go-to'

// UI Lib
import { Crops } from '../../utils/Routes'
import VirtualScanner from '../virtual-scanner'
import Setup from './setup'
import Provision from './provision'
import ICrop from 'graphql-lib/interfaces/ICrop'

const QueryCrop = gql`
  query($id: ID) {
    crop(id: $id) {
      id
      name
      containerSize
      containersPerLocator
      inventoryCount
    }
  }
`

export enum ETagType {
  Virtual = 'TagType:Virtual',
  Physical = 'TagType:Physical'
}

export interface ITagCardProps {
  outlined?: boolean;
  resting?: boolean;
  raised?: boolean;
  animated?: boolean;

  id: ID
  facilityId: ID
}

const TagCard = ({
  outlined,
  resting,
  raised,
  animated,

  id,
  facilityId
}: ITagCardProps): JSX.Element => {
  // URL params ////////////////////////////////////////////////////////////////
  const {
    page,
    twoTag
  } = parse(location.search)

  const setPage = (newPage: number | string): void => {
    const { pathname } = location
    const newSearch = stringify({
      page: newPage,
      twoTag
    })

    GoTo(`${pathname}?${newSearch}`)
  }

  // State /////////////////////////////////////////////////////////////////////
  const [crop, setCrop] = useState<ICrop>()
  const [containerSize, setContaineSize] = useState<string>()
  const [containersPerLocator, setContainersPerLocator] = useState<number>()

  const [currentGroup, setCurrentGroup] = useState<number>()
  const [totalGroups, setTotalGroups] = useState<number>()

  const [newTagText, setNewTagText] = useState<string>()
  const [newTagType, setNewTagType] = useState<string>()

  const [showVirtualScanner, setShowVirtualScanner] = useState<boolean>()

  const [uuid, setUuid] = useState(v4())

  // GraphQL ///////////////////////////////////////////////////////////////////

  // Query a Crop
  const {
    error,
    data
  } = useQuery(QueryCrop, { variables: { id } })

  // Effects ///////////////////////////////////////////////////////////////////

  // If quering a Crop returned an error,
  // display it.
  useEffect((): void => {
    if (error) {
      toast.error("Couldn't load crop; please try again in a few seconds.")
    }
  }, [error])

  // If quering a Crop returned data,
  // set it.
  useEffect((): void => {
    if (data) {
      const newCrop = data.crop[0]
      const newContainerSize = newCrop.containerSize
      const newContainersPerLocator = newCrop.containersPerLocator
      const newCurrentGroup = newCrop.inventoryCount + 1

      setCrop(newCrop)
      setContaineSize(newContainerSize)
      setContainersPerLocator(newContainersPerLocator)
      setCurrentGroup(newCurrentGroup)
      setTotalGroups(newCurrentGroup)
    }
  }, [data])

  // Callbacks /////////////////////////////////////////////////////////////////

  const $close = (): void => GoTo(Crops)

  const $closeScanner = (): void => {
    setShowVirtualScanner(false)
  }

  const virtualScanner$scan = (code?: string): void => {
    if (Has(code)) {
      setNewTagText(code)
      setNewTagType(ETagType.Virtual)
    }
  }

  const virtualScanner$error = (): void => {
    setShowVirtualScanner(false)
  }

  const setup$continue = (): void => setPage(1)

  const provision$showVirtualScanner = (show: boolean = true): void => {
    setShowVirtualScanner(show)
  }

  const provision$done = (): void => GoTo(Crops)
  const provision$next = (): void => {
    const newCurrentGroup = currentGroup + 1
    setCurrentGroup(newCurrentGroup)
    setTotalGroups(newCurrentGroup)

    setNewTagText('')
    setNewTagType('')

    setUuid(v4())
  }

  // Render ////////////////////////////////////////////////////////////////////

  let className = 'Theme__Light Card TagCard'
  if (outlined) className += ' Outlined'
  if (resting) className += ' Resting'
  if (raised) className += ' Raised'
  if (animated) className += ' Animated'

  const closeScannerButtonClassName = animated
    ? 'Animated'
    : ''

  return (
    <article className={className}>
      <div className='Head'>
        <div className='Row'>
          <button onClick={$close}>
            <FontAwesomeIcon icon={faTimes} />
          </button>
          {crop
            ? <h6 className='Primary'>{crop.name}</h6>
            : <h6 className='Loading' />}
        </div>
      </div>

      {(showVirtualScanner || showVirtualScanner) && (
        <div className='CloseScanner'>
          <button
            className={closeScannerButtonClassName}
            onClick={$closeScanner}
          >
            <FontAwesomeIcon icon={faTimes} />
          </button>
        </div>
      )}

      {showVirtualScanner && (
        <VirtualScanner
          onScan={virtualScanner$scan}
          onError={virtualScanner$error}
        />
      )}

      {(IsVoid(page) || Number(page) === 0) && (
        <Setup
          containerSize={containerSize}
          containersPerLocator={containersPerLocator}
          totalGroups={totalGroups}

          setContainerSize={setContaineSize}
          setContainersPerLocator={setContainersPerLocator}
          setTotalGroups={setTotalGroups}

          onContinue={setup$continue}
        />
      )}

      {Number(page) === 1 && (
        <Provision
          key={uuid}

          outlined={outlined}
          resting={resting}
          raised={raised}
          animated={animated}

          id={id}
          facilityId={facilityId}
          twoTag={!!twoTag}

          containerSize={containerSize}
          containersPerLocator={containersPerLocator}
          currentGroup={currentGroup}
          totalGroups={totalGroups}

          newTagText={newTagText}
          newTagType={newTagType}

          onShowVirtualScanner={provision$showVirtualScanner}

          onDone={provision$done}
          onNext={provision$next}
        />
      )}
    </article>
  )
}

export default TagCard

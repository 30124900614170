/**
 * @author Miras Absar <mabsar@iunu.com>
 */

import { v4 } from 'uuid'
import m from 'moment-timezone'

import React, {
  useEffect,
  useState
} from 'react'

import useAsyncEffect from 'use-async-effect'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faPaperclip,
  faPaperPlane,
  faFile,
  faFileImage,
  faTimes
} from '@fortawesome/pro-light-svg-icons'
import { toast } from 'react-toastify';
import { useDropzone } from 'react-dropzone';

import { gql, useQuery, useMutation } from '@apollo/client';
import client from 'graphql-lib/index';

import { GetItem, EStorage } from 'utils/storage';
import BlobUtils from 'utils/BlobUtils';
import B64DataUrlUtils from 'utils/B64DataUrlUtils';
import Input from 'ui-lib/components/Input/Input';
import TextAreaFacade from 'ui-lib/facades/textarea-input';
import {
  RequiredTextInputCore as RequiredTextCore
} from 'ui-lib/cores/text-input-core';
import ITaskComment from 'graphql-lib/interfaces/ITaskComment';
import ID from 'graphql-lib/interfaces/ID';

const QueryComments = gql`
  query($id: ID) {
    task(id: $id) {
      id

      taskComment {
        id
        createdOn

        body

        taskCommentAttachment {
          id

          attachment {
            mimetype
            filename
            url
          }
        }

        user {
          name
          username
          email
        }
      }
    }
  }
`

const CreateComment = gql`
  mutation(
    $taskId: ID!,
    $body: String!
  ) {
    TaskComment {
      create(input: {
        taskId: $taskId,
        body: $body
      }) {
        id
      }
    }
  }
`

const UploadAttachment = gql`
  mutation(
    $name: String!,
    $path: String!,
    $size: Int!,
    $type: String!,
    $base64: String!
  ) {
    Attachment {
      create(input: {
        name: $name,
        path: $path,
        size: $size,
        type: $type,
        base64: $base64
      }) {
        id
      }
    }
  }
`

const CreateCommentAttachment = gql`
  mutation(
    $attachmentId: ID!,
    $taskCommentId: ID!
  ) {
    TaskCommentAttachment {
      create(input: {
        attachmentId: $attachmentId,
        taskCommentId: $taskCommentId
      }) {
        id
      }
    }
  }
`

export interface IViewTaskCardCommentsProps {
  animated?: boolean;
  id: ID;
}

const ViewTaskCardComments = ({
  animated,
  id
}: IViewTaskCardCommentsProps): JSX.Element => {
  const {
    refetch: refetchComments,
    error: commentsError,
    data: commentsData
  } = useQuery(QueryComments, {
    variables: { id }
  })

  const [
    createComment,
    {
      error: createCommentError,
      data: createCommentData
    }
  ] = useMutation(CreateComment)

  const [uuid, setUuid] = useState<string>(v4())
  const [comments, setComments] = useState<Array<ITaskComment>>()
  const [showStatusUntouched, setShowStatusUntouched] = useState<boolean>()
  const [newComment, setNewComment] = useState<string>()
  const [newAttachments, setNewAttachments] = useState<Array<File>>()

  /** If `commentsError` changed, display it. */
  useEffect((): void => {
    if (commentsError) {
      toast.error("Couldn't load comments.")
    }
  }, [commentsError])

  /** If `createCommentError` changed, display it. */
  useEffect((): void => {
    if (createCommentError) {
      toast.error("Couldn't create comment.")
    }
  }, [createCommentError])

  /** If `commentsData` changed, update comments. */
  useEffect((): void => {
    if (commentsData) {
      const newComments = commentsData.task[0].taskComment
      setComments(newComments)
    }
  }, [commentsData])

  /**  */
  useAsyncEffect(async (): Promise<void> => {
    if (createCommentData) {
      const hasAttachments =
        newAttachments &&
        newAttachments.length > 0

      if (hasAttachments) {
        const promises = newAttachments.map(async (newAttachment): Promise<void> => {
          const dataUrl = await BlobUtils.toDataUrl(newAttachment)
          const base64 = await B64DataUrlUtils.getBase64(dataUrl)

          const {
            errors: uploadAttachmentError,
            data: uploadAttachmentData
          } = await client.mutate({
            mutation: UploadAttachment,
            variables: {
              name: newAttachment.name,
              path: encodeURIComponent(newAttachment.name),
              size: newAttachment.size,
              type: newAttachment.type,
              base64
            }
          })

          if (uploadAttachmentError) {
            toast.error(`Couldn't upload ${newAttachment.name}.`)
          } else {
            const {
              TaskComment: {
                create: {
                  id: taskCommentId
                }
              }
            } = createCommentData

            const {
              Attachment: {
                create: {
                  id: attachmentId
                }
              }
            } = uploadAttachmentData

            const {
              errors: createCommentAttachmentError
            } = await client.mutate({
              mutation: CreateCommentAttachment,
              variables: {
                taskCommentId,
                attachmentId
              }
            })

            if (createCommentAttachmentError) {
              toast.error(`Couldn't attach ${newAttachment.name}.`)
            }
          }
        })

        await Promise.all(promises)
      }

      setUuid(v4())
      setShowStatusUntouched(false)
      setNewComment('')
      setNewAttachments([])
      refetchComments()
    }
  }, [createCommentData])

  /** Update `newComment`. */
  const $comment = (name: string, value: string): void => {
    setNewComment(value)
  }

  /** Update `newAttachments`. */
  const $addAttachment = (newFiles: Array<File>): void => {
    const newNewAttachments = [
      ...(newAttachments || []),
      ...newFiles
    ]

    setNewAttachments(newNewAttachments)
  }

  /** If `newComment` is valid, create it. */
  const $createComment = (): void => {
    const isNewCommentInvalid =
      !newComment ||
      /^\s+$/.test(newComment)

    if (isNewCommentInvalid) {
      setShowStatusUntouched(true)
    } else {
      createComment({
        variables: {
          taskId: id,
          body: newComment
        }
      })
    }
  }

  const {
    getRootProps,
    getInputProps
  } = useDropzone({
    onDrop: $addAttachment
  })

  return (
    <>
      <div className='Body Comments'>
        {comments?.map((comment): JSX.Element => {
          const facilities = GetItem('facilities', EStorage.EphemeralStorage)
          const timezone = facilities[0].timezone
          const user = comment.user[0]

          return (
            <div
              key={comment.id}
              className='Comment'
            >
              <div className='Body'>{comment.body}</div>
              <div className='Meta'>
                {user.name || user.email}
                &ensp;-&ensp;
                {m.tz(comment.createdOn, timezone).format('lll zz')}
              </div>
              <div className='Attachments'>
                {comment.taskCommentAttachment.map((taskCommentAttachment): JSX.Element => {
                  const attachment = taskCommentAttachment.attachment[0]

                  let icon = faFile
                  if (attachment.mimetype.startsWith('image/')) {
                    icon = faFileImage
                  }

                  const $open = (): void => {
                    window.open(attachment.url, '_blank')
                  }

                  return (
                    <div
                      key={taskCommentAttachment.id}
                      className='Attachment'
                      onClick={$open}
                    >
                      <FontAwesomeIcon icon={icon} />
                      <div>{attachment.filename}</div>
                    </div>
                  )
                })}
              </div>
            </div>
          )
        })}
      </div>

      <div className='NewComment'>
        <div className='Inputs'>
          <Input
            key={uuid}
            animated={animated}
            label='New Comment'
            facade={TextAreaFacade}
            name='new-comment'
            showStatusUntouched={showStatusUntouched}
            onValueChange={$comment}
            core={RequiredTextCore}
            readOnly={false}
          />

          <button {...getRootProps()}>
            <input {...getInputProps()} />
            <FontAwesomeIcon icon={faPaperclip} />
          </button>

          <button onClick={$createComment}>
            <FontAwesomeIcon icon={faPaperPlane} />
          </button>
        </div>

        <div className='Attachments'>
          {newAttachments && newAttachments.map((newAttachment, index): JSX.Element => {
            let icon = faFile
            if (newAttachment.type.startsWith('image/')) {
              icon = faFileImage
            }

            const $removeAttachment = (): void => {
              const newNewAttachments = [...newAttachments]
              newNewAttachments.splice(index, 1)

              setNewAttachments(newNewAttachments)
            }

            return (
              <div
                key={index}
                className='Attachment'
              >
                <FontAwesomeIcon icon={icon} />
                <div>{newAttachment.name}</div>
                <button onClick={$removeAttachment}>
                  <FontAwesomeIcon icon={faTimes} />
                </button>
              </div>
            )
          })}
        </div>
      </div>
    </>
  )
}

export default ViewTaskCardComments

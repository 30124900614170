/**
 * @author Miras Absar <mabsar@iunu.com>
 */

// Types
import EInputStatus from 'ui-lib/enums/input-status'
import IInputStatus from 'ui-lib/interfaces/IInputStatus'

const Text = (): IInputStatus => ({
  status: EInputStatus.OK,
  message: ''
})

const RequiredText = (val: string): IInputStatus => {
  if (/^\s*$/.test(val)) {
    return {
      status: EInputStatus.Error,
      message: 'Required'
    }
  } else {
    return {
      status: EInputStatus.OK,
      message: ''
    }
  }
}

export {
  Text,
  RequiredText
}

// Types
import EDiff from '../../enums/diff'
import EOverlay from '../../enums/overlay'
import ITime from '../../interfaces/time'
import IDiff from '../../interfaces/diff'
import IEntity from '../../interfaces/entity'

import IFacility from 'graphql-lib/interfaces/IFacility'
import IBuilding from 'graphql-lib/interfaces/IBuilding'
import ISpace from 'graphql-lib/interfaces/ISpace'

import ISensor, { ISensorRecord } from 'graphql-lib/interfaces/ISensorDevice'
import IAreacam from 'graphql-lib/interfaces/IAreacam'

import IGridPrediction from 'graphql-lib/interfaces/IGridPrediction'
import IInventory from 'graphql-lib/interfaces/IInventory'
import IContainer from 'graphql-lib/interfaces/IContainer'
import IHarvestGroup from 'graphql-lib/interfaces/IHarvestGroup'
import IInventoryStitch from 'graphql-lib/interfaces/IInventoryStitch'
import IHeatmap from 'graphql-lib/interfaces/IHeatmap'

import IChart from '../../interfaces/chart'

// Libs
import { isEqual } from 'date-fns'
import Debug from 'debug'
import { v4 } from 'uuid'
import useStorage from 'react-lib/use-storage'

// React
import React, {
  Children,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState
} from 'react'

// React Libs
import { useRouteMatch } from 'react-router-dom'
import { useEvent } from 'react-lib/use-event'
import { useIter } from 'react-lib/use-iter'
import useSearchParams from 'react-lib/use-search-params'

// Contexts
import NavigationContext from 'context/navigation'

// GraphQL
import { useQuery } from '@apollo/client'
import {
  QueryStaticData,
  QuerySensors,
  QueryAreacams,
  QuerySensorRecords,
  QueryCO2SensorRecords,
  QueryGreenAutomationWaterSystemRecords,
  QueryAreaImageRecords,
  QueryGridPredictions,
  QueryInventories,
  QueryContainers,
  QueryInventoryStitches,
  QueryContainerStitches,
  QueryCV,
  QueryTasks,
  QueryTaskIssues,
  QueryHarvestGroups
} from './queries'

// Effects
import {
  UpdateTime,
  UpdateSelectedEntities,
  UpdateOverlays,
  UpdateStaticData,
  UpdateSensorIDs,
  UpdateSensors,
  UpdateWaterSystems,
  UpdateAreacamIDs,
  UpdateAreacams,
  UpdateGridPredictions,
  UpdateInventories,
  UpdateContainers,
  UpdateHarvestGroups,
  UpdateInventoryStitches,
  UpdateContainerStitches,
  UpdateCV,
  UpdateSpaceCharts,
  UpdateInventoryCharts,
  UpdateContainerCharts,
  UpdateTasks,
  UpdateTaskIssues,
  LogSelectedEntities
} from './effects'

// MapView
import { MapViewRoute } from '../../utils/routes'
import MobiusContext, { IFunctionTask } from '../../contexts/mobius'
import EChart from '../../enums/chart'
import { GraphQLError } from 'graphql'
import IOverlay from 'map-view/interfaces/overlay'
import IGreenAutomationWaterSystemRecord from 'graphql-lib/interfaces/IGreenAutomationWaterSystemRecord'
import IContainerStich from 'graphql-lib/interfaces/IContainerStitch'
import IBudDetection from 'graphql-lib/interfaces/IBudDetection'
import ICanopyCoverage, { ICanopyHeight } from 'graphql-lib/interfaces/ICanopyCoverage'
import IFlowerCoverage from 'graphql-lib/interfaces/IFlowerCoverage'
import IGerminationRate from 'graphql-lib/interfaces/IGerminationRate'
import ITopCanopyCoverage, { ITopGerminationRate } from 'graphql-lib/interfaces/ITopCanopyCoverage'
import IYieldPredictionAverage from 'graphql-lib/interfaces/IYieldPredictionAverage'
import ITask from 'graphql-lib/interfaces/ITask'
import ITaskIssue from 'graphql-lib/interfaces/ITaskIssue'
import ID from 'graphql-lib/interfaces/ID';
const SENSOR_CHARTS = new Set<string>([
  EChart.DLI,
  EChart.AbsoluteHumidity,
  EChart.Humidity,
  EChart.Temperature,
  EChart.umol,
  EChart.VPD
])

interface IMobiusProps {
  children: React.ReactNode;
}

const debug = Debug('Mobius:Index')
const Mobius = ({
  children
}: IMobiusProps): JSX.Element => {
  // Contexts //////////////////////////////////////////////////////////////////

  const { setTitle } = useContext(NavigationContext)

  // State /////////////////////////////////////////////////////////////////////

  const [customerID] = useStorage('customerID')
  const onlyInventories = false
  const onlyContainers = true

  // Params ////

  const { params: { id } } = useRouteMatch<{id: string}>(MapViewRoute)
  const [searchParams, setSearchParams, searchParamsUUID] = useSearchParams()

  const [time, setTime] = useState<ITime>()
  const [selectedEntities, setSelectedEntities] = useState<Array<IEntity>>()
  const [visibleEntities, setVisibleEntities] = useState<IEntity[]>()
  const [overlays, setOverlays] = useState<Array<EOverlay>>()
  const [zoom, setZoom] = useState<any>()

  // IDs ////

  const [facilityID, setFacilityID] = useState<ID>()
  const [buildingIDs, setBuildingIDs] = useState<ID[]>()
  const [spaceIDs, setSpaceIDs] = useState<ID[]>()

  const [sensorIDs, setSensorIDs] = useState<ID[]>()
  const [areacamIDs, setAreacamIDs] = useState<ID[]>()

  const [inventoryIDs, setInventoryIDs] = useState<ID[]>()
  const [inventoryWOCIDs, setInventoryWOCIDs] = useState<ID[]>()
  const [inventoryWCIDs, setInventoryWCIDs] = useState<ID[]>()
  const [containerIDs, setContainerIDs] = useState<ID[]>()

  // Loading ////

  const [facilityLoading, setFacilityLoading] = useState<boolean>()
  const [buildingsLoading, setBuildingsLoading] = useState<boolean>()
  const [spacesLoading, setSpacesLoading] = useState<boolean>()

  const [sensorsLoading, setSensorsLoading] = useState<boolean>()
  const [waterSystemsLoading, setWaterSystemsLoading] = useState<boolean>()
  const [areacamsLoading, setAreacamsLoading] = useState<boolean>()

  const [gridPredictionsLoading, setGridPredictionsLoading] = useState<boolean>()
  const [inventoriesLoading, setInventoriesLoading] = useState<boolean>()
  const [containersLoading, setContainersLoading] = useState<boolean>()
  const [harvestGroupsLoading, setHarvestGroupsLoading] = useState<boolean>(false)

  const [inventoryStitchesLoading, setInventoryStitchesLoading] = useState<boolean>()
  const [containerStitchesLoading, setContainerStitchesLoading] = useState<boolean>()

  const [heatmapsLoading, setHeatmapsLoading] = useState<boolean>()
  const [budDetectionsLoading, setBudDetectionsLoading] = useState<boolean>()
  const [canopyCoveragesLoading, setCanopyCoveragesLoading] = useState<boolean>()
  const [canopyHeightsLoading, setCanopyHeightsLoading] = useState<boolean>()
  const [flowerCoveragesLoading, setFlowerCoveragesLoading] = useState<boolean>()
  const [germinationRatesLoading, setGerminationRatesLoading] = useState<boolean>()
  const [topCanopyCoveragesLoading, setTopCanopyCoveragesLoading] = useState<boolean>()
  const [topGerminationRatesLoading, setTopGerminationRatesLoading] = useState<boolean>()
  const [potCountsLoading, setPotCountsLoading] = useState<boolean>()
  const [readinessCoveragesLoading, setReadinessCoveragesLoading] = useState<boolean>()
  const [yieldPredictionAveragesLoading, setYieldPredictionAveragesLoading] = useState<boolean>()
  const [boardCountLoading, setBoardCountLoading] = useState<boolean>()
  const [totalWeightLoading, setTotalWeightLoading] = useState<boolean>()

  const [spaceChartsLoading, setSpaceChartsLoading] = useState<boolean>()
  const [inventoryChartsLoading, setInventoryChartsLoading] = useState<boolean>()
  const [containerChartsLoading, setContainerChartsLoading] = useState<boolean>()

  const [tasksLoading, setTasksLoading] = useState<boolean>()
  const [taskIssuesLoading, setTaskIssuesLoading] = useState<boolean>()

  const [loadSensorHistory, setLoadSensorHistory] = useState(false)

  // Errors ////

  const [facilityError, setFacilityError] = useState<Error>()
  const [buildingsError, setBuildingsError] = useState<Error>()
  const [spacesError, setSpacesError] = useState<Error>()

  const [sensorsError, setSensorsError] = useState<Error>()
  const [waterSystemsError, setWaterSystemsError] = useState<Error>()
  const [areacamsError, setAreacamsError] = useState<Error>()

  const [gridPredictionsError, setGridPredictionsError] = useState<Error>()
  const [inventoriesError, setInventoriesError] = useState<Error>()
  const [containersError, setContainersError] = useState<Error>()
  const [harvestGroupsError, setHarvestGroupsError] = useState<Error>()

  const [inventoryStitchesError, setInventoryStitchesError] = useState<Error>()
  const [containerStitchesError, setContainerStitchesError] = useState<Error>()

  const [heatmapsError, setHeatmapsError] = useState<Error>()
  const [budDetectionsError, setBudDetectionsError] = useState<Error>()
  const [canopyCoveragesError, setCanopyCoveragesError] = useState<Error>()
  const [canopyHeightsError, setCanopyHeightsError] = useState<Error>()
  const [flowerCoveragesError, setFlowerCoveragesError] = useState<Error>()
  const [germinationRatesError, setGerminationRatesError] = useState<Error>()
  const [topCanopyCoveragesError, setTopCanopyCoveragesError] = useState<Error>()
  const [topGerminationRatesError, setTopGerminationRatesError] = useState<Error>()
  const [potCountsError, setPotCountsError] = useState<Error>()
  const [readinessCoveragesError, setReadinessCoveragesError] = useState<Error>()
  const [yieldPredictionAveragesError, setYieldPredictionAveragesError] = useState<Error>()
  const [boardCountError, setBoardCountError] = useState<[]>()
  const [totalWeightError, setTotalWeightError] = useState<[]>()

  const [spaceChartsError, setSpaceChartsError] = useState<Error>()
  const [inventoryChartsError, setInventoryChartsError] = useState<Error>()
  const [containerChartsError, setContainerChartsError] = useState<Error>()

  const [tasksError, setTasksError] = useState<Error>()
  const [taskIssuesError, setTaskIssuesError] = useState<Error>()
  // Data ////

  const [facility, setFacility] = useState<IFacility>()
  const [buildings, setBuildings] = useState<IBuilding[]>()
  const [spaces, setSpaces] = useState<ISpace[]>()

  const [sensors, setSensors] = useState<ISensorRecord[]>()
  const [waterSystems, setWaterSystems] = useState<IGreenAutomationWaterSystemRecord[]>()
  const [areacams, setAreacams] = useState<IAreacam[]>()

  const [gridPredictions, setGridPredictions] = useState<Array<IGridPrediction>>()
  const [inventories, setInventories] = useState<Array<IInventory>>()
  const [inventoriesWOC, setInventoriesWOC] = useState<Array<IInventory>>()
  const [inventoriesWC, setInventoriesWC] = useState<Array<IInventory>>()
  const [containers, setContainers] = useState<Array<IContainer>>()
  const [harvestGroups, setHarvestGroups] = useState<Array<IHarvestGroup>>()

  const [inventoryStitches, setInventoryStitches] = useState<Array<IInventoryStitch>>()
  const [containerStitches, setContainerStitches] = useState<Array<IContainerStich>>()

  const [heatmaps, setHeatmaps] = useState<Array<IHeatmap>>();
  const [budDetections, setBudDetections] = useState<Array<IBudDetection>>();
  const [canopyCoverages, setCanopyCoverages] = useState<Array<ICanopyCoverage>>()
  const [canopyHeights, setCanopyHeights] = useState<Array<ICanopyHeight>>()
  const [flowerCoverages, setFlowerCoverages] = useState<Array<IFlowerCoverage>>()
  const [germinationRates, setGerminationRates] = useState<Array<IGerminationRate>>()
  const [topCanopyCoverages, setTopCanopyCoverages] = useState<Array<ITopCanopyCoverage>>()
  const [topGerminationRates, setTopGerminationRates] = useState<Array<ITopGerminationRate>>()
  const [potCounts, setPotCounts] = useState<boolean>()
  const [readinessCoverages, setReadinessCoverages] = useState<boolean>()
  const [yieldPredictionAverages, setYieldPredictionAverages] = useState<Array<IYieldPredictionAverage>>()
  const [boardCount, setBoardCount] = useState<number>()
  const [totalWeight, setTotalWeight] = useState<number>()

  const [spaceCharts, setSpaceCharts] = useState<Array<IChart>>()
  const [inventoryCharts, setInventoryCharts] = useState<Array<IChart>>()
  const [containerCharts, setContainerCharts] = useState<Array<IChart>>()

  const [tasks, setTasks] = useState<Array<ITask>>()
  const [taskIssues, setTaskIssues] = useState<Array<ITaskIssue>>()

  // Diffs ////

  const [selectedEntitiesDiff, setSelectedEntitiesDiff] = useState<Array<IDiff<IEntity>>>()
  const [visibleEntitiesDiff, setVisibleEntitiesDiff] = useState<Array<IDiff<IEntity>>>()
  const [overlaysDiff, setOverlaysDiff] = useState<Array<IDiff<EOverlay>>>()

  // Times ////

  const [sensorsTime, setSensorsTime] = useState<Date>()
  const [areacamsTime, setAreacamsTime] = useState<Date>()

  const [gridPredictionsTime, setGridPredictionsTime] = useState<Date>()

  const [inventoryStitchesTime, setInventoryStitchesTime] = useState<Date>()
  const [containerStitchesTime, setContainerStitchesTime] = useState<Date>()

  const [heatmapsTime, setHeatmapsTime] = useState<Date>()
  const [budDetectionsTime, setBudDetectionsTime] = useState<Date>()
  const [canopyCoveragesTime, setCanopyCoveragesTime] = useState<Date>()
  const [canopyHeightsTime, setCanopyHeightsTime] = useState<Date>()
  const [flowerCoveragesTime, setFlowerCoveragesTime] = useState<Date>()
  const [germinationRatesTime, setGerminationRatesTime] = useState<Date>()
  const [topCanopyCoveragesTime, setTopCanopyCoveragesTime] = useState<Date>()
  const [topGerminationRatesTime, setTopGerminationRatesTime] = useState<Date>()
  const [potCountsTime, setPotCountsTime] = useState<Date>()
  const [readinessCoveragesTime, setReadinessCoveragesTime] = useState<Date>()
  const [yieldPredictionAveragesTime, setYieldPredictionAveragesTime] = useState<Date>()

  const [tasksTime, setTasksTime] = useState<Date>()
  const [taskIssuesTime, setTaskIssuesTime] = useState<Date>()
  // UUIDs ////

  const [selectedEntitiesUUID, setSelectedEntitiesUUID] = useState<string>()
  const [visibleEntitiesUUID, setVisibleEntitiesUUID] = useState<string>()
  const [overlaysUUID, setOverlaysUUID] = useState<string>()
  const [zoomUUID, setZoomUUID] = useState<string>()

  const [facilityUUID, setFacilityUUID] = useState<string>()
  const [buildingsUUID, setBuildingsUUID] = useState<string>()
  const [spacesUUID, setSpacesUUID] = useState<string>()

  const [sensorsUUID, setSensorsUUID] = useState<string>()
  const [waterSystemsUUID, setWaterSystemsUUID] = useState<string>()
  const [areacamsUUID, setAreacamsUUID] = useState<string>()

  const [gridPredictionsUUID, setGridPredictionsUUID] = useState<string>()
  const [inventoriesUUID, setInventoriesUUID] = useState<string>()
  const [containersUUID, setContainersUUID] = useState<string>()
  const [harvestGroupsUUID, setHarvestGroupsUUID] = useState<string>()

  const [inventoryStitchesUUID, setInventoryStitchesUUID] = useState<string>()
  const [containerStitchesUUID, setContainerStitchesUUID] = useState<string>()

  const [heatmapsUUID, setHeatmapsUUID] = useState<string>()
  const [budDetectionsUUID, setBudDetectionsUUID] = useState<string>()
  const [canopyCoveragesUUID, setCanopyCoveragesUUID] = useState<string>()
  const [canopyHeightsUUID, setCanopyHeightsUUID] = useState<string>()
  const [flowerCoveragesUUID, setFlowerCoveragesUUID] = useState<string>()
  const [germinationRatesUUID, setGerminationRatesUUID] = useState<string>()
  const [topCanopyCoveragesUUID, setTopCanopyCoveragesUUID] = useState<string>()
  const [topGerminationRatesUUID, setTopGerminationRatesUUID] = useState<string>()
  const [potCountsUUID, setPotCountsUUID] = useState<string>()
  const [readinessCoveragesUUID, setReadinessCoveragesUUID] = useState<string>()
  const [yieldPredictionAveragesUUID, setYieldPredictionAveragesUUID] = useState<string>()

  const [spaceChartsUUID, setSpaceChartsUUID] = useState<string>()
  const [inventoryChartsUUID, setInventoryChartsUUID] = useState<string>()
  const [containerChartsUUID, setContainerChartsUUID] = useState<string>()

  const [tasksUUID, setTasksUUID] = useState<string>()
  const [taskIssuesUUID, setTaskIssuesUUID] = useState<string>()

  // GraphQL ///////////////////////////////////////////////////////////////////

  // Query...
  // - Facility
  // - Buildings
  // - Spaces
  const staticData = useQuery(QueryStaticData, {
    skip: !id,
    variables: { id },
    fetchPolicy: 'no-cache'
  })

  // Sensors ////

  // Query Sensors
  const sensorsData = useQuery(QuerySensors, {
    skip: !sensorIDs || sensorIDs.length === 0,
    variables: { sensorIds: sensorIDs },
    fetchPolicy: 'no-cache'
  })

  // Query Sensor Records
  const sensorRecordsData = useQuery(QuerySensorRecords, {
    skip: !id || !time,
    variables: {
      startDate: time?.twoWeekLookback,
      endDate: time?.now,
      getMostRecentPerDevice: !loadSensorHistory
    },
    fetchPolicy: 'no-cache'
  })

  // Query CO2 Sensor Records
  const co2SensorRecordsData = useQuery(QueryCO2SensorRecords, {
    skip: !id || !time,
    variables: {
      startDate: time?.twoWeekLookback,
      endDate: time?.now,
      getMostRecentPerDevice: true
    },
    fetchPolicy: 'no-cache'
  })

  // Query Water System Records (TODO: support more than Green Automation)
  const waterSystemRecordsData = useQuery(QueryGreenAutomationWaterSystemRecords, {
    skip: !id || !time,
    variables: {
      facilityIds: [facility?.id],
      startDate: time?.twoWeekLookback,
      endDate: time?.now,
      getMostRecentPerSystem: true
    },
    fetchPolicy: 'no-cache'
  })

  // Areacams ////

  // Skip Areacams query if no areacam IDs
  const skipAreaCams = !areacamIDs || areacamIDs.length === 0;

  // Query Areacams
  const areacamsData = useQuery(QueryAreacams, {
    skip: skipAreaCams,
    variables: { areacamIds: areacamIDs },
    fetchPolicy: 'no-cache'
  })

  // Query Area Image Records
  const areaImageRecordsData = useQuery(QueryAreaImageRecords, {
    skip: !id || !time,
    variables: {
      facilityIds: [id],
      startDate: time?.dayLookback,
      endDate: time?.now
    },
    fetchPolicy: 'no-cache'
  })

  // Inventories & Containers ////

  // Query Grid Predictions
  const gridPredictionsData = useQuery(QueryGridPredictions, {
    skip: !spaceIDs || spaceIDs.length === 0 || !time,
    variables: {
      spaceIds: spaceIDs,
      startDate: time?.tenDayLookback,
      endDate: time?.now,
      onlyInventories,
      onlyContainers
    },
    fetchPolicy: 'no-cache'
  })

  // Query Inventories
  const inventoriesData = useQuery(QueryInventories, {
    skip: !inventoryIDs || inventoryIDs.length === 0,
    variables: { inventoryIds: inventoryIDs },
    fetchPolicy: 'no-cache'
  })

  // Query Containers
  const containersData = useQuery(QueryContainers, {
    skip: !containerIDs || containerIDs.length === 0,
    variables: { containerIds: containerIDs },
    fetchPolicy: 'no-cache'
  })

  /**
   * Filtering inventoryWCIDs to only those that
   * have a CV container stich as well.  This fixes a bug where
   * harvest weight was showing on crops that are
   * "never seen"
   *
   */
  const inventoriesWithContainerStitch = useMemo(() => {
    if (
      inventoryWCIDs &&
      inventoryWCIDs.length &&
      containerStitches &&
      containerStitches.length
    ) {
      // Creating a set of inventory ids based on the
      // container stitches
      const invIdSet = containerStitches.reduce(
        (retVal: Set<ID>, { inventoryId }) => {
          if (inventoryId) {
            retVal.add(inventoryId)
          }
          return retVal
        },
        new Set<ID>()
      )
      // Returning the inventory ids that also have a CV stich
      return inventoryWCIDs.filter((id) => invIdSet.has(id))
    } else {
      return []
    }
  }, [harvestGroups, containerStitches])

  // Query HarvestGroups
  const harvestGroupData = useQuery(QueryHarvestGroups, {
    // Other inventory ids may be needed with dif customers
    // skip: inventoriesWithContainerStitch.length === 0,
    skip: true,
    variables: { inventoryIds: inventoriesWithContainerStitch },
    fetchPolicy: 'no-cache'
  })

  const skipCV =
    !inventoryWOCIDs ||
    !containerIDs ||
    !isEqual(time?.now, gridPredictionsTime)

  // Query Inventory Stitches
  const inventoryStitchesData = useQuery(QueryInventoryStitches, {
    skip: skipCV,
    variables: {
      inventoryIds: inventoryWOCIDs,
      startDate: time?.tenDayLookback,
      endDate: time?.now
    },
    fetchPolicy: 'no-cache'
  })

  // Query Container Stitches
  const containerStitchesData = useQuery(QueryContainerStitches, {
    skip: skipCV,
    variables: {
      containerIds: containerIDs,
      startDate: time?.tenDayLookback,
      endDate: time?.now
    },
    fetchPolicy: 'no-cache'
  })

  // Query CV
  const cvData = useQuery(QueryCV, {
    skip: skipCV,
    variables: {
      inventoryIds: inventoryWOCIDs,
      containerIds: containerIDs,
      allInventoryIds: [...(inventoryWOCIDs || []), ...(inventoryWCIDs || [])],
      startDate: time?.twoWeekLookback,
      endDate: time?.now
    },
    fetchPolicy: 'no-cache'
  })

  // Tasks ////

  // Query Tasks
  const tasksData = useQuery(QueryTasks, {
    skip: skipCV,
    variables: {
      inventoryIds: inventoryWOCIDs,
      containerIds: containerIDs,
      startDate: time?.twoWeekLookback,
      endDate: time?.dayLookforward,
      includeRejectedIssues: true
    },
    fetchPolicy: 'no-cache'
  })

  // Query TaskIssues
  const taskIssuesData = useQuery(QueryTaskIssues, {
    skip: skipCV,
    variables: {
      inventoryIds: inventoryWOCIDs,
      containerIds: containerIDs,
      startDate: time?.twoWeekLookback,
      endDate: time?.dayLookforward,
      includeRejectedIssues: true
    },
    fetchPolicy: 'no-cache'
  })

  // Effects ///////////////////////////////////////////////////////////////////

  useEffect(() => {
    if (!loadSensorHistory) {
      // only run if currently false, because once true there is no
      // reason to dump the whole history just to load the current values
      const chartsSelected = searchParams.selectedEntities
        ? JSON.parse(searchParams.selectedEntities)
        : []

      setLoadSensorHistory(
        chartsSelected.some(({ id = '__none' }) => SENSOR_CHARTS.has(id))
      )
    }
  }, [searchParams])

  useEffect(
    (): void => {
      if (facility) {
        setTitle(facility.name)
      }
    },
    [facilityUUID]
  )

  useIter(
    UpdateTime,

    {
      searchParams,
      time,
      facility,

      setSearchParams,
      setTime
    },

    [searchParamsUUID,
      facilityUUID]
  )

  useIter(
    UpdateSelectedEntities,

    {
      searchParams,
      selectedEntities,

      setSearchParams,
      setSelectedEntities,
      setSelectedEntitiesDiff,
      setSelectedEntitiesUUID
    },

    [searchParamsUUID]
  )

  useIter(
    UpdateOverlays,

    {
      searchParams,
      overlays,

      setSearchParams,
      setOverlays,
      setOverlaysDiff,
      setOverlaysUUID
    },

    [searchParamsUUID]
  )

  useIter(
    UpdateStaticData,

    {
      facilityId: id,
      loading: staticData.loading,
      error: staticData.error,
      data: staticData.data,

      setFacilityID,
      setBuildingIDs,
      setSpaceIDs,

      setFacilityLoading,
      setBuildingsLoading,
      setSpacesLoading,

      setFacilityError,
      setBuildingsError,
      setSpacesError,

      setFacility,
      setBuildings,
      setSpaces,

      setFacilityUUID,
      setBuildingsUUID,
      setSpacesUUID
    },

    [staticData.loading,
      staticData.error,
      staticData.data]
  )

  // Sensors ////

  useIter(
    UpdateSensorIDs,

    {
      time,
      sensorRecordsData,
      co2SensorRecordsData,

      setSensorIDs,
      setSensorsLoading,
      setSensorsError,
      setSensorsTime,
      setSensorsUUID
    },

    [sensorRecordsData.loading,
      sensorRecordsData.error,
      sensorRecordsData.data,

      co2SensorRecordsData.loading,
      co2SensorRecordsData.error,
      co2SensorRecordsData.data]
  )

  useIter(
    UpdateSensors,

    {
      sensorsData,
      sensorRecordsData,
      co2SensorRecordsData,

      setSensorsLoading,
      setSensorsError,
      setSensors,
      setSensorsUUID
    },

    [sensorsData.loading,
      sensorsData.error,
      sensorsData.data,
      sensorRecordsData.loading,
      sensorRecordsData.error,
      sensorRecordsData.data,
      co2SensorRecordsData.loading,
      co2SensorRecordsData.error,
      co2SensorRecordsData.data]
  )

  useIter(
    UpdateWaterSystems,

    {
      waterSystemRecordsData,
      setWaterSystemsLoading,
      setWaterSystemsError,
      setWaterSystems,
      setWaterSystemsUUID
    },

    [waterSystemRecordsData.loading,
      waterSystemRecordsData.error,
      waterSystemRecordsData.data
    ]
  )

  // Areacams ////

  useIter(
    UpdateAreacamIDs,

    {
      time,
      loading: areaImageRecordsData.loading,
      error: areaImageRecordsData.error,
      data: areaImageRecordsData.data,

      setAreacamIDs,
      setAreacamsLoading,
      setAreacamsError,
      setAreacamsTime,
      setAreacamsUUID
    },

    [areaImageRecordsData.loading,
      areaImageRecordsData.error,
      areaImageRecordsData.data]
  )

  useIter(
    UpdateAreacams,

    {
      areacamsData,
      areaImageRecordsData,
      skipAreaCams,

      setAreacamsLoading,
      setAreacamsError,
      setAreacams,
      setAreacamsUUID
    },

    [areacamsData.loading,
      areacamsData.error,
      areacamsData.data,
      // This is necessary because the areacamsData query would never execute
      // if the areaImageRecordsData query returns an empty result set.
      // By reacting to the below loading state, we can handle a case where
      // areaImageRecordsData has finished, but the effect won't run.
      areaImageRecordsData.loading]
  )

  // Inventories & Containers ////

  useIter(
    UpdateGridPredictions,

    {
      time,
      loading: gridPredictionsData.loading,
      error: gridPredictionsData.error,
      data: gridPredictionsData.data,

      setInventoryIDs,
      setInventoryWOCIDs,
      setInventoryWCIDs,
      setContainerIDs,
      setGridPredictionsLoading,
      setGridPredictionsError,
      setGridPredictions,
      setGridPredictionsTime,
      setGridPredictionsUUID
    },

    [gridPredictionsData.loading,
      gridPredictionsData.error,
      gridPredictionsData.data]
  )

  useIter(
    UpdateInventories,

    {
      inventoryWOCIDs,
      inventoryWCIDs,
      loading: inventoriesData.loading,
      error: inventoriesData.error,
      data: inventoriesData.data,

      setInventoriesLoading,
      setInventoriesError,
      setInventories,
      setInventoriesWOC,
      setInventoriesWC,
      setInventoriesUUID
    },

    [inventoriesData.loading,
      inventoriesData.error,
      inventoriesData.data]
  )

  useIter(
    UpdateContainers,

    {
      loading: containersData.loading,
      error: containersData.error,
      data: containersData.data,

      setContainersLoading,
      setContainersError,
      setContainers,
      setContainersUUID
    },

    [containersData.loading,
      containersData.error,
      containersData.data]
  )

  useIter(
    UpdateHarvestGroups,

    {
      loading: harvestGroupData.loading,
      error: harvestGroupData.error,
      data: harvestGroupData.data,

      setHarvestGroupsLoading,
      setHarvestGroupsError,
      setHarvestGroups,
      setHarvestGroupsUUID
    },

    [harvestGroupData.loading,
      harvestGroupData.error,
      harvestGroupData.data]
  )

  useIter(
    UpdateInventoryStitches,

    {
      time,
      loading: inventoryStitchesData.loading,
      error: inventoryStitchesData.error,
      data: inventoryStitchesData.data,

      setInventoryStitchesLoading,
      setInventoryStitchesError,
      setInventoryStitches,
      setInventoryStitchesTime,
      setInventoryStitchesUUID
    },

    [inventoryStitchesData.loading,
      inventoryStitchesData.error,
      inventoryStitchesData.data]
  )

  useIter(
    UpdateContainerStitches,

    {
      time,
      loading: containerStitchesData.loading,
      error: containerStitchesData.error,
      data: containerStitchesData.data,

      setContainerStitchesLoading,
      setContainerStitchesError,
      setContainerStitches,
      setContainerStitchesTime,
      setContainerStitchesUUID
    },

    [containerStitchesData.loading,
      containerStitchesData.error,
      containerStitchesData.data]
  )

  useIter(
    UpdateCV,

    {
      time,
      loading: cvData.loading,
      error: cvData.error,
      data: cvData.data,

      setHeatmapsLoading,
      setBudDetectionsLoading,
      setCanopyCoveragesLoading,
      setCanopyHeightsLoading,
      setFlowerCoveragesLoading,
      setGerminationRatesLoading,
      setTopCanopyCoveragesLoading,
      setTopGerminationRatesLoading,
      setPotCountsLoading,
      setReadinessCoveragesLoading,
      setYieldPredictionAveragesLoading,
      setBoardCountLoading,
      setTotalWeightLoading,

      setHeatmapsError,
      setBudDetectionsError,
      setCanopyCoveragesError,
      setCanopyHeightsError,
      setFlowerCoveragesError,
      setGerminationRatesError,
      setTopCanopyCoveragesError,
      setTopGerminationRatesError,
      setPotCountsError,
      setReadinessCoveragesError,
      setYieldPredictionAveragesError,
      setBoardCountError,
      setTotalWeightError,

      setHeatmaps,
      setBudDetections,
      setCanopyCoverages,
      setCanopyHeights,
      setFlowerCoverages,
      setGerminationRates,
      setTopCanopyCoverages,
      setTopGerminationRates,
      setPotCounts,
      setReadinessCoverages,
      setYieldPredictionAverages,
      setBoardCount,
      setTotalWeight,

      setHeatmapsTime,
      setBudDetectionsTime,
      setCanopyCoveragesTime,
      setCanopyHeightsTime,
      setFlowerCoveragesTime,
      setGerminationRatesTime,
      setTopCanopyCoveragesTime,
      setTopGerminationRatesTime,
      setPotCountsTime,
      setReadinessCoveragesTime,
      setYieldPredictionAveragesTime,

      setHeatmapsUUID,
      setBudDetectionsUUID,
      setCanopyCoveragesUUID,
      setCanopyHeightsUUID,
      setFlowerCoveragesUUID,
      setGerminationRatesUUID,
      setTopCanopyCoveragesUUID,
      setTopGerminationRatesUUID,
      setPotCountsUUID,
      setReadinessCoveragesUUID,
      setYieldPredictionAveragesUUID
    },

    [cvData.loading,
      cvData.error,
      cvData.data]
  )

  // Charts ////

  useIter(
    UpdateSpaceCharts,

    {
      spacesLoading,
      spacesError,
      spaces,

      sensorsLoading,
      sensorsError,
      sensors,
      waterSystemsLoading,
      waterSystemsError,
      waterSystems,

      setSpaceChartsLoading,
      setSpaceChartsError,
      setSpaceCharts,
      setSpaceChartsUUID
    },

    [spacesUUID,
      sensorsUUID,
      waterSystemsUUID]
  )

  useIter(
    UpdateInventoryCharts,

    {
      inventoryIDs: inventoryWOCIDs,
      inventoriesLoading,
      inventoriesError,
      inventories: inventoriesWOC,

      budDetectionsLoading,
      budDetectionsError,
      budDetections,

      canopyCoveragesLoading,
      canopyCoveragesError,
      canopyCoverages,

      canopyHeightsLoading,
      canopyHeightsError,
      canopyHeights,

      flowerCoveragesLoading,
      flowerCoveragesError,
      flowerCoverages,

      germinationRatesLoading,
      germinationRatesError,
      germinationRates,

      setInventoryChartsLoading,
      setInventoryChartsError,
      setInventoryCharts,
      setInventoryChartsUUID
    },

    [inventoriesUUID,
      budDetectionsUUID,
      canopyCoveragesUUID,
      canopyHeightsUUID,
      flowerCoveragesUUID,
      germinationRatesUUID]
  )

  useIter(
    UpdateContainerCharts,

    {
      inventoriesLoading,
      inventoriesError,
      inventories: inventoriesWC,

      containerIDs,
      containersLoading,
      containersError,
      containers,

      budDetectionsLoading,
      budDetectionsError,
      budDetections,

      canopyCoveragesLoading,
      canopyCoveragesError,
      canopyCoverages,

      canopyHeightsLoading,
      canopyHeightsError,
      canopyHeights,

      flowerCoveragesLoading,
      flowerCoveragesError,
      flowerCoverages,

      germinationRatesLoading,
      germinationRatesError,
      germinationRates,

      yieldPredictionAveragesLoading,
      yieldPredictionAveragesError,
      yieldPredictionAverages,

      topCanopyCoveragesLoading,
      topCanopyCoveragesError,
      topCanopyCoverages,

      topGerminationRatesLoading,
      topGerminationRatesError,
      topGerminationRates,

      potCountsLoading,
      potCountsError,
      potCounts,

      readinessCoveragesLoading,
      readinessCoveragesError,
      readinessCoverages,

      harvestGroupsLoading,
      harvestGroupsError,
      harvestGroups,

      boardCountLoading,
      boardCountError,
      boardCount,

      totalWeightLoading,
      totalWeightError,
      totalWeight,

      setContainerChartsLoading,
      setContainerChartsError,
      setContainerCharts,
      setContainerChartsUUID
    },

    [inventoriesUUID,
      containersUUID,
      budDetectionsUUID,
      canopyCoveragesUUID,
      canopyHeightsUUID,
      flowerCoveragesUUID,
      germinationRatesUUID,
      topCanopyCoveragesUUID,
      topGerminationRatesUUID,
      potCountsUUID,
      readinessCoveragesUUID,
      harvestGroupsUUID]
  )

  // Tasks ////
  useIter(
    UpdateTasks,

    {
      time,
      loading: tasksData.loading,
      error: tasksData.error,
      data: tasksData.data,

      setTasksLoading,
      setTasksError,
      setTasks,
      setTasksTime,
      setTasksUUID
    },

    [tasksData.loading,
      tasksData.error,
      tasksData.data]
  )

  useIter(
    UpdateTaskIssues,

    {
      time,
      loading: taskIssuesData.loading,
      error: taskIssuesData.error,
      data: taskIssuesData.data,

      setTaskIssuesLoading,
      setTaskIssuesError,
      setTaskIssues,
      setTaskIssuesTime,
      setTaskIssuesUUID
    },

    [taskIssuesData.loading,
      taskIssuesData.error,
      taskIssuesData.data]
  )

  // Misc ////

  useIter(
    LogSelectedEntities,

    {
      selectedEntitiesDiff,

      facilityLoading,
      buildingsLoading,
      spacesLoading,

      sensorsLoading,
      areacamsLoading,

      inventoriesLoading,
      inventoryStitchesLoading,
      heatmapsLoading,
      budDetectionsLoading,
      canopyCoveragesLoading,
      canopyHeightsLoading,
      flowerCoveragesLoading,
      germinationRatesLoading,
      boardCountLoading,
      yieldPredictionAveragesLoading,

      facilityError,
      buildingsError,
      spacesError,

      sensorsError,
      areacamsError,

      inventoriesError,
      inventoryStitchesError,
      heatmapsError,
      budDetectionsError,
      canopyCoveragesError,
      canopyHeightsError,
      flowerCoveragesError,
      germinationRatesError,
      boardCountError,
      yieldPredictionAveragesError,

      facility,
      buildings,
      spaces,

      sensors,
      areacams,

      inventories,
      inventoryStitches,
      heatmaps,
      budDetections,
      canopyCoverages,
      canopyHeights,
      flowerCoverages,
      germinationRates,
      boardCount,
      yieldPredictionAverages
    },

    [selectedEntitiesUUID,

      facilityUUID,
      buildingsUUID,
      spacesUUID,

      sensorsUUID,
      areacamsUUID,

      inventoriesUUID,
      inventoryStitchesUUID,
      heatmapsUUID,
      budDetectionsUUID,
      canopyCoveragesUUID,
      canopyHeightsUUID,
      flowerCoveragesUUID,
      germinationRatesUUID,
      yieldPredictionAveragesUUID
    ]
  )

  // Callbacks /////////////////////////////////////////////////////////////////

  const _setSearchParam = useCallback(
    (newKey: string, newVal: any): void => {
      const newSearchParams = Object.assign(
        {},
        searchParams,
        { [newKey]: newVal }
      )

      setSearchParams(newSearchParams)
    },
    [searchParamsUUID]
  )

  const _setTime = useCallback(
    (newVal: Date): void => {
      const newSearchParams = Object.assign(
        {},
        searchParams,
        { time: newVal.toISOString() }
      )

      setSearchParams(newSearchParams)
    },
    [searchParamsUUID]
  )

  const _setSelectedEntities = useCallback(
    (newVal: IEntity[]): void => {
      const newSearchParams = Object.assign(
        {},
        searchParams,
        { selectedEntities: JSON.stringify(newVal) }
      )

      setSearchParams(newSearchParams)
    },
    [searchParamsUUID]
  )

  const _selectEntity = useCallback(
    (newVal: IEntity, multiselect?: boolean): void => {
      const selected = !!selectedEntities
        .find(e =>
          e.parentType === newVal.parentType &&
          String(e.parentID) === String(newVal.parentID) &&
          e.type === newVal.type &&
          String(e.id) === String(newVal.id))

      let newSelectedEntities: IEntity[]
      if (!multiselect) {
        newSelectedEntities = [newVal]
      } else {
        if (!selected) {
          newSelectedEntities = [
            ...selectedEntities,
            newVal
          ]
        } else {
          newSelectedEntities = selectedEntities
            .filter(entity =>
              entity.parentType !== newVal.parentType ||
              String(entity.parentID) !== String(newVal.parentID) ||
              entity.type !== newVal.type ||
              String(entity.id) !== String(newVal.id))
        }
      }

      _setSelectedEntities(newSelectedEntities)
    },

    [selectedEntitiesUUID,
      _setSelectedEntities]
  )

  const _setVisibleEntities = useCallback(
    (newVal: IEntity[]): void => {
      const added = newVal
        .filter(ne => !(visibleEntities || [])
          .find(oe =>
            ne.type === oe.type &&
            String(ne.id) === String(oe.id)))

        .map(e => ({
          type: EDiff.Add,
          value: e
        }))

      const changed = newVal
        .filter(ne => !!(visibleEntities || [])
          .find(oe =>
            ne.type === oe.type &&
            String(ne.id) === String(oe.id) &&
            ne.visibility !== oe.visibility))

        .map(e => ({
          type: EDiff.Change,
          value: e
        }))

      const removed = (visibleEntities || [])
        .filter(oe => !newVal
          .find(ne =>
            oe.type === ne.type &&
            String(oe.id) === String(ne.id)))

        .map(e => ({
          type: EDiff.Remove,
          value: e
        }))

      const newVisibleEntitiesDiff = [
        ...added,
        ...changed,
        ...removed
      ]

      setVisibleEntities(newVal)
      setVisibleEntitiesDiff(newVisibleEntitiesDiff)
      setVisibleEntitiesUUID(v4())
    },
    [visibleEntitiesUUID]
  )

  const _setOverlays = useCallback(
    (newVal: string[]): void => {
      const newSearchParams = Object.assign(
        {},
        searchParams,
        { overlays: JSON.stringify(newVal) }
      )

      setSearchParams(newSearchParams)
    },
    [searchParamsUUID]
  )

  const _selectOverlay = useCallback(
    (newVal: EOverlay): void => {
      const selected = !!overlays
        .find(o => o === newVal)

      let newOverlays
      if (selected) {
        newOverlays = overlays
          .filter(o => o !== newVal)
      } else {
        if (
          newVal === EOverlay.CO2 ||
          newVal === EOverlay.DLI ||
          newVal === EOverlay.Humidity ||
          newVal === EOverlay.MMol ||
          newVal === EOverlay.Temperature ||
          newVal === EOverlay.VPD
        ) {
          // If a Sensor Overlay was selected,
          // remove all Sensor Overlays and add the current one.
          newOverlays = overlays
            .filter(o =>
              o !== EOverlay.CO2 &&
              o !== EOverlay.DLI &&
              o !== EOverlay.Humidity &&
              o !== EOverlay.MMol &&
              o !== EOverlay.Temperature &&
              o !== EOverlay.VPD)

          newOverlays.push(newVal)
        } else if (newVal === EOverlay.Task || newVal === EOverlay.TaskIssue) {
          // If an Inventory Overlay was selected,
          // remove all Inventory Overlays and add the current one.

          newOverlays = overlays
            .filter(o => o !== newVal)
          newOverlays.push(newVal)
        } else {
          newOverlays = [
            ...overlays,
            newVal
          ]
        }
      }

      _setOverlays(newOverlays)
    },

    [overlaysUUID,
      _setOverlays]
  )

  const _setZoom = useCallback(
    (newVal: any): void => {
      setZoom(newVal)
      setZoomUUID(v4())
    },
    [zoomUUID]
  )

  const _setTasks = useCallback(
    (newVal): void => {
      setTasks(newVal)
      setTasksUUID(v4())
    },

    [tasksUUID]
  )

  const _setTaskIssues = useCallback(
    (newVal): void => {
      setTaskIssues(newVal)
      setTaskIssuesUUID(v4())
    },

    [taskIssuesUUID]
  )

  // Context ///////////////////////////////////////////////////////////////////

  const context = {
    searchParams: {
      searchParams,
      setSearchParams,
      setSearchParam: _setSearchParam,
      searchParamsUUID
    },

    time: {
      time,
      setTime: _setTime
    },

    selectedEntities: {
      selectedEntities,
      selectedEntitiesDiff,
      setSelectedEntities: _setSelectedEntities,
      selectEntity: _selectEntity,
      selectedEntitiesUUID
    },

    visibleEntities: {
      visibleEntities,
      visibleEntitiesDiff,
      setVisibleEntities: _setVisibleEntities,
      visibleEntitiesUUID
    },

    overlays: {
      overlays,
      overlaysDiff,
      setOverlays: _setOverlays,
      selectOverlay: _selectOverlay,
      overlaysUUID
    },

    zoom: {
      zoom,
      setZoom: _setZoom,
      zoomUUID
    },

    facility: {
      facilityID,
      facilityLoading,
      facilityError,
      facility,
      facilityUUID
    },

    buildings: {
      buildingIDs,
      buildingsLoading,
      buildingsError,
      buildings,
      buildingsUUID
    },

    spaces: {
      spaceIDs,
      spacesLoading,
      spacesError,
      spaces,
      spacesUUID
    },

    sensors: {
      sensorIDs,
      sensorsLoading,
      sensorsError,
      sensors,
      sensorsTime,
      sensorsUUID
    },

    waterSystems: {
      waterSystemsLoading,
      waterSystemsError,
      waterSystems,
      waterSystemsUUID
    },

    areacams: {
      areacamIDs,
      areacamsLoading,
      areacamsError,
      areacams,
      areacamsTime,
      areacamsUUID
    },

    gridPredictions: {
      gridPredictionsLoading,
      gridPredictionsError,
      gridPredictions,
      gridPredictionsTime,
      gridPredictionsUUID
    },

    inventories: {
      inventoryIDs,
      inventoryWOCIDs,
      inventoryWCIDs,
      inventoriesLoading,
      inventoriesError,
      inventories,
      inventoriesWOC,
      inventoriesWC,
      inventoriesUUID,
      inventoriesData
    },

    containers: {
      containerIDs,
      containersLoading,
      containersError,
      containers,
      containersUUID
    },

    inventoryStitches: {
      inventoryStitchesLoading,
      inventoryStitchesError,
      inventoryStitches,
      inventoryStitchesTime,
      inventoryStitchesUUID
    },

    containerStitches: {
      containerStitchesLoading,
      containerStitchesError,
      containerStitches,
      containerStitchesTime,
      containerStitchesUUID
    },

    heatmaps: {
      heatmapsLoading,
      heatmapsError,
      heatmaps,
      heatmapsTime,
      heatmapsUUID
    },

    budDetections: {
      budDetectionsLoading,
      budDetectionsError,
      budDetections,
      budDetectionsTime,
      budDetectionsUUID
    },

    canopyCoverages: {
      canopyCoveragesLoading,
      canopyCoveragesError,
      canopyCoverages,
      canopyCoveragesTime,
      canopyCoveragesUUID
    },

    canopyHeights: {
      canopyHeightsLoading,
      canopyHeightsError,
      canopyHeights,
      canopyHeightsTime,
      canopyHeightsUUID
    },

    flowerCoverages: {
      flowerCoveragesLoading,
      flowerCoveragesError,
      flowerCoverages,
      flowerCoveragesTime,
      flowerCoveragesUUID
    },

    germinationRates: {
      germinationRatesLoading,
      germinationRatesError,
      germinationRates,
      germinationRatesTime,
      germinationRatesUUID
    },

    yieldPredictionAverages: {
      yieldPredictionAveragesLoading,
      yieldPredictionAveragesError,
      yieldPredictionAverages,
      yieldPredictionAveragesTime,
      yieldPredictionAveragesUUID
    },

    spaceCharts: {
      spaceChartsLoading,
      spaceChartsError,
      spaceCharts,
      setSpaceCharts,
      spaceChartsUUID
    },

    inventoryCharts: {
      inventoryChartsLoading,
      inventoryChartsError,
      inventoryCharts,
      inventoryChartsUUID
    },

    containerCharts: {
      containerChartsLoading,
      containerChartsError,
      containerCharts,
      containerChartsUUID
    },

    tasks: {
      tasksLoading,
      tasksError,
      tasks,
      tasksTime,
      tasksUUID,
      setTasks: _setTasks
    },

    taskIssues: {
      taskIssuesLoading,
      taskIssuesError,
      taskIssues,
      taskIssuesTime,
      taskIssuesUUID,
      setTaskIssues: _setTaskIssues
    }
  }

  // Debug /////////////////////////////////////////////////////////////////////

  debug('Render')

  // Render ////////////////////////////////////////////////////////////////////

  return (
    <MobiusContext.Provider value={context}>
      {children}
    </MobiusContext.Provider>
  )
}

export default Mobius

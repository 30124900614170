// React
import React, {
  forwardRef,
  MouseEventHandler,
  MutableRefObject
} from 'react'

// Font Awesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCalendar } from '@fortawesome/pro-regular-svg-icons'

const TimeTravelInput = (
  props: {
    onClick: MouseEventHandler<HTMLDivElement>,
    ref: MutableRefObject<any>
  }
): JSX.Element => {
  const { onClick, ref } = props;
  return (
    <div
      ref={ref}
      className='TimeTravelInput'
      onClick={onClick}
    >
      <FontAwesomeIcon icon={faCalendar} />
    </div>
  )
}

export default forwardRef(TimeTravelInput)

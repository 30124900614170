/**
 * @author Miras Absar <mabsar@iunu.com>
 */

import m from 'moment'

import IColumn from 'arrayview/interfaces/IColumn'
import IGroup from 'arrayview/interfaces/IGroup'

import ICustomerUser from '../interfaces/ICustomerUser'

const SafeIncludes = (a: string, b: string): boolean => {
  a = a || ''
  b = b || ''

  return a.toLowerCase().includes(b.toLowerCase())
}

const SafeCompare = (a: string, b: string): number => {
  a = a || ''
  b = b || ''

  return a.localeCompare(b)
}

const NameColumn: IColumn<ICustomerUser> = Object.freeze({
  id: 'customer-user::name',
  name: 'Name',
  searchFunc: (_: IColumn<ICustomerUser>, search: string, data: ICustomerUser): boolean =>
    SafeIncludes(data.name, search),

  ascendingSortFunc: (_: IColumn<ICustomerUser>, first: ICustomerUser, second: ICustomerUser): number =>
    SafeCompare(first.name, second.name),

  descendingSortFunc: (_: IColumn<ICustomerUser>, first: ICustomerUser, second: ICustomerUser): number =>
    SafeCompare(second.name, first.name),

  groupFunc: (_: IColumn<ICustomerUser>, data: ICustomerUser): IGroup => ({
    id: `${_.id}::${data.name}`,
    name: data.name,
    data: []
  })
})

const EmailColumn: IColumn<ICustomerUser> = Object.freeze({
  id: 'customer-user::email',
  email: 'Email',
  searchFunc: (_: IColumn<ICustomerUser>, search: string, data: ICustomerUser): boolean =>
    SafeIncludes(data.email, search),

  ascendingSortFunc: (_: IColumn<ICustomerUser>, first: ICustomerUser, second: ICustomerUser): number =>
    SafeCompare(first.email, second.email),

  descendingSortFunc: (_: IColumn<ICustomerUser>, first: ICustomerUser, second: ICustomerUser): number =>
    SafeCompare(second.email, first.email),

  groupFunc: (_: IColumn<ICustomerUser>, data: ICustomerUser): IGroup => ({
    id: `${_.id}::${data.email}`,
    name: data.email,
    data: []
  })
})

const UsernameColumn: IColumn<ICustomerUser> = Object.freeze({
  id: 'customer-user::username',
  username: 'Username',
  searchFunc: (_: IColumn<ICustomerUser>, search: string, data: ICustomerUser): boolean =>
    SafeIncludes(data.username, search),

  ascendingSortFunc: (_: IColumn<ICustomerUser>, first: ICustomerUser, second: ICustomerUser): number =>
    SafeCompare(first.username, second.username),

  descendingSortFunc: (_: IColumn<ICustomerUser>, first: ICustomerUser, second: ICustomerUser): number =>
    SafeCompare(second.username, first.username),

  groupFunc: (_: IColumn<ICustomerUser>, data: ICustomerUser): IGroup => ({
    id: `${_.id}::${data.username}`,
    name: data.username,
    data: []
  })
})

export {
  NameColumn,
  EmailColumn,
  UsernameColumn
}

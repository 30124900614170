/**
 * @author Miras Absar <mabsar@iunu.com>
 */

// Styles
import 'react-toastify/dist/ReactToastify.css'
import './styles.scss'
import './authorization.scss'
import './crops.scss'
import './dfs.scss'
import './graphql_lib.scss'
import './map_view.scss'
import './map_view_infotip.scss'
import './grid_view.scss'
import './static_camera.scss'
import './tasks.scss'
import './ui_lib.scss'

// Utils
import { EStorage } from 'utils/storage';
import config from '../config';

// React
import React, { useEffect, useState } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';

// React Libs
import { toast } from 'react-toastify'
import useDevice from 'react-lib/use-device'
import { useIter } from 'react-lib/use-iter'
import useSearchParams from 'react-lib/use-search-params'
import useStorage from 'react-lib/use-storage'

// GraphQL
import { ApolloProvider } from '@apollo/client'
import client from 'graphql-lib/index'

// Effects
import { UpdateClass } from './effects'

// Contexts
import NavigationContext from 'context/navigation'
import NotificationContext from 'context/notification'

// Authorization
import SignIn, { SignInRoutes } from 'authorization/components/sign-in'
import ForgotPassword, { ForgotPasswordRoutes } from 'authorization/components/forgot-password'
import ResetPassword, { ResetPasswordRoutes } from 'authorization/components/reset-password'
import SelectCustomer, { SelectCustomerRoutes } from 'authorization/components/select-customer'

// Navigation
import TopNavigation from 'ui-lib/components/top-navigation'
import BottomNavigation from 'ui-lib/components/bottom-navigation'

// App
import MapView, { MapViewRoutes } from 'map-view/components/map-view'
import ReportView from '../report-view'
import Facility, { FacilityRoute } from 'grid-view/components/facility'
import Space, { SpaceRoutes } from 'grid-view/components/space'
import ImportCrops, { ImportCropsRoutes } from 'crops/components/import-crops'
import CropsComparison, { CropsComparisonRoutes } from 'crops/components/crops-comparison'
import CropsView, { CropsViewRoutes } from 'crops/components/crops-view'
import TaskAndIssues, { TasksAndIssuesViewRoutes } from 'tasks/components/tasks-and-issues-view'
import StaticCameraList, { Routes as StaticCameraRoutes } from 'static-camera/components/static-camera-list'
import IRoute from 'ui-lib/interfaces/IRoute'
import IAction from 'arrayview/interfaces/IAction'
import INotification from 'graphql-lib/interfaces/INotification'

toast.configure()

const App = (): JSX.Element => {
  // State /////////////////////////////////////////////////////////////////////

  const {
    mobile,
    tablet,
    desktop,

    width,
    height,

    uuid: deviceUUID,
  } = useDevice()

  const [searchParams] = useSearchParams()

  const [refreshToken] = useStorage('refreshToken')
  const [accessToken] = useStorage('accessToken', EStorage.EphemeralStorage)
  const [user] = useStorage('user', EStorage.EphemeralStorage)
  const [customer] = useStorage('customer', EStorage.EphemeralStorage)
  const [facilities] = useStorage('facilities', EStorage.EphemeralStorage)

  const [primaryAction, setPrimaryAction] = useState<IAction<IRoute>>()
  const [secondaryActions, setSecondaryActions] = useState<Array<IAction<IRoute>>>()
  const [title, setTitle] = useState<string>()
  const [routes, setRoutes] = useState<Array<IRoute>>()

  const [notifications, setNotifications] = useState<Array<INotification>>()

  const navCtxVal = {
    primaryAction,
    secondaryActions,

    title,
    routes,

    setPrimaryAction,
    setSecondaryActions,
    setTitle,
    setRoutes,
  }

  const notifCtxVal = {
    notifications,
    setNotifications,
  }

  // Effects ///////////////////////////////////////////////////////////////////

  useIter(
    UpdateClass,

    {
      target: document.body,

      mobile,
      tablet,
      desktop,

      width,
      height,
    },

    [deviceUUID]
  )

  // Render ////////////////////////////////////////////////////////////////////

  const authorized =
    ((searchParams?.accessToken && accessToken) || (refreshToken && accessToken)) &&
    user &&
    customer &&
    facilities?.length > 0
  let redirectTo = ''
  if (authorized) {
    if (customer.id != 20) {
      redirectTo += `/grid/facility/${facilities[0].id}?cid=${customer.id}&fid=${facilities[0].id}`
    } else {
      redirectTo += `/static-camera?cid=${customer.id}&fid=${facilities[0].id}`
    }
  }

  if (searchParams?.accessToken && accessToken) {
    redirectTo += `&accessToken=${accessToken}`
  }

  useEffect(() => {
    const { zenDeskId } = config;
    if (!zenDeskId) {
      return;
    }

    const loadingZenDesk = async () => {
      const onScriptLoaded = () => {
        if (global.zE) {
          global.zESettings = {
            webWidget: {
              chat: { suppress: false }
            }
          };

          global.zE(() => global.zE('webWidget', 'hide'));
        }
      }

      const loadingZenDesk = document.createElement('script')

      loadingZenDesk.setAttribute(
        'src',
        `https://static.zdassets.com/ekr/snippet.js?key=${zenDeskId}`
      )
      loadingZenDesk.setAttribute('id', 'ze-snippet')
      loadingZenDesk.addEventListener('load', onScriptLoaded);
      document.head.appendChild(loadingZenDesk)
    }

    loadingZenDesk()
  }, [])

  if (authorized) {
    return (
      <NotificationContext.Provider value={notifCtxVal}>
        <NavigationContext.Provider value={navCtxVal}>
          <ApolloProvider client={client}>
            <TopNavigation
              animated
              primaryAction={primaryAction}
              secondaryActions={secondaryActions}
              title={title}
              routes={routes}
              notifications={notifications}
            />
            <Switch>
              <Route path={SelectCustomerRoutes} component={SelectCustomer} exact />
              <Route path={MapViewRoutes} component={MapView} exact />
              <Route path={'/report'} component={ReportView} exact />
              <Route path={FacilityRoute} component={Facility} exact />
              <Route path={SpaceRoutes} component={Space} exact />
              <Route path={ImportCropsRoutes} component={ImportCrops} exact />
              <Route path={CropsComparisonRoutes} component={CropsComparison} exact />
              <Route path={CropsViewRoutes} component={CropsView} exact />
              <Route path={TasksAndIssuesViewRoutes} component={TaskAndIssues} exact />
              <Route path={StaticCameraRoutes} component={StaticCameraList} exact />
              <Redirect to={redirectTo} />
            </Switch>
            <BottomNavigation animated routes={routes} notifications={notifications} />
          </ApolloProvider>
        </NavigationContext.Provider>
      </NotificationContext.Provider>
    )
  }

  return (
    <ApolloProvider client={client}>
      <Switch>
        <Route path={ForgotPasswordRoutes} component={ForgotPassword} exact />
        <Route path={ResetPasswordRoutes} component={ResetPassword} exact />
        <Route path={SignInRoutes} component={SignIn} />
      </Switch>
    </ApolloProvider>
  )
}

export default App

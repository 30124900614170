export default class B64DataUrlUtils {
  public static COMPONENT_REGEXP = /^data:(\w+\/\w+);base64,([A-Za-z0-9+/=]+)$/;

  public static getType(dataUrl: string): Promise<string> {
    return new Promise((resolve): void => {
      const type = dataUrl.match(B64DataUrlUtils.COMPONENT_REGEXP)[1];

      resolve(type);
    });
  }

  public static getBase64(dataUrl: string): Promise<string> {
    return new Promise((resolve): void => {
      const b64 = dataUrl.match(B64DataUrlUtils.COMPONENT_REGEXP)[2];

      resolve(b64);
    });
  }

  public static toArrayBuffer(dataUrl: string): Promise<ArrayBuffer> {
    return new Promise(async (resolve): Promise<void> => {
      const res = await fetch(dataUrl);
      const arrBuf = await res.arrayBuffer();

      resolve(arrBuf);
    });
  }

  public static toBlob(dataUrl: string): Promise<Blob> {
    return new Promise(async (resolve): Promise<void> => {
      const res = await fetch(dataUrl);
      const blob = await res.blob();

      resolve(blob);
    });
  }
}

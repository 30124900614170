/**
 * @author Miras Absar <mabsar@iunu.com>
 */

import { gql } from '@apollo/client'

const QueryCrops = gql`
  query(
    $facilityIds: [ID],
    $offset: Int,
    $limit: Int,
    $enabled: Boolean,
    $archived: Boolean,
    $hasInventoryOnly: Boolean
  ) {
    crop(
      facilityIds: $facilityIds,
      offset: $offset,
      limit: $limit,
      enabled: $enabled,
      archived: $archived,
      hasInventoryOnly: $hasInventoryOnly
    ) {
      id
      createdOn
      updatedOn
      
      startDate
      endDate
      transplantDate
      enabled
      archived
      code
      name
      containerSize
      containersPerLocator
      rating      

      speciesCode
      speciesName
      speciesIcon

      varietyCode
      varietyName
      varietyColor
      varietyPattern

      inventoryCount
      inventory {
        id
        currentLocator{
          id
          serial
        }
      }
    }
  }
  
`

export { QueryCrops }

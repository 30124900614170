/**
 * @author Miras Absar <mabsar@iunu.com>
 */

import React, { ReactElement } from 'react'
import ISelectFacadeProps from '../../interfaces/ISelectFacadeProps'

export default function SelectFacade ({
  /* Visual Properties */
  animated,
  itemFacade: ItemFacade,

  /* Behavioral Properties */
  values,
  onSelect
}: ISelectFacadeProps<any>): ReactElement {
  const className = animated
    ? 'Select Raised Animated'
    : 'Select Raised'

  return (
    <div className={className}>
      {values.map((value): ReactElement => (
        <ItemFacade
          key={value.id}
          animated={animated}
          value={value}
          onSelect={onSelect}
        />
      ))}
    </div>
  )
}

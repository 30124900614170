import { createDate } from 'utils/dates'
import EInputStatus from '../enums/input-status'
import IInputCore from '../interfaces/IInputCore'
import IInputStatus from '../interfaces/IInputStatus'

const DateInputCore: IInputCore<Date, Date> = {
  getValue: (uiValue: Date): Date => {
    if (typeof uiValue === 'string') return createDate(uiValue)
    else return uiValue
  },
  getUIValue: (value: Date): Date => {
    if (typeof value === 'string') return createDate(value)
    else return value
  },
  getStatus: async (): Promise<IInputStatus> => ({
    status: EInputStatus.None,
    message: ''
  })
}

const RequiredDateInputCore: IInputCore<Date, Date> = Object.assign(
  {},
  DateInputCore,
  {
    getStatus: (value: Date): IInputStatus => {
      if (value === undefined ||
        value === null) {
        return {
          status: EInputStatus.Error,
          message: 'Required'
        }
      } else if (Number.isNaN(value.getTime())) {
        return {
          status: EInputStatus.Error,
          message: 'Invalid'
        }
      } else {
        return {
          status: EInputStatus.OK,
          message: ''
        }
      }
    }
  }
)

export {
  DateInputCore,
  RequiredDateInputCore
}

/**
 * @author Miras Absar <mabsar@iunu.com>
 * @file ...
 */

const MapViewRoute = '/map/:id'
const MapViewRoutes = [
  MapViewRoute
]

export { MapViewRoute }
export default MapViewRoutes

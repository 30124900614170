/**
 * @author Miras Absar <mabsar@iunu.com>
 */

// GraphQL
import { gql } from '@apollo/client'

const QueryStaticData = gql`
  query(
    $id: ID
  ) {
    facility(id: $id) {
      id
      vendorIds

      code
      name
      nickname
      timezone

      building {
        id
        facilityId

        code
        name
        nickname
        app_config
        space {
          id
          buildingId
          dcs
          code
          name
          nickname
          vendorCustomerFacilityIds
        }
      }
    }
  }
`

const QueryEnvironmentalZones = gql`
  query(
    $spaceIds: [ID]
  ) {
    environmentalZone(
      spaceIds: $spaceIds
    ) {
      id
      facilityId
      buildingId
      spaceId

      code
      name
    }
  }
`

const QuerySensorRecords = gql`
  query(
    $environmentalZoneIds: [ID],
    $startDate: DateTime,
    $endDate: DateTime
  ) {
    sensorRecord(
      environmentalZoneIds: $environmentalZoneIds,
      startDate: $startDate,
      endDate: $endDate
    ) {
      id
      createdOn
      environmentalZoneId
      sensorDeviceId

      umol
      humidity
      temperature
      vpd
      dli
      absoluteHumidity
    }
  }
`

const QueryCO2SensorRecords = gql`
  query(
    $environmentalZoneIds: [ID],
    $startDate: DateTime,
    $endDate: DateTime
  ) {
    co2SensorRecord(
      environmentalZoneIds: $environmentalZoneIds,
      startDate: $startDate,
      endDate: $endDate
    ) {
      id
      createdOn
      environmentalZoneId
      sensorDeviceId

      co2
    }
  }
`

const QueryGreenAutomationWaterSystemRecords = gql`
  query(
    $facilityIds: [ID]
    $getMostRecentPerSystem: Boolean
    $startDate: DateTime
    $endDate: DateTime
  ) {
    greenAutomationWaterSystemRecord (
      getMostRecentPerSystem: $getMostRecentPerSystem
      facilityIds: $facilityIds
      startDate: $startDate
      endDate: $endDate
    ) {
      id
      vendorCustomerFacilityId
      createdOn
      updatedOn
      status
      waterSystem
      ec1
      ec2
      ph1
      ph2
      dissolvedO2
      waterTemp
    }
  }
`

const QuerySensors = gql`
  query(
    $ids: [ID]
  ) {
    sensor(
      ids: $ids
    ) {
      id
      code
    }
  }
`

const QueryAreaImageRecords = gql`
  query(
    $spaceIds: [ID],
    $startDate: DateTime,
    $endDate: DateTime
  ) {
    areaImageRecord(
      spaceIds: $spaceIds,
      startDate: $startDate,
      endDate: $endDate
    ) {
      id
      createdOn
      spaceId
      camDeviceId

      url
      thumbUrl
    }
  }
`

const QueryAreacams = gql`
  query(
    $ids: [ID]
  ) {
    areacam(
      ids: $ids
    ) {
      id

      code
      latitude
      longitude
    }
  }
`

export {
  QueryStaticData,
  QueryEnvironmentalZones,
  QuerySensorRecords,
  QueryCO2SensorRecords,
  QueryGreenAutomationWaterSystemRecords,
  QuerySensors,
  QueryAreaImageRecords,
  QueryAreacams
}

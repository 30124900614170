// Types
import ID from 'graphql-lib/interfaces/ID';

// React
import React, {
  CSSProperties,
  MouseEvent,
  useEffect,
  useState
} from 'react'

// React Libs
import GoTo from 'react-lib/go-to'

// Font Awesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTag } from '@fortawesome/pro-solid-svg-icons'

// UI Lib
import CropItem from 'ui-lib/components/crop-item';
import { Tag } from '../../utils/Routes';

export interface ICropCardProps {
  style: CSSProperties;

  outlined?: boolean;
  resting?: boolean;
  raised?: boolean;
  animated?: boolean;

  data: any;
  selectedId: ID;
  onClick: (data: any) => void;
}

const CropCard = ({
  style,

  outlined,
  resting,
  raised,
  animated,

  data,
  selectedId,
  onClick
}: ICropCardProps): JSX.Element => {
  // State /////////////////////////////////////////////////////////////////////

  const [canTag, setCanTag] = useState<boolean>()

  // Effects ///////////////////////////////////////////////////////////////////

  useEffect(
    (): void => {
      if (data) {
        const newCanTag = data.containerSize && data.containersPerLocator
        setCanTag(newCanTag)
      }
    },

    [data]
  )

  // Callbacks /////////////////////////////////////////////////////////////////

  const $click = (): void =>
    onClick(data)

  const $oneTag = (ev: MouseEvent<HTMLButtonElement>): void => {
    ev.preventDefault()
    ev.stopPropagation()

    const to = Tag.replace(':id', data.id) + '?page=1'
    GoTo(to)
  }

  const $twoTag = (ev: MouseEvent<HTMLButtonElement>): void => {
    ev.preventDefault()
    ev.stopPropagation()

    const to = Tag.replace(':id', data.id) + '?page=1&twoTag=true'
    GoTo(to)
  }

  // Render ////////////////////////////////////////////////////////////////////

  let className = 'Theme__Light CropCard'
  if (outlined) className += ' Outlined'
  if (resting) className += ' Resting'
  if (raised) className += ' Raised'
  if (animated) className += ' Animated'
  if (data.id == selectedId) className += ' Selected'

  return (
    <article
      style={style}
      className={className}
      onClick={$click}
    >
      <CropItem
        crop={data}
        readOnly
      />

      {canTag ? (
        <div className='Actions'>
          <button onClick={$oneTag}>
            <FontAwesomeIcon icon={faTag} />
          </button>
        </div>
      ) : null}
    </article>
  )
}

export default CropCard

// @author Miras Absar <mabsar@iunu.com>

// Types
import IChartGroup from 'map-view/interfaces/chart-group'

// Utils
import {
  ChartAccuracy,
  ChartName,
  ChartUnit
} from 'map-view/utils/chart'

// React
import React, {
  useMemo
} from 'react'

interface IChartGroupTileProps {
  outlined?: boolean;
  resting?: boolean
  raised?: boolean;
  animated?: boolean;

  chartGroup: IChartGroup;
}

const ChartGroupTile = ({
  outlined,
  resting,
  raised,
  animated,

  chartGroup
}: IChartGroupTileProps): JSX.Element => {
  // Memos /////////////////////////////////////////////////////////////////////

  const chartName = useMemo(
    () => ChartName[chartGroup.chart],
    [chartGroup.chart]
  )

  const chartUnit = useMemo(
    () => ChartUnit[chartGroup.chart],
    [chartGroup.chart]
  )

  const chartMin = useMemo(
    () => {
      const chartAccuracy = ChartAccuracy[chartGroup.chart]
      const chartMin = chartGroup.min.toFixed(chartAccuracy)

      return chartMin
    },

    [ chartGroup.chart,
      chartGroup.min ]
  )

  const chartMax = useMemo(
    () => {
      const chartAccuracy = ChartAccuracy[chartGroup.chart]
      const chartMax = chartGroup.max.toFixed(chartAccuracy)

      return chartMax
    },

    [ chartGroup.chart,
      chartGroup.max ]
  )

  // Render ////////////////////////////////////////////////////////////////////

  let className = 'ChartGroupTile'
  if (outlined) className += ' Outlined'
  if (resting) className += ' Resting'
  if (raised) className += ' Raised'
  if (animated) className += ' Animated'

  return (
    <div className={className}>
      <div className='Name'>{chartName}</div>
      <div className='Value'>{chartMin} {chartUnit}</div>
      <div className='Divider' />
      <div className='Value'>{chartMax} {chartUnit}</div>
    </div>
  )
}

export default ChartGroupTile

// Libs
import { format } from 'date-fns'

import { createDate } from 'utils/dates'

// React
import React, {
  MouseEvent,
  useEffect,
  useState
} from 'react'

// FontAwesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

// UI Lib
import IInventory from 'graphql-lib/interfaces/IInventory';
import IInventoryLocation from 'graphql-lib/interfaces/IInventoryLocation'
import IAction from 'arrayview/interfaces/IAction';
import { IStrayInventoryLocationsType } from 'crops/actions/inventory-group';

interface IInventoryCardProps {
  outlined: boolean
  resting?: boolean
  raised?: boolean
  animated: boolean
  actions: Array<IAction<IStrayInventoryLocationsType>>
  data: IInventory
  supData: Array<IInventoryLocation>
  onClick: (param: CustomEvent<any>) => void
  onAction: (param: CustomEvent<any>) => void
}
const InventoryCard = ({
  outlined,
  resting,
  raised,
  animated,
  actions,
  data,
  supData,
  onClick,
  onAction
}: IInventoryCardProps): JSX.Element => {
  // State /////////////////////////////////////////////////////////////////////
  const [inventoryLocations, setInventoryLocations] = useState<Array<IInventoryLocation>>()

  // Effects ///////////////////////////////////////////////////////////////////

  useEffect((): void => {
    if (supData) {
      const newInventoryLocations = supData.filter((s): boolean =>
        Number(s.inventoryId) === Number(data.id))

      setInventoryLocations(newInventoryLocations)
    }
  }, [supData])

  // Callbacks /////////////////////////////////////////////////////////////////

  const $click = () =>
    onClick(new CustomEvent('click', {
      detail: {
        id: 0,
        inventory: data,
        locations: inventoryLocations
      }
    }))

  const $action =
    (ev: MouseEvent<HTMLButtonElement>, action: IAction<IStrayInventoryLocationsType>): void => {
      ev.preventDefault()
      ev.stopPropagation()

      return onAction(new CustomEvent('action', {
        detail: {
          action,
          data: {
            id: 0,
            inventory: data,
            locations: inventoryLocations
          }
        }
      }))
    }

  // Render ////////////////////////////////////////////////////////////////////

  let className = 'InventoryCard'
  if (outlined) className += ' Outlined'
  if (resting) className += ' Resting'
  if (raised) className += ' Raised'
  if (animated) className += ' Animated'

  let image = <div className='Placeholder' />
  let seenOn = <p className='Caption Secondary'>Never Seen</p>
  let tags = null

  if (inventoryLocations && inventoryLocations.length > 0) {
    const [a, b] = inventoryLocations

    if (a?.seenOn) {
      image = (
        <img
          className='Image'
          src={a.thumbUrl}
        />
      )

      seenOn = (
        <p className='Caption Secondary'>
          {format(createDate(a.seenOn), 'P p')}
        </p>
      )
    }

    if (a) {
      tags = (
        <p className='Caption Secondary'>
          {a.locatorCode}
        </p>
      )
    }

    if (a && b) {
      tags = (
        <p className='Caption Secondary'>
          {a.locatorCode}
          &ensp;
          -
          &ensp;
          {b.locatorCode}
        </p>
      )
    }
  }

  return (
    <article
      className={className}
      onClick={$click}
    >
      {image}
      <div className='Body'>
        <p className='Body1 Primary'>Group {data.code}</p>
        {seenOn}
        {tags}
      </div>

      <div style={{ flex: '1 0 auto' }} />

      {actions && (
        <div className='Actions'>
          {actions.map((action): JSX.Element => {
            const $click = (ev: MouseEvent<HTMLButtonElement>) => {
              $action(ev, action);
            }

            return (
              <button
                key={action.id}
                onClick={$click}
              >
                <FontAwesomeIcon icon={action.icon} />
              </button>
            )
          })}
        </div>
      )}
    </article>
  )
}

export default InventoryCard

import IColumn from 'arrayview/interfaces/IColumn'
import IGroup from 'arrayview/interfaces/IGroup'

import IProduct from '../interfaces/IProduct'

/**
 * TODO: Implement columns...
 * - id
 * - createdOn
 * - updatedOn
 * - maturityDays
 * - enabled
 */

const SafeIncludes = (a: string, b: string): boolean => {
  a = a || ''
  b = b || ''

  return a.toLowerCase().includes(b.toLowerCase())
}

const SafeCompare = (a: string, b: string): number => {
  a = a || ''
  b = b || ''

  return a.localeCompare(b)
}

const CodeColumn: IColumn<IProduct> = Object.freeze({
  id: 'product::code',
  name: 'Code',
  searchFunc: (_: IColumn<IProduct>, search: string, data: IProduct): boolean =>
    SafeIncludes(data.code, search),

  ascendingSortFunc: (_: IColumn<IProduct>, first: IProduct, second: IProduct): number =>
    SafeCompare(first.code, second.code),

  descendingSortFunc: (_: IColumn<IProduct>, first: IProduct, second: IProduct): number =>
    SafeCompare(second.code, first.code),

  groupFunc: (_: IColumn<IProduct>, data: IProduct): IGroup => ({
    id: `${_.id}::${data.code}`,
    name: data.code,
    data: []
  })
})

const SpeciesColumn: IColumn<IProduct> = Object.freeze({
  id: 'product::species',
  name: 'Species',
  searchFunc: (_: IColumn<IProduct>, search: string, data: IProduct): boolean =>
    SafeIncludes(data.species, search),

  ascendingSortFunc: (_: IColumn<IProduct>, first: IProduct, second: IProduct): number =>
    SafeCompare(first.species, second.species),

  descendingSortFunc: (_: IColumn<IProduct>, first: IProduct, second: IProduct): number =>
    SafeCompare(second.species, first.species),

  groupFunc: (_: IColumn<IProduct>, data: IProduct): IGroup => ({
    id: `${_.id}::${data.species}`,
    name: data.species,
    data: []
  })
})

const VarietyColumn: IColumn<IProduct> = Object.freeze({
  id: 'product::variety',
  name: 'Variety',
  searchFunc: (_: IColumn<IProduct>, search: string, data: IProduct): boolean =>
    SafeIncludes(data.variety, search),

  ascendingSortFunc: (_: IColumn<IProduct>, first: IProduct, second: IProduct): number =>
    SafeCompare(first.variety, second.variety),

  descendingSortFunc: (_: IColumn<IProduct>, first: IProduct, second: IProduct): number =>
    SafeCompare(second.variety, first.variety),

  groupFunc: (_: IColumn<IProduct>, data: IProduct): IGroup => ({
    id: `${_.id}::${data.variety}`,
    name: data.variety,
    data: []
  })
})

const NicknameColumn: IColumn<IProduct> = Object.freeze({
  id: 'product::nickname',
  name: 'Nickname',
  searchFunc: (_: IColumn<IProduct>, search: string, data: IProduct): boolean =>
    SafeIncludes(data.nickname, search),

  ascendingSortFunc: (_: IColumn<IProduct>, first: IProduct, second: IProduct): number =>
    SafeCompare(first.nickname, second.nickname),

  descendingSortFunc: (_: IColumn<IProduct>, first: IProduct, second: IProduct): number =>
    SafeCompare(second.nickname, first.nickname),

  groupFunc: (_: IColumn<IProduct>, data: IProduct): IGroup => ({
    id: `${_.id}::${data.nickname}`,
    name: data.nickname,
    data: []
  })
})

const DescriptionColumn: IColumn<IProduct> = Object.freeze({
  id: 'product::description',
  name: 'Description',
  searchFunc: (_: IColumn<IProduct>, search: string, data: IProduct): boolean =>
    SafeIncludes(data.description, search),

  ascendingSortFunc: (_: IColumn<IProduct>, first: IProduct, second: IProduct): number =>
    SafeCompare(first.description, second.description),

  descendingSortFunc: (_: IColumn<IProduct>, first: IProduct, second: IProduct): number =>
    SafeCompare(second.description, first.description),

  groupFunc: (_: IColumn<IProduct>, data: IProduct): IGroup => ({
    id: `${_.id}::${data.description}`,
    name: data.description,
    data: []
  })
})

const ContainerSizeColumn: IColumn<IProduct> = Object.freeze({
  id: 'product::container_size',
  name: 'Container Size',
  searchFunc: (_: IColumn<IProduct>, search: string, data: IProduct): boolean =>
    SafeIncludes(data.containerSize, search),

  ascendingSortFunc: (_: IColumn<IProduct>, first: IProduct, second: IProduct): number =>
    SafeCompare(first.containerSize, second.containerSize),

  descendingSortFunc: (_: IColumn<IProduct>, first: IProduct, second: IProduct): number =>
    SafeCompare(second.containerSize, first.containerSize),

  groupFunc: (_: IColumn<IProduct>, data: IProduct): IGroup => ({
    id: `${_.id}::${data.containerSize}`,
    name: data.containerSize,
    data: []
  })
})

export {
  CodeColumn,
  SpeciesColumn,
  VarietyColumn,
  NicknameColumn,
  DescriptionColumn,
  ContainerSizeColumn
}

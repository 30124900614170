/**
 * @author Miras Absar <mabsar@iunu.com>
 */

// Libs
import m from 'moment-timezone'
import download from 'in-browser-download'

// React Libs
import { toast } from 'react-toastify'
import {
  faDownload,
  faPlus
} from '@fortawesome/pro-light-svg-icons'

// GraphQL
import client from 'graphql-lib/index'
import { gql } from '@apollo/client'

// Utils
import GoTo from 'react-lib/go-to'

// UI Lib
import EColor from 'ui-lib/enums/color'
import IAction from 'arrayview/interfaces/IAction'
import { CreateTask as CreateTaskRoute, CreateTaskIssue as CreateTaskIssueRoute } from '../utils/routes'
import ITask from 'graphql-lib/interfaces/ITask'
import IRoute from 'ui-lib/interfaces/IRoute'

const papaparse = require('papaparse');
const { unparse } = papaparse;

const QueryTasks = gql`
  query {
    task {
      id
      createdOn
      priority
      complete
      name
      body
      dueDate
      flagTitle
      flagCategoryName
      customerUserName
      completedByName
      completedOn
      assigneeName
      assignedOn
    }
  }
`

export const CreateTask: IAction<IRoute> = {
  id: 'action::create-task',
  icon: faPlus,
  name: 'Create Task',
  color: EColor.None,
  applicableFunc: () => true,
  func: async () => GoTo(CreateTaskRoute)
}

export const CreateTaskIssue: IAction<IRoute> = {
  id: 'action::create-task-issue',
  icon: faPlus,
  name: 'Create Issue',
  color: EColor.None,
  applicableFunc: () => true,
  func: async () => GoTo(CreateTaskIssueRoute)
}

export const ExportTasks: IAction<IRoute> = {
  id: 'action::export-tasks',
  icon: faDownload,
  name: 'Export Tasks',
  color: EColor.None,
  applicableFunc: () => true,
  func: async (_: IAction<IRoute>) => {
    const {
      error,
      data
    } = await client.query({ query: QueryTasks })

    if (error) {
      toast.error("Couldn't export tasks; please try again in a few seconds.")
    }

    if (data) {
      const { task: tasks } = data
      const keysToBeRenamed: Record<string, string> = {
        name: 'title',
        body: 'description',
        priority: 'urgent',
        flagTitle: 'flag',
        flagCategory: 'flagCategory',
        customerUserName: 'createdBy'
      }
      /**
       * Rename/Reduce Properties
       */
      const formattedTasksForCSV = tasks.map((task: Partial<ITask> & Record<string, string>): Record<string, string> => {
        const builtObject: Record<string, string> = {};

        const taskObjectKeys: Array<string> = Object.keys(task).sort();
        taskObjectKeys.forEach(key => {
          const propertyName = keysToBeRenamed[key] || key;
          builtObject[propertyName] = task[key];
        });

        return builtObject;
      });
      const csv = unparse(formattedTasksForCSV)
      download(csv, `LUNA Task Export - ${m().toISOString()}.csv`)
    }
  }
}

/**
 * @author Miras Absar <mabsar@iunu.com>
 */

// GraphQL
import { gql } from '@apollo/client'

// Mutations
export const ToggleInventoryFavoriteState = gql`
  mutation ($id: ID!, $favorite: Boolean) {
    Inventory {
      update(input: { id: $id, favorite: $favorite }) {
        id
        favorite
      }
    }
  }
`

export const DeleteInventoryLocator = gql`
  mutation($id: ID!) {
    InventoryLocator {
      delete(input: {id: $id}) {
        id
      }
    }
  }
`

export const DeleteInventory = gql`
  mutation($id: ID!) {
    Inventory {
      delete(input: {id: $id}) {
        id
      }
    }
  }
`

// Queries
export const QueryGridPredictions = gql`
  query(
    $spaceIds: [ID]
    $startDate: DateTime
    $endDate: DateTime
  ) {
    gridPrediction(
      spaceIds: $spaceIds,
      onlyInventories: false,
      onlyContainers: true,
      distinct: true,
      startDate: $startDate,
      endDate: $endDate
    ) {
      id
      createdOn
      spaceId
      grid
    }
  }
`

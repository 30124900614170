/**
 * @author miras Absar <mabsar@iunu.com>
 */

// React
import React, {
  useEffect,
  useRef,
  useState
} from 'react'

// Uitls
import Has from 'utils/has'
import IsVoid from 'utils/is-void'

// UI Lib
import EInputStatus from 'ui-lib/enums/input-status'
import IInputStatus from 'ui-lib/interfaces/IInputStatus'
import Input from 'ui-lib/components/Input/Input'
import Button from 'ui-lib/components/button'
import { RequiredTextInputCore as TextCore }
  from 'ui-lib/cores/text-input-core'

import { RequiredNumberInputCore as NumberCore }
  from 'ui-lib/cores/number-input-core'

import TextFacade from 'ui-lib/facades/text-input'
import ITag from 'graphql-lib/interfaces/ITag'
import ICrop from 'graphql-lib/interfaces/ICrop'
import ISetStateType from 'graphql-lib/interfaces/ISetStateType'

export interface ISetupProps {
  containerSize?: string
  containersPerLocator?: number
  totalGroups?: number
  setContainerSize: ISetStateType<string>
  setContainersPerLocator: ISetStateType<number>
  setTotalGroups: ISetStateType<number>
  onContinue: () => void
}

const Setup = ({
  containerSize,
  containersPerLocator,
  totalGroups,
  setContainerSize,
  setContainersPerLocator,
  setTotalGroups,
  onContinue
}: ISetupProps): JSX.Element => {
  // State /////////////////////////////////////////////////////////////////////
  type ISetupCrop = ICrop & { totalGroups: number } & { [key: string]: any }
  const [initialValue, setInitialValue] = useState<ISetupCrop>()
  const value = useRef<Record<keyof ISetupCrop, any>>({
    totalGroups: null,
    containerSize: null,
    containersPerLocator: null
  })
  const status = useRef<Record<string, IInputStatus>>({})

  const [showStatusUntouched, setShowStatusUntouched] = useState(false)

  // Effects ///////////////////////////////////////////////////////////////////

  useEffect((): void => {
    const newInitialValue = Object.assign(
      {},
      initialValue
    )

    if (Has(containerSize)) {
      newInitialValue.containerSize = containerSize
    }

    if (Has(containersPerLocator)) {
      newInitialValue.containersPerLocator = containersPerLocator
    }

    if (Has(totalGroups)) {
      newInitialValue.totalGroups = totalGroups
    }

    setInitialValue(newInitialValue)
  }, [
    containerSize,
    containersPerLocator,
    totalGroups
  ])

  // Callbacks /////////////////////////////////////////////////////////////////

  const $value = (name: string, _value: any): void => {
    if (Has(_value)) {
      value.current[name] = _value
    }
  }

  const $status = (name: string, _status: IInputStatus): void => {
    status.current[name] = _status
  }

  const $continue = (): void => {
    const hasError = Object.values(status.current).reduce(
      (acc: boolean, cur: IInputStatus): boolean =>
        acc || cur.status === EInputStatus.Error,
      false
    )

    if (hasError) {
      setShowStatusUntouched(true)
    } else {
      if (IsVoid(value.current.containerSize)) {
        value.current.containerSize = String(initialValue.containerSize)
      }

      if (IsVoid(value.current.containersPerLocator)) {
        value.current.containersPerLocator = initialValue.containersPerLocator
      }

      if (IsVoid(value.current.totalGroups)) {
        value.current.totalGroups = initialValue.totalGroups
      }

      setContainerSize(value.current.containerSize)
      setContainersPerLocator(value.current.containersPerLocator)
      setTotalGroups(value.current.totalGroups)
      onContinue()
    }
  }

  // Render ////////////////////////////////////////////////////////////////////
  return (
    <>
      <div className='Body'>
        <Input
          animated
          label='Container Type'
          facade={TextFacade}

          name='containerSize'
          initialValue={initialValue?.containerSize}
          showStatusUntouched={showStatusUntouched}
          onValueChange={$value}
          onStatusChange={$status}
          readOnly={false}
          core={TextCore}
        />

        <Input
          animated
          label='Containers/Boards per Group'
          facade={TextFacade}

          name='containersPerLocator'
          initialValue={initialValue?.containersPerLocator}
          showStatusUntouched={showStatusUntouched}
          onValueChange={$value}
          onStatusChange={$status}
          readOnly={false}
          core={NumberCore}
        />

        <Input
          animated
          label='Number of Groups to Provision'
          facade={TextFacade}

          name='totalGroups'
          initialValue={initialValue?.totalGroups}
          showStatusUntouched={showStatusUntouched}
          onValueChange={$value}
          onStatusChange={$status}
          readOnly={false}
          core={NumberCore}
        />
      </div>

      <div style={{ flex: '1 0 auto' }} />

      <div className='Actions'>
        <Button
          id="continue"
          animated
          outlined
          onClick={$continue}
        >
          Continue
        </Button>
      </div>
    </>
  )
}

export default Setup

/**
 * @author Miras Absar <mabsar@iunu.com>
 */

//  Types
import ISpace from 'graphql-lib/interfaces/ISpace'

// React
import React, {
  MouseEvent,
  useCallback
} from 'react'

// Font Awesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faStar,
  faWarehouse
} from '@fortawesome/pro-solid-svg-icons'

interface ISpaceTileProps {
  space: ISpace;
  favorite?: boolean;
  onFavorite?: (space: ISpace, newFavorite: boolean) => void;
}

const SpaceTile = ({
  space,
  favorite,
  onFavorite
}: ISpaceTileProps): JSX.Element => {
  // Callbacks /////////////////////////////////////////////////////////////////

  // Render ////////////////////////////////////////////////////////////////////

  return (
    <div className='Theme__Dark SpaceTile'>
      <div className='SpaceIconContainer'>
        <FontAwesomeIcon icon={faWarehouse} />
      </div>
    </div>
  )
}

export default SpaceTile

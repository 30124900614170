/**
 * @author Miras Absar <mabsar@iunu.com>
 */

import { format, parseISO } from 'date-fns'
import { createDate } from 'utils/dates'

const StringIncludes = (a: string, b: string): boolean => {
  a = a || ''
  b = b || ''

  return a.toLowerCase().includes(b.toLowerCase())
}

const StringCompare = (a: string, b: string): number => {
  a = a || ''
  b = b || ''

  return a.localeCompare(b)
}

const DateIncludes = (date: string | Date, search: string): boolean => {
  if (date === undefined ||
    date === null ||
    (typeof date === 'string' && Number.isNaN(parseISO(date).getTime())) ||
    (date instanceof Date && Number.isNaN(date.getTime()))) {
    return false
  }

  const dateString = format(createDate(date), 'PPPPpp')
  return StringIncludes(dateString, search)
}

const DateCompare = (a: string | Date, b: string | Date): number => {
  let aTime: number
  let bTime: number

  if (a === undefined ||
    a === null ||
    (typeof a === 'string' && Number.isNaN(parseISO(a).getTime())) ||
    (a instanceof Date && Number.isNaN(a.getTime()))) {
    aTime = Number.MIN_SAFE_INTEGER
  } else {
    aTime = createDate(a).getTime()
  }

  if (b === undefined ||
    b === null ||
    (typeof b === 'string' && Number.isNaN(parseISO(b).getTime())) ||
    (b instanceof Date && Number.isNaN(b.getTime()))) {
    bTime = Number.MIN_SAFE_INTEGER
  } else {
    bTime = createDate(b).getTime()
  }

  return aTime - bTime
}

export {
  StringIncludes,
  StringCompare,
  DateIncludes,
  DateCompare
}

// Types
import ICrop from 'graphql-lib/interfaces/ICrop'

// Libs
import { v4 } from 'uuid'

// GraphQL
import client from 'graphql-lib/index'
import { ApolloQueryResult, FetchResult, gql } from '@apollo/client'

import ID from 'graphql-lib/interfaces/ID';
import ISetStateType from 'graphql-lib/interfaces/ISetStateType';

const Init = ({
  hookID
}: {
  hookID: { current: string };
}): void => {
  hookID.current = `hook:useCrop:${v4()}`
}

const QueryCrop = async ({
  id,
  columns,

  setLoading,
  setError,
  setCrop,
  setUUID
}: {
  id: ID;
  columns: string[];
  setLoading: ISetStateType<boolean>
  setError: ISetStateType<Error>
  setCrop: ISetStateType<ICrop>
  setUUID: ISetStateType<string>
}): Promise<void> => {
  if (id && columns) {
    const query = gql`
      query($id: ID) {
        crop(id: $id) {
          ${columns.join(' ')}
        }
      } 
    `

    setLoading(true)
    setUUID(v4())
    let error
    let data
    try {
      const response: FetchResult<{ crop: ICrop[] }> = await client.query({
        fetchPolicy: 'no-cache',
        query,
        variables: { id }
      })

      data = response.data
    } catch (err) {
      error = err as Error
    }

    setLoading(false)
    if (error) {
      setError(error)
      setCrop(undefined)
      setUUID(v4())
    } else if (data) {
      setError(undefined)
      setCrop(data.crop[0])
      setUUID(v4())
    }
  }
}

export {
  Init,
  QueryCrop
}

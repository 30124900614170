// React
import { useEffect } from 'react'

/**
 * Ask Miras about this (・・；)
 */
export const useIter = (
  effect: Function,
  args?: any,
  deps?: any[]
): void =>
  useEffect(
    () => effect(args),
    deps
  )

/**
 * Ask Miras about this (・・；)
 */
 export const useAsyncIter = (
  effect: Function,
  args?: any,
  deps?: any[],
  onResolve?: Function,
  onReject?: Function,
  onError?: Function,
  onFinish?: Function
): void =>
  useEffect(
    () => {
      effect(args)
        .then(onResolve, onReject)
        .catch(onError)
        .finally(onFinish)
    },
    deps
  )

import EOverlay from 'map-view/enums/overlay'

const UpdateSelected = ({
  type,
  overlays,

  setSelected
}: {
  type: EOverlay
  overlays: Array<EOverlay>

  setSelected: (val: boolean) => void
}): void => {
  if (
    type &&
    overlays
  ) {
    const newSelected = !!overlays
      .find(o => o === type)

    setSelected(newSelected)
  }
}

export { UpdateSelected }

// React Libs
import { faUnlink } from '@fortawesome/pro-regular-svg-icons'

// GraphQL
import { gql } from '@apollo/client'
import client from 'graphql-lib/index'

// UI Lib
import EColor from 'ui-lib/enums/color'
import IAction from 'arrayview/interfaces/IAction'
import ID from 'graphql-lib/interfaces/ID'

const DeleteInventoryLocator = gql`
  mutation($id: ID!) {
    InventoryLocator {
      delete(input: {id: $id}) {
        id
      }
    }
  }
`
export interface IStrayInventoryLocationsType {
  locations: Array<{ id: ID }>;
}
const DeprovisionInventory = Object.freeze({
  id: 'action:deprovision-inventory',
  icon: faUnlink,
  name: 'Deprovision',
  color: EColor.Warning,
  applicableFunc: (_: IAction<IStrayInventoryLocationsType>, data: IStrayInventoryLocationsType): boolean =>
    data.locations && data.locations.length > 0,
  func: async (action: IAction<IStrayInventoryLocationsType>, data: Array<IStrayInventoryLocationsType>) => {
    if (data.length !== 1) {
      throw new Error(`${action.id} can only deprovision one inventory group`)
    }
    const { locations } = data[0]
    if (!action.applicableFunc(action, data[0])) {
      throw new Error(`${action.id} can only deprovision provisioned inventory groups`)
    }

    return Promise.all(locations.map(
      async ({ id }) =>
        client.mutate({
          mutation: DeleteInventoryLocator,
          variables: { id }
        })
    ));
  }
})

export { DeprovisionInventory }

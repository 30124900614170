/**
 * @author Miras Absar <mabsar@iunu.com>
 */

const selectStyles = {
  container: styles => ({
    ...styles,
    height: '36px'
  }),
  control: styles => ({
    ...styles,
    background: 'none',
    border: 'none',
    borderRadius: 0,
    boxShadow: 'none'
  }),
  placeholder: styles => ({
    ...styles,
    fontFamily: 'Roboto, "Noto Sans", sans-serif',
    fontWeight: 400,
    fontSize: '16px',
    color: 'var(--theme__hint)'
  }),
  input: styles => ({
    ...styles,
    fontFamily: 'Roboto, "Noto Sans", sans-serif',
    fontWeight: 400,
    fontSize: '16px',
    color: 'var(--theme__primary)'
  }),
  singleValue: styles => ({
    ...styles,
    fontFamily: 'Roboto, "Noto Sans", sans-serif',
    fontWeight: 400,
    fontSize: '16px',
    color: 'var(--theme__primary)'
  }),
  indicatorSeparator: styles => ({
    ...styles,
    background: 'var(--theme__border)'
  }),
  dropdownIndicator: styles => ({
    ...styles,
    color: 'var(--theme__hint)'
  }),
  menu: styles => ({
    ...styles,
    background: 'var(--theme__paper__24)',
    boxShadow: '0 0 2px var(--theme__shadow), 0 2px 2px var(--theme__shadow)'
  }),
  option: (styles, { isFocused, isSelected }) => ({
    ...styles,
    background: isSelected
      ? 'var(--theme__accent)'
      : isFocused
        ? 'var(--theme__shadow)'
        : 'transparent',
    fontFamily: 'Roboto, "Noto Sans", sans-serif',
    fontWeight: 400,
    fontSize: '16px',
    color: 'var(--theme__primary)'
  })
}

const filterSelectStyles = {
  ...selectStyles,
  container: (styles, state) => ({
    ...styles,
    height: '36px',
    width: '175px'
  }),
}

const columnSelectStyles = {
  ...selectStyles,
  container: (styles, state) => ({
    ...styles,
    height: '36px',
    width: '144px'
  }),
}

export {
  columnSelectStyles,
  filterSelectStyles
}

/**
 * @author Miras Absar <mabsar@iunu.com>
 */

// Styles
import lunaLogo from 'public/assets/img/luna-logo.png'

// Types
import EInputStatus from 'ui-lib/enums/input-status'

// React
import React, {
  KeyboardEvent,
  useCallback
} from 'react'

// React Libs
import { gql, useMutation } from '@apollo/client'
import { useIter } from 'react-lib/use-iter'

// UI Lib
import { useInput } from 'ui-lib/hooks/use-input'
import { RequiredEmail } from 'ui-lib/validators/email'
import OutlinedInput from 'ui-lib/components/input/outlined-input'
import Button from 'ui-lib/components/button'

// Effects
import { HandleForgotPassword } from './effects'

// Mutations
const CreateResetToken = gql`
  mutation($email: Email!) {
    ResetToken {
      create(input: { email: $email }) {
        email
      }
    }
  }
`

// Routes
const ForgotPasswordRoutes = [
  '/forgot-password'
]

const ForgotPassword = (): JSX.Element => {
  // State /////////////////////////////////////////////////////////////////////

  const emailState = useInput<string>('', RequiredEmail)
  const [
    email,
    emailStatus
  ] = emailState

  // API ///////////////////////////////////////////////////////////////////////

  const [
    createResetToken,

    { loading,
      error,
      data }
  ] = useMutation(CreateResetToken)

  // Effects ///////////////////////////////////////////////////////////////////

  useIter(
    HandleForgotPassword,

    { loading,
      error,
      data },

    [ loading,
      error,
      data ]
  )

  // Callbacks /////////////////////////////////////////////////////////////////

  const $forgotPassword = (): void => {
    if (emailStatus?.status === EInputStatus.OK) {
      createResetToken({ variables: { email } })
    }
  }

  const $keyPress = (ev: KeyboardEvent): void => {
    if (ev.key === 'Enter') {
      $forgotPassword()
    }
  }

  // Render ////////////////////////////////////////////////////////////////////

  return (
    <main
      className='ForgotPassword'
      onKeyPress={$keyPress}
    >
      <section className='ForgotPasswordCard'>
        <div className='Image'>
          <img src={lunaLogo} />
        </div>

        <OutlinedInput
          animated
          label='Email'
          inputProps={{ type: 'email' }}
          state={emailState}
        />

        <Button
          loading={loading}
          animated
          resting
          onClick={$forgotPassword}
        >
          Forgot Password
        </Button>
      </section>
    </main>
  )
}

export { ForgotPasswordRoutes }
export default ForgotPassword

/**
 * @author Miras Absar <mabsar@iunu.com>
 */

// Types
import ICrop from 'graphql-lib/interfaces/ICrop'
import IInventory from 'graphql-lib/interfaces/IInventory'
import ITask from 'graphql-lib/interfaces/ITask'

// Libs
import { v4 } from 'uuid'

const UpdateCrop = ({
  cropLoading,
  cropData,

  setCrop,
  setCropUUID
}: {
  cropLoading: boolean;
  cropData: { crop: ICrop[] };

  setCrop: (newVal: ICrop) => void;
  setCropUUID: (newVal: string) => void;
}): void => {
  if (!cropLoading && cropData) {
    const newCrop = cropData.crop[0]
    const newCropUUID = v4()

    setCrop(newCrop)
    setCropUUID(newCropUUID)
  }
}

import ID from 'graphql-lib/interfaces/ID';

const UpdateInventories = ({
  inventoriesLoading,
  inventoriesData,

  setInventories,
  setInventoryIDs,
  setInventoriesUUID
}: {
  inventoriesLoading: boolean;
  inventoriesData: { inventory: IInventory[] };

  setInventories: (newVal: IInventory[]) => void;
  setInventoryIDs: (newVal: ID[]) => void;
  setInventoriesUUID: (newVal: string) => void;
}): void => {
  if (!inventoriesLoading && inventoriesData) {
    const newInventories = inventoriesData.inventory
    const newInventoryIDs = newInventories.map((i): ID => i.id)
    const newInventoriesUUID = v4()

    setInventories(newInventories)
    setInventoryIDs(newInventoryIDs)
    setInventoriesUUID(newInventoriesUUID)
  }
}

const UpdateTasks = ({
  tasksLoading,
  tasksData,

  setTasks,
  setTasksUUID
}: {
  tasksLoading: boolean;
  tasksData: { task: ITask[] };

  setTasks: (newVal: ITask[]) => void;
  setTasksUUID: (newVal: string) => void;
}): void => {
  if (!tasksLoading && tasksData) {
    const newTasks = tasksData.task
    const newTasksUUID = v4()

    setTasks(newTasks)
    setTasksUUID(newTasksUUID)
  }
}

const ConcatenateTasks = ({
  tasksA,
  tasksB,

  setTasks,
  setTasksUUID
}: {
  tasksA: ITask[];
  tasksB: ITask[];

  setTasks: (newVal: ITask[]) => void;
  setTasksUUID: (newVal: string) => void;
}): void => {
  if (tasksA && tasksB) {
    const newTasks = [
      ...tasksA,
      ...tasksB
    ]

    const newTasksUUID = v4()

    setTasks(newTasks)
    setTasksUUID(newTasksUUID)
  }
}

export {
  UpdateCrop,
  UpdateInventories,
  UpdateTasks,
  ConcatenateTasks
}

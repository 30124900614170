/**
 * @author Miras Absar <mabsar@iunu.com>
 */

import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSpinnerThird, faAnalytics } from '@fortawesome/pro-regular-svg-icons'

interface ISensorControlProps {
  outlined?: boolean;
  resting?: boolean;
  raised?: boolean;
  animated?: boolean;

  loading: boolean;
  disabled: boolean;
  onClick: () => void;
}

const SensorControl = ({
  outlined,
  resting,
  raised,
  animated,

  loading,
  disabled,
  onClick
}: ISensorControlProps): JSX.Element => {
  let className = 'SensorControl'
  if (outlined) className += ' Outlined'
  if (resting) className += ' Resting'
  if (raised) className += ' Raised'
  if (animated) className += ' Animated'
  if (disabled) className += ' Disabled'

  const $click = (): void => {
    if (!loading && !disabled) onClick()
  }

  return (
    <div
      className={className}
      onClick={$click}
    >
      {loading
        ? <FontAwesomeIcon icon={faSpinnerThird} spin />
        : <FontAwesomeIcon icon={faAnalytics} />}
    </div>
  )
}

export type { ISensorControlProps }
export default SensorControl

// Types
import IEntity from 'graphql-lib/interfaces/entity'
import ISpace from 'graphql-lib/interfaces/ISpace'
import IInventory from 'graphql-lib/interfaces/IInventory'
import IInventoryLocation from 'graphql-lib/interfaces/IInventoryLocation'
import IInventoryStitch from 'graphql-lib/interfaces/IInventoryStitch'
import m from 'moment'

// Utils
import Has from 'utils/has'
import { ChartOrder } from 'utils/chart'

// Libs
import { v4 } from 'uuid'

// React
import React, {
  MouseEvent,
  useCallback,
  useContext,
  useEffect,
  useState
} from 'react'

// React Libs
import { toast } from 'react-toastify'
import GoTo from 'react-lib/go-to'
import { useIter } from 'react-lib/use-iter'

// GraphQL & Queries
import client from 'graphql-lib/index'
import {
  DeleteInventoryLocator,
  DeleteInventory,
  QueryGridPredictions
} from './queries'

// Contexts
import NavigationContext from 'context/navigation'

// Effects
import {
  UpdateSelected,
  UpdateLastSeen,
  UpdateInventoryCharts
} from './effects'

// Font Awesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUnlink, faTrash } from '@fortawesome/pro-regular-svg-icons'

// UI Lib
import InventoryChart from './inventory-chart'
import useSearchParams from 'react-lib/use-search-params'
import { FetchResult, useLazyQuery } from '@apollo/client'
import { DetailItemLoader } from 'ui-lib/utils/Placeholder'
import FavoriteButton from 'ui-lib/components/batches/favorite-button'
import IChart from 'map-view/interfaces/chart'
import ID from 'graphql-lib/interfaces/ID'

export interface IInventoryItemProps {
  animated?: boolean;

  readOnly?: boolean;
  spacesLoading: boolean;
  inventoryLocationsLoading: boolean;
  inventoryStitchesLoading: boolean;
  refetchInventories: () => void
  spaces: ISpace[];
  inventory: IInventory;
  inventoryLocations: Record<ID, Array<IInventoryLocation>>;
  inventoryStitches: Record<ID, Array<IInventoryStitch>>;
  inventoryCharts: Record<ID, Array<IChart>>;
  selectedEntities?: Array<IEntity>;

  onSelect?: (cropID: ID, inventoryID: ID) => void;
  onSelectChart?: (cropID: ID, inventoryID: ID, chartKey: string) => void;
  onEdit?: (cropID: ID, inventoryID: ID) => void;
}

const InventoryItem = ({
  animated,

  readOnly,
  spacesLoading,
  inventoryLocationsLoading,
  inventoryStitchesLoading,
  refetchInventories,
  spaces,
  inventory,
  inventoryLocations: _inventoryLocations,
  inventoryStitches: _inventoryStitches,
  inventoryCharts: _inventoryCharts,
  selectedEntities,

  onSelect,
  onSelectChart,
  onEdit
}: IInventoryItemProps): JSX.Element => {
  // Queries /////////////////////////////////////////////////////////////////////
  const [getGridPredictions, { data: gridPredictions }] = useLazyQuery(QueryGridPredictions)

  // State /////////////////////////////////////////////////////////////////////
  const [searchParams] = useSearchParams()
  const [selected, setSelected] = useState<boolean>()
  const [image, setImage] = useState<string>()
  const [lastSeen, setLastSeen] = useState<string>()
  const [tags, setTags] = useState<string>()
  const [inventoryCharts, setInventoryCharts] = useState<Record<string, Array<{value: number}> >>()

  const [_, rerender] = useState<string>()
  const [deleted, setDeleted] = useState<boolean>()

  // Contexts ///////////////////////////////////////////////////////////////////
  const { routes } = useContext(NavigationContext)

  // Effects ///////////////////////////////////////////////////////////////////

  useIter(
    UpdateSelected,

    { inventory,
      selectedEntities,

      setSelected },

    [ inventory,
      selectedEntities]
  )

  useIter(
    UpdateLastSeen,

    { spacesLoading,
      inventoryLocationsLoading,
      inventoryStitchesLoading,

      spaces,
      inventory,
      _inventoryLocations,
      _inventoryStitches,

      setImage,
      setLastSeen,
      setTags },

    [ spaces,
      inventory,
      inventoryStitchesLoading,
      _inventoryLocations,
      _inventoryStitches ]
  )

  useIter(
    UpdateInventoryCharts,

    { inventory,
      _inventoryCharts,

      setInventoryCharts },

    [ inventory,
      _inventoryCharts]
  )

  useEffect(() => {
    if (!gridPredictions) return
    const { gridPrediction } = gridPredictions
    const customerFacilityId = searchParams.fid
    const inventoryLocations = _inventoryLocations[inventory.id]
    const inventoryLocation = inventoryLocations[0]

    // Once the user clicks an inventory image,
    // only send them to map view if the customer has map view and grid predictions.
    // Otherwise, send them to grid view.
    const hasMapView = routes.find(r => r.id === 'route::map' && !r.disabled)

    const to = (hasMapView && gridPrediction.length)
      ? `/map/${customerFacilityId}?fid=${customerFacilityId}&selectInventoryId=${inventory.id}&selectSpaceId=${inventoryLocation.spaceId}&time=${inventoryLocation.seenOn}`
      : `/grid/space/${inventoryLocation.spaceId}/${inventoryLocation.seenOn}/${inventoryLocation.positionX}/${inventoryLocation.positionY}`

    GoTo(to)
  }, [gridPredictions])

  // Callbacks /////////////////////////////////////////////////////////////////

  const $open = useCallback(
    (ev: MouseEvent<Element, any>): void => {
      ev.preventDefault()
      ev.stopPropagation()

      const inventoryLocations = _inventoryLocations[inventory.id]

      if (Has(inventoryLocations?.[0]?.facilityId)) {
        const inventoryLocation = inventoryLocations[0]
        const spaceId = inventoryLocation.spaceId

        // Query to check if map view grid predictions are available. 
        // Then redirect to either map or grid view.
        getGridPredictions({
          variables: {
            spaceIds: [spaceId],
            startDate: m(inventoryLocation.seenOn).subtract(14, 'd').format(),
            endDate: m(inventoryLocation.seenOn).add(14, 'd').format()
          },
          fetchPolicy: 'no-cache'
        })
      }
    },

    [inventory,
      inventory?.id,
      _inventoryLocations]
  )

  const $select = useCallback(
    (): void => onSelect?.(inventory.cropId, inventory.id),

    [inventory,
      inventory?.id,
      onSelect]
  )

  const $deprovision = useCallback(
    async (ev: MouseEvent<Element, any>): Promise<void> => {
      ev.preventDefault()
      ev.stopPropagation()

      if (inventory.inventoryLocator.length === 1) {
        const inventoryLocator = inventory.inventoryLocator[0]
        const deprovision = confirm(`Are you sure you want to deprovision Tag ${inventoryLocator.locator[0].serial}?`)
        if (!deprovision) {
          return
        }
        let error: Error // To check with Michael. see Lines bellow
        let data
        try {
          const response: FetchResult = await client.mutate({
            mutation: DeleteInventoryLocator,
            variables: { id: inventoryLocator.id }
          })
          // const error = response.errors
          data = response.data
        } catch (err) {
          error = err as Error
        }
        if (error) {
          toast.error(`Couldn't deprovision Tag ${inventoryLocator.locator[0].serial}.`)
        } else if (data) {
          toast.info(`Deprovisioned Tag ${inventoryLocator.locator[0].serial}.`)
          inventory.inventoryLocator.splice(0, 1)
          rerender(v4())
        }
      }
    },

    [ inventory,
      inventory?.id ]
  )

  const $delete = useCallback(
    async (ev: MouseEvent): Promise<void> => {
      ev.preventDefault()
      ev.stopPropagation()

      const del = confirm(`Are you sure you want to delete Inventory Group ${inventory.code}?`)
      if (!del) {
        return
      }

      const needsToDeprovision = inventory.inventoryLocator.length === 1
      let error: Error
      let data
      if (needsToDeprovision) {
        const inventoryLocator = inventory.inventoryLocator[0]
        try {
          const response: FetchResult<{}> = await client.mutate({
            mutation: DeleteInventoryLocator,
            variables: { id: inventoryLocator.id }
          })
          data = response.data
        } catch (err) {
          error = err as Error
        }
        if (error) {
          toast.error(`Couldn't deprovision Tag ${inventoryLocator.locator[0].serial}.`)
        } else if (data) {
          toast.info(`Deprovisioned Tag ${inventoryLocator.locator[0].serial}.`)
          inventory.inventoryLocator.splice(0, 1)
          rerender(v4())
        }
      }

      error = undefined;
      data = undefined;
      try {
        const response: FetchResult<{}> = await client.mutate({
          mutation: DeleteInventory,
          variables: { id: inventory.id }
        })

        data = response.data
      } catch (err) {
        error = err as Error
      }

      if (error) {
        toast.error(`Couldn't delete Inventory Group ${inventory.code}.`)
      } else if (data) {
        toast.info(`Deleted Inventory Group ${inventory.code}.`)
        rerender(v4())
        setDeleted(true)
      }
    },

    [ inventory,
      inventory?.id ]
  )

  // Render ////////////////////////////////////////////////////////////////////

  let className = 'InventoryItem'
  if (animated) className += ' Animated'
  if (selected) className += ' Selected'

  if (!inventory) {
    return null
  }

  const imageElement = image
    ? <img src={image} />
    : <div className='Placeholder' />

  const canDeprovision = inventory.inventoryLocator.length === 1
  const canDelete =
    _inventoryLocations &&
    (
      !_inventoryLocations[inventory.id] ||
      _inventoryLocations[inventory.id].length === 0
    )

  if (deleted) {
    return null
  }

  const lastSeenAndLocation = lastSeen && lastSeen.length
    ? lastSeen
    : <DetailItemLoader className="mt-xs" options={{ wrapped: true, lines: 2, range: [80, 240] }} />

  return (
    <section
      className={className}
      onClick={$select}
    >
      <div className='Main'>
        <div
          className='Image'
          onClick={$open}
        >
          {imageElement}
        </div>

        <div className='Body'>
          <h6>Group {inventory.code}</h6>
          <p className="w-100">{lastSeenAndLocation}</p>
          <p>{tags}</p>
        </div>

        <div className='Actions'>
          {!readOnly && <FavoriteButton inventory={inventory} refetchInventories={refetchInventories} />}
          {
            !readOnly && canDeprovision && (
              <button className="d-f ai-c jc-c w-l p-s" onClick={ev => $deprovision(ev)}>
                <FontAwesomeIcon icon={faUnlink} />
              </button>
            )
          }

          {
            !readOnly && canDelete && (
              <button className="d-f ai-c jc-c w-l p-s" onClick={ev => $delete(ev)}>
                <FontAwesomeIcon icon={faTrash} />
              </button>
            )
          }
        </div>
      </div>

      <div className='Charts'>
        { selected &&
          inventoryCharts &&
          Object.entries(inventoryCharts)
            .sort((a, b): number => ChartOrder[a[0]] - ChartOrder[b[0]])
            .map((c): JSX.Element => (
              <InventoryChart
                key={c[0]}

                animated={animated}

                selectedEntities={selectedEntities}
                inventory={inventory}
                chart={c}

                onSelect={onSelectChart}
              />
            ))}
      </div>
    </section>
  )
}

export default InventoryItem

// React
import React, {
  useCallback
} from 'react';

// Font Awesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/pro-regular-svg-icons';
import { faPencil } from '@fortawesome/pro-solid-svg-icons';

// UI Lib
import CropItem from 'ui-lib/components/crop-item';
import useCrop from 'graphql-lib/states/use-crop';

// Types
import ID from 'graphql-lib/interfaces/ID';
export interface ICropHeaderProps {
  animated?: boolean;

  readOnly?: boolean;
  id: ID;

  onClose?: (id: ID) => void;
  onEdit?: (id: ID) => void;
}

const CropHeader = ({
  animated,

  readOnly,
  id,

  onClose,
  onEdit
}: ICropHeaderProps): JSX.Element => {
  // State /////////////////////////////////////////////////////////////////////

  const {
    crop,
    update
  } = useCrop(
    id,

    [ 'id',

      'startDate',
      'transplantDate',
      'endDate',

      'code',
      'name',
      'rating',

      'speciesCode',
      'speciesName',
      'speciesIcon',

      'varietyCode',
      'varietyName',
      'varietyColor',
      'varietyPattern' ]
  )

  // Callbacks /////////////////////////////////////////////////////////////////

  const rating$change = useCallback(
    (cropId: ID, newVal: number): void => {
      if (!readOnly) {
        update({ rating: newVal as 0 | 1 | 2 | 3 | 4 | 5 })
      }
    },

    [ id,
      update ]
  )

  const $close = useCallback(
    (): void => onClose?.(id),

    [ id,
      onClose ]
  )

  const $edit = useCallback(
    (): void => onEdit?.(id),

    [ id,
      onEdit ]
  )

  // Render ////////////////////////////////////////////////////////////////////

  let className = 'CropHeader'
  if (animated) className += ' Animated'

  if (!crop) {
    return null
  }

  return (
    <section className={className}>
      <CropItem
        crop={crop}
        readOnly={readOnly}
        onChangeRating={rating$change}
      />

      <div className='Actions'>
        <button id="close" onClick={$close}>
          <FontAwesomeIcon icon={faTimes} />
        </button>

        {!readOnly && (
          <button id="edit" onClick={$edit}>
            <FontAwesomeIcon icon={faPencil} />
          </button>
        )}
      </div>
    </section>
  )
}

export default CropHeader

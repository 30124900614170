import { v4 } from 'uuid';
import m from 'moment-timezone';
import { saveAs } from 'file-saver';

import React, { KeyboardEvent, useEffect, useState } from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faTimes,
  faArrowUp,
  faArrowDown,
  faArrowLeft,
  faArrowRight,
  faDownload,
} from '@fortawesome/pro-regular-svg-icons';

import PinchZoomPan from 'react-responsive-pinch-zoom-pan';
import TimeTravelControl from '../time-travel-control';

import GoTo from 'react-lib/go-to';
import { GetItem, EStorage } from 'utils/storage';
import { Space } from '../../utils/routes';

import Has from 'utils/has';
import useStorage from 'react-lib/use-storage';

import Magnifier from 'react-magnifier';
import AspectRatio from 'ui-lib/components/aspect-ratio'
import useDevice from '../../../react-lib/use-device';
import { Moment } from 'moment';

export interface IImageCardProps {
  outlined?: boolean;
  resting?: boolean;
  raised?: boolean;
  animated?: boolean;

  timestamp: Moment;
  x: number | string;
  y: number | string;
  space: any;
  arcImages: any[][];

  spaceCols: number;
  spaceRows: number;

  show: boolean;
}

const ImageCard = ({
  outlined,
  resting,
  raised,
  animated,

  timestamp,
  x,
  y,
  space,
  arcImages,

  spaceCols,
  spaceRows,

  show,
}: IImageCardProps): JSX.Element => {
  const [user] = useStorage('user', EStorage.EphemeralStorage);
  const admin = Has(user.admin);
  // const { desktop } = useDevice()
  const desktop = false

  let className = 'Card ViewImageCard';
  if (outlined) className += ' Outlined';
  if (resting) className += ' Resting';
  if (raised) className += ' Raised';
  if (animated) className += ' Animated';

  const [uuid, setUUID] = useState<string>();
  useEffect((): void => {
    setTimeout((): void => {
      setUUID(v4());
    }, 200);
  }, [x, y, show]);

  const xIndex = Number(x);
  const yIndex = Number(y);
  const arcImage =
    arcImages?.[xIndex]?.[yIndex] ||
    {
      id: -1,
      positionX: x,
      positionY: y,
    };

  const $close = () => {
    const _timestamp = timestamp.toISOString();

    const to = Space.replace(':id', space.id)
      .replace(':timestamp?', _timestamp)
      .replace(':x?', (x as string))
      .replace(':y?', (y as string));

    GoTo(to);
  };

  const $up = (): void => {
    const _y = Number(y);

    if (_y > 0) {
      const _timestamp = timestamp.toISOString();

      const to =
        Space.replace(':id', space.id)
          .replace(':timestamp?', _timestamp)
          .replace(':x?', (x as string))
          .replace(':y?', String(_y - 1)) + `?imageId=${arcImage.id}`;

      GoTo(to);
    }
  };

  const $down = (): void => {
    const _y = Number(y);

    if (_y < spaceRows) {
      const _timestamp = timestamp.toISOString();

      const to =
        Space.replace(':id', space.id)
          .replace(':timestamp?', _timestamp)
          .replace(':x?', (x as string))
          .replace(':y?', String(_y + 1)) + `?imageId=${arcImage.id}`;

      GoTo(to);
    }
  };

  const $left = (): void => {
    const _x = Number(x);

    if (_x > 0) {
      const _timestamp = timestamp.toISOString();

      const to =
        Space.replace(':id', space.id)
          .replace(':timestamp?', _timestamp)
          .replace(':x?', String(_x - 1))
          .replace(':y?', y as string) + `?imageId=${arcImage.id}`;

      GoTo(to);
    }
  };

  const $right = (): void => {
    const _x = Number(x);

    if (_x < spaceCols) {
      const _timestamp = timestamp.toISOString();

      const to =
        Space.replace(':id', space.id)
          .replace(':timestamp?', _timestamp)
          .replace(':x?', String(_x + 1))
          .replace(':y?', y as string) + `?imageId=${arcImage.id}`;

      GoTo(to);
    }
  };

  const $download = async (): Promise<void> => {
    const uriRegex = /^(?<scheme>\w+):\/\/(?<host>\S+?)\/(?<bucket>\S+?)\/(?<object>\S+?)(?:$|\?|&)/
    const uriParts = arcImage.url.match(uriRegex)?.groups
    if (uriParts == null) {
      throw new TypeError("'arcImage.url' is invalid.")
    }
    const name = uriParts.object
    const res = await fetch(arcImage.url);
    const blob = await res.blob();

    saveAs(blob, name);
  };

  const $timeTravel = (time: Moment): void => {
    if (time.isSame(timestamp)) {
      return;
    }

    const to =
      Space.replace(':id', space.id)
        .replace(':timestamp?', time.toISOString())
        .replace(':x?', x as string)
        .replace(':y?', y as string) + `?imageId=${arcImage.id}`;

    GoTo(to);
  };

  const $keyDown = ({ key }: KeyboardEvent): void => {
    switch (key) {
      case 'ArrowUp':
        $up();
        break;
      case 'ArrowDown':
        $down();
        break;
      case 'ArrowLeft':
        $left();
        break;
      case 'ArrowRight':
        $right();
        break;
      case 'Escape':
        $close();
        break;
    }
  };

  const $stopKeyDown = (ev: KeyboardEvent): void => {
    ev.stopPropagation();
  }

  const facilities = GetItem('facilities', EStorage.EphemeralStorage);
  const timezone = facilities[0].timezone;

  if (!show) return null;

  return (
    <article className={className} onKeyPress={$keyDown} tabIndex={0}>
      <div className="Head">
        <div className="Row">
          <button onClick={$close}>
            <FontAwesomeIcon icon={faTimes} />
          </button>

          <div className="Space" />

          <div className="Metadata">
            <p>Space {space.name}</p>
            <p>
              Position {arcImage.positionX},{arcImage.positionY}
            </p>
            {admin && arcImage.hostname && <p>Hostname {arcImage.hostname}</p>}
            <p>
              {arcImage.createdOn
                ? m.tz(arcImage.createdOn, timezone).format('lll z')
                : 'No Image'}
            </p>
          </div>
        </div>
      </div>

      <div className="Image">
        {
          !desktop ?
            <PinchZoomPan
              key={uuid}
              maxScale={10}
              position="center"
              zoomButtons={false}
            >
              <img key={uuid} src={arcImage.url} />
            </PinchZoomPan>
            : <AspectRatio
              defaultWidth={4122}
              defaultHeight={2176}
              imgSrc={arcImage.url}
            >
              <Magnifier
                src={arcImage.url}
                zoomFactor={2.5}
                mgWidth={150}
                mgHeight={150}
              />
            </AspectRatio>
        }
      </div>

      <div className="IC_Navigation">
        <button className="nav-buttons" onClick={$right}>
          <FontAwesomeIcon icon={faArrowRight} />
        </button>

        <div>
          <button className="nav-buttons" onClick={$up}>
            <FontAwesomeIcon icon={faArrowUp} />
          </button>

          <button className="nav-buttons" onClick={$down}>
            <FontAwesomeIcon icon={faArrowDown} />
          </button>
        </div>

        <button className="nav-buttons" onClick={$left}>
          <FontAwesomeIcon icon={faArrowLeft} />
        </button>

        <button className="download" onClick={$download}>
          <FontAwesomeIcon icon={faDownload} />
        </button>
      </div>

      <div className="IC_Navigation" onKeyPress={$stopKeyDown}>
        <TimeTravelControl
          outlined
          animated
          time={timestamp}
          onTimeTravel={$timeTravel}
        />
      </div>
    </article>
  );
};

export default ImageCard;

// Libs
import { v4 } from 'uuid'

// React
import React, {
  useCallback,
  useRef,
  useState,
  Children
} from 'react'

// React Libs
import { useIter } from 'react-lib/use-iter'

// OpenLayers
import Map from 'ol/Map'
import View from 'ol/View'
import Collection from 'ol/Collection'
import Interaction from 'ol/interaction/Interaction'
import Control from 'ol/control/Control'
import Overlay from 'ol/Overlay'
import BaseLayer from 'ol/layer/Base'
import LayerGroup from 'ol/layer/Group'

import {
  InitMap,
  UpdateView,
  CallOnMapRef,
  UpdateCallback
} from './effects'
import { MouseEventForMap } from '../map-canvas'

interface IOLViewProps {
  ref?: any
  view?: View;
  interactions?: Collection<Interaction>;
  controls?: Collection<Control>;
  overlays: Collection<Overlay>;
  layers: Collection<BaseLayer | LayerGroup>;

  pixelRatio?: number;
  moveTolerance?: number;
  maxTilesLoading?: number;

  // General Events
  onMapRef?: (newVal: Map) => void;
  onChange?: (ev: Event) => void;
  onError?: (ev: Event) => void;

  // Mouse Events
  onClick?: (ev: Event) => void;
  onDblClick?: (ev: Event) => void;
  onSingleClick?: (ev: MouseEventForMap) => void;
  onContextMenu?: (ev: Event) => void;

  // Pointer Events
  onPointerCancel?: (ev: Event) => void;
  onPointerDown?: (ev: Event) => void;
  onPointerEnter?: (ev: Event) => void;
  onPointerLeave?: (ev: Event) => void;
  onPointerMove?: (ev: MouseEventForMap) => void;
  onPointerOut?: (ev: Event) => void;
  onPointerOver?: (ev: Event) => void;
  onPointerUp?: (ev: Event) => void;

  // Move Events
  onMoveStart?: (ev: Event) => void;
  onMoveEnd?: (ev: Event) => void;

  // Render Events
  onPreCompose?: (ev: Event) => void;
  onPostCompose?: (ev: Event) => void;
  onPreRender?: (ev: Event) => void;
  onPostRender?: (ev: Event) => void;
  onRenderComplete?: (ev: Event) => void;

  children?: typeof Children;
}

const OLView = ({
  view,
  interactions,
  controls,
  overlays,
  layers,

  pixelRatio,
  moveTolerance,
  maxTilesLoading,

  onMapRef,
  onChange,
  onError,

  onClick,
  onDblClick,
  onSingleClick,
  onContextMenu,

  onPointerCancel,
  onPointerDown,
  onPointerEnter,
  onPointerLeave,
  onPointerMove,
  onPointerOut,
  onPointerOver,
  onPointerUp,

  onMoveStart,
  onMoveEnd,

  onPreCompose,
  onPostCompose,
  onPreRender,
  onPostRender,
  onRenderComplete,

  children
}: IOLViewProps): JSX.Element => {
  // State /////////////////////////////////////////////////////////////////////

  const divRef = useRef<HTMLDivElement>()
  const mapRef = useRef<Map>()
  const callbacksRef = useRef<Record<string, Function>>({})

  const [divRefUUID, setDivRefUUID] = useState<string>()
  const [mapRefUUID, setMapRefUUID] = useState<string>()

  // Effects ///////////////////////////////////////////////////////////////////

  useIter(
    InitMap,

    { view,
      interactions,
      controls,
      overlays,
      layers,

      pixelRatio,
      moveTolerance,
      maxTilesLoading,

      divRef: divRef.current,
      mapRef,
      setMapRefUUID },

    [ view,
      interactions,
      controls,
      overlays,
      layers,

      pixelRatio,
      moveTolerance,
      maxTilesLoading,

      divRefUUID ]
  )

  useIter(
    UpdateView,

    { view,
      mapRef: mapRef.current },

    [ view,
      mapRefUUID ]
  )

  // General Events ////

  useIter(
    CallOnMapRef,

    { onMapRef,
      mapRef: mapRef.current },

    [ onMapRef,
      mapRefUUID ]
  )

  useIter(
    UpdateCallback,

    { event: 'change',
      callback: onChange,
      callbacksRef: callbacksRef.current,
      mapRef: mapRef.current },

    [ onChange,
      mapRefUUID ]
  )

  useIter(
    UpdateCallback,

    { event: 'error',
      callback: onError,
      callbacksRef: callbacksRef.current,
      mapRef: mapRef.current },

    [ onError,
      mapRefUUID ]
  )

  // Mouse Events ////

  useIter(
    UpdateCallback,

    { event: 'click',
      callback: onClick,
      callbacksRef: callbacksRef.current,
      mapRef: mapRef.current },

    [ onClick,
      mapRefUUID ]
  )

  useIter(
    UpdateCallback,

    { event: 'dblclick',
      callback: onDblClick,
      callbacksRef: callbacksRef.current,
      mapRef: mapRef.current },

    [ onDblClick,
      mapRefUUID ]
  )

  useIter(
    UpdateCallback,

    { event: 'singleclick',
      callback: onSingleClick,
      callbacksRef: callbacksRef.current,
      mapRef: mapRef.current },

    [ onSingleClick,
      mapRefUUID ]
  )

  useIter(
    UpdateCallback,

    { event: 'contextmenu',
      callback: onContextMenu,
      callbacksRef: callbacksRef.current,
      mapRef: mapRef.current },

    [ onContextMenu,
      mapRefUUID ]
  )

  // Pointer Events ////

  useIter(
    UpdateCallback,

    { event: 'pointerdown',
      callback: onPointerDown,
      callbacksRef: callbacksRef.current,
      mapRef: mapRef.current },

    [ onPointerDown,
      mapRefUUID ]
  )

  useIter(
    UpdateCallback,

    { event: 'pointermove',
      callback: onPointerMove,
      callbacksRef: callbacksRef.current,
      mapRef: mapRef.current },

    [ onPointerMove,
      mapRefUUID ]
  )

  // Move Events ////

  useIter(
    UpdateCallback,

    { event: 'movestart',
      callback: onMoveStart,
      callbacksRef: callbacksRef.current,
      mapRef: mapRef.current },

    [ onMoveStart,
      mapRefUUID ]
  )

  useIter(
    UpdateCallback,

    { event: 'moveend',
      callback: onMoveEnd,
      callbacksRef: callbacksRef.current,
      mapRef: mapRef.current },

    [ onMoveEnd,
      mapRefUUID ]
  )

  // Render Events ////

  useIter(
    UpdateCallback,

    { event: 'precompose',
      callback: onPreCompose,
      callbacksRef: callbacksRef.current,
      mapRef: mapRef.current },

    [ onPreCompose,
      mapRefUUID ]
  )

  useIter(
    UpdateCallback,

    { event: 'postcompose',
      callback: onPostCompose,
      callbacksRef: callbacksRef.current,
      mapRef: mapRef.current },

    [ onPostCompose,
      mapRefUUID ]
  )

  useIter(
    UpdateCallback,

    { event: 'prerender',
      callback: onPreRender,
      callbacksRef: callbacksRef.current,
      mapRef: mapRef.current },

    [ onPreRender,
      mapRefUUID ]
  )

  useIter(
    UpdateCallback,

    { event: 'postrender',
      callback: onPostRender,
      callbacksRef: callbacksRef.current,
      mapRef: mapRef.current },

    [ onPostRender,
      mapRefUUID ]
  )

  useIter(
    UpdateCallback,

    { event: 'rendercomplete',
      callback: onRenderComplete,
      callbacksRef: callbacksRef.current,
      mapRef: mapRef.current },

    [ onRenderComplete,
      mapRefUUID ]
  )

  // Callbacks /////////////////////////////////////////////////////////////////

  const $divRef = useCallback(
    (newVal: HTMLDivElement): void => {
      if (newVal && !divRef.current) {
        divRef.current = newVal
        setDivRefUUID(v4())
      }
    },
    [divRefUUID]
  )

  // Render ////////////////////////////////////////////////////////////////////

  return (
    <div
      ref={$divRef}
      className='OLView'
    >
      {children}
    </div>
  )
}

export default OLView

// @author Miras Absar <mabsar@iunu.com>

// Types
import IChart from 'map-view/interfaces/chart'

// Utils
import {
  ChartAccuracy,
  ChartName,
  ChartUnit
} from 'map-view/utils/chart'

// React
import React, {
  useCallback,
  useMemo
} from 'react'

interface IChartTileProps {
  outlined?: boolean;
  resting?: boolean
  raised?: boolean;
  animated?: boolean;

  selectedCharts: IChart[];
  chart: IChart;

  onClick: (newVal: IChart) => void;
}

const ChartTile = ({
  outlined,
  resting,
  raised,
  animated,

  selectedCharts,
  chart,

  onClick
}: IChartTileProps): JSX.Element => {
  // Memos /////////////////////////////////////////////////////////////////////

  const chartName = useMemo(
    () => ChartName[chart.chart],
    [chart.chart]
  )

  const chartUnit = useMemo(
    () => chart.dynamicUnit || ChartUnit[chart.chart],
    [chart.chart, chart.dynamicUnit]
  )

  const chartValue = useMemo(
    () => {
      const chartAccuracy = ChartAccuracy[chart.chart];
      const chartValue = (Array.isArray(chart.value)
        ? chart.value[chart.value.length - 1][1]
        : chart.value).toFixed(chartAccuracy)

      return chartValue
    },
    [chart]
  )

  const style = useMemo(
    () => {
      const isSelected = selectedCharts
        .find(_chart => _chart.chart === chart.chart)

      const style: { borderColor?: string, borderWidth?: string } = {}
      if (isSelected) {
        style.borderColor = chart.color
        style.borderWidth = '2px'
      }

      return style
    },

    [selectedCharts, chart.chart]
  )

  // Callbacks /////////////////////////////////////////////////////////////////

  const $click =
    (): void => onClick?.(chart)

  // Render ////////////////////////////////////////////////////////////////////

  let className = 'ChartTile'
  if (outlined) className += ' Outlined'
  if (resting) className += ' Resting'
  if (raised) className += ' Raised'
  if (animated) className += ' Animated'

  return (
    <div
      style={style}
      className={className}
      onClick={$click}
    >
      <div className='Name'>{chartName}</div>
      <div className='Value'>{chartValue} {chartUnit}</div>
    </div>
  )
}

export default ChartTile

import React from 'react'
import { random } from 'lodash'
import ContentLoader, { IContentLoaderProps } from 'react-content-loader'

// To create your own variation, you can use https://skeletonreact.com/

const LIGHT_BACKGROUND_COLOR = '#f3f3f3'
const LIGHT_FOREGROUND_COLOR = '#ecebeb'
const LINE_HEIGHT = 12
const LINES_SPACING = 4

export function MetricsLoader(props: IContentLoaderProps) {
  return (
    <SkeletonLoader width={80} height={28} {...props}>
      <rect x="0" y="0" rx="4" ry="4" width="80" height={LINE_HEIGHT} />
      <rect x="16" y="16" rx="4" ry="4" width="48" height={LINE_HEIGHT} />
    </SkeletonLoader>
  )
}

export function DetailItemLoader(
  props: IContentLoaderProps & { options?: { wrapped?: boolean; lines?: number; range?: [number, number] } }
) {
  const wrapped = props?.options?.wrapped ?? false
  const lines = Array.from(Array(props?.options?.lines ?? (wrapped ? 3 : 1)).keys())
  const range = props?.options?.range ?? (wrapped ? [150, 250] : [24, 144])
  const width = random(...range)
  const height = wrapped ? (LINE_HEIGHT + LINES_SPACING) * lines.length : LINE_HEIGHT

  return (
    <SkeletonLoader width={width} height={height} {...props}>
      {wrapped
        ? lines.map((line) => {
            const width = random(...range)
            const y = (LINE_HEIGHT + LINES_SPACING) * line

            return <rect key={line} x="0" y={y} rx="4" ry="4" width={width} height={LINE_HEIGHT} />
          })
        : null}
    </SkeletonLoader>
  )
}

export function SkeletonLoader(props: IContentLoaderProps) {
  const {
    speed = 2,
    width = 48,
    height = LINE_HEIGHT,
    foregroundColor = LIGHT_FOREGROUND_COLOR,
    backgroundColor = LIGHT_BACKGROUND_COLOR,
    radius = 4,
    children,
  } = props
  return (
    <ContentLoader
      speed={speed}
      width={width}
      height={height}
      viewBox={`0 0 ${width} ${height}`}
      backgroundColor={backgroundColor}
      foregroundColor={foregroundColor}
      {...props}
    >
      {children ?? <rect x="0" y="0" rx={radius} ry={radius} width={width} height={height} />}
    </ContentLoader>
  )
}

/**
 * @author Miras Absar <mabsar@iunu.com>
 */

import React, { useState } from 'react'
import Fit from 'react-fit'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSpinnerThird, faVideo } from '@fortawesome/pro-regular-svg-icons'
import AreacamTile from '../areacam-tile'
import IAreacam from 'graphql-lib/interfaces/IAreacam'

export interface IAreacamControlProps {
  outlined?: boolean;
  resting?: boolean;
  raised?: boolean;
  animated?: boolean;

  loading: boolean;
  disabled: boolean;
  areacams: any[];
  onSelect: (areacam: any) => void;
}

const AreacamControl = ({
  outlined,
  resting,
  raised,
  animated,

  loading,
  disabled,
  areacams,
  onSelect
}: IAreacamControlProps): JSX.Element => {
  let className = 'AreacamControl'
  if (outlined) className += ' Outlined'
  if (resting) className += ' Resting'
  if (raised) className += ' Raised'
  if (animated) className += ' Animated'
  if (disabled) className += ' Disabled'

  const [showAreacams, setShowAreacams] = useState(false)

  const $click = (): void => {
    if (!loading && !disabled && areacams) {
      setShowAreacams(!showAreacams)
    }
  }

  const $select = (areacam: IAreacam): void => {
    setShowAreacams(false)
    onSelect(areacam)
  }

  return (
    <div
      className={className}
      onClick={$click}
    >
      {loading
        ? <FontAwesomeIcon icon={faSpinnerThird} spin />
        : <FontAwesomeIcon icon={faVideo} />}

      {showAreacams && (
        <Fit invertAxis>
          <div className='Areacams'>
            {!loading && !disabled && areacams && areacams.map((areacam): JSX.Element => (
              <AreacamTile
                key={areacam.id}

                resting
                animated={animated}

                areacam={areacam}
                onClick={$select}
              />
            ))}
          </div>
        </Fit>
      )}
    </div>
  )
}

export default AreacamControl

// @author Miras Absar <mabsar@iunu.com>

// Types
import IChart from 'map-view/interfaces/chart'
import IChartGroup from 'map-view/interfaces/chart-group'
import IEntity from 'map-view/interfaces/entity'

// React
import React, {
  useContext,
  useRef,
  useState
} from 'react'

// React Libs
import Collapse from '@kunukn/react-collapse'
import { useIter } from 'react-lib/use-iter'

// Font Awesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faExclamationCircle,
  faSpinnerThird
} from '@fortawesome/pro-regular-svg-icons'

// Map View
import Mobius from 'map-view/contexts/mobius'

// Effects
import {
  UpdateSelectedCharts,
  UpdateVisibleCharts,
  UpdateVisibleChartGroups
} from './effects'

// Components
import ChartGroupTile from './chart-group-tile'
import ChartTile from './chart-tile'

interface ISensorChartsCarouselProps {
  outlined?: boolean;
  resting?: boolean
  raised?: boolean;
  animated?: boolean;
}

const SensorChartsCarousel = ({
  outlined,
  resting,
  raised,
  animated
}: ISensorChartsCarouselProps): JSX.Element => {
  // Context ///////////////////////////////////////////////////////////////////

  const {
    searchParams: {
      searchParams,
      setSearchParam,
      searchParamsUUID
    },

    selectedEntities: {
      selectedEntities,
      selectedEntitiesDiff,
      selectedEntitiesUUID
    },

    visibleEntities: {
      visibleEntities,
      visibleEntitiesUUID
    },

    inventoryStitches: {
      inventoryStitches,
      inventoryStitchesUUID
    },

    containerStitches: {
      containerStitches,
      containerStitchesUUID
    },

    spaceCharts: {
      spaceChartsLoading,
      spaceCharts,
      spaceChartsUUID
    },

    inventoryCharts: {
      inventoryChartsLoading,
      inventoryCharts,
      inventoryChartsUUID
    },

    containerCharts: {
      containerChartsLoading,
      containerCharts,
      containerChartsUUID
    }
  } = useContext(Mobius)

  // State /////////////////////////////////////////////////////////////////////

  const ref = useRef()

  const [selectedCharts, setSelectedCharts] = useState<IChart[]>()
  const [visibleSpaces, setVisibleSpaces] = useState<IEntity[]>()
  // const [visibleInventories, setVisibleInventories] = useState<IEntity[]>()
  // const [visibleContainers, setVisibleContainers] = useState<IEntity[]>()
  const [visibleCharts, setVisibleCharts] = useState<IChart[]>()
  const [visibleChartGroups, setVisibleChartGroups] = useState<IChartGroup[]>()

  const [selectedChartsUUID, setSelectedChartsUUID] = useState<string>()
  const [visibleSpacesUUID, setVisibleSpacesUUID] = useState<string>()
  // const [visibleInventoriesUUID, setVisibleInventoriesUUID] = useState<string>()
  // const [visibleContainersUUID, setVisibleContainersUUID] = useState<string>()
  const [visibleChartsUUID, setVisibleChartsUUID] = useState<string>()
  const [visibleChartGroupsUUID, setVisibleChartGroupsUUID] = useState<string>()

  // Effects ///////////////////////////////////////////////////////////////////

  useIter(
    UpdateSelectedCharts,

    {
      selectedEntities,
      selectedEntitiesDiff,
      inventoryStitches,
      containerStitches,
      spaceCharts,
      inventoryCharts,
      containerCharts,

      setSelectedCharts,
      setSelectedChartsUUID
    },

    [selectedEntitiesUUID,
      inventoryStitchesUUID,
      containerStitchesUUID,
      spaceChartsUUID,
      inventoryChartsUUID,
      containerChartsUUID]
  )

  useIter(
    UpdateVisibleCharts,

    {
      visibleEntities,
      spaceCharts,
      // inventoryCharts,
      // containerCharts,

      setVisibleSpaces,
      setVisibleCharts,
      setVisibleSpacesUUID,
      setVisibleChartsUUID
    },

    [visibleEntitiesUUID,
      spaceChartsUUID,
      inventoryChartsUUID,
      containerChartsUUID]
  )

  useIter(
    UpdateVisibleChartGroups,

    {
      visibleCharts,

      setVisibleChartGroups,
      setVisibleChartGroupsUUID
    },

    [visibleChartsUUID]
  )

  const isLoading =
    spaceChartsLoading ||
    inventoryChartsLoading ||
    containerChartsLoading

  const showVisibleChartGroups =
    !isLoading &&
    !!visibleSpaces &&
    visibleSpaces.length > 1 &&
    !!visibleChartGroups &&
    visibleChartGroups.length > 0

  const showVisibleCharts =
    !isLoading &&
    !!visibleSpaces &&
    visibleSpaces.length == 1 &&
    !!visibleCharts &&
    visibleCharts.length > 0

  const showSelectedCharts =
    !isLoading &&
    !!selectedCharts &&
    selectedCharts.length > 0

  let content = (
    <div className='Status'>
      <FontAwesomeIcon icon={faExclamationCircle}/>
      <div className='Status'>No Data</div>
    </div>
  )

  if (isLoading) {
    content = (
      <div className='Status'>
        <FontAwesomeIcon icon={faSpinnerThird} spin/>
        <div className='Status'>Loading</div>
      </div>
    )
  }

  if (showVisibleChartGroups) {
    content = (
      <>
        {visibleChartGroups.map(chartGroup => (
          <ChartGroupTile
            key={chartGroup.chart}

            resting
            animated={animated}

            chartGroup={chartGroup}
          />
        ))}
      </>
    )
  }

  if (showVisibleCharts) {
    content = (
      <>
        {visibleCharts.map(chart => (
          <ChartTile
            key={chart.chart}

            resting
            animated={animated}

            chart={chart}
          />
        ))}
      </>
    )
  }

  if (showSelectedCharts) {
    content = (
      <>
        {selectedCharts.map(chart => (
          <ChartTile
            key={chart.chart}

            resting
            animated={animated}

            chart={chart}
          />
        ))}
      </>
    )
  }

  if (
    searchParams.sensorChartsCarouselOpen &&
    ref.current
  ) {
    const computedStyle = getComputedStyle(ref.current)
    document.body.style.setProperty('--navigation-subtract-height', computedStyle.height)
  }

  // Render ////////////////////////////////////////////////////////////////////

  let className = 'Theme__Dark SensorChartsCarousel'
  if (outlined) className += ' Outlined'
  if (resting) className += ' Resting'
  if (raised) className += ' Raised'
  if (animated) className += ' Animated'

  return (
    <Collapse
      className='SensorChartsCarouselParent'
      isOpen={searchParams.sensorChartsCarouselOpen}
    >
      <div ref={ref} className={className}>
        {content}
      </div>
    </Collapse>
  )
}

export default SensorChartsCarousel

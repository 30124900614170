// Types
import IInputStatus from 'ui-lib/interfaces/IInputStatus'
interface IUpdateStatusProps<T, S, P> {
  validator: (val: T) => S;
  value: T;
  setStatus: (newVal: P) => void;
};

export const UpdateStatus = <T, >({
  validator,
  value,
  setStatus
}: IUpdateStatusProps<T, IInputStatus, IInputStatus>): void => {
  setStatus(validator(value));
}

export const UpdateStatusAsync = async <T, > ({
  validator,
  value,
  setStatus
}: IUpdateStatusProps<T, Promise<IInputStatus>, IInputStatus>): Promise<void> => {
  setStatus(await validator(value));
}

/**
 * @author Miras Absar <mabsar@iunu.com>
 */


import { v4 } from 'uuid'
import m from 'moment-timezone'
import React, {
  useEffect,
  useState
} from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimes } from '@fortawesome/pro-regular-svg-icons'
import PinchZoomPan from 'react-responsive-pinch-zoom-pan'
import TimeTravelControl from '../time-travel-control'

import GoTo from 'react-lib/go-to'
import { Space } from '../../utils/routes'

import { GetItem, EStorage } from 'utils/storage'
import Magnifier from 'react-magnifier'
import AspectRatio from 'ui-lib/components/aspect-ratio'
import useDevice from 'react-lib/use-device'
import { Moment } from 'moment'

export interface IAreacamCardProps {
  outlined?: boolean;
  resting?: boolean;
  raised?: boolean;
  animated?: boolean;

  timestamp: Moment;
  x: number | string;
  y: number | string;
  space: any;
  areacam: any;

  show: boolean;
}

const AreacamCard = ({
  outlined,
  resting,
  raised,
  animated,

  timestamp,
  x,
  y,
  space,
  areacam,

  show
}: IAreacamCardProps) => {
  // const { desktop } = useDevice()
  const desktop = false
  let className = 'Card AreacamCard'
  if (outlined) className += ' Outlined'
  if (resting) className += ' Resting'
  if (raised) className += ' Raised'
  if (animated) className += ' Animated'

  const [uuid, setUUID] = useState<string>()
  useEffect((): void => {
    setTimeout((): void => {
      setUUID(v4())
    }, 200)
  }, [areacam, areacam?.id, show])

  const { areaImageRecord } = areacam
  const $close = () => {
    const _timestamp = timestamp.toISOString()

    const to = Space
      .replace(':id', space.id)
      .replace(':timestamp?', _timestamp)
      .replace(':x?', (x as string) || '')
      .replace(':y?', (y as string) || '')
      .replace(/\/+$/, '')

    GoTo(to)
  }

  const $timeTravel = (time: Moment) => {
    if (time.isSame(timestamp)) {
      return
    }

    const to = Space
      .replace(':id', space.id)
      .replace(':timestamp?', time.toISOString())
      .replace(':x?', (x as string) || '')
      .replace(':y?', (y as string) || '')
      .replace(/\/+$/, '')
    GoTo(`${to}?areacamId=${areacam.id}`)
  }

  const facilities = GetItem('facilities', EStorage.EphemeralStorage)
  const timezone = facilities[0].timezone

  return (
    <article className={className}>
      <div className='Head'>
        <div className='Row'>
          <button onClick={$close}>
            <FontAwesomeIcon icon={faTimes} />
          </button>

          <div className='Space' />

          <div className='Metadata'>
            <p>{areacam.code.toUpperCase()}</p>
            <p>
              {
                areaImageRecord[0]
                  ? m.tz(areaImageRecord[0].createdOn, timezone).format('lll z')
                  : 'No Image'
              }
            </p>
          </div>
        </div>
      </div>

      <div className='Image'>
        {
          !desktop
            ? <PinchZoomPan
              key={uuid}
              maxScale={10}
              position='center'
              zoomButtons={false}
            >
              <img
                key={uuid}
                src={areaImageRecord[0] && areaImageRecord[0].url}
              />
            </PinchZoomPan>
            :
            <AspectRatio
              defaultWidth={3280}
              defaultHeight={2464}
              imgSrc={areaImageRecord[0] && areaImageRecord[0].url}
            >
              <Magnifier
                src={areaImageRecord[0] && areaImageRecord[0].url}
                zoomFactor={2.5}
                mgWidth={150}
                mgHeight={150}
              />
            </AspectRatio>
        }
      </div>

      <div className='AC_Navigation'>
        <TimeTravelControl
          outlined
          animated

          time={timestamp}
          onTimeTravel={$timeTravel}
        />
      </div>
    </article>
  )
}

export default AreacamCard

const prefix = 'iunu'
const iconName = 'logo-2'

const width = 169
const height = 30
const ligatures: [] = []
const unicode: string = null
const svgPathData = `
M35.0512245 23.3805741 35.0512245 28.537963 0 28.537963 0 0.332314815 6.32469388 0.332314815 6.32469388 23.3805741 35.0512245 23.3805741 z
M67.3345714,21.2448519 C68.7216327,19.8751667 69.3336327,18.1416481 69.3336327,15.5235556 L69.3336327,0.332314815 L75.6583265,0.332314815 L75.6583265,16.1278889 C75.6583265,19.9957593 74.4343265,22.6152222 72.312449,24.7098333 C69.7416327,27.2491296 64.8858776,29.1827222 55.255551,29.1827222 C45.6259184,29.1827222 40.7701633,27.2491296 38.2000408,24.7098333 C36.0781633,22.6152222 34.8541633,19.9957593 34.8541633,16.1278889 L34.8541633,0.332314815 L41.1781633,0.332314815 L41.1781633,15.5235556 C41.1781633,18.1416481 41.7908571,19.8751667 43.1779184,21.2448519 C44.8917959,22.9365741 48.6047347,24.1452407 55.255551,24.1452407 C61.9070612,24.1452407 65.6206939,22.9365741 67.3345714,21.2448519 z
M114.308 23.0975926 114.308 0.332314815 120.592449 0.332314815 120.592449 28.537963 111.737184 28.537963 85.623102 5.3697963 85.623102 28.537963 79.3386531 28.537963 79.3386531 0.332314815 88.6421633 0.332314815 114.308 23.0975926 z
M149.122612 0.171296296 140.553224 0.171296296 120.681265 28.537963 127.659592 28.537963 132.229469 21.8896111 135.657224 16.8932407 144.674857 3.71712963 153.733429 16.8932407 157.161184 21.8896111 161.731061 28.537963 168.993878 28.537963 149.122612 0.171296296 z
`
export default {
  prefix,
  iconName,
  icon: [
    width,
    height,
    ligatures,
    unicode,
    svgPathData
  ]
}

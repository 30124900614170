import {
  AxisLabelsFormatterContextObject,
  AxisOptions,
  Options as HighChartsOptions,
  SeriesOptionsType as HighChartsSeriesOptions,
  TooltipFormatterContextObject,
  XAxisOptions
} from 'highcharts';

// Types
import EEntity from 'graphql-lib/enums/entity';
import IEntity from 'graphql-lib/interfaces/entity';
import ICrop from 'graphql-lib/interfaces/ICrop';
import IInventory from 'graphql-lib/interfaces/IInventory';
import getChartColor from 'utils/chose-chart-entity-color';
import IChart, { IChartValues } from 'map-view/interfaces/chart';
import ID from 'graphql-lib/interfaces/ID';
import ISetStateType from 'graphql-lib/interfaces/ISetStateType';

// Utils
import {
  ChartName,
  ChartAccuracy,
  ChartUnit,
  ChartType
} from 'utils/chart';

const MS_PER_DAY = 86400000;
const MS_PER_HOUR = 3600000;
const MS_PER_MINUTE = 60000;
export interface CoordinateAxisType {
  ordinal?: boolean,
  type?: string,
  title?: { text: string }
  custom?: {
    chartKey?: string,
    accuracy?: number
  }
  id?: string
  labels?: {
    axis?: {
      options: {
        custom: {
          chartKey?: string,
          accuracy?: number
        }
      }
      value: number
    },
    value: number,
    reserveSpace?: boolean,
    formatter: (valueParam: number) => string
  },
  visible?: boolean
}

interface PointType {
  y: number
  x: number
  series: {
    options: {
      custom: {
        cropName: string
        inventoryCode: string
        chartKey: string
      }
    }
  }
}

const UpdateOptions = ({
  relativeCharting,
  selectedEntities,
  cropIDs,

  crops,
  inventories,

  cropsCache,
  inventoriesCache,
  inventoryChartsCache,

  setOptions
}: {
  relativeCharting?: boolean
  selectedEntities?: Array<IEntity>
  cropIDs?: Array<ID>
  crops: Array<ICrop>
  inventories: Array<IInventory>
  cropsCache?: Record<ID, ICrop>
  inventoriesCache?: Record<ID, Array<IInventory>>
  inventoryChartsCache?: Record<ID, IChart>
  setOptions: ISetStateType<HighChartsOptions>
}): void => {
  if (
    selectedEntities &&
    cropIDs &&
    crops &&
    inventories &&
    cropsCache &&
    inventoriesCache &&
    inventoryChartsCache
  ) {
    const selectedInventories = inventories
      .filter((inventory): boolean => !!selectedEntities
        .find((selectedEntity): boolean =>
          selectedEntity.type === EEntity.Inventory &&
          Number(selectedEntity.id) === Number(inventory.id)))

    const selectedInventorySeries: Array<HighChartsSeriesOptions> = selectedInventories
      .reduce(
        (selectedInventorySeries, inventory) => {
          const inventorySeries = Object.entries(inventoryChartsCache[inventory.id])
            .filter(([chartKey]): boolean => !!selectedEntities
              .find((selectedEntity): boolean =>
                selectedEntity.type === EEntity.Chart &&
                Number(selectedEntity.metadata?.inventoryID) === Number(inventory.id) &&
                selectedEntity.metadata?.chartKey === chartKey))
            .map(([chartKey, chartValues]): any => {
              const data = chartValues
                .sort((a: IChart, b: IChart): number =>
                  Number(new Date(a.createdOn)) - Number(new Date(b.createdOn)))
                .map((v: IChart): [Date, IChartValues] =>
                  relativeCharting
                    ? [v.relativeCreatedOn, v.value]
                    : [v.createdOn, v.value])

              const inventorySeries = {
                custom: {
                  cropID: inventory.cropId,
                  inventoryID: inventory.id,
                  chartKey,

                  cropName: cropsCache[inventory.cropId].name,
                  inventoryCode: inventory.code
                },
                id: `series:${inventory.id}:${chartKey}`,

                type: ChartType[chartKey],
                color: getChartColor(selectedEntities, inventory.id, chartKey),
                data,
                yAxis: `y-axis:${chartKey}`
              }

              return inventorySeries
            })

          return [...selectedInventorySeries, ...inventorySeries]
        },
        []
      )

    let xAxis: Pick<XAxisOptions, 'ordinal' | 'type' | 'labels' | 'title'>;
    if (relativeCharting) {
      xAxis = {
        ordinal: false,
        type: 'linear',
        labels: {
          formatter: function (): string {
            const value: number = Number(this.value);
            const day = Math.floor(value / MS_PER_DAY);
            const hour = Math.floor(value % MS_PER_DAY / MS_PER_HOUR);
            const minute = Math.floor(value % MS_PER_DAY % MS_PER_HOUR / MS_PER_MINUTE).toString().padStart(2, '0');
            return `Day ${day} ${hour}:${minute}`;
          }
        },
        title: { text: 'Day' }
      }
    } else {
      xAxis = {
        ordinal: false,
        type: 'datetime',
        title: { text: 'Time' }
      }
    }

    const yAxis = Object.keys(ChartName)
      .map((chartKey) => {
        const id = `y-axis:${chartKey}`
        const visible = !!selectedInventorySeries
          .find((_): boolean => _.yAxis === id) &&
          selectedInventorySeries.length <= 4

        const yAxis = {
          custom: { chartKey },
          id,
          labels: {
            axis: {
              options: {
                custom: {
                  chartKey
                }
              }
            },
            formatter: function (this: AxisLabelsFormatterContextObject): string {
              const key = (this.axis.options as AxisOptions & { custom: { chartKey: string } }).custom.chartKey
              const accuracy = ChartAccuracy[key]
              const unit = ChartUnit[key]
              const value = Number(this.value).toFixed(accuracy)
              return `${value} ${unit}`
            }
          },
          visible
        }

        return yAxis
      })

    const newOptions: HighChartsOptions = {
      boost: {
        seriesThreshold: 1,
        useGPUTranslations: true,
        usePreallocated: true
      },
      navigator: { xAxis },
      rangeSelector: { enabled: false },
      series: selectedInventorySeries,
      title: { text: null as string },
      tooltip: {
        formatter: function (this: TooltipFormatterContextObject) {
          const tooltips = []
          const day = Math.floor(this.x / MS_PER_DAY)
          const hour = Math.floor(this.x % MS_PER_DAY / MS_PER_HOUR)
          const minute: string = Math.floor(this.x % MS_PER_DAY % MS_PER_HOUR / MS_PER_MINUTE).toString().padStart(2, '0')
          tooltips.push(`Day ${day} ${hour}:${minute}`);
          const points: Array<TooltipFormatterContextObject> = this.points;
          points.forEach((point): void => {
            const chartKey = point.series.options.custom.chartKey
            const cropName = point.series.options.custom.cropName
            const inventoryCode = point.series.options.custom.inventoryCode
            const seriesName = ChartName[chartKey]
            const chartAccuracy = ChartAccuracy[chartKey]
            const pointValue = point.y.toFixed(chartAccuracy)
            const seriesUnit = ChartUnit[chartKey]

            tooltips.push(`
              <div>
                <p><b>${cropName} — ${inventoryCode}</b></p>
                <br>
                <p><b>${seriesName}</b>: ${pointValue} ${seriesUnit}</p>
              </div>
            `)
          })

          return tooltips
        },
        split: true
      },
      xAxis,
      yAxis
    }

    setOptions(newOptions)
  }
}

export {
  UpdateOptions
}

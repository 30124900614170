import { GetItem, EStorage } from 'utils/storage';
import { isNil } from 'utils/ts-utils';
import EColor from 'ui-lib/enums/color';
import IFilter from 'arrayview/interfaces/IFilter';
import ITaskIssue from 'graphql-lib/interfaces/ITaskIssue';

export const All: IFilter<ITaskIssue> = Object.freeze({
  id: 'task-issue:filter:all',
  icon: undefined,
  name: 'All',
  color: EColor.None,
  func: (filter: IFilter<ITaskIssue>, taskIssue: ITaskIssue): boolean => true
})

export const Alert: IFilter<ITaskIssue> = Object.freeze({
  id: 'task-issue:filter:alert',
  icon: undefined,
  name: 'Alert',
  color: EColor.None,
  func: (filter: IFilter<ITaskIssue>, taskIssue: ITaskIssue): boolean => taskIssue.customerTaskCategory[0].title === 'LUNA Alert'
});

export const CreatedByMe: IFilter<ITaskIssue> = Object.freeze({
  id: 'task-issue:filter:created-by-me',
  icon: undefined,
  name: 'Created By Me',
  color: EColor.None,
  func: (filter: IFilter<ITaskIssue>, taskIssue: ITaskIssue): boolean => {
    if (taskIssue.createdBy) {
      const userId = GetItem('userID', EStorage.LocalStorage)
      return taskIssue.createdBy === userId
    }

    return false
  }
});

export const Pending: IFilter<ITaskIssue> = Object.freeze({
  id: 'task-issue:filter:pending',
  icon: undefined,
  name: 'Pending',
  color: EColor.None,
  func: (filter: IFilter<ITaskIssue>, taskIssue: ITaskIssue): boolean => isNil(taskIssue.rejectedAt) && isNil(taskIssue.taskId)
});

export const ConvertedToTask: IFilter<ITaskIssue> = Object.freeze({
  id: 'task-issue:filter:convertedToTask',
  icon: undefined,
  name: 'Converted To Task',
  color: EColor.None,
  func: (filter: IFilter<ITaskIssue>, taskIssue: ITaskIssue): boolean => !isNil(taskIssue.taskId)
});

export const Rejected: IFilter<ITaskIssue> = Object.freeze({
  id: 'task-issue:filter:rejected',
  icon: undefined,
  name: 'Rejected',
  color: EColor.None,
  func: (filter: IFilter<ITaskIssue>, taskIssue: ITaskIssue): boolean => !isNil(taskIssue.rejectedAt)
});

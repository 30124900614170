// Types
import ICustomer from 'graphql-lib/interfaces/ICustomer'

// Libs
import { v4 } from 'uuid'

// GraphQL
import client from 'graphql-lib/index'
import { FetchResult, gql } from '@apollo/client'

import ID from 'graphql-lib/interfaces/ID';
import ISetStateType from 'graphql-lib/interfaces/ISetStateType';

const Init = ({
  hookID
}: {
  hookID: { current: string };
}): void => {
  hookID.current = `hook:useCustomer:${v4()}`
}

const QueryCustomer = async ({
  id,
  columns,

  setLoading,
  setError,
  setCustomer,
  setUUID
}: {
  id: ID;
  columns: string[];

  setLoading: ISetStateType<boolean>
  setError: ISetStateType<Error>
  setCustomer: ISetStateType<ICustomer>
  setUUID:ISetStateType<string>
}): Promise<void> => {
  if (id && columns) {
    const query = gql`
      query($id: ID) {
        customer(id: $id) {
          ${columns.join(' ')}
        }
      } 
    `

    setLoading(true)
    setUUID(v4())

    let error: Error
    let data: { customer: Array<ICustomer>}
    try {
      const response: FetchResult<typeof data> = await client.query({
        fetchPolicy: 'no-cache',
        query,
        variables: { id }
      })

      data = response.data
    } catch (err) {
      error = err as Error
    }

    setLoading(false)
    if (error) {
      setError(error)
      setCustomer(undefined)
      setUUID(v4())
    } else if (data) {
      setError(undefined)
      setCustomer(data.customer[0])
      setUUID(v4())
    }
  }
}

export {
  Init,
  QueryCustomer
}

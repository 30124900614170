/**
 * @author Miras Absar <mabsar@iunu.com>
 */

type ColumnTitles =
  | 'Area'
  | 'Code'
  | 'Container Type'
  | 'Containers per Group'
  | 'Description'
  | 'End Date'
  | 'Name'
  | 'Product Code'
  | 'Start Date'
  | 'Sub Zone'
  | 'Transplant Date'
  | 'Zone';

const Columns: Record<ColumnTitles, string> = {
  'Product Code': 'productCode',
  'Area': 'areaName',
  'Zone': 'zoneName',
  'Sub Zone': 'subZoneName',
  'Name': 'name',
  'Code': 'code',
  'Container Type': 'containerSize',
  'Containers per Group': 'containersPerLocator',
  'Start Date': 'startDate',
  'Transplant Date': 'transplantDate',
  'End Date': 'endDate',
  'Description': 'description'
}

export default Columns

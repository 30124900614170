/**
 * @author Miras Absar <mabsar@iunu.com>
 */

// Types
import EEntity from 'graphql-lib/enums/entity'
import IEntity from 'graphql-lib/interfaces/entity'
import IInventory from 'graphql-lib/interfaces/IInventory'

const UpdateSelected = ({
  inventory,
  chart,
  selectedEntities,

  setSelected
}: {
  inventory: IInventory;
  chart: [string, number[][]];
  selectedEntities: IEntity[];

  setSelected: (newVal: boolean) => void;
}): void => {
  if (inventory && chart && selectedEntities) {
    const newSelected = !!selectedEntities.find((e): boolean =>
      e.type == EEntity.Chart &&
      e.metadata?.inventoryID == inventory.id &&
      e.metadata?.chartKey == chart[0])

    setSelected(newSelected)
  }
}

export { UpdateSelected }

import {ApolloQueryResult, gql} from '@apollo/client';
import client from 'graphql-lib/index';
import ILocator from 'graphql-lib/interfaces/ILocator';

import EInputStatus from 'ui-lib/enums/input-status';
import IInputCore from 'ui-lib/interfaces/IInputCore';
import IInputStatus from 'ui-lib/interfaces/IInputStatus';

const queryLocator = gql`
  query($serial: String) {
    locator(serial: $serial) {
      id
      serial

      inventoryLocator {
        id

        inventory {
          id
          code

          crop {
            id

            code
            name

            archived
            endDate
            enabled
          }
        }
      }
    }
  }
`;

const TagInputCore: IInputCore<string, string> = {
  getValue: (uiValue: string): string => uiValue,
  getUIValue: (value: string): string => value,
  getStatus: async (value: string): Promise<IInputStatus> => {
    if (value && value.length === 6) {
      const res: ApolloQueryResult<{locator: Array<ILocator>}> = await client.query({
        query: queryLocator,
        variables: { serial: value },
        fetchPolicy: 'no-cache'
      });

      if (res.data?.locator?.length === 0) {
        return {
          status: EInputStatus.Error,
          message: 'Invalid'
        };
      } else if (res.data?.locator?.length > 0) {
        const [locator] = res.data.locator;
        const [inventoryLocator] = locator.inventoryLocator;

        if (inventoryLocator?.inventory?.length > 0) {
          const [inventory] = inventoryLocator.inventory
          const [crop] = inventory.crop

          return {
            status: EInputStatus.Warning,
            message: 'Tag is already provisioned',
            meta: {
              locator,
              inventoryLocator,
              inventory,
              crop
            }
          };
        }
      } else {
        return {
          status: EInputStatus.OK,
          message: 'Valid'
        };
      }
    }

    return {
      status: EInputStatus.Error,
      message: 'Required',
    };
  },
};

export default Object.freeze(TagInputCore);

/**
 * @author Miras Absar <mabsar@iunu.com>
 */

// Types
import EEntity from 'map-view/enums/entity'
import EChart from 'map-view/enums/chart'

// Utils
import Has from 'utils/has'
import { ChartOrder } from 'map-view/utils/chart'

// Libs
import { format } from 'date-fns'
import { createDate } from 'utils/dates'
import IEntity from 'map-view/interfaces/entity'
import ISpace from 'graphql-lib/interfaces/ISpace'
import IChart from 'map-view/interfaces/chart'
import IInventory from 'graphql-lib/interfaces/IInventory'
import IInventoryStitch from 'graphql-lib/interfaces/IInventoryStitch'

const DateFormat = 'M/d/yy'

const UpdateState = ({
  selectedEntities,
  spaces,
  spaceCharts,
  inventory,
  inventoryStitches,
  inventoryCharts,

  setSelected,
  setSeen,
  setTag,
  setCharts
}: {
  selectedEntities: Array<IEntity>
  spaces: Array<ISpace>
  spaceCharts: Array<IChart>
  inventory: IInventory
  inventoryStitches: Array<IInventoryStitch>
  inventoryCharts: Array<IChart>
  setSelected: (value: boolean) => void
  setSeen: (value: string) => void
  setTag: (value: string) => void
  setCharts: (value: Array<IChart>) => void
}): void => {
  if (
    !selectedEntities ||
    !spaces ||
    !spaceCharts ||
    !inventory ||
    !inventoryStitches ||
    !inventoryCharts
  ) {
    return
  }

  const newSelected = !!selectedEntities
    .find(entity =>
      entity.type === EEntity.Inventory &&
      String(entity.id) === String(inventory.id))

  const inventoryStitch = inventoryStitches
    .find(inventoryStitch =>
      String(inventoryStitch.inventoryId) === String(inventory.id) &&
      Has(inventoryStitch.width) &&
      Has(inventoryStitch.height) &&
      Has(inventoryStitch.tilesUrl))

  const space = spaces
    .find(space => String(space.id) === String(inventoryStitch?.spaceId))

  let newSeen = 'Never Seen'
  let newTag = 'No Tag'
  if (
    inventoryStitch &&
    space
  ) {
    newSeen =
      `Last Seen ${format(createDate(inventoryStitch.lastImageCreatedOn), DateFormat)} - ${space.name}`;

    newTag = inventoryStitch.locatorCode
  }

  const newInventoryCharts = inventoryCharts
    .filter(chart =>
      chart.parentType === EEntity.Inventory &&
      String(chart.parentID) === String(inventory.id))
    .sort((chartA, chartB) =>
      ChartOrder[chartA.chart] - ChartOrder[chartB.chart])

  const newSpaceCharts = spaceCharts
    .filter(chart =>
      chart.parentType === EEntity.Space &&
      String(chart.parentID) === String(space?.id))
    .sort((chartA, chartB) =>
      ChartOrder[chartA.chart] - ChartOrder[chartB.chart])

  const newCharts = [
    ...newInventoryCharts,
    ...newSpaceCharts
  ]

  setSelected(newSelected)
  setSeen(newSeen)
  setTag(newTag)
  setCharts(newCharts)
}

export { UpdateState }

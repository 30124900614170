/**
 * @author Miras Absar <mabsar@iunu.com>
 * @file ...
 */

type Point = number[]
type Vector = number[]
type Matrix = number[]

class ProjectiveTransformation {
  private sourcePoints: Point[]
  private destinationPoints: Point[]

  private matrix: Matrix;
  private inverseMatrix: Matrix;

  private static AdjugateMatrix (matrix: Matrix): Matrix {
    return [
      matrix[4] * matrix[8] - matrix[5] * matrix[7],
      matrix[2] * matrix[7] - matrix[1] * matrix[8],
      matrix[1] * matrix[5] - matrix[2] * matrix[4],
      matrix[5] * matrix[6] - matrix[3] * matrix[8],
      matrix[0] * matrix[8] - matrix[2] * matrix[6],
      matrix[2] * matrix[3] - matrix[0] * matrix[5],
      matrix[3] * matrix[7] - matrix[4] * matrix[6],
      matrix[1] * matrix[6] - matrix[0] * matrix[7],
      matrix[0] * matrix[4] - matrix[1] * matrix[3]
    ]
  }

  private static MultiplyMatrices (a: Matrix, b: Matrix): Matrix {
    const c = []

    for (let i = 0; i !== 3; i++) {
      for (let j = 0; j !== 3; j++) {
        let acc = 0

        for (let k = 0; k !== 3; k++) {
          acc += a[3 * i + k] * b[3 * k + j]
        }

        c[3 * i + j] = acc
      }
    }

    return c
  }

  private static MultiplyMatrixVector (matrix: Matrix, vector: Vector): Matrix {
    return [
      matrix[0] * vector[0] + matrix[1] * vector[1] + matrix[2] * vector[2],
      matrix[3] * vector[0] + matrix[4] * vector[1] + matrix[5] * vector[2],
      matrix[6] * vector[0] + matrix[7] * vector[1] + matrix[8] * vector[2]
    ]
  }

  private static Points2Matrix (points: Point[]): Matrix {
    const matrix1 = [
      points[0][0], points[1][0], points[2][0],
      points[0][1], points[1][1], points[2][1],
      1, 1, 1
    ]

    const basisVector = ProjectiveTransformation.MultiplyMatrixVector(
      ProjectiveTransformation.AdjugateMatrix(matrix1),
      [points[3][0], points[3][1], 1]
    )

    const matrix2 = ProjectiveTransformation.MultiplyMatrices(
      matrix1,
      [
        basisVector[0], 0, 0,
        0, basisVector[1], 0,
        0, 0, basisVector[2]
      ]
    )

    return matrix2
  }

  private static Project (matrix: Matrix, point: Point): Point {
    const vector = ProjectiveTransformation.MultiplyMatrixVector(
      matrix,
      [point[0], point[1], 1]
    )

    return [
      vector[0] / vector[2],
      vector[1] / vector[2]
    ]
  }

  public constructor (sourcePoints: Point[], destinationPoints: Point[]) {
    this.sourcePoints = sourcePoints
    this.destinationPoints = destinationPoints

    const sourceMatrix = ProjectiveTransformation.Points2Matrix(sourcePoints)
    const destinationMatrix =
      ProjectiveTransformation.Points2Matrix(destinationPoints)

    this.matrix = ProjectiveTransformation.MultiplyMatrices(
      destinationMatrix,
      ProjectiveTransformation.AdjugateMatrix(sourceMatrix)
    )

    this.inverseMatrix = ProjectiveTransformation.MultiplyMatrices(
      sourceMatrix,
      ProjectiveTransformation.AdjugateMatrix(destinationMatrix)
    )
  }

  public transform (point: Point): Point {
    return ProjectiveTransformation.Project(this.matrix, point)
  }

  public inverseTransform (point: Point): Point {
    return ProjectiveTransformation.Project(this.inverseMatrix, point)
  }
}

export default ProjectiveTransformation

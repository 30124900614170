/**
 * @author Miras Absar <mabsar@iunu.com>
 */

// Types
import ICrop from 'graphql-lib/interfaces/ICrop'

// Utils
import has from 'utils/has'
import {
  EStorage,
  GetItem
} from 'utils/storage'

// Libs
import {
  format,
  utcToZonedTime
} from 'date-fns-tz'

const DateFormat = 'M/d/yy'
const SetDates = ({
  crop,

  setStartDate,
  setTransplantDate,
  setEndDate
}: {
  crop: ICrop;

  setStartDate: (newVal: string) => void;
  setTransplantDate: (newVal: string) => void;
  setEndDate: (newVal: string) => void;
}): void => {
  const [facility] = GetItem('facilities', EStorage.EphemeralStorage)

  if (
    facility &&
    has(facility.timezone) &&
    crop
  ) {
    const timeZone = { timeZone: facility.timezone }

    const newStartDate = format(
      utcToZonedTime(crop.startDate, facility.timezone),
      DateFormat,
      timeZone
    )

    const newTransplantDate = crop.transplantDate
      ? format(
        utcToZonedTime(crop.transplantDate, facility.timezone),
        DateFormat,
        timeZone
      )
      : null

    const newEndDate = format(
      utcToZonedTime(crop.endDate, facility.timezone),
      DateFormat,
      timeZone
    )

    setStartDate(newStartDate)
    setTransplantDate(newTransplantDate)
    setEndDate(newEndDate)
  }
}

export { SetDates }

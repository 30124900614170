/**
 * @author Miras Absar <mabsar@iunu.com>
 */

// React
import React, {
  useCallback
} from 'react'

// Font Awesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faExpand } from '@fortawesome/pro-regular-svg-icons'

interface IResetZoomProps {
  outlined?: boolean;
  resting?: boolean
  raised?: boolean;
  animated?: boolean;
}

const ResetZoom = ({
  outlined,
  resting,
  raised,
  animated
}: IResetZoomProps): JSX.Element => {
  // Callbacks /////////////////////////////////////////////////////////////////

  const $click = useCallback(
    (): boolean => window.dispatchEvent(new Event('MapCanvas:ResetZoom')),
    []
  )

  // Render ////////////////////////////////////////////////////////////////////

  let className = 'ResetZoom'

  if (outlined) className += ' Outlined'
  if (resting) className += ' Resting'
  if (raised) className += ' Raised'
  if (animated) className += ' Animated'

  return (
    <button
      className={className}
      onClick={$click}
    >
      <FontAwesomeIcon icon={faExpand} />
    </button>
  )
}

export default ResetZoom

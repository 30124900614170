import { ISensorRecord, ISensorRecordByType } from 'graphql-lib/interfaces/ISensorDevice'
import IGreenAutomationWaterSystemRecord from 'graphql-lib/interfaces/IGreenAutomationWaterSystemRecord'
import ISpace from 'graphql-lib/interfaces/ISpace'
import IChart from 'map-view/interfaces/chart'
import EChart from 'map-view/enums/chart'
import { SensorCharts, WaterSystemCharts } from 'map-view/utils/chart'
import { OperationVariables, QueryResult } from '@apollo/client'

/**
 * Updates a space with new space sensors
 * to be used when creating charts
 */
const buildSpaceSensor = (space: ISpace, sensorRecords?: ISensorRecord[]) => {
  space.sensor ??= []
  if (sensorRecords?.length) {
    const spaceSensor = {
      sensorRecord: sensorRecords,
      spaceId: space.id,
    }
    space.sensor.push(spaceSensor)
  }
}

interface ICanBuildChartProps {
  chart: IChart
  sensorRecords?: ISensorRecord[]
  CO2sensorRecords?: ISensorRecord[]
  waterSystemRecords?: IGreenAutomationWaterSystemRecord[]
}
/**
 * Determines if a chart can be successfully
 * built from the sensor record data
 * @returns boolean
 */
const canBuildChart = ({
  chart,
  sensorRecords,
  CO2sensorRecords,
  waterSystemRecords
}: ICanBuildChartProps) => {
  if (chart.chart === EChart.CO2) {
    return CO2sensorRecords?.length > 0
  }

  if (SensorCharts.includes(chart.chart)) {
    return sensorRecords?.length > 0
  }

  if (WaterSystemCharts.includes(chart.chart)) {
    return waterSystemRecords?.length > 0
  }

  return false
}

/**
 * This filters out sensor records that
 * do not match the correct space ID
 * @returns sensorRecords ISensorRecord[]
 */
const filterSensorRecords = (space: ISpace, sensorRecordData: QueryResult<ISensorRecordByType>) => {
  const data = sensorRecordData?.data
  const sensorRecordResult = data?.sensorRecord || data?.co2SensorRecord
  const sensorRecords = sensorRecordResult
    ?.filter((record: ISensorRecord) => String(record.spaceId) === String(space.id))

  return sensorRecords
}

/**
 * This filters out water system records that are not associated
 * with the given space (via vendorCustomerFacilityId)
 * @returns waterSystemRecords IGreenAutomationWaterSystemRecord[]
 */
const filterWaterSystemRecords = (space: ISpace, waterSystemRecordData: QueryResult<{ greenAutomationWaterSystemRecord: Array<IGreenAutomationWaterSystemRecord>}, OperationVariables>) => {
  const waterSystemRecords = waterSystemRecordData?.data?.greenAutomationWaterSystemRecord || []
  const spaceWaterSystemRecords = waterSystemRecords
    ?.filter((record: IGreenAutomationWaterSystemRecord): boolean =>
      space.vendorCustomerFacilityIds?.includes(record.vendorCustomerFacilityId)
    )

  return spaceWaterSystemRecords
}

export {
  buildSpaceSensor,
  canBuildChart,
  filterSensorRecords,
  filterWaterSystemRecords
}

// Types
import EEntity from 'map-view/enums/entity'
import IChart from 'map-view/interfaces/chart'

// React
import React, {
  MouseEvent,
  useCallback,
  useContext,
  useEffect,
  useState
} from 'react'

// React Lib
import { useIter } from 'react-lib/use-iter'

// Map View
import Mobius from 'map-view/contexts/mobius'

// Effects
import {
  UpdateSelected,
  UpdateState
} from './effects'
import { OperationVariables, useLazyQuery } from '@apollo/client'
import {
  QuerySensorRecords,
  QueryCO2SensorRecords,
  QueryGreenAutomationWaterSystemRecords
} from 'map-view/components/mobius/queries'
import { NewChart, SensorCharts, WaterSystemCharts } from 'map-view/utils/chart'
import ISpace from 'graphql-lib/interfaces/ISpace'

// Utils
import {
  buildSpaceSensor,
  filterSensorRecords,
  filterWaterSystemRecords,
  canBuildChart
} from 'map-view/components/chart-canvas-compat/utils'
import EChart from 'map-view/enums/chart'
import { ISensorRecordByType } from 'graphql-lib/interfaces/ISensorDevice'
import IGreenAutomationWaterSystemRecord from 'graphql-lib/interfaces/IGreenAutomationWaterSystemRecord'

interface IChartTileProps {
  animated?: boolean;
  chart: IChart;
  space?: ISpace;
}

const ChartTile = ({
  animated,
  chart,
  space
}: IChartTileProps): JSX.Element => {
  // Contexts //////////////////////////////////////////////////////////////////

  const {
    selectedEntities: {
      selectedEntities,
      selectEntity,
      selectedEntitiesUUID,
    },
    time: {
      time
    }
  } = useContext(Mobius)

  // GraphQL
  const [getSensorRecords, sensorRecordData] = useLazyQuery<ISensorRecordByType>(QuerySensorRecords)
  const [getCO2SensorRecords, CO2sensorRecordData] = useLazyQuery<ISensorRecordByType>(QueryCO2SensorRecords)
  const [getWaterSystemRecords, waterSystemRecordData] = useLazyQuery<{ greenAutomationWaterSystemRecord: Array<IGreenAutomationWaterSystemRecord>}, OperationVariables>(QueryGreenAutomationWaterSystemRecords)

  // State /////////////////////////////////////////////////////////////////////

  const [selected, setSelected] = useState<boolean>()
  const [style, setStyle] = useState<any>()

  const [chartable, setChartable] = useState<string>()
  const [name, setName] = useState<string>()
  const [value, setValue] = useState<string>()
  const [unit, setUnit] = useState<string>()
  const [needsHistoricalData, setNeedsHistoricalData] = useState<boolean>(!!space)

  const selectChart = () => {
    if (chartable) {
      selectEntity({
        parentType: chart.parentType,
        parentID: chart.parentID,
        type: EEntity.Chart,
        id: chart.chart
      }, true)
    }
  }

  const getHistoricalData = () => {
    if (chart.chart === EChart.CO2) {
      getCO2SensorRecords({
        variables: {
          spaceIds: [chart.parentID],
          startDate: time?.twoWeekLookback,
          endDate: time?.now
        },
        fetchPolicy: 'no-cache'
      })
    } else if (WaterSystemCharts.includes(chart.chart)) {
      // Make the query to get historical data for the space's water system sensors
      getWaterSystemRecords({
        variables: {
          spaceIds: [chart.parentID],
          startDate: time?.twoWeekLookback,
          endDate: time?.now
        },
        fetchPolicy: 'no-cache'
      })
    } else if (SensorCharts.includes(chart.chart)) {
      // Make the query to get historical data for the space's sensors
      getSensorRecords({
        variables: {
          spaceIds: [chart.parentID],
          startDate: time?.twoWeekLookback,
          endDate: time?.now
        },
        fetchPolicy: 'no-cache'
      })
    }
  }

  // Effects ///////////////////////////////////////////////////////////////////

  useIter(
    UpdateSelected,

    { selectedEntities,
      chart,

      setSelected,
      setStyle },

    [ selectedEntitiesUUID,
      chart.parentID,
      chart.parentType,
      chart.id ]
  )

  useIter(
    UpdateState,

    { chart,
      loading: sensorRecordData.loading || CO2sensorRecordData.loading || waterSystemRecordData.loading,

      setChartable,
      setName,
      setValue,
      setUnit },

    [ chart.parentID,
      chart.parentType,
      chart.id,
      sensorRecordData.loading,
      CO2sensorRecordData.loading,
      waterSystemRecordData.loading
    ]
  )

  useEffect(() => {
    // Set the newly queried historical sensor record data
    if ((!sensorRecordData.data && !CO2sensorRecordData.data && !waterSystemRecordData.data) || !space) return
    setNeedsHistoricalData(false)

    // Build sensors on the space
    const sensorRecords = filterSensorRecords(space, sensorRecordData)
    const CO2sensorRecords = filterSensorRecords(space, CO2sensorRecordData)
    const waterSystemRecords = filterWaterSystemRecords(space, waterSystemRecordData)
    buildSpaceSensor(space, sensorRecords)
    buildSpaceSensor(space, CO2sensorRecords)
    space.waterSystemRecords = waterSystemRecords || []

    // Create the new chart with historical data and replace the values of the current chart
    if (canBuildChart({ chart, sensorRecords, CO2sensorRecords, waterSystemRecords })) {
      const newChart = NewChart(space, chart.chart)
      chart.value = newChart?.value
    }

    selectChart()
  }, [sensorRecordData.data, CO2sensorRecordData.data, waterSystemRecordData.data])

  // Callbacks /////////////////////////////////////////////////////////////////

  const $click = useCallback(
    (ev: MouseEvent<HTMLElement>): void => {
      ev.preventDefault()
      ev.stopPropagation()

      if (needsHistoricalData) {
        getHistoricalData()
        return
      }

      selectChart()
    },

    [ selectEntity,
      chart.parentID,
      chart.parentType,
      chart.id,
      chartable ]
  )

  // Render ////////////////////////////////////////////////////////////////////

  let className = 'ChartTile'
  if (animated) className += ' Animated'
  if (selected) className += ' Selected'

  return (
    <div
      style={style}
      className={className}
      onClick={$click}
    >
      <p className='Name'>{name}</p>
      <p className='Value'>{value} {unit}</p>
    </div>
  )
}

export default ChartTile

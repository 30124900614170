/**
 * @author Miras Absar <mabsar@iunu.com>
 */

// Types
import IBuilding from 'graphql-lib/interfaces/IBuilding'
import ISpace from 'graphql-lib/interfaces/ISpace'

// React
import React, {
  ChangeEvent,
  Ref,
  useContext,
  useRef,
  useState
} from 'react'

// React Libs
import { useDebounce } from 'use-debounce'
import { useIter } from 'react-lib/use-iter'

// Font Awesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSearch } from '@fortawesome/pro-light-svg-icons'

// Metron
import Metron from 'grid-view/contexts/metron'

// Effects
import {
  SearchData,
  SortData,
  GroupData
} from './effects'

import Building from './building'

export interface ISpaceProps {
  animated?: boolean;
  open?: boolean;
}

const Spaces = ({
  animated,
  open
}: ISpaceProps): JSX.Element | null => {
  // Context ///////////////////////////////////////////////////////////////////

  const {
    facility,
    buildings,
    spaces,

    buildingsUUID,
    spacesUUID
  } = useContext(Metron)

  // State /////////////////////////////////////////////////////////////////////

  const ref = useRef()

  const [searchText, setSearchText] = useState<string>('')
  const [debouncedSearchText] = useDebounce<string>(searchText, 300)

  const [searchedData, setSearchedData] = useState<ISpace[]>()
  const [sortedData, setSortedData] = useState<ISpace[]>()
  const [groupedData, setGroupedData] = useState<(IBuilding)[]>()

  const [searchedDataUUID, setSearchedDataUUID] = useState<string>()
  const [sortedDataUUID, setSortedDataUUID] = useState<string>()

  // Effects ///////////////////////////////////////////////////////////////////

  useIter(
    SearchData,

    { spaces,
      searchText: debouncedSearchText,

      setSearchedData,
      setSearchedDataUUID },

    [spacesUUID, debouncedSearchText]
  )

  useIter(
    SortData,

    { searchedData,
      setSortedData,
      setSortedDataUUID },

    [searchedDataUUID]
  )

  useIter(
    GroupData,

    { sortedData,
      buildings,
      ref,

      setGroupedData },

    [sortedDataUUID, buildingsUUID]
  )

  // Callbacks /////////////////////////////////////////////////////////////////

  // const $ref = (newRef: any): void => {
  //   if (!ref.current) {
  //     ref.current = newRef
  //   }
  // }

  const $searchText = (ev: ChangeEvent<HTMLInputElement>): void => {
    const newSearchText = ev.target.value
    setSearchText(newSearchText)
  }

  const $itemSize = (index: number): number => {
    const item = groupedData[index]
    const isBuilding = item.__typename === 'Building'

    if (isBuilding) {
      return 36
    } else {
      return 72 + 8
    }
  }

  // Render ////////////////////////////////////////////////////////////////////

  if (!open) {
    return null
  }

  return (
    <div className='GridNavigation'>
      <div className='Search'>
        <div className='Icon'>
          <FontAwesomeIcon icon={faSearch} />
        </div>

        <input
          type='text'
          placeholder='Search'
          value={searchText}

          onChange={$searchText}
        />
      </div>

      {groupedData && (
        <div className='List'>
          {/* <AutoSizer>
            {({ width, height }): JSX.Element => (
              <List
                ref={$ref}
                width={width}
                height={height}
                itemData={groupedData}
                itemCount={groupedData.length}
                itemSize={$itemSize}
              >
                {$item}
              </List>
            )}
          </AutoSizer> */}
          <div className='GridView__FacilityHeader'>
            <label>{facility.name}</label>
          </div>
          {
            groupedData.map((item): JSX.Element =>
              <Building
                style={{ width: 'calc(100% - 16px)' }}
                animated={animated}
                data={item}
                key={'f' + item.facilityId + '.' + item.id}
              />
            )
          }

        </div>
      )}
    </div>
  )
}

export default Spaces

enum EOverlay {
  Areacam = 'Overlay.Areacam',
  CO2 = 'Chart.CO2', // same as enum EChart.CO2
  DLI = 'Chart.DLI', // same as enum EChart.DLI
  Humidity = 'Chart.Humidity', // same as enum EChart.Humidity
  MMol = 'Chart.umol', // same as enum EChart.umol
  Temperature = 'Chart.Temperature', // same as enum EChart.Temperature
  VPD = 'Chart.VPD', // same as enum EChart.VPD
  Tag = 'Overlay.Tag',
  Heatmap = 'Overlay.Heatmap',
  Task = 'Overlay.Task',
  TaskIssue = 'Overlay.TaskIssue'
}

export default EOverlay

/**
 * @author Miras Absar <mabsar@iunu.com>
 */

// Utils
import Has from 'utils/has'
import { EStorage, GetItem } from 'utils/storage'

// Libs
import { parseUrl, stringifyUrl } from 'query-string'

// React Libs
import history from 'react-lib/history'

function GoTo (to: string): void {
  const refreshToken = GetItem('refreshToken')
  const accessToken = GetItem('accessToken', EStorage.EphemeralStorage)
  const customer = GetItem('customer', EStorage.EphemeralStorage)
  const facilities = GetItem('facilities', EStorage.EphemeralStorage)

  const {
    url,
    query
  } = parseUrl(to)

  if (
    !refreshToken &&
    accessToken
  ) {
    query.accessToken = accessToken
  }

  if (customer) {
    query.cid = customer.id
  }

  if (facilities) {
    query.fid = facilities?.[0]?.id
  }

  const newTo = stringifyUrl({
    url,
    query
  })

  history.push(newTo)
}

export default GoTo

// React
import React, {
  useCallback
} from 'react'

// Icons
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSpinnerThird } from '@fortawesome/pro-regular-svg-icons'

// Types
import ID from 'graphql-lib/interfaces/ID'
export interface ITab {
  id: ID;
  label: string;
  loading?: boolean;
}

export interface ITabProps {
  selected?: boolean;
  animated?: boolean;

  tab: ITab;
  onSelect?: (tab: ITab) => void;
}

const Tab = ({
  animated,
  selected,

  tab: {
    id,
    label,
    loading
  },

  onSelect
}: ITabProps): JSX.Element => {
  // Callbacks /////////////////////////////////////////////////////////////////

  const $click = useCallback(
    (): void => {
      if (onSelect) {
        onSelect({
          id,
          label
        })
      }
    },

    [id, onSelect]
  )

  // Render ////////////////////////////////////////////////////////////////////

  let className = 'Tab'
  if (selected) className += ' Selected'
  if (animated) className += ' Animated'

  return (
    <button
      className={className}
      onClick={$click}
    >
      {label}{loading && <FontAwesomeIcon className='ml-s' icon={faSpinnerThird} spin />}
    </button>
  )
}

export default Tab

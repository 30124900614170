/* eslint
curly: ['error', 'multi'],
default-case: 'off',
implicit-arrow-linebreak: 'off',
no-else-return: 'off',
no-extra-boolean-cast: 'off',
nonblock-statement-body-position: ['error', 'any'],
object-curly-spacing: ['error', 'never'],

@typescript-eslint/interface-name-prefix: ['error', 'always'],

react/jsx-indent: 'off',
react/no-unescaped-entities: 'off',
react/prop-types: 'off',

jsx-a11y/control-has-associated-label: 'off',
jsx-a11y/label-has-associated-control: 'off',
jsx-a11y/label-has-for: 'off',
*/

/**
 * @author Miras Absar <mabsar@iunu.com>
 */;

import React from 'react';

export interface IProgressBarProps {
  animated: boolean;
  currentValue: number;
  totalValue: number;
}

export default ({
  animated,
  currentValue,
  totalValue,
}: IProgressBarProps): JSX.Element => {
  const progressBarClassName = animated
    ? 'ProgressBar Animated'
    : 'ProgressBar';

  let progressWidth = currentValue / totalValue * 100;
  if (progressWidth > 100) progressWidth = 100;

  const progressStyle = {
    width: `${progressWidth}%`,
  };

  return (
    <div className={progressBarClassName}>
      <div className='Bar'>
        <div className='Progress' style={progressStyle} />
      </div>

      <p className='Caption'>{currentValue} / {totalValue}</p>
    </div>
  );
};

/**
 * @author Miras Absar <mabsar@iunu.com>
 */

// GraphQL
import { gql } from '@apollo/client'

const QueryInventory = gql`
  query(
    $id: ID
  ) {
    crop(
      id: $id
    ) {
      id

      inventory {
        id
        createdOn
        code
      }
    }
  }
`

const QueryInventoryLocations = gql`
  query(
    $inventoryIds: [ID]
  ) {
    inventoryLocation(
      inventoryIds: $inventoryIds
    ) {
      inventoryId
      locatorId
      locatorCode
      detectedOn
      seenOn
      facilityId
      spaceId
      positionX
      positionY
      thumbUrl
    }
  }
`

export {
  QueryInventory,
  QueryInventoryLocations
}

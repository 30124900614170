// Types
import IFacility from 'graphql-lib/interfaces/IFacility'

// Libs
import { v4 } from 'uuid'

// GraphQL
import client from 'graphql-lib/index'
import { FetchResult, gql } from '@apollo/client'
import ISetStateType from 'graphql-lib/interfaces/ISetStateType'

const Init = ({
  hookID
}: {
  hookID: { current: string };
}): void => {
  hookID.current = `hook:useFacility:${v4()}`
}

const QueryFacilities = async ({
  columns,
  setLoading,
  setError,
  setFacilities,
  setUUID
}: {
  columns: string[];
  setLoading: ISetStateType<boolean>
  setError: ISetStateType<Error>
  setFacilities: ISetStateType<Array<IFacility>>
  setUUID: ISetStateType<string>
}): Promise<void> => {
  if (columns) {
    const query = gql`
      query {
        facility {
          ${columns.join(' ')}
        }
      } 
    `

    setLoading(true);
    setUUID(v4());
    let error: Error
    let data: { facility: Array<IFacility> }
    try {
      const response: FetchResult<typeof data> = await client.query({
        fetchPolicy: 'no-cache',
        query
      });
      data = response.data;
    } catch (err) {
      error = err as Error;
    }

    setLoading(false)
    if (error) {
      setError(error)
      setFacilities(undefined)
      setUUID(v4())
    } else if (data) {
      setError(undefined)
      setFacilities(data.facility)
      setUUID(v4())
    }
  }
}

export {
  Init,
  QueryFacilities
}

// Utils
import GoTo from 'react-lib/go-to'

// Libs
import { parse, stringify } from 'query-string'
import { v4 } from 'uuid'

// React & React Libs
import {
  useCallback,
  useState
} from 'react'
import { useIter } from 'react-lib/use-iter'

// Types
export type ISearchParams = Record<string, any>

/**
 * Update data based on `location.search`.
 */
const URL2Data = ({
  search,

  setData2URL,
  setData,
  setDataUUID
}: {
  search: string;

  setData2URL: (newVal: boolean) => void;
  setData: (newVal: any) => void;
  setDataUUID: (newVal: string) => void;
}): void => {
  const newData = parse(search)
  const newDataUUID = v4()

  setData2URL(false)
  setData(newData)
  setDataUUID(newDataUUID)
}

/**
 * Update `location.search` based on data.
 */
const Data2URL = ({
  pathname,
  data2URL,
  data
}: {
  pathname: string;
  data2URL: boolean;
  data: any;
}): void => {
  if (data2URL) {
    const to = `${pathname}?${stringify(data)}`
    GoTo(to)
  }
}

/**
 * Use the browser's search parameters as state.
 *
 * @example
 * const [searchParams, setSearchParams, searchParamsUUID] = useSearchParams()
 *
 * const incrementCount = useCallback(
 *   (): void => {
 *     const newSearchParams = Object.assign(
 *       {},
 *       searchParams,
 *       { count: searchParams.count + 1 }
 *     )
 *
 *     setSearchParams(newSearchParams)
 *   },
 *   [searchParamsUUID]
 * )
 */
const useSearchParams = (): [
  ISearchParams,
  (newVal: ISearchParams) => void,
  string
] => {
  // State /////////////////////////////////////////////////////////////////////

  const [data2URL, setData2URL] = useState<boolean>()
  const [data, setData] = useState<ISearchParams>(parse(location.search))
  const [dataUUID, setDataUUID] = useState<string>()

  // Effects ///////////////////////////////////////////////////////////////////

  // Update data based on `location.search`.
  useIter(
    URL2Data,

    { search: location.search,
      setData2URL,
      setData,
      setDataUUID },

    [location.search]
  )

  // Update `location.search` based on data.
  useIter(
    Data2URL,

    { pathname: location.pathname,
      data2URL,
      data },

    [data]
  )

  // Callbacks /////////////////////////////////////////////////////////////////

  const _setData = useCallback(
    (newVal: any): void => {
      setData2URL(true)
      setData(newVal)
    },
    [dataUUID]
  )

  // Return ////////////////////////////////////////////////////////////////////

  return [
    data,
    _setData,
    dataUUID
  ]
}

export default useSearchParams

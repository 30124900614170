// Types
import EEntity from 'map-view/enums/entity'
import IContainer from 'graphql-lib/interfaces/IContainer'

// Libs
import { stringify } from 'query-string'
import FavoriteButton from 'ui-lib/components/batches/favorite-button'

// React
import React, {
  MouseEvent,
  useCallback,
  useContext,
  useState
} from 'react'

// Font Awesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCopy } from '@fortawesome/pro-regular-svg-icons'

// React Libs
import GoTo from 'react-lib/go-to'
import { useIter } from 'react-lib/use-iter'

// Effects
import { UpdateState } from './effects'

// Map View
import Mobius from 'map-view/contexts/mobius'

// UI Lib
import CropTile from 'ui-lib/components/crop-tile'
import ChartTile from '../../chart-tile'
import IChart from 'map-view/interfaces/chart'

interface IContainerItemProps {
  animated?: boolean;
  container: IContainer;
  refetch?: () => void
}

const ContainerItem = ({
  animated,
  container,
  refetch
}: IContainerItemProps): JSX.Element => {
  // Context ///////////////////////////////////////////////////////////////////

  const {
    selectedEntities: {
      selectedEntities,
      selectEntity,
      selectedEntitiesUUID
    },

    spaces: {
      spaces,
      spacesUUID
    },

    containerStitches: {
      containerStitches,
      containerStitchesUUID
    },

    containerCharts: {
      containerCharts,
      containerChartsUUID
    }
  } = useContext(Mobius)

  // State /////////////////////////////////////////////////////////////////////

  const [selected, setSelected] = useState<boolean>()

  const [seen, setSeen] = useState<string>()
  const [tag, setTag] = useState<string>()

  const [charts, setCharts] = useState<Array<IChart>>()

  // Effects ///////////////////////////////////////////////////////////////////

  useIter(
    UpdateState,

    { selectedEntities,
      spaces,
      container,
      containerStitches,
      containerCharts,

      setSelected,
      setSeen,
      setTag,
      setCharts },

    [ selectedEntitiesUUID,
      spacesUUID,
      container.id,
      containerStitchesUUID,
      containerChartsUUID ]
  )

  // Callbacks /////////////////////////////////////////////////////////////////

  const $click = useCallback(
    (ev: MouseEvent<HTMLElement>): void =>
      selectEntity(
        { type: EEntity.Container,
          id: container.id,

          parentType: EEntity.Inventory,
          parentID: container.inventoryId },

        ev.ctrlKey || ev.metaKey
      ),

    [ selectEntity,
      container.id ]
  )

  const $compare = useCallback(
    (ev: MouseEvent<HTMLElement>): void => {
      const queryString = stringify({
        cropIDs: JSON.stringify([container?.inventory[0]?.cropId]),
        navigationOpen: true
      })

      const to = '/crops/comparison?' + queryString
      
      GoTo(to)
    },
    [container.id]
  )

  // Render ////////////////////////////////////////////////////////////////////

  let className = 'ContainerItem'
  if (animated) className += ' Animated'
  if (selected) className += ' Selected'

  return (
    <section
      className={className}
      onClick={$click}
      data-type={EEntity.Container}
      data-id={container.id}
      data-parent-type={EEntity.Inventory}
      data-parent-id={container.inventoryId}
    >
      <div className='Main'>
        <CropTile
          crop={{
            id: container?.inventory[0]?.cropId,
            speciesIcon: container?.inventory[0]?.speciesIcon,
            varietyColor: container?.inventory[0]?.varietyColor
          }}

          readOnly
        />

        <div className='Body'>
          <h6>
            {container?.inventory[0]?.cropName}
            <span className='Space' />
            {container?.inventory[0]?.code}
          </h6>
          <p>{seen}</p>
          <p>{tag}</p>
        </div>

        <div className='Actions ai-c'>
          <button onClick={$compare}>
            <FontAwesomeIcon icon={faCopy} />
          </button>
          {container?.inventory[0] && (
            <FavoriteButton inventory={container.inventory[0]} refetchInventories={refetch} />
          )}
        </div>
      </div>

      {charts && charts.length > 0 && (
        <div className='ChartTiles'>
          {charts.map(chart => (
            <ChartTile
              key={chart.chart}
              animated={animated}
              chart={chart}
            />
          ))}
        </div>
      )}
    </section>
  )
}

export default ContainerItem

/**
 * @author Matthew Marone <mmarone@iunu.com>
 */

import { parseISO } from "date-fns";

/**
 * Safely create a new Date from an ISO string, number or Date.
 * @param date
 * @returns a new Date or Invalid Date
 */
const createDate = (date: string | number | Date): Date => {
  return typeof date === "string" ? parseISO(date) : new Date(date);
};

/**
 * Sorts an array of dates in ascending order (earliest to latest)
 *
 * @param {Array<Date>} dates Array of dates to sort
 * @returns {Array<Date>} Sorted array of dates
 */
function sortDates(dates: Array<Date>): Array<Date> {
  return dates.sort((a, b) => a.getTime() - b.getTime());
}

export { createDate, sortDates };

/**
 * @author Miras Absar <mabsar@iunu.com>
 */

// Types
import EEntity from 'map-view/enums/entity'
import EDiff from 'map-view/enums/diff'

// Utils
import Has from 'utils/has'
import { v4 } from 'uuid'
import IContainer from 'graphql-lib/interfaces/IContainer'
import IEntity from 'map-view/interfaces/entity'
import IInventory from 'graphql-lib/interfaces/IInventory'
import { IListRef } from '../task-issues-list/effects'
import IDiff from 'map-view/interfaces/diff'

const UpdateVisibleInventories = ({
  visibleEntities,
  inventories,
  setVisibleInventories,
  setVisibleInventoriesUUID
}: {
  visibleEntities: Array<IEntity>
  inventories: Array<IInventory>
  setVisibleInventories: (value: Array<IInventory>) => void
  setVisibleInventoriesUUID: (value: string) => void
}): void => {
  if (
    !visibleEntities ||
    !inventories
  ) {
    return
  }

  const newVisibleInventories = inventories
    .filter(inventory => !!visibleEntities
      .find(entity =>
        entity.type === EEntity.Inventory &&
        entity.id === inventory.id))

  setVisibleInventories(newVisibleInventories)
  setVisibleInventoriesUUID(v4())
}

const UpdateSearchedInventories = ({
  inventories,
  visibleInventories,
  search,
  visible,
  setSearchedInventories,
  setSearchedInventoriesUUID
}: {
  inventories: Array<IInventory>
  visibleInventories: Array<IInventory>
  search: string
  visible: boolean
  setSearchedInventories: (value: Array<IInventory>) => void
  setSearchedInventoriesUUID: (value: string) => void
}): void => {
  if (
    !inventories ||
    !visibleInventories
  ) {
    return
  }

  let newSearchedInventories = visible
    ? visibleInventories
    : inventories

  if (search?.trim()) {
    const searchLC = search.toLocaleLowerCase()
    newSearchedInventories = newSearchedInventories
      .filter(inventory =>
        inventory.code?.toLocaleLowerCase().includes(searchLC) ||
        inventory.cropCode?.toLocaleLowerCase().includes(searchLC) ||
        inventory.cropName?.toLocaleLowerCase().includes(searchLC) ||
        inventory.productCode?.toLocaleLowerCase().includes(searchLC) ||
        inventory.productNickname?.toLocaleLowerCase().includes(searchLC) ||
        inventory.speciesName?.toLocaleLowerCase().includes(searchLC) ||
        inventory.varietyName?.toLocaleLowerCase().includes(searchLC))
  }

  setSearchedInventories(newSearchedInventories)
  setSearchedInventoriesUUID(v4())
}

const UpdateContainers = ({
  inventories,
  containers,
  setContainers,
  setContainersUUID
}: {
  inventories: Array<IInventory>
  containers: Array<IContainer>
  setContainers: (value: Array<IContainer>) => void
  setContainersUUID: (value: string) => void
}): void => {
  if (
    !inventories ||
    !containers
  ) {
    return
  }

  const newContainers = containers
    .map(container => {
      const newContainer = { ...container }

      const inventory = inventories
        .find(inventory => String(inventory.id) === String(container.inventoryId))

      newContainer.inventory = [inventory]

      return newContainer
    })

  setContainers(newContainers)
  setContainersUUID(v4())
}

const UpdateVisibleContainers = ({
  visibleEntities,
  containers,
  setVisibleContainers,
  setVisibleContainersUUID
}: {
  visibleEntities: Array<IEntity>
  containers: Array<IContainer>
  setVisibleContainers: (value: Array<IContainer>) => void
  setVisibleContainersUUID: (value: string) => void
}): void => {
  if (
    !visibleEntities ||
    !containers
  ) {
    return
  }

  const newVisibleContainers = containers
    .filter(container => !!visibleEntities
      .find(entity =>
        entity.type === EEntity.Container &&
        entity.id === container.id))

  setVisibleContainers(newVisibleContainers)
  setVisibleContainersUUID(v4())
}

const UpdateSearchedContainers = ({
  containers,
  visibleContainers,
  search,
  visible,
  setSearchedContainers,
  setSearchedContainersUUID
}: {
  containers: Array<IContainer>
  visibleContainers: Array<IContainer>
  search: string
  visible: boolean
  setSearchedContainers: (value: Array<IContainer>) => void
  setSearchedContainersUUID: (value: string) => void
}): void => {
  if (
    !containers ||
    !visibleContainers
  ) {
    return
  }

  let newSearchedContainers = visible
    ? visibleContainers
    : containers

  if (search?.trim()) {
    const searchLC = search.toLocaleLowerCase()
    newSearchedContainers = newSearchedContainers
      .filter(container =>
        container?.inventory[0]?.code?.toLocaleLowerCase().includes(searchLC) ||
        container?.inventory[0]?.cropCode?.toLocaleLowerCase().includes(searchLC) ||
        container?.inventory[0]?.cropName?.toLocaleLowerCase().includes(searchLC) ||
        container?.inventory[0]?.productCode?.toLocaleLowerCase().includes(searchLC) ||
        container?.inventory[0]?.productNickname?.toLocaleLowerCase().includes(searchLC) ||
        container?.inventory[0]?.speciesName?.toLocaleLowerCase().includes(searchLC) ||
        container?.inventory[0]?.varietyName?.toLocaleLowerCase().includes(searchLC)||
        container?.inventory[0]?.currentLocator?.serial?.toLocaleLowerCase().includes(searchLC)
        )
  }

  setSearchedContainers(newSearchedContainers)
  setSearchedContainersUUID(v4())
}

const UpdateScroll = ({
  selectedEntitiesDiff,
  listRef
}: {
  selectedEntitiesDiff: Array<IDiff<IEntity>>
  listRef: IListRef
}): void => {
  if (
    !selectedEntitiesDiff ||
    !listRef
  ) {
    return
  }

  const diff = selectedEntitiesDiff
    .find(diff =>
      diff.type === EDiff.Add &&
      (
        diff.value.type === EEntity.Inventory ||
        diff.value.type === EEntity.Container
      ))

  if (!diff) {
    return
  }

  const child = Array.from(listRef.children)
    .find(child =>
      child.dataset.type === diff.value.type &&
      child.dataset.id === diff.value.id)

  if (!child) {
    return
  }

  child.scrollIntoView({
    behavior: 'smooth',
    block: 'start',
    inline: 'nearest'
  })
}

export {
  UpdateVisibleInventories,
  UpdateSearchedInventories,
  UpdateContainers,
  UpdateVisibleContainers,
  UpdateSearchedContainers,
  UpdateScroll
}

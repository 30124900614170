/**
 * @author Miras Absar <mabsar@iunu.com>
 * @file ...
 */

// Types
import IInputStatus from 'ui-lib/interfaces/IInputStatus'

// Libs
import { v4 } from 'uuid'

// React
import React, {
  useCallback,
  useRef,
  useState,
  ChangeEvent
} from 'react'

// Font Awesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faSearch,
  faEye,
  faEyeSlash
} from '@fortawesome/pro-light-svg-icons'

// UI Lib
import ToggleSwitch from 'ui-lib/components/toggle-switch/index';
import EmailReportButton from '../tasks-list/email-report-button/EmailReportButton'
import { EStorage, GetItem } from 'utils/storage'

interface ISearchProps<T, U> {
  animated?: boolean;

  searchState: [
    T,
    IInputStatus,
    string,
    (newVal: T) => void,
    (reinitVal: T) => void,
    boolean,
    boolean,
    (newVal: boolean) => void,
    (newVal: boolean) => void
  ];

  visibleState: [
    U,
    IInputStatus,
    string,

    (newVal: U) => void,
    (reinitVal: U) => void,

    boolean,
    boolean,
    (newVal: boolean) => void,
    (newVal: boolean) => void
  ];

  isIssuesTab: boolean
}

const Search = ({
  animated,

  searchState: [
    search,
    searchStatus,
    searchUUID,

    setSearch,
    resetSearch,

    searchTouched,
    searchPristine,
    setSearchTouched,
    setSearchedPristine
  ],

  visibleState: [
    visible,
    visibleStatus,
    visibleUUID,

    setVisible,
    resetVisible,

    visibleTouched,
    visiblePristine,
    setVisibleTouched,
    setVisiblePristine
  ],

  isIssuesTab
}: ISearchProps<string, boolean>): JSX.Element => {
  // State /////////////////////////////////////////////////////////////////////

  const inputRef = useRef<HTMLInputElement>()
  const [inputRefUUID, setInputRefUUID] = useState<string>()

  // Get info on the current logged in user. 
  const user = GetItem('user', EStorage.EphemeralStorage)

  // Callbacks /////////////////////////////////////////////////////////////////

  const icon$click = useCallback(
    (): void => {
      if (inputRef.current) {
        inputRef.current.focus()
        setSearchTouched(true)
      }
    },
    [inputRefUUID]
  )

  const search$ref = useCallback(
    (newInputRef: HTMLInputElement): void => {
      if (
        newInputRef &&
        !inputRef.current
      ) {
        inputRef.current = newInputRef
        setInputRefUUID(v4())
      }
    },
    [inputRefUUID]
  )

  const search$change = useCallback(
    (ev: ChangeEvent<HTMLInputElement>): void => {
      const newSearch = ev.target.value
      setSearch(newSearch)
    },
    [searchUUID]
  )

  const visible$change = useCallback(
    (): void => {
      const newVisible = !visible
      setVisible(newVisible)
    },
    [visibleUUID]
  )

  // Render ////////////////////////////////////////////////////////////////////

  let className = 'Search'
  if (animated) className += ' Animated'

  const switchStyleStatus = visible
    ? 'switch-background--on'
    : ''

  return (
    <div className='Search-Container'>
      <div className={className}>
        <div onClick={icon$click}>
          <FontAwesomeIcon icon={faSearch} />
        </div>

        <input
          type='text'
          placeholder='Search'
          value={search}

          ref={search$ref}
          onChange={search$change}
        />
      </div>
      
      <ToggleSwitch classNames={switchStyleStatus} onClick={visible$change}/>
      {isIssuesTab && user.admin &&  <EmailReportButton/>}
    </div>
  )
}

export default Search

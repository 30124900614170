// React
import React, {
  useContext
} from 'react'

// FontAwesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faSpinnerThird,
  faExclamationCircle
} from '@fortawesome/pro-regular-svg-icons'

// UI Lib
import Mobius from 'map-view/contexts/mobius'
import AreacamTile from './areacam-tile'

interface IAreacamTilesControlProps {
  outlined?: boolean;
  resting?: boolean;
  raised?: boolean;
  animated?: boolean;
}

const AreacamCarousel = ({
  outlined,
  resting,
  raised,
  animated
}: IAreacamTilesControlProps): JSX.Element | null => {
  // Context ///////////////////////////////////////////////////////////////////

  const {
    searchParams: {
      searchParams,
    },
    areacams: { areacams, areacamsLoading }
  } = useContext(Mobius)

  const isOpen = searchParams.viewAreacamTiles
  let className = 'Theme__Dark AreacamTilesControl'

  if (outlined) className += ' Outlined'
  if (resting) className += ' Resting'
  if (raised) className += ' Raised'
  if (animated) className += ' Animated'

  if (!isOpen) return null

  return (
    <div className={className}>
      {areacamsLoading && (
        <div className='Status'>
          <FontAwesomeIcon icon={faSpinnerThird} spin />
          <p>Loading</p>
        </div>
      )}

      {areacams && areacams.length === 0 && (
        <div className='Status'>
          <FontAwesomeIcon icon={faExclamationCircle} />
          <p>No Areacams</p>
        </div>
      )}

      {areacams &&
        areacams.length > 0 &&
        areacams.map((areacam: any): JSX.Element => (
          <AreacamTile
            outlined={outlined}
            resting={resting}
            raised={raised}
            animated={animated}

            key={areacam.id}
            areacam={areacam}
          />
        ))}
    </div>
  )
}

export default AreacamCarousel

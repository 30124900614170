// Types
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import EOverlay from '../../../../enums/overlay';
import ID from 'graphql-lib/interfaces/ID';

// React
import React, {
  useContext,
  useCallback,
  useState
} from 'react'

// React Libs
import { useIter } from 'react-lib/use-iter'

// Font Awesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

// MapView
import Mobius from '../../../../contexts/mobius'

// Effects
import { UpdateSelected } from './effects'

interface IOverlayProps {
  animated?: boolean;
  type: EOverlay;
  icon: IconProp;
  name: string;
}

const Overlay = ({
  animated,
  type,
  icon,
  name
}: IOverlayProps): JSX.Element => {
  // Context ///////////////////////////////////////////////////////////////////

  const {
    overlays: {
      overlays,
      selectOverlay,
      overlaysUUID
    }
  } = useContext(Mobius)

  // State /////////////////////////////////////////////////////////////////////

  const [selected, setSelected] = useState<boolean>()

  // Effects ///////////////////////////////////////////////////////////////////

  useIter(
    UpdateSelected,

    { type,
      overlays,
      setSelected },

    [ type,
      overlaysUUID ]
  )

  // Callbacks /////////////////////////////////////////////////////////////////

  const $toggleSelected = useCallback(
    (): void => selectOverlay(type),

    [ type,
      selectOverlay ]
  )

  // Render ////////////////////////////////////////////////////////////////////

  let className = 'Overlay'
  if (animated) className += ' Animated'
  if (selected) className += ' Selected'
  return (
    <button
      className={className}
      onClick={$toggleSelected}
    >
      <FontAwesomeIcon icon={icon} />
      {name}
    </button>
  )
}

export default Overlay

/**
 * @author Miras Absar <mabsar@iunu.com>
 */

// React
import React, { useContext, useEffect } from 'react'
import { match } from 'react-router-dom'

import NavigationContext from 'context/navigation'
import { SwitchCustomer } from 'utils/actions'
import Metron from 'grid-view/components/metron'
import FacilityHeader from 'grid-view/components/facility-header'
import Spaces from 'grid-view/components/grid-navigation/spaces'
export { Facility as FacilityRoute } from 'grid-view/utils/routes'

export interface IFacilityProps {
  match: match<{ id: string }>;
}

const Facility = ({
  match
}: IFacilityProps): JSX.Element => {
  const { primaryAction, setPrimaryAction } = useContext(NavigationContext)

  /**
   * Ensures the customer switcher always renders, instead of
   * a possible back button whenever the user is on the facility page.
   */
  useEffect(() => {
    setPrimaryAction(SwitchCustomer)
  }, [primaryAction])

  return (
    <Metron match={match}>
      <div className='Facility'>
        <FacilityHeader id={match.params.id} />
        <Spaces animated open />
      </div>
    </Metron>
  )
}

export default Facility

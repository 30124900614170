/**
 * @author Miras Absar <mabsar@iunu.com>
 */

import EInputStatus from '../enums/input-status'

const Status2ClassName = {
  [EInputStatus.Uninitialized]: '',
  [EInputStatus.None]: '',
  [EInputStatus.OK]: 'OK',
  [EInputStatus.Warning]: 'Warning',
  [EInputStatus.Error]: 'Error'
}

export default Object.freeze(Status2ClassName)

/* eslint
curly: ['error', 'multi'],
default-case: 'off',
implicit-arrow-linebreak: 'off',
no-else-return: 'off',
no-extra-boolean-cast: 'off',
nonblock-statement-body-position: ['error', 'any'],
object-curly-spacing: ['error', 'never'],

@typescript-eslint/interface-name-prefix: ['error', 'always'],

react/jsx-indent: 'off',
react/no-unescaped-entities: 'off',
react/prop-types: 'off',

jsx-a11y/control-has-associated-label: 'off',
jsx-a11y/label-has-associated-control: 'off',
jsx-a11y/label-has-for: 'off',
*/

/**
 * @author Miras Absar <mabsar@iunu.com>
 */

import {v4} from 'uuid';

import React, {
  ChangeEvent,
} from 'react';

import EInputStatus from 'ui-lib/enums/input-status';
import IInputFacadeProps from 'ui-lib/interfaces/IInputFacadeProps';
import Status2ClassName from 'ui-lib/utils/Status2ClassName';

export default ({
  /* Visual Properties */
  animated,
  label,
  hint,

  /* Behavioral Properties */
  inputType,
  name,
  value,
  status,
  touched,
  dirty,
  showStatusUntouched,
  onBlur,
  onChange,
  readOnly,
}: IInputFacadeProps<string>): JSX.Element => {
  const id = v4();
  const className = animated ? 'ContainersInput Animated' : 'ContainersInput';
  const showingStatus = (touched || dirty || showStatusUntouched)
    && status.status !== EInputStatus.Uninitialized
    && status.status !== EInputStatus.None;

  const statusClassName = Status2ClassName[status.status];

  const $blur = (): void => {
    if (name && onBlur) onBlur(name);
  };

  const $input = (ev: ChangeEvent<HTMLInputElement>): void => {
    const {value: newValue} = ev.target;
    if (name && onChange) onChange(name, newValue);
  };

  return (
    <div className={className}>
      <label htmlFor={id}>{label}</label>

      <input
        id={id}
        type={inputType}
        name={name}
        placeholder={hint}
        value={value}
        onBlur={$blur}
        onInput={$input}
        readOnly={readOnly}
      />

      {showingStatus
        ? <p className={statusClassName}>{status.message}</p>
        : null}
    </div>
  );
};

/**
 * @author Miras Absar <mabsar@iunu.com>
 */

import { createContext } from 'react'
import IAction from 'arrayview/interfaces/IAction'
import IRoute from 'ui-lib/interfaces/IRoute'

export interface INavigationContext {
  primaryAction: IAction<IRoute>;
  secondaryActions: IAction<IRoute>[];

  title: string;
  routes: IRoute[];

  setPrimaryAction: (newVal: IAction<IRoute>) => void;
  setSecondaryActions: (newVal: IAction<IRoute>[]) => void;
  setTitle: (newVal: string) => void;
  setRoutes: (newVal: IRoute[]) => void;
}

const NavigationContext = createContext<INavigationContext>({
  primaryAction: null,
  secondaryActions: null,

  title: null,
  routes: null,

  setPrimaryAction: (): void => {},
  setSecondaryActions: (): void => {},
  setTitle: (): void => {},
  setRoutes: (): void => {}
})

export default NavigationContext

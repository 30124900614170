import { columnSelectStyles, filterSelectStyles } from './styles.js'

// React
import React from 'react'
import Select, { ActionMeta, ValueType } from 'react-select'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faSearch,
  faSortAmountUp,
  faSortAmountDown
} from '@fortawesome/pro-regular-svg-icons'

export interface IListHeaderProps {
  animated?: boolean;

  filters: any[];
  columns: any[];

  search: string;
  filter: any;
  column: any;
  descending: boolean;

  onSearch: (newVal: string) => void;
  onFilter: (newVal: any) => void;
  onColumn: (newVal: any) => void;
  onDescending: (newVal: any) => void;
}

const ListHeader = ({
  animated,

  filters,
  columns,

  search,
  filter,
  column,
  descending,

  onSearch,
  onFilter,
  onColumn,
  onDescending
}: IListHeaderProps): JSX.Element => {
  const filterOpts = filters.map((f): any => ({
    value: f,
    label: f.name
  }))

  const columnOpts = columns.map((c): any => ({
    value: c,
    label: c.name
  }))

  const filterVal = {
    value: filter,
    label: filter.name
  }

  const columnVal = {
    value: column,
    label: column.name
  }

  interface StatesHeaderType {
    value: string;
  }

  const $search = ({ target: { value } }: { target: StatesHeaderType }): void =>
    onSearch(value)

  const $filter = (statesHeader: ValueType<StatesHeaderType>, actionMeta: ActionMeta<StatesHeaderType>): void =>
    onFilter((statesHeader as StatesHeaderType).value)

  const $column = (statesHeader: ValueType<StatesHeaderType>, actionMeta: ActionMeta<StatesHeaderType>): void =>
    onColumn((statesHeader as StatesHeaderType).value)

  const $descending = (): void =>
    onDescending(!descending)

  let className = 'ListHeader'
  if (animated) className += ' Animated'

  const icon = descending
    ? faSortAmountUp
    : faSortAmountDown

  return (
    <div className={className}>
      <div className='Search'>
        <FontAwesomeIcon icon={faSearch} />

        <input
          placeholder='Search'
          value={search}
          onChange={$search}
        />
      </div>

      <div className='FilterSort'>
        <Select
          styles={filterSelectStyles}
          options={filterOpts}
          value={filterVal}
          onChange={$filter}
        />

        <Select
          styles={columnSelectStyles}
          options={columnOpts}
          value={columnVal}
          onChange={$column}
        />

        <button onClick={$descending}>
          <FontAwesomeIcon icon={icon} />
        </button>
      </div>
    </div>
  )
}

export default ListHeader

/**
 * @author Miras Absar <mabsar@iunu.com>
 */

import {
  EStorage,
  GetItem
} from 'utils/storage'

const Chartable: Record<string, boolean> = Object.freeze({
  absoluteHumidity: true,
  dli: true,
  humidity: true,
  temperature: true,
  umol: true,
  vpd: true,

  co2: true,

  budDetection: true,
  budDetectionDensity: true,
  canopyCoverage: true,
  canopyCoverageDaily: true,
  flowerCoverage: true,
  flowerCoverageDaily: true,
  germinationRate: false,
  germinationRatePerc: false
})

const ChartOrder: Record<string, number> = Object.freeze({
  absoluteHumidity: 0,
  dli: 1,
  humidity: 2,
  temperature: 3,
  umol: 4,
  vpd: 5,

  co2: 6,

  budDetection: 7,
  budDetectionDensity: 8,
  canopyCoverage: 9,
  canopyCoverageDaily: 10,
  flowerCoverage: 11,
  flowerCoverageDaily: 12,
  germinationRate: 13,
  germinationRatePerc: 14
})

const ChartName: Record<string, string> = Object.freeze({
  absoluteHumidity: 'Abs. Humidity',
  dli: 'DLI',
  humidity: 'Humidity',
  temperature: 'Temperature',
  umol: 'μmol',
  vpd: 'VPD',

  co2: 'CO2',

  budDetection: 'Flower Count',
  budDetectionDensity: 'Flowers / Sq Meter',
  canopyCoverage: 'Canopy Index',
  canopyCoverageDaily: 'Canopy Daily Growth',
  flowerCoverage: 'Flower Index',
  flowerCoverageDaily: 'Flower Daily Growth',
  germinationRate: 'Germination Count',
  germinationRatePerc: 'Germination Rate'
})

const ChartAccuracy: Record<string, number> = Object.freeze({
  absoluteHumidity: 2,
  dli: 2,
  humidity: 2,
  temperature: 2,
  umol: 0,
  vpd: 2,

  co2: 2,

  budDetection: 0,
  budDetectionDensity: 2,
  canopyCoverage: 2,
  canopyCoverageDaily: 2,
  flowerCoverage: 2,
  flowerCoverageDaily: 2,
  germinationRate: 0,
  germinationRatePerc: 0
})

const ChartUnit: Record<string, string> = Object.freeze({
  absoluteHumidity: 'g/m³',
  dli: 'mol',
  humidity: '%',
  get temperature (): string {
    const customer = GetItem('customer', EStorage.EphemeralStorage)
    const temperatureMetric = customer.temperatureMetric
    const temperatureUnit = temperatureMetric === 'celcius'
      ? '°C'
      : '°F'

    return temperatureUnit
  },
  umol: 'μmol',
  vpd: 'hPa',

  co2: 'ppm',

  budDetection: '',
  budDetectionDensity: ' / m²',
  canopyCoverage: '%',
  canopyCoverageDaily: '%',
  flowerCoverage: '%',
  flowerCoverageDaily: '%',
  germinationRate: '',
  germinationRatePerc: '%'
})

const ChartType: Record<string, string> = Object.freeze({
  absoluteHumidity: 'line',
  dli: 'line',
  humidity: 'line',
  temperature: 'line',
  umol: 'line',
  vpd: 'line',

  co2: 'line',

  budDetection: 'line',
  budDetectionDensity: 'line',
  canopyCoverage: 'line',
  canopyCoverageDaily: 'column',
  flowerCoverage: 'line',
  flowerCoverageDaily: 'column'
})

const ChartColor: Record<string, string> = Object.freeze({
  absoluteHumidity: '#F44336',
  dli: '#E91E63',
  humidity: '#9C27B0',
  temperature: '#673AB7',
  umol: '#3F51B5',
  vpd: '#2196F3',

  co2: '#03A9F4',

  budDetection: '#00BCD4',
  budDetectionDensity: '#0097A7',
  canopyCoverage: '#4DB6AC',
  canopyCoverageDaily: '#00796B',
  flowerCoverage: '#81C784',
  flowerCoverageDaily: '#388E3C'
})

/**
 * 2D array of hex color strings
 * [metric-series][related-colors-for-comparing-same-metric]
 */
const ChartColors = [
  // 1st metric selected will always be purple
  ['#6500FB', '#BF80FF'],
  // 2nd metric selected  is blue
  ['#4477AA', '#66CCEE'],
  // 3rd metric selected is green
  ['#1B7837', '#5AAE61']
]


export {
  Chartable,
  ChartOrder,
  ChartName,
  ChartAccuracy,
  ChartUnit,
  ChartType,
  ChartColor,
  ChartColors
}

export type ObjectArrayType = {
  [key: string]: number | string | object
}

/**
 * @description Utility function to search for lowest nonzero value in an object
 * @param {ObjectArrayType} obj - The object you wish to find the lowest nonzero value in
 * @param {string} path - A nested path to fetch within the obj element (optional)
 * @returns number
 */
export const lowestNonZeroValue = (obj: ObjectArrayType, path?: string): number => {
  return Object.keys(obj).reduce((prev: number, key): number => {
    const val = (typeof obj?.[key] === 'object' && path) ? obj?.[key]?.[path as keyof object] : obj?.[key] as number
    const min = Math.min(prev, val)
    return (min !== 0 && min < prev) ? min : prev
  }, Infinity)
}

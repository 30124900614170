// Types
import EInputStatus from 'ui-lib/enums/input-status'
import IInputStatus from 'ui-lib/interfaces/IInputStatus'
import IFacility from 'graphql-lib/interfaces/IFacility';
import IBuilding from 'graphql-lib/interfaces/IBuilding';
import IZone from 'graphql-lib/interfaces/IZone';
import ISubZone from 'graphql-lib/interfaces/ISubZone';
import ISpace from 'graphql-lib/interfaces/ISpace';

const getFacilityId = () => {
  const params = new URLSearchParams(window.location.search)
  return params.get('fid');
}

const getCurrentFacility = (): IFacility => {
  const facilities = JSON.parse(window.ephemeralStorage.getItem('facilities'));
  const fid = getFacilityId();
  const currentFacility = facilities.find((f: IFacility) => f.id === fid)
  return currentFacility;
}

const getCurrentAreas = (areaName: string): IBuilding => {
  const currentFacility = getCurrentFacility()
  const areas = currentFacility.building;
  const currentArea = areas?.find((a: IBuilding) =>a.name === areaName)
  return currentArea;
}

const getCurrentZones = (zoneName: string, areaId: number): ISpace => {
  const currentFacility = getCurrentFacility();
  const zones = currentFacility.space;
  const currentZones = zones?.find((z) => z.name === zoneName && Number(areaId) === Number(z.buildingId))
  return currentZones;
}

const getCurrentSubZone = (subZoneName: string, zoneId: number): ISubZone => {
  const currentFacility = getCurrentFacility();
  const subZones = currentFacility.subZone;
  const currentSubZone = subZones?.find((s: ISubZone) => s.name === subZoneName && Number(zoneId) === Number(s.zoneId))
  return currentSubZone;
}

const NotRequiredAreaName = (areaVal: string, zoneVal: string, subZoneVal: string): IInputStatus => {
  const fid = getFacilityId();
  if (!fid) {
    return {
      status: EInputStatus.Error,
      message: 'Not facility id at url'
    }
  }
  const currentFacility = getCurrentFacility()
  if (!currentFacility) {
    return {
      status: EInputStatus.Error,
      message: 'Not facility match with facility id at url'
    }
  }

  // It verifies that area shouldn't be empty if zone or subZone are filled
  if ((subZoneVal !== '' || zoneVal !== '') && areaVal === '') {
    return {
      status: EInputStatus.Error,
      message: 'Area cannot be empty if one of subzone or zone are filled.'
    }
  }

  const areasFiltered = getCurrentAreas(areaVal)
  if ( areaVal !== '' && !areasFiltered?.id ) {
    return {
      status: EInputStatus.Error,
      message: 'Invalid Area'
    }
  }

  return {
    status: EInputStatus.OK,
    message: ''
  }
}

const NotRequiredZoneName = (areaVal: string, zoneVal: string, subZoneVal: string): IInputStatus => {
  const fid = getFacilityId();

  if (!fid) {
    return {
      status: EInputStatus.Error,
      message: 'Not facility id at url'
    }
  }

  const currentFacility = getCurrentFacility()

  if (!currentFacility) {
    return {
      status: EInputStatus.Error,
      message: 'Not facility match with facility id at url'
    }
  }

  // Validates that the area is not empty if zone is filled.
  if ((areaVal === '' && zoneVal !== '') || (subZoneVal !== '' && zoneVal === '')) {
    return {
      status: EInputStatus.Error,
      message: 'Not empty area name is allowed while validating zone or Not empty value for zone is allwed while validating subZone'
    }
  }

  const currentArea = getCurrentAreas(areaVal)
  if ((!currentArea?.id && zoneVal !== '' && areaVal !== '') || (!currentArea?.id && zoneVal !== '' && areaVal === '')) {
    return {
      status: EInputStatus.Error,
      message: 'Valid Area name is needed.'
    }
  }

  const zonesFiltered = getCurrentZones(zoneVal, Number(currentArea?.id));
  if (zoneVal !== '' && areaVal !== '' && !zonesFiltered?.id) {
    return {
      status: EInputStatus.Error,
      message: 'Invalid'
    }
  }

  return {
    status: EInputStatus.OK,
    message: ''
  }
}

const NotRequiredSubZoneName = (areaVal: string, zoneVal: string, subZoneVal: string): IInputStatus => {  
  const fid = getFacilityId()
  if (!fid) {
    return {
      status: EInputStatus.Error,
      message: 'Not facility id at url'
    }
  }

  const currentFacility = getCurrentFacility();

  if (!currentFacility) {
    return {
      status: EInputStatus.Error,
      message: 'Not facility match with facility id at url'
    }
  }

  if ((areaVal === '' || zoneVal === '') && subZoneVal !== '') {
    return {
      status: EInputStatus.Error,
      message: 'Not empty Area or Zone name is allowed while adding a subzone.'
    }
  }

  const currentArea = getCurrentAreas(areaVal)
  if (!currentArea?.id && subZoneVal !== '') {
    return {
      status: EInputStatus.Error,
      message: 'Valid Area name is needed'
    }
  }

  const currentZone = getCurrentZones(zoneVal, Number(currentArea?.id))
  if (!currentZone?.id && subZoneVal !== '') {
    return {
      status: EInputStatus.Error,
      message: 'Valid Zone name is needed'
    }
  }

  const subZonesFiltered = getCurrentSubZone(subZoneVal, currentZone?.id)
  if (!subZonesFiltered?.id && subZoneVal !== '') {
    return {
      status: EInputStatus.Error,
      message: 'Invalid'
    }
  }

  return {
    status: EInputStatus.OK,
    message: ''
  }
}

export {
  NotRequiredSubZoneName,
  NotRequiredZoneName,
  NotRequiredAreaName
}

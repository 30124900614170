// Types
import IFacility from 'graphql-lib/interfaces/IFacility'

// React
import {
  useRef,
  useState
} from 'react'

// React Libs
import {
  useIter,
  useAsyncIter
} from 'react-lib/use-iter'

// Effects
import {
  Init,
  QueryFacilities
} from './effects'

const useFacilities = (columns: string[]): {
  loading: boolean
  error: Error;
  facilities: IFacility[];
  uuid: string;
} => {
  // State /////////////////////////////////////////////////////////////////////

  const hookID = useRef<string>()
  const [loading, setLoading] = useState<boolean>()
  const [error, setError] = useState<Error>()
  const [facilities, setFacilities] = useState<IFacility[]>()
  const [uuid, setUUID] = useState<string>()

  // Effects ///////////////////////////////////////////////////////////////////

  useIter(
    Init,
    { hookID },
    []
  )

  useAsyncIter(
    QueryFacilities,

    { columns,

      setLoading,
      setError,
      setFacilities,
      setUUID },

    [ ...columns ]
  )

  // Return ////////////////////////////////////////////////////////////////////

  return {
    loading,
    error,
    facilities,
    uuid
  }
}

export default useFacilities

// GraphQL
import { gql } from '@apollo/client'

const UpdateTask = gql`
  mutation(
    $id: ID!,
    $description: String,
    $dueDate: DateTime,
    $priority: Int,
    $notes: String,
    $complete: Boolean
  ) {
    Task {
      update(input: {
        id: $id,
        description: $description,
        dueDate: $dueDate,
        priority: $priority,
        notes: $notes,
        complete: $complete
      }) {
        id
        updatedOn

        description
        dueDate
        priority
        notes
        complete

        flag {
          id

          icon
          color
          title
          description
        }

        calendarEvent {
          id
          nextDueTime
        }

        completion {
          id
        }
        
        locationProfile {
          id
          updatedOn

          spaceId
          cropId
          inventoryId
        }

        customerUser {
          id

          name
          email
          username
        }
        
        taskAssignee {
          id
          updatedOn

          customerUser {
            id

            name
            email
            username
          }
        }
      }
    }
  }
`

export { UpdateTask }

/**
 * @author Miras Absar <mabsar@iunu.com>
 */

// React
import React from 'react'

// Font Awesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faBars,
  faTimes
} from '@fortawesome/pro-light-svg-icons'

export interface INavigationProps {
  outlined?: boolean;
  resting?: boolean
  raised?: boolean;
  animated?: boolean;

  open?: boolean;
  toggleOpen: () => void;

  head?: any;
  body?: any;
}

const Navigation = ({
  outlined,
  resting,
  raised,
  animated,

  open,
  toggleOpen,

  head,
  body
}: INavigationProps) => {
  // Render ////////////////////////////////////////////////////////////////////

  let className = 'Navigation'
  if (outlined) className += ' Outlined'
  if (resting) className += ' Resting'
  if (raised) className += ' Raised'
  if (animated) className += ' Animated'
  if (open) className += ' Open'

  const toggleIcon = open
    ? faTimes
    : faBars

  return (
    <div className={className}>
      <div className='Head'>
        <button onClick={toggleOpen}>
          <FontAwesomeIcon icon={toggleIcon} />
        </button>

        {head}
      </div>

      {body}
    </div>
  )
}

export default Navigation

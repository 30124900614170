// Types
import EInputStatus from 'ui-lib/enums/input-status';
import IInputStatus from 'ui-lib/interfaces/IInputStatus';

const Num = async (val: string): Promise<IInputStatus> => {
  if (!/^$|^[0-9]+(?:\.[0-9]+)?$/.test(val)) {
    return {
      status: EInputStatus.Error,
      message: 'Invalid'
    }
  }

  return {
    status: EInputStatus.OK,
    message: ''
  }
}

const RequiredNum = async (val: string): Promise<IInputStatus> => {
  if (/^\s*$/.test(val)) {
    return {
      status: EInputStatus.Error,
      message: 'Required'
    }
  }

  if (!/^[0-9]+(?:\.[0-9]+)?$/.test(val)) {
    return {
      status: EInputStatus.Error,
      message: 'Invalid'
    }
  }

  return {
    status: EInputStatus.OK,
    message: ''
  }
}

export {
  Num,
  RequiredNum
}

/**
 * @author Miras Absar <mabsar@iunu.com>
 */

import Style from 'ol/style/Style'
import Fill from 'ol/style/Fill'
import Stroke from 'ol/style/Stroke'

const Paper16 = '#f9f9f9'
const Border = 'rgba(0, 0, 0, 0.173343)'

const Red500 = '#F44336'
const Green500 = '#4CAF50'
const Blue500 = '#2196F3'
const Purple = '#7665BB'
const DarkPurple = '#443482'

const Red500Transparent = 'rgba(244, 67, 54, 0.173343)'
const Green500Transparent = 'rgba(76, 175, 80, 0.173343)'
const Blue500Transparent = 'rgba(33, 150, 243, 0.173343)'

const Facility = {
  Default: new Style({
    fill: new Fill({
      color: Paper16
    }),
    stroke: new Stroke({
      color: Border,
      width: 1
    })
  }),

  Selected: new Style({
    fill: new Fill({
      color: Paper16
    }),
    stroke: new Stroke({
      color: Blue500,
      width: 2
    })
  })
}

const Building = {
  Default: new Style({
    fill: new Fill({
      color: Paper16
    }),
    stroke: new Stroke({
      color: Border,
      width: 1
    })
  }),

  Selected: new Style({
    fill: new Fill({
      color: Paper16
    }),
    stroke: new Stroke({
      color: Blue500,
      width: 2
    })
  })
}

const Space = {
  Default: new Style({
    fill: new Fill({
      color: Paper16
    }),
    stroke: new Stroke({
      color: Border,
      width: 1
    })
  }),

  Selected: new Style({
    fill: new Fill({
      color: Paper16
    }),
    stroke: new Stroke({
      color: Blue500,
      width: 2
    })
  })
}

const Inventory = {
  Default: new Style({
    fill: new Fill({
      color: Green500Transparent
    }),
    stroke: new Stroke({
      color: Border,
      width: 1
    })
  }),

  Selected: new Style({
    fill: new Fill({
      color: Green500Transparent
    }),
    stroke: new Stroke({
      color: Green500,
      width: 2
    })
  })
}

const Container = {
  Default: new Style({
    fill: new Fill({
      color: Green500Transparent
    }),
    stroke: new Stroke({
      color: Border,
      width: 1
    })
  }),

  Selected: new Style({
    fill: new Fill({
      color: Green500Transparent
    }),
    stroke: new Stroke({
      color: Green500,
      width: 2
    })
  }),

  OutOfDate: new Style({
    stroke: new Stroke({
      color: Purple,
      lineCap: 'square',
      width: 5
    })
  }),

  OutOfDateSelected: new Style({
    stroke: new Stroke({
      color: DarkPurple,
      lineCap: 'square',
      width: 5
    })
  })
}

export {
  Facility,
  Building,
  Space,
  Inventory,
  Container
}

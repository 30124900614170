import React, { useEffect, useState } from 'react';
import useUser from 'graphql-lib/states/use-user';
import { useLocation } from 'react-router-dom';

const TELETRANSPORT_MAP: Record<string, string> = {
  'localhost:8080': 'http://localhost:3000/ai',
  '\\w+.preview.iunu.com': 'https://portal-staging.artemisag.com/ai',
  '\\w+.iunu.com': 'https://one.luna.iunu.com/ai'
};

export default function Teletransport() {
  const userId = localStorage.getItem('userID') ?? '';
  const [href, setHref] = useState<string>('#');

  const { loading: isUserLoading, user } = useUser(userId, ['id', 'isAdmin']);

  const location = useLocation();

  useEffect(() => {
    const { host } = global.location;

    const matchingHost = Object.keys(TELETRANSPORT_MAP).find((key) =>
      new RegExp(key).test(host)
    );

    if (!matchingHost) {
      return;
    }

    const origin = TELETRANSPORT_MAP[matchingHost];
    let href = global.location.href.replace(global.location.origin, origin) ?? '#'

    // Fix params/urls to match Portal
    href = href.replace('fid=', 'facility_id=').replace('/map/', '/map/facility/')

    setHref(
      href.replace(global.location.origin, origin) ?? '#'
    );
  }, [location]);

  // Uncomment this block to limit access to Admin only
  // if (!user?.isAdmin) {
  //   return null;
  // }

  if (!location || !href || isUserLoading) {
    return null;
  }

  if (href === '#') {
    return null;
  }

  return (
    <div className="Route">
      <a className="unified-action" href={href}>
        Unified
      </a>
    </div>
  );
}

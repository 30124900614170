/**
 * @author Miras Absar <mabsar@iunu.com>
 */

// Libs
import { BrowserMultiFormatReader, Result } from '@zxing/library';

// React
import React, {
  Ref,
  useRef
} from 'react'

export interface IVirtualScannerProps {
  onError: (err: Error) => void;
  onScan: (code: string) => void;
}

const VirtualScanner = ({
  onError,
  onScan
}: IVirtualScannerProps): JSX.Element => {
  const elementRef = useRef<string | HTMLVideoElement>()
  const scannerRef = useRef<string | HTMLVideoElement>()

  const $ref = (newRef: string | HTMLVideoElement): void => {
    if (!elementRef.current) {
      const scanner = new BrowserMultiFormatReader()

      scanner.decodeFromInputVideoDevice(undefined, newRef)
        .then((result: Result) => onScan(result.getText()))
        .catch(err => onError(err))

      elementRef.current = newRef
      scannerRef.current = newRef
    }
  }

  return (
    <div className='VirtualScanner'>
      <video
        ref={$ref}
        width='320'
        height='320'
      />
    </div>
  )
}

export default VirtualScanner

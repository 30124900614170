// Types
import ICrop from 'graphql-lib/interfaces/ICrop';
import ID from 'graphql-lib/interfaces/ID';

// React
import React, {
  MouseEvent,
  useCallback,
  useState
} from 'react';

// React Libs
import { useIter } from 'react-lib/use-iter';

// Effects
import { SetDates } from './effects';

// UI Lib
import CropTile from 'ui-lib/components/crop-tile';

export interface ICropItemProps {
  crop: ICrop;
  readOnly?: boolean;
  onSelect?: (cropId: ID) => void;
  onChangeRating?: (cropId: ID, newVal: number) => void;
}

const CropItem = ({
  crop,
  readOnly,
  onSelect,
  onChangeRating
}: ICropItemProps): null | JSX.Element => {
  // State /////////////////////////////////////////////////////////////////////

  const [startDate, setStartDate] = useState<string>()
  const [transplantDate, setTransplantDate] = useState<string>()
  const [endDate, setEndDate] = useState<string>()

  // Effects ///////////////////////////////////////////////////////////////////

  useIter(
    SetDates,

    { crop,

      setStartDate,
      setTransplantDate,
      setEndDate },

    [crop]
  )

  // Callbacks /////////////////////////////////////////////////////////////////

  const $select = useCallback(
    (ev: MouseEvent<HTMLDivElement>): void => {
      if (onSelect) {
        ev.preventDefault()
        ev.stopPropagation()
  
        onSelect(crop.id)
      }
    },

    [ crop,
      onSelect ]
  )

  // Render ////////////////////////////////////////////////////////////////////

  if (!crop) {
    return null
  }

  let className = 'CropItem'
  if (onSelect) className += ' Clickable'

  return (
    <div
      className={className}
      onClick={$select}
    >
      <CropTile
        crop={crop}
        readOnly={readOnly}
        onChangeRating={onChangeRating}
      />

      <div className='Body'>
        <h6>{crop.name}</h6>
        <p>
          {crop.speciesName}
          &ensp;-&ensp;
          {crop.varietyName}
          &ensp;-&ensp;
          { crop.inventoryCount } <span>Inventor{ crop.inventoryCount > 1 ? <span>ies</span> : <span>y</span>}</span>
        </p>
        <p>
          {startDate}
          {transplantDate && (
            <>
              &ensp;-&ensp;
              {transplantDate}
            </>
          )}
          &ensp;-&ensp;
          {endDate}
        </p>
      </div>
    </div>
  )
}

export default CropItem

/**
 * @author Miras Absar <mabsar@iunu.com>
 */

import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faQuestionCircle } from '@fortawesome/pro-light-svg-icons'
import config from 'config'

import GoTo from 'react-lib/go-to'
import { TasksRoute } from 'utils/routes'
import IRoute from '../../interfaces/IRoute'

interface IBottomNavigationProps {
  animated: boolean;
  routes: IRoute[];
  notifications: any[];
}

const BottomNavigation = ({
  animated,
  routes,
  notifications
}: IBottomNavigationProps): JSX.Element => {
  const className = animated ? 'BottomNavigation Animated' : 'BottomNavigation'

  const numTaskAssgnNotifs = (notifications || []).filter(
    (n): boolean => !n.acknowledged && n.objectType === 'task'
  ).length

  const taskAssgnNotifsStr = numTaskAssgnNotifs > 9 ? '9+' : numTaskAssgnNotifs

  const handleHelpClick = () => {
    if (config.zenDeskId && global.zE) {
      const isHidden = global.zE('webWidget:get', 'display') === 'hidden'
      global.zE.activate()
      global.zE('webWidget', isHidden ? 'show' : 'hide')
    }
  }

  return (
    <div className={className}>
      {routes &&
        routes.map((route): JSX.Element => {
          let _className = 'Route'
          if (route.active) _className += ' Active'
          if (route.disabled) _className += ' Disabled'

          const $click = (): void => {
            if (!route.disabled) {
              GoTo(route.route)
            }
          }

          return (
            <div key={route.id} className={_className} onClick={$click}>
              <FontAwesomeIcon icon={route.icon} />
              <p className="Name">{route.name}</p>
              {route.id === TasksRoute.id && numTaskAssgnNotifs > 0 && (
                <div className="BlueDot">{taskAssgnNotifsStr}</div>
              )}
              {route.beta && <label className="Beta">Beta</label>}
            </div>
          )
        })}
      <div className="Route" onClick={handleHelpClick}>
        <FontAwesomeIcon icon={faQuestionCircle} />
        <p className="Name">Help</p>
      </div>
    </div>
  )
}

export type { IBottomNavigationProps }
export default BottomNavigation

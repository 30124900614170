export function isNil(value: any): boolean {
  // eslint-disable-next-line eqeqeq
  return value == null;
}

export type ReplacedField<T extends Record<string, any>, F extends keyof T & string, N extends {}> = Omit<T, F> & N;

export function replaceField<T extends Record<string, any>,
                             F extends keyof T & string,
                             N extends {}>(obj: T, oldFieldName: F, additionalFields?: N): ReplacedField<T, F, N> {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { [oldFieldName]: deleted, ...allButOldFieldName } = obj;
  return Object.assign(allButOldFieldName, additionalFields);
}
// Types
import EEntity from 'map-view/enums/entity'
import IInventory from 'graphql-lib/interfaces/IInventory'

// React
import React, {
  MouseEvent,
  useCallback,
  useContext,
  useState
} from 'react'

// React Libs
import { useIter } from 'react-lib/use-iter'

// Effects
import { UpdateState } from './effects'

// Map View
import Mobius from 'map-view/contexts/mobius'

// UI Lib
import CropTile from 'ui-lib/components/crop-tile'
import ChartTile from '../../chart-tile'
import IChart from 'map-view/interfaces/chart'

interface IInventoryItemProps {
  animated?: boolean;
  inventory: IInventory;
}

const InventoryItem = ({
  animated,
  inventory
}: IInventoryItemProps): JSX.Element => {
  // Context ///////////////////////////////////////////////////////////////////

  const {
    selectedEntities: {
      selectedEntities,
      selectEntity,
      selectedEntitiesUUID
    },

    spaces: {
      spaces,
      spacesUUID
    },

    spaceCharts: {
      spaceCharts
    },

    inventoryStitches: {
      inventoryStitches,
      inventoryStitchesUUID
    },

    inventoryCharts: {
      inventoryCharts,
      inventoryChartsUUID
    }
  } = useContext(Mobius)

  // State /////////////////////////////////////////////////////////////////////

  const [selected, setSelected] = useState<boolean>()

  const [seen, setSeen] = useState<string>()
  const [tag, setTag] = useState<string>()

  const [charts, setCharts] = useState<Array<IChart>>()

  // Effects ///////////////////////////////////////////////////////////////////

  useIter(
    UpdateState,

    { selectedEntities,
      spaces,
      spaceCharts,
      inventory,
      inventoryStitches,
      inventoryCharts,

      setSelected,
      setSeen,
      setTag,
      setCharts },

    [ selectedEntitiesUUID,
      spacesUUID,
      inventory.id,
      inventoryStitchesUUID,
      inventoryChartsUUID ]
  )

  // Callbacks /////////////////////////////////////////////////////////////////

  const $click = useCallback(
    (ev: MouseEvent<HTMLElement>): void =>
      selectEntity(
        { type: EEntity.Inventory,
          id: inventory.id },
        ev.ctrlKey || ev.metaKey
      ),

    [ selectEntity,
      inventory.id ]
  )

  // Render ////////////////////////////////////////////////////////////////////

  let className = 'InventoryItem'
  if (animated) className += ' Animated'
  if (selected) className += ' Selected'

  return (
    <section
      className={className}
      onClick={$click}
      data-type={EEntity.Inventory}
      data-id={inventory.id}
    >
      <div className='Main'>
        <CropTile
          crop={{
            id: inventory.cropId,
            speciesIcon: inventory.speciesIcon,
            varietyColor: inventory.varietyColor
          }}

          readOnly
        />

        <div className='Body'>
          <h6>
            {inventory.cropName}
            <span className='Space' />
            {inventory.code}
          </h6>
          <p>{seen}</p>
          <p>{tag}</p>
        </div>
      </div>

      {charts && charts.length > 0 && (
        <div className='ChartTiles'>
          {charts.map(chart => (
            <ChartTile
              key={chart.chart}
              animated={animated}
              chart={chart}
            />
          ))}
        </div>
      )}
    </section>
  )
}

export default InventoryItem

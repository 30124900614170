// Libs
import { format } from 'date-fns'
import { pickBy } from 'lodash'

// React
import React, {
  useContext,
  useEffect,
  useRef,
  useState
} from 'react'

// GraphQL
import { gql, useQuery, useMutation } from '@apollo/client'

// UI Lib
import Input from 'ui-lib/components/Input/Input'
import SearchSelect from 'ui-lib/components/search-select'
// import Chips from 'ui-lib/components/chips'
import Checkbox from 'ui-lib/components/Checkbox/Checkbox'
import Button from 'ui-lib/components/button'
import ITask from 'graphql-lib/interfaces/ITask'

// Types
import ISpace from 'graphql-lib/interfaces/ISpace';
import ICrop from 'graphql-lib/interfaces/ICrop';

// UI Cores
import {
  TextInputCore as TextCore,
  RequiredTextInputCore as RequiredTextCore
} from 'ui-lib/cores/text-input-core'
import { DateInputCore as DateCore } from 'ui-lib/cores/date-input-core'
import FlagCore from 'graphql-lib/cores/search-select/flag'
import CropCore from 'graphql-lib/cores/search-select/crop'
import SpaceCore from 'graphql-lib/cores/search-select/space'
import CustomerUserCore from 'graphql-lib/cores/search-select/customer-user'

// UI Facades
import TextFacade from 'ui-lib/facades/text-input'
import DateTimeFacade from 'ui-lib/facades/datetime-input'
import TextAreaFacade from 'ui-lib/facades/textarea-input'
import SelectFacade from 'ui-lib/facades/select'
import FlagFacade from 'graphql-lib//facades/search-select/flag-item'
import SpaceFacade from 'graphql-lib/facades/search-select/space-item'
import CropFacade from 'graphql-lib/facades/search-select/crop-item'
import CustomerUserFacade from 'graphql-lib/facades/search-select/customer-user'

// Contexts
import Mobius, { IFunctionTask, ITaskOrTaskIssueModel } from 'map-view/contexts/mobius'
import IInventory from 'graphql-lib/interfaces/IInventory'
import IFlag from 'graphql-lib/interfaces/IFlag'
import IUser from 'graphql-lib/interfaces/IUser'
import IInputStatus from 'ui-lib/interfaces/IInputStatus'
import ID from 'graphql-lib/interfaces/ID'

const QueryTask = gql`
  query($id: ID) {
    task(id: $id) {
      id
      createdOn
      updatedOn

      name
      dueDate
      priority
      body
      complete

      flag {
        id

        icon
        color
        title
        description
      }
      
      locationProfile {
        id
        updatedOn

        spaceId
        cropId
        inventoryId
      }

      calendarEvent {
        id
        nextDueTime
      }

      completion {
        id
      }

      customerUser {
        id

        name
        email
        username
      }
      
      taskAssignee {
        id
        updatedOn

        customerUser {
          id

          name
          email
          username
        }
      }
    }
  }
`

const QuerySpace = gql`
  query($id: ID) {
    space(id: $id) {
      id

      code
      name
      nickname
    }
  }
`

const QueryCrop = gql`
  query($id: ID) {
    crop(id: $id) {
      id

      code
      name
      species
      variety
      enabled
    }
  }
`

const QueryInventory = gql`
  query($id: ID) {
    inventory(id: $id) {
      id
      code
      cropCode
      zoneId
    }
  }
`

const CreateTaskAssignee = gql`
  mutation($customerUserId: ID!, $taskId: ID!) {
    TaskAssignee {
      create(input: {customerUserId: $customerUserId, taskId: $taskId}) {
        id
      }
    }
  }
`

const UpdateTask = gql`
  mutation(
    $id: ID!,
    $description: String,
    $dueDate: DateTime,
    $flagId: Int,
    $priority: Int,
    $notes: String,
    $complete: Boolean
    $customerUserId: Int
  ) {
    Task {
      update(input: {
        id: $id,
        description: $description,
        dueDate: $dueDate,
        flagId: $flagId,
        priority: $priority,
        notes: $notes,
        complete: $complete
        customerUserId: $customerUserId
      }) {
        id
        updatedOn
        createdOn
        customerId
        customerUserId
        locationProfileId

        name
        priority
        dueDate
        complete
        body

        flag {
          id
          createdOn

          icon
          color
          title
          description

          flagCategory {
            id
            createdOn

            name
          }
        }

        locationProfile {
          id
          updatedOn
          createdOn
          customerId
            
          spaceId
          cropId
          inventoryId
          containerId

          positionX
          positionY
        }

        customerUser {
          id

          name
          email
          username
        }


        taskAssignee {
          id
          updatedOn

          customerUser {
            id

            name
            email
            username
          }
        }
      }
    }
  }
`

export interface IViewTaskCardDetailsProps {
  animated?: boolean;
  id: ID;
  back: () => void;
  updateFlagsViewTask?: (updatedTask: ITask) => void;
}

const ViewTaskCardDetails = ({
  animated,
  id,
  back,
  updateFlagsViewTask
}: IViewTaskCardDetailsProps): JSX.Element => {;
  const [task, setTask] = useState<ITask>();
  const [flag, setFlag] = useState<IFlag>();
  const [space, setSpace] = useState<ISpace>();
  const [crop, setCrop] = useState<ICrop>();
  const [inventory, setInventory] = useState<IInventory>();
  const [creator, setCreator] = useState<IUser>();
  const [assignee, setAssignee] = useState<IUser>();
  const taskPatch = useRef<Record<string, any>>({});
  const status = useRef<Record<string, any>>({});
  const [pristine, setPristine] = useState(true);

  const flagCore = useRef(new FlagCore(true, false));
  const cropCore = useRef(new CropCore(false));
  const spaceCore = useRef(new SpaceCore(true));
  const customerUserCore = useRef(new CustomerUserCore(false));

  const mobiusContext = useContext(Mobius);

  const userID = localStorage.getItem('userID');

  /**
   * Query the task
   */
  const {
    error: taskError,
    data: taskData
  } = useQuery(QueryTask, {
    variables: { id }
  })

  /**
   * Query the space if the task's been queried
   */
  const {
    error: spaceError,
    data: spaceData
  } = useQuery(QuerySpace, {
    skip: !task || !task.locationProfile[0].spaceId,
    variables: {
      id: task
        ? task.locationProfile[0].spaceId
        : undefined
    }
  })

  /**
   * Query the crop if the task's been queried
   */
  const {
    error: cropError,
    data: cropData
  } = useQuery(QueryCrop, {
    skip: !task || !task.locationProfile[0].cropId,
    variables: {
      id: task
        ? task.locationProfile[0].cropId
        : undefined
    }
  })

  /**
   * Query the inventory if the task's been queried
   */
  const {
    error: inventoryError,
    data: inventoryData
  } = useQuery(QueryInventory, {
    skip: !task || !task.locationProfile[0].inventoryId,
    variables: {
      id: task
        ? task.locationProfile[0].inventoryId
        : undefined
    }
  })

  const [
    updateTask,
    {
      error: updateTaskError,
      data: updateTaskData
    }
  ] = useMutation(UpdateTask)

  // Create a Task Assignee
  const [
    createTaskAsgn,
    {
      error: createTaskAsgnError,
      data: createTaskAsgnData
    }
  ] = useMutation(CreateTaskAssignee)

  /**
   * If the task's been queried but hasn't been set,
   * set it.
   */
  useEffect((): void => {
    if (taskData) {
      const { task: [newTask] } = taskData
      setTask(newTask)
    }
  }, [taskData])

  /**
   * If the task's been queried but the flag hasn't been set,
   * set it.
   */
  useEffect((): void => {
    if (task &&
      task.flag &&
      task.flag.length > 0) {
      const { flag: [newFlag] } = task
      setFlag(newFlag)
    } else {
      setFlag(undefined)
    }
  }, [task])

  /**
   * If the task's been queried but the creator hasn't been set,
   * set it.
   */
  useEffect((): void => {
    if (task &&
      task.customerUser &&
      task.customerUser.length > 0) {
      const { customerUser: [newCreator] } = task
      setCreator(newCreator)
    } else {
      setCreator(undefined)
    }
  }, [task])

  /**
   * If the task's been queried but the assignee hasn't been set,
   * set it.
   */
  useEffect((): void => {
    if (task &&
      task.taskAssignee &&
      task.taskAssignee.length > 0) {
      const { taskAssignee: [{ customerUser: [newAssignee] }] } = task
      setAssignee(newAssignee)
    } else {
      setAssignee(undefined)
    }
  }, [task])

  /**
   * If the space's been queried but hasn't been set,
   * set it.
   */
  useEffect((): void => {
    if (spaceData) {
      const { space: [newSpace] } = spaceData
      setSpace(newSpace)
    }
  }, [spaceData])

  /**
   * If the crop's been queried but hasn't been set,
   * set it.
   */
  useEffect((): void => {
    if (cropData) {
      const { crop: [newCrop] } = cropData
      setCrop(newCrop)
    }
  }, [cropData])

  /**
   * If the inventory's been queried but hasn't been set,
   * set it.
   */
  useEffect((): void => {
    if (inventoryData) {
      const { inventory: [newInventory] } = inventoryData
      setInventory(newInventory)
    }
  }, [inventoryData])

  /**
   * -_-
   */
  useEffect((): void => {
    if (updateTaskData &&
      updateTaskData.Task.update.updatedOn !== task.updatedOn) {
      const { Task: { update: updatedDbTask } } = updateTaskData

      // Remove undefined properties from updatedDbTask with lodash
      const cleanedDbTask = pickBy(updatedDbTask, (propVal) => propVal !== undefined)
      // Only rewrite local task with populated values to avoid undefined properties
      const updatedLocalTask = { ...task, ...cleanedDbTask }

      setTask(updatedLocalTask)
    }
  }, [updateTaskData])

  /**
   * Update tasks state on map view's global context
   */
  useEffect((): void => {
    if (updateTaskData) {
      const { Task: { update: updatedDbTask } } = updateTaskData

      // Remove undefined properties from updatedDbTask with lodash
      const cleanedDbTask = pickBy(updatedDbTask, (propVal) => propVal !== undefined)
      // Only rewrite local task with populated values to avoid undefined properties
      const updatedLocalTask = { ...task, ...cleanedDbTask }
      // Cause TasksList to rerender with updated tasks
      const functionSetTasks: IFunctionTask = (currentLocalTasks: Array<ITaskOrTaskIssueModel>) => {
        const localTasks = currentLocalTasks as Array<ITask>;
        const updatedTasks = localTasks.map(task => {
          if (Number(task.id) === Number(id)) {
            // Update the local task with the user's changes
            return updatedLocalTask
          }
          return task
        })
        return updatedTasks
      }
      mobiusContext.tasks?.setTasks(functionSetTasks)

      // Update flags view with new task if flags view is parent component
      updateFlagsViewTask?.(updatedLocalTask)
    }
  }, [updateTaskData])

  /**
   * Pretty self-explanatory
   */
  const $value = (name: string, newValue: string | boolean | number): void => {
    switch (name) {
      case 'type':
        taskPatch.current[name] = newValue
        break
      case 'title':
      case 'due':
      case 'assignee':
        taskPatch.current[name] = newValue
        break
      case 'description':
        taskPatch.current[name] = newValue
        break
      case 'urgent':
        taskPatch.current[name] = newValue ? 1 : 0
        break
    }

    setPristine(false)
  }

  const $status = (name: string, newStatus: IInputStatus): void => {
    status.current[name] = newStatus
  }

  const $save = (): void => {
    const isTaskEdited = Object.values(taskPatch).length > 0
    const updatedFlagId = Number(taskPatch.current.type?.id) || undefined
    const updatesToTaskInDb = {
      id,
      description: taskPatch.current.title,
      dueDate: taskPatch.current.due,
      priority: taskPatch.current.urgent,
      notes: taskPatch.current.description,
      customerUserId: parseInt(userID),
      ...(updatedFlagId && { flagId: updatedFlagId })
    };

    if (isTaskEdited) {
      updateTask({
        variables: updatesToTaskInDb
      })
    };
    setPristine(true);
  }
  // If the user entered an assignee, create a TaskAssignee with the values in taskPatch once a Task has been updated.
  useEffect((): void => {
    if (updateTaskData && taskPatch.current.assignee) {
      const taskId = updateTaskData.Task.update.id
      createTaskAsgn({
        variables: {
          customerUserId: taskPatch.current.assignee.id,
          taskId
        }
      })
    }
  }, [updateTaskData])
  /**
   * Pretty self-explanatory
   */
  const $complete = (): void => {
    const confirm = window.confirm('Complete task?');

    if (confirm) {
      updateTask({
        variables: {
          id,
          complete: true
        }
      });
      setPristine(true);
    }
  };

  const createdOnDate = task?.createdOn
  if (task) {
    return (
      <>
        <div className='Body Details'>
          <SearchSelect
            animated={animated}
            label='Type'
            inputFacade={TextFacade}
            selectFacade={SelectFacade}
            itemFacade={FlagFacade}

            name='type'
            initialValue={flag}
            onValueChange={$value}
            onStatusChange={$status}
            readOnly={true}
            core={flagCore.current}
          />

          <Input
            animated={animated}
            label={'Title'}
            facade={TextFacade}

            name='title'
            initialValue={task.name}
            onValueChange={$value}
            onStatusChange={$status}
            readOnly={task.complete}
            core={TextCore}
          />

          {space && (
            <SearchSelect
              animated={animated}
              label='Location'
              inputFacade={TextFacade}
              selectFacade={SelectFacade}
              itemFacade={SpaceFacade}

              name='location'
              initialValue={space}
              onValueChange={$value}
              onStatusChange={$status}
              readOnly
              core={spaceCore.current}
            />
          )}

          {crop && (
            <SearchSelect
              animated={animated}
              label='Crop'
              inputFacade={TextFacade}
              selectFacade={SelectFacade}
              itemFacade={CropFacade}

              name='crop'
              initialValue={crop}
              onValueChange={$value}
              onStatusChange={$status}
              readOnly
              core={cropCore.current}
            />
          )}

          {inventory && (
            <Input
              animated={animated}
              label='Inventory'
              facade={TextFacade}

              name='inventory'
              initialValue={`${inventory.cropCode} - ${inventory.code}`}
              onValueChange={$value}
              onStatusChange={$status}
              readOnly
              core={TextCore}
            />
          )}

          <SearchSelect
            animated={animated}
            label='Creator'
            inputFacade={TextFacade}
            selectFacade={SelectFacade}
            itemFacade={CustomerUserFacade}

            name='creator'
            initialValue={creator}
            onValueChange={$value}
            onStatusChange={$status}
            readOnly
            core={customerUserCore.current}
          />
          <SearchSelect
            animated={animated}
            label='Assignee'
            inputFacade={TextFacade}
            selectFacade={SelectFacade}
            itemFacade={CustomerUserFacade}

            name='assignee'
            initialValue={assignee}
            onValueChange={$value}
            onStatusChange={$status}
            readOnly={task.complete}
            core={customerUserCore.current}
          />

          <Input
            animated={animated}
            label='Created On'
            facade={DateTimeFacade}

            name='createdOn'
            initialValue={createdOnDate}
            readOnly
            core={DateCore}
          />

          <Input
            animated={animated}
            label='Due'
            facade={DateTimeFacade}

            name='due'
            initialValue={task.dueDate}
            onValueChange={$value}
            onStatusChange={$status}
            readOnly={task.complete}
            core={DateCore}
          />

          {task.complete && (
            <Input
              animated={animated}
              label='Completed On'
              facade={DateTimeFacade}

              name='completedOn'
              initialValue={task.updatedOn}
              onValueChange={$value}
              onStatusChange={$status}
              readOnly={task.complete}
              core={DateCore}
            />
          )}

          <div className='Row'>
            <p className='Body1 Secondary'>Urgent</p>
            <div className='Space' />
            <Checkbox
              animated
              name='urgent'
              initialValue={task.priority === 1}
              onInput={$value}
              readOnly={task.complete}
            />
          </div>

          <Input
            animated={animated}
            label='Description'
            facade={TextAreaFacade}

            name='description'
            initialValue={task.body || ''}
            onValueChange={$value}
            onStatusChange={$status}
            readOnly={task.complete}
            core={TextCore}
          />
        </div>

        <div style={{ flex: '1 0 auto' }} />

        <div className='Actions'>
          {!pristine && !task?.complete && <Button
            animated={animated}
            outlined
            onClick={$complete}
          >
            Complete
          </Button>}

          {!pristine && !task?.complete && (
            <Button
              animated={animated}
              outlined
              onClick={$save}
            >
              Save
            </Button>
          )}
        </div>
      </>
    )
  }

  return (
    <div className='Body Details'>
      <div className='Input Loading' />
      <div className='Input Loading' />
      <div className='Input Loading' />
      <div className='Input Loading' />
      <div className='Input Loading' />
      <div className='Input Loading' />
      <div className='Input Loading' />
      <div className='Input Loading' />
      <div className='Input Loading' />
    </div>
  )
}

export default ViewTaskCardDetails

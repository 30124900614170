// Types
import EEntity from 'map-view/enums/entity'

// Libs
import { v4 } from 'uuid'

// React
import React, {
  useCallback,
  useContext,
  useRef,
  useState,
  useEffect
} from 'react'

import useStorage from 'react-lib/use-storage'

// React Lib
import { useIter } from 'react-lib/use-iter'

// Queries
import { QueryTaskIssue } from './queries'

// Effects
import {
  UpdateOverlays,
  UpdateTaskIssues,
  UpdateVisibleTaskIssues,
  UpdateSearchedTaskIssues,
  UpdateScroll,
  UpdateCreatingViewingTaskIssue
} from './effects'

// UI Lib
import { useInput } from 'ui-lib/hooks/use-input'
import { NoOp } from 'ui-lib/validators/no-op'
import CreateTaskIssueCard from 'tasks/components/create-task-issue-card'
import ViewTaskIssueCard from 'tasks/components/view-task-issue-card'

// Contexts
import Mobius from 'map-view/contexts/mobius'
import { TaskIssueListContext } from './contexts/TaskIssueListContext'

// MapView
import Search from '../search'
import TaskIssueItem from './task-issue-item'
import ITaskIssue from 'graphql-lib/interfaces/ITaskIssue'

import {
  EStorage
} from 'utils/storage'
import { ITab } from 'ui-lib/components/tab'

interface ITasksIssueList {
  animated?: boolean;
  invisible?: boolean;
  tabs: ITab[]
}

const TaskIssueList = ({
  animated,
  invisible,
  tabs
}: ITasksIssueList): JSX.Element => {
  // Context ///////////////////////////////////////////////////////////////////

  const {
    searchParams: {
      searchParams,
      setSearchParams,
      searchParamsUUID
    },

    selectedEntities: {
      selectedEntities,
      selectedEntitiesDiff,
      setSelectedEntities,
      selectedEntitiesUUID
    },

    visibleEntities: {
      visibleEntities,
      visibleEntitiesUUID
    },

    overlays: {
      overlays,
      selectOverlay,
      overlaysUUID
    },

    taskIssues: {
      taskIssues: _taskIssues,
      taskIssuesUUID: _taskIssuesUUID,
      setTaskIssues: _setTaskIssues
    }
  } = useContext(Mobius)
  const taskIssueListContext = useContext(TaskIssueListContext)

  // State /////////////////////////////////////////////////////////////////////

  const listRef = useRef<HTMLDivElement>()

  const searchState = useInput<string>('', NoOp)
  const visibleState = useInput<boolean>(true, NoOp)

  const [search, , searchUUID] = searchState
  const [visible, , visibleUUID] = visibleState

  const [taskIssues, setTaskIssues] = useState<[]>()
  const [visibleTaskIssues, setVisibleTaskIssues] = useState<[]>()
  const [searchedTaskIssues, setSearchedTaskIssues] = useState<[]>()

  const [listRefUUID, setListRefUUID] = useState<string>()

  const [taskIssuesUUID, setTaskIssuesUUID] = useState<string>()
  const [visibleTaskIssuesUUID, setVisibleTaskIssuesUUID] = useState<string>()
  const [searchedTaskIssuesUUID, setSearchedTaskIssuesUUID] = useState<string>()

  // Effects ///////////////////////////////////////////////////////////////////

  // Reset state when the user switches tabs
  useEffect(() => {
    taskIssueListContext.setCreatingTaskIssue(false)
    taskIssueListContext.setViewingTaskIssue(undefined)
  }, [])

  useIter(
    UpdateOverlays,

    {
      invisible,
      overlays,
      selectOverlay
    },

    [invisible,
      selectOverlay,
      overlaysUUID]
  )

  useIter(
    UpdateTaskIssues,

    {
      taskIssues: _taskIssues,

      setTaskIssues,
      setTaskIssuesUUID
    },

    [_taskIssues]
  )

  useIter(
    UpdateVisibleTaskIssues,

    {
      visibleEntities,
      taskIssues,

      setVisibleTaskIssues,
      setVisibleTaskIssuesUUID
    },

    [visibleEntitiesUUID,
      taskIssuesUUID]
  )

  useIter(
    UpdateSearchedTaskIssues,

    {
      taskIssues,
      visibleTaskIssues,
      search,
      visible,

      setSearchedTaskIssues,
      setSearchedTaskIssuesUUID
    },

    [
      taskIssuesUUID,
      visibleTaskIssuesUUID,
      searchUUID,
      visibleUUID
    ]
  )

  useIter(
    UpdateScroll,

    {
      selectedEntitiesDiff,
      listRef: listRef.current
    },

    [selectedEntitiesUUID,
      listRefUUID]
  )

  useIter(
    UpdateCreatingViewingTaskIssue,

    {
      searchParams,
      selectedEntities,
      creatingTaskIssue: taskIssueListContext.creatingTaskIssue,

      setCreatingTaskIssue: taskIssueListContext.setCreatingTaskIssue,
      setViewingTaskIssue: taskIssueListContext.setViewingTaskIssue
    },

    [searchParamsUUID,
      selectedEntitiesUUID]
  )

  // Callbacks /////////////////////////////////////////////////////////////////

  const list$ref = useCallback(
    (newVal: HTMLDivElement): void => {
      if (
        newVal &&
        !listRef.current
      ) {
        listRef.current = newVal
        setListRefUUID(v4())
      }
    },
    [listRefUUID]
  )

  const createTaskIssueCard$back = useCallback(
    (): void => {
      const newSearchParams = { ...searchParams }
      delete newSearchParams.creatingTaskIssue
      delete newSearchParams.inventoryId
      delete newSearchParams.containerId
      delete newSearchParams.positionX
      delete newSearchParams.positionY

      setSearchParams(newSearchParams)

      taskIssueListContext.setCreatingTaskIssue(false)
    },
    [searchParamsUUID]
  )

  const createTaskIssueCard$save = useCallback(
    (createTaskIssueData): void => {
      const newSearchParams = { ...searchParams }
      delete newSearchParams.creatingTaskIssue
      delete newSearchParams.inventoryId
      delete newSearchParams.containerId
      delete newSearchParams.positionX
      delete newSearchParams.positionY

      setSearchParams(newSearchParams)

      // Make TasksList and MapCanvas in browser rerender with updated tasks
      const newTaskIssue = createTaskIssueData.TaskIssue.create
      _setTaskIssues((currentLocalTaskIssues) => [newTaskIssue, ...currentLocalTaskIssues])
    },
    [searchParamsUUID,
      _taskIssuesUUID,
      _setTaskIssues]
  )

  const viewTaskIssueCard$back =
    (): void => {
      const newSelectedEntities = selectedEntities
        .filter((entity:any) => !(
          (entity.type === EEntity.TaskIssue) &&
          entity.id === taskIssueListContext.viewingTaskIssue
        ))
      taskIssueListContext.setCreatingTaskIssue(false)
      taskIssueListContext.setViewingTaskIssue(undefined)
      setSelectedEntities(newSelectedEntities)
    }

  // Render ////////////////////////////////////////////////////////////////////

  let className = 'TasksList'
  if (animated) className += ' Animated'
  if (invisible) className += ' Invisible'
  const [user] = useStorage('user', EStorage.EphemeralStorage)
  if (taskIssueListContext.creatingTaskIssue) {
    return user.admin
      ? (
      <div className={className}>
        <CreateTaskIssueCard
          animated={animated}
          batchId={searchParams.inventoryId}
          containerId={searchParams.containerId}
          positionX={searchParams.positionX}
          positionY={searchParams.positionY}
          appliedToView='map'
          onBack={createTaskIssueCard$back}
          onSave={createTaskIssueCard$save}
        />
      </div>
        )
      : (
      <p>No permission to add Task Issue.</p>
        )
  }

  if (taskIssueListContext.viewingTaskIssue) {
    return (
      <div className={className}>
        <ViewTaskIssueCard
          animated={animated}
          id={taskIssueListContext.viewingTaskIssue}
          onBack={viewTaskIssueCard$back}
          searchParams={searchParams}
          setSearchParams={setSearchParams}
        />
      </div>
    )
  }

  return (
    <div className={className}>
      <Search
        isIssuesTab={true}
        animated={animated}
        searchState={searchState}
        visibleState={visibleState}
      />

      <div
        ref={list$ref}
        className='List'
      >
        {searchedTaskIssues?.map((taskIssue: ITaskIssue, index) => {
          // always show task if it's selected
          const taskIssueSelected = !!selectedEntities
            .find((entity:any) =>
              entity.type === EEntity.Task &&
              Number(entity.id) === Number(taskIssue.id))

          // Don't show completed tasks or rejected issues
          if (!taskIssueSelected && taskIssue.rejectedAt) return

          return (
            <TaskIssueItem
              key={taskIssue.id}
              animated={animated}
              taskIssue={taskIssue}
              searchedTaskIssuesIndex={index}
            />
          )
        })}
      </div>
    </div>
  )
}

export default TaskIssueList

/* Libs */
import queryString from 'query-string'

/* React */
import React, {
  MouseEvent,
  useEffect,
  useState
} from 'react'

// React Libs
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTh } from '@fortawesome/pro-solid-svg-icons'
import { faTimes } from '@fortawesome/pro-regular-svg-icons'
import { toast } from 'react-toastify'
import useDevice from 'react-lib/use-device'

/* GraphQL */
import { gql, useQuery } from '@apollo/client'

// Utils
import GoTo from 'react-lib/go-to'
import { GetItem, EStorage } from 'utils/storage'

/* UI */
import { ITab } from 'ui-lib/components/tab';
import Details from '../view-task-issue-card-details';
import { createDate } from 'utils/dates';
import ETab from 'map-view/enums/tab';
import ITaskIssue from 'graphql-lib/interfaces/ITaskIssue';
import ILocationProfile from 'graphql-lib/interfaces/ILocationProfile'
import mapClockIcon from 'public/assets/img/flag/icon/map-clock.svg';
import ID from 'graphql-lib/interfaces/ID'

const QueryTaskIssue = gql`
  query($id: ID, $offset: Int, $limit: Int) {
    taskIssue(id: $id, offset: $offset, limit: $limit) {
      id
      createdAt
      updatedAt
      priority
      appliedToView
      description
      notes
      taskId
      rejectedAt
      convertedToTaskAt
      task {
        id
      }
      customerTaskCategory {
        name
      }
      createdBy
      createdByUser {
        id

        name
        email
        username
      }
      locationProfile {
        spaceId
        inventoryId

        positionX
        positionY
      }
    }
  }
`

export interface IViewTaskCardProps {
  outlined?: boolean;
  resting?: boolean;
  raised?: boolean;
  animated?: boolean;

  id: ID;
  tabs?: ITab[];
  onBack: () => void;
  updateFlagsViewTask?: (updatedTask: ITaskIssue) => void;
  setTaskOrIssue?: () => void | undefined;
  searchParams?: any;
  setSearchParams?: (newParams: Record<string, any>) => void;
}

const ViewTaskIssueCard = ({
  outlined,
  resting,
  raised,
  animated,
  id,
  onBack,
  updateFlagsViewTask,
  setSearchParams,
  searchParams,
  tabs,
  setTaskOrIssue
}: IViewTaskCardProps): JSX.Element => {
  const { mobile } = useDevice()

  const [taskIssue, setTaskIssue] = useState<ITaskIssue>()
  const [locationProfile, setLocationProfile] = useState<ILocationProfile>()
  const {
    error,
    data
  } = useQuery(QueryTaskIssue, { variables: { id } })

  useEffect((): void => {
    if (error) {
      toast.error("Couldn't load task-issue; please try again in a few seconds.")
    }
  }, [error])

  useEffect((): void => {
    if (data) {
      const newTaskIssue = data.taskIssue[0]
      const newLocationProfile = newTaskIssue.locationProfile[0]

      setTaskIssue(newTaskIssue)
      setLocationProfile(newLocationProfile)
    }
  }, [data])

  let className = 'Theme__Light Card ViewTaskCard'
  if (outlined) className += ' Outlined'
  if (resting) className += ' Resting'
  if (raised) className += ' Raised'
  if (animated) className += ' Animated'

  const isImageGridTask = taskIssue?.appliedToView === 'grid'
  const isMapViewTask = taskIssue?.appliedToView === 'map'

  const $imageGrid = (ev: MouseEvent<HTMLButtonElement>): void => {
    if (locationProfile?.spaceId && locationProfile?.positionX && locationProfile?.positionY) {
      const to = `/grid/space/${locationProfile.spaceId}/${new Date().toISOString()}/${locationProfile.positionX}/${locationProfile.positionY}`
      GoTo(to)
    } else {
      toast.error('There is not enough information for this location profile');
    }
  }

  const $mapView = (ev: MouseEvent<HTMLButtonElement>): void => {
    const facilities = GetItem('facilities', EStorage.EphemeralStorage)
    const facilityId = facilities[0].id
    const time = createDate(taskIssue.createdAt).toISOString()
    const taskIssueId = taskIssue.id
    const navigationTab = ETab.Issues
    const queryObject = {
      navigationTab: navigationTab,
      timeTravelOpen: true,
      overlays: JSON.stringify(['Overlay.TaskIssue']),
      selectedEntities: JSON.stringify([{ type: 'Entity.TaskIssue', id: taskIssueId }]),
      time,
      navigationOpen: false
    }

    // Workaround for a bug in MapView where even if navigationOpen is false
    // it's presence will still open the navigation 🙃
    if (!mobile) {
      queryObject.navigationOpen = true
    }

    const to = `/map/${facilityId}?${queryString.stringify(queryObject)}`
    GoTo(to)
  }
  if (!taskIssue) return null;
  const mapClockIconString = mapClockIcon as unknown as string;
  return (
    <article className={className}>
      <div className='Head'>
        <div className='Row'>
          <button type='button' onClick={onBack}>
            <FontAwesomeIcon icon={faTimes} />
          </button>

          {window.taskDistances?.[Number(taskIssue.id)] && (
            <div className='DistanceContainer'>
              <p>{window.taskDistances[Number(taskIssue.id)].toFixed(2)}m from Top</p>
            </div>
          )}
          {taskIssue
            ? <h6 className='Primary'>{taskIssue.description}</h6>
            : <h6 className='Loading' />}
          <div className='Space' />

          {isImageGridTask && (
            <button onClick={$imageGrid}>
              <FontAwesomeIcon icon={faTh} />
            </button>
          )}

          {isMapViewTask && (
            // TODO: Replace tooltip with CMP's tooltip component once apps are merged
            <div className="tooltip-container" data-text="Show image at flag creation.">
              <button onClick={$mapView}>
                <img src={mapClockIconString} alt="Map Icon" />
              </button>
            </div>
          )}
        </div>
      </div>
        <Details
          animated={animated}
          id={id}
          back={onBack}
          updateFlagsViewTask={updateFlagsViewTask}
          setSearchParams={setSearchParams}
          searchParams={searchParams}
          tabs={tabs}
          setTaskOrIssue={setTaskOrIssue}
        />
    </article>
  )
}

export default ViewTaskIssueCard

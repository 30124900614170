// Styles
import 'react-datepicker/dist/react-datepicker.css'

// Libs
import {
  addDays,
  subDays
} from 'date-fns'

// React
import React, {
  useCallback,
  useContext,
  useState
} from 'react'

// React Libs
import DatePicker from 'react-datepicker'
import { useIter } from 'react-lib/use-iter'

// Font Awesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faChevronLeft,
  faChevronRight,
  faClock,
  faSpinnerThird,
  faTimes
} from '@fortawesome/pro-regular-svg-icons'

// MapView
import Mobius from 'map-view/contexts/mobius'
import TimeTravelInput from './time-travel-input'

// Effects
import {
  UpdateNow,
  UpdateTimeIsNow
} from './effects'

interface ITimeTravelProps {
  outlined?: boolean;
  resting?: boolean;
  raised?: boolean;
  animated?: boolean;
}

const TimeTravel = ({
  outlined,
  resting,
  raised,
  animated
}: ITimeTravelProps): JSX.Element => {
  // Context ///////////////////////////////////////////////////////////////////

  const {
    searchParams: {
      searchParams,
      setSearchParam,
      searchParamsUUID
    },

    time: {
      time,
      setTime
    },

    facility: { facilityLoading },
    buildings: { buildingsLoading },
    spaces: { spacesLoading },
    areacams: { areacamsLoading },
    gridPredictions: { gridPredictionsLoading },
    inventories: { inventoriesLoading },
    containers: { containersLoading },
    inventoryStitches: { inventoryStitchesLoading },
    containerStitches: { containerStitchesLoading },
    heatmaps: { heatmapsLoading },
    spaceCharts: { spaceChartsLoading },
    inventoryCharts: { inventoryChartsLoading },
    containerCharts: { containerChartsLoading },
    tasks: { tasksLoading }
  } = useContext(Mobius)

  const loading =
    facilityLoading ||
    buildingsLoading ||
    spacesLoading ||
    areacamsLoading ||
    gridPredictionsLoading ||
    inventoriesLoading ||
    containersLoading ||
    inventoryStitchesLoading ||
    containerStitchesLoading ||
    heatmapsLoading ||
    spaceChartsLoading ||
    inventoryChartsLoading ||
    containerChartsLoading ||
    tasksLoading

  // State /////////////////////////////////////////////////////////////////////

  const [now, setNow] = useState<Date>()
  const [timeIsNow, setTimeIsNow] = useState<boolean>()

  // Effects ///////////////////////////////////////////////////////////////////

  useIter(
    UpdateNow,
    { setNow },
    [time?.now]
  )

  useIter(
    UpdateTimeIsNow,

    { time, now, setTimeIsNow },

    [time?.now, now]
  )

  // Callbacks /////////////////////////////////////////////////////////////////

  const $toggleOpen = useCallback(
    (): void => {
      const newOpen = !searchParams.timeTravelOpen
        ? true
        : undefined

      setSearchParam('timeTravelOpen', newOpen)
    },
    [searchParamsUUID]
  )

  const datePicker$change = useCallback(
    (newDate): void => setTime(newDate),
    [time]
  )

  const yesterday$click = useCallback(
    (): void => {
      if (
        !time ||
        !time.now
      ) {
        return
      }

      const newTime = subDays(time.now, 1)
      setTime(newTime)
    },
    [time]
  )

  const tomorrow$click = useCallback(
    (): void => {
      if (
        !time ||
        !time.now ||
        timeIsNow
      ) {
        return
      }

      const newTime = addDays(time.now, 1)
      setTime(newTime)
    },

    [time, timeIsNow]
  )

  // Render ////////////////////////////////////////////////////////////////////

  let className = 'TimeTravel'
  if (outlined) className += ' Outlined'
  if (resting) className += ' Resting'
  if (raised) className += ' Raised'
  if (animated) className += ' Animated'

  let contentClassName = 'Content'
  if (searchParams.timeTravelOpen) contentClassName += ' Open'
  else contentClassName += ' Closed'

  let toggleIcon = searchParams.timeTravelOpen
    ? faTimes
    : faClock

  if (loading) {
    toggleIcon = faSpinnerThird
  }

  return (
    <div className={className}>
      <div className={contentClassName}>
        <DatePicker
          customInput={<TimeTravelInput onClick={(ev) => null}/>}
          dateFormat='P p'
          maxDate={now}
          onChange={datePicker$change}
          selected={time?.now}
          showTimeSelect
          withPortal
        />

        <button onClick={yesterday$click}>
          <FontAwesomeIcon icon={faChevronLeft} />
        </button>

        <button
          disabled={timeIsNow}
          onClick={tomorrow$click}
        >
          <FontAwesomeIcon icon={faChevronRight} />
        </button>
      </div>

      <button onClick={$toggleOpen}>
        <FontAwesomeIcon
          icon={toggleIcon}
          spin={loading}
        />
      </button>
    </div>
  )
}

export default TimeTravel

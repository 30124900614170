// React
import React, {
  MouseEvent
} from 'react'

// Font Awesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSpinnerThird } from '@fortawesome/pro-solid-svg-icons'
import { v4 } from 'uuid'
import ID from 'graphql-lib/interfaces/ID';

interface IButtonProps {
  id?: ID;
  animated?: boolean;
  flush?: boolean;
  outlined?: boolean;
  resting?: boolean;
  raised?: boolean;
  propClassNames?: string
  disabled?: boolean;
  placeholder?: boolean;
  loading?: boolean;
  showTextWhileLoading?: boolean
  onClick?: (ev: MouseEvent<HTMLButtonElement>) => void;
  children: any;
}

const Button = ({
  id,
  animated,
  flush,
  outlined,
  resting,
  raised,
  propClassNames,
  disabled,
  placeholder,
  loading,
  showTextWhileLoading,
  onClick,
  children
}: IButtonProps): JSX.Element => {
  // Render ////////////////////////////////////////////////////////////////////

  const className = `Button${propClassNames ? ` ${propClassNames}` : ''}
  ${animated && ' Animated'}${flush ? ' Flush' : ''}${outlined ? ' Outlined' : ''}
  ${resting ? ' Resting' : ''}${raised ? ' Raised' : ''}${disabled ? ' Disabled' : ''}
  ${placeholder ? ' Placeholder' : ''}${loading ? ' Loading' : ''}`;

  const idVal = id || children.toString().concat(v4()).toLowerCase().replace(' ', '-')
  const props = {
    disabled,
    onClick: !disabled ? onClick : (): void => null,
    className,
    id: idVal
  }
  return (
    <button {...props}>
      { loading && <FontAwesomeIcon icon={faSpinnerThird} spin/>}
      { !placeholder && ((loading && showTextWhileLoading) || !loading) && children}
    </button>
  )
}

export default Button

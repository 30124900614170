/**
 * @author Miras Absar <mabsar@iunu.com>
 */

export const Tasks = '/tasks/task'
export const CreateTask = `${Tasks}/create`
export const ViewTask = `${Tasks}/:id`
export const TasksIssue = '/tasks/issue'
export const CreateTaskIssue = `${TasksIssue}/create`
export const ViewTaskIssue = `${TasksIssue}/:id`

/**
 * @author Miras Absar <mabsar@iunu.com>
 */

// Types
import EDiff from 'map-view/enums/diff'
import EEntity from 'map-view/enums/entity'
import IChart from 'map-view/interfaces/chart'
import IChartGroup from 'map-view/interfaces/chart-group'
import IDiff from 'map-view/interfaces/diff'
import IEntity from 'map-view/interfaces/entity'

// Utils
import Has from 'utils/has'
import { ChartOrder } from 'map-view/utils/chart'

// Libs
import { v4 } from 'uuid'

import ID from 'graphql-lib/interfaces/ID';

const UpdateSelectedCharts = ({
  selectedEntities,
  selectedEntitiesDiff,
  inventoryStitches,
  containerStitches,
  spaceCharts,
  inventoryCharts,
  containerCharts,

  setSelectedCharts,
  setSelectedChartsUUID
}: {
  selectedEntities: Array<IEntity>
  selectedEntitiesDiff: IDiff<IEntity>[];
  inventoryStitches: any[];
  containerStitches: any[];
  spaceCharts: IChart[];
  inventoryCharts: IChart[];
  containerCharts: IChart[];

  setSelectedCharts: (newVal: IChart[]) => void;
  setSelectedChartsUUID: (newVal: string) => void;
}): void => {
  if (
    !selectedEntities ||
    selectedEntities.length === 0
  ) {
    return
  }

  const selectedSpace = selectedEntities
    .find((entity: IEntity) => entity.type === EEntity.Space)

  const selectedInventory = selectedEntities
    .find((entity: IEntity) => entity.type === EEntity.Inventory)

  const selectedContainer = selectedEntities
    .find((entity: IEntity) => entity.type === EEntity.Container)

  if (
    !selectedSpace &&
    !selectedInventory &&
    !selectedContainer
  ) {
    setSelectedCharts([])
    setSelectedChartsUUID(v4())
    return
  }

  if (
    //selectedEntitiesDiff[0].value.type === EEntity.Inventory &&
    selectedInventory &&
    !!inventoryCharts &&
    inventoryCharts.length > 0
  ) {
    //const selectedInventory = selectedEntitiesDiff[0].value
    let selectedInventoryCharts = inventoryCharts
      .filter(chart => chart.parentID == selectedInventory.id)

    if (
      inventoryStitches &&
      inventoryStitches.length > 0
    ) {
      const inventoryStitch = inventoryStitches
        .find(inventoryStitch =>
          String(inventoryStitch.inventoryId) === String(selectedInventory.id) &&
          Has(inventoryStitch.width) &&
          Has(inventoryStitch.height) &&
          Has(inventoryStitch.tilesUrl))

      if (inventoryStitch && spaceCharts) {
        const selectedSpaceCharts = spaceCharts
          .filter(chart => String(chart.parentID) === String(inventoryStitch.spaceId))

        selectedInventoryCharts = [...selectedInventoryCharts, ...selectedSpaceCharts]
      }
    }

    selectedInventoryCharts
      .sort((chartA, chartB) => ChartOrder[chartA.chart] - ChartOrder[chartB.chart])

    setSelectedCharts(selectedInventoryCharts)
    setSelectedChartsUUID(v4())
    return
  }

  if (
    //selectedEntitiesDiff[0].value.type === EEntity.Container &&
    selectedContainer &&
    !!containerCharts &&
    containerCharts.length > 0
  ) {
    //const selectedContainer = selectedEntitiesDiff[0].value
    let selectedContainerCharts = containerCharts
      .filter(chart => String(chart.parentID) === String(selectedContainer.id))

    if (
      containerStitches &&
      containerStitches.length > 0
    ) {
      const containerStitch = containerStitches
        .find(containerStitch =>
          String(containerStitch.containerId) === String(selectedContainer.id) &&
          Has(containerStitch.width) &&
          Has(containerStitch.height) &&
          Has(containerStitch.tilesUrl))

      if (containerStitch && spaceCharts) {
        const selectedSpaceCharts = spaceCharts
          .filter(chart => String(chart.parentID) === String(containerStitch.spaceId))

        selectedContainerCharts = [...selectedContainerCharts, ...selectedSpaceCharts]
      }
    }

    selectedContainerCharts
      .sort((chartA, chartB) => ChartOrder[chartA.chart] - ChartOrder[chartB.chart])

    setSelectedCharts(selectedContainerCharts)
    setSelectedChartsUUID(v4())
    return
  }

  if (
    //selectedEntitiesDiff[0].value.type === EEntity.Space &&
    selectedSpace &&
    !!spaceCharts &&
    spaceCharts.length > 0
  ) {
    //const selectedSpace = selectedEntitiesDiff[0].value
    const selectedSpaceCharts = spaceCharts
      .filter(chart => String(chart.parentID) === String(selectedSpace.id))

    selectedSpaceCharts
      .sort((chartA, chartB) => ChartOrder[chartA.chart] - ChartOrder[chartB.chart])

    setSelectedCharts(selectedSpaceCharts)
    setSelectedChartsUUID(v4())
    return
  }
}

const UpdateVisibleCharts = ({
  visibleEntities,
  spaceCharts,
  // inventoryCharts,
  // containerCharts,

  setVisibleSpaces,
  setVisibleCharts,
  setVisibleSpacesUUID,
  setVisibleChartsUUID
}: {
  visibleEntities: IEntity[];
  spaceCharts: IChart[];
  // inventoryCharts: IChart[];
  // containerCharts: IChart[];

  setVisibleSpaces: (newVal: IEntity[]) => void;
  setVisibleCharts: (newVal: IChart[]) => void;
  setVisibleSpacesUUID: (newVal: string) => void;
  setVisibleChartsUUID: (newVal: string) => void;
}): void => {
  if (!visibleEntities) {
    return
  }

  let newVisibleSpaces: IEntity[] = []
  let newVisibleCharts: IChart[] = []
  if (spaceCharts) {
    const visibleSpaces = visibleEntities
      .filter(entity => entity.type === EEntity.Space)

    const visibleSpacesMap = visibleSpaces
      .reduce(
        (visibleSpaces, entity) => {
          visibleSpaces[entity.id] = entity
          return visibleSpaces
        },
        {} as Record<ID, IEntity>
      )

    const visibleSpaceCharts = spaceCharts
      .filter(chart =>
        chart.parentType === EEntity.Space &&
        !!visibleSpacesMap[chart.parentID])

    newVisibleSpaces = [...newVisibleSpaces, ...visibleSpaces]
    newVisibleCharts = [...newVisibleCharts, ...visibleSpaceCharts]
  }

  setVisibleSpaces(newVisibleSpaces)
  setVisibleCharts(newVisibleCharts)
  setVisibleSpacesUUID(v4())
  setVisibleChartsUUID(v4())
}

const UpdateVisibleChartGroups = ({
  visibleCharts,

  setVisibleChartGroups,
  setVisibleChartGroupsUUID
}: {
  visibleCharts: IChart[];

  setVisibleChartGroups: (newVal: IChartGroup[]) => void;
  setVisibleChartGroupsUUID: (newVal: string) => void;
}): void => {
  if (!visibleCharts) {
    return
  }

  const newVisibleChartGroups = visibleCharts
    .reduce(
      (newVisibleChartGroups, chart) => {
        let chartGroup = newVisibleChartGroups
          .find(chartGroup => chartGroup.chart === chart.chart)

        if (!chartGroup) {
          chartGroup = {
            charts: [],

            chart: chart.chart,
            min: Number.MAX_VALUE,
            max: Number.MIN_VALUE
          }

          newVisibleChartGroups.push(chartGroup)
        }

        chartGroup.charts.push(chart)
        const values = chartGroup.charts
          .map(chart => {
            const value = chart.value as Array<[number, number]>
            if (value) { return value.slice(-1)[0][1] }
          })

        chartGroup.min = Math.min(...values)
        chartGroup.max = Math.max(...values)

        return newVisibleChartGroups
      },
      [] as IChartGroup[]
    )
    .sort((chartGroupA, chartGroupB) => ChartOrder[chartGroupA.chart] - ChartOrder[chartGroupB.chart])

  setVisibleChartGroups(newVisibleChartGroups)
  setVisibleChartGroupsUUID(v4())
}

export {
  UpdateSelectedCharts,
  UpdateVisibleCharts,
  UpdateVisibleChartGroups
}

/**
 * @author Trevor Hudson <thudson@iunu.com>
 */

import { parse, stringify } from 'query-string'
import GoTo from 'react-lib/go-to'

const getSearchParams = (): any => {
  const searchParams = parse(location.search)
  return searchParams
}

const setSearchParams = (searchParams: any): void => {
  const to = `${location.pathname}?${stringify(searchParams)}`
  GoTo(to)
}

export {
  getSearchParams,
  setSearchParams
}

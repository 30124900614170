import React, { useContext, useEffect } from 'react'
import EEntity from 'map-view/enums/entity'
import ITaskIssue from 'graphql-lib/interfaces/ITaskIssue'

// Icons
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFlag } from '@fortawesome/pro-solid-svg-icons'

// Contexts
import { TaskIssueListContext } from '../contexts/TaskIssueListContext'
import Mobius, { ITaskOrTaskIssueModel } from 'map-view/contexts/mobius'

// Import Custom Components
import Button from 'ui-lib/components/button'

// GraphQL Queries
import { useMutation } from '@apollo/client'
import { UpdateTaskIssue } from '../task-issue-item/queries'
import IEntity from 'map-view/interfaces/entity'

interface IPlaceTask {
  taskIssue: ITaskIssue
  searchedTaskIssuesIndex: number
}
const PlaceTaskButtons = ({ taskIssue }: IPlaceTask) => {
  // Contexts
  const taskIssueListContext = useContext(TaskIssueListContext)
  const {
    taskIssues: {
      taskIssues: _taskIssues,
      taskIssuesUUID: _taskIssuesUUID,
      setTaskIssues: _setTaskIssues
    },
    selectedEntities: {
      selectedEntities,
      setSelectedEntities
    }
  } = useContext(Mobius)
  // GraphQL Mutations /////////////////////////////////////////////////////////
  const [
    updateTaskIssue,
    {
      error: updateTaskIssueError,
      data: updateTaskIssueData
    }
  ] = useMutation(UpdateTaskIssue)

  const dismissIssue = () => {
  // Deselect the issue
    const newSelectedEntities = selectedEntities
      .filter((entity) => !((entity.type === EEntity.TaskIssue) && entity.id === taskIssue.id))

    setSelectedEntities(newSelectedEntities)
    taskIssueListContext.setViewingTaskIssue(undefined)

    // Make a call to update the date issue was rejected on in the backend
    const currentDateTime = new Date().toISOString()
    updateTaskIssue({
      variables: {
        id: taskIssue.id,
        rejectedAt: currentDateTime
      }
    })

    // Update the ancestor context for all of map view to reflect newly dismissed issue.
    _setTaskIssues((existingTaskIssues: Array<ITaskOrTaskIssueModel>) => existingTaskIssues
      ?.filter(existingTaskIssue => {
        return existingTaskIssue.id !== taskIssue.id
      })
    )
  }

  const transformToTask = (): void => {
    const confirm = window.confirm('Transform to a task?')
    if (confirm) {
      updateTaskIssue({
        variables: {
          id: taskIssue.id,
          convertToTask: true
        }
      })
    }
  }

  return (
  <div className="place-flag-buttons-container">
    <Button
      outlined
      onClick={dismissIssue}
    >
      Dismiss
    </Button>

    <Button
      outlined
      propClassNames='place-flag-button'
      onClick={transformToTask}
    >
      Convert to Task
      <FontAwesomeIcon icon={faFlag} />
    </Button>
  </div>
  )
}

export default PlaceTaskButtons

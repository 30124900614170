/**
 * @author Miras Absar <mabsar@iunu.com>
 */

// React Libs
import { toast } from 'react-toastify'
import GoTo from 'react-lib/go-to'

// Routes
import { SignInRoutes } from 'authorization/components/sign-in'

const HandleResetPassword = ({
  loading,
  error,
  data
}: {
  loading: boolean;
  error: Error;
  data: any;
}): void => {
  if (!loading) {
    if (error) {
      toast.error("We couldn't reset your password, your link may be expired.")
    } else if (data) {
      toast.info("We've reset your password.")
      GoTo(SignInRoutes[0])
    }
  }
}

export { HandleResetPassword }

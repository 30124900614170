// Types
import EEntity from '../../enums/entity'
import IAreacam from 'graphql-lib/interfaces/IAreacam'
import ITask from 'graphql-lib/interfaces/ITask'
import IContainer from '../../interfaces/container'
import ITaskElement from '../../interfaces/task-element'
import ITaskIssueElement from '../../interfaces/task-issue-element'
import ITaskRollupElement from '../../interfaces/task-rollup-element'
import IContainerMeta from '../../interfaces/container-meta'
import ISpaceMeta from '../../interfaces/space-meta'
import { IInventoryMeta } from '../../interfaces/metas'
import OverlayPositioning from 'ol/OverlayPositioning'

// Utils
import isVoid from 'utils/is-void';
import { isNil } from 'utils/ts-utils';

// OpenLayers - Overlays
import Overlay from 'ol/Overlay'

// OpenLayers - Extent
import {
  extend as extendExtent,
  Extent,
  getCenter as getExtentCenter
} from 'ol/extent'

// OpenLayers - Misc
import Polygon from 'ol/geom/Polygon'

// MapView
import '../../overlays/sensor'
import '../../overlays/areacam'
import '../../overlays/task'
import '../../overlays/task-rollup'
import '../../overlays/issue-rollup'
import '../../overlays/tag'
import ITaskIssue from 'graphql-lib/interfaces/ITaskIssue'
import IInventory from 'graphql-lib/interfaces/IInventory'
import IInventoryStitch from 'graphql-lib/interfaces/IInventoryStitch'
import IEntity from 'map-view/interfaces/entity'
import { ISensor } from 'graphql-lib/interfaces/ISensorDevice'
import IDiff from 'map-view/interfaces/diff'
import EOverlay from 'map-view/enums/overlay'
import { Collection } from 'ol'
import IContainerStich from 'graphql-lib/interfaces/IContainerStitch'
import IUser from 'graphql-lib/interfaces/IUser'
import ISpace from 'graphql-lib/interfaces/ISpace'

// Local types
interface IMetas {
  [index: string]: IContainerMeta | ISpaceMeta
}

const PTScale = 1000000000000
const ScalePT = (pt: number): number => Math.round(pt * PTScale)
const UnscalePT = (pt: number): number => pt / PTScale

const getTaskMeta = (taskIssue: ITaskIssue, metas: IMetas) => !isNil(taskIssue.locationProfile[0].inventoryId)
  ? metas.inventoryMetas[taskIssue.locationProfile[0].inventoryId] as IInventoryMeta
  : metas.containerMetas[taskIssue.locationProfile[0].containerId] as unknown as IContainerMeta;

const getTaskIssuePt = (taskMeta: any, taskIssue: ITaskIssue) => taskMeta.projectiveTransformation.transform([
  ScalePT(taskIssue.locationProfile[0].positionX),
  ScalePT(taskIssue.locationProfile[0].positionY)
])

const CreateTaskIssueRollups = ({
  entityName,
  rollupLayerEntities,
  metas,
  overlays,
  taskIssues
}: ICreateTaskIssueRollupsProps) => {
  if (!rollupLayerEntities?.length) return;
  rollupLayerEntities.forEach(entity => {
    const entityMeta = metas[entityName + 'Metas'][entity.id]
    if (!entityMeta) {
      return
    }

    const entityTaskIssues = taskIssues
      .filter((taskIssue: ITaskIssue) => {
        if (taskIssue.rejectedAt || taskIssue.convertedToTaskAt) {
          return false;
        }
        const taskMeta = getTaskMeta(taskIssue, metas);

        if (!taskMeta) {
          return false;
        }

        const taskIssuePt = getTaskIssuePt(taskMeta, taskIssue);

        const entityPolygon = new Polygon([entityMeta.dstPts])
        return entityPolygon.intersectsCoordinate(taskIssuePt)
      })

    // Find the boundaries/extent of the entity
    const entityTaskIssuesExtent = entityTaskIssues.reduce(
      (acc, taskIssue: ITaskIssue) => {
        const taskMeta = getTaskMeta(taskIssue, metas);
        const taskIssuePt = getTaskIssuePt(taskMeta, taskIssue);
        const taskIssueExtent = [ ...taskIssuePt, ...taskIssuePt ] as Extent;
        if (acc.length === 0) {
          return taskIssueExtent;
        }
        return extendExtent(acc as Extent, taskIssueExtent);
      },
      []
    );

    // Check if any of the tasks in the entity are urgent
    if (entityTaskIssuesExtent.length > 0) {
      const entityTaskIssuesUrgent = entityTaskIssues.some(taskIssue => !!taskIssue.priority)

      // Create the entity's task rollup
      const assignTypeToEntityTaskIssuesExtent = entityTaskIssuesExtent
      const taskIssueRollupPt = getExtentCenter(assignTypeToEntityTaskIssuesExtent as Extent)
      const taskIssueRollupElement: ITaskRollupElement = document.createElement('issue-rollup-overlay')
      taskIssueRollupElement.count = { value: String(entityTaskIssuesExtent.length) }
      taskIssueRollupElement.urgent = { value: entityTaskIssuesUrgent }
      taskIssueRollupElement.onclick = (ev: Event): void => ev.stopPropagation()
      taskIssueRollupElement.onpointerdown = (ev: Event): void => ev.stopPropagation()

      const taskIssueRollupOverlay = new Overlay({
        element: taskIssueRollupElement,
        position: taskIssueRollupPt,
        positioning: 'center-center' as OverlayPositioning,
        stopEvent: false
      })

      taskIssueRollupOverlay.set('type', EEntity.TaskIssue)
      taskIssueRollupOverlay.set('id', entityTaskIssues[0].id)
      taskIssueRollupOverlay.set('point', taskIssueRollupPt)
      taskIssueRollupOverlay.set(`${entityName}Rollup`, true)

      // Add this entity's rollup to the array of all overlays
      overlays.push(taskIssueRollupOverlay)
    }
  })
}

const DeleteAreacamOverlays = (
  overlays: Collection<Overlay>
): void => {
  const indices = overlays
    .getArray()
    .map((o, i) => ({
      type: o.get('type'),
      index: i
    }))
    .filter(e => e.type === EEntity.Areacam)
    .map(e => e.index)
    .reverse()

  indices
    .forEach(i => overlays
      .removeAt(i)
      .dispose())
}

const CreateAreacamOverlays = (
  areacams: Array<IAreacam>,
  overlays: Collection<Overlay>
): void => {
  DeleteAreacamOverlays(overlays)

  areacams
    .filter((a: IAreacam): boolean => a.areaImageRecord.length > 0)
    .forEach((areacam: IAreacam): void => {
      const position = [
        areacam.latitude,
        areacam.longitude
      ]

      let positioning = 'top-left' as OverlayPositioning;
      if (
        Number(areacam.id) === 1055 ||
        Number(areacam.id) === 1066 ||
        Number(areacam.id) === 1058 ||
        Number(areacam.id) === 1103 ||
        Number(areacam.id) === 1079 ||
        Number(areacam.id) === 1081 ||
        Number(areacam.id) === 1048 ||
        Number(areacam.id) === 1055 ||
        Number(areacam.id) === 1055 ||
        Number(areacam.id) === 1055
      ) {
        positioning = 'top-right' as OverlayPositioning;
      }

      const element: { areacam?: IAreacam } & HTMLElement = document.createElement('areacam-overlay')

      element.areacam = areacam

      const areacamOverlay = new Overlay({
        id: areacam.id,
        element,
        stopEvent: true,
        positioning
      })

      areacamOverlay.set('type', EEntity.Areacam)
      areacamOverlay.set('id', areacam.id)
      areacamOverlay.set('position', position)
      areacamOverlay.set('point', position)

      overlays.push(areacamOverlay)
    })
}

const CreateSensorOverlays = (
  overlayDiff: IDiff<EOverlay>,
  spaces: Array<ISpace>,
  sensors: Array<ISensor>,
  spaceMetas: ISpaceMeta,
  overlays: Collection<Overlay>
): void => {
  spaces
    .forEach(space => {
      const measurement = overlayDiff.value.split('.')[1].toLocaleLowerCase()
      const spaceMeta = spaceMetas[Number(space.id)]
      const sensor = sensors
        .filter(s => s.spaceId === space.id)
        .filter(s => measurement in s.sensorRecord[0])[0]
      if (
        spaceMeta &&
        sensor
      ) {
        let sensorPt
        if (
          isVoid(sensor.relativeX) ||
          isVoid(sensor.relativeY)
        ) {
          sensorPt = getExtentCenter(spaceMeta.extent)
        } else {
          sensorPt = spaceMeta.projectiveTransformation.transform(
            [sensor.relativeX, sensor.relativeY].map(ScalePT)
          )
        }

        const sensorElement: { sensor?: ISensor, chart?: String, measurement?: String, onClick?: [() => null]} & HTMLElement = document.createElement('sensor-overlay')
        sensorElement.sensor = sensor
        sensorElement.chart = overlayDiff.value
        sensorElement.measurement = measurement
        sensorElement.onClick = [() => null]

        const sensorOverlay = new Overlay({
          element: sensorElement,
          position: sensorPt,
          positioning: 'center-center' as OverlayPositioning,
          stopEvent: false
        })

        sensorOverlay.set('type', EEntity.SensorDevice)
        sensorOverlay.set('id', sensor.id)
        sensorOverlay.set('measurement', measurement)
        sensorOverlay.set('point', sensorPt)

        overlays.push(sensorOverlay)
      }
    })
}

const DeleteSensorOverlays = (
  overlayDiff: IDiff<EOverlay>,
  overlays: Collection<Overlay>
): void => {
  const measurement = overlayDiff.value.split('.')[1].toLocaleLowerCase()

  const indices = overlays
    .getArray()
    .map((o: Overlay, i: number) => ({
      type: o.get('type'),
      id: o.get('id'),
      measurement: o.get('measurement'),
      index: i
    }))
    .filter(e => e.type === EEntity.SensorDevice)
    .filter(e => e.measurement === measurement)
    .map(e => e.index)
    .reverse()

  indices
    .forEach(i => overlays
      .removeAt(i)
      .dispose())
}

const CreateTaskRollups = ({
  entityName,
  rollupLayerEntities,
  metas,
  overlays,
  tasks
}: ICreateTaskRollupsProps) => {
  if (!rollupLayerEntities?.length) return;
  rollupLayerEntities.forEach(entity => {
    const entityMeta = metas[entityName + 'Metas'][entity.id]
    if (!entityMeta) {
      return
    }

    // Get the tasks for the entity we'll be making rollups in
    const entityTasks = tasks
      .filter((task: ITask) => {
        // Don't show completed tasks, rejected issues, or tasks that don't match the selected tab
        if (task.complete) return false;

        const taskMeta = task.locationProfile[0].inventoryId
          ? metas.inventoryMetas[task.locationProfile[0].inventoryId]
          : metas.containerMetas[task.locationProfile[0].containerId]

        if (!taskMeta) {
          return false;
        }

        const taskPt = taskMeta.projectiveTransformation.transform([
          ScalePT(task.locationProfile[0].positionX),
          ScalePT(task.locationProfile[0].positionY)
        ])

        const entityPolygon = new Polygon([entityMeta.dstPts])
        return entityPolygon.intersectsCoordinate(taskPt)
      })

    // Find the boundaries/extent of the entity
    const entityTasksExtent = entityTasks
      .reduce(
        (acc, task) => {
          const taskMeta = task.locationProfile[0].inventoryId
            ? metas.inventoryMetas[task.locationProfile[0].inventoryId]
            : metas.containerMetas[task.locationProfile[0].containerId]

          if (!taskMeta) {
            return
          }

          const taskPt = taskMeta.projectiveTransformation.transform([
            ScalePT(task.locationProfile[0].positionX),
            ScalePT(task.locationProfile[0].positionY)
          ])

          const taskExtent = [...taskPt, ...taskPt]

          if (acc.length === 0) {
            return taskExtent
          } else {
            const accToType = acc as Extent;
            const taskExtentToType = taskExtent as Extent;
            return extendExtent(accToType, taskExtentToType)
          }
        },
        []
      )

    // Check if any of the tasks in the entity are urgent
    const entityTasksUrgent = entityTasks
      .reduce(
        (acc, cur) => acc || !!cur.priority,
        false
      )

    if (entityTasks.length > 0) {
      // Create the entity's task rollup
      const entityTasksExtentToType = entityTasksExtent as Extent;
      const taskRollupPt = getExtentCenter(entityTasksExtentToType)
      const taskRollupElement: ITaskRollupElement = document.createElement('task-rollup-overlay')
      taskRollupElement.count = { value: String(entityTasks.length) }
      taskRollupElement.urgent = { value: Boolean(entityTasksUrgent) }
      taskRollupElement.onclick = (ev: Event): void => ev.stopPropagation()
      taskRollupElement.onpointerdown = (ev: Event): void => ev.stopPropagation()

      const taskRollupOverlay = new Overlay({
        element: taskRollupElement,
        position: taskRollupPt,
        positioning: 'center-center' as OverlayPositioning,
        stopEvent: false
      })

      taskRollupOverlay.set('type', EEntity.Task)
      taskRollupOverlay.set('id', entityTasks[0].id)
      taskRollupOverlay.set('point', taskRollupPt)
      taskRollupOverlay.set(`${entityName}Rollup`, true)

      // Add this entity's rollup to the array of all overlays
      overlays.push(taskRollupOverlay)
    }
  })
}

const CreateTaskOverlays = (
  spaces: ISpace[],
  containers: IContainer[],
  tasks: ITask[],
  spaceMetas: ISpaceMeta,
  inventoryMetas: IInventoryMeta,
  containerMetas: IContainerMeta,
  overlays: Collection<Overlay>,
  searchParams: { selectedEntities: string }
): void => {
  // Create each individual task overlay
  tasks
    .forEach((task: ITask) => {
      if (task.complete) {
        return
      }

      const meta = task.locationProfile[0].inventoryId
        ? inventoryMetas[task.locationProfile[0].inventoryId]
        : containerMetas[task.locationProfile[0].containerId]

      if (meta) {
        const taskPt = meta.projectiveTransformation.transform([
          ScalePT(task.locationProfile[0].positionX),
          ScalePT(task.locationProfile[0].positionY)
        ])

        const selectedTask = JSON.parse(searchParams.selectedEntities ?? '[]').find(
          (entity: IEntity) => entity.type === EEntity.Task
        )
        const selectedTaskId = selectedTask ? selectedTask.id : null
        const isThisTaskCurrentlySelected = String(task.id) === String(selectedTaskId)

        // Icons are NOT react components. They are components rendered with hybrids.js
        // hybrids.js looks for HTML elements we've named <task-overlay> and renders the properties we set below
        const taskElement: ITaskElement = document.createElement('flag-overlay')

        taskElement.task = task
        taskElement.isSelected = Object(isThisTaskCurrentlySelected)
        taskElement.onclick = (ev: Event): void => ev.stopPropagation()
        taskElement.onpointerdown = (ev: Event): void => ev.stopPropagation()

        const taskOverlay = new Overlay({
          element: taskElement,
          position: taskPt,
          positioning: 'center-center' as OverlayPositioning,
          stopEvent: false
        })

        taskOverlay.set('type', EEntity.Task)
        taskOverlay.set('id', task.id)
        taskOverlay.set('point', taskPt)

        overlays.push(taskOverlay)
      }
    })

  // Get props ready to create task rollups
  const createRollupProps = {
    overlays,
    metas: { containerMetas, spaceMetas },
    tasks
  }

  // Create first layer of rollups based on spaces.
  // The rollup overlays consolidate tasks on the map
  // when the user is zoomed out.
  // Space rollups are shown at the highest zoom level.
  CreateTaskRollups({
    entityName: 'space', 
    rollupLayerEntities: spaces, 
    ...createRollupProps
  })
  
  // Create second layer of rollups based on containers.
  // The rollup overlays consolidate tasks on the map
  // when the user is zoomed out.
  // Container rollups are shown at the medium zoom level.
  CreateTaskRollups({
    entityName: 'container', 
    rollupLayerEntities: containers,
    ...createRollupProps
  })
}

const CreateTaskIssueOverlays = (
  spaces: ISpace[],
  containers: IContainer[],
  taskIssues: ITaskIssue[],
  spaceMetas: ISpaceMeta,
  inventoryMetas: IInventoryMeta,
  containerMetas: IContainerMeta,
  overlays: Collection<Overlay>,
  searchParams: {selectedEntities: string},
  user: IUser
): void => {
  // Create each individual task overlay
  taskIssues
    .forEach((taskIssue: ITaskIssue) => {
      if (!user.isAdmin || taskIssue.rejectedAt || taskIssue.convertedToTaskAt) {
        return
      }

      const meta = !isNil(taskIssue.locationProfile[0].inventoryId)
        ? inventoryMetas[taskIssue.locationProfile[0].inventoryId]
        : containerMetas[taskIssue.locationProfile[0].containerId]

      if (meta) {
        const taskIssuePt = meta.projectiveTransformation.transform([
          ScalePT(taskIssue.locationProfile[0].positionX),
          ScalePT(taskIssue.locationProfile[0].positionY)
        ])

        const selectedTaskIssue = JSON.parse(searchParams.selectedEntities ?? '[]').find(
          (entity: IEntity) => entity.type === EEntity.TaskIssue
        )
        const selectedTaskId = selectedTaskIssue ? selectedTaskIssue.id : null
        const isThisTaskIssueCurrentlySelected = String(taskIssue.id) === String(selectedTaskId)

        // Icons are NOT react components. They are components rendered with hybrids.js
        // hybrids.js looks for HTML elements we've named <task-overlay> and renders the properties we set below
        const taskIssueElement: ITaskIssueElement = document.createElement('issue-overlay')

        taskIssueElement.taskIssue = taskIssue
        taskIssueElement.isSelected = Object(isThisTaskIssueCurrentlySelected)
        taskIssueElement.onclick = (ev: Event): void => ev.stopPropagation()
        taskIssueElement.onpointerdown = (ev: Event): void => ev.stopPropagation()

        const taskIssueOverlay = new Overlay({
          element: taskIssueElement,
          position: taskIssuePt,
          positioning: 'center-center' as OverlayPositioning,
          stopEvent: false
        })

        taskIssueOverlay.set('type', EEntity.TaskIssue)
        taskIssueOverlay.set('id', taskIssue.id)
        taskIssueOverlay.set('point', taskIssuePt)

        overlays.push(taskIssueOverlay)
      }
    })

  // Get props ready to create task rollups
  const createRollupProps = {
    overlays,
    metas: { containerMetas, spaceMetas },
    taskIssues
  }

  // Create first layer of rollups based on spaces.
  // The rollup overlays consolidate tasks on the map
  // when the user is zoomed out.
  // Space rollups are shown at the highest zoom level.
  CreateTaskIssueRollups({
    entityName: 'space',
    rollupLayerEntities: spaces,
    ...createRollupProps
  })

  // Create second layer of rollups based on containers.
  // The rollup overlays consolidate tasks on the map
  // when the user is zoomed out.
  // Container rollups are shown at the medium zoom level.
  CreateTaskIssueRollups({
    entityName: 'container',
    rollupLayerEntities: containers,
    ...createRollupProps
  })
}

interface ICreateTaskRollupsProps {
  entityName: string,
  rollupLayerEntities: IContainer[] | ISpace[],
  metas: IMetas
  overlays: Collection<Overlay>
  tasks: Array<ITask>
}

interface ICreateTaskIssueRollupsProps {
  entityName: string,
  rollupLayerEntities: Array<IContainer> | Array<ISpace>,
  metas: IMetas
  overlays: Collection<Overlay>
  taskIssues: Array<ITaskIssue>
}

const CreateTagOverlays = (
  inventories: Array<IInventory>,
  containers: Array<IContainer>,
  inventoryStitches: Array<IInventoryStitch>,
  containerStitches: Array<IContainerStich>,
  inventoryMetas: IInventoryMeta,
  containerMetas: IContainerMeta,
  overlays: Collection<Overlay>
): void => {
  inventories
    ?.forEach(inventory => {
      const meta = inventoryMetas[Number(inventory.id)]
      if (!meta.inventoryStitchID) {
        return
      }

      const point = meta.projectiveTransformation.transform([
        ScalePT(0.5),
        ScalePT(0.5)
      ])

      const inventoryStitch = inventoryStitches
        .find(inventoryStitch => String(inventoryStitch.id) === String(meta.inventoryStitchID))

      const element: { tag?: String } & HTMLElement = document.createElement('tag-overlay')
      element.tag = inventoryStitch.locatorCode

      const overlay = new Overlay({
        element,
        position: point,
        positioning: 'center-center' as OverlayPositioning,
        stopEvent: false
      })

      overlay.set('type', EEntity.Tag)
      overlay.set('point', point)

      overlays.push(overlay)
    })
}

const DeleteTaskOverlays = (
  overlays: Collection<Overlay>
): void => {
  const indices: Array<number> = overlays
    .getArray()
    .map((o, i: number) => ({
      type: o.get('type'),
      index: i
    }))
    .filter((e) => e.type === EEntity.Task)
    .map((e) => e.index)
    .reverse()

  indices
    .forEach(i => overlays
      .removeAt(i)
      .dispose())
}

const DeleteTaskIssueOverlays = (
  overlays: Collection<Overlay>
): void => {
  const indices: Array<number> = overlays
    .getArray()
    .map((o: Overlay, i: number) => ({
      type: o.get('type'),
      index: i
    }))
    .filter((e) => e.type === EEntity.TaskIssue)
    .map((e) => e.index)
    .reverse()

  indices
    .forEach(i => overlays
      .removeAt(i)
      .dispose())
}

const DeleteTagOverlays = (
  overlays: Collection<Overlay>
): void => {
  const indices = overlays
    .getArray()
    .map((o, i) => ({
      type: o.get('type'),
      index: i
    }))
    .filter(e => e.type === EEntity.Tag)
    .map(e => e.index)
    .reverse()

  indices
    .forEach(i => overlays
      .removeAt(i)
      .dispose())
}

export {
  ScalePT,
  UnscalePT,
  CreateAreacamOverlays,
  DeleteAreacamOverlays,
  CreateSensorOverlays,
  DeleteSensorOverlays,
  CreateTaskOverlays,
  DeleteTaskOverlays,
  CreateTaskIssueOverlays,
  DeleteTaskIssueOverlays,
  CreateTagOverlays,
  DeleteTagOverlays
}

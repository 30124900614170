// Types
import IUser from 'graphql-lib/interfaces/IUser'
import ID from 'graphql-lib/interfaces/ID';

// Libs
import { v4 } from 'uuid'

// GraphQL
import client from 'graphql-lib/index'
import { FetchResult, gql } from '@apollo/client'
import { clearStorage } from 'utils/storage';
import ISetStateType from 'graphql-lib/interfaces/ISetStateType';

const Init = ({
  hookID
}: {
  hookID: { current: string };
}): void => {
  hookID.current = `hook:useUser:${v4()}`
}

const QueryUser = async ({
  id,
  columns,
  setLoading,
  setError,
  setUser,
  setUUID
}: {
  id: ID;
  columns: string[];
  setLoading: ISetStateType<boolean>;
  setError: ISetStateType<Error>;
  setUser: ISetStateType<IUser>;
  setUUID: ISetStateType<string>;
}): Promise<void> => {
  if (id && columns) {
    const query = gql`
      query($ids: [ID]) {
        user(ids: $ids, isAdmin: true, isCustomer: true) {
          ${columns.join(' ')}
        }
      } 
    `

    setLoading(true)
    setUUID(v4())
    let error: Error
    let data
    try {
      const response: FetchResult<{ user: Array<IUser> }> = await client.query({
        fetchPolicy: 'no-cache',
        query,
        variables: { ids: [id] }
      })
      data = response.data;
    } catch (err) {
      error = err as Error;
    }

    setLoading(false)
    if (error) {
      setError(error);
      setUser(undefined);
      setUUID(v4());
      clearStorage();
    } else if (data) {
      setError(undefined);
      setUser(data.user[0]);
      setUUID(v4());
    }
  }
}

export {
  Init,
  QueryUser
}

/**
 * @author Miras Absar <mabsar@iunu.com>
 */

import EInputStatus from 'ui-lib/enums/input-status'
import ISearchCore from 'ui-lib/interfaces/ISelectCore'
import IInputStatus from 'ui-lib/interfaces/IInputStatus'

class ContainerTypeSearchCore
implements ISearchCore<string, string> {
  private required: boolean;

  private data: string[] = [
    'Flat',
    'Pots',
    'Basket',
    'Boards',
    'Channel',
    'Cube',
    'Slab',
    'Gutter',
    'Bag'
  ]

  public inputType = 'text';

  public constructor (required: boolean) {
    this.required = required
  }

  public getValue = (): string => null;

  public getUIValue = (value: string): string => value;

  public getStatus = async (value: string): Promise<IInputStatus> => {
    if (this.required && !value) {
      return {
        status: EInputStatus.Error,
        message: 'Required'
      }
    } else {
      return {
        status: EInputStatus.OK,
        message: ''
      }
    }
  }

  public getValues = async (uiValue: string): Promise<string[]> => {
    uiValue = uiValue || ''
    return this.data.filter(nani => nani.toLowerCase().includes(uiValue.toLowerCase()))
  }
}

export default ContainerTypeSearchCore

/**
 * @author Miras Absar <mabsar@iunu.com>
 */

// Types
import EInputStatus from 'ui-lib/enums/input-status'
import IInputStatus from 'ui-lib/interfaces/IInputStatus'

// Libs
import { validate } from 'isemail'

const Email = (val: string): IInputStatus => {
  if (val.length === 0) {
    return {
      status: EInputStatus.None,
      message: 'Optional'
    }
  } else if (/^\s*$/.test(val)) {
    return {
      status: EInputStatus.OK,
      message: ''
    }
  } else if (!validate(val)) {
    return {
      status: EInputStatus.Error,
      message: 'Invalid Email'
    }
  } else {
    return {
      status: EInputStatus.OK,
      message: ''
    }
  }
}

const RequiredEmail = (val: string): IInputStatus => {
  if (/^\s*$/.test(val)) {
    return {
      status: EInputStatus.Error,
      message: 'Required'
    }
  } else if (!validate(val)) {
    return {
      status: EInputStatus.Error,
      message: 'Invalid Email'
    }
  } else {
    return {
      status: EInputStatus.OK,
      message: ''
    }
  }
}

export {
  Email,
  RequiredEmail
}

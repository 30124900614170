import EInputStatus from '../enums/input-status'
import IInputCore from '../interfaces/IInputCore'
import IInputStatus from '../interfaces/IInputStatus'

const TextInputCore: IInputCore<string, string> = {
  inputType: 'text',
  getValue: (uiValue: string): string => uiValue,
  getUIValue: (value: string): string => value,
  getStatus: async (): Promise<IInputStatus> => ({
    status: EInputStatus.None,
    message: ''
  })
}

const RequiredTextInputCore = Object.assign(
  {},
  TextInputCore,
  {
    getStatus: (value: string): IInputStatus => {
      if (value === undefined ||
        value === null ||
        /^\s*$/.test(value)) {
        return {
          status: EInputStatus.Error,
          message: 'Required'
        }
      } else {
        return {
          status: EInputStatus.OK,
          message: ''
        }
      }
    }
  }
)

export {
  TextInputCore,
  RequiredTextInputCore
}

import React from 'react'
import { ITab } from 'ui-lib/components/tab'
import IEntity from 'src/graphql-lib/interfaces/entity'

interface ITaskListContextProps {
  viewingTask?: number
  creatingTask: boolean
  tasksTab: ITab
  setViewingTask: (viewingTask?: number) => void;
  setCreatingTask: (creatingTask: boolean) => void;
  setTabButNotSearchParams: (newTab: ITab) => void;
}

export const TaskListContext = React.createContext<ITaskListContextProps>({
  creatingTask: false,
  tasksTab: { id: '', label: '' },
  setViewingTask: () => {},
  setCreatingTask: () => {},
  setTabButNotSearchParams: () => {}
})
export const TaskListProvider = TaskListContext.Provider

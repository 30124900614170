// @author Miras Absar <mabsar@iunu.com>

// Styles
import 'react-datepicker/dist/react-datepicker.css'

// Types
import IChart from 'map-view/interfaces/chart'

// Utils
import { format } from 'date-fns'

// React
import React, {
  useCallback,
  useMemo
} from 'react'

// React Libs
import PinchZoomPan from 'react-responsive-pinch-zoom-pan'

import DatePicker from 'react-datepicker'

// Font Awesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimes } from '@fortawesome/pro-regular-svg-icons'

// Components
import ChartCanvas from 'map-view/components/chart-canvas'
import ChartTile from './chart-tile'
import TimeTravelInput from './time-travel-input'
import { createDate } from 'utils/dates'
import IAreacam from 'graphql-lib/interfaces/IAreacam'

interface IStaticCameraViewProps {
  animated?: boolean;

  uuid: string;
  endDate: Date;
  areacam: IAreacam;
  selectedCharts: IChart[];
  selectedChartsUUID: string;

  setEndDate: (newVal: Date) => void;
  setSelectedAreacam: (newVal: any) => void;
  setSelectedCharts: (newVal: IChart[]) => void;
}

const DateFormat = 'M/d/yy p'
const StaticCameraView = ({
  animated,

  uuid,
  endDate,
  areacam,
  selectedCharts,
  selectedChartsUUID,

  setEndDate,
  setSelectedAreacam,
  setSelectedCharts
}: IStaticCameraViewProps): JSX.Element => {
  // Memos /////////////////////////////////////////////////////////////////////

  const imageSrc = useMemo(
    (): string => {
      if (
        !areacam.areaImageRecord ||
        areacam.areaImageRecord.length === 0
      ) {
        return undefined
      }

      const latestAreaImageRecord = areacam.areaImageRecord[0]
      return latestAreaImageRecord.url
    },
    [areacam, uuid]
  )

  const areacamCode = useMemo(
    (): string => {
      const areacamCode = areacam.code.toLocaleUpperCase()
      return areacamCode
    },
    [areacam, uuid]
  )

  const lastSeen = useMemo(
    (): string => {
      if (
        !areacam.areaImageRecord ||
        areacam.areaImageRecord.length === 0 ||
        !areacam.space ||
        areacam.space.length === 0
      ) {
        return 'No Data'
      }

      const space = areacam.space[0]
      const latestAreaImageRecord = areacam.areaImageRecord[0]
      const lastSeen =
        (space.name || space.code) +
        ' - ' +
        format(createDate(latestAreaImageRecord.createdOn), DateFormat)

      return lastSeen
    },
    [areacam, uuid]
  )

  // Callbacks /////////////////////////////////////////////////////////////////

  const close$click =
    (): void => {
      setSelectedAreacam(undefined)
      setSelectedCharts([])
    }

  const chartTile$click =
    (newVal: IChart): void => {
      const isSelected = selectedCharts
        .find(chart => chart.chart === newVal.chart)

      let newSelectedCharts
      if (isSelected) {
        newSelectedCharts = selectedCharts
          .filter(chart => chart.chart !== newVal.chart)
      } else {
        newSelectedCharts = [...selectedCharts, newVal]
      }

      setSelectedCharts(newSelectedCharts)
    }

  // Render ////////////////////////////////////////////////////////////////////

  let className = 'StaticCameraView'
  if (animated) className += ' Animated'

  let content
  if (
    selectedCharts &&
    selectedCharts.length > 0
  ) {
    content = (
      <ChartCanvas
        resize=''
        charts={selectedCharts}
        chartsUUID={selectedChartsUUID}
      />
    )
  } else {
    content = (
      <PinchZoomPan
        maxScale={10}
        position='center'
        zoomButtons={false}
      >
        <img src={imageSrc} />
      </PinchZoomPan>
    )
  }

  return (
    <div className={className}>
      <button
        className='Close'
        onClick={close$click}
      >
        <FontAwesomeIcon icon={faTimes} />
      </button>

      {content}

      <div className='Body'>
        <h6>{areacamCode}</h6>
        <p>
          {lastSeen}

          <DatePicker
            customInput={<TimeTravelInput onClick={() => null} />}
            dateFormat='P p'
            maxDate={new Date()}
            onChange={setEndDate}
            selected={endDate}
            showTimeSelect
            withPortal
          />
        </p>

        {areacam.chart && areacam.chart.length > 0 && (
          <div className='ChartTiles'>
            {areacam.chart.map(chart => (
              <ChartTile
                key={chart.chart}

                outlined
                animated

                selectedCharts={selectedCharts}
                chart={chart}

                onClick={chartTile$click}
              />
            ))}
          </div>
        )}
      </div>
    </div>
  )
}

export default StaticCameraView

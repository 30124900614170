/**
 * @author Miras Absar <mabsar@iunu.com>
 * @file ...
 */

// Utils
import MapViewRoutes from '../../utils/routes'

// React
import React, {
  useContext,
  useEffect
} from 'react'

// React Libs
import AutoSizer from 'react-virtualized-auto-sizer'

// Contexts
import NavigationContext from 'context/navigation'
import Mobius from '../mobius'

// UI Lib
import MapCanvas from '../map-canvas'
import MapControls from '../map-controls'
import ChartCanvasCompat from '../chart-canvas-compat'
import AreacamView from '../map-controls/areacams/areacam-view'
import AreacamCarousel from '../map-controls/areacams/areacam-carousel'
import SensorChartsCarousel from '..//map-controls/sensor-charts-carousel'

const MapView = (): JSX.Element => {
  const {
    setSecondaryActions,
  } = useContext(NavigationContext)

  useEffect((): void => {
    setSecondaryActions([])
  }, [])

  // Render ////////////////////////////////////////////////////////////////////

  return (
    <Mobius>
      <main className='Theme__Light MapView'>
        <AutoSizer className='AutoSizer'>
          {({ width, height }: { width: number, height: number }): JSX.Element => (
            <MapCanvas
              width={width}
              height={height}
            />
          )}
        </AutoSizer>

        <MapControls />
        <ChartCanvasCompat animated />
      </main>

      <AreacamView />
      <AreacamCarousel raised />
      <SensorChartsCarousel
        raised
        animated
      />

    </Mobius>
  )
}

export { MapViewRoutes }
export default MapView

/**
 * @author Miras Absar <mabsar@iunu.com>
 */

// Types
import IEntity from 'graphql-lib/interfaces/entity'

// Libs
import { parse, stringify } from 'query-string'
import { isEqual } from 'lodash'
import { v4 } from 'uuid'

// Utils
import GoTo from 'react-lib/go-to'

import ID from 'graphql-lib/interfaces/ID';

/**
 * Update URL params based on `location.search`.
 */
const Search2URLParams = ({
  search,
  setURLParams2Search,
  setURLParams,
  setURLParamsUUID
}: {
  search: string,
  setURLParams2Search: (newVal: boolean) => void;
  setURLParams: (newVal: any) => void;
  setURLParamsUUID: (newVal: string) => void;
}): void => {
  const newURLParams = parse(search)
  const newURLParamsUUID = v4()

  setURLParams2Search(false)
  setURLParams(newURLParams)
  setURLParamsUUID(newURLParamsUUID)
}

/**
 * Update `location.search` based on URL params.
 */
const URLParams2Search = ({
  pathname,
  urlParams2Search,
  urlParams
}: {
  pathname: string;
  urlParams2Search: boolean;
  urlParams: any;
}): void => {
  if (urlParams2Search) {
    const to = `${pathname}?${stringify(urlParams)}`
    GoTo(to)
  }
}

/**
 * Update selected entities based on URL params.
 */
const URLParams2SelectedEntities = ({
  urlParams,
  selectedEntities,

  setSelectedEntities,
  setSelectedEntitiesUUID
}: {
  urlParams: any;
  selectedEntities: IEntity[];

  setSelectedEntities: (newVal: IEntity[]) => void;
  setSelectedEntitiesUUID: (newVal: string) => void;
}): void => {
  if (urlParams) {
    const newSelectedEntitiesJSON = urlParams.selectedEntities
    const newSelectedEntities = newSelectedEntitiesJSON
      ? JSON.parse(newSelectedEntitiesJSON)
      : []

    if (!isEqual(selectedEntities, newSelectedEntities)) {
      const newSelectedEntitiesUUID = v4()

      setSelectedEntities(newSelectedEntities)
      setSelectedEntitiesUUID(newSelectedEntitiesUUID)
    }
  }
}

/**
 * Update crop IDs based on URL params.
 */
const URLParams2CropIDs = ({
  urlParams,
  cropIDs,

  setCropIDs,
  setCropIDs_UUID
}: {
  urlParams: any;
  cropIDs: ID[];

  setCropIDs: (newVal: ID[]) => void;
  setCropIDs_UUID: (newVal: string) => void;
}): void => {
  if (urlParams) {
    const newCropIDs_JSON = urlParams.cropIDs
    const newCropIDs = newCropIDs_JSON
      ? JSON.parse(newCropIDs_JSON)
      : []

    if (!isEqual(cropIDs, newCropIDs)) {
      const newCropIDs_UUID = v4()

      setCropIDs(newCropIDs)
      setCropIDs_UUID(newCropIDs_UUID)
    }
  }
}

export {
  Search2URLParams,
  URLParams2Search,
  URLParams2SelectedEntities,
  URLParams2CropIDs
}

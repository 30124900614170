import React from 'react'
import Bugsnag from '@bugsnag/browser'
import BugsnagPluginReact from '@bugsnag/plugin-react'
import config from 'config'
import { BrowserConfig } from '@bugsnag/js'

/**
 * Starts the Bugsnag service and registers the React plugin.
 */

const options: BrowserConfig = {
  apiKey: config.bugsnagId,
  plugins: [new BugsnagPluginReact(React)],
  enabledReleaseStages: ['qa', 'staging', 'production'],
  releaseStage: config.releaseStage
}

function ignore_log() {}

if (config.releaseStage === 'development') {
  options.logger = {
    debug: ignore_log,
    info: ignore_log,
    warn: ignore_log,
    error: function () {
      console.error.apply(console, arguments as unknown as any[])
    }
  }
}

Bugsnag.start(options)

export default Bugsnag

enum EChart {
  AbsoluteHumidity = 'Chart.AbsoluteHumidity',
  DLI = 'Chart.DLI',
  Humidity = 'Chart.Humidity',
  Temperature = 'Chart.Temperature',
  umol = 'Chart.umol',
  VPD = 'Chart.VPD',

  CO2 = 'Chart.CO2',
  HWC = 'Chart.HarvestWeight',

  BudDetectionData = 'Chart.BudDetection.Data',
  BudDetectionDensity = 'Chart.BudDetection.Density',
  CanopyCoverageData = 'Chart.CanopyCoverage.Data',
  CanopyCoverageDelta = 'Chart.CanopyCoverage.Delta',
  CanopyHeightData = 'Chart.CanopyHeight.Data',
  CanopyHeightDelta = 'Chart.CanopyHeight.Delta',
  FlowerCoverageData = 'Chart.FlowerCoverage.Data',
  FlowerCoverageDelta = 'Chart.FlowerCoverage.Delta',
  GerminationRate = 'Chart.GerminationRate.Count',
  GerminationRatePercent = 'Chart.GerminationRate.Percent',
  YieldPrediction = 'Chart.YieldPrediction.Count',
  BoardCount = 'Chart.BoardCount.Count',
  TotalWeight = 'Chart.TotalWeight.Count',

  TopCanopyCoverageData = 'Chart.TopCanopyCoverage.Data',
  TopCanopyCoverageDelta = 'Chart.TopCanopyCoverage.Delta',
  TopCanopyHeightData = 'Chart.TopCanopyHeightData',
  TopCanopyHeightDelta = 'Chart.TopCanopyHeightDelta',
  TopGerminationRate = 'Chart.TopGerminationRate.Count',
  PotCount = 'Chart.PotCount.Count',
  ReadinessCoverage = 'Chart.ReadinessCoverage.Coverage',

  GreenAutomationWaterSystemEC1 = 'Chart.Vendor.GreenAutomation.WaterSystem.EC1',
  GreenAutomationWaterSystemEC2 = 'Chart.Vendor.GreenAutomation.WaterSystem.EC2',
  GreenAutomationWaterSystemPH1 = 'Chart.Vendor.GreenAutomation.WaterSystem.PH1',
  GreenAutomationWaterSystemPH2 = 'Chart.Vendor.GreenAutomation.WaterSystem.PH2',
  GreenAutomationWaterSystemDissolvedO2 = 'Chart.Vendor.GreenAutomation.WaterSystem.DissolvedO2',
  GreenAutomationWaterSystemWaterTemp = 'Chart.Vendor.GreenAutomation.WaterSystem.WaterTemp',
}

export default EChart

import React, {useState, useContext, useEffect} from 'react'

// Contexts
import Mobius from 'map-view/contexts/mobius'

// All 
import { tabs } from 'map-view/components/map-controls/index'

interface IToggleSwitchProps {
  classNames?: string
  onClick: () => void
}

const ToggleSwitch = ({classNames, onClick}: IToggleSwitchProps) => {
  const [tabName, setTabName] = useState<string>('crops')
  const mobiusContext = useContext(Mobius)
  
  // Set the tab name to show in description of toggle switch
  useEffect(() => {
    const currentParams = mobiusContext.searchParams.searchParams
    const tabKey = currentParams.navigationTab
    let currentTab = tabs.find(tab => tab?.id == tabKey)
    const currentTabName = currentTab?.label.toLowerCase() || ''
    setTabName(currentTabName)
  }, [mobiusContext.searchParams.searchParams])
  

  return (
    <div className='ToggleSwitch'>
      <span>Only show {tabName} in view</span>

      <div className="switch-container">
        <label className={"switch-background " + classNames}>
          <input 
            type="checkbox" 
            className="invisible-checkbox"
            onClick={onClick}
            checked={classNames ? true : false}
            readOnly={true}
          />
          <div/>
        </label>
      </div>
    </div>
  )
}

export default ToggleSwitch
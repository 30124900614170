/**
 * @author Miras Absar <mabsar@iunu.com>
 * @file ...
 */

enum ETab {
  Facility = 'Tab.Facility',
  Plants = 'Tab.Plants',
  Flags = 'Tab.Flags',
  Issues = 'Tab.Issues'
}

export default ETab

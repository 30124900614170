// Styles

// Utils
import Has from 'utils/has'
import IsVoid from 'utils/is-void'

// React
import React, {
  CSSProperties,
  Children,
  useRef,
  useState,
  useEffect,
  ReactElement
} from 'react'

// React Libs
import { useEvent } from 'react-lib/use-event'

interface IAspectRatioProps {
  defaultWidth: number,
  defaultHeight: number,
  width?: number;
  height?: number;
  aspectRatio?: number;
  imgSrc?: string;
  children: ReactElement
}

const AspectRatio = ({
  defaultWidth,
  defaultHeight,
  width,
  height,
  aspectRatio,
  imgSrc,
  children
}: IAspectRatioProps): JSX.Element => {
  // State /////////////////////////////////////////////////////////////////////

  const parentRef = useRef<HTMLDivElement>()
  const [childStyle, setChildStyle] = useState<CSSProperties>()
  const [{ actualWidth, actualHeight }, setActualDimensions] = useState(
    { actualWidth: defaultWidth, actualHeight: defaultHeight })

  // Use computed with and height unless overridden
  width = width || actualWidth; height = height || actualHeight
  // Callbacks & Events ////////////////////////////////////////////////////////

  /**
   * Loads the image as a JavaScript object in order to get the actual
   * width and height.
   */
  useEffect(() => {
    if (imgSrc) {
      const img = new Image()

      img.onload = function () {
        setActualDimensions({
          actualWidth: img.width,
          actualHeight: img.height
        })
      }
      img.src = imgSrc // this must be done AFTER setting onload
      if (img.complete) { // Case where image is loaded from cache
        setActualDimensions({
          actualWidth: img.width,
          actualHeight: img.height
        })
      }
    }
  }, [imgSrc])

  const updateParentStyle = (): void => {
    if (parentRef.current) {
      if (
        Has(width) &&
        Has(height) &&
        IsVoid(aspectRatio)
      ) {
        aspectRatio = width / height
      }

      const parentStyle = window.getComputedStyle(parentRef.current)
      const parentWidth = parseFloat(parentStyle.width)
      const parentHeight = parseFloat(parentStyle.height)
      const parentAspectRatio = parentWidth / parentHeight

      let childWidth
      let childHeight
      if (aspectRatio < parentAspectRatio) {
        childHeight = parentHeight
        childWidth = childHeight * aspectRatio
      } else {
        childWidth = parentWidth
        childHeight = childWidth / aspectRatio
      }

      setChildStyle({
        width: childWidth,
        height: childHeight
      })
    }
  }

  const updateParentRef = (newParentRef: HTMLDivElement): void => {
    if (
      !parentRef.current ||
      (
        newParentRef &&
        !Object.is(parentRef.current, newParentRef)
      )
    ) {
      parentRef.current = newParentRef
      updateParentStyle()

      setTimeout(
        updateParentStyle,
        300
      )
    }
  }

  useEvent(
    window,
    'resize',
    updateParentStyle,
    [updateParentStyle]
  )

  // Render ////////////////////////////////////////////////////////////////////

  return (
    <div
      ref={updateParentRef}
      className='aspect-ratio'
    >
      <div style={childStyle}>
        {children}
      </div>
    </div>
  )
}

export default AspectRatio

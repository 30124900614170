// Types
import IBuilding from 'graphql-lib/interfaces/IBuilding'
import Space from '../space'

// React
import React, { CSSProperties } from 'react'

// Utils
import GoTo from 'react-lib/go-to'
import { Space as SpaceRoute } from 'grid-view/utils/routes'
import ISpace from 'graphql-lib/interfaces/ISpace'

export interface IBuildingProps {
  style: CSSProperties;
  animated: boolean;
  data: IBuilding;
}

const $click = (data: ISpace): void => {
  const to = SpaceRoute
    .replace(':id', String(data.id))
    .replace(':timestamp?', '')
    .replace(':x?', '')
    .replace(':y?', '')
    .replace(/\/+$/, '')

  GoTo(to)
}

const Building = ({
  style,
  animated,
  data
}: {
  style: CSSProperties
  animated: boolean
  data: IBuilding
}): JSX.Element => (
  <div>
    {!data?.app_config.aiHideZone &&
      <div
        style={style}
        className='GridView__BuildingHeader'
      >
        <label>{data.name}</label>
      </div>
    }

    {data.spaces.map(sp =>
      <Space
        key={'b' + data.id + '.' + sp.id}
        outlined
        animated={animated}
        data={sp}
        onClick={$click}
      />
    )}
  </div>
)

export default Building

// Config
import config from 'config'

/* Utils */
import Has from 'utils/has'
import {
  EStorage,
  GetItem
} from 'utils/storage'

/* Libs */
import { parse, ParsedQuery } from 'query-string'
import m, { Moment } from 'moment'

/* React */
import React, {
  CSSProperties,
  useContext,
  useEffect,
  useRef,
  useState
} from 'react'
import { match } from 'react-router-dom'

/* React Libs */
import { useDebouncedCallback } from 'use-debounce'
import { toast } from 'react-toastify'
import AutoSizer from 'react-virtualized-auto-sizer'
import { FixedSizeGrid as Grid, GridOnItemsRenderedProps, GridOnScrollProps } from 'react-window'
import LongPressable from './react-longpressable'
import useStorage from 'react-lib/use-storage'

/* GraphQL */
import { gql, useQuery } from '@apollo/client'

/* Types */
import ITask from 'graphql-lib/interfaces/ITask'

/* Navigation */
import NavigationContext from 'context/navigation'
import { Back } from 'utils/actions'
import {
  MapRoute,
  FacilityRoute,
  CropsRoute,
  TasksRoute
} from 'utils/routes'
import GoTo from 'react-lib/go-to'

import Flag from 'ui-lib/components/flag'

import {
  Building,
  Space as SpaceRoute
} from '../../utils/routes'

import Minimap from '../minimap'
import AreacamControl from '../areacam-control'
import SensorControl from '../sensor-control'
import TimeTravelControl from '../time-travel-control'

import Overlay from 'zinnia/components/atoms/general/overlay'
import ImageCard from '../image-card'
import AreacamCard from '../areacam-card'
import SensorCard from '../sensor-card'
import CreateTaskCard from 'tasks/components/create-task-card'
import CreateTaskIssueCard from 'tasks/components/create-task-issue-card'
import ViewTaskCard from 'tasks/components/view-task-card'

import isVoid from 'utils/is-void'
import ITaskIssue from 'graphql-lib/interfaces/ITaskIssue'
import ISpace from 'graphql-lib/interfaces/ISpace'
import { ICO2SensorRecord, ISensorRecord } from 'graphql-lib/interfaces/ISensorDevice'
import IAreacam from 'graphql-lib/interfaces/IAreacam'
import IArcImageRecord from 'graphql-lib/interfaces/IArcImageRecord'
import ID from 'graphql-lib/interfaces/ID'

const QuerySpace = gql`
  query($id: ID) {
    space(id: $id) {
      id
      buildingId

      code
      name
      nickname
      xMax
      yMax
    }
  }
`

const QuerySensorRecords = gql`
  query(
    $id: ID,
    $startDate: DateTime,
    $endDate: DateTime,
  ) {
    space(id: $id) {
      id

      sensorRecord(
        startDate: $startDate,
        endDate: $endDate
      ) {
        createdOn
        sensorDeviceId

        umol
        humidity
        temperature
        vpd
        dli
        absoluteHumidity
      }
    }
  }
`

const QueryCo2SensorRecords = gql`
  query(
    $id: ID,
    $startDate: DateTime,
    $endDate: DateTime,
  ) {
    space(id: $id) {
      id

      co2SensorRecord(
        startDate: $startDate,
        endDate: $endDate
      ) {
        createdOn
        sensorDeviceId

        co2
      }
    }
  }
`

const QueryAreacams = gql`
  query(
    $id: ID,
    $startDate: DateTime,
    $endDate: DateTime,
  ) {
    space(id: $id) {
      id

      areacam {
        id
        code

        areaImageRecord(
          startDate: $startDate,
          endDate: $endDate,
          limit: 1
        ) {
          id
          createdOn

          thumbUrl
          url
        }
      }
    }
  }
`

const QueryArcImages = gql`
  query(
    $id: ID,
    $startDate: DateTime,
    $endDate: DateTime
  ) {
    space(id: $id) {
      id

      imageGrid(
        startDate: $startDate,
        endDate: $endDate
      ) {
        id
        createdOn

        positionX
        positionY

        hostname
        thumbUrl
        legacyUrl
        url
      }
    }
  }
`

const QueryTasks = gql`
  query($id: ID) {
    space(id: $id) {
      id

      xMax
      yMax

      task(isGridview: true) {
        id

        complete

        flag {
          icon
          color
        }

        locationProfile {
          positionX
          positionY
        }
      }
    }
  }
`

const QueryTaskIssues = gql`
  query($id: ID) {
    space(id: $id) {
      id

      xMax
      yMax

      taskIssue(appliedToView: grid) {
        id

        customerTaskCategory {
          icon
          color
        }

        locationProfile {
          positionX
          positionY
        }
      }
    }
  }
`

export const SpaceRoutes = [
  SpaceRoute
]

interface ISpaceParameters {
  id: string;
  timestamp: string;
  x: string;
  y: string;
}

export interface ISpaceProps {
  match: match<ISpaceParameters>
}

const Space = ({
  match: {
    params: {
      id,
      timestamp,
      x,
      y
    },
    path
  }
}: ISpaceProps): JSX.Element => {
  /** Get params */
  const {
    imageId,
    areacamId,
    sensor,
    createTask,
    createTaskIssue,
    taskId,
    spaceId,
    positionX,
    positionY
  }: ParsedQuery<string> = parse(location.search)
  const isSpace = path === SpaceRoute
  const isImage = !!imageId
  const isAreacam = !!areacamId
  const isSensor = !!sensor
  const isCreateTask = !!createTask
  const isCreateTaskIssue = !!createTaskIssue
  const isViewTask = !!taskId

  const [facilities] = useStorage('facilities', EStorage.EphemeralStorage)
  const supportsMapView = Has(facilities[0].geojson) &&
    Has(facilities[0].dcs) &&
    facilities[0].dcs.environments?.includes(config.code)

  /** Navigation Context */
  const {
    setPrimaryAction,
    setSecondaryActions,

    setTitle,
    setRoutes
  } = useContext(NavigationContext)

  /** State & Refs */
  const [now, setNow] = useState<Moment>()
  const [edgeOfTomorrow, setEdgeOfTomorrow] = useState<Moment>()
  const [yesterday, setYesterday] = useState<Moment>()
  const [yesterweek, setYesterweek] = useState<Moment>()
  const [yestermonth, setYestermonth] = useState<Moment>()
  const [space, setSpace] = useState<ISpace>()
  const [sensorRecords, setSensorRecords] = useState<Array<ISensorRecord>>()
  const [co2SensorRecords, setCo2SensorRecords] = useState<Array<ICO2SensorRecord>>()
  const [areacams, setAreacams] = useState<Array<IAreacam>>()
  const [arcImages, setArcImages] = useState<Array<Array<IArcImageRecord>>>()
  const [tasks, setTasks] = useState<Array<Array<ITask[]>>>()

  const [arcImageMaxX, setArcImageMaxX] = useState<number>()
  const [arcImageMaxY, setArcImageMaxY] = useState<number>()
  const [arcImageWidth, setArcImageWidth] = useState<number>()
  const [arcImageHeight, setArcImageHeight] = useState<number>()

  const [firstCol, setFirstCol] = useState<number>(0)
  const [lastCol, setLastCol] = useState<number>(0)
  const [firstRow, setFirstRow] = useState<number>(0)
  const [lastRow, setLastRow] = useState<number>(0)

  // const [arcImage, setArcImage] = useState()
  const [areacam, setAreacam] = useState<IAreacam>()

  const sensorRecordsTimestamp = useRef<Date>()
  const co2SensorRecordsTimestamp = useRef<Date>()
  const areacamsTimestamp = useRef<Date>()
  const arcImagesTimestamp = useRef<Date>()
  const grid = useRef<any>()

  const [user] = useStorage('user', EStorage.EphemeralStorage)
  /**
   * Query a space.
   */
  const {
    error: spaceError,
    data: spaceData
  } = useQuery(QuerySpace, { variables: { id } })

  /**
   * Query sensor records.
   */
  const {
    variables: sensorRecordsVars,
    loading: sensorRecordsLoading,
    error: sensorRecordsError,
    data: sensorRecordsData
  } = useQuery(QuerySensorRecords, {
    skip: !now || !yesterweek,
    variables: {
      id,
      startDate: yesterweek,
      endDate: now
    }
  })

  /**
   * Query CO2 sensor records.
   */
  const {
    variables: co2SensorRecordsVars,
    loading: co2SensorRecordsLoading,
    error: co2SensorRecordsError,
    data: co2SensorRecordsData
  } = useQuery(QueryCo2SensorRecords, {
    skip: !now || !yesterweek,
    variables: {
      id,
      startDate: yesterweek,
      endDate: now
    }
  })

  /**
   * Query area cams.
   */
  const {
    variables: areacamsVars,
    loading: areacamsLoading,
    error: areacamsError,
    data: areacamsData
  } = useQuery(QueryAreacams, {
    skip: !now || !yesterweek,
    variables: {
      id,
      startDate: yesterweek,
      endDate: now
    }
  })

  /**
   * Query arc images.
   */
  const {
    variables: arcImagesVars,
    loading: arcImagesLoading,
    error: arcImagesError,
    data: arcImagesData
  } = useQuery(QueryArcImages, {
    skip: !now || !yesterweek,
    variables: {
      id,
      startDate: yesterweek,
      endDate: now
    }
  })

  const {
    loading: tasksLoading,
    error: tasksError,
    data: tasksData,
    refetch: tasksRefetch
  } = useQuery(QueryTasks, { variables: { id } })

  const {
    loading: taskIssuesLoading,
    error: taskIssuesError,
    data: taskIssuesData,
    refetch: taskIssuesRefetch
  } = useQuery(QueryTaskIssues, { variables: { id } })

  /**
   * Set navigation context.
   */
  useEffect((): void => {
    const newRoutes = [
      FacilityRoute,
      CropsRoute,
      TasksRoute
    ]

    if (supportsMapView) {
      newRoutes.unshift(MapRoute)
    }

    setPrimaryAction(Back)
    setSecondaryActions([])
    setRoutes(newRoutes)
  }, [])

  /**
   * Instantiate and/or traverse time.
   */
  useEffect((): void => {
    /* If a timestamp wasn't give and time hasn't been instantiated. */
    if (!timestamp && !now) {
      const newNow = m();
      const newEdgeOfTomorrow = m().add(1, 'day')
      const newYesterday = m().subtract(1, 'day')
      const newYesterweek = m().subtract(1, 'week')
      const newYestermonth = m().subtract(1, 'month')

      setNow(newNow)
      setEdgeOfTomorrow(newEdgeOfTomorrow)
      setYesterday(newYesterday)
      setYesterweek(newYesterweek)
      setYestermonth(newYestermonth)
    }

    /* If a timestamp was given and time hasn't been instantiated. */
    if (timestamp && !now) {
      const newNow = m(timestamp)
      const newEdgeOfTomorrow = m(timestamp).add(1, 'day')
      const newYesterday = m(timestamp).subtract(1, 'day')
      const newYesterweek = m(timestamp).subtract(1, 'week')
      const newYestermonth = m(timestamp).subtract(1, 'month')

      setNow(newNow)
      setEdgeOfTomorrow(newEdgeOfTomorrow)
      setYesterday(newYesterday)
      setYesterweek(newYesterweek)
      setYestermonth(newYestermonth)
    }

    /* If a timestamp was given and time has been instantiated,
     * but they don't match. */
    if (timestamp && now && !now.isSame(timestamp)) {
      const newNow = m(timestamp)
      const newEdgeOfTomorrow = m(timestamp).add(1, 'day')
      const newYesterday = m(timestamp).subtract(1, 'day')
      const newYesterweek = m(timestamp).subtract(1, 'week')
      const newYestermonth = m(timestamp).subtract(1, 'month')

      setNow(newNow)
      setEdgeOfTomorrow(newEdgeOfTomorrow)
      setYesterday(newYesterday)
      setYesterweek(newYesterweek)
      setYestermonth(newYestermonth)
    }
  }, [timestamp])

  let spaceTimeSignature: string
  if (now && arcImagesVars.endDate) {
    spaceTimeSignature = ':x,:y,:timestamp :fabricInstantiated,:fabricXDim,:fabricYDim'
      .replace(':x', x)
      .replace(':y', y)
      .replace(':fabricInstantiated', String(!!grid.current))
      .replace(':fabricXDim', String(arcImageWidth))
      .replace(':fabricYDim', String(arcImageHeight))
  }

  /**
   * Traverse space.
   */
  useEffect((): void => {
    if (now && x !== undefined && y !== undefined && arcImagesVars.endDate &&
      grid.current && arcImageWidth && arcImageHeight) {
      grid.current.scrollToItem({
        columnIndex: Number.parseInt(x, 10),
        rowIndex: Number.parseInt(y, 10)
      })
    }
  }, [spaceTimeSignature])

  /**
   * If the space has been queried and state hasn't been set,
   * update navigation context and state.
   */
  useEffect((): void => {
    if (spaceData && !space) {
      const newSpace = spaceData.space[0]
      const newNumArcImages = (newSpace.xMax + 1) * (newSpace.yMax + 1)

      const _Back = Object.assign(
        {},
        Back,
        {
          func: async (): Promise<void> =>
            GoTo(Building.replace(':id', newSpace.buildingId))
        }
      )

      setPrimaryAction(_Back)
      setTitle(newSpace.name)
      setSpace(newSpace)
    }
  }, [spaceData])

  /**
   * If the sensor records have been queried and state hasn't been set (1),
   * or if the sensor records have been queried and state has been set,
   * but they don't match (2),
   * update state.
   */
  useEffect((): void => {
    const _1 = sensorRecordsData && !sensorRecords
    const _2 = sensorRecordsData &&
      !sensorRecordsVars.endDate.isSame(sensorRecordsTimestamp.current)

    if (_1 || _2) {
      const customer = GetItem('customer', EStorage.EphemeralStorage)
      const temperatureMetric = customer.temperatureMetric
      const isFahrenheit = temperatureMetric === 'fahrenheit'

      let newSensorRecords = sensorRecordsData.space[0].sensorRecord
      const newSensorRecordsTimestamp = sensorRecordsVars.endDate

      if (isFahrenheit) {
        newSensorRecords = newSensorRecords.map((s: ISensorRecord) => {
          const newS = { ...s }
          newS.temperature = (newS.temperature * 9 / 5) + 32

          return newS
        })
      }

      setSensorRecords(newSensorRecords)
      sensorRecordsTimestamp.current = newSensorRecordsTimestamp
    }
  }, [sensorRecordsData])

  /**
   * If the CO2 sensor records have been queried and state hasn't been set (1),
   * or if the CO2 sensor records have been queried and state has been set,
   * but they don't match (2),
   * update state.
   */
  useEffect((): void => {
    const _1 = co2SensorRecordsData && !co2SensorRecords
    const _2 = co2SensorRecordsData &&
      !co2SensorRecordsVars.endDate.isSame(co2SensorRecordsTimestamp.current)

    if (_1 || _2) {
      const newCo2SensorRecords = co2SensorRecordsData.space[0].co2SensorRecord
      const newCo2SensorRecordsTimestamp = co2SensorRecordsVars.endDate

      setCo2SensorRecords(newCo2SensorRecords)
      co2SensorRecordsTimestamp.current = newCo2SensorRecordsTimestamp
    }
  }, [co2SensorRecordsData])

  /**
   * If the area cams have been queried and state hasn't been set (1),
   * or if the area cams have been queried and state has been set,
   * but they don't match (2),
   * update state.
   */
  useEffect((): void => {
    const _1 = areacamsData && !areacams
    const _2 = areacamsData &&
      !areacamsVars.endDate.isSame(areacamsTimestamp.current)

    if (_1 || _2) {
      const newAreacams = areacamsData.space[0].areacam
      const newAreacamsTimestamp = areacamsVars.endDate

      setAreacams(newAreacams)
      areacamsTimestamp.current = newAreacamsTimestamp

      if (isAreacam) {
        const newAreacam = newAreacams.find((_areacam: IAreacam): boolean =>
          Number(_areacam.id) === Number(areacamId))

        setAreacam(newAreacam)
      }
    }
  }, [areacamsData])

  useEffect((): void => {
    if (areacams && isAreacam) {
      const newAreacam = areacams.find((_areacam: IAreacam): boolean =>
        Number(_areacam.id) === Number(areacamId))

      setAreacam(newAreacam)
    }
  }, [areacams && areacamId])

  /**
   * If the arc images have been queried and state hasn't been set (1),
   * or if the arc images have been queried and state has been set,
   * but they don't match (2),
   * update state.
   */
  useEffect((): void => {
    const _1 = arcImagesData && !arcImages
    const _2 = arcImagesData &&
      !arcImagesVars.endDate.isSame(arcImagesTimestamp.current)

    if (_1 || _2) {
      let newArcImageMaxX = 0
      let newArcImageMaxY = 0
      const newArcImages = arcImagesData.space[0].imageGrid
        .map((arcImage: IArcImageRecord) => {
          if (arcImage.legacyUrl && !arcImage.url) {
            const legacyUrlComponents = arcImage.legacyUrl.split('/')
            legacyUrlComponents.splice(0, 2)
            legacyUrlComponents[1] += '-thumbs'
            legacyUrlComponents[legacyUrlComponents.length - 1] =
              'thumb.' +
              legacyUrlComponents[legacyUrlComponents.length - 1]

            const legacyThumbUrl = 'https://' + legacyUrlComponents.join('/')

            return Object.assign(
              {},
              arcImage,
              {
                url: arcImage.legacyUrl,
                thumbUrl: legacyThumbUrl
              }
            )
          } else {
            return arcImage
          }
        })
        .reverse()
        .reduce(
          (acc: Array<Array<IArcImageRecord>>, cur: IArcImageRecord): any[] => {
            const { positionX, positionY } = cur

            if (!acc[positionX]) {
              acc[positionX] = []
            }

            acc[positionX][positionY] = cur

            if (positionX > newArcImageMaxX) newArcImageMaxX = positionX
            if (positionY > newArcImageMaxY) newArcImageMaxY = positionY

            return acc
          },
          []
        )

      setArcImages(newArcImages)
      setArcImageMaxX(newArcImageMaxX)
      setArcImageMaxY(newArcImageMaxY)
      arcImagesTimestamp.current = arcImagesVars.endDate
    }
  }, [arcImagesData])

  /**
   * If the tasks have been queried and state hasn't been set,
   * update state
   */
  useEffect((): void => {
    if (tasksData) {
      const newSpace = tasksData.space[0]
      const newTasks = newSpace.task
        .filter((task: ITask): boolean => !task.complete)
        .reduce((acc: Array<Array<Array<ITask>>>, cur: ITask): any[] => {
          const { locationProfile: [{ positionX, positionY }] } = cur

          if (isVoid(positionX) ||
            isVoid(positionY)) {
            return acc
          }

          if (!acc[positionX]) {
            acc[positionX] = []
          }

          if (!acc[positionX][positionY]) {
            acc[positionX][positionY] = [cur]
          } else {
            acc[positionX][positionY].push(cur)
          }

          return acc
        }, [])

      setTasks(newTasks)
    }
  }, [tasksData])

  /**
   * TODO: Write Documentation
   */
  const card$back = (): void => {
    const to = SpaceRoute
      .replace(':id', id as string)
      .replace(':timestamp?', m(timestamp).toISOString())
      .replace(':x?', x || '')
      .replace(':y?', y || '')
      .replace(/\/+$/, '')

    GoTo(to)
  }

  /**
   * TODO: Write Documentation
   */
  const task$save = (createTaskData: {Task: {create: ITask}}): void => {
    const taskId = createTaskData.Task.create.id
    tasksRefetch()

    const to = SpaceRoute
      .replace(':id', id as string)
      .replace(':timestamp?', m(timestamp).toISOString())
      .replace(':x?', x || '')
      .replace(':y?', y || '')
      .replace(/\/+$/, '') +
      `?taskId=${taskId}`

    GoTo(to)
  }

  interface ICreateTaskIssueDataProp { TaskIssue: {create: ITaskIssue} }
  const taskIssue$save = (createTaskIssueData: ICreateTaskIssueDataProp): void => {
    const taskIssueId = createTaskIssueData.TaskIssue.create.id
    taskIssuesRefetch()

    const to = SpaceRoute
      .replace(':id', id as string)
      .replace(':timestamp?', m(timestamp).toISOString())
      .replace(':x?', x || '')
      .replace(':y?', y || '')
      .replace(/\/+$/, '') +
      `?taskIssueId=${taskIssueId}`

    GoTo(to)
  }

  /**
   * TODO: Write Documentation
   */
  const flag$click = (taskId: ID): void => {
    const to = SpaceRoute
      .replace(':id', id as string)
      .replace(':timestamp?', m(timestamp).toISOString())
      .replace(':x?', x || '')
      .replace(':y?', y || '')
      .replace(/\/+$/, '') +
      `?taskId=${taskId}`

    GoTo(to)
  }

  /**
   * TODO: Write Documentation
   */
  const [$itemsRendered] = useDebouncedCallback<(props: GridOnItemsRenderedProps) => any>(
    ({
      overscanColumnStartIndex,
      overscanColumnStopIndex,
      overscanRowStartIndex,
      overscanRowStopIndex,
      visibleColumnStartIndex,
      visibleColumnStopIndex,
      visibleRowStartIndex,
      visibleRowStopIndex
    }: GridOnItemsRenderedProps): void => {
      setFirstCol(visibleColumnStartIndex)
      setLastCol(visibleColumnStopIndex)

      setFirstRow(visibleRowStartIndex)
      setLastRow(visibleRowStopIndex)
    },
    100
  )

  /**
   * TODO: Write Documentation
   */
  const image$ref = (ref: HTMLImageElement): void => {
    if (!arcImageWidth && !arcImageHeight && ref) {
      ref.onload = (): void => {
        const { naturalWidth, naturalHeight } = ref
        const nextArcImageWidth = 160
        const nextArcImageHeight = nextArcImageWidth / naturalWidth *
          naturalHeight

        setArcImageWidth(nextArcImageWidth)
        setArcImageHeight(nextArcImageHeight)

        ref.onload = null
      }
    }
  }

  /**
   * TODO: Write Documentation
   */
  const image$shortPress = (arcImage: Partial<IArcImageRecord>): void => {
    if (arcImage?.id) {
      const to = SpaceRoute
        .replace(':id', id as string)
        .replace(':timestamp?', m(timestamp).toISOString())
        .replace(':x?', String(arcImage.positionX))
        .replace(':y?', String(arcImage.positionY))
        .replace(/\/+$/, '') +
      `?imageId=${arcImage.id}`

      GoTo(to)
    }
  }

  /**
   * TODO: Write Documentation
   */
  const image$longPress = (arcImage: Partial<IArcImageRecord>): void => {
    if (arcImage?.id) {
      // Right now we only have support for create Task
      // while long but not to create Task Issue.
      // What should we do in this case. Tab? Double click?
      // Ask Trevor.
      const to = SpaceRoute
        .replace(':id', id as string)
        .replace(':timestamp?', m(timestamp).toISOString())
        .replace(':x?', String(arcImage.positionX))
        .replace(':y?', String(arcImage.positionY))
        .replace(/\/+$/, '') +
        `?createTask=1&spaceId=${id}&positionX=${arcImage.positionX}&positionY=${arcImage.positionY}`

      GoTo(to);
    }
  }

  /**
   * TODO: Write Documentation
   */
  const grid$ref = (ref: Grid<JSX.Element>): void => {
    if (ref !== grid.current) {
      grid.current = ref
    }
  }

  /**
   * TODO: Write Documentation
   */
  const areacamControl$select = (areacam: IAreacam): void => {
    const to = SpaceRoute
      .replace(':id', id as string)
      .replace(':timestamp?', m(timestamp).toISOString())
      .replace(':x?', x || '')
      .replace(':y?', y || '')
      .replace(/\/+$/, '') +
      `?areacamId=${areacam.id}`

    GoTo(to)
  }

  /**
   * TODO: Write Documentation
   */
  const sensorControl$click = (): void => {
    const to = SpaceRoute
      .replace(':id', id as string)
      .replace(':timestamp?', m(timestamp).toISOString())
      .replace(':x?', x || '')
      .replace(':y?', y || '')
      .replace(/\/+$/, '') +
      '?sensor=1'

    GoTo(to)
  }

  /**
   * TODO: Write Documentation
   */
  const $timeTravel = (time: Moment): void => {
    if (time.isSame(now)) {
      return
    }

    if (isSpace) {
      const to = SpaceRoute
        .replace(':id', id as string)
        .replace(':timestamp?', time.toISOString())
        .replace(':x?', x || '')
        .replace(':y?', y || '')
        .replace(/\/+$/, '')

      GoTo(to)
    }
  }

  const onScroll = (props: GridOnScrollProps) => {
    if (props.scrollTop > window.innerHeight) {
      const percent = Math.trunc((props.scrollTop + grid.current.props.height) * 100 / (Number(arcImageHeight) * arcImageMaxY))
    }
  }

  const Cell = ({ columnIndex, rowIndex, style }: {columnIndex: number, rowIndex: number, style: CSSProperties}): JSX.Element => {
    let className = 'Cell'
    const arcImage: Partial<IArcImageRecord> = arcImages[columnIndex]?.[rowIndex] || {};

    const _tasks: Array<ITask> = tasks && tasks[arcImage.positionX] &&
      tasks[arcImage.positionX][arcImage.positionY]
      ? tasks[arcImage.positionX][arcImage.positionY]
      : []

    if (arcImage.thumbUrl === undefined && arcImage.url === undefined) {
      className += ' Placeholder'

      return (
        <div
          style={style}
          className={className}
        />
      )
    } else {
      className += ' Image'
    }

    if (arcImage.positionX !== undefined &&
      arcImage.positionY !== null &&
      arcImage.positionY !== undefined &&
      arcImage.positionY !== null &&
      Number(arcImage.positionX) === Number(x) &&
      Number(arcImage.positionY) === Number(y)) {
      className += ' Focus'
    }

    return (
      <div
        key={`${columnIndex},${rowIndex}`}
        style={style}
        className={className}
      >
        <LongPressable
          onShortPress={(): void => image$shortPress(arcImage)}
          onLongPress={(): void => image$longPress(arcImage)}
        >
          <img
            ref={image$ref}
            src={arcImage.thumbUrl || arcImage.url}
          />
        </LongPressable>

        <div className='Flags'>
          {_tasks.map((task): JSX.Element => (
            <Flag
              key={task.id}
              flag={task.flag[0]}
              onClick={(): void => flag$click(task.id)}
            />
          ))}
        </div>
      </div>
    )
  }

  if (space && arcImages) {
    return (
      <div className='GridView__Space'>
        <AutoSizer className='AutoSizer'>
          {({ width, height }: { width: number, height: number }) => (
            <Grid
              ref={grid$ref}
              className='Grid'
              width={width}
              height={height}
              columnCount={arcImageMaxX + 1}
              rowCount={arcImageMaxY + 1}
              columnWidth={arcImageWidth || 160}
              rowHeight={arcImageHeight || 160}
              onItemsRendered={$itemsRendered}
              onScroll={onScroll}
            >
              {Cell}
            </Grid>
          )}
        </AutoSizer>

        <div className='Controls'>
          <Minimap
            animated
            raised

            spaceCols={arcImageMaxX}
            spaceRows={arcImageMaxY}

            firstCol={firstCol}
            lastCol={lastCol}

            firstRow={firstRow}
            lastRow={lastRow}
          />

          <AreacamControl
            animated
            resting

            loading={areacamsLoading}
            disabled={!areacams || areacams.length === 0}
            areacams={areacams}
            onSelect={areacamControl$select}
          />

          <SensorControl
            animated
            resting

            loading={sensorRecordsLoading}
            disabled={!sensorRecords || sensorRecords.length === 0}
            onClick={sensorControl$click}
          />

          <TimeTravelControl
            animated
            resting

            time={now}
            onTimeTravel={$timeTravel}
          />
        </div>

        <Overlay show={isImage} animate>
          <div className='Overlay__Layout'>
            <ImageCard
              animated
              raised

              timestamp={now}
              x={x}
              y={y}
              space={space}
              arcImages={arcImages}

              spaceCols={arcImageMaxX}
              spaceRows={arcImageMaxY}

              show={isImage}
            />
          </div>
        </Overlay>

        <Overlay show={isAreacam && !!areacam} animate>
          <div className='Overlay__Layout'>
            <AreacamCard
              animated
              raised
              timestamp={now}
              x={x}
              y={y}
              space={space}
              areacam={areacam}
              show={isAreacam && !!areacam}
            />
          </div>
        </Overlay>

        <Overlay show={isSensor} animate>
          <div className='Overlay__Layout'>
            <SensorCard
              animated
              raised
              timestamp={now.toDate()}
              x={x}
              y={y}
              space={space}
              sensorRecords={sensorRecords}
              co2SensorRecords={co2SensorRecords}
            />
          </div>
        </Overlay>

        <Overlay show={isCreateTask} animate>
          <div className='Overlay__Layout'>
            <CreateTaskCard
              animated
              raised
              appliedToView='grid'
              spaceId={spaceId}
              positionX={positionX}
              positionY={positionY}
              onBack={card$back}
              onSave={task$save}
            />
          </div>
        </Overlay>

        <Overlay show={isCreateTaskIssue} animate>
          {user.admin
            ? (
            <div className='Overlay__Layout'>
              <CreateTaskIssueCard
                animated
                raised

                appliedToView='grid'
                zoneId={spaceId}
                positionX={positionX}
                positionY={positionY}

                onBack={card$back}
                onSave={taskIssue$save}
              />
            </div>
              )
            : (
            <p>No permission to add Task Issue</p>
              )
          }
        </Overlay>

        <Overlay show={isViewTask} animate>
          <div className='Overlay__Layout'>
            <ViewTaskCard
              animated
              raised

              id={taskId as string}
              onBack={card$back}
            />
          </div>
        </Overlay>
      </div>
    )
  }

  return (
    <div className='GridView__Space'></div>
  )
}

export default Space

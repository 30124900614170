// Types
import ITime from 'map-view/interfaces/time';

// Libs
import { isSameDay } from 'date-fns'

const UpdateNow = ({
  setNow
}: {
  setNow: (newNow: Date) => void;
}): void => {
  setNow(new Date())
}

const UpdateTimeIsNow = ({
  time,
  now,
  setTimeIsNow
}: {
  time: ITime,
  now: Date,
  setTimeIsNow: (newTimeIsNow: boolean) => void
}): void => {
  if (
    !time ||
    !time.now ||
    !now
  ) {
    return
  }

  const newTimeIsNow = isSameDay(now, time.now)
  setTimeIsNow(newTimeIsNow)
}

export {
  UpdateNow,
  UpdateTimeIsNow
}

/**
 * @author Miras Absar <mabsar@iunu.com>
 */

// Types
import IInputStatus from 'ui-lib/interfaces/IInputStatus'

// Libs
import { isEqual } from 'lodash'
import { v4 } from 'uuid'

// React
import {
  useCallback,
  useState
} from 'react'

// React Libs
import { useDebounce } from 'use-debounce'
import { useAsyncIter, useIter } from 'react-lib/use-iter'

// Effects
import { UpdateStatus, UpdateStatusAsync } from './effects'

/**
 * TODO: Write Documentation
 */
const useInput = <T, >(
  initVal: T,
  validator: (val: T) => IInputStatus
):
[
  T,
  IInputStatus | undefined,
  string | undefined,

  (newVal: T) => void,
  (reinitVal: T) => void,

  boolean,
  boolean,
  (newVal: boolean) => void,
  (newVal: boolean) => void
] => {
  // State /////////////////////////////////////////////////////////////////////

  const [value, setValue] = useState<T>(initVal)
  const [status, setStatus] = useState<IInputStatus>()
  const [uuid, setUUID] = useState<string>()
  const [touched, setTouched] = useState<boolean>(false)
  const [pristine, setPristine] = useState<boolean>(true)

  // Effects ///////////////////////////////////////////////////////////////////

  useIter(
    UpdateStatus,

    { validator,
      value,

      setStatus },

    [uuid]
  )

  // Callbacks /////////////////////////////////////////////////////////////////

  const _setValue = useCallback(
    (newVal: T): void => {
      if (isEqual(newVal, value)) {
        return
      }
      setValue(newVal)
      setUUID(v4())

      setPristine(false)
    },

    [uuid, pristine]
  )

  const _resetValue = useCallback(
    (reinitVal: T): void => {
      setValue(reinitVal)
      setUUID(v4())

      setTouched(false)
      setPristine(true)
    },

    [uuid, touched, pristine]
  )

  // Return ////////////////////////////////////////////////////////////////////
  return [
    value,
    status,
    uuid,
    _setValue,
    _resetValue,
    touched,
    pristine,
    setTouched,
    setPristine
  ];
}

/**
 * TODO: Write Documentation
 */
const useDebouncedInput = <T, >(
  initVal: T,
  validator: (val: T) => Promise<IInputStatus>,
  wait: number
): [
  T,
  IInputStatus | undefined,
  string | undefined,

  (newVal: T) => void,
  (reinitVal: T) => void,

  boolean,
  boolean,
  (newVal: boolean) => void,
  (newVal: boolean) => void
] => {
  // State /////////////////////////////////////////////////////////////////////

  const [value, setValue] = useState<T>(initVal)
  const [status, setStatus] = useState<IInputStatus>()
  const [uuid, setUUID] = useState<string>()
  const [debouncedUUID] = useDebounce(uuid, wait)

  const [touched, setTouched] = useState<boolean>(false)
  const [pristine, setPristine] = useState<boolean>(true)

  // Effects ///////////////////////////////////////////////////////////////////

  useAsyncIter(
    UpdateStatusAsync,

    { validator,
      value,

      setStatus },

    [debouncedUUID]
  )

  // Callbacks /////////////////////////////////////////////////////////////////

  const _setValue = useCallback(
    (newVal: T): void => {
      setValue(newVal)
      setUUID(v4())

      setPristine(false)
    },

    [ uuid,
      pristine ]
  )

  const _resetValue = useCallback(
    (reinitVal: T): void => {
      setValue(reinitVal)
      setUUID(v4())

      setTouched(false)
      setPristine(true)
    },

    [ uuid,
      touched,
      pristine ]
  )

  // Return ////////////////////////////////////////////////////////////////////

  return [
    value,
    status,
    debouncedUUID,

    _setValue,
    _resetValue,

    touched,
    pristine,
    setTouched,
    setPristine
  ]
}

export {
  useInput,
  useDebouncedInput
}

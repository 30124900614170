// Types
import EEntity from '../../enums/entity';
import { ISensor, ISensorForChartUtils } from 'graphql-lib/interfaces/ISensorDevice';

// Hybrids
import {
  html,
  define,
  render
} from 'hybrids';

// Utils
import {
  ChartAccuracy,
  ChartUnit
} from 'map-view/utils/chart'
import EChart from 'map-view/enums/chart';

import ID from 'graphql-lib/interfaces/ID';
import { IMeasurementOptions } from 'graphql-lib/interfaces/IMeasurementUnit';
import { IOverlayRender } from '../types';

interface IRenderProps {
  sensor: ISensor
  chart? : EChart
  measurement: IMeasurementOptions
  absoluteHumidity: number
  onClick: [(ev: Event, type: EEntity, id: ID) => void]
}

const $click = (host: {sensor: ISensorForChartUtils, onClick: Array<(ec: Event, id: ID, type: EEntity) => null>} & HTMLElement, ev: Event): void => {
  const [onClick] = host.onClick
  const type = EEntity.SensorDevice
  const id = host.sensor.id

  onClick(ev, id, type)
}

const AlertOverlay: IOverlayRender<{sensor: ISensor, chart: EChart, measurement: IMeasurementOptions}> = {
  sensor: null,
  chart: null,
  measurement: null,

  render: render(({
    sensor,
    chart,
    measurement
  }: IRenderProps) => {
    let value
    let unit

    if (
      sensor &&
      chart &&
      measurement
    ) {
      value = sensor.sensorRecord[0][measurement].toFixed(ChartAccuracy[chart])
      unit = ChartUnit[chart]
    }

    return html`
      <div
        class='SensorOverlay'
        onclick=${$click}
      >
        <div class='Value'>${value}</div>
        <div class='Unit'>${unit}</div>
      </div>
    `
  }, { shadowRoot: false })
}

define('sensor-overlay', AlertOverlay)

// React
import React, {
  useEffect,
  useState
} from 'react'
import IInputStatus from 'ui-lib/interfaces/IInputStatus';

// React Libs
import useAsyncEffect from 'use-async-effect'

import EInputStatus from '../../enums/input-status'
import IInputProps from '../../interfaces/IInputProps'

interface FallbackInputStatusType {
  status: EInputStatus;
  message: string;
}
const FallbackInputStatus: FallbackInputStatusType = Object.freeze({
  status: EInputStatus.Uninitialized,
  message: ''
})

const Input = ({
  // Visual Props
  animated,
  label,
  hint,
  facade: Facade,

  // Behavioral Props
  name,
  initialValue,
  showStatusUntouched,
  onValueChange,
  onStatusChange,
  readOnly,
  core: {
    inputType,
    getValue,
    getUIValue,
    getStatus
  }
}: IInputProps <any, any>): JSX.Element => {
  // Refs & State //////////////////////////////////////////////////////////////

  const [value, setValue] = useState<string>();
  const [uiValue, setUIValue] = useState<string>();
  const [status, setStatus] = useState<FallbackInputStatusType>();

  const [touched, setTouched] = useState<boolean>();
  const [dirty, setDirty] = useState<boolean>();

  // Effects ///////////////////////////////////////////////////////////////////

  // If `initialValue` changes,
  // reset state.
  useEffect((): void => {
    const newValue = initialValue;
    const newUIValue = getUIValue(initialValue);

    const newStatus: FallbackInputStatusType = null;

    const newTouched = false;

    setValue(newValue);
    setUIValue(newUIValue);

    setStatus(newStatus);

    setTouched(newTouched);
    setDirty(newTouched);

    if (name && onValueChange) {
      onValueChange(name, newValue);
    }
  }, [initialValue])

  // If `value` changes,
  // recompute status.
  useAsyncEffect(async (): Promise<void> => {
    const newStatus = await getStatus(value);
    setStatus(newStatus);

    if (name && onStatusChange) {
      onStatusChange(name as keyof IInputStatus, newStatus);
    }
  }, [value])

  const $blur = (): void => {
    const newTouched = true;
    setTouched(newTouched);
  }

  const $change = (_: string, newUIValue: any): void => {
    const newValue = getValue(newUIValue);
    const newDirty = true;

    setValue(newValue);
    setUIValue(newUIValue);
    setDirty(newDirty);

    if (name && onValueChange) {
      onValueChange(name, newValue);
    }
  }

  return (
    <Facade
      animated={animated}
      label={label}
      hint={hint}

      inputType={inputType}
      name={name}
      value={uiValue}
      status={status || FallbackInputStatus}
      touched={touched}
      dirty={dirty}
      showStatusUntouched={showStatusUntouched}
      onBlur={$blur}
      onChange={$change}
      readOnly={readOnly}
    />
  )
}

export default Input;

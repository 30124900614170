// @author Miras Absar <mabsar@iunu.com>

// Types
import IChart from 'map-view/interfaces/chart'

// Utils
import {
  ChartColor,
  Chartable,
  ChartName,
  ChartAccuracy,
  ChartUnit
} from 'map-view/utils/chart'

// React
import React, {
  useMemo
} from 'react'

interface IChartTileProps {
  animated?: boolean;
  chart: IChart;
}

const ChartTile = ({
  animated,
  chart
}: IChartTileProps): JSX.Element => {
  // Memos /////////////////////////////////////////////////////////////////////

  const chartName = useMemo(
    (): string => {
      const chartName = ChartName[chart.chart]
      return chartName
    },
    [chart]
  )

  const chartValue = useMemo(
    (): string => {
      const chartValue = (Array.isArray(chart.value)
        ? chart.value[chart.value.length - 1][1]
        : chart.value).toFixed(ChartAccuracy[chart.chart])

      return chartValue
    },
    [chart]
  )

  const chartUnit = useMemo(
    (): string => {
      const chartUnit = chart.dynamicUnit || ChartUnit[chart.chart]
      return chartUnit
    },
    [chart]
  )

  // Render ////////////////////////////////////////////////////////////////////

  let className = 'ChartTile'
  if (animated) className += ' Animated'

  return (
    <div className={className}>
      <p className='Name'>{chartName}</p>
      <p className='Value'>{chartValue} {chartUnit}</p>
    </div>
  )
}

export default ChartTile

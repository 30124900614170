/**
 * @author Miras Absar <mabsar@iunu.com>
 */

export const Crops = '/crops'
export const CompareCrops = `${Crops}/comparison`
export const ImportCrops = `${Crops}/import`
export const CreateCrop = `${Crops}/create`
export const ViewCrop = `${Crops}/:id`
export const EditCrop = `${Crops}/edit/:id`
export const Tag = `${Crops}/tag/:id`
export const CloseoutInventory = `${Crops}/closeout-inventory`

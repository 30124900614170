/**
 * @author Miras Absar <mabsar@iunu.com>
 */

import { v4 } from 'uuid'
import React, {
  ChangeEvent
} from 'react'

import EInputStatus from '../../enums/input-status'
import IInputFacadeProps from '../../interfaces/IInputFacadeProps'
import Status2ClassName from '../../utils/Status2ClassName'

export default function TextFacade ({
  /* Visual Properties */
  animated,
  label,
  hint,

  /* Behavioral Properties */
  inputType,
  name,
  value,
  status,
  touched,
  dirty,
  showStatusUntouched,
  onBlur,
  onChange,
  readOnly
}: IInputFacadeProps<string>): JSX.Element {
  const id = v4()
  const className = animated ? 'Input Animated' : 'Input'
  const showingStatus = (touched || dirty || showStatusUntouched) &&
    status.status !== EInputStatus.Uninitialized &&
    status.status !== EInputStatus.None

  const statusClassName = Status2ClassName[status.status]

  const $blur = (): void => {
    if (name && onBlur) {
      onBlur(name)
    }
  }

  const $input = (ev: ChangeEvent<HTMLInputElement>): void => {
    const { value: newValue } = ev.target

    if (name && onChange) {
      onChange(name, newValue)
    }
  }

  return (
    <div className={className}>
      <label htmlFor={id}>{label}</label>

      <input
        id={id}
        type={inputType}
        name={name}
        placeholder={hint}
        value={value}
        autoComplete='off'
        onBlur={$blur}
        onInput={$input}
        readOnly={readOnly}
      />

      {showingStatus
        ? <p className={statusClassName}>{status.message}</p>
        : null}
    </div>
  )
}

/**
 * @author Miras Absar <mabsar@iunu.com>
 */
import { match } from 'react-router-dom'

// Styles
import lunaLogo from 'public/assets/img/luna-logo.png'

// Types
import EInputStatus from 'ui-lib/enums/input-status'

// React
import React, {
  KeyboardEvent,
  useCallback,
  useState
} from 'react'

// React Libs
import { gql, useMutation } from '@apollo/client'
import { useIter } from 'react-lib/use-iter'

// UI Lib
import { useInput } from 'ui-lib/hooks/use-input'
import { RequiredPassword } from 'ui-lib/validators/password'
import OutlinedInput from 'ui-lib/components/input/outlined-input'
import Button from 'ui-lib/components/button'

// Effects
import { HandleResetPassword } from './effects'

// Mutations
const UpdateResetToken = gql`
  mutation($token: String!, $password: String!) {
    ResetToken {
      update(input: { token: $token, password: $password }) {
        email
      }
    }
  }
`

// Routes
const ResetPasswordRoutes = [
  '/reset-password/:token'
]

export interface IResetPasswordProps {
  match: match<{ token: string }>;
}

const ResetPassword = ({
  match: {
    params: { token }
  }
}: IResetPasswordProps): JSX.Element => {
  // State /////////////////////////////////////////////////////////////////////

  const passwordState = useInput<string>('', RequiredPassword)
  const [
    password,
    passwordStatus,
    passwordUUID
  ] = passwordState

  // API ///////////////////////////////////////////////////////////////////////

  const [
    updateResetToken,

    { loading,
      error,
      data }
  ] = useMutation(UpdateResetToken)

  // Effects ///////////////////////////////////////////////////////////////////

  useIter(
    HandleResetPassword,

    { loading,
      error,
      data },

    [ loading,
      error,
      data ]
  )

  // Callbacks /////////////////////////////////////////////////////////////////

  const $resetPassword = useCallback(
    (): void => {
      if (passwordStatus?.status === EInputStatus.OK) {
        updateResetToken({ variables: {
          token,
          password
        } })
      }
    },
    []
  )

  const $keyPress = useCallback(
    (ev: KeyboardEvent): void => {
      if (ev.key === 'Enter') {
        $resetPassword()
      }
    },
    []
  )

  // Render ////////////////////////////////////////////////////////////////////

  return (
    <main
      className='ResetPassword'
      onKeyPress={$keyPress}
    >
      <section className='ResetPasswordCard'>
        <div className='Image'>
          <img src={lunaLogo} />
        </div>

        <OutlinedInput
          animated
          label='Password'
          inputProps={{ type: 'password' }}
          state={passwordState}
        />

        <Button
          loading={loading}
          animated
          resting
          onClick={$resetPassword}
        >
          Reset Password
        </Button>
      </section>
    </main>
  )
}

export { ResetPasswordRoutes }
export default ResetPassword

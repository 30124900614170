/**
 * @author Miras Absar <mabsar@iunu.com>
 */

import { gql } from '@apollo/client'

const CreateRefreshToken = gql`
  mutation(
    $email: Email,
    $password: String,
    $apiKey: String
  ) {
    RefreshToken {
      create(input: {
        email: $email,
        password: $password,
        apiKey: $apiKey
      }) {
        userId
        customerId

        iat
        exp

        isApi

        refreshToken
      }
    }
  }
`

export { CreateRefreshToken }

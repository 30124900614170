/**
 * @author Miras Absar <mabsar@iunu.com>
 * @file ...
 */

enum EVisibility {
  None = 'Visibility:None',
  Other = 'Visibility:Other',
  ViewportContains = 'Visibility:ViewportContains',
  ContainsViewport = 'Visibility:ContainsViewport',
  IntersectsViewport = 'Visibility:IntersectsViewport',
}

export default EVisibility

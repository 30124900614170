// Types
import IChart from 'map-view/interfaces/chart';
import EEntity from 'map-view/enums/entity';

// Utils
import {
  ChartName,
  ChartUnit
} from 'map-view/utils/chart';

// Libs
import download from 'in-browser-download';
import { format } from 'date-fns';
import { v4 } from 'uuid';

// React
import React, {
  useCallback,
  useRef,
  useState
} from 'react';

// React Libs
import { useIter } from 'react-lib/use-iter';

// Highcharts
import { Options as HighchartsOptions } from 'highcharts';
import Highcharts from 'highcharts/highstock';
import HighchartsReact from 'highcharts-react-official';

// Effects
import {
  UpdateHighchartsSize,
  UpdateOptions
} from './effects';

// Components
import Button from 'ui-lib/components/button'
import { createDate } from 'utils/dates'
import { ReactElementType } from 'react-window'

const papaparse = require('papaparse');
const { unparse } = papaparse;
interface IChartCanvasProps {
  resize: string;
  charts: Array<IChart>;
  chartsUUID?: string;
}

const DateFormat = 'M/d/yy p'
const ChartCanvas = ({
  resize,
  charts,
  chartsUUID
}: IChartCanvasProps): JSX.Element | null => {
  // State /////////////////////////////////////////////////////////////////////
  const highchartsRef = useRef<typeof Highcharts>()
  const [options, setOptions] = useState<HighchartsOptions>()
  const [xAxis, setXAxis] = useState<{min: number, max: number}>()

  const [optionsUUID, setOptionsUUID] = useState<string>()

  // Effects ///////////////////////////////////////////////////////////////////

  useIter(
    UpdateHighchartsSize,
    { highchartsRef: highchartsRef.current },
    [resize]
  )

  useIter(
    UpdateOptions,

    { charts,
      setOptions,
      setXAxis,
     },

    [ chartsUUID ]
  )

  // Callbacks /////////////////////////////////////////////////////////////////

  const highcharts$ref = useCallback(
    (newVal: any) => {
      if (
        newVal &&
        !highchartsRef.current
      ) {
        highchartsRef.current = newVal
        setOptionsUUID(v4())
      }
    },
    [optionsUUID]
  )

  const download$click = useCallback(
    (xAxis): void => {
      charts
        .forEach(chart => {
          const chartName = ChartName[chart.chart]
          const chartUnit = chart.dynamicUnit || ChartUnit[chart.chart]
          let chartValue = chart.value
          if (Array.isArray(chartValue)) {
            if (xAxis?.min) {
              chartValue = chartValue.filter(chartItem => chartItem[0] >= xAxis.min && chartItem[0] <= xAxis.max)
            }

            const dataChartValue = chartValue
              .map(([unixTime, value]: [unixTime: number, value: number]) => {
                let firstValue, secondValue
                const parent = chart.meta.parent;
                const isInventory = parent.__typename === EEntity.Inventory;
                const isContainer = parent.__typename === EEntity.Container;

                if (isInventory && 'varietyName' in parent) {
                  firstValue = parent.varietyName
                  secondValue = parent.code
                }

                if (isContainer && 'inventory' in parent) {
                  firstValue = parent.inventory[0].varietyName
                  secondValue = parent.inventory[0].code
                }
                return [
                  firstValue,
                  secondValue,
                  chart.meta.parentName,
                  format(createDate(unixTime), DateFormat),
                  value,
                  chartUnit
                ]
              })

            const chartCSV = unparse({
              fields: ['Variety', 'Luna Code', 'Name', 'Time', 'Value', 'Unit'],
              data: dataChartValue
            })

            download(chartCSV, `${chartName}.csv`)
          }
        })
    },

    [chartsUUID]
  )

  // Render ////////////////////////////////////////////////////////////////////

  if (!options) {
    return null
  }
  return (
    <div className='Theme__Light ChartCanvas'>
      <HighchartsReact
        ref={highcharts$ref}
        options={options}
        highcharts={Highcharts}
        constructorType='stockChart'
        containerProps={{ className: 'ChartCanvas--Highcharts' }}
      />

      <div className='Buttons'>
        <Button
          outlined
          animated
          onClick={() => download$click(xAxis)}
        >
          Download
        </Button>
      </div>

    </div>
  )
}

export default ChartCanvas

/**
 * @author Miras Absar <mabsar@iunu.com>
 */

// Types
import ICustomer from 'graphql-lib/interfaces/ICustomer'

// React
import React, {
  useCallback
} from 'react'

// UI Lib
import Overlay from 'zinnia/components/atoms/general/overlay'
import SelectCustomerCard from 'authorization/components/select-customer-card'

// Routes
const SelectCustomerRoutes = [
  '/select-customer'
]

const SelectCustomer = (): JSX.Element => {
  // Callbacks /////////////////////////////////////////////////////////////////

  const $selectCustomer = useCallback(
    (newCustomer: ICustomer): void => {
      const newHref = `${window.location.origin}?cid=${newCustomer.id}`
      window.location.href = newHref
    },
    []
  )

  // Render ////////////////////////////////////////////////////////////////////

  return (
    <main className='SelectCustomer'>
      <Overlay
        show
        animate
      >
        <div className='Overlay__Layout'>
          <SelectCustomerCard
            animated
            raised

            onSelect={$selectCustomer}
          />
        </div>
      </Overlay>
    </main>
  )
}

export { SelectCustomerRoutes }
export default SelectCustomer

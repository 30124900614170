// Libs
import { v4 } from 'uuid'

// React
import React, {
  useCallback,
  useContext,
  useRef,
  useState
} from 'react'

// React Lib
import { useIter } from 'react-lib/use-iter'

// Effects
import {
  UpdateVisibleSpaces,
  UpdateSearchedSpaces,
  UpdateScroll
} from './effects'

// UI Lib
import { useInput } from 'ui-lib/hooks/use-input'
import { NoOp } from 'ui-lib/validators/no-op'

// MapView
import Mobius from 'map-view/contexts/mobius'
import Search from '../search'
import SpaceItem from './space-item'
import ISpace from 'graphql-lib/interfaces/ISpace'
import IInputStatus from 'ui-lib/interfaces/IInputStatus'

interface IFacilityListProps {
  animated?: boolean;
  invisible?: boolean;
}

const FacilityList = ({
  animated,
  invisible
}: IFacilityListProps): JSX.Element => {
  // Context ///////////////////////////////////////////////////////////////////

  const {
    selectedEntities: {
      selectedEntitiesDiff,
      selectedEntitiesUUID
    },

    visibleEntities: {
      visibleEntities,
      visibleEntitiesUUID
    },

    spaces: {
      spaces,
      spacesUUID
    },

    sensors: {
      sensors,
      sensorsUUID
    },

    areacams: {
      areacams,
      areacamsUUID
    }
  } = useContext(Mobius)

  // State /////////////////////////////////////////////////////////////////////

  const listRef = useRef<HTMLDivElement>()

  const searchState = useInput<string>('', NoOp)
  const visibleState = useInput<boolean>(true, NoOp)

  const [search, , searchUUID] = searchState
  const [visible, , visibleUUID] = visibleState

  const [visibleSpaces, setVisibleSpaces] = useState<Array<ISpace>>()
  const [searchedSpaces, setSearchedSpaces] = useState<Array<ISpace>>()

  const [listRefUUID, setListRefUUID] = useState<string>()

  const [visibleSpacesUUID, setVisibleSpacesUUID] = useState<string>()
  const [searchedSpacesUUID, setSearchedSpacesUUID] = useState<string>()

  // Effects ///////////////////////////////////////////////////////////////////

  useIter(
    UpdateVisibleSpaces,

    { visibleEntities,
      spaces,

      setVisibleSpaces,
      setVisibleSpacesUUID },

    [ visibleEntitiesUUID,
      spacesUUID ]
  )

  useIter(
    UpdateSearchedSpaces,

    { spaces,
      visibleSpaces,
      search,
      visible,

      setSearchedSpaces,
      setSearchedSpacesUUID },

    [ spacesUUID,
      visibleSpacesUUID,
      searchUUID,
      visibleUUID ]
  )

  useIter(
    UpdateScroll,

    { selectedEntitiesDiff,
      listRef: listRef.current },

    [ selectedEntitiesUUID,
      listRefUUID ]
  )

  // Callbacks /////////////////////////////////////////////////////////////////

  const list$ref = useCallback(
    (newVal: HTMLDivElement): void => {
      if (
        newVal &&
        !listRef.current
      ) {
        listRef.current = newVal
        setListRefUUID(v4())
      }
    },
    [listRefUUID]
  )

  // Render ////////////////////////////////////////////////////////////////////

  let className = 'FacilityList'
  if (animated) className += ' Animated'
  if (invisible) className += ' Invisible'
  return (
    <div className={className}>
      <Search
        animated={animated}
        isIssuesTab={false}
        searchState={searchState}
        visibleState={visibleState}
      />

      <div
        ref={list$ref}
        className='List'
      >
        {searchedSpaces?.map(space => (
          <SpaceItem
            key={space.id}
            animated={animated}
            space={space}
          />
        ))}
      </div>
    </div>
  )
}

export default FacilityList

import { GetItem, EStorage } from 'utils/storage'
import EColor from 'ui-lib/enums/color'
import IFilter from 'arrayview/interfaces/IFilter'
import ITask from 'graphql-lib/interfaces/ITask'

export const All: IFilter<ITask> = Object.freeze({
  id: 'task:filter:all',
  icon: undefined,
  name: 'All',
  color: EColor.None,
  func: (_: IFilter<ITask>, task: ITask): boolean => true
});

export const Alert: IFilter<ITask> = Object.freeze({
  id: 'task:filter:all',
  icon: undefined,
  name: 'Alert',
  color: EColor.None,
  func: (_: IFilter<ITask>, task: ITask): boolean => task.flag[0].title == 'LUNA Alert'
});

export const CreatedByMe: IFilter<ITask> = Object.freeze({
  id: 'task:filter:created-by-me',
  icon: undefined,
  name: 'Created By Me',
  color: EColor.None,
  func: (_: IFilter<ITask>, task: ITask): boolean => {
    if (task.customerUser && task.customerUser.length === 1) {
      const userId = GetItem('userID', EStorage.LocalStorage)
      return task.customerUser[0].id == userId
    }

    return false
  }
});

export const AssignedToMe: IFilter<ITask> = Object.freeze({
  id: 'task:filter:assigned-to-me',
  icon: undefined,
  name: 'Assigned To Me',
  color: EColor.None,
  func: (_: IFilter<ITask>, task: ITask): boolean => {
    if (task.taskAssignee && task.taskAssignee.length > 0) {
      const userId = GetItem('userID', EStorage.LocalStorage)
      return task.taskAssignee.some((taskAssignee): boolean => {
        return Number(taskAssignee.customerUser[0]?.id) === Number(userId)
      })
    }

    return false
  }
});

export const Open: IFilter<ITask> = Object.freeze({
  id: 'task:filter:open',
  icon: undefined,
  name: 'Open',
  color: EColor.None,
  func: (_: IFilter<ITask>, task: ITask): boolean => !task.complete
});

export const Completed: IFilter<ITask> = Object.freeze({
  id: 'task:filter:completed',
  icon: undefined,
  name: 'Completed',
  color: EColor.None,
  func: (_: IFilter<ITask>, task: ITask): boolean => task.complete
});

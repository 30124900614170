// Libs
import { GetItem, EStorage } from 'utils/storage'
import m from 'moment-timezone'
import download from 'in-browser-download'

// React Libs
import { toast } from 'react-toastify'
import {
  faDownload,
  faUpload,
  faPlus
} from '@fortawesome/pro-regular-svg-icons'

// GraphQL
import { gql } from '@apollo/client'
import client from 'graphql-lib/index'

// Utils
import GoTo from 'react-lib/go-to'

// UI Lib
import EColor from 'ui-lib/enums/color'
import IAction from 'arrayview/interfaces/IAction'
import {
  CreateCrop as CreateCropRoute,
  ImportCrops as ImportCropsRoute
} from '../utils/Routes'

import { createDate } from 'utils/dates';
import ICrop from 'graphql-lib/interfaces/ICrop'
import IRoute from 'ui-lib/interfaces/IRoute'

const papaparse = require('papaparse');
const { unparse } = papaparse;

const QueryCropExport = gql`
  query {
    cropExport {
      cropId
      cropName
      cropStartDate
      cropTransplantDate
      cropEndDate

      productCode

      inventoryId
      inventoryCode

      locatorId
      locatorCode
      lastSeen
      facilityId
      spaceId
      positionX
      positionY
    }
  }
`

const ExportCrops: IAction<IRoute> = {
  id: 'action::export-crops',
  icon: faDownload,
  name: 'Export Crops',
  color: EColor.None,
  applicableFunc: () => true,
  func: async () => {
    const {
      error,
      data
    } = await client.query({ query: QueryCropExport })

    if (error) {
      toast.error("Couldn't export crops; please try again in a few seconds.")
    }

    if (data) {
      const facilities = GetItem('facilities', EStorage.EphemeralStorage)
      const timezone = facilities[0].timezone

      const { cropExport } = data
      const inventory = cropExport.map((crop: any): any => {
        return {
          Crop: crop.cropName,
          'Crop Start Date': m.tz(crop.cropStartDate, timezone).format('ll'),
          'Crop Transplant Date': crop.cropTransplantDate
            ? m.tz(crop.cropTransplantDate, timezone).format('ll')
            : '',
          'Crop End Date': m.tz(crop.cropEndDate, timezone).format('ll'),
          Product: crop.productCode,
          Inventory: crop.inventoryCode,
          Locator: crop.locatorCode || '',
          'Crop URL': `${location.origin}/crops/${crop.cropId}`,
          'Image URL': crop.lastSeen
            ? `${location.origin}/grid/space/${crop.spaceId}/${createDate(crop.lastSeen).toISOString()}/${crop.positionX}/${crop.positionY}?imageId=-1`
            : ''
        }
      })

      const csv = unparse(inventory)
      download(csv, `LUNA Crop Export - ${m().toISOString()}.csv`)
    }
  }
}

const ImportCrops: IAction<IRoute> = {
  id: 'action:import-crops',
  icon: faUpload,
  name: 'Import Crops',
  color: EColor.None,
  applicableFunc: () => true,
  func: async () => GoTo(ImportCropsRoute)
}

const CreateCrop: IAction<IRoute> = {
  id: 'action:create-crop',
  icon: faPlus,
  name: 'Create Crop',
  color: EColor.None,
  applicableFunc: () => true,
  func: async () => GoTo(CreateCropRoute)
}

export {
  ExportCrops,
  ImportCrops,
  CreateCrop
}

/**
 * @author Miras Absar <mabsar@iunu.com>
 */

import { gql } from '@apollo/client'

const CreateAccessToken = gql`
  mutation(
    $refreshToken: String!,
    $customerId: ID
  ) {
    AccessToken {
      create(input: {
        refreshToken: $refreshToken,
        customerId: $customerId
      }) {
        userId
        customerId

        iat
        exp

        isApi

        accessToken
      }
    }
  }
`

export { CreateAccessToken }

// GraphQL
import {
  ApolloClient,
  InMemoryCache,
  createHttpLink
} from '@apollo/client';

import { onError } from '@apollo/client/link/error';
import { setContext } from '@apollo/client/link/context';

// Config
import config from 'config'

// Utils
import Has from 'utils/has'
import {
  EStorage,
  GetItem,
  clearStorage
} from 'utils/storage'

const httpLink = createHttpLink({
  uri: config.graphQlV1Url
})

const authLink = setContext((_, { headers }) => {
  const accessToken = GetItem('accessToken', EStorage.EphemeralStorage)
  const hasAccessToken = Has(accessToken)

  if (hasAccessToken) {
    return {
      headers: {
        ...headers,
        Authorization: `Bearer ${accessToken}`
      }
    }
  }

  return {
    headers: {
      headers
    }
  }
});

const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors) {
    graphQLErrors.forEach(({ message, locations, path }) => {
      switch (message) {
        case 'Invalid refresh token.':
          clearStorage();
          break
        case 'Invalid access token.':
          window.location.reload()
          break
        default:
          console.log(`[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`)
          break
      }
    });
  }
  if (networkError) {
    console.error(`[Network error]: ${networkError}`);
  }
});

const apolloClient = new ApolloClient({
  cache: new InMemoryCache(),
  link: authLink.concat(httpLink).concat(errorLink)
})

export default apolloClient

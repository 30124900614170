/**
 * @author Miras Absar <mabsar@iunu.com>
 */

// Types
import EEntity from 'map-view/enums/entity'

// Libs
import { v4 } from 'uuid'

// React
import React, {
  useCallback,
  useContext,
  useRef,
  useState,
  useEffect
} from 'react'

// React Lib
import { useIter } from 'react-lib/use-iter'

// Queries
import { QueryTask } from './queries'

// Effects
import {
  UpdateOverlays,
  UpdateTasks,
  UpdateVisibleTasks,
  UpdateSearchedTasks,
  UpdateScroll,
  UpdateCreatingViewingTask,
} from './effects'

// UI Lib
import { useInput } from 'ui-lib/hooks/use-input'
import { NoOp } from 'ui-lib/validators/no-op'
import CreateTaskCard from 'tasks/components/create-task-card'
import ViewTaskCard from 'tasks/components/view-task-card'

// Contexts
import Mobius from 'map-view/contexts/mobius'
import { TaskListContext } from './contexts/TaskListContext';

// MapView
import Search from '../search'
import TaskItem from './task-item'
import ITask from 'graphql-lib/interfaces/ITask'

interface ITasksList {
  animated?: boolean;
  invisible?: boolean;
}

const TasksList = ({
  animated,
  invisible
}: ITasksList): JSX.Element => {
  // Context ///////////////////////////////////////////////////////////////////

  const {
    searchParams: {
      searchParams,
      setSearchParams,
      searchParamsUUID
    },

    selectedEntities: {
      selectedEntities,
      selectedEntitiesDiff,
      setSelectedEntities,
      selectedEntitiesUUID
    },

    visibleEntities: {
      visibleEntities,
      visibleEntitiesUUID
    },

    overlays: {
      overlays,
      selectOverlay,
      overlaysUUID
    },

    tasks: {
      tasks: _tasks,
      tasksUUID: _tasksUUID,
      setTasks: _setTasks
    }
  } = useContext(Mobius)
  const taskListContext = useContext(TaskListContext)

  // State /////////////////////////////////////////////////////////////////////

  const listRef = useRef<HTMLDivElement>()

  const searchState = useInput<string>('', NoOp)
  const visibleState = useInput<boolean>(true, NoOp)

  const [search, , searchUUID] = searchState
  const [visible, , visibleUUID] = visibleState

  const [tasks, setTasks] = useState<Array<ITask>>()
  const [visibleTasks, setVisibleTasks] = useState<Array<ITask>>()
  const [searchedTasks, setSearchedTasks] = useState<Array<ITask>>()

  const [listRefUUID, setListRefUUID] = useState<string>()

  const [tasksUUID, setTasksUUID] = useState<string>()
  const [visibleTasksUUID, setVisibleTasksUUID] = useState<string>()
  const [searchedTasksUUID, setSearchedTasksUUID] = useState<string>()
  // Effects ///////////////////////////////////////////////////////////////////

  useIter(
    UpdateOverlays,

    { invisible,
      overlays,
      selectOverlay },

    [ invisible,
      selectOverlay,
      overlaysUUID ]
  )

  useIter(
    UpdateTasks,

    { tasks: _tasks,

      setTasks,
      setTasksUUID },

    [_tasks]
  )

  useIter(
    UpdateVisibleTasks,

    { visibleEntities,
      tasks,

      setVisibleTasks,
      setVisibleTasksUUID },

    [ visibleEntitiesUUID,
      tasksUUID ]
  )

  useIter(
    UpdateSearchedTasks,

    { tasks,
      visibleTasks,
      search,
      visible,

      setSearchedTasks,
      setSearchedTasksUUID },

    [ tasksUUID,
      visibleTasksUUID,
      searchUUID,
      visibleUUID ]
  )

  useIter(
    UpdateScroll,

    { selectedEntitiesDiff,
      listRef: listRef.current },

    [ selectedEntitiesUUID,
      listRefUUID ]
  )

  useIter(
    UpdateCreatingViewingTask,

    { searchParams,
      selectedEntities,

      setCreatingTask: taskListContext.setCreatingTask,
      setViewingTask: taskListContext.setViewingTask },

    [ searchParamsUUID,
      selectedEntitiesUUID ]
  )

  // Callbacks /////////////////////////////////////////////////////////////////

  const list$ref = useCallback(
    (newVal: HTMLDivElement): void => {
      if (
        newVal &&
        !listRef.current
      ) {
        listRef.current = newVal
        setListRefUUID(v4())
      }
    },
    [listRefUUID]
  )

  const createTaskCard$back = useCallback(
    (): void => {
      const newSearchParams = { ...searchParams }
      delete newSearchParams.creatingTask
      delete newSearchParams.inventoryId
      delete newSearchParams.containerId
      delete newSearchParams.positionX
      delete newSearchParams.positionY

      setSearchParams(newSearchParams)
    },
    [searchParamsUUID]
  )

  // This gets called once a task is created on the backend (and task assignee if applicable)
  const createTaskCard$save = useCallback(
    (createTaskData): void => {
      const newSearchParams = { ...searchParams }
      delete newSearchParams.creatingTask
      delete newSearchParams.inventoryId
      delete newSearchParams.containerId
      delete newSearchParams.positionX
      delete newSearchParams.positionY

      setSearchParams(newSearchParams)

      // Make TasksList and MapCanvas in browser rerender with updated tasks
      const newTask = createTaskData.Task.create
      _setTasks((currentLocalTasks) => [newTask, ...currentLocalTasks])
    },

    [ searchParamsUUID,
      _tasksUUID,
      _setTasks ]
  )

  const viewTaskCard$back =
    (): void => {
      const newSelectedEntities = selectedEntities
        .filter((entity:any) => !(
          (entity.type === EEntity.Task) &&
          entity.id === taskListContext.viewingTask
        ))
      taskListContext.setViewingTask(undefined)
      setSelectedEntities(newSelectedEntities)
    }

  // Render ////////////////////////////////////////////////////////////////////

  let className = 'TasksList'
  if (animated) className += ' Animated'
  if (invisible) className += ' Invisible'
  if (taskListContext.creatingTask) {
    return (
      <div className={className}>
        <CreateTaskCard
          appliedToView={'map'}
          animated={animated}
          inventoryId={searchParams.inventoryId}
          containerId={searchParams.containerId}
          positionX={searchParams.positionX}
          positionY={searchParams.positionY}
          isMapView
          onBack={createTaskCard$back}
          onSave={createTaskCard$save}
        />
      </div>
    )
  }

  if (taskListContext.viewingTask) {
    return (
      <div className={className}>
        <ViewTaskCard
          animated={animated}

          id={taskListContext.viewingTask}
          onBack={viewTaskCard$back}
        />
      </div>
    )
  }
  return (
    <div className={className}>
      <Search
        animated={animated}
        isIssuesTab={false}
        searchState={searchState}
        visibleState={visibleState}
      />

      <div
        ref={list$ref}
        className='List'
      >
        {searchedTasks?.map((task: ITask, index) => {
          // always show task if it's selected
          const taskSelected = !!selectedEntities
            .find((entity:any) =>
              entity.type === EEntity.Task &&
              entity.id === task.id)

          // Don't show completed tasks or rejected issues
          if (!taskSelected && task.complete) return

          return (
            <TaskItem
              key={task.id}
              animated={animated}
              task={task}
            />
          )
        })
        }
      </div>
    </div>
  )
}

export default TasksList

// Types
import { QueryResult } from '@apollo/client'
import EChart from 'map-view/enums/chart'
import IChart from 'map-view/interfaces/chart'

// Utils
import {
  ChartOrder,
  NewChart
} from 'map-view/utils/chart'

// Libs
import {
  isEqual,
  subDays
} from 'date-fns'
import { v4 } from 'uuid'

// Types
import { ICO2SensorRecord, ISensorRecord } from 'graphql-lib/interfaces/ISensorDevice'
import IFacility from 'graphql-lib/interfaces/IFacility'
import IBuilding from 'graphql-lib/interfaces/IBuilding'
import ISpace from 'graphql-lib/interfaces/ISpace'
import IAreaImageRecord, { IAreaImageRecordData } from 'graphql-lib/interfaces/IAreaImageRecord'
import ID from 'graphql-lib/interfaces/ID';

const UpdateDates = ({
  setStartDate,
  setEndDate,
  setDateUUID
}: {
  setStartDate: (newVal: Date) => void;
  setEndDate: (newVal: Date) => void;
  setDateUUID: (newVal: string) => void;
}): void => {
  const newEndDate = new Date()
  const newStartDate = subDays(newEndDate, 7)

  setStartDate(newStartDate)
  setEndDate(newEndDate)
  setDateUUID(v4())
}

const UpdateSpaces = ({
  query,
  setSpaceIDs,
  setSpaces,
  setSpacesUUID
}: {
  query: QueryResult;
  setSpaceIDs: (newVal: ID[]) => void;
  setSpaces: (newVal: []) => void;
  setSpacesUUID: (newVal: string) => void;
}): void => {
  if (
    query.loading ||
    query.error ||
    !query.data
  ) {
    return
  }

  const facilities = query.data.facility
  const buildings = facilities
    .map((facility: IFacility) => facility.building)
    .flat()

  const newSpaces = buildings
    .map((building: IBuilding) => building.space)
    .flat()

  const newSpaceIDs = newSpaces
    .map((space: ISpace) => space.id)

  setSpaceIDs(newSpaceIDs)
  setSpaces(newSpaces)
  setSpacesUUID(v4())
}

const UpdateSensorIDs = ({
  endDate,
  sensorRecordsQuery,
  co2SensorRecordsQuery,
  setSensorIDs,
  setSensorsUUID
}: {
  endDate: Date;
  sensorRecordsQuery: QueryResult;
  co2SensorRecordsQuery: QueryResult;
  setSensorIDs: (newVal: ID[]) => void;
  setSensorsUUID: (newVal: string) => void;
}): void => {
  if (
    sensorRecordsQuery.loading ||
    sensorRecordsQuery.error ||
    !sensorRecordsQuery.data ||
    !isEqual(endDate, sensorRecordsQuery.variables.endDate) ||

    co2SensorRecordsQuery.loading ||
    co2SensorRecordsQuery.error ||
    !co2SensorRecordsQuery.data ||
    !isEqual(endDate, co2SensorRecordsQuery.variables.endDate)
  ) {
    return
  }

  const newSensorIDs: ID[] = Array.from(
    [
      ...sensorRecordsQuery.data.sensorRecord,
      ...co2SensorRecordsQuery.data.co2SensorRecord
    ]
      .reduce(
        (newSensorIDs, sensorRecord) => newSensorIDs.add(sensorRecord.sensorDeviceId),
        new Set()
      )
  )

  setSensorIDs(newSensorIDs)
  setSensorsUUID(v4())
}

const UpdateSensors = ({
  endDate,

  sensorRecordsQuery,
  co2SensorRecordsQuery,
  sensorsQuery,
  setSensors,
  setSensorsEndDate,
  setSensorsUUID
}: {
  endDate: Date;
  sensorRecordsQuery: QueryResult<{sensorRecord: Array<ISensorRecord>}>;
  co2SensorRecordsQuery: QueryResult<{co2SensorRecord: Array<ICO2SensorRecord>}>;
  sensorsQuery: QueryResult;
  setSensors: (newVal: []) => void;
  setSensorsEndDate: (newVal: Date) => void;
  setSensorsUUID: (newVal: string) => void;
}): void => {
  if (
    sensorRecordsQuery.loading ||
    sensorRecordsQuery.error ||
    !sensorRecordsQuery.data ||
    !isEqual(endDate, sensorRecordsQuery.variables.endDate) ||

    co2SensorRecordsQuery.loading ||
    co2SensorRecordsQuery.error ||
    !co2SensorRecordsQuery.data ||
    !isEqual(endDate, co2SensorRecordsQuery.variables.endDate) ||

    sensorsQuery.loading ||
    sensorsQuery.error ||
    !sensorsQuery.data
  ) {
    return
  }

  const newSensors = sensorsQuery.data.sensor
    .map((sensor: any) => {
      const newSensor = Object.assign(
        {},
        sensor
      )

      newSensor.sensorRecord = [
        ...sensorRecordsQuery.data.sensorRecord,
        ...co2SensorRecordsQuery.data.co2SensorRecord
      ]
        .filter(sensorRecord => Number(sensorRecord.sensorDeviceId) === Number(newSensor.id))

      if (newSensor.sensorRecord.length > 0) {
        newSensor.spaceId = newSensor.sensorRecord[0].spaceId
      }
      newSensor.sensorRecord = newSensor.sensorRecord
        .filter((sensorRecord: ISensorRecord) => Number(sensorRecord.spaceId) === Number(newSensor.spaceId))

      return newSensor
    })

  setSensors(newSensors)
  setSensorsEndDate(endDate)
  setSensorsUUID(v4())
}

const UpdateCharts = ({
  endDate,

  spaces,
  sensors,
  sensorsEndDate,

  setCharts,
  setChartsEndDate,
  setChartsUUID
}:{
  endDate: Date
  spaces: Array<ISpace>
  sensors: Array<ISensorRecord>
  sensorsEndDate: Date
  setCharts: (newValue: Array<IChart>) => void
  setChartsEndDate: (newValue: Date) => void
  setChartsUUID: (newValue: string) => void
}): void => {
  if (
    !spaces ||
    !sensors ||
    !isEqual(endDate, sensorsEndDate)
  ) {
    return
  }

  const newCharts = spaces
    .map(space => {
      const newSpace = Object.assign(
        {},
        space
      )

      newSpace.sensor = sensors
        .filter(sensor => Number(sensor.spaceId) === Number(newSpace.id))

      return newSpace
    })
    .map((space: ISpace) => [
      NewChart(space, EChart.AbsoluteHumidity),
      NewChart(space, EChart.DLI),
      NewChart(space, EChart.Humidity),
      NewChart(space, EChart.Temperature),
      NewChart(space, EChart.umol),
      NewChart(space, EChart.VPD),
      NewChart(space, EChart.CO2)
    ])
    .flat()
    .filter(chart => !!chart)

  setCharts(newCharts)
  setChartsEndDate(endDate)
  setChartsUUID(v4())
}

const UpdateAreacamIDs = ({
  endDate,

  query,

  setAreacamIDs,
  setAreacamsUUID
}: {
  endDate: Date;

  query: QueryResult;

  setAreacamIDs: (newVal: Array<any>) => void;
  setAreacamsUUID: (newVal: string) => void;
}): void => {
  if (
    query.loading ||
    query.error ||
    !query.data ||
    !isEqual(endDate, query.variables.endDate)
  ) {
    return
  }

  const newAreacamIDs = Array.from(
    query.data.areaImageRecord
      .reduce(
        (newAreacamIDs: Set<ID>, areaImageRecord: IAreaImageRecord) => newAreacamIDs.add(areaImageRecord.camDeviceId),
        new Set()
      )
  )
    .filter(id => Number(id) !== 1217) // API edge case

  setAreacamIDs(newAreacamIDs)
  setAreacamsUUID(v4())
}

const UpdateAreacams = ({
  endDate,

  spaces,

  charts,
  chartsEndDate,

  areaImageRecordsQuery,
  areacamsQuery,

  setAreacams,
  setAreacamsEndDate,
  setAreacamsUUID,

  selectedAreacam,
  setSelectedAreacam
}: {
  endDate: Date;

  spaces: [];

  charts: IChart[];
  chartsEndDate: Date;

  areaImageRecordsQuery: QueryResult<{areaImageRecord: Array<IAreaImageRecord>}>;
  areacamsQuery: QueryResult;

  setAreacams: (newVal: []) => void;
  setAreacamsEndDate: (newVal: Date) => void;
  setAreacamsUUID: (newVal: string) => void;

  selectedAreacam: any;
  setSelectedAreacam: (newVal: any) => void;
}): void => {
  if (
    areaImageRecordsQuery.loading ||
    areaImageRecordsQuery.error ||
    !areaImageRecordsQuery.data ||
    !isEqual(endDate, areaImageRecordsQuery.variables.endDate) ||

    areacamsQuery.loading ||
    areacamsQuery.error ||
    !areacamsQuery.data
  ) {
    return
  }

  let newAreacams = areacamsQuery.data.areacam
    .map((areacam: any) => {
      const newAreacam = Object.assign(
        {},
        areacam
      )

      newAreacam.areaImageRecord = areaImageRecordsQuery.data.areaImageRecord
        .filter((areaImageRecord) => Number(areaImageRecord.camDeviceId) === Number(newAreacam.id))

      return newAreacam
    })

  if (
    spaces &&
    charts &&
    isEqual(endDate, chartsEndDate)
  ) {
    newAreacams = newAreacams
      .map((areacam: IAreaImageRecord) => {
        areacam.space = spaces
          .filter((space: ISpace) => Number(space.id) === Number(areacam.spaceId))

        areacam.chart = charts
          .filter(chart => Number(chart.parentID) === Number(areacam.spaceId))
          .sort((chartA, chartB) => ChartOrder[chartA.chart] - ChartOrder[chartB.chart])

        return areacam
      })
  }

  if (selectedAreacam) {
    const newSelectedAreacam = newAreacams
      .find((areacam: IAreaImageRecord) => Number(areacam.id) === Number(selectedAreacam.id))

    setSelectedAreacam(newSelectedAreacam)
  }

  setAreacams(newAreacams)
  setAreacamsEndDate(endDate)
  setAreacamsUUID(v4())
}

export {
  UpdateDates,
  UpdateSpaces,
  UpdateSensorIDs,
  UpdateSensors,
  UpdateCharts,
  UpdateAreacamIDs,
  UpdateAreacams
}

/**
 * @author Miras Absar <mabsar@iunu.com>
 */

const Has = (val: any): boolean =>
  val !== undefined &&
  val !== null &&
  !Number.isNaN(val) &&
  val !== 'undefined' &&
  val !== 'null' &&
  val !== 'NaN'

export default Has

import React, {
  useEffect,
  useState
} from 'react'

export interface IDetailsViewProps {
  animated: boolean;
  showDetails: boolean;

  main: any;
  details: any;
}

const DetailsView = ({
  animated,
  showDetails,

  main,
  details
}: IDetailsViewProps): JSX.Element => {
  const [hidden, setHidden] = useState<boolean>(!showDetails);
  const [animatingIn, setAnimatingIn] = useState<boolean>();
  const [animatingOut, setAnimatingOut] = useState<boolean>();
  
  useEffect((): void => {
    if (animated) {
      if (showDetails) {
        setHidden(false)
        setAnimatingIn(true)

        setTimeout((): void => {
          setAnimatingIn(false)
        }, 150)
      } else {
        setAnimatingOut(true)
        setHidden(true);

        setTimeout((): void => {
          setAnimatingOut(false)
        }, 75)
      }
    } else {
      setHidden(!showDetails)
    }
  }, [showDetails]);

  let className = 'DetailsView';
  if (animated) className += ' Animated';

  let detailsClassName = 'Details';
  if (hidden) detailsClassName += ' Hidden';
  if (animatingIn) detailsClassName += ' AnimatingOut';
  if (animatingOut) detailsClassName += ' AnimatingOut';
  return (
    <div className={className}>
      <div className='Main'>
        {main}
      </div>

      <div className={detailsClassName}>
        {details}
      </div>
    </div>
  )
};

export default DetailsView;

import download from 'in-browser-download'

import m from 'moment'
import React, {
  useEffect,
  useState
} from 'react'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimes, faDownload } from '@fortawesome/pro-regular-svg-icons'
import AutoSizer from 'react-virtualized-auto-sizer'
import Chart from 'react-apexcharts'

import GoTo from 'react-lib/go-to'
import { Space } from '../../utils/routes'
import SensorTile from '../sensor-tile'

import { EStorage } from 'utils/storage'
import useStorage from 'react-lib/use-storage'

import { createDate } from 'utils/dates'
import { ChartAccuracy } from 'utils/chart'
import { ApexOptions } from 'apexcharts'

const papaparse = require('papaparse');
const { unparse } = papaparse;
interface ISensorCardProps {
  outlined?: boolean;
  resting?: boolean;
  raised?: boolean;
  animated?: boolean;

  timestamp: Date;
  x: number | string;
  y: number | string;
  space: any;
  sensorRecords: any[];
  co2SensorRecords: any[];
}

const SensorCard = ({
  outlined,
  resting,
  raised,
  animated,

  timestamp,
  x,
  y,
  space,
  sensorRecords,
  co2SensorRecords
}: ISensorCardProps): JSX.Element => {
  const [customer] = useStorage('customer', EStorage.EphemeralStorage)
  const temperatureMetric = customer.temperatureMetric
  const isF = temperatureMetric === 'fahrenheit'

  let className = 'Theme__Light Card SensorCard'
  if (outlined) className += ' Outlined'
  if (resting) className += ' Resting'
  if (raised) className += ' Raised'
  if (animated) className += ' Animated'
  interface ISeries { name: string, data: Array<[number, number]> }
  const [umolSeries, setUmolSeries] = useState<ISeries>()
  const [humiditySeries, setHumiditySeries] = useState<ISeries>()
  const [temperatureSeries, setTemperatureSeries] = useState<ISeries>()
  const [vpdSeries, setVpdSeries] = useState<ISeries>()
  const [dliSeries, setDliSeries] = useState<ISeries>()
  const [absHumiditySeries, setAbsHumiditySeries] = useState<ISeries>()
  const [co2Series, setCo2Series] = useState<ISeries>()

  const [showUmol, setShowUmol] = useState<boolean>(true)
  const [showHumidity, setShowHumidity] = useState<boolean>()
  const [showTemperature, setShowTemperature] = useState<boolean>()
  const [showVpd, setShowVpd] = useState<boolean>()
  const [showDli, setShowDli] = useState<boolean>()
  const [showAbsHumidity, setShowAbsHumidity] = useState<boolean>()
  const [showCo2, setShowCo2] = useState<boolean>()

  const [options, setOptions] = useState<ApexOptions>()
  const [series, setSeries] = useState<Array<ISeries>>()

  const sensorSignature =
    'timestamp umolSeries,showUmol,showHumidity,showTemperature,showVpd,showDli,showAbsHumidity,showCo2'
      .replace('timestamp', timestamp.toISOString())
      .replace('umolSeries', String(!!umolSeries))
      .replace('showUmol', String(!!showUmol))
      .replace('showHumidity', String(!!showHumidity))
      .replace('showTemperature', String(!!showTemperature))
      .replace('showVpd', String(!!showVpd))
      .replace('showDli', String(!!showDli))
      .replace('showAbsHumidity', String(!!showAbsHumidity))
      .replace('showCo2', String(!!showCo2))

  useEffect((): void => {
    if (sensorRecords) {
      const newUmolSeries: ISeries = { name: 'μmol', data: [] }
      const newHumiditySeries: ISeries = { name: 'Humidity', data: [] }
      const newTemperatureSeries: ISeries = { name: 'Temperature', data: [] }
      const newVpdSeries: ISeries = { name: 'VPD', data: [] }
      const newDliSeries: ISeries = { name: 'DLI', data: [] }
      const newAbsHumiditySeries: ISeries = { name: 'Abs. Humidity', data: [] }

      sensorRecords.forEach((sensorRecord): void => {
        const {
          createdOn,
          umol,
          humidity,
          temperature,
          vpd,
          dli,
          absoluteHumidity
        } = sensorRecord

        const _createdOn = createDate(createdOn).getTime()

        newUmolSeries.data.push([_createdOn, umol])
        newHumiditySeries.data.push([_createdOn, humidity])
        newTemperatureSeries.data.push([_createdOn, temperature])
        newVpdSeries.data.push([_createdOn, vpd])
        newDliSeries.data.push([_createdOn, dli])
        newAbsHumiditySeries.data.push([_createdOn, absoluteHumidity])
      })

      setUmolSeries(newUmolSeries)
      setHumiditySeries(newHumiditySeries)
      setTemperatureSeries(newTemperatureSeries)
      setVpdSeries(newVpdSeries)
      setDliSeries(newDliSeries)
      setAbsHumiditySeries(newAbsHumiditySeries)
    }
  }, [sensorRecords])

  useEffect((): void => {
    if (co2SensorRecords) {
      const newCo2Series: ISeries = { name: 'CO2', data: [] }

      co2SensorRecords.forEach((sensorRecord): void => {
        const {
          createdOn,
          co2
        } = sensorRecord

        const _createdOn = createDate(createdOn).getTime()

        newCo2Series.data.push([_createdOn, co2])
      })

      setCo2Series(newCo2Series)
    }
  }, [co2SensorRecords])

  useEffect((): void => {
    const newOptions: ApexOptions & {yaxis: Array<ApexYAxis> } = {
      chart: {
        height: '100%',
        toolbar: {
          autoSelected: 'zoom'
        },
        type: 'line',
        zoom: {
          type: 'x',
          enabled: true,
          autoScaleYaxis: true
        }
      },
      tooltip: {
        x: {
          formatter: (v): string => m(v).format('lll')
        }
      },
      xaxis: {
        type: 'datetime'
      },
      yaxis: []
    }

    const newSeries = []

    if (umolSeries && showUmol) {
      newOptions.yaxis.push({
        seriesName: 'μmol',
        tickAmount: 10,
        decimalsInFloat: ChartAccuracy.umol,
        title: {
          text: 'μmol'
        }
      })

      newSeries.push(umolSeries)
    }

    if (humiditySeries && showHumidity) {
      newOptions.yaxis.push({
        seriesName: 'Humidity',
        tickAmount: 10,
        decimalsInFloat: ChartAccuracy.humidity,
        title: {
          text: 'Humidity'
        }
      })

      newSeries.push(humiditySeries)
    }

    if (temperatureSeries && showTemperature) {
      newOptions.yaxis.push({
        seriesName: 'Temperature',
        tickAmount: 10,
        decimalsInFloat: ChartAccuracy.temperature,
        title: {
          text: 'Temperature'
        }
      })

      newSeries.push(temperatureSeries)
    }

    if (vpdSeries && showVpd) {
      newOptions.yaxis.push({
        seriesName: 'VPD',
        tickAmount: 10,
        decimalsInFloat: ChartAccuracy.vpd,
        title: {
          text: 'VPD'
        }
      })

      newSeries.push(vpdSeries)
    }

    if (dliSeries && showDli) {
      newOptions.yaxis.push({
        seriesName: 'DLI',
        tickAmount: 10,
        decimalsInFloat: ChartAccuracy.dli,
        title: {
          text: 'DLI'
        }
      })

      newSeries.push(dliSeries)
    }

    if (absHumiditySeries && showAbsHumidity) {
      newOptions.yaxis.push({
        seriesName: 'Abs. Humidity',
        tickAmount: 10,
        decimalsInFloat: ChartAccuracy.absoluteHumidity,
        title: {
          text: 'Abs. Humidity'
        }
      })

      newSeries.push(absHumiditySeries)
    }

    if (co2Series && showCo2) {
      newOptions.yaxis.push({
        seriesName: 'CO2',
        tickAmount: 10,
        decimalsInFloat: ChartAccuracy.co2,
        title: {
          text: 'CO2'
        }
      })

      newSeries.push(co2Series)
    }

    setOptions(newOptions)
    setSeries(newSeries)
  }, [sensorSignature])

  const $back = (): void => {
    const _timestamp = timestamp.toISOString()

    const to = Space
      .replace(':id', space.id)
      .replace(':timestamp?', _timestamp)
      .replace(':x?', String(x))
      .replace(':y?', String(y))

    GoTo(to)
  }

  const $download = (): void => {
    const columns = ['createdOn']
    const _sensorRecords = [...sensorRecords]

    if (showUmol) columns.push('umol')
    if (showHumidity) columns.push('humidity')
    if (showTemperature) columns.push('temperature')
    if (showVpd) columns.push('vpd')
    if (showDli) columns.push('dli')
    if (showAbsHumidity) columns.push('absoluteHumidity')
    if (showCo2) {
      columns.push('co2')
      _sensorRecords.push(...co2SensorRecords)
      _sensorRecords.sort((a, b) => {
        const aTime = createDate(a.createdOn).getTime()
        const bTime = createDate(b.createdOn).getTime()

        return aTime - bTime
      })
    }

    const csv = unparse(_sensorRecords, { columns })
    download(csv, `LUNA Sensor Data Export - ${m().toISOString()}.csv`)
  }

  const $toggleUmol = (): void => setShowUmol(!showUmol)
  const $toggleHumidity = (): void => setShowHumidity(!showHumidity)
  const $toggleTemperature = (): void => setShowTemperature(!showTemperature)
  const $toggleVpd = (): void => setShowVpd(!showVpd)
  const $toggleDli = (): void => setShowDli(!showDli)
  const $toggleAbsHumidity = (): void => setShowAbsHumidity(!showAbsHumidity)
  const $toggleCo2 = (): void => setShowCo2(!showCo2)

  if (options && series) {
    return (
      <article className={className}>
        <div className='Head'>
          <div className='Row'>
            <button onClick={$back}>
              <FontAwesomeIcon icon={faTimes} />
            </button>

            <h6 className='Primary'>Sensor Data</h6>
          </div>
        </div>

        <div className='Body Chart'>
          <AutoSizer>
            {({ width, height }: { width: number, height: number }): JSX.Element => (
              <Chart
                width={width}
                height={height}
                options={options}
                series={series}
              />
            )}
          </AutoSizer>
        </div>

        <div className='SensorTiles'>
          <div
            className='Download'
            onClick={$download}
          >
            <FontAwesomeIcon icon={faDownload} />
          </div>

          {umolSeries && (
            <SensorTile
              animated={animated}
              outlined
              raised={showUmol}

              label='μmol'
              value={umolSeries.data[0][1]}
              accuracy={ChartAccuracy.umol}
              unit='μmol'
              onClick={$toggleUmol}
            />
          )}

          {humiditySeries && (
            <SensorTile
              animated={animated}
              outlined
              raised={showHumidity}

              label='Humidity'
              value={humiditySeries.data[0][1]}
              accuracy={ChartAccuracy.humidity}
              unit='%'
              onClick={$toggleHumidity}
            />
          )}

          {temperatureSeries && (
            <SensorTile
              animated={animated}
              outlined
              raised={showTemperature}

              label='Temperature'
              value={temperatureSeries.data[0][1]}
              accuracy={ChartAccuracy.temperature}
              unit={isF ? '°F' : '°C'}
              onClick={$toggleTemperature}
            />
          )}

          {vpdSeries && (
            <SensorTile
              animated={animated}
              outlined
              raised={showVpd}

              label='VPD'
              value={vpdSeries.data[0][1]}
              accuracy={ChartAccuracy.vpd}
              unit='hPa'
              onClick={$toggleVpd}
            />
          )}

          {dliSeries && (
            <SensorTile
              animated={animated}
              outlined
              raised={showDli}

              label='DLI'
              value={dliSeries.data[0][1]}
              accuracy={ChartAccuracy.dli}
              unit='mol'
              onClick={$toggleDli}
            />
          )}

          {absHumiditySeries && (
            <SensorTile
              animated={animated}
              outlined
              raised={showAbsHumidity}

              label='Abs. Humidity'
              value={absHumiditySeries.data[0][1]}
              accuracy={ChartAccuracy.absoluteHumidity}
              unit='g/m³'
              onClick={$toggleAbsHumidity}
            />
          )}

          {co2Series && co2Series.data.length > 0 && (
            <SensorTile
              animated={animated}
              outlined
              raised={showCo2}

              label='CO2'
              value={co2Series.data[0][1]}
              accuracy={ChartAccuracy.co2}
              unit='ppm'
              onClick={$toggleCo2}
            />
          )}
        </div>
      </article>
    )
  }

  return null
}

export type { ISensorCardProps }
export default SensorCard

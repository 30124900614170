/**
 * @author Miras Absar <mabsar@iunu.com>
 * @file ...
 */

// Types
import IInputStatus from 'ui-lib/interfaces/IInputStatus'

// Libs
import { isEqual } from 'lodash'
import { v4 } from 'uuid'
import { BrowserMultiFormatReader } from '@zxing/library'

// React
import React, {
  useCallback,
  useRef,
  useState
} from 'react'

// React Libs
import { useIter } from 'react-lib/use-iter'

// Effects
import { SetupScanner } from './effects'

interface IScannerInput<T> {
  state: [
    T,
    IInputStatus,
    string,

    (newVal: T) => void,
    (reinitVal: T) => void,

    boolean,
    boolean,
    (newVal: boolean) => void,
    (newVal: boolean) => void
  ]
}

const ScannerInput = ({
  state: [
    value,
    status,
    uuid,

    setValue,
    resetValue,

    touched,
    pristine,
    setTouched,
    setPristine
  ]
}: IScannerInput<any>): JSX.Element => {
  // Refs //////////////////////////////////////////////////////////////////////

  const videoRef = useRef<HTMLVideoElement>()
  const scannerRef = useRef<BrowserMultiFormatReader>()

  const [refUUID, setRefUUID] = useState<string>()

  // Effects ///////////////////////////////////////////////////////////////////

  useIter(
    SetupScanner,

    { videoRef: videoRef.current,
      scannerRef,

      setValue },

    [refUUID]
  )

  // Callbacks /////////////////////////////////////////////////////////////////

  const $ref = useCallback(
    (newVideo): void => {
      if (
        !newVideo ||
        videoRef.current ||
        isEqual(newVideo, videoRef.current)
      ) {
        return
      }

      videoRef.current = newVideo
      setRefUUID(v4())
    },
    [refUUID]
  )

  // Render ////////////////////////////////////////////////////////////////////

  return (
    <div className='ScannerInput'>
      <video
        ref={$ref}
        width='320'
        height='320'
      />
    </div>
  )
}

export default ScannerInput

/**
 * @author Miras Absar <mabsar@iunu.com>
 */
import React, {
  KeyboardEvent
} from 'react'

import ICustomerUser from '../../../interfaces/ICustomerUser'
import IItemFacadeProps from 'ui-lib/interfaces/IItemFacadeProps'

export default ({
  /* Visual Properties */
  animated,

  /* Behavioral Properties */
  value,
  onSelect
}: IItemFacadeProps<ICustomerUser>): JSX.Element => {
  const className = animated
    ? 'CustomerUserItem Animated'
    : 'CustomerUserItem'

  const $keyPress = (ev: KeyboardEvent): void => {
    if (ev.key === 'Enter') {
      onSelect(value)
    }
  }

  const $click = (): void => onSelect(value)

  return (
    <div
      className={className}
      onKeyPress={$keyPress}
      onClick={$click}
      tabIndex={0}
    >
      <p className='Title'>{value.name}</p>
      <p className='Subtitle'>{value.email}</p>
    </div>
  )
}

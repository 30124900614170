/** Libs */
import queryString from 'query-string'
import { isNil } from 'utils/ts-utils';

/* React */
import React, {
  MouseEvent,
  useContext,
  useEffect,
  useState
} from 'react'

// React Libs
import useAsyncEffect from 'use-async-effect'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTh } from '@fortawesome/pro-solid-svg-icons'
import { faTimes } from '@fortawesome/pro-regular-svg-icons'
import { toast } from 'react-toastify'
import useDevice from 'react-lib/use-device'

/* GraphQL */
import { gql, useQuery } from '@apollo/client'
import client from 'graphql-lib/index'

/* Context */
import NotificationContext from 'context/notification'

// Utils
import GoTo from 'react-lib/go-to'
import { GetItem, EStorage } from 'utils/storage'

/* UI */
import { ITab } from 'ui-lib/components/tab'
import Tabs from 'ui-lib/components/tabs'
import Details from '../view-task-card-details'
import Comments from '../view-task-card-comments'
import { createDate } from 'utils/dates'
import ETab from 'map-view/enums/tab'
import ITask from 'graphql-lib/interfaces/ITask'
import ILocationProfile from 'graphql-lib/interfaces/ILocationProfile'
import mapClockIcon from 'public/assets/img/flag/icon/map-clock.svg';
import ID from 'graphql-lib/interfaces/ID';

const mapClockIconString = mapClockIcon as unknown as string;

const DetailsTab: ITab = {
  id: 0,
  label: 'Details'
}

const CommentsTab: ITab = {
  id: 1,
  label: 'Conversation'
}

const _Tabs = [
  DetailsTab,
  CommentsTab
]

const QueryTask = gql`
  query($id: ID, $offset: Int, $limit: Int) {
    task(id: $id, offset: $offset, limit: $limit) {
      id
      createdOn
      updatedOn
      complete
      isGridview
      name

      flag {
        title
      }

      locationProfile {
        spaceId
        inventoryId

        positionX
        positionY
      }

      completion{
        id
      }
      calendarEvent{
        id
        nextDueTime
      }
    }
  }
`

const QueryNotification = gql`
  query {
    notification {
      id
      createdOn

      seen
      acknowledged

      name
      body

      objectType
      objectId

      meta
    }
  }
`

const UpdateNotification = gql`
  mutation(
    $id: ID!,
    $seen: Boolean,
    $acknowledged: Boolean
  ) {
    Notification {
      update(input: {
        id: $id,
        seen: $seen,
        acknowledged: $acknowledged
      }) {
        id
      }
    }
  }
`

export interface IViewTaskCardProps {
  outlined?: boolean;
  resting?: boolean;
  raised?: boolean;
  animated?: boolean;
  id: ID;
  onBack: () => void;
  updateFlagsViewTask?: (updatedTask: ITask) => void;
}

const ViewTaskCard = ({
  outlined,
  resting,
  raised,
  animated,

  id,
  onBack,
  updateFlagsViewTask
}: IViewTaskCardProps): JSX.Element => {
  const { mobile } = useDevice()
  const {
    notifications,
    setNotifications
  } = useContext(NotificationContext)

  const [task, setTask] = useState<ITask>()
  const [locationProfile, setLocationProfile] = useState<ILocationProfile>()
  const [selectedTab, setSelectedTab] = useState(DetailsTab)

  const {
    error,
    data
  } = useQuery(QueryTask, { variables: { id } })

  useEffect((): void => {
    if (error) {
      toast.error("Couldn't load task; please try again in a few seconds.")
    }
  }, [error])

  useEffect((): void => {
    if (data) {
      const newTask = data.task[0]
      const newLocationProfile = newTask.locationProfile[0]

      setTask(newTask)
      setLocationProfile(newLocationProfile)
    }
  }, [data])

  useAsyncEffect(async (): Promise<void> => {
    if (notifications && task) {
      const taskNotifications = notifications.filter((n: any): boolean =>
        n.objectType === 'task' &&
        n.objectId === task.id &&
        !n.acknowledged)

      if (taskNotifications.length > 0) {
        const taskPromises = taskNotifications.map(
          async (n: any): Promise<void> => {
            await client.mutate({
              mutation: UpdateNotification,
              variables: {
                id: n.id,
                seen: true,
                acknowledged: true
              }
            })
          })

        await Promise.all(taskPromises)

        const {
          data: newNotificationsData
        } = await client.query({
          query: QueryNotification,
          fetchPolicy: 'no-cache'
        })

        const newNotifications = newNotificationsData.notification
        setNotifications(newNotifications)
      }
    }
  }, [notifications, task])

  let className = 'Theme__Light Card ViewTaskCard'
  if (outlined) className += ' Outlined'
  if (resting) className += ' Resting'
  if (raised) className += ' Raised'
  if (animated) className += ' Animated'

  const isImageGridTask = task?.appliedToView === 'grid'
  const isMapViewTask = task?.appliedToView === 'map'

  const $imageGrid = (ev: MouseEvent<HTMLButtonElement>): void => {
    if (!isNil(locationProfile?.spaceId)) {
      const to = `/grid/space/${locationProfile.spaceId}/${new Date().toISOString()}/${locationProfile.positionX}/${locationProfile.positionY}`
      GoTo(to)
    }
  }

  const $mapView = (ev: MouseEvent<HTMLButtonElement>): void => {
    const facilities = GetItem('facilities', EStorage.EphemeralStorage)
    const facilityId = facilities[0].id
    const time = createDate(task.createdOn).toISOString()
    const taskId = task.id
    const navigationTab = ETab.Flags
    const queryObject = {
      navigationTab: navigationTab,
      timeTravelOpen: true,
      overlays: JSON.stringify(['Overlay.Task']),
      selectedEntities: JSON.stringify([{ type: 'Entity.Task', id: taskId }]),
      time,
      navigationOpen: false
    }

    // Workaround for a bug in MapView where even if navigationOpen is false
    // it's presence will still open the navigation 🙃
    if (!mobile) {
      queryObject.navigationOpen = true
    }

    const to = `/map/${facilityId}?${queryString.stringify(queryObject)}`
    GoTo(to)
  }

  if (!task) return null
  return (
    <article className={className}>
      <div className='Head'>
        <div className='Row'>
          <button type='button' onClick={onBack}>
            <FontAwesomeIcon icon={faTimes} />
          </button>

          {window.taskDistances?.[Number(task.id)] && (
            <div className='DistanceContainer'>
              <p>{window.taskDistances[Number(task.id)].toFixed(2)}m from Top</p>
            </div>
          )}

          {task
            ? <h6 className='Primary'>{task.name}</h6>
            : <h6 className='Loading' />}

          <div className='Space' />

          {isImageGridTask && (
            <button onClick={$imageGrid}>
              <FontAwesomeIcon icon={faTh} />
            </button>
          )}

          {isMapViewTask && (
            // TODO: Replace tooltip with CMP's tooltip component once apps are merged
            <div className="tooltip-container" data-text="Show image at flag creation.">
              <button onClick={$mapView}>
                <img src={mapClockIconString} alt="Map Icon" />
              </button>
            </div>
          )}
        </div>
      </div>

      <div className='Body'>
        <Tabs
          animated={animated}

          tabs={_Tabs}
          selectedTab={selectedTab}
          onSelect={setSelectedTab}
        />
      </div>

      {selectedTab.id === DetailsTab.id && (
        <Details
          animated={animated}
          id={id}
          back={onBack}
          updateFlagsViewTask={updateFlagsViewTask}
        />
      )}

      {selectedTab.id === CommentsTab.id && (
        <Comments
          animated={animated}
          id={id}
        />
      )}
    </article>
  )
}

export default ViewTaskCard

/**
 * @author Miras Absar <mabsar@iunu.com>
 */

// Types
import EEntity from 'map-view/enums/entity'
import IChart from 'map-view/interfaces/chart'

// React
import React, {
  useCallback,
  useContext,
  useEffect,
  useState
} from 'react'

// React Libs
import { useIter } from 'react-lib/use-iter'
import AutoSizer from 'react-virtualized-auto-sizer'

// Font Awesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimes } from '@fortawesome/pro-regular-svg-icons'

// Contexts
import Mobius from 'map-view/contexts/mobius'

// Effects
import { QueryHistoricalChartData, UpdateCharts, UpdateChartsFromHistoricalData } from './effects'

// UI Lib
import ChartCanvas from '../chart-canvas'
import { useLazyQuery } from '@apollo/client'
import {
  QueryCO2SensorRecords,
  QuerySensorRecords,
  QueryGreenAutomationWaterSystemRecords
} from '../mobius/queries'
import IEntity from 'map-view/interfaces/entity'
import { ISensorRecordByType } from 'graphql-lib/interfaces/ISensorDevice'
import IGreenAutomationWaterSystemRecord from 'graphql-lib/interfaces/IGreenAutomationWaterSystemRecord'

interface IChartCanvasCompatProps {
  animated?: boolean;
}

const ChartCanvasCompat = ({
  animated
}: IChartCanvasCompatProps): JSX.Element | null => {
  // GraphQL
  const [getSensorRecords, sensorRecordData] = useLazyQuery<ISensorRecordByType, Record<string, any>>(QuerySensorRecords)
  const [getCO2SensorRecords, CO2sensorRecordData] = useLazyQuery<ISensorRecordByType, Record<string, any>>(QueryCO2SensorRecords)
  const [getWaterSystemRecords, waterSystemRecordData] = useLazyQuery<{ greenAutomationWaterSystemRecord: Array<IGreenAutomationWaterSystemRecord> }>(QueryGreenAutomationWaterSystemRecords)

  // Contexts //////////////////////////////////////////////////////////////////

  const {
    searchParams: {
      searchParams,
      searchParamsUUID
    },

    selectedEntities: {
      selectedEntities,
      setSelectedEntities,
      selectedEntitiesUUID
    },

    spaceCharts: {
      spaceCharts,
      setSpaceCharts,
      spaceChartsUUID
    },

    inventoryCharts: {
      inventoryCharts,
      inventoryChartsUUID,
    },

    containerCharts: {
      containerCharts,
      containerChartsUUID
    },

    time: {
      time
    },

    spaces: {
      spaces
    }
  } = useContext(Mobius)

  // State /////////////////////////////////////////////////////////////////////

  const [charts, setCharts] = useState<IChart[]>()
  const [chartsUUID, setChartsUUID] = useState<string>()
  const [needsHistoricalData, setNeedsHistoricalData] = useState<boolean>(true)

  // Effects ///////////////////////////////////////////////////////////////////

  useIter(
    UpdateCharts,

    { selectedEntities,
      spaceCharts,
      inventoryCharts,
      containerCharts,
      needsHistoricalData,

      setCharts,
      setChartsUUID },

    [ selectedEntitiesUUID,
      spaceChartsUUID,
      inventoryChartsUUID,
      containerChartsUUID,
      needsHistoricalData ]
  )

  // Make queries for historical data of space charts that are
  // selected on page load directly through the URL
  useEffect(() => {
    if (!selectedEntities?.length || !needsHistoricalData || !time) return

    QueryHistoricalChartData({
      selectedEntities,
      time,

      getSensorRecords,
      getCO2SensorRecords,
      getWaterSystemRecords,
      setNeedsHistoricalData
    })
  }, [selectedEntities, time])

  // Update charts with the returned historical data gotten from queries
  // for space charts that are selected on page load directly through the URL
  useEffect(() => {
    if (
      (!sensorRecordData.data && !CO2sensorRecordData.data && !waterSystemRecordData.data) ||
      !spaceCharts ||
      !needsHistoricalData
    ) return

    UpdateChartsFromHistoricalData({
      spaceCharts,
      spaces,
      sensorRecordData,
      CO2sensorRecordData,
      waterSystemRecordData,

      setSpaceCharts,
      setNeedsHistoricalData
    })
  }, [
    sensorRecordData.data,
    CO2sensorRecordData.data,
    waterSystemRecordData.data,
    spaceCharts
  ])

  // Callbacks /////////////////////////////////////////////////////////////////

  const $close = useCallback(
    (): void => {
      const newSelectedEntities = selectedEntities
        .filter((entity: IEntity) => entity.type !== EEntity.Chart)

      setSelectedEntities(newSelectedEntities)
    },

    [setSelectedEntities, selectedEntitiesUUID]
  )

  // Render ////////////////////////////////////////////////////////////////////

  if (
    !charts ||
    charts.length === 0
  ) {
    return null
  }

  let className = 'ChartCanvasCompat'
  if (animated) className += ' Animated'
  if (searchParams.navigationOpen) className += ' Narrow'
  else className += ' Wide'

  return (
    <div className={className}>
      <button onClick={$close}>
        <FontAwesomeIcon icon={faTimes} />
      </button>

      <ChartCanvas
        resize={searchParamsUUID}
        charts={charts}
        chartsUUID={chartsUUID}
      />
    </div>
  )
}

export default ChartCanvasCompat

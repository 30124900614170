/// Types
import ICrop from 'graphql-lib/interfaces/ICrop';
import IInventory from 'graphql-lib/interfaces/IInventory';
import ITask from 'graphql-lib/interfaces/ITask';
import ID from 'graphql-lib/interfaces/ID';

// React
import React, {
  useState
} from 'react'

// React Libs
import { useIter } from 'react-lib/use-iter'

// GraphQL
import { useQuery } from '@apollo/client'
import {
  QueryCrop,
  QueryInventories,
  QueryCropTasks,
  QueryInventoryTasks
} from './queries'

// Effects
import {
  UpdateCrop,
  UpdateInventories,
  UpdateTasks,
  ConcatenateTasks
} from './effects'

interface ITaskListProps {
  show?: boolean;
  animated?: boolean;
  id: ID;
}

const TaskList = ({
  show,
  animated,
  id
}: ITaskListProps): JSX.Element | null => {
  // State /////////////////////////////////////////////////////////////////////

  const [crop, setCrop] = useState<ICrop>()
  const [inventories, setInventories] = useState<IInventory[]>()
  const [cropTasks, setCropTasks] = useState<ITask[]>()
  const [inventoryTasks, setInventoryTasks] = useState<ITask[]>()
  const [tasks, setTasks] = useState<ITask[]>()

  const [inventoryIDs, setInventoryIDs] = useState<ID[]>()

  const [cropUUID, setCropUUID] = useState<string>()
  const [inventoriesUUID, setInventoriesUUID] = useState<string>()
  const [cropTasksUUID, setCropTasksUUID] = useState<string>()
  const [inventoryTasksUUID, setInventoryTasksUUID] = useState<string>()
  const [tasksUUID, setTasksUUID] = useState<string>()

  // Queries ///////////////////////////////////////////////////////////////////

  const hasInventoryIDs = inventoryIDs && inventoryIDs.length > 0

  const {
    loading: cropLoading,
    error: cropError,
    data: cropData
  } = useQuery(QueryCrop, {
    skip: !id,
    variables: { id },
    fetchPolicy: 'no-cache'
  })

  const {
    loading: inventoriesLoading,
    error: inventoriesError,
    data: inventoriesData
  } = useQuery(QueryInventories, {
    skip: !id,
    variables: { cropIds: [id] },
    fetchPolicy: 'no-cache'
  })

  const {
    loading: cropTasksLoading,
    error: cropTasksError,
    data: cropTasksData
  } = useQuery(QueryCropTasks, {
    skip: !id,
    variables: { cropIds: [id] },
    fetchPolicy: 'no-cache'
  })

  const {
    loading: inventoryTasksLoading,
    error: inventoryTasksError,
    data: inventoryTasksData
  } = useQuery(QueryInventoryTasks, {
    skip: !crop || !hasInventoryIDs,
    variables: { inventoryIds: inventoryIDs },
    fetchPolicy: 'no-cache'
  })

  // Effects ///////////////////////////////////////////////////////////////////

  useIter(
    UpdateCrop,

    { cropLoading,
      cropData,

      setCrop,
      setCropUUID },

    [cropData]
  )

  useIter(
    UpdateInventories,

    { inventoriesLoading,
      inventoriesData,

      setInventories,
      setInventoryIDs,
      setInventoriesUUID },

    [inventoriesData]
  )

  useIter(
    UpdateTasks,

    { tasksLoading: cropTasksLoading,
      tasksData: cropTasksData,

      setTasks: setCropTasks,
      setTasksUUID: setCropTasksUUID },

    [cropTasksData]
  )

  useIter(
    UpdateTasks,

    { tasksLoading: inventoryTasksLoading,
      tasksData: inventoryTasksData,

      setTasks: setInventoryTasks,
      setTasksUUID: setInventoryTasksUUID },

    [inventoryTasksData]
  )

  useIter(
    ConcatenateTasks,

    { tasksA: cropTasks,
      tasksB: inventoryTasks,

      setTasks,
      setTasksUUID },

    [cropTasksUUID, inventoryTasksUUID]
  )

  // Render ////////////////////////////////////////////////////////////////////

  if (!show) {
    return null
  }

  if (!tasks) {
    return null
  }

  return (
    <section className='TaskList'>
    </section>
  )
}

export default TaskList

// Libs
import { format } from 'date-fns'
import { isNil } from 'utils/ts-utils';
import { ViewTask } from 'tasks/utils/routes';
import GoTo from 'react-lib/go-to';

// React
import React, {
  useEffect,
  useState,
  CSSProperties
} from 'react';

// FontAwesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faExclamationCircle
} from '@fortawesome/pro-solid-svg-icons';

// UI Lib
import Flag from 'ui-lib/components/flag';
import { createDate } from 'utils/dates';
import Button from 'ui-lib/components/button'
import useSearchParams from 'react-lib/use-search-params';

// Types
import { ITab } from 'ui-lib/components/tab';
import ITaskIssue from 'graphql-lib/interfaces/ITaskIssue';
import ISetStateType from 'graphql-lib/interfaces/ISetStateType';
import EEntity from 'graphql-lib/enums/entity';
import IFlag from 'graphql-lib/interfaces/IFlag';
import ID from 'graphql-lib/interfaces/ID';

export interface ITaskIssueProps {
  outlined?: boolean
  resting?: boolean
  raised?: boolean
  animated?: boolean
  tabs: Array<ITab>
  data: ITaskIssue
  selectedId: ID
  style?: CSSProperties
  onClick: (taskIssue: ITaskIssue) => void
  setTaskOrIssue: ISetStateType<ITab>
}

const TaskIssueCard = ({
  outlined,
  resting,
  raised,
  animated,
  data: taskIssue,
  selectedId,
  style,
  tabs,
  onClick,
  setTaskOrIssue
}: ITaskIssueProps): JSX.Element => {
  // State /////////////////////////////////////////////////////////////////////

  const [flag, setFlag] = useState<IFlag>()
  const [urgent, setUrgent] = useState<boolean>()
  const [notification, setNotification] = useState<boolean>()

  const [
    searchParams,
    setSearchParams
  ] = useSearchParams();

  // Callbacks /////////////////////////////////////////////////////////////////

  const $click = (): void => {
    onClick(taskIssue)
  }

  const $goToTask = (taskId: ID): void => {
    if (setTaskOrIssue && tabs) {
      const to = ViewTask.replace(':id', String(taskId))
      window.location.href = to
    } else if (setSearchParams && searchParams) {
      const newSearchParams = { ...searchParams }
      const buildSelectedEntities = [
        {
          type: EEntity.Task,
          id: taskId
        }
      ]
      newSearchParams.selectedEntities = JSON.stringify(buildSelectedEntities);
      newSearchParams.navigationTab = 'Tab.Flags';
      setSearchParams(newSearchParams);
    }
  }

  // Effects ///////////////////////////////////////////////////////////////////

  useEffect((): void => {
    if (taskIssue) {
      const newFlag = taskIssue.customerTaskCategory[0]
      setFlag(newFlag)
    }
  }, [taskIssue])

  useEffect((): void => {
    if (taskIssue) {
      const newUrgent = taskIssue.priority > 0

      setUrgent(newUrgent)
    }
  }, [taskIssue])

  // Render ////////////////////////////////////////////////////////////////////

  let className = 'flex items-center Theme__Light TaskCard'
  if (outlined) className += ' Outlined'
  if (resting) className += ' Resting'
  if (raised) className += ' Raised'
  if (animated) className += ' Animated'
  if (Number(taskIssue.id) === Number(selectedId)) className += ' Selected'

  return (
    <article
      style={style}
      className={className}
      onClick={$click}
    >
      <div className='FlagContainer'>
        {flag && (
          <Flag flag={flag} onClick={() => null} status={taskIssue.rejectedAt ? 'rejected' : 'current'} />
        )}

        {!flag && (
          <div className='FlagPlaceholder' />
        )}
      </div>

      <div className='Body'>
        <div className='Head'>
          <h6 className='Primary'>{taskIssue.description}{!isNil(taskIssue.rejectedAt) && <small className='ml-s badge badge-warning'>Rejected</small>}</h6>
        </div>
        <div className='Subhead'>
          <p>{format(createDate(taskIssue.createdAt), 'P p')}</p>
          { !isNil(taskIssue.taskId) && <Button
            animated={animated}
            outlined
            onClick={() => $goToTask(taskIssue.taskId)}
          >
            Go to Related Task
          </Button> }
        </div>
      </div>

      <div className='Icons'>
        {notification && (
          <FontAwesomeIcon
            className='Blue'
            icon={faExclamationCircle}
          />
        )}

        {urgent && (
          <FontAwesomeIcon
            className='Red'
            icon={faExclamationCircle}
          />
        )}
      </div>
    </article>
  )
}

export default TaskIssueCard

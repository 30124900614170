/**
 * @author Miras Absar <mabsar@iunu.com>
 * @file ...
 */

// Types
import EInputStatus from 'ui-lib/enums/input-status'
import IInputStatus from 'ui-lib/interfaces/IInputStatus'

const NoOp = (val: any): IInputStatus => ({
  status: EInputStatus.None,
  message: ''
})

export { NoOp }

// Libs
import { v4 } from 'uuid'

// React
import React, {
  useCallback,
  useContext,
  useRef,
  useState
} from 'react'

// React Lib
import { useIter } from 'react-lib/use-iter'

// Effects
import {
  UpdateVisibleInventories,
  UpdateSearchedInventories,
  UpdateContainers,
  UpdateVisibleContainers,
  UpdateSearchedContainers,
  UpdateScroll
} from './effects'

// UI Lib
import { useInput } from 'ui-lib/hooks/use-input'
import { NoOp } from 'ui-lib/validators/no-op'

// MapView
import Mobius from 'map-view/contexts/mobius'
import Search from '../search'
import InventoryItem from './inventory-item'
import ContainerItem from './container-item'
import IInventory from 'graphql-lib/interfaces/IInventory'
import IContainer from 'graphql-lib/interfaces/IContainer'

interface IPlantsListProps {
  animated?: boolean;
  invisible?: boolean;
}

const PlantsList = ({
  animated,
  invisible
}: IPlantsListProps): JSX.Element => {
  // Context ///////////////////////////////////////////////////////////////////
  const mobiusContext = useContext(Mobius)
  const {
    selectedEntities: {
      selectedEntitiesDiff,
      selectedEntitiesUUID
    },

    visibleEntities: {
      visibleEntities,
      visibleEntitiesUUID
    },

    inventories: {
      inventoriesWOC,
      inventoriesWC,
      inventoriesUUID,
      inventoriesData: _inventoriesData
    },

    containers: {
      containers: _containers,
      containersUUID: _containersUUID
    }
  } = mobiusContext;

  // State /////////////////////////////////////////////////////////////////////

  const listRef = useRef<HTMLDivElement>()

  const searchState = useInput<string>('', NoOp)
  const visibleState = useInput<boolean>(true, NoOp)

  const [search, , searchUUID] = searchState
  const [visible, , visibleUUID] = visibleState

  const [visibleInventories, setVisibleInventories] = useState<Array<IInventory>>()
  const [searchedInventories, setSearchedInventories] = useState<Array<IInventory>>()

  const [containers, setContainers] = useState<Array<IContainer>>()
  const [visibleContainers, setVisibleContainers] = useState<Array<IContainer>>()
  const [searchedContainers, setSearchedContainers] = useState<Array<IContainer>>()

  const [listRefUUID, setListRefUUID] = useState<string>()

  const [visibleInventoriesUUID, setVisibleInventoriesUUID] = useState<string>()
  const [searchedInventoriesUUID, setSearchedInventoriesUUID] = useState<string>()

  const [containersUUID, setContainersUUID] = useState<string>()
  const [visibleContainersUUID, setVisibleContainersUUID] = useState<string>()
  const [searchedContainersUUID, setSearchedContainersUUID] = useState<string>()

  // Effects ///////////////////////////////////////////////////////////////////

  useIter(
    UpdateVisibleInventories,

    { visibleEntities,
      inventories: inventoriesWOC,

      setVisibleInventories,
      setVisibleInventoriesUUID },

    [ visibleEntitiesUUID,
      inventoriesUUID ]
  )

  useIter(
    UpdateSearchedInventories,

    { inventories: inventoriesWOC,
      visibleInventories,
      search,
      visible,

      setSearchedInventories,
      setSearchedInventoriesUUID },

    [ inventoriesUUID,
      visibleInventoriesUUID,
      searchUUID,
      visibleUUID ]
  )

  useIter(
    UpdateContainers,

    { inventories: inventoriesWC,
      containers: _containers,

      setContainers,
      setContainersUUID },

    [ inventoriesUUID,
      _containersUUID ]
  )

  useIter(
    UpdateVisibleContainers,

    { visibleEntities,
      containers,

      setVisibleContainers,
      setVisibleContainersUUID },

    [ visibleEntitiesUUID,
      containersUUID ]
  )

  useIter(
    UpdateSearchedContainers,

    { containers,
      visibleContainers,
      search,
      visible,

      setSearchedContainers,
      setSearchedContainersUUID },

    [ containersUUID,
      visibleContainersUUID,
      searchUUID,
      visibleUUID ]
  )

  useIter(
    UpdateScroll,

    { selectedEntitiesDiff,
      listRef: listRef.current },

    [ selectedEntitiesUUID,
      listRefUUID ]
  )

  // Callbacks /////////////////////////////////////////////////////////////////

  const list$ref = useCallback(
    (newVal: HTMLDivElement): void => {
      if (
        newVal &&
        !listRef.current
      ) {
        listRef.current = newVal
        setListRefUUID(v4())
      }
    },
    [listRefUUID]
  )

  // Render ////////////////////////////////////////////////////////////////////

  let className = 'PlantsList'
  if (animated) className += ' Animated'
  if (invisible) className += ' Invisible'

  return (
    <div className={className}>
      <Search
        animated={animated}
        isIssuesTab={false}
        searchState={searchState}
        visibleState={visibleState}
      />

      <div
        ref={list$ref}
        className='List'
      >
        { searchedInventories?.map(inventory => (
          <InventoryItem
            key={inventory.id}
            animated={animated}
            inventory={inventory}
          />
        )) }

        { searchedContainers?.map(container => (
          <ContainerItem
            key={container.id}
            animated={animated}
            container={container}
            refetch={async () => {
              await _inventoriesData.refetch()
            }}
          />
        )) }
      </div>
    </div>
  )
}

export default PlantsList

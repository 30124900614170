/**
 * @author Miras Absar <mabsar@iunu.com>
 */
import React from 'react'

interface ISensorTileProps {
  outlined?: boolean;
  resting?: boolean;
  raised?: boolean;
  animated?: boolean;

  label: string;
  value: number | string;
  accuracy?: number;
  unit: string;
  onClick: () => void;
}

const SensorTile = ({
  outlined,
  resting,
  raised,
  animated,

  label,
  value,
  accuracy = 1,
  unit,
  onClick
}: ISensorTileProps): JSX.Element => {
  let className = 'SensorTile'
  if (outlined) className += ' Outlined'
  if (resting) className += ' Resting'
  if (raised) className += ' Raised'
  if (animated) className += ' Animated'

  let _value = value
  if (typeof _value === 'string') _value = Number.parseInt(_value, 10)
  if (typeof _value === 'number') _value = _value.toFixed(accuracy)

  return (
    <div id={label} className={className} onClick={onClick}>
      <p className='Label'>{label}</p>
      <p className='Value'>{_value} {unit}</p>
    </div>
  )
}

export type { ISensorTileProps }
export default SensorTile

/**
 * @author Miras Absar <mabsar@iunu.com>
 */

// Types
import EEntity from 'map-view/enums/entity'
import EDiff from 'map-view/enums/diff'
import EOverlay from 'map-view/enums/overlay'

// Utils
import { v4 } from 'uuid'
import IEntity from 'map-view/interfaces/entity'
import ID from 'graphql-lib/interfaces/ID'
import IDiff from 'map-view/interfaces/diff'
import ITaskIssue from 'graphql-lib/interfaces/ITaskIssue'

export const UpdateOverlays = ({
  invisible,
  overlays,
  selectOverlay
}: {
  invisible: boolean
  overlays: Array<EOverlay>
  selectOverlay: (value: EOverlay) => void
}): void => {
  if (
    !overlays ||
    !selectOverlay
  ) {
    return
  }

  if (
    !invisible &&
    !overlays.includes(EOverlay.TaskIssue)
  ) {
    selectOverlay(EOverlay.TaskIssue)
  }
}

export const UpdateTaskIssues = ({
  taskIssues,
  setTaskIssues,
  setTaskIssuesUUID
}: {
  taskIssues: Array<ITaskIssue>
  setTaskIssues: (value: Array<ITaskIssue>) => void
  setTaskIssuesUUID: (value: string) => void
}): void => {
  if (!taskIssues) {
    return
  }

  setTaskIssues(taskIssues)
  setTaskIssuesUUID(v4())
}

export const UpdateVisibleTaskIssues = ({
  visibleEntities,
  taskIssues,

  setVisibleTaskIssues,
  setVisibleTaskIssuesUUID
}:
{
  visibleEntities: Array<IEntity>
  taskIssues: Array<ITaskIssue>

  setVisibleTaskIssues: (value: Array<ITaskIssue>) => void
  setVisibleTaskIssuesUUID: (value: string) => void
}
): void => {
  if (
    !visibleEntities ||
    !taskIssues
  ) {
    return
  }

  const newVisibleTaskIssues = taskIssues
    .filter(taskIssue => visibleEntities.some((entity) => entity.type === EEntity.TaskIssue && String(entity.id) === String(taskIssue.id)));

  setVisibleTaskIssues(newVisibleTaskIssues)
  setVisibleTaskIssuesUUID(v4())
}

export const UpdateSearchedTaskIssues = ({
  taskIssues,
  visibleTaskIssues,
  search,
  visible,

  setSearchedTaskIssues,
  setSearchedTaskIssuesUUID
}: {
  taskIssues: Array<ITaskIssue>
  visibleTaskIssues: Array<ITaskIssue>
  search: string
  visible: boolean
  setSearchedTaskIssues: (value: Array<ITaskIssue>) => void
  setSearchedTaskIssuesUUID:(value: string) => void
}): void => {
  if (
    !taskIssues ||
    !visibleTaskIssues
  ) {
    return
  }

  let newSearchedTaskIssues = visible
    ? visibleTaskIssues
    : taskIssues

  if (search.trim()) {
    const searchLC = search.toLocaleLowerCase()
    newSearchedTaskIssues = newSearchedTaskIssues
      .filter((taskIssue: any) =>
        taskIssue.name?.toLocaleLowerCase().includes(searchLC) ||
        taskIssue.customerTaskCategory[0].title?.toLocaleLowerCase().includes(searchLC) ||
        taskIssue.customerTaskCategory[0].description?.toLocaleLowerCase().includes(searchLC) ||
        taskIssue.customerTaskCategory[0].flagCategory[0].name?.toLocaleLowerCase().includes(searchLC))
  }

  // Sort tasks by most urgent
  newSearchedTaskIssues.sort((firstTaskIssue, secondTaskIssue) => secondTaskIssue.priority - firstTaskIssue.priority)

  setSearchedTaskIssues(newSearchedTaskIssues)
  setSearchedTaskIssuesUUID(v4())
}
export interface IListRef{
  children: Array<{
    dataset: IEntity,
    scrollIntoView: (params: {
      behavior: string,
      block: string,
      inline: string
    }) => void
  }>
}
export const UpdateScroll = ({
  selectedEntitiesDiff,
  listRef
}: {
  selectedEntitiesDiff: Array<IDiff<IEntity>>
  listRef: IListRef
}): void => {
  if (
    !selectedEntitiesDiff ||
    !listRef
  ) {
    return
  }

  const diff = selectedEntitiesDiff
    .find(diff =>
      diff.type === EDiff.Add &&
      diff.value.type === EEntity.Task)

  if (!diff) {
    return
  }

  const child = Array.from(listRef.children)
    .find(child =>
      child.dataset.type === diff.value.type &&
      child.dataset.id === diff.value.id)

  if (!child) {
    return
  }

  child.scrollIntoView({
    behavior: 'smooth',
    block: 'start',
    inline: 'nearest'
  })
}

export const UpdateCreatingViewingTaskIssue = ({
  searchParams,
  selectedEntities,
  setCreatingTaskIssue,
  setViewingTaskIssue
}: {
  searchParams:{
    creatingTaskIssue: boolean
  }
  selectedEntities: Array<IEntity>
  setCreatingTaskIssue: (val: boolean) => void
  setViewingTaskIssue: (val: ID) => void
}): void => {
  if (
    !searchParams ||
    !selectedEntities
  ) {
    return;
  }

  const newViewingTaskIssue = selectedEntities
    .find(entity => entity.type === EEntity.TaskIssue)
    ?.id;

  setViewingTaskIssue(newViewingTaskIssue);

  // Allow creating task-issue card to open if user long-clicked
  const newCreatingTaskIssue = searchParams.creatingTaskIssue
  setCreatingTaskIssue(newCreatingTaskIssue)
}

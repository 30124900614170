// Types
import EVendor from '../../../enums/Vendor'
import ITime from 'grid-view/interfaces/time'
import IFacility from 'graphql-lib/interfaces/IFacility'
import IBuilding from 'graphql-lib/interfaces/IBuilding'
import ISpace from 'graphql-lib/interfaces/ISpace'
import { ISensorRecord, ICO2SensorRecord, ISensorRecordByType } from 'graphql-lib/interfaces/ISensorDevice'
import IGreenAutomationWaterSystemRecord from 'graphql-lib/interfaces/IGreenAutomationWaterSystemRecord'
import IAreacam from 'graphql-lib/interfaces/IAreacam'
import IAreaImageRecord from 'graphql-lib/interfaces/IAreaImageRecord'

// Libs
import { subDays } from 'date-fns'

// React
import React, {
  useState
} from 'react'
import { matchPath } from 'react-router-dom'

// React Libs
import { useIter } from 'react-lib/use-iter'

// GraphQL
import { useQuery } from '@apollo/client'
import {
  QueryStaticData,
  QueryEnvironmentalZones,
  QuerySensorRecords,
  QueryCO2SensorRecords,
  QueryGreenAutomationWaterSystemRecords,
  QuerySensors,
  QueryAreaImageRecords,
  QueryAreacams
} from './queries'

// Context
import MetronContext, {
  IMetronContext
} from 'grid-view/contexts/metron'

// Effects
import {
  Search2URLParams,
  URLParams2Search,
  URLParams2Time,
  UpdateStaticData,
  UpdateEnvironmentalZones,
  UpdateSensorRecords,
  UpdateAllSensorSupdata,
  UpdateSensors,
  UpdateGreenAutomationWaterSystemRecords,
  UpdateAreaImageRecords,
  UpdateAreacams
} from './effects'
import ISensorDevice from 'graphql-lib/interfaces/ISensorDevice'

import ID from 'graphql-lib/interfaces/ID';

export interface IMetronProps {
  match: {
    params: { id: string }
  }
  children: any;
}

const Metron = ({
  match: {
    params: { id }
  },
  children
}: IMetronProps): JSX.Element => {
  // State /////////////////////////////////////////////////////////////////////

  // Data
  const [programmaticTransaction, setProgrammaticTransaction] = useState<boolean>()
  const [urlParams, setURLParams] = useState<any>()

  const [time, setTime] = useState<ITime>()

  // Static Data
  const [facility, setFacility] = useState<IFacility>()
  const [buildings, setBuildings] = useState<IBuilding[]>()
  const [spaces, setSpaces] = useState<ISpace[]>()

  // Temporal Data
  const [environmentalZones, setEnvironmentalZones] = useState<any[]>()
  const [sensors, setSensors] = useState<ISensorRecord[]>()
  const [areacams, setAreacams] = useState<IAreacam[]>()

  // Supplemental Data
  const [sensorRecords, setSensorRecords] = useState<ISensorRecordByType[]>()
  const [co2SensorRecords, setCO2SensorRecords] = useState<ICO2SensorRecord[]>()
  const [greenAutomationWaterSystemRecords, setGreenAutomationWaterSystemRecords] = useState<IGreenAutomationWaterSystemRecord[]>()
  const [allSensorRecords, setAllSensorRecords] = useState<(ISensorRecord | ICO2SensorRecord)[]>()
  const [areaImageRecords, setAreaImageRecords] = useState<IAreaImageRecord[]>()

  const [spaceIDs, setSpaceIDs] = useState<ID[]>()
  const [environmentalZoneIDs, setEnvironmentalZoneIDs] = useState<ID[]>()
  const [sensorIDs, setSensorIDs] = useState<ID[]>()
  const [co2SensorIDs, setCO2SensorIDs] = useState<ID[]>()
  const [allSensorIDs, setAllSensorIDs] = useState<ID[]>()
  const [areacamIDs, setAreacamIDs] = useState<ID[]>()

  // UUID Data
  const [staticDataUUID, setStaticDataUUID] = useState<string>()

  const [environmentalZonesUUID, setEnvironmentalZonesUUID] = useState<string>()
  const [sensorRecordsUUID, setSensorRecordsUUID] = useState<string>()
  const [co2SensorRecordsUUID, setCO2SensorRecordsUUID] = useState<string>()
  const [greenAutomationWaterSystemRecordsUUID, setGreenAutomationWaterSystemRecordsUUID] = useState<string>()
  const [sensorsUUID, setSensorsUUID] = useState<string>()
  const [areacamsUUID, setAreacamsUUID] = useState<string>()

  // GraphQL ///////////////////////////////////////////////////////////////////

  const hasSpaceIDs = spaceIDs && spaceIDs.length > 0
  const hasEnvironmentalZoneIDs = environmentalZoneIDs && environmentalZoneIDs.length > 0
  const hasGreenAutomation = facility && facility.vendorIds?.includes(EVendor.GREEN_AUTOMATION)

  const {
    loading: staticDataLoading,
    error: staticDataError,
    data: staticData
  } = useQuery(QueryStaticData, {
    variables: { id },
    fetchPolicy: 'no-cache'
  })

  const {
    loading: environmentalZonesLoading,
    error: environmentalZonesError,
    data: environmentalZonesData
  } = useQuery(QueryEnvironmentalZones, {
    skip: !hasSpaceIDs,
    variables: { spaceIds: spaceIDs },
    fetchPolicy: 'no-cache'
  })

  const {
    loading: sensorRecordsLoading,
    error: sensorRecordsError,
    data: sensorRecordsData
  } = useQuery(QuerySensorRecords, {
    skip: !hasEnvironmentalZoneIDs,
    variables: {
      environmentalZoneIds: environmentalZoneIDs,
      startDate: time?.sensorRecordsLookback,
      endDate: time?.now
    },
    fetchPolicy: 'no-cache'
  })

  const {
    loading: co2SensorRecordsLoading,
    error: co2SensorRecordsError,
    data: co2SensorRecordsData
  } = useQuery(QueryCO2SensorRecords, {
    skip: !hasEnvironmentalZoneIDs,
    variables: {
      environmentalZoneIds: environmentalZoneIDs,
      startDate: time?.sensorRecordsLookback,
      endDate: time?.now
    },
    fetchPolicy: 'no-cache'
  })

  const {
    loading: greenAutomationWaterSystemRecordsLoading,
    error: greenAutomationWaterSystemRecordsError,
    data: greenAutomationWaterSystemRecordsData
  } = useQuery(QueryGreenAutomationWaterSystemRecords, {
    skip: !hasGreenAutomation,
    variables: {
      facilityIds: [facility?.id],
      getMostRecentPerSystem: true,
      startDate: time?.sensorRecordsLookback,
      endDate: time?.now
    },
    fetchPolicy: 'no-cache'
  })

  const {
    loading: sensorsLoading,
    error: sensorsError,
    data: sensorsData
  } = useQuery(QuerySensors, {
    skip: !allSensorIDs || allSensorIDs.length === 0,
    variables: { ids: allSensorIDs },
    fetchPolicy: 'no-cache'
  })

  const {
    loading: areaImageRecordsLoading,
    error: areaImageRecordsError,
    data: areaImageRecordsData
  } = useQuery(QueryAreaImageRecords, {
    skip: !hasSpaceIDs,
    variables: {
      spaceIds: spaceIDs,
      startDate: time?.sensorRecordsLookback,
      endDate: time?.now
    },
    fetchPolicy: 'no-cache'
  })

  const {
    loading: areacamsLoading,
    error: areacamsError,
    data: areacamsData
  } = useQuery(QueryAreacams, {
    skip: !areacamIDs || areacamIDs.length === 0,
    variables: { ids: areacamIDs },
    fetchPolicy: 'no-cache'
  })

  // Context ///////////////////////////////////////////////////////////////////

  const ctxVal: IMetronContext = {
    // Data
    urlParams,

    facility,
    buildings,
    spaces,

    environmentalZones,
    sensors,
    greenAutomationWaterSystemRecords,
    areacams,

    // Update Data
    setProgrammaticTransaction,
    setURLParams,

    // Loading Data
    facilityLoading: staticDataLoading,
    buildingsLoading: staticDataLoading,
    spacesLoading: staticDataLoading,

    environmentalZonesLoading,
    sensorsLoading:
      sensorRecordsLoading ||
      co2SensorRecordsLoading ||
      sensorsLoading,

    greenAutomationWaterSystemRecordsLoading,

    areacamsLoading:
      areaImageRecordsLoading ||
      areacamsLoading,

    // UUID Data
    facilityUUID: staticDataUUID,
    buildingsUUID: staticDataUUID,
    spacesUUID: staticDataUUID,

    environmentalZonesUUID,
    sensorsUUID,
    greenAutomationWaterSystemRecordsUUID,
    areacamsUUID
  }

  // Effects ///////////////////////////////////////////////////////////////////

  // Update URL params based on `location.search`.
  useIter(
    Search2URLParams,

    { search: location.search,
      setProgrammaticTransaction,
      setURLParams },

    [location.search]
  )

  // Update `location.search` based on URL params.
  useIter(
    URLParams2Search,

    { pathname: location.pathname,
      programmaticTransaction,
      urlParams },

    [urlParams]
  )

  // Update time based on URL params.
  useIter(
    URLParams2Time,

    { urlParams,
      time,
      setProgrammaticTransaction,
      setURLParams,
      setTime },

    [ urlParams,
      urlParams && urlParams.time ]
  )

  // Update facility, buildings, and spaces based on static data.
  useIter(
    UpdateStaticData,
    { staticDataLoading,
      staticData,

      setFacility,
      setBuildings,
      setSpaces,

      setSpaceIDs,
      setStaticDataUUID },

    [staticData]
  )

  // Update environmental zones and environmental zone IDs based on environmental zone data.
  useIter(
    UpdateEnvironmentalZones,

    { environmentalZonesData,
      environmentalZonesLoading,

      setEnvironmentalZones,
      setEnvironmentalZoneIDs,
      setEnvironmentalZonesUUID },

    [environmentalZonesData]
  )

  // Update sensor records and sensor IDs based on sensor record data.
  useIter(
    UpdateSensorRecords,

    { co2: false,
      sensorRecordsData,
      sensorRecordsLoading,

      setSensorRecords,
      setSensorIDs,

      setSensorRecordsUUID },

    [sensorRecordsData]
  )

  // Update CO2 sensor records and CO2 sensor IDs based on CO2 sensor record data.
  useIter(
    UpdateSensorRecords,

    { co2: true,
      sensorRecordsData: co2SensorRecordsData,
      sensorRecordsLoading: co2SensorRecordsLoading,

      setSensorRecords: setCO2SensorRecords,
      setSensorIDs: setCO2SensorIDs,

      setSensorRecordsUUID: setCO2SensorRecordsUUID },

    [co2SensorRecordsData]
  )

  // Update Green Automation watersytem records based on water system record data.
  useIter(
    UpdateGreenAutomationWaterSystemRecords,

    { greenAutomationWaterSystemRecordsData,
      greenAutomationWaterSystemRecordsLoading,

      setGreenAutomationWaterSystemRecords,
      setGreenAutomationWaterSystemRecordsUUID },

    [greenAutomationWaterSystemRecordsData]
  )

  // Update all sensor IDs based on sensor IDs and CO2 sensor IDs.
  useIter(
    UpdateAllSensorSupdata,

    { sensorRecords,
      co2SensorRecords,
      sensorIDs,
      co2SensorIDs,

      setAllSensorRecords,
      setAllSensorIDs },

    [sensorRecordsUUID, co2SensorRecordsUUID]
  )

  // Update sensors based on sensor data.
  useIter(
    UpdateSensors,

    { sensorsData,
      sensorsLoading,
      sensorRecords: allSensorRecords,

      setSensors,
      setSensorsUUID },

    [sensorsData]
  )

  // Update area image records and areacam IDs based on area image record data.
  useIter(
    UpdateAreaImageRecords,

    { areaImageRecordsData,
      areaImageRecordsLoading,

      setAreaImageRecords,
      setAreacamIDs },

    [areaImageRecordsData]
  )

  // Update areacams based on areacam data.
  useIter(
    UpdateAreacams,

    { areacamsData,
      areacamsLoading,
      areaImageRecords,

      setAreacams,
      setAreacamsUUID },

    [areacamsData]
  )

  // Render ////////////////////////////////////////////////////////////////////

  return (
    <MetronContext.Provider value={ctxVal}>
      {children}
    </MetronContext.Provider>
  )
}

export default Metron

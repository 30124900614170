// Types
import IInventory from 'graphql-lib/interfaces/IInventory';
import ID from 'graphql-lib/interfaces/ID';

// React
import React, {
  useState
} from 'react';

// React Libs
import GoTo from 'react-lib/go-to';
import { useIter } from 'react-lib/use-iter';

// GraphQL
import { useQuery } from '@apollo/client';
import {
  QueryInventory,
  QueryInventoryLocations
} from './queries';

// Effects
import {
  UpdateInventories,
  UpdateInventoryLocations
} from './effects';

import Button from 'ui-lib/components/button';
import InventoryCard from './inventory-card';
import { DeprovisionInventory } from '../../../actions/inventory-group';
import { Tag } from '../../../utils/Routes';
import { Space } from 'grid-view/utils/routes';
import IInventoryLocation from 'graphql-lib/interfaces/IInventoryLocation';
import IAction from 'arrayview/interfaces/IAction';

export interface IInventoryGroupsProps {
  animated?: boolean
  id: ID
}

const InventoryGroups = ({
  animated,
  id
}: IInventoryGroupsProps): JSX.Element => {
  // State /////////////////////////////////////////////////////////////////////

  const [inventories, setInventories] = useState<IInventory[]>()
  const [inventoryLocations, setInventoryLocations] = useState<Array<IInventoryLocation>>()
  const [inventoryIDs, setInventoryIDs] = useState<ID[]>()

  // GraphQL ///////////////////////////////////////////////////////////////////

  const {
    loading: inventoriesLoading,
    error: inventoriesError,
    data: inventoriesData,
    refetch: refetchInventories
  } = useQuery(QueryInventory, {
    variables: { id },
    fetchPolicy: 'no-cache'
  })

  const {
    loading: inventoryLocationsLoading,
    error: inventoryLocationsError,
    data: inventoryLocationsData,
  } = useQuery(QueryInventoryLocations, {
    skip: !inventoryIDs || inventoryIDs.length === 0,
    variables: { inventoryIds: inventoryIDs },
    fetchPolicy: 'no-cache'
  })

  // Effects ///////////////////////////////////////////////////////////////////

  useIter(
    UpdateInventories,

    { inventoriesLoading,
      inventoriesData,

      setInventories,
      setInventoryIDs },

    [inventoriesData]
  )

  useIter(
    UpdateInventoryLocations,

    { inventoryLocationsLoading,
      inventoryLocationsData,

      setInventoryLocations },

    [inventoryLocationsData]
  )

  // Callbacks /////////////////////////////////////////////////////////////////

  const inventoryGroup$click = (evnt: CustomEvent<any>): void => {
    const { locations } = evnt.detail;
    if (locations?.[0]?.seenOn) {
      const [location] = locations
      const to = Space
        .replace(':id', String(location.spaceId))
        .replace(':timestamp?', String(location.seenOn))
        .replace(':x?', String(location.positionX))
        .replace(':y?', String(location.positionY))

      GoTo(`${to}?imageId=1`)
    }
  }
  const inventoryGroup$action = async ({ detail: { action, data } }: { detail: { action: IAction<IInventory>, data: IInventory }}): Promise<void> => {
    await action.func(action, [data])
    refetchInventories()
  }

  const $oneTag = (): void => GoTo(Tag.replace(':id', String(id)))
  const $twoTag = (): void => GoTo(Tag.replace(':id', String(id)) + '?twoTag=true')

  // Render ////////////////////////////////////////////////////////////////////

  let content = null

  if (inventoriesLoading) {
    content = (
      <>
        <article className='InventoryCard Loading' />
        <article className='InventoryCard Loading' />
        <article className='InventoryCard Loading' />
      </>
    )
  }

  if (inventories && inventories.length === 0) {
    content = (
      <p className='Body1 Primary'>No Inventory Groups</p>
    )
  }

  if (inventories && inventories.length > 0) {
    content = inventories.map((i): JSX.Element => (
      <InventoryCard
        outlined
        animated={animated}

        key={i.id}
        actions={[DeprovisionInventory]}
        data={i}
        supData={inventoryLocations}
        onClick={inventoryGroup$click}
        onAction={inventoryGroup$action}
      />
    ))
  }

  return (
    <>
      <div className='Body InventoryGroups'>
        {content}
      </div>

      <div style={{ flex: '1 0 auto' }} />

      <div className='Actions'>
        <Button
          animated={animated}
          outlined
          onClick={$oneTag}
        >
          Provision 1 Tag
        </Button>

        <Button
          animated={animated}
          outlined
          onClick={$twoTag}
        >
          Provision 2 Tag
        </Button>
      </div>
    </>
  )
}

export default InventoryGroups

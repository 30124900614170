// Libs
import { v4 } from 'uuid'

// OpenLayers
import Map from 'ol/Map'
import { Overlay, View } from 'ol'
import { Interaction } from 'ol/interaction'
import Control from 'ol/control/Control'
import Layer from 'ol/layer/Layer'

type IFunc = () => void
interface IMapRef {
  current: Map
  setView: (value: View) => void
  getView: () => null
  un: (event: number, previousCallback: IFunc) => null
  on: (event: number, previousCallback: IFunc) => null
}

const InitMap = ({
  view,
  interactions,
  controls,
  overlays,
  layers,
  pixelRatio,
  moveTolerance,
  maxTilesLoading,
  divRef,
  mapRef,
  setMapRefUUID
}: {
  view: View
  interactions: Array<Interaction>
  controls: Array<Control>
  overlays: Array<Overlay>
  layers: Array<Layer>
  pixelRatio: number
  moveTolerance: number
  maxTilesLoading: number
  divRef: string
  mapRef: IMapRef
  setMapRefUUID: (value: string) => void
}): void => {
  if (
    view &&
    interactions &&
    controls &&
    overlays &&
    layers &&
    divRef &&
    !mapRef.current
  ) {
    const newMapRef = new Map({
      target: divRef,
      view,
      interactions,
      controls,
      overlays,
      layers,
      pixelRatio,
      moveTolerance,
      maxTilesLoading
    })

    mapRef.current = newMapRef
    setMapRefUUID(v4())
  }
}

const UpdateView = ({
  view,
  mapRef
}: {
  view: View
  mapRef: IMapRef
}): void => {
  if (
    view &&
    mapRef &&
    !Object.is(view, mapRef.getView())
  ) {
    mapRef.setView(view)
  }
}

const CallOnMapRef = ({
  onMapRef,
  mapRef
}: {
  onMapRef: (val: IMapRef) => void
  mapRef: IMapRef
}): void => {
  if (
    onMapRef &&
    mapRef
  ) {
    onMapRef(mapRef)
  }
}

const UpdateCallback = ({
  event,
  callback,
  callbacksRef,
  mapRef
}: {
  event: number
  callback: IFunc
  callbacksRef: Array<IFunc>
  mapRef: IMapRef
}): void => {
  const previousCallback = callbacksRef[event]

  if (!mapRef) {
    return
  }

  if (
    !callback &&
    !previousCallback
  ) {
    return
  }

  if (
    !callback &&
    previousCallback
  ) {
    mapRef.un(event, previousCallback)
    delete callbacksRef[event]

    return
  }

  if (
    callback &&
    !previousCallback
  ) {
    mapRef.on(event, callback)
    callbacksRef[event] = callback

    return
  }

  if (
    callback &&
    previousCallback
  ) {
    mapRef.un(event, previousCallback)
    mapRef.on(event, callback)
    callbacksRef[event] = callback
  }
}

export {
  InitMap,
  UpdateView,
  CallOnMapRef,
  UpdateCallback
}

// Types
import EEntity from 'graphql-lib/enums/entity';
import IEntity from 'graphql-lib/interfaces/entity';
import ISpace from 'graphql-lib/interfaces/ISpace';
import IInventory from 'graphql-lib/interfaces/IInventory';
import IInventoryLocation from 'graphql-lib/interfaces/IInventoryLocation';
import IInventoryStitch from 'graphql-lib/interfaces/IInventoryStitch';
import ID from 'graphql-lib/interfaces/ID';

// Libs
import { format } from 'date-fns'

import { createDate } from 'utils/dates'


const DateFormat = 'M/d/yy'

const UpdateSelected = ({
  inventory,
  selectedEntities,

  setSelected
}: {
  inventory: IInventory;
  selectedEntities: IEntity[];

  setSelected: (newVal: IEntity) => void;
}): void => {
  if (inventory && selectedEntities) {
    const newSelected = selectedEntities.find((e): boolean =>
      e.type == EEntity.Inventory && e.id == inventory.id)

    setSelected(newSelected)
  }
}

const UpdateLastSeen = ({
  spacesLoading,
  inventoryLocationsLoading,
  inventoryStitchesLoading,

  spaces,
  inventory,
  _inventoryLocations,
  _inventoryStitches,

  setImage,
  setLastSeen,
  setTags
}: {
  spacesLoading: boolean;
  inventoryLocationsLoading: boolean;
  inventoryStitchesLoading: boolean;

  spaces: ISpace[];
  inventory: IInventory;
  _inventoryLocations: Record<ID, IInventoryLocation[]>;
  _inventoryStitches: Record<ID, IInventoryStitch[]>;

  setImage: (newVal: string) => void;
  setLastSeen: (newVal: string) => void;
  setTags: (newVal: string) => void;
}): void => {
  if (
    spacesLoading ||
    inventoryLocationsLoading ||
    inventoryStitchesLoading
  ) {
    setLastSeen('')
    return
  }

  let newImage: string = '';
  let newLastSeen: string = 'Never Seen';
  let newTags: string = 'No Tags';

  if (spaces && _inventoryLocations) {
    const newInventoryLocation = _inventoryLocations[inventory.id]?.[0]

    if (newInventoryLocation) {
      newImage = newInventoryLocation.thumbUrl
      newTags = newInventoryLocation.locatorCode
      const space = spaces.find((s): boolean =>
        s.id == (newInventoryLocation.spaceIdFromStitch || newInventoryLocation.spaceId))

      if (space) {
        newLastSeen = '';
        if (newInventoryLocation.seenOn) {
          newLastSeen = `Last Seen ${format(createDate(newInventoryLocation.seenOn), DateFormat)} - `;
        }

        newLastSeen = `${newLastSeen}${space.name}`;
        newTags = newInventoryLocation.locatorCode
      } else {
        newTags = ''
      }
    }
  }

  if (inventory?.inventoryLocator?.length == 1) {
    newTags = inventory.inventoryLocator[0]?.locator[0]?.serial ?? newTags
  }

  setImage(newImage)
  setLastSeen(newLastSeen)
  setTags(newTags)
}

const UpdateInventoryCharts = ({
  inventory,
  _inventoryCharts,

  setInventoryCharts
}: {
  inventory: IInventory;
  _inventoryCharts: Record<ID, Record<string, number[][]>>;

  setInventoryCharts: (newVal: Record<string, number[][]>) => void;
}): void => {
  if (inventory && _inventoryCharts) {
    const newInventoryCharts = _inventoryCharts[inventory.id]
    // Filtering out any charts that do not have a list of values to chart
    const filteredCharts = Object.entries(newInventoryCharts).reduce(
      (retVal, [key, val]) => {
        if (val?.length > 0) retVal[key] = val
        return retVal
      },
      {} as typeof newInventoryCharts
    )
    setInventoryCharts(filteredCharts)
  }
}

export {
  UpdateSelected,
  UpdateLastSeen,
  UpdateInventoryCharts
}

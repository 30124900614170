/**
 * @author Miras Absar <mabsar@iunu.com>
 */

import { gql } from '@apollo/client'

const CreateCrop = gql`
  mutation(
    $facilityId: ID!,
    $productCode: String,
    $name: String
    $code: String,
    $containerSize: String,
    $containersPerLocator: Int,
    $startDate: DateTime!,
    $transplantDate: DateTime,
    $endDate: DateTime!
    $description: String,
    $zoneName: String,
    $subZoneName: String
  ) {
    Crop {
      create(input: {
        facilityId: $facilityId,
        productCode: $productCode,
        name: $name,
        code: $code,
        containerSize: $containerSize,
        containersPerLocator: $containersPerLocator,
        startDate: $startDate,
        transplantDate: $transplantDate,
        endDate: $endDate,
        description: $description,
        zoneName: $zoneName,
        subZoneName: $subZoneName
      }) {
        id,
        inventory { id }
      }
    }
  }
`

export { CreateCrop }

/**
 * @author Miras Absar <mabsar@iunu.com>
 */

// React
import React, {
  useCallback,
  useContext
} from 'react'

// FontAwesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faSpinnerThird,
  faTimes,
  faVideo
} from '@fortawesome/pro-regular-svg-icons'

// UI Lib
import Mobius from 'map-view/contexts/mobius'

interface IAreacamsControlProps {
  outlined?: boolean;
  resting?: boolean
  raised?: boolean;
  animated?: boolean;
}

const AreacamsControl = ({
  outlined,
  resting,
  raised,
  animated
}: IAreacamsControlProps): JSX.Element => {
  
  // Context ///////////////////////////////////////////////////////////////////

  const {
    searchParams: {
      searchParams,
      setSearchParams,
      searchParamsUUID
    },
    spaces: { spaces },
    areacams: { areacams, areacamsLoading }
  } = useContext(Mobius)
  
  // Callbacks /////////////////////////////////////////////////////////////////

  const $toggleOpen = useCallback(
    (): void => {
      const open = !searchParams.viewAreacamTiles
        ? true
        : undefined

      const newSearchParams = Object.assign(
        {},
        searchParams,
        { viewAreacamTiles: open }
      )

      setSearchParams(newSearchParams)
    },
    [searchParamsUUID]
  )

  // Render ////////////////////////////////////////////////////////////////////
  
  let className = 'AreacamsControl'

  if (outlined) className += ' Outlined'
  if (resting) className += ' Resting'
  if (raised) className += ' Raised'
  if (animated) className += ' Animated'

  const toggleIcon = searchParams.viewAreacamTiles
    ? faTimes
    : faVideo

  return (
    <div className={className}>
      {areacamsLoading && (
        <button className='Loading'>
          <FontAwesomeIcon icon={faSpinnerThird} spin />
        </button>
      )}

      {areacams && areacams.length === 0 && (
        <button className='Disabled'>
          <FontAwesomeIcon icon={faVideo} />
        </button>
      )}

      {areacams && areacams.length > 0 && (
        <button onClick={$toggleOpen}>
          <FontAwesomeIcon icon={toggleIcon} />
        </button>
      )}
    </div>
  )
}

export default AreacamsControl

// Types
import IFacility from 'graphql-lib/interfaces/IFacility';
import IBuilding from 'graphql-lib/interfaces/IBuilding';
import ISpace from 'graphql-lib/interfaces/ISpace';
import { ISensorRecord } from 'graphql-lib/interfaces/ISensorDevice';
import IGreenAutomationWaterSystemRecord from 'graphql-lib/interfaces/IGreenAutomationWaterSystemRecord';
import IAreacam from 'graphql-lib/interfaces/IAreacam';

// React
import { createContext } from 'react'
import IEnvironmentalZone from 'graphql-lib/interfaces/IEnvironmentalZone';
import ISetStateType from 'graphql-lib/interfaces/ISetStateType';

export interface IMetronContext {
  // Data
  urlParams: any;
  facility?: IFacility;
  buildings?: Array<IBuilding>;
  spaces?: Array<ISpace>;
  environmentalZones?: Array<IEnvironmentalZone>;
  sensors?: Array<ISensorRecord>;
  greenAutomationWaterSystemRecords?: Array<IGreenAutomationWaterSystemRecord>;
  areacams?: Array<IAreacam>;
  // Update Data
  setProgrammaticTransaction: ISetStateType<boolean>
  setURLParams: ISetStateType<string>
  // Loading Data
  facilityLoading?: boolean;
  buildingsLoading?: boolean;
  spacesLoading?: boolean;
  environmentalZonesLoading?: boolean;
  sensorsLoading?: boolean;
  greenAutomationWaterSystemRecordsLoading?: boolean;
  areacamsLoading?: boolean;
  // UUID Data
  facilityUUID?: string;
  buildingsUUID?: string;
  spacesUUID?: string;
  environmentalZonesUUID?: string;
  sensorsUUID?: string;
  greenAutomationWaterSystemRecordsUUID?: string;
  areacamsUUID?: string;
}

const MetronContext = createContext<IMetronContext>({
  // Data
  urlParams: undefined,

  facility: undefined,
  buildings: undefined,
  spaces: undefined,

  environmentalZones: undefined,
  sensors: undefined,
  areacams: undefined,

  // Update Data
  setProgrammaticTransaction: () => {},
  setURLParams: () => {},

  // Loading Data
  facilityLoading: undefined,
  buildingsLoading: undefined,
  spacesLoading: undefined,

  environmentalZonesLoading: undefined,
  sensorsLoading: undefined,
  areacamsLoading: undefined,

  // UUID Data
  facilityUUID: undefined,
  buildingsUUID: undefined,
  spacesUUID: undefined,

  environmentalZonesUUID: undefined,
  sensorsUUID: undefined,
  areacamsUUID: undefined
})

export default MetronContext

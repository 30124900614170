import { EStorage } from 'utils/storage';

// Libs
import { v4 } from 'uuid';
import { parse } from 'query-string';

// React
import React, {
  CSSProperties,
  useContext,
  useEffect,
  useState
} from 'react';

// React Libs
import { useDebounce } from 'use-debounce';
import { toast } from 'react-toastify';
import AutoSizer from 'react-virtualized-auto-sizer';
import { VariableSizeList as List, ListOnItemsRenderedProps } from 'react-window';
import useStorage from 'react-lib/use-storage';

// GraphQL
import { gql, useQuery } from '@apollo/client';

// Contexts
import NavigationContext from 'context/navigation';

// Routes
import {
  Tasks,
  CreateTaskIssue,
  ViewTaskIssue
} from '../../utils/routes'

// Columns
import {
  CreatedAtColumn,
  UpdatedAtColumn,
  DescriptionColumn,
  FlagColumn
} from 'graphql-lib/columns/task-issue'

import { ITasksViewProps } from '../tasks-view'

// Filters
import {
  All as AllFilter,
  Alert as AlertFilter,
  CreatedByMe as CreatedByMeFilter,
  Rejected as RejectedFilter,
  Pending as PendingFilter,
  ConvertedToTask as ConvertedToTaskFilter
} from '../../filters/task-issue'

// Utils
import GoTo from 'react-lib/go-to'

// UI Lib
import DetailsView from 'ui-lib/components/details-view'
import ListHeader from 'ui-lib/components/list-header'
import TaskIssueCard from '../task-issue-card'
import ViewTaskIssueCard from '../view-task-issue-card'
import ITaskIssue from 'graphql-lib/interfaces/ITaskIssue'
import IFilter from 'arrayview/interfaces/IFilter'
import IColumn from 'arrayview/interfaces/IColumn'

const QueryTasksIssues = gql`
  query($facilityIds: [ID], $offset: Int, $limit: Int, $convertedToTaskOnly: Boolean, $rejectedOnly: Boolean, $pendingOnly: Boolean) {
    taskIssue(facilityIds: $facilityIds, convertedToTaskOnly: $convertedToTaskOnly, rejectedOnly: $rejectedOnly, pendingOnly: $pendingOnly, offset: $offset, limit: $limit) {
      id

      description
      priority
      createdAt
      rejectedAt
      convertedToTaskAt
      taskId
      task{
        complete
        taskAssignee {
          customerUser {
            id

            name
            username
            email
          }
        }
      }

      customerTaskCategory {
        id

        icon
        color

        title
        description

        flagCategory {
          id
          name
        }
      }

      locationProfile {
        id

        inventoryId
        positionX
        positionY
      }
    }
  }
`

export const TaskIssuesViewRoutes = [
  Tasks,
  CreateTaskIssue,
  ViewTaskIssue
]

const Columns = [
  CreatedAtColumn,
  UpdatedAtColumn,
  DescriptionColumn,
  FlagColumn
]

const Filters = [
  AllFilter,
  AlertFilter,
  CreatedByMeFilter,
  ConvertedToTaskFilter,
  RejectedFilter,
  PendingFilter
]

const TasksIssueView = ({
  match: {
    params: { id },
    path
  },
  setTaskOrIssue,
  tabs
}: ITasksViewProps): JSX.Element => {
  // ┌─────────────────────────────────────────────────────────────────────────┐
  // │ CONTEXT                                                                 │
  // └─────────────────────────────────────────────────────────────────────────┘

  // Navigation Context
  const {
    setSecondaryActions,
    setTitle
  } = useContext(NavigationContext)

  // ┌─────────────────────────────────────────────────────────────────────────┐
  // │ PARAMS                                                                  │
  // └─────────────────────────────────────────────────────────────────────────┘

  const {
    flagId,
    spaceId,
    cropId,
    positionX,
    positionY
  } = parse(location.search)

  // ┌─────────────────────────────────────────────────────────────────────────┐
  // │ STATE                                                                   │
  // └─────────────────────────────────────────────────────────────────────────┘

  const [facilities] = useStorage('facilities', EStorage.EphemeralStorage)

  // GQL Data
  const [offset, setOffset] = useState(0);
  const [limit, setLimit] = useState(100);
  const [atEnd, setAtEnd] = useState(false);
  const [data, setData] = useState<Array<ITaskIssue>>();

  const [search, setSearch] = useState<string>('');
  const [debouncedSearch] = useDebounce(search, 300);
  const [filter, setFilter] = useState<IFilter<ITaskIssue>>(AllFilter);
  const [column, setColumn] = useState<IColumn<ITaskIssue>>(CreatedAtColumn);
  const [descending, setDescending] = useState<boolean>(true);

  const [searchedData, setSearchedData] = useState<Array<ITaskIssue>>()
  const [filteredData, setFilteredData] = useState<Array<ITaskIssue>>()
  const [sortedData, setSortedData] = useState<Array<ITaskIssue>>()

  const [_, rerender] = useState<string>()

  // ┌─────────────────────────────────────────────────────────────────────────┐
  // │ GRAPHQL                                                                 │
  // └─────────────────────────────────────────────────────────────────────────┘
  const {
    loading: gqlLoading,
    error: gqlError,
    data: gqlData,
    variables: {
      offset: gqlOffset,
      limit: gqlLimit
    }
  } = useQuery(QueryTasksIssues, {
    skip: !facilities || facilities.length === 0,
    variables: {
      facilityIds: [facilities?.[0]?.id],
      convertedToTaskOnly: filter.id === 'task-issue:filter:convertedToTask',
      rejectedOnly: filter.id === 'task-issue:filter:rejected',
      pendingOnly: filter.id === 'task-issue:filter:pending',
      offset,
      limit
    },
    fetchPolicy: 'no-cache'
  })

  // ┌─────────────────────────────────────────────────────────────────────────┐
  // │ EFFECTS                                                                 │
  // └─────────────────────────────────────────────────────────────────────────┘

  // Setup Navigation Context
  useEffect((): void => {
    setSecondaryActions([])
    setTitle(null)
  }, [])

  // Display Error from GQL Error
  useEffect((): void => {
    if (gqlError) {
      toast.error("Couldn't load tasks; please try again in a few seconds.")
    }
  }, [gqlError])

  // Update Data from GQL Data
  useEffect((): void => {
    if (!gqlLoading && gqlData) {
      const {
        taskIssue: newData
      } = gqlData

      // If offset is 0 overwrite data,
      // else append to data.
      if (gqlOffset === 0) {
        setData(newData)
      } else {
        const dataValue = data || [];
        const newDataValue = newData || [];
        const _newData = [...dataValue, ...newDataValue];
        setData(_newData)
      }

      if (newData.length === 0) {
        setAtEnd(true)
      }
    }
  }, [gqlData])

  // Update Searched Data from Data and/or Search
  useEffect((): void => {
    if (data) {
      const newSearchedData = data.filter((d): boolean =>
        Columns.some((c): boolean =>
          c.searchFunc(c, debouncedSearch, d)))

      setSearchedData(newSearchedData)
    }
  }, [data, debouncedSearch])

  // Update Filtered Data from Searched Data and/or Filter
  useEffect((): void => {
    if (searchedData) {
      const newFilteredData = searchedData.filter((d): boolean =>
        filter.func(filter, d))

      setFilteredData(newFilteredData)
    }
  }, [searchedData, filter])

  // Update Sorted Data from Filtered Data and/or Column
  useEffect((): void => {
    if (filteredData) {
      const sortFunc = descending
        ? column.descendingSortFunc
        : column.ascendingSortFunc

      const newSortedData = [...filteredData].sort((a, b): number =>
        sortFunc(column, a, b))

      setSortedData(newSortedData)
      rerender(v4())
    }
  }, [filteredData, column, descending])

  // ┌─────────────────────────────────────────────────────────────────────────┐
  // │ CALLBACKS                                                               │
  // └─────────────────────────────────────────────────────────────────────────┘

  const $back = (): void => GoTo(Tasks)

  const $save = (createTaskIssueData: {TaskIssue: {create: ITaskIssue}}): void => {
    // Update the local task view with the new task
    const newTaskIssue = createTaskIssueData.TaskIssue.create
    const updatedTasksIssue = [newTaskIssue, ...data]
    setData(updatedTasksIssue)

    // Select the task and open the task card details card
    GoTo(ViewTaskIssue.replace(':id', String(newTaskIssue.id)))
  }

  const $nextPage = (pageLength?: number): void => {
    const newLimit = pageLength || limit
    const newOffset = offset + limit

    if (!atEnd && !gqlLoading) {
      setOffset(newOffset)
      setLimit(newLimit)
    }
  }

  const $search = (newSearch: string): void =>
    setSearch(newSearch)

  const $filter = (newFilter: any): void =>
    setFilter(newFilter)

  const $column = (newColumn: any): void =>
    setColumn(newColumn)

  const $descending = (newDescending: boolean): void =>
    setDescending(newDescending)

  const $click = (data: any): void => {
    const to = ViewTaskIssue.replace(':id', data.id)
    GoTo(to)
  }

  const $itemSize = (index: number): number => {
    const margin = index === 0
      ? 16
      : 8

    return 54 + 16 + margin
  }

  const $item = (params: { index: number, style: CSSProperties }): JSX.Element => {
    const { index, style } = params;
    const item = sortedData[index]

    const width = 'calc(100% - 32px)'
    const margin = index === 0
      ? '8px'
      : '0px 8px 8px'

    const _style = Object.assign(
      {},
      style,
      {
        width,
        height: '54px',
        margin
      }
    )

    return (
      <TaskIssueCard
        outlined
        animated
        tabs={tabs}
        setTaskOrIssue={setTaskOrIssue}
        data={item}
        selectedId={id}
        style={_style}
        onClick={$click}
      />
    )
  }

  const $itemsRendered = (params: ListOnItemsRenderedProps): void => {
    const { visibleStopIndex } = params
    if (visibleStopIndex === sortedData.length - 1) {
      $nextPage()
    }
  }

  // Updates the flags view with the updated task issue on save
  const updateFlagsViewTask = (updatedTaskIssue: ITaskIssue): void => {
    const selectedTaskIssueIndex = data.findIndex((taskIssue: ITaskIssue) => taskIssue.id === id)

    if (selectedTaskIssueIndex > -1) {
      const dataVal = data || [];
      const updatedTaskIssues = [...dataVal]
      updatedTaskIssues[selectedTaskIssueIndex] = updatedTaskIssue
      setData(updatedTaskIssues)
    }
  }

  // ┌─────────────────────────────────────────────────────────────────────────┐
  // │ RENDER                                                                  │
  // └─────────────────────────────────────────────────────────────────────────┘

  const showDetails = path === ViewTaskIssue

  return (
    <DetailsView
      animated
      showDetails={showDetails}

      main={
        <div className='TasksIssueView'>
          <ListHeader
            animated

            filters={Filters}
            columns={Columns}

            search={search}
            filter={filter}
            column={column}
            descending={descending}

            onSearch={$search}
            onFilter={$filter}
            onColumn={$column}
            onDescending={$descending}
          />

          {sortedData && (
            <div className='AutoSizerParent'>
              <AutoSizer>
                {({ width, height }: { width: number, height: number }): JSX.Element => (
                  <List
                    width={width}
                    height={height}
                    itemData={sortedData}
                    itemCount={sortedData.length}
                    itemSize={$itemSize}
                    onItemsRendered={$itemsRendered}
                  >
                    {$item}
                  </List>
                )}
              </AutoSizer>
            </div>
          )}
        </div>
      }

      details={showDetails
        ? <ViewTaskIssueCard
            resting
            animated
            id={id}
            onBack={$back}
            updateFlagsViewTask={updateFlagsViewTask}
            setTaskOrIssue={setTaskOrIssue}
            tabs={tabs}
          />
        : null }
    />
  )
}

export default TasksIssueView

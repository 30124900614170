import { gql } from '@apollo/client'

const QuerySpaces = gql`
  query($facilityId: ID) {
    facility(id: $facilityId) {
      id

      building {
        id


        space {
          id

          code
          name
          nickname
        }
      }
    }
  }
`

const QuerySensors = gql`
  query($sensorIds: [ID]) {
    sensor(ids: $sensorIds) {
      id

      code
    }
  }
`

const QueryAreacams = gql`
  query($areacamIds: [ID]) {
    areacam(ids: $areacamIds) {
      id
      spaceId

      code
      latitude
      longitude
    }
  }
`

const QuerySensorRecords = gql`
  query(
    $facilityIds: [ID],
    $startDate: DateTime,
    $endDate: DateTime
  ) {
    sensorRecord(
      facilityIds: $facilityIds,
      startDate: $startDate,
      endDate: $endDate
    ) {
      id
      createdOn
      spaceId
      sensorDeviceId

      absoluteHumidity
      dli
      humidity
      temperature
      umol
      vpd
    }
  }
`

const QueryCO2SensorRecords = gql`
  query(
    $facilityIds: [ID],
    $startDate: DateTime,
    $endDate: DateTime
  ) {
    co2SensorRecord(
      facilityIds: $facilityIds,
      startDate: $startDate,
      endDate: $endDate
    ) {
      id
      createdOn
      spaceId
      environmentalZoneId
      sensorDeviceId

      co2
    }
  }
`

const QueryAreaImageRecords = gql`
  query(
    $facilityIds: [ID],
    $startDate: DateTime,
    $endDate: DateTime
  ) {
    areaImageRecord(
      facilityIds: $facilityIds,
      startDate: $startDate,
      endDate: $endDate
    ) {
      id
      createdOn
      spaceId
      camDeviceId

      thumbUrl
      url
    }
  }
`

export {
  QuerySpaces,
  QuerySensors,
  QueryAreacams,
  QuerySensorRecords,
  QueryCO2SensorRecords,
  QueryAreaImageRecords
}

/* eslint-disable camelcase */
// @ts-nocheck
const imageLoadFunction = (img: any, src: string): void => {
  WebP_to_PNG(src, 5, stbir_filter.cubicbspline, true)
    .then((png: string): void => {
      const image = img.getImage() as HTMLImageElement;
      image.src = png
    })
}

export default imageLoadFunction

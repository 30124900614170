// Types
import ISpace from 'graphql-lib/interfaces/ISpace';
import ICrop from 'graphql-lib/interfaces/ICrop';
import IInventory from 'graphql-lib/interfaces/IInventory';
import IInventoryLocation from 'graphql-lib/interfaces/IInventoryLocation';
import IInventoryStitch from 'graphql-lib/interfaces/IInventoryStitch';
import IEnvironmentalZone from 'graphql-lib/interfaces/IEnvironmentalZone';
import ID from 'graphql-lib/interfaces/ID';

import {
  ISensorRecord,
  ISensorRecordData,
  ICO2SensorRecord
} from 'graphql-lib/interfaces/ISensorDevice'

// Utils
import {
  EStorage,
  GetItem
} from 'utils/storage'

// Libs
import {
  addHours,
  startOfDay
} from 'date-fns'
import { uniqBy } from 'lodash'
import { v4 } from 'uuid'
import { createDate } from 'utils/dates'

interface IInventoryPosition {
  inventoryId: ID;
  spaceId: ID;
  startDate: string;
  endDate: string;
}

const UpdateSpaces = ({
  spacesLoading,
  spacesData,

  setSpacesLoading,
  setSpaces,
  setSpacesUUID
}: {
  spacesLoading: boolean;
  spacesData: { space: ISpace[] };

  setSpacesLoading: (newVal: boolean) => void;
  setSpaces: (newVal: ISpace[]) => void;
  setSpacesUUID: (newVal: string) => void;
}): void => {
  setSpacesLoading(spacesLoading)
  setSpacesUUID(v4())

  if (!spacesData) {
    return;
  }

  setSpaces(spacesData.space)
}

const UpdateCrop = ({
  cropLoading,
  cropData,

  setCropLoading,
  setCrop,
  setCropUUID
}: {
  cropLoading: boolean;
  cropData: { crop: ICrop[] };

  setCropLoading: (newVal: boolean) => void;
  setCrop: (newVal: ICrop) => void;
  setCropUUID: (newVal: string) => void;
}): void => {
  if (cropLoading || !cropData) {
    setCropLoading(true)
    setCropUUID(v4())
    return
  }

  setCropLoading(false)
  setCrop(cropData.crop[0])
  setCropUUID(v4())
}

const UpdateInventories = ({
  inventoriesLoading,
  inventoriesData,

  setInventoriesLoading,
  setInventories,
  setInventoryIDs,
  setInventoriesUUID
}: {
  inventoriesLoading: boolean;
  inventoriesData: { inventory: IInventory[] };

  setInventoriesLoading: (newVal: boolean) => void;
  setInventories: (newVal: IInventory[]) => void;
  setInventoryIDs: (newVal: ID[]) => void;
  setInventoriesUUID: (newVal: string) => void;
}): void => {
  if (inventoriesLoading || !inventoriesData) {
    setInventoriesLoading(true)
    setInventoriesUUID(v4())
    return
  }

  const newInventories = inventoriesData.inventory
  const newInventoryIDs = newInventories
    .map((i): ID => i.id)

  setInventoriesLoading(false)
  setInventories(newInventories)
  setInventoryIDs(newInventoryIDs)
  setInventoriesUUID(v4())
}

const UpdateInventoryLocations = ({
  inventoryLocationsLoading,
  inventoryLocationsData,
  inventoryIDs,
  inventories,
  setInventoryLocationsLoading,
  setInventoryLocations,
  setSpaceIDs,
  inventoryLocationsCache,
  setInventoryLocationsUUID,
  setInventorySpaceIdMap
}: {
  inventoryLocationsLoading: boolean;
  inventoryLocationsData: { inventoryLocation: IInventoryLocation[] };
  inventoryIDs: number[],
  inventories: IInventory[],
  setInventoryLocationsLoading: (newVal: boolean) => void;
  setInventoryLocations: (newVal: IInventoryLocation[]) => void;
  setSpaceIDs: (newVal: ID[]) => void;
  inventoryLocationsCache: { current: Record<ID, IInventoryLocation[]> };
  setInventoryLocationsUUID: (newVal: string) => void;
  setInventorySpaceIdMap: (newVal: {[key: string]: number[]})=> void;
}): void => {
  setInventoryLocationsLoading(inventoryLocationsLoading)
  if (!inventoryLocationsData) {
    return
  }
  setInventoryLocationsUUID(v4())
  const newInventoryLocations = inventoryLocationsData.inventoryLocation.map((inventoryLocation) => {
    const inventory = inventories.find((i) => i.id === inventoryLocation.inventoryId)
    return {
      ...inventoryLocation,
      spaceId: inventoryLocation.spaceIdFromStitch ?? inventoryLocation.spaceId ?? inventory?.zoneId,
    }
  });

  // Maps every spaceId with theirs inventoriesIds necessary to display the compare button.
  const mapInventoriesIdWithSpaceId = newInventoryLocations
    .reduce(
      (acc: { [key: string]: number[] }, cur) => {
        const inventoryId = Number(cur.inventoryId);

        if (acc[cur.spaceId]) {
          if (!acc[cur.spaceId].includes(inventoryId)) {
            acc[cur.spaceId].push(inventoryId);
          } // else nothing to do; keeping the values in each array unique
        } else {
          acc[cur.spaceId] = [ inventoryId ];
        }

        return acc;
      },
      {});

  const newSpaceIDs = Object.keys(
    newInventoryLocations.reduce(
      (acc, cur) => ({
        ...acc,
        [cur.spaceId]: true,
      }),
      {}
    )
  )

  const newInventoryLocationsCache = newInventoryLocations.reduce(
    (acc: Record<ID, IInventoryLocation[]>, cur: IInventoryLocation): Record<ID, IInventoryLocation[]> => {
      const record = acc[cur.inventoryId]
      if (record) {
        record.push(cur)
      } else {
        Object.assign(
          acc,
          { [cur.inventoryId]: [cur] }
        )
      }

      return acc
    },
    {}
  )

  setInventoryLocationsLoading(false)
  setInventoryLocations(newInventoryLocations)
  setSpaceIDs(newSpaceIDs)
  inventoryLocationsCache.current = newInventoryLocationsCache
  setInventoryLocationsUUID(v4())
  setInventorySpaceIdMap(mapInventoriesIdWithSpaceId)
}

const UpdateInventoryStitches = ({
  inventoryStitchesLoading,
  inventoryStitchesData,

  spacesLoading,
  spacesData,

  setInventoryStitchesLoading,
  setInventoryStitches,
  inventoryStitchesCache,
  setInventoryStitchesUUID
}: {
  inventoryStitchesLoading: boolean;
  inventoryStitchesData: { inventoryStitch: IInventoryStitch[] };

  spacesLoading: boolean;
  spacesData: { space: ISpace[] };

  setInventoryStitchesLoading: (newVal: boolean) => void;
  setInventoryStitches: (newVal: IInventoryStitch[]) => void;
  inventoryStitchesCache: { current: Record<ID, IInventoryStitch[]> };
  setInventoryStitchesUUID: (newVal: string) => void;
}): void => {
  if (inventoryStitchesLoading || spacesLoading) {
    setInventoryStitchesLoading(true)
    setInventoryStitchesUUID(v4())
    return
  }
  
  if (
    !inventoryStitchesData ||
    !spacesData
    ) {
      setInventoryStitchesLoading(false)
      setInventoryStitchesUUID(v4())
      return
    }
    
  const newInventoryStitches = inventoryStitchesData.inventoryStitch
  const newSpaces = spacesData.space
  newInventoryStitches.forEach((is): void => {
    Object.assign(
      is,
      { space: [newSpaces.find((s): boolean => s.id == is.spaceId)] }
    )
  })

  const newInventoryStitchesCache = newInventoryStitches.reduce(
    (acc: Record<ID, IInventoryStitch[]>, cur: IInventoryStitch): Record<ID, IInventoryStitch[]> => {
      const record = acc[cur.inventoryId]
      if (record) {
        record.push(cur)
      } else {
        Object.assign(
          acc,
          { [cur.inventoryId]: [cur] }
        )
      }

      return acc
    },
    {}
  )

  setInventoryStitchesLoading(false)
  setInventoryStitches(newInventoryStitches)
  inventoryStitchesCache.current = newInventoryStitchesCache
  setInventoryStitchesUUID(v4())
}

const UpdateEnvironmentalZones = ({
  environmentalZonesLoading,
  environmentalZonesData,

  setEnvironmentalZonesLoading,
  setEnvironmentalZones,
  setEnvironmentalZoneIDs,
  environmentalZonesCache,
  setEnvironmentalZonesUUID
}: {
  environmentalZonesLoading: boolean;
  environmentalZonesData: { environmentalZone: IEnvironmentalZone[] };

  setEnvironmentalZonesLoading: (newVal: boolean) => void;
  setEnvironmentalZones: (newVal: IEnvironmentalZone[]) => void;
  setEnvironmentalZoneIDs: (newVal: ID[]) => void;
  environmentalZonesCache: { current: Record<ID, IEnvironmentalZone[]> };
  setEnvironmentalZonesUUID: (newVal: string) => void;
}): void => {
  if (environmentalZonesLoading || !environmentalZonesData) {
    setEnvironmentalZonesLoading(true)
    setEnvironmentalZonesUUID(v4())
    return
  }

  const newEnvironmentalZones = environmentalZonesData.environmentalZone
  const newEnvironmentalZoneIDs = Object.keys(newEnvironmentalZones.reduce(
    (acc: Record<ID, boolean>, cur: IEnvironmentalZone): Record<ID, boolean> =>
      Object.assign(
        acc,
        { [cur.id]: true }
      ),
    {}
  ))

  const newEnvironmentalZonesCache = newEnvironmentalZones.reduce(
    (acc: Record<ID, IEnvironmentalZone[]>, cur: IEnvironmentalZone) => {
      const record = acc[cur.spaceId]
      if (record) {
        record.push(cur)
      } else {
        Object.assign(
          acc,
          { [cur.spaceId]: [cur] }
        )
      }

      return acc
    },
    {}
  )

  setEnvironmentalZonesLoading(false)
  setEnvironmentalZones(newEnvironmentalZones)
  setEnvironmentalZoneIDs(newEnvironmentalZoneIDs)
  environmentalZonesCache.current = newEnvironmentalZonesCache
  setEnvironmentalZonesUUID(v4())
}

interface IUpdateSensorRecords {
  co2: boolean;
  sensorRecordsLoading: boolean;
  sensorRecordsData: ISensorRecordData['data'];

  setSensorRecordsLoading: (newVal: boolean) => void;
  setSensorRecords: (newVal: any[]) => void;
  sensorRecordsCache: { current: Record<ID, any[]> };
  setSensorRecordsUUID: (newVal: string) => void;
}

const UpdateSensorRecords = ({
  co2,
  sensorRecordsLoading,
  sensorRecordsData,

  setSensorRecordsLoading,
  setSensorRecords,
  sensorRecordsCache,
  setSensorRecordsUUID
}: IUpdateSensorRecords): void => {
  if (sensorRecordsLoading || !sensorRecordsData) {
    setSensorRecordsLoading(true)
    setSensorRecordsUUID(v4())
    return
  }

  let newSensorRecords = co2
    ? sensorRecordsData.co2SensorRecord
    : sensorRecordsData.sensorRecord

  newSensorRecords = uniqBy(newSensorRecords, (s): ID => s.id)

  const newSensorRecordsCache = newSensorRecords.reduce(
    (acc: Record<ID, any[]>, cur: any): Record<ID, any[]> => {
      const record = acc[cur.environmentalZoneId]
      if (record) {
        record.push(cur)
      } else {
        Object.assign(
          acc,
          { [cur.environmentalZoneId]: [cur] }
        )
      }

      return acc
    },
    {}
  )

  setSensorRecordsLoading(false)
  setSensorRecords(newSensorRecords)
  sensorRecordsCache.current = newSensorRecordsCache
  setSensorRecordsUUID(v4())
}

const UpdateInventoryChartsCache = ({
  environmentalZonesCache,
  sensorRecordsCache,
  co2SensorRecordsCache,
  crop,
  inventories,
  inventoryLocationsCache,

  inventoryChartsCache,
  setInventoryChartsUUID,
  setInventoryChartsLoading
}: {
  environmentalZonesCache: Record<ID, IEnvironmentalZone[]>;
  sensorRecordsCache: Record<ID, ISensorRecord[]>;
  co2SensorRecordsCache: Record<ID, ICO2SensorRecord[]>;
  crop: ICrop;
  inventories: IInventory[];
  inventoryLocationsCache: Record<ID, IInventoryLocation[]>;

  inventoryChartsCache: { current: Record<ID, any> };
  setInventoryChartsUUID: (newVal: string) => void;
  setInventoryChartsLoading: (val: boolean) =>void;
}): void => {
  if(inventoryLocationsCache || environmentalZonesCache){
      setInventoryChartsLoading(true)
    }
  if (environmentalZonesCache &&
      sensorRecordsCache &&
      co2SensorRecordsCache &&
      inventories &&
      inventoryLocationsCache) {

    const customer = GetItem('customer', EStorage.EphemeralStorage)
    const temperatureMetric = customer.temperatureMetric
    const isFahrenheit = temperatureMetric === 'fahrenheit'

    const inventoryContinua = inventories.reduce(
      (inventoryContinua: Record<ID, any[]>, inventory: IInventory) => {
        const inventoryLocations = (inventoryLocationsCache[inventory.id] || [])
          .filter((i): boolean => !!i.seenOn)

        const inventoryContinuum = inventoryLocations.reduce(
          (inventoryContinuum: any[], inventoryLocation: IInventoryLocation): any[] => {
            const prevInventoryPosition = inventoryContinuum[inventoryContinuum.length - 1]
            if (prevInventoryPosition?.spaceId == inventoryLocation.spaceId) {
              const prevStartDate = createDate(prevInventoryPosition.startDate).getTime()
              const curStartDate = createDate(inventoryLocation.seenOn).getTime()

              if (curStartDate < prevStartDate) {
                prevInventoryPosition.startDate = inventoryLocation.seenOn
              }
            } else {
              const curInventoryPosition = {
                inventoryId: inventory.id,
                spaceId: inventoryLocation.spaceId,
                startDate: inventoryLocation.seenOn,
                endDate: inventoryLocation.seenOn
              }

              inventoryContinuum.push(curInventoryPosition)
            }

            return inventoryContinuum
          },
          []
        )

        Object.assign(
          inventoryContinua,
          { [inventory.id]: inventoryContinuum }
        )

        return inventoryContinua
      },
      {}
    )

    const newInventoryChartsCache = inventories.reduce(
      (newInventoryChartsCache: Record<ID, any[]>, inventory: IInventory): Record<ID, any[]> => {
        const inventoryContinuum = inventoryContinua[inventory.id]
        const inventoryCharts = inventoryContinuum.reduce(
          (inventoryCharts: any, inventoryPosition: any): any => {
            const environmentalZones = environmentalZonesCache[inventoryPosition.spaceId]
            const startDate = createDate(inventoryPosition.startDate).getTime()
            const endDate = createDate(inventoryPosition.endDate).getTime()

            const sensorRecords = environmentalZones?.reduce(
              (sensorRecords: ISensorRecord[], environmentalZone: IEnvironmentalZone): ISensorRecord[] => {
                const matchedSensorRecords = sensorRecordsCache[environmentalZone.id]
                  ? sensorRecordsCache[environmentalZone.id]
                    .filter((s): boolean => {
                      const createdOn = createDate(s.createdOn).getTime()
                      return createdOn >= startDate &&
                        createdOn <= endDate
                    })
                  : []

                return [
                  ...sensorRecords,
                  ...matchedSensorRecords
                ]
              },
              []
            )

            const co2SensorRecords = environmentalZones?.reduce(
              (co2SensorRecords: ISensorRecord[], environmentalZone: IEnvironmentalZone): ICO2SensorRecord[] => {
                const matchedCO2SensorRecords = co2SensorRecordsCache[environmentalZone.id]
                  ? co2SensorRecordsCache[environmentalZone.id]
                    .filter((s): boolean => {
                      const createdOn = createDate(s.createdOn).getTime()
                      return createdOn >= startDate &&
                        createdOn <= endDate
                    })
                  : []

                return [
                  ...co2SensorRecords,
                  ...matchedCO2SensorRecords
                ]
              },
              []
            )

            let allSensorRecords = [...(sensorRecords ?? []), ...(co2SensorRecords ?? [])]

            allSensorRecords = allSensorRecords?.sort((a: ISensorRecord | ICO2SensorRecord, b: ISensorRecord | ICO2SensorRecord): number => {
              const aCreatedOn = createDate(a.createdOn).getTime()
              const bCreatedOn = createDate(b.createdOn).getTime()

              return aCreatedOn - bCreatedOn
            })

            allSensorRecords?.forEach((sensorRecord: ISensorRecord | ICO2SensorRecord): void => {
              Object.entries(sensorRecord).forEach(([key, val]): void => {
                if (key !== '__typename' &&
                    key !== 'id' &&
                    key !== 'createdOn' &&
                    key !== 'environmentalZoneId') {
                  const createdOn = createDate(sensorRecord.createdOn).getTime()
                  const relativeCreatedOn = createdOn - createDate(crop.startDate).getTime()
                  const value = key === 'temperature' && isFahrenheit
                    ? (val * 9 / 5) + 32
                    : val

                  const _ = {
                    createdOn,
                    relativeCreatedOn,
                    value
                  }

                  if (inventoryCharts[key]) {
                    inventoryCharts[key].push(_)
                  } else {
                    Object.assign(
                      inventoryCharts,
                      { [key]: [_] }
                    )
                  }
                }
              })
            })

            return inventoryCharts
          },
          {}
        )

        let cropEndDate: Date | number = createDate(crop.endDate)
        cropEndDate = startOfDay(cropEndDate)
        cropEndDate = addHours(cropEndDate, 12)
        cropEndDate = cropEndDate.getTime()

        // Bud Detections
        if (inventory?.budDetection?.[0]?.data) {
          inventoryCharts.budDetection = inventory.budDetection[0].data.map((c) => {
            let createdOn: Date | number = createDate(c[0])
            createdOn = startOfDay(createdOn)
            createdOn = addHours(createdOn, 12)
            createdOn = createdOn.getTime()
            const relativeCreatedOn = createdOn - createDate(crop.startDate).getTime()

            const _ = {
              createdOn,
              relativeCreatedOn,
              value: c[1]
            }

            return _
          })
            .sort((a, b) => a.createdOn - b.createdOn)
            .filter((a) => a.createdOn <= cropEndDate)
        }

        if (Number(customer.id) === 1210) {
          // Bud Density
          if (inventory?.budDetection?.[0]?.data) {
            inventoryCharts.budDetectionDensity = inventory.budDetection[0].data.map((c) => {
              let createdOn: Date | number = createDate(c[0])
              createdOn = startOfDay(createdOn)
              createdOn = addHours(createdOn, 12)
              createdOn = createdOn.getTime()
              const relativeCreatedOn = createdOn - createDate(crop.startDate).getTime()

              const _ = {
                createdOn,
                relativeCreatedOn,
                value: c[1] / 109.32
              }

              return _
            })
              .sort((a, b) => a.createdOn - b.createdOn)
              .filter((a) => a.createdOn <= cropEndDate)
          }
        }

        // Canopy Coverage
        if (inventory?.canopyCoverage?.[0]?.data) {
          inventoryCharts.canopyCoverage = inventory.canopyCoverage[0].data.map((c) => {
            let createdOn: Date | number = createDate(c[0])
            createdOn = startOfDay(createdOn)
            createdOn = addHours(createdOn, 12)
            createdOn = createdOn.getTime()
            const relativeCreatedOn = createdOn - createDate(crop.startDate).getTime()

            const _ = {
              createdOn,
              relativeCreatedOn,
              value: c[1]
            }

            return _
          })
            .sort((a, b) => a.createdOn - b.createdOn)
            .filter((a) => a.createdOn <= cropEndDate)
        }

        // Canopy Coverage Daily
        if (inventory?.canopyCoverage?.[0]?.delta) {
          inventoryCharts.canopyCoverageDaily = inventory.canopyCoverage[0].delta.map((c) => {
            let createdOn: Date | number = createDate(c[0])
            createdOn = startOfDay(createdOn)
            createdOn = addHours(createdOn, 12)
            createdOn = createdOn.getTime()
            const relativeCreatedOn = createdOn - createDate(crop.startDate).getTime()

            const _ = {
              createdOn,
              relativeCreatedOn,
              value: c[1]
            }

            return _
          })
            .sort((a: {createdOn: number}, b: {createdOn: number}) => a.createdOn - b.createdOn)
            .filter((a: {createdOn: number}) => a.createdOn <= cropEndDate)
        }

        // Flower Coverage
        if (inventory?.flowerCoverage?.[0]?.data) {
          inventoryCharts.flowerCoverage = inventory.flowerCoverage[0].data.map((c) => {
            let createdOn: Date | number = createDate(c[0])
            createdOn = startOfDay(createdOn)
            createdOn = addHours(createdOn, 12)
            createdOn = createdOn.getTime()
            const relativeCreatedOn = createdOn - createDate(crop.startDate).getTime()

            const _ = {
              createdOn,
              relativeCreatedOn,
              value: c[1]
            }

            return _
          })
          .sort((a, b) => a.createdOn - b.createdOn)
          .filter((a) => a.createdOn <= cropEndDate)
        }

        // Flower Coverage Daily
        if (inventory?.flowerCoverage?.[0]?.delta) {
          inventoryCharts.flowerCoverageDaily = inventory.flowerCoverage[0].delta.map((c) => {
            let createdOn: Date | number = createDate(c[0])
            createdOn = startOfDay(createdOn)
            createdOn = addHours(createdOn, 12)
            createdOn = createdOn.getTime()
            const relativeCreatedOn = createdOn - createDate(crop.startDate).getTime()

            const _ = {
              createdOn,
              relativeCreatedOn,
              value: c[1]
            }

            return _
          })
          .sort((a, b) => a.createdOn - b.createdOn)
          .filter((a) => a.createdOn <= cropEndDate)
        }

        // Germination Count
        if (inventory?.germinationRate?.[0]?.count) {
          inventoryCharts.germinationRate = [{
            value: inventory.germinationRate[0].count
          }]
        }

        Object.assign(
          newInventoryChartsCache,
          { [inventory.id]: inventoryCharts }
        )
        
        return newInventoryChartsCache
      },
      {}
    )

    const newInventoryChartsUUID = v4()

    inventoryChartsCache.current = newInventoryChartsCache

    setInventoryChartsLoading(false)
    setInventoryChartsUUID(newInventoryChartsUUID)
  }
}

const CallOnLoaded = ({
  crop,
  inventories,
  inventoryStitchesCache,
  inventoryChartsCache,
  calledOnLoaded,

  onLoaded
}: {
  crop: ICrop;
  inventories: IInventory[];
  inventoryStitchesCache: Record<ID, IInventoryStitch[]>;
  inventoryChartsCache: Record<ID, any>;
  calledOnLoaded: { current: boolean };

  onLoaded?: (
    crop: ICrop,
    inventories: IInventory[],
    inventoryStitchesCache: Record<ID, IInventoryStitch[]>,
    inventoryChartsCache: Record<ID, any>
  ) => void;
}): void => {
  if (
    crop &&
    inventories &&
    inventoryStitchesCache &&
    inventoryChartsCache &&
    !calledOnLoaded.current &&
    onLoaded
  ) {
    calledOnLoaded.current = true
    onLoaded(
      crop,
      inventories,
      inventoryStitchesCache,
      inventoryChartsCache
    )
  }
}

export {
  UpdateSpaces,
  UpdateCrop,
  UpdateInventories,
  UpdateInventoryLocations,
  UpdateInventoryStitches,
  UpdateEnvironmentalZones,
  UpdateSensorRecords,
  UpdateInventoryChartsCache,
  CallOnLoaded
}

/**
 * @author Miras Absar <mabsar@iunu.com>
 */

import App from './app'

import React from 'react'
import ReactGA from 'react-ga'
import { Router } from 'react-router-dom'
import { render } from 'react-dom'

import history from 'react-lib/history'

import config from './config'

// Wayne -> McGinnis Migration
import Has from 'utils/has'
import JWTDecode from 'jwt-decode'
import { clearStorage } from 'utils/storage'
import 'pepjs'

declare global {
  var zE: any;
  var zESettings: any;
  var heap: any;
}

if (Has(localStorage.getItem('customerId'))) {
  const refreshToken = localStorage.getItem('refreshToken')
  const decodedRefreshToken = JWTDecode<{userId: string}>(refreshToken)
  const customerID = localStorage.getItem('customerId')
  const userID = decodedRefreshToken.userId

  localStorage.clear()

  localStorage.setItem('refreshToken', refreshToken)
  localStorage.setItem('userID', userID)
  localStorage.setItem('customerID', customerID)
} else if (localStorage.getItem('customerId') === 'null') {
  clearStorage();
}

if (config.googleAnalyticsId) {
  ReactGA.initialize(config.googleAnalyticsId)
  ReactGA.pageview(window.location.pathname + window.location.search)
}

if (config.heapId) {
  heap.load(config.heapId)
}

const isProduction = process.env.NODE_ENV === 'production'
const isHTTPS = process.env.HTTPS
const isActuallyHTTPS = location.href.startsWith('https://')

// Redirect to HTTPS
if ((isProduction || isHTTPS) && !isActuallyHTTPS) {
  location.href = location.href.replace('http://', 'https://')
}

// Invalidate Cache
fetch(location.origin, { cache: 'no-cache' }).then()

const supportsWebp =
  document
    .createElement('canvas')
    .toDataURL('image/webp')
    .indexOf('data:image/webp') === 0

window.supportsWebp = supportsWebp

const Container = document.getElementById('Container')
render(
  <Router history={history}>
    <App />
  </Router>,
  Container
)

/**
 * @author Miras Absar <mabsar@iunu.com>
 * @file ...
 */

import { gql } from '@apollo/client'

const QueryInventory = gql`
  query($serial: String) {
    locator(serial: $serial) {
      id
      serial

      inventoryLocator {
        id

        inventory {
          id
          code

          crop {
            id

            startDate
            transplantDate
            endDate

            code
            name
            rating

            speciesCode
            speciesName
            speciesIcon

            varietyCode
            varietyName
            varietyColor
            varietyPattern
          }
        }
      }
    }
  }
`

export { QueryInventory }

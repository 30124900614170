import { html, define, render } from 'hybrids';
import { format } from 'date-fns';
import { getSearchParams, setSearchParams } from 'utils/searchParams';
import { createDate } from 'utils/dates';

// Types
import IAreacam from 'graphql-lib/interfaces/IAreacam';

import CCTV from '@fortawesome/fontawesome-pro/svgs/regular/cctv.svg';
import Times from '@fortawesome/fontawesome-pro/svgs/regular/times.svg';
import { IOverlayRender } from '../types';
const $toggle = (host: HTMLElement & { open: boolean }, event: Event): void => {
  event.preventDefault()
  event.stopPropagation()

  host.open = !host.open
}

const $click = (host: HTMLElement & { areacam: IAreacam }, ev: Event): void => {
  const newParams = Object.assign(
    {},
    getSearchParams()
  )

  newParams.viewSensorTiles = undefined
  newParams.viewAreacamTiles = true
  newParams.viewAreacam = host.areacam.id

  setSearchParams(newParams)
}

const AreacamOverlay: IOverlayRender<{open: boolean, areacam: IAreacam}> = {
  areacam: null,
  open: false,

  render: render(({
    open,
    areacam
  }: {open: boolean, areacam: IAreacam}) => {
    const className = open
      ? ['AreacamOverlay', 'Raised', 'Animated', 'Open']
      : ['AreacamOverlay', 'Raised', 'Animated']

    const icon = open
      ? Times
      : CCTV

    const hasImage = areacam.areaImageRecord.length > 0

    if (hasImage) {
      const thumbnail = areacam.areaImageRecord[0].thumbUrl
      const time = format(createDate(areacam.areaImageRecord[0].createdOn), 'P p')

      return html`
        <div class='${className}'>
          <div class='Head' ondblclick=${$toggle}>
            <div
              class='Icon'
              innerHTML='${icon}'
              onclick=${$toggle}
            >
            </div>

            <div class='Time'>${time}</div>
          </div>

          <div class="Body">
            <img
              src='${thumbnail}'
              onclick=${$click}
            />
          </div>
        </div>
      `
    } else {
      return html`
        <div class='${className}'>
          <div class='Head'>
            <div class='Icon' innerHTML='${icon}'></div>
          </div>
        </div>
      `
    }
  }, { shadowRoot: false })
}

define('areacam-overlay', AreacamOverlay)

/**
 * @author Miras Absar <mabsar@iunu.com>
 */

import { gql } from '@apollo/client'

const QueryCrop = gql`
  query($id: ID) {
    crop(id: $id) {
      id
      startDate
      endDate
    }
  }
`

const QueryInventories = gql`
  query($cropIds: [ID]) {
    inventory(cropIds: $cropIds) {
      id
      createdOn
      cropId
      zoneId

      code
    }
  }
`

const QueryCropTasks = gql`
  query($cropIds: [ID]) {
    task(cropIds: $cropIds) {
      id
    }
  }
`

const QueryInventoryTasks = gql`
  query($inventoryIds: [ID]) {
    task(inventoryIds: $inventoryIds) {
      id
    }
  }
`

export {
  QueryCrop,
  QueryInventories,
  QueryCropTasks,
  QueryInventoryTasks
}

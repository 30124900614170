/**
 * @author Miras Absar <mabsar@iunu.com>
 */

const UpdateClass = ({
  target,

  mobile,
  tablet,
  desktop,

  width,
  height
}: {
  target: HTMLElement;

  mobile: boolean;
  tablet: boolean;
  desktop: boolean;

  width: number;
  height: number;
}): void => {
  if (width) {
    const classList = target.classList
    const style = target.style

    classList.remove('Mobile')
    classList.remove('Tablet')
    classList.remove('Desktop')

    if (mobile) classList.add('Mobile')
    if (tablet) classList.add('Tablet')
    if (desktop) classList.add('Desktop')

    style.setProperty('--width', width + 'px')
    style.setProperty('--height', height + 'px')
  }
}

export { UpdateClass }

/**
 * @author Miras Absar <mabsar@iunu.com>
 */

enum EInputStatus {
  Uninitialized = 0,
  None = 1,
  OK = 2,
  Warning = 3,
  Error = 4
}

export default EInputStatus

/**
 * @author Miras Absar <mabsar@iunu.com>
 * @file ...
 */

// Types
import IInventory from 'graphql-lib/interfaces/IInventory'

// React
import React, {
  useCallback,
  useState
} from 'react'

// React Libs
import { useAsyncIter } from 'react-lib/use-iter'

// GraphQL Lib
import { RequiredTag } from 'graphql-lib/validators/tag'

// UI Lib
import { useInput } from 'ui-lib/hooks/use-input'
import { RequiredText } from 'ui-lib/validators/text'
import CropItem from 'ui-lib/components/crop-item'
import Button from 'ui-lib/components/button'
import OutlinedInput from 'ui-lib/components/input/outlined-input'
import ScannerInput from 'ui-lib/components/scanner-input'

// Effects
import { UpdateInventory } from './effects'

interface IInventoryCloseoutCard {
  outlined?: boolean;
  resting?: boolean;
  raised?: boolean;
  animated?: boolean;
}

const InventoryCloseoutCard = ({
  outlined,
  resting,
  raised,
  animated
}: IInventoryCloseoutCard): JSX.Element => {
  // State /////////////////////////////////////////////////////////////////////

  const tagState = useInput<string>('', RequiredTag)
  const weightState = useInput<string>('', RequiredText)

  const [, tagStatus, tagUUID,, resetTag] = tagState
  const [, weightStatus, weightUUID,, resetWeight] = weightState

  const [inventory, setInventory] = useState<IInventory>()
  const [inventoryError, setInventoryError] = useState<Error>()
  const [inventoryUUID, setInventoryUUID] = useState<string>()

  // Effects ///////////////////////////////////////////////////////////////////

  useAsyncIter(
    UpdateInventory,

    { tagState,

      setInventory,
      setInventoryError,
      setInventoryUUID },

    [ tagStatus?.status,
      tagUUID ]
  )

  // Callbacks /////////////////////////////////////////////////////////////////

  const $save = useCallback(
    (done?: boolean): void => {
      // TODO: Implement API call

      resetTag('')
      resetWeight('')

      setInventory(undefined)
      setInventoryError(undefined)
      setInventoryUUID(undefined)

      if (done) {
        // TODO: Implement navigation call
      }
    },

    [ tagStatus?.status,
      weightStatus?.status,

      tagUUID,
      weightUUID,
      inventoryUUID ]
  )

  // Render ////////////////////////////////////////////////////////////////////

  let className = 'Theme__Light Card InventoryCloseoutCard'
  if (outlined) className += ' Outlined'
  if (resting) className += ' Resting'
  if (raised) className += ' Raised'
  if (animated) className += ' Animated'

  let head = (
    <div className='HeadPlaceholder' />
  )

  if (inventory) {
    head = (
      <CropItem
        crop={inventory.crop[0]}
      />
    )
  }

  return (
    <article className={className}>
      {head}

      <ScannerInput
        state={tagState}
      />

      <div className='Body'>
        <OutlinedInput
          animated
          label='Tag'
          inputProps={{ type: 'text', maxLength: 6 }}
          state={tagState}
        />

        <OutlinedInput
          animated
          label='Weight'
          inputProps={{ type: 'number' }}
          state={weightState}
        />
      </div>

      <div className='Actions'>
        <Button
          animated={animated}
          outlined
          onClick={() => $save()}
        >
          Next
        </Button>

        <Button
          animated={animated}
          outlined
          onClick={() => $save(true)}
        >
          Done
        </Button>
      </div>
    </article>
  )
}

export default InventoryCloseoutCard

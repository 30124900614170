/**
 * @author Miras Absar <mabsar@iunu.com>
 */

// Types
import EEntity from 'map-view/enums/entity'
import EDiff from 'map-view/enums/diff'

// Utils
import Has from 'utils/has'
import { v4 } from 'uuid'
import { isEmpty } from 'lodash'
import { IListRef } from '../task-issues-list/effects'
import IDiff from 'map-view/interfaces/diff'
import IEntity from 'map-view/interfaces/entity'
import ISpace from 'graphql-lib/interfaces/ISpace'

const UpdateVisibleSpaces = ({
  visibleEntities,
  spaces,
  setVisibleSpaces,
  setVisibleSpacesUUID
}: {
  visibleEntities: Array<IEntity>
  spaces: Array<ISpace>
  setVisibleSpaces: (value: Array<ISpace>) => void
  setVisibleSpacesUUID: (value: string) => void
}): void => {
  if (
    !visibleEntities ||
    !spaces
  ) {
    return
  }

  const newVisibleSpaces = spaces
    .filter(space => !!visibleEntities
      .find(entity =>
        entity.type === EEntity.Space &&
        entity.id === space.id))

  setVisibleSpaces(newVisibleSpaces)
  setVisibleSpacesUUID(v4())
}

const UpdateSearchedSpaces = ({
  spaces,
  visibleSpaces,
  search,
  visible,
  setSearchedSpaces,
  setSearchedSpacesUUID
}: {
  spaces: Array<ISpace>
  visibleSpaces: Array<ISpace>
  search: string
  visible: boolean
  setSearchedSpaces: (value: Array<ISpace>) => void
  setSearchedSpacesUUID: (value: string) => void
}): void => {
  if (
    !spaces ||
    !visibleSpaces
  ) {
    return
  }
  // Only showing spaces on the map with coordinate
  let newSearchedSpaces = (visible ? visibleSpaces : spaces)
    .filter(({ dcs, geojson }) => !isEmpty(geojson) || (dcs !== 'null' && !isEmpty(dcs)))
  // dcs (Differential Coordinate System))

  if (search?.trim()) {
    const searchLC = search.toLocaleLowerCase()
    newSearchedSpaces = newSearchedSpaces
      .filter(space =>
        space.code?.toLocaleLowerCase().includes(searchLC) ||
        space.name?.toLocaleLowerCase().includes(searchLC) ||
        space.nickname?.toLocaleLowerCase().includes(searchLC))
  }

  setSearchedSpaces(newSearchedSpaces)
  setSearchedSpacesUUID(v4())
}

const UpdateScroll = ({
  selectedEntitiesDiff,
  listRef
}: {
  selectedEntitiesDiff: Array<IDiff<IEntity>>
  listRef: IListRef
}): void => {
  if (
    !selectedEntitiesDiff ||
    !listRef
  ) {
    return
  }

  const diff = selectedEntitiesDiff
    .find(diff =>
      diff.type === EDiff.Add &&
      diff.value.type === EEntity.Space)

  if (!diff) {
    return
  }

  const child = Array.from(listRef.children)
    .find(child =>
      child.dataset.type === diff.value.type &&
      child.dataset.id === diff.value.id)

  if (!child) {
    return
  }

  child.scrollIntoView({
    behavior: 'smooth',
    block: 'start',
    inline: 'nearest'
  })
}

export {
  UpdateVisibleSpaces,
  UpdateSearchedSpaces,
  UpdateScroll
}

// Types
import ICustomer from 'graphql-lib/interfaces/ICustomer'
import ID from 'graphql-lib/interfaces/ID';

// React
import {
  useRef,
  useState
} from 'react'

// React Libs
import {
  useIter,
  useAsyncIter
} from 'react-lib/use-iter'

// Effects
import {
  Init,
  QueryCustomer
} from './effects'

const useCustomer = (id: ID, columns: string[]): {
  loading: boolean;
  error: Error;
  customer: ICustomer;
  uuid: string;
  update?: (update: Partial<ICustomer>) => void;
} => {
  // State /////////////////////////////////////////////////////////////////////

  const hookID = useRef<string>()
  const [loading, setLoading] = useState<boolean>()
  const [error, setError] = useState<Error>()
  const [customer, setCustomer] = useState<ICustomer>()
  const [uuid, setUUID] = useState<string>()

  // Effects ///////////////////////////////////////////////////////////////////

  useIter(
    Init,
    { hookID },
    [id]
  )

  useAsyncIter(
    QueryCustomer,

    { id,
      columns,

      setLoading,
      setError,
      setCustomer,
      setUUID },

    [ id,
      ...columns ]
  )

  // Return ////////////////////////////////////////////////////////////////////

  return {
    loading,
    error,
    customer,
    uuid
  }
}

export default useCustomer

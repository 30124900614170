// Libs
import { pickBy } from 'lodash';
import { isNil, replaceField } from 'utils/ts-utils';
import GoTo from 'react-lib/go-to';

// React
import React, {
  useContext,
  useEffect,
  useRef,
  useState
} from 'react';

// GraphQL
import { gql, useQuery, useMutation } from '@apollo/client';

// UI Lib
import Input from 'ui-lib/components/Input/Input';
import SearchSelect from 'ui-lib/components/search-select';
// import Chips from 'ui-lib/components/chips'
import Checkbox from 'ui-lib/components/Checkbox/Checkbox';
import Button from 'ui-lib/components/button';

// UI Cores
import {
  TextInputCore as TextCore,
  RequiredTextInputCore as RequiredTextCore
} from 'ui-lib/cores/text-input-core';
import { DateInputCore as DateCore } from 'ui-lib/cores/date-input-core';
import FlagCore from 'graphql-lib/cores/search-select/flag';
import CropCore from 'graphql-lib/cores/search-select/crop';
import SpaceCore from 'graphql-lib/cores/search-select/space';
import CustomerUserCore from 'graphql-lib/cores/search-select/customer-user';

// UI Facades
import TextFacade from 'ui-lib/facades/text-input';
import DateTimeFacade from 'ui-lib/facades/datetime-input';
import SelectFacade from 'ui-lib/facades/select';
import FlagFacade from 'graphql-lib//facades/search-select/flag-item';
import SpaceFacade from 'graphql-lib/facades/search-select/space-item';
import CropFacade from 'graphql-lib/facades/search-select/crop-item';
import CustomerUserFacade from 'graphql-lib/facades/search-select/customer-user';

// Contexts
import Mobius, { IFunctionTask } from 'map-view/contexts/mobius';
import { TasksIssue, ViewTask } from 'tasks/utils/routes';

import ITaskIssue from 'graphql-lib/interfaces/ITaskIssue';
import EEntity from 'graphql-lib/enums/entity';
import IInventory from 'graphql-lib/interfaces/IInventory';
import { ICustomerTaskCategory } from 'graphql-lib/interfaces/IFlag';
import ISpace from 'graphql-lib/interfaces/ISpace';
import ICrop from 'graphql-lib/interfaces/ICrop';
import IUser from 'graphql-lib/interfaces/IUser';
import IInputStatus from 'ui-lib/interfaces/IInputStatus';
import { ITab } from 'ui-lib/components/tab';
import ID from 'graphql-lib/interfaces/ID';

const DateFormat = 'M/d/yy';

const QueryTaskIssue = gql`
  query($id: ID) {
    taskIssue(id: $id) {
      id
      createdAt
      updatedAt

      description
      priority
      notes
      taskId
      rejectedAt
      convertedToTaskAt
      task{
        complete
        taskAssignee {
          id
          updatedOn

          customerUser {
            id

            name
            email
            username
          }
        }
      }

      customerTaskCategory {
        id

        icon
        color
        title
      }
      
      locationProfile {
        id
        updatedOn

        spaceId
        cropId
        inventoryId
      }

      createdByUser {
        id

        name
        email
        username
      }
      createdBy
    }
  }
`

const QuerySpace = gql`
  query($id: ID) {
    space(id: $id) {
      id

      code
      name
      nickname
    }
  }
`

const QueryCrop = gql`
  query($id: ID) {
    crop(id: $id) {
      id

      code
      name
      species
      variety
      enabled
    }
  }
`

const QueryInventory = gql`
  query($id: ID) {
    inventory(id: $id) {
      id
      code
      cropCode
      zoneId
    }
  }
`

const UpdateTaskIssue = gql`
  mutation(
    $id: ID!,
    $customerTaskCategoryId: Int,
    $priority: Int,
    $convertToTask: Boolean,
    $userId: Int,
    $rejectIssue: Boolean
  ) {
    TaskIssue {
      update(input: {
        id: $id,
        customerTaskCategoryId: $customerTaskCategoryId,
        priority: $priority,
        convertToTask: $convertToTask
        userId: $userId,
        rejectIssue: $rejectIssue
      }) {
        id
        updatedAt
        createdAt
        customerId
        locationProfileId
        priority
        convertedToTaskAt
        rejectedAt
        customerTaskCategory {
          id
          createdAt

          icon
          color
          title
          description

          flagCategory {
            id
            createdOn

            name
          }
        }
        
        taskId
        task {
          id
        }

        locationProfile {
          id
          updatedOn
          createdOn
          customerId
            
          spaceId
          cropId
          inventoryId
          containerId

          positionX
          positionY
        }
        createdBy
        createdByUser {
          id

          name
          email
          username
        }
      }
    }
  }
`

export interface IViewTaskIssueCardDetailsProps {
  animated?: boolean;
  id: ID;
  searchParams: any;
  tabs?: ITab[];
  back: () => void;
  updateFlagsViewTask?: (updatedTask: ITaskIssue) => void;
  setTaskOrIssue?: (tab: ITab) => void
  setSearchParams?: (e: any) => void
}

const ViewTaskIssueCardDetails = ({
  animated,
  id,
  back,
  updateFlagsViewTask,
  setTaskOrIssue,
  tabs,
  setSearchParams,
  searchParams
}: IViewTaskIssueCardDetailsProps): JSX.Element => {
  const [taskIssue, setTaskIssue] = useState<ITaskIssue>(undefined)
  const [customerTaskCategory, setCustomerTaskCategory] = useState<ICustomerTaskCategory>()
  const [space, setSpace] = useState<ISpace>()
  const [crop, setCrop] = useState<ICrop>()
  const [inventory, setInventory] = useState<IInventory>()
  const [creator, setCreator] = useState<IUser>()

  const taskIssuePatch = useRef<Partial<ITaskIssue>>({})
  const status = useRef<Partial<IInputStatus & Record<keyof IInputStatus, any>>>()
  const [pristine, setPristine] = useState(false)

  const flagCore = useRef(new FlagCore(true, true))
  const cropCore = useRef(new CropCore(false))
  const spaceCore = useRef(new SpaceCore(true))
  const customerUserCore = useRef(new CustomerUserCore(true))

  const mobiusContext = useContext(Mobius)

  const userID = localStorage.getItem('userID');

  /**
   * Query the task
   */
  const {
    error: taskIssueError,
    data: taskIssueData
  } = useQuery(QueryTaskIssue, {
    variables: { id }
  })

  /**
   * Query the space if the task's been queried
   */
  const {
    error: spaceError,
    data: spaceData
  } = useQuery(QuerySpace, {
    skip: isNil(taskIssue?.locationProfile[0]?.spaceId),
    variables: {
      id: taskIssue?.locationProfile[0]?.spaceId
    }
  })

  /**
   * Query the crop if the task's been queried
   */
  const {
    error: cropError,
    data: cropData
  } = useQuery(QueryCrop, {
    skip: !taskIssue || !taskIssue.locationProfile[0].cropId,
    variables: {
      id: taskIssue
        ? taskIssue.locationProfile[0].cropId
        : undefined
    }
  })

  /**
   * Query the inventory if the task's been queried
   */
  const {
    error: inventoryError,
    data: inventoryData
  } = useQuery(QueryInventory, {
    skip: !taskIssue || !taskIssue.locationProfile[0].inventoryId,
    variables: {
      id: taskIssue
        ? taskIssue.locationProfile[0].inventoryId
        : undefined
    }
  })

  const [
    updateTaskIssue,
    {
      error: updateTaskIssueError,
      data: updateTaskIssueData
    }
  ] = useMutation(UpdateTaskIssue)

  /**
   * If the task's been queried but hasn't been set,
   * set it.
   */
  useEffect((): void => {
    if (taskIssueData) {
      const { taskIssue } = taskIssueData;
      setTaskIssue(taskIssue[0]);
    }
  }, [taskIssueData])

  /**
   * If the task's been queried but the flag hasn't been set,
   * set it.
   */
  useEffect((): void => {
    if (taskIssue?.customerTaskCategory?.length) {
      const { customerTaskCategory: [newCustomerTaskCategory] } = taskIssue
      setCustomerTaskCategory(newCustomerTaskCategory)
    } else {
      setCustomerTaskCategory(undefined)
    }
  }, [taskIssue])
  /**
   * If the task's been queried but the creator hasn't been set,
   * set it.
   */
  useEffect((): void => {
    if (taskIssue?.createdByUser?.length) {
      const { createdByUser: [newCreator] } = taskIssue
      setCreator(newCreator)
    } else {
      setCreator(undefined)
    }
  }, [taskIssue])

  /**
   * If the space's been queried but hasn't been set,
   * set it.
   */
  useEffect((): void => {
    if (spaceData) {
      const { space: [newSpace] } = spaceData
      setSpace(newSpace)
    }
  }, [spaceData])

  /**
   * If the crop's been queried but hasn't been set,
   * set it.
   */
  useEffect((): void => {
    if (cropData) {
      const { crop: [newCrop] } = cropData
      setCrop(newCrop)
    }
  }, [cropData])

  /**
   * If the inventory's been queried but hasn't been set,
   * set it.
   */
  useEffect((): void => {
    if (inventoryData) {
      const { inventory: [newInventory] } = inventoryData
      setInventory(newInventory)
    }
  }, [inventoryData])

  /**
   * Update tasks state on map view's global context
   */
  useEffect((): void => {
    if (updateTaskIssueData) {
      const { TaskIssue: { update: updatedDbTaskIssue } } = updateTaskIssueData

      // Remove undefined properties from updatedDbTask with lodash
      const cleanedDbTaskIssue = pickBy(updatedDbTaskIssue, (propVal) => propVal !== undefined)
      // Only rewrite local task with populated values to avoid undefined properties
      const updatedLocalTaskIssue = { ...taskIssue, ...cleanedDbTaskIssue };

      // Cause TasksList to rerender with updated tasks
      mobiusContext.taskIssues?.setTaskIssues(
        (currentLocalTasksIssue) => {
          const localTaskIssues: Array<ITaskIssue> = currentLocalTasksIssue as Array<ITaskIssue>;
          const updatedTasksIssue = localTaskIssues.map((taskIssue) => {
            if (Number(taskIssue.id) === Number(id)) {
              // Update the local task with the user's changes
              return updatedLocalTaskIssue
            }
            return taskIssue
          })
          return updatedTasksIssue
        }
      )

      // Update flags view with new task if flags view is parent component
      updateFlagsViewTask?.(updatedLocalTaskIssue)

      if (updateTaskIssueData.TaskIssue.update.updatedAt !== taskIssue.updatedAt) {
        const { TaskIssue: { update: updatedDbTaskIssue } } = updateTaskIssueData

        // Remove undefined properties from updatedDbTask with lodash
        const cleanedDbTaskIssue = pickBy(updatedDbTaskIssue, (propVal) => propVal !== undefined)
        // Only rewrite local task with populated values to avoid undefined properties
        const updatedLocalTaskIssue = { ...taskIssue, ...cleanedDbTaskIssue }
        setTaskIssue(updatedLocalTaskIssue)
      }
    }
  }, [updateTaskIssueData])

  const $value = (name: string, newValue: any): void => {
    switch (name) {
      case 'customerTaskCategoryId':
        taskIssuePatch.current[name] = newValue
        break
      case 'priority':
        taskIssuePatch.current[name] = newValue ? 1 : 0
        break
    }

    setPristine(false);
  }

  const $status = (name: keyof IInputStatus, newStatus: IInputStatus): void => {
    status.current[name] = newStatus
  }

  const $save = (): void => {
    const isTaskIssueEdited = Object.values(taskIssuePatch).length > 0;

    const changesToIssueRecord = { convertedToTaskAt: new Date().toISOString() };
    const updatedCustomerTaskCategoryId = taskIssuePatch.current?.customerTaskCategory ? Number(taskIssuePatch.current.customerTaskCategory[0].id) : undefined;
    const updatesToTaskIssueInDb = {
      id,
      priority: taskIssuePatch.current.priority,
      createdBy: parseInt(userID),
      ...(updatedCustomerTaskCategoryId && { customerTaskCategoryId: updatedCustomerTaskCategoryId }),
      ...changesToIssueRecord
    };

    if (isTaskIssueEdited) {
      updateTaskIssue({
        variables: updatesToTaskIssueInDb
      })
    };

    setPristine(true);
  }

  const $transformToTask = async (): Promise<void> => {
    const confirm = window.confirm('Convert to a task?')

    if (confirm) {
      const updatedRecord = await updateTaskIssue({
        variables: {
          id,
          convertToTask: true
        }
      });

      const newTaskId = updatedRecord.data.TaskIssue.update.taskId;
      // At the Task and Issues section, it switches between tabs.
      // At map section it changes the url searchParams and does not use
      // the tab switcher. Depending of the section where the rollup
      // form is, it change the way it displays.
      if (setTaskOrIssue && tabs) {
        GoTo(ViewTask.replace(':id', newTaskId));
      } else if (setSearchParams && searchParams) {
        const buildSelectedEntities = [
          {
            type: EEntity.Task,
            id: newTaskId
          }
        ];
        const newSearchParams = replaceField(searchParams,
          'selectedEntities',
          { selectedEntities: JSON.stringify(buildSelectedEntities), navigationTab: 'Tab.Flags' });
        setSearchParams(newSearchParams);
      }
    }
  }

  const $goToTask = (taskId: ID): void => {
    if (setTaskOrIssue && tabs) {
      const to = ViewTask.replace(':id', String(taskId))
      setTaskOrIssue(tabs[0]);
      GoTo(to);
    } else if (setSearchParams && searchParams) {
      const newSearchParams = { ...searchParams }
      const buildSelectedEntities = [
        {
          type: EEntity.Task,
          id: taskId
        }
      ]
      newSearchParams.selectedEntities = JSON.stringify(buildSelectedEntities);
      newSearchParams.navigationTab = 'Tab.Flags';
      setSearchParams(newSearchParams);
    }
  }

  const $dismiss = (): void => {
    const confirm = window.confirm('Dismiss?')

    if (confirm) {
      updateTaskIssue({
        variables: {
          id,
          rejectIssue: true
        }
      })

      GoTo(TasksIssue)
    }
  }
  if (taskIssue) {
    return (
      <>
        <div className='Body Details'>

          <SearchSelect
            animated={animated}
            label='Type'
            inputFacade={TextFacade}
            selectFacade={SelectFacade}
            itemFacade={FlagFacade}

            name='type'
            initialValue={customerTaskCategory}
            onValueChange={$value}
            onStatusChange={() => $status}
            readOnly={false}
            core={flagCore.current}
          />

          {space && (
            <SearchSelect
              animated={animated}
              label='Location'
              inputFacade={TextFacade}
              selectFacade={SelectFacade}
              itemFacade={SpaceFacade}

              name='location'
              initialValue={space}
              onValueChange={$value}
              onStatusChange={() => $status}
              readOnly
              core={spaceCore.current}
            />
          )}

          {crop && (
            <SearchSelect
              animated={animated}
              label='Crop'
              inputFacade={TextFacade}
              selectFacade={SelectFacade}
              itemFacade={CropFacade}

              name='crop'
              initialValue={crop}
              onValueChange={$value}
              onStatusChange={() => $status}
              readOnly
              core={cropCore.current}
            />
          )}

          {inventory && (
            <Input
              animated={animated}
              label='Inventory'
              facade={TextFacade}

              name='inventory'
              initialValue={`${inventory.cropCode} - ${inventory.code}`}
              onValueChange={$value}
              onStatusChange={() => $status}
              readOnly
              core={TextCore}
            />
          )}

          <SearchSelect
            animated={animated}
            label='Creator'
            inputFacade={TextFacade}
            selectFacade={SelectFacade}
            itemFacade={CustomerUserFacade}

            name='creator'
            initialValue={creator}
            onValueChange={$value}
            onStatusChange={() => $status}
            readOnly
            core={customerUserCore.current}
          />
          <Input
            animated={animated}
            label='Issue Detected'
            facade={DateTimeFacade}

            name='createdAt'
            initialValue={taskIssue.createdAt}
            readOnly
            core={DateCore}
          />
          {taskIssue.convertedToTaskAt && (
            <Input
              animated={animated}
              label='Issue Converted to a Task at'
              facade={DateTimeFacade}

              name='convertedToTaskAt'
              initialValue={taskIssue.convertedToTaskAt}
              readOnly
              core={DateCore}
            />
          )}

          {taskIssue.rejectedAt && (
            <Input
              animated={animated}
              label='Issue Rejected'
              facade={DateTimeFacade}

              name='rejectedAt'
              initialValue={taskIssue.rejectedAt}
              readOnly
              core={DateCore}
            />
          )}
          <div className='Row'>
            <p className='Body1 Secondary'>Urgent</p>
            <div className='Space' />
            <Checkbox
              animated
              name='priority'
              initialValue={taskIssue.priority === 1}
              onInput={$value}
              readOnly={!(isNil(taskIssue.convertedToTaskAt) && isNil(taskIssue.rejectedAt && isNil(taskIssue.taskId)))}
            />
          </div>
        </div>

        <div style={{ flex: '1 0 auto' }} />
        <div className='Actions'>
          {taskIssue.taskId !== null &&
            <>
              <Button
              animated={animated}
              outlined
              onClick={() => $goToTask(taskIssue.taskId)}
              >
                Go to Related Task
              </Button>
            </>
          }
          { isNil(taskIssue.taskId) && isNil(taskIssue.rejectedAt) && !pristine && (
            <>
              <Button
                animated={animated}
                outlined
                onClick={$transformToTask}
              >
                Convert to Task
              </Button>
              <Button
                animated={animated}
                outlined
                onClick={$dismiss}
              >
                Dismiss
              </Button>
              <Button
                animated={animated}
                outlined
                onClick={$save}
              >
                Save
              </Button>
            </>
          )}
        </div>
      </>
    )
  }

  return (
    <div className='Body Details'>
      <div className='Input Loading' />
      <div className='Input Loading' />
      <div className='Input Loading' />
      <div className='Input Loading' />
      <div className='Input Loading' />
      <div className='Input Loading' />
      <div className='Input Loading' />
      <div className='Input Loading' />
      <div className='Input Loading' />
    </div>
  )
}

export default ViewTaskIssueCardDetails

// Types
import EInputStatus from 'ui-lib/enums/input-status'
import IInputStatus from 'ui-lib/interfaces/IInputStatus'

// Libs
import { v4 } from 'uuid'

// Utils
import Validators from './validators'

interface CropCell {
  readOnly: boolean;
  value: string;
  status?: IInputStatus;
  className?: string;
}
type CropCells = CropCell[][];

interface IInitData {
  setData: (newVal: CropCells) => void;
  setDataUUID: (newVal: string) => void;
}

const InitData = ({
  setData,
  setDataUUID
}: IInitData): void => {
  const newData: CropCells = [
    [
      { readOnly: true, value: '' },
      { readOnly: true, value: 'Product Code' },
      { readOnly: true, value: 'Area' },
      { readOnly: true, value: 'Zone' },
      { readOnly: true, value: 'Sub Zone' },
      { readOnly: true, value: 'Name' },
      { readOnly: true, value: 'Code' },
      { readOnly: true, value: 'Container Type' },
      { readOnly: true, value: 'Containers per Group' },
      { readOnly: true, value: 'Start Date' },
      { readOnly: true, value: 'Transplant Date' },
      { readOnly: true, value: 'End Date' },
      { readOnly: true, value: 'Description' }
    ]
  ]

  for (let row = newData.length; row < 101; row++) {
    newData.push([
      { readOnly: true, value: row.toString() },
      { readOnly: false, value: '' },
      { readOnly: false, value: '' },
      { readOnly: false, value: '' },
      { readOnly: false, value: '' },
      { readOnly: false, value: '' },
      { readOnly: false, value: '' },
      { readOnly: false, value: '' },
      { readOnly: false, value: '' },
      { readOnly: false, value: '' },
      { readOnly: false, value: '' },
      { readOnly: false, value: '' },
      { readOnly: false, value: '' }
    ])
  }

  setData(newData)
  setDataUUID(v4())
}

interface IValidateData {
  data: CropCells;

  setData: (newVal: CropCells) => void;
  setStatus: (newVal: EInputStatus) => void;
  setStatusUUID: (newVal: string) => void;
}

const ValidateData = async ({
  data,
  setData,
  setStatus,
  setStatusUUID
}: IValidateData): Promise<void> => {
  if (!data) {
    return
  }

  const newData = [...data]
  let newStatus = EInputStatus.None
  const cellValidators = newData
    .map((row, rowIndex): any[] => {
      const isXAxis = rowIndex === 0
      if (isXAxis) {
        return []
      }

      const isEmptyRow: boolean = row
        .reduce(
          (isEmptyRow: boolean, cell: CropCell, cellIndex: number): boolean => {
            const isYAxis = cellIndex === 0
            if (isYAxis) {
              return true
            }

            return isEmptyRow && /^\s*$/.test(cell.value)
          },
          true
        )

      if (isEmptyRow) {
        return []
      }

      const cellValidators = row
        .map((cell, cellIndex): any => {
          const isYAxis = cellIndex === 0
          if (isYAxis) {
            return null
          }

          const column = newData[0][cellIndex].value
          const validator = Validators[column]

          if (!validator) {
            throw new Error(`No validator for column: ${column}`)
          }

          const cellValidator = async (): Promise<any> => {
            let status
            if ((['Zone', 'Sub Zone', 'Area']).includes(column)) {
              status = await validator(row[2].value, row[3].value, row[4].value)
            } else {
              status = await validator(row[cellIndex].value)
            }

            return {
              rowIndex,
              columnIndex: cellIndex,
              status
            }
          }

          return cellValidator()
        })
        .filter((cellValidator): boolean => !!cellValidator)

      return cellValidators
    })
    .reduce(
      (cellValidators, _cellValidators): any[] => [...cellValidators, ..._cellValidators],
      []
    )

  const validatedCells = await Promise.all(cellValidators);
  validatedCells.forEach((validatedCell): void => {
    const {
      rowIndex,
      columnIndex,
      status: cellStatus
    } = validatedCell

    newData[rowIndex][columnIndex].status = cellStatus
    switch (cellStatus.status) {
      case EInputStatus.Warning:
        newData[rowIndex][columnIndex].className = 'warning'
        break
      case EInputStatus.Error:
        newData[rowIndex][columnIndex].className = 'error'
        break
      default:
        newData[rowIndex][columnIndex].className = ''
        break
    }

    if (newStatus < cellStatus.status) {
      newStatus = cellStatus.status
    }
  })

  setData(newData)
  setStatus(newStatus)
  setStatusUUID(v4())
}

export {
  InitData,
  ValidateData
}

/**
 * How far the user has zoomed in determines when individual 
 * flags/issues should be consolidated into rollups on the map.  
 * Units are set in meters per pixel.
 */
enum EOverlayThresholds {
  ZoomedOut = 3 / 35,
  ZoomedIn = 3 / 200
}

export default EOverlayThresholds
/**
 * @author Miras Absar <mabsar@iunu.com>
 */

// React Libs
import { toast } from 'react-toastify'
import GoTo from 'react-lib/go-to'

// Routes
import { SignInRoutes } from 'authorization/components/sign-in'

const HandleForgotPassword = ({
  loading,
  error,
  data
}: {
  loading: boolean;
  error: Error;
  data: any;
}): void => {
  if (!loading) {
    if (error) {
      toast.error("We couldn't find an account with that email.")
    } else if (data) {
      toast.info("We've sent you an email.")
      GoTo(SignInRoutes[0])
    }
  }
}

export { HandleForgotPassword }

// Types
import EEntity from 'map-view/enums/entity'
import EDiff from 'map-view/enums/diff'
import EOverlay from 'map-view/enums/overlay'

// Utils
import { v4 } from 'uuid'
import IDiff from 'map-view/interfaces/diff'
import IEntity from 'map-view/interfaces/entity'
import ID from 'graphql-lib/interfaces/ID'
import ITask from 'graphql-lib/interfaces/ITask'
import { IListRef } from '../task-issues-list/effects'

const UpdateOverlays = ({
  invisible,
  overlays,
  selectOverlay
}: {
  invisible: boolean
  overlays: Array<EOverlay>
  selectOverlay: (val: EOverlay) => void
}): void => {
  if (
    !overlays ||
    !selectOverlay
  ) {
    return
  }

  if (
    !invisible &&
    !overlays.includes(EOverlay.Task)
  ) {
    selectOverlay(EOverlay.Task)
  }
}

const UpdateTasks = ({
  tasks,
  setTasks,
  setTasksUUID
}:
{
  tasks: Array<ITask>
  setTasks: (val: Array<ITask>) => void
  setTasksUUID: (val: string) => void
}): void => {
  if (!tasks) {
    return
  }

  setTasks(tasks)
  setTasksUUID(v4())
}

const UpdateVisibleTasks = ({
  visibleEntities,
  tasks,
  setVisibleTasks,
  setVisibleTasksUUID
}:
{
  visibleEntities: Array<IEntity>
  tasks: Array<ITask>
  setVisibleTasks: (val: Array<ITask>) => void
  setVisibleTasksUUID: (val: string) => void
}): void => {
  if (
    !visibleEntities ||
    !tasks
  ) {
    return
  }

  const newVisibleTasks = tasks
    .filter(task => !!visibleEntities
      .find(entity =>
        entity.type === EEntity.Task &&
        String(entity.id) === String(task.id)))

  setVisibleTasks(newVisibleTasks)
  setVisibleTasksUUID(v4())
}

const UpdateSearchedTasks = ({
  tasks,
  visibleTasks,
  search,
  visible,

  setSearchedTasks,
  setSearchedTasksUUID
}: {
  tasks: Array<ITask>
  visibleTasks: Array<ITask>
  search: string
  visible: boolean
  setSearchedTasks: (val: Array<ITask>) => void
  setSearchedTasksUUID: (val: string) => void
}): void => {
  if (
    !tasks ||
    !visibleTasks
  ) {
    return
  }

  let newSearchedTasks = visible
    ? visibleTasks
    : tasks

  if (search?.trim()) {
    const searchLC = search.toLocaleLowerCase()
    newSearchedTasks = newSearchedTasks
      .filter((task: any) =>
        task.description?.toLocaleLowerCase().includes(searchLC) ||
        task.flag[0].title?.toLocaleLowerCase().includes(searchLC) ||
        task.flag[0].description?.toLocaleLowerCase().includes(searchLC) ||
        task.flag[0].flagCategory[0].name?.toLocaleLowerCase().includes(searchLC))
  }

  // Sort tasks by most urgent
  newSearchedTasks.sort((firstTask, secondTask) => secondTask.priority - firstTask.priority)

  setSearchedTasks(newSearchedTasks)
  setSearchedTasksUUID(v4())
}

const UpdateScroll = ({
  selectedEntitiesDiff,
  listRef
}: {
  selectedEntitiesDiff: Array<IDiff<IEntity>>
  listRef: IListRef
}): void => {
  if (
    !selectedEntitiesDiff ||
    !listRef
  ) {
    return
  }

  const diff = selectedEntitiesDiff
    .find(diff =>
      diff.type === EDiff.Add &&
      diff.value.type === EEntity.Task)

  if (!diff) {
    return
  }

  const child = Array.from(listRef.children)
    .find(child =>
      child.dataset.type === diff.value.type &&
      child.dataset.id === diff.value.id)

  if (!child) {
    return
  }

  child.scrollIntoView({
    behavior: 'smooth',
    block: 'start',
    inline: 'nearest'
  })
}

const UpdateCreatingViewingTask = ({
  searchParams,
  selectedEntities,
  setCreatingTask,
  setViewingTask
}: {
  searchParams: {
    creatingTask: boolean
  },
  selectedEntities: Array<IEntity>
  setCreatingTask: (val: boolean) => void
  setViewingTask: (val: ID) => void
}): void => {
  if (
    !searchParams ||
    !selectedEntities
  ) {
    return
  }

  const newViewingTask = selectedEntities
    .find(entity => entity.type === EEntity.Task)
    ?.id

  setViewingTask(newViewingTask)

  // Allow creating task card to open if user long-clicked
  const newCreatingTask = searchParams.creatingTask
  setCreatingTask(newCreatingTask)
}

export {
  UpdateOverlays,
  UpdateTasks,
  UpdateVisibleTasks,
  UpdateSearchedTasks,
  UpdateScroll,
  UpdateCreatingViewingTask
}

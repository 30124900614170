/**
 * @author Miras Absar <mabsar@iunu.com>
 */

// Utils
import isVoid from 'utils/is-void'
import {
  EStorage,
  GetItem
} from 'utils/storage'

// Libs
import { toDate as toDateTZ } from 'date-fns-tz'

const toSelf = (val: string): string | undefined => {
  if (
    isVoid(val) ||
    /^$/.test(val)
  ) {
    return
  }

  return val
}

const toNumber = (val: string): number | undefined => {
  if (
    isVoid(val) ||
    /^$/.test(val)
  ) {
    return
  }

  return Number.parseFloat(val)
}

const toDate = (val: string): Date | undefined => {
  const [facility] = GetItem('facilities', EStorage.EphemeralStorage)

  if (
    !facility ||
    isVoid(facility.timezone) ||
    isVoid(val) ||
    /^$/.test(val)
  ) {
    return
  }

  return toDateTZ(val, { timeZone: facility.timezone })
}

const Formatters: Record<string, (val: string) => any> = {
  'Product Code': toSelf,
  'Area': toSelf,
  'Zone': toSelf,
  'Sub Zone': toSelf,
  'Name': toSelf,
  'Code': toSelf,
  'Container Type': toSelf,
  'Containers per Group': toNumber,
  'Start Date': toDate,
  'Transplant Date': toDate,
  'End Date': toDate,
  'Description': toSelf
}

export default Formatters

// Types
import IBuilding from 'graphql-lib/interfaces/IBuilding'
import ISpace from 'graphql-lib/interfaces/ISpace'

// Libs
import { v4 } from 'uuid'

const SearchData = ({
  spaces,
  searchText,

  setSearchedData,
  setSearchedDataUUID
}: {
  spaces: ISpace[];
  searchText: string;

  setSearchedData: (newVal: ISpace[]) => void;
  setSearchedDataUUID: (newVal: string) => void;
}): void => {
  if (spaces) {
    let newSearchData: ISpace[] = []
    const isID = /^ID: \d+$/.test(searchText)
    if (isID) {
      const id = searchText.substring(4)
      newSearchData = spaces.filter((s: ISpace): boolean => Number(s.id) === Number(id))
    } else {
      const _searchText = searchText.toLowerCase()
      newSearchData = spaces.filter((s: ISpace): boolean =>
        s.code?.toLowerCase()?.includes(_searchText) ||
        s.name?.toLowerCase()?.includes(_searchText) ||
        s.nickname?.toLowerCase()?.includes(_searchText))
    }

    setSearchedData(newSearchData)
    setSearchedDataUUID(v4())
  }
}

const SortData = ({
  searchedData,
  setSortedData,
  setSortedDataUUID
}: {
  searchedData: ISpace[];
  setSortedData: (newVal: ISpace[]) => void;
  setSortedDataUUID: (newVal: string) => void;
}): void => {
  if (searchedData) {
    const newSortedData = searchedData.sort((a: ISpace, b: ISpace): number => 
      a.name.localeCompare(b.name, undefined, { numeric: true }))

    setSortedData(newSortedData)
    setSortedDataUUID(v4())
  }
}

const GroupData = ({
  sortedData,
  buildings,
  ref,

  setGroupedData
}: {
  sortedData: ISpace[];
  buildings: IBuilding[];
  ref: { current: any };

  setGroupedData: (newVal: (ISpace | IBuilding)[]) => void;
}): void => {
  if (sortedData && buildings) {
    const newGroupedData = buildings
      // Sort buildings by name
      .sort((a, b): number =>
        a.name.localeCompare(b.name, undefined, { numeric: true }))
      .map(bb => {
        // Sort spaces after their associated buildings
        bb.spaces = sortedData.filter((s: ISpace): boolean => Number(s.buildingId) === Number(bb.id))
        return bb
      })

    setGroupedData(newGroupedData)
    ref.current?.resetAfterIndex(0)
  }
}

export {
  SearchData,
  SortData,
  GroupData
}

/**
 * @author Miras Absar <mabsar@iunu.com>
 */

// Types
import IRefreshToken from 'graphql-lib/interfaces/IRefreshToken'

// Libs
import { v4 } from 'uuid'

// React
import {
  useCallback,
  useRef,
  useState
} from 'react'

// React Libs
import { useIter } from 'react-lib/use-iter'

// GraphQL
import client from 'graphql-lib/index'
import { CreateRefreshToken } from './queries'

// Effects
import { Init } from './effects'
import { FetchResult } from '@apollo/client'

const createRefreshToken = (): {
  loading: boolean;
  error: Error;
  refreshToken: string;
  uuid: string;

  create: (create: {email: string, password: string, apiKey?: string}) => Promise<Partial<{refreshToken: string}>>;
} => {
  // State /////////////////////////////////////////////////////////////////////

  const hookID = useRef<string>()
  const [loading, setLoading] = useState<boolean>()
  const [error, setError] = useState<Error>()
  const [refreshToken, setRefreshToken] = useState<string>()
  const [uuid, setUUID] = useState<string>()

  // Effects ///////////////////////////////////////////////////////////////////

  useIter(
    Init,
    { hookID },
    []
  )

  // Callbacks /////////////////////////////////////////////////////////////////

  const create = useCallback(
    async (create) => {
      setLoading(true)
      setUUID(v4())

      let error: Error;
      let data;
      try {
        const response: FetchResult<{RefreshToken: { create: string }}> = await client.mutate({
          fetchPolicy: 'no-cache',
          mutation: CreateRefreshToken,
          variables: create
        })

        data = response.data
      } catch (err) {
        error = err as Error;
      }

      setLoading(false)
      if (error) {
        setError(error)
        setUUID(v4())

        return { error }
      } else if (data) {
        const newRefreshToken = data.RefreshToken.create

        setError(undefined)
        setRefreshToken(newRefreshToken)
        setUUID(v4())

        const ev = new CustomEvent(
          'create_refresh_token',
          {
            detail: {
              dispatcherID: hookID.current,
              create: newRefreshToken
            }
          }
        )

        window.dispatchEvent(ev)

        return { refreshToken: newRefreshToken }
      }

      return {}
    },
    [uuid]
  )

  // Return ////////////////////////////////////////////////////////////////////

  return {
    loading,
    error,
    refreshToken,
    uuid,
    create
  }
}

export default createRefreshToken

/**
 * @author Miras Absar <mabsar@iunu.com>
 */
import React, {
  KeyboardEvent
} from 'react'

import IFlag from '../../../interfaces/IFlag'
import IItemFacadeProps from 'ui-lib/interfaces/IItemFacadeProps'

import Flag from 'ui-lib/components/flag'

export default ({
  /* Visual Properties */
  animated,

  /* Behavioral Properties */
  value,
  onSelect
}: IItemFacadeProps<IFlag>): JSX.Element => {
  const className = animated
    ? 'FlagItem Animated'
    : 'FlagItem'

  const $keyPress = (ev: KeyboardEvent): void => {
    if (ev.key === 'Enter') {
      onSelect(value)
    }
  }

  const $click = (): void => onSelect(value)

  return (
    <div
      className={className}
      onKeyPress={$keyPress}
      onClick={$click}
      tabIndex={0}
    >
      <Flag flag={value} />
      <p className='Title'>{value.title}</p>
    </div>
  )
}

// Types
import ID from 'graphql-lib/interfaces/ID';
import IEntity from 'graphql-lib/interfaces/entity';
import ICrop from 'graphql-lib/interfaces/ICrop';
import IInventory from 'graphql-lib/interfaces/IInventory';
import IInventoryStitch from 'graphql-lib/interfaces/IInventoryStitch';

// React
import React, {
  useCallback,
  useRef,
  useState
} from 'react';

// React Libs
import { useIter } from 'react-lib/use-iter';

// Effects
import {
  UpdateSize,
  UpdateSelectedInventories,
  UpdateCurrentInventoryStitches,
  UpdateLaidOutStitches,
  UpdateOpenLayers
} from './effects';

// OpenLayers
import { containsXY } from 'ol/extent';
import Map from 'ol/Map';
import Layer from 'ol/layer/Layer';
import View from 'ol/View'
import Overlay from 'ol/Overlay';
import Collection from 'ol/Collection';
import OlView from 'map-view/components/ol-view';
import { Coordinate } from 'ol/coordinate';

export interface ICropsCanvasProps {
  width: number;
  height: number;

  selectedEntities?: IEntity[];
  crops: ICrop[];
  inventories: IInventory[];
  inventoryStitchesCache: Record<ID, IInventoryStitch[]>;

  selectedEntitiesUUID?: string;
  inventoriesUUID?: string;
}

const CropsCanvas = ({
  width,
  height,

  selectedEntities,
  crops,
  inventories,
  inventoryStitchesCache,

  selectedEntitiesUUID,
  inventoriesUUID
}: ICropsCanvasProps): JSX.Element => {
  // Refs & State //////////////////////////////////////////////////////////////

  const [selectedInventories, setSelectedInventories] = useState<IInventory[]>()
  const [currentInventoryStitches, setCurrentInventoryStitches] = useState<IInventoryStitch[]>()
  const [laidOutInventoryStitches, setLaidOutInventoryStitches] = useState<null>()

  const mapRef = useRef<Map>()
  const [view, setView] = useState<View>()
  const layers = useRef<Collection<Layer>>(new Collection<Layer>())
  const overlays = useRef<Collection<Overlay>>(new Collection<Overlay>())

  const [selectedInventoriesUUID, setSelectedInventoriesUUID] = useState<string>()
  const [currentInventoryStitchesUUID, setCurrentInventoryStitchesUUID] = useState<string>()
  const [laidOutInventoryStitchesUUID, setLaidOutInventoryStitchesUUID] = useState<string>()
  const [openLayersUUID, setOpenLayersUUID] = useState<string>()

  // Effects ///////////////////////////////////////////////////////////////////

  useIter(
    UpdateSize,

    { mapRef },

    [ width,
      height ]
  )

  useIter(
    UpdateSelectedInventories,

    { selectedEntities,
      inventories,

      setSelectedInventories,
      setSelectedInventoriesUUID },

    [ selectedEntitiesUUID,
      inventoriesUUID ]
  )

  useIter(
    UpdateCurrentInventoryStitches,

    { selectedInventories,
      inventoryStitchesCache,

      setCurrentInventoryStitches,
      setCurrentInventoryStitchesUUID },

    [selectedInventoriesUUID]
  )

  useIter(
    UpdateLaidOutStitches,

    { currentInventoryStitches,

      setLaidOutInventoryStitches,
      setLaidOutInventoryStitchesUUID },

    [currentInventoryStitchesUUID]
  )

  useIter(
    UpdateOpenLayers,

    { crops,
      inventories,
      laidOutInventoryStitches,
      view,

      setView,
      layers: layers.current,
      setOpenLayersUUID },

    [laidOutInventoryStitchesUUID]
  )

  // Callbacks /////////////////////////////////////////////////////////////////

  const $pointerMove = useCallback(
    (ev: { coordinate: Coordinate}): void => {
      // Get layer
      const layer = layers.current
        .getArray()
        .find((l: Layer): boolean => {
          const extent = l.getExtent()
          const [
            x,
            y
          ] = ev.coordinate

          const contains = containsXY(extent, x, y)
          return contains
        })

      // Get overlay
      let [overlay] = overlays.current.getArray()
      if (!overlay) {
        const rootNode = document.createElement('div')
        const cropNode = document.createElement('p')
        const inventoryNode = document.createElement('p')

        rootNode.className = 'InventoryStitchOverlay'
        cropNode.className = 'Crop'
        inventoryNode.className = 'Inventory'

        rootNode.appendChild(cropNode)
        rootNode.appendChild(inventoryNode)

        overlay = new Overlay({
          element: rootNode,
          offset: [
            16,
            8
          ],
          stopEvent: false
        })

        overlays.current.push(overlay)
      }

      // Assign layer metadata to overlay
      const [cropNode] = overlay.getElement().getElementsByClassName('Crop')
      const [inventoryNode] = overlay.getElement().getElementsByClassName('Inventory')

      if (layer) {
        const cropName = layer.get('cropName');
        const inventoryCode = layer.get('inventoryCode');

        (cropNode as HTMLElement).innerText = cropName;
        (inventoryNode as HTMLElement).innerText = `Group ${inventoryCode}`;
        overlay.setPosition(ev.coordinate)
      } else {
        (cropNode as HTMLElement).innerText = '';
        (inventoryNode as HTMLElement).innerText = '';
        overlay.setPosition(null);
      }
    },
    [openLayersUUID]
  )

  // Render ////////////////////////////////////////////////////////////////////

  const style: { width?: string, height?: string } = {}
  if (width && height) {
    style.width = `${width}px`
    style.height = `${height}px`
  }

  return (
    <div
      style={style}
      className='CropsCanvas'
    >
      <OlView
        view={view}
        layers={layers.current}
        overlays={overlays.current}

        pixelRatio={1}
        moveTolerance={10}

        onPointerMove={$pointerMove}
      />
    </div>
  )
}

export default CropsCanvas

// Types
import EDiff from '../../enums/diff';
import EEntity from 'graphql-lib/enums/entity';
import EChart from 'map-view/enums/chart';
import IEntity from 'graphql-lib/interfaces/entity';
import ISetStateType from 'graphql-lib/interfaces/ISetStateType';

// Utils
import isVoid from 'utils/is-void';
import { NewChart } from 'map-view/utils/chart';

// Libs
import { addDays, isAfter, isEqual, isValid, subDays } from 'date-fns';
import Debug from 'debug';
import { isEqual as isDeepEqual } from 'lodash';
import { v4 } from 'uuid';
import { createDate } from 'utils/dates';
import { lowestNonZeroValue, ObjectArrayType } from 'graphql-lib/utils/lowest-nonzero-value';
import IGridPrediction from 'graphql-lib/interfaces/IGridPrediction';
import ISpace from 'graphql-lib/interfaces/ISpace';
import { ISensor, ISensorData, ISensorRecord, ISensorRecordData } from 'graphql-lib/interfaces/ISensorDevice';
import IGreenAutomationWaterSystemRecord, { IGreenAutomationWaterSystemRecordData } from 'graphql-lib/interfaces/IGreenAutomationWaterSystemRecord';
import IChart from 'map-view/interfaces/chart'
import IFacility from 'graphql-lib/interfaces/IFacility'
import IOverlay from 'map-view/interfaces/overlay'
import { GraphQLError } from 'graphql'
import IModelList from 'graphql-lib/interfaces/IModelList'
import IBuilding from 'graphql-lib/interfaces/IBuilding'
import IAreacam, { IAreacamData } from 'graphql-lib/interfaces/IAreacam'
import IAreaImageRecord, { IAreaImageRecordData } from 'graphql-lib/interfaces/IAreaImageRecord'
import IInventory from 'graphql-lib/interfaces/IInventory'
import IContainer from 'graphql-lib/interfaces/IContainer'
import IHarvestGroup from 'graphql-lib/interfaces/IHarvestGroup'
import IInventoryStitch from 'graphql-lib/interfaces/IInventoryStitch'
import IContainerStich from 'graphql-lib/interfaces/IContainerStitch'
import IHeatmap from 'graphql-lib/interfaces/IHeatmap'
import IBudDetection from 'graphql-lib/interfaces/IBudDetection'
import ICanopyCoverage, { ICanopyHeight } from 'graphql-lib/interfaces/ICanopyCoverage'
import IFlowerCoverage from 'graphql-lib/interfaces/IFlowerCoverage'
import IGerminationRate from 'graphql-lib/interfaces/IGerminationRate'
import IYieldPredictionAverage from 'graphql-lib/interfaces/IYieldPredictionAverage'
import ITopCanopyCoverage, { ITopGerminationRate } from 'graphql-lib/interfaces/ITopCanopyCoverage'
import IPotCount from 'graphql-lib/interfaces/IPotCount'
import IBoardCount from 'graphql-lib/interfaces/IBoardCount'
import ITask from 'graphql-lib/interfaces/ITask'
import ITaskIssue from 'graphql-lib/interfaces/ITaskIssue'

// Types
import ID from 'graphql-lib/interfaces/ID';
import EOverlay from 'map-view/enums/overlay';

interface ITimeType {
  now: Date
  dayLookforward: Date
  dayLookback: Date
  tenDayLookback: Date
  weekLookback: Date
  twoWeekLookback: Date
};

interface ISearchParamType {
  time?: string
  selectedEntities?: string
  overlays?: string
}

interface IDiff<Type> {
  type: EDiff
  value: Type
}

const debug = Debug('Mobius:Effects')

const UpdateTime = ({
  searchParams,
  time,
  facility,

  setSearchParams,
  setTime
}:
{
  searchParams: ISearchParamType
  time: ITimeType
  facility: IFacility
  setSearchParams: ISetStateType<ISearchParamType>
  setTime: ISetStateType<ITimeType>
}): void => {
  if (searchParams && facility) {
    const { time: urlTime } = searchParams
    const { defaultTimestamp } = facility

    const urlTimeIsVoid = isVoid(urlTime)
    const defaultTimestampIsVoid = isVoid(defaultTimestamp)

    const urlTimeIsValid =
      !urlTimeIsVoid &&
      typeof urlTime === 'string' &&
      isValid(createDate(urlTime)) &&
      !isAfter(createDate(urlTime), new Date())

    const urlTimeIsSame =
      urlTimeIsValid &&
      isEqual(createDate(urlTime), time?.now)

    const defaultTimestampIsValid =
      !defaultTimestampIsVoid &&
      typeof defaultTimestamp === 'string' &&
      isValid(createDate(defaultTimestamp)) &&
      !isAfter(createDate(urlTime), new Date())

    if (urlTimeIsSame) {
      return
    }

    let newNow = new Date()
    if (urlTimeIsVoid && defaultTimestampIsValid) {
      newNow = createDate(defaultTimestamp)
    } else if (urlTimeIsValid) {
      newNow = createDate(urlTime)
    }

    const newTime = {
      now: newNow,
      dayLookforward: addDays(newNow, 1),
      dayLookback: subDays(newNow, 1),
      tenDayLookback: subDays(newNow, 10),
      weekLookback: subDays(newNow, 7),
      twoWeekLookback: subDays(newNow, 14)
    }

    const newSearchParams = Object.assign(
      {},
      searchParams,
      { time: newNow.toISOString() }
    )

    setSearchParams(newSearchParams)
    setTime(newTime)

    debug('Update Time', newTime)
  }
}

const UpdateSelectedEntities = ({
  searchParams,
  selectedEntities,
  setSearchParams,
  setSelectedEntities,
  setSelectedEntitiesDiff,
  setSelectedEntitiesUUID
}: {
  searchParams: ISearchParamType
  selectedEntities: Array<IEntity>
  setSearchParams: ISetStateType<ISearchParamType>
  setSelectedEntities: ISetStateType<Array<IEntity>>
  setSelectedEntitiesDiff: ISetStateType<Array<IDiff<IEntity>>>
  setSelectedEntitiesUUID: ISetStateType<string>
}): void => {
  if (searchParams) {
    const { selectedEntities: urlSelectedEntities } = searchParams

    const urlSelectedEntitiesIsVoid = isVoid(urlSelectedEntities)
    let urlSelectedEntitiesIsValid: boolean
    try {
      urlSelectedEntitiesIsValid =
        !urlSelectedEntitiesIsVoid &&
        typeof urlSelectedEntities === 'string' &&
        Array.isArray(JSON.parse(urlSelectedEntities))
    } catch (err) {
      urlSelectedEntitiesIsValid = false
    }

    const urlSelectedEntitiesIsSame =
      urlSelectedEntitiesIsValid &&
      isDeepEqual(JSON.parse(urlSelectedEntities), selectedEntities)

    if (urlSelectedEntitiesIsSame) {
      return
    }

    let newSelectedEntities: IEntity[] = []
    if (urlSelectedEntitiesIsValid) {
      newSelectedEntities = JSON.parse(urlSelectedEntities)
    }

    const added = newSelectedEntities
      .filter(ne => !(selectedEntities || [])
        .find(oe =>
          ne.type === oe.type &&
          String(ne.id) === String(oe.id)))

      .map(e => ({
        type: EDiff.Add,
        value: e
      }))

    const removed = (selectedEntities || [])
      .filter(oe => !newSelectedEntities
        .find(ne =>
          oe.type === ne.type &&
          String(oe.id) === String(ne.id)))

      .map(e => ({
        type: EDiff.Remove,
        value: e
      }))

    const newSelectedEntitiesDiff = [
      ...added,
      ...removed
    ]

    const newSearchParams = Object.assign(
      {},
      searchParams,
      { selectedEntities: JSON.stringify(newSelectedEntities) }
    )

    setSearchParams(newSearchParams)
    setSelectedEntities(newSelectedEntities)
    setSelectedEntitiesDiff(newSelectedEntitiesDiff)
    setSelectedEntitiesUUID(v4())

    debug('Update Selected Entities', newSelectedEntities)
  }
}

const UpdateOverlays = ({
  searchParams,
  overlays,
  setSearchParams,
  setOverlays,
  setOverlaysDiff,
  setOverlaysUUID
}: {
  searchParams: ISearchParamType
  overlays: Array<EOverlay>
  setSearchParams: ISetStateType<ISearchParamType>
  setOverlays: ISetStateType<Array<EOverlay>>
  setOverlaysDiff: ISetStateType<Array<IDiff<EOverlay>>>
  setOverlaysUUID: ISetStateType<string>
}): void => {
  if (searchParams) {
    const { overlays: urlOverlays } = searchParams
    const urlOverlaysIsVoid = isVoid(urlOverlays)
    let urlOverlaysIsValid: boolean
    try {
      urlOverlaysIsValid =
        !urlOverlaysIsVoid &&
        typeof urlOverlays === 'string' &&
        Array.isArray(JSON.parse(urlOverlays))
    } catch (err) {
      urlOverlaysIsValid = false
    }

    const urlOverlaysIsSame =
      urlOverlaysIsValid &&
      isDeepEqual(JSON.parse(urlOverlays), overlays)
    if (urlOverlaysIsSame) {
      return
    }

    let newOverlays: EOverlay[] = []
    if (urlOverlaysIsValid) {
      newOverlays = JSON.parse(urlOverlays)
    }
    const added = newOverlays
      .filter(no => !(overlays || [])
        .find(oo => no === oo))

      .map(o => ({
        type: EDiff.Add,
        value: o
      }))

    const removed = (overlays || [])
      .filter(oo => !newOverlays
        .find(no => oo === no))

      .map(o => ({
        type: EDiff.Remove,
        value: o
      }))

    const newOverlaysDiff = [
      ...added,
      ...removed
    ]
    const newSearchParams = Object.assign(
      {},
      searchParams,
      { overlays: JSON.stringify(newOverlays) }
    )

    setSearchParams(newSearchParams)
    setOverlays(newOverlays)
    setOverlaysDiff(newOverlaysDiff)
    setOverlaysUUID(v4())

    debug('Update Overlays', newOverlays)
  }
}

const UpdateStaticData = ({
  facilityId,
  loading,
  error,
  data,
  setFacilityID,
  setBuildingIDs,
  setSpaceIDs,
  setFacilityLoading,
  setBuildingsLoading,
  setSpacesLoading,
  setFacilityError,
  setBuildingsError,
  setSpacesError,
  setFacility,
  setBuildings,
  setSpaces,
  setFacilityUUID,
  setBuildingsUUID,
  setSpacesUUID
}: {
  facilityId: ID
  loading: boolean
  error: GraphQLError
  data: IModelList<IFacility>
  setFacilityID: ISetStateType<ID>
  setBuildingIDs: ISetStateType<Array<ID>>
  setSpaceIDs: ISetStateType<Array<number>>
  setFacilityLoading: ISetStateType<boolean>
  setBuildingsLoading: ISetStateType<boolean>
  setSpacesLoading: ISetStateType<boolean>
  setFacilityError: ISetStateType<GraphQLError>
  setBuildingsError: ISetStateType<GraphQLError>
  setSpacesError: ISetStateType<GraphQLError>
  setFacility: ISetStateType<IFacility>
  setBuildings: ISetStateType<Array<IBuilding>>
  setSpaces: ISetStateType<Array<ISpace>>
  setFacilityUUID: ISetStateType<string>
  setBuildingsUUID: ISetStateType<string>
  setSpacesUUID: ISetStateType<string>
}): void => {
  if (loading) {
    debug('Update Static Data - Loading')

    setFacilityLoading(true)
    setBuildingsLoading(true)
    setSpacesLoading(true)

    setFacilityUUID(v4())
    setBuildingsUUID(v4())
    setSpacesUUID(v4())
    return
  }

  if (error) {
    debug('Update Static Data - Error', error)

    setFacilityLoading(false)
    setBuildingsLoading(false)
    setSpacesLoading(false)

    setFacilityError(error)
    setBuildingsError(error)
    setSpacesError(error)

    setFacilityUUID(v4())
    setBuildingsUUID(v4())
    setSpacesUUID(v4())

    return
  }

  if (data) {
    debug('Update Static Data - Data', data)

    const newFacility = data.facility
      .find((f: IFacility) => String(f.id) === String(facilityId))

    const newBuildings = newFacility.building
    const newSpaces = newBuildings
      .map(b => b.space)
      .reduce(
        (acc, cur) => [...acc, ...cur],
        []
      )
      .map(s => {
        if (
          Number(s.customerId) === 1214 ||
          Number(s.customerId) === 1116
        ) {
          if (s.geojson?.features) {
            const coordinates = s.geojson.features[0].coordinates[0].slice(0, 4)
            let coordinate = coordinates.shift()
            coordinates.push(coordinate)

            if (
              Number(s.id) === 9949 ||
              Number(s.id) === 9945 ||
              Number(s.id) === 9948 ||
              Number(s.id) === 9947 ||
              Number(s.id) === 9944 ||
              Number(s.id) === 9946
            ) {
              coordinate = coordinates.shift()
              coordinates.push(coordinate)
              coordinate = coordinates.shift()
              coordinates.push(coordinate)
            }

            s.geojson.features[0].coordinates[0] = coordinates
          }
        }

        return s
      })

    const newFacilityID = newFacility.id
    const newBuildingIDs = newBuildings.map(b => b.id)
    const newSpaceIDs = newSpaces.map(s => s.id)

    setFacilityID(newFacilityID)
    setBuildingIDs(newBuildingIDs)
    setSpaceIDs(newSpaceIDs)

    setFacilityLoading(false)
    setBuildingsLoading(false)
    setSpacesLoading(false)

    setFacilityError(null)
    setBuildingsError(null)
    setSpacesError(null)

    setFacility(newFacility)
    setBuildings(newBuildings)
    setSpaces(newSpaces)

    setFacilityUUID(v4())
    setBuildingsUUID(v4())
    setSpacesUUID(v4())
  }
}

const UpdateSensorIDs = ({
  time,
  sensorRecordsData,
  co2SensorRecordsData,
  setSensorIDs,
  setSensorsLoading,
  setSensorsError,
  setSensors,
  setSensorsTime,
  setSensorsUUID
}: {
  time: ITimeType
  sensorRecordsData: ISensorRecordData
  co2SensorRecordsData: ISensorRecordData
  setSensorIDs: ISetStateType<Array<ID>>
  setSensorsLoading: ISetStateType<boolean>
  setSensorsError: ISetStateType<GraphQLError>
  setSensors: ISetStateType<ISensorRecord>
  setSensorsTime: ISetStateType<Date>
  setSensorsUUID: ISetStateType<string>
}): void => {
  if (
    sensorRecordsData.loading ||
    co2SensorRecordsData.loading
  ) {
    debug('Update Sensor IDs - Loading')

    setSensorsLoading(true)
    setSensorsTime(time.now)
    setSensorsUUID(v4())

    return
  }

  if (
    sensorRecordsData.error ||
    co2SensorRecordsData.error
  ) {
    const error =
      sensorRecordsData.error ||
      co2SensorRecordsData.error

    debug('Update Sensor IDs - Error', error)

    setSensorsError(error)
    setSensorsTime(time.now)
    setSensorsUUID(v4())

    return
  }

  if (
    sensorRecordsData.data &&
    co2SensorRecordsData.data
  ) {
    debug(
      'Update Sensor IDs - Data',
      sensorRecordsData.data,
      co2SensorRecordsData.data
    )

    const newSensorIDs = [...new Set(
      [...sensorRecordsData.data.sensorRecord, ...co2SensorRecordsData.data.co2SensorRecord]
        .map(cur => cur.sensorDeviceId)
    )]

    setSensorIDs(newSensorIDs)
    setSensorsError(null)
    setSensorsTime(time.now)
    setSensorsUUID(v4())
  }
}

const UpdateSensors = ({
  sensorsData,
  sensorRecordsData,
  co2SensorRecordsData,
  setSensorsLoading,
  setSensorsError,
  setSensors,
  setSensorsUUID
}: {
  sensorsData: ISensorData
  sensorRecordsData: ISensorRecordData
  co2SensorRecordsData: ISensorRecordData
  setSensorsLoading: ISetStateType<boolean>
  setSensorsError: ISetStateType<GraphQLError>
  setSensors: ISetStateType<Array<ISensor>>
  setSensorsUUID: ISetStateType<string>
}) => {
  if (sensorsData.loading ||
    sensorRecordsData.loading ||
    co2SensorRecordsData.loading) {
    debug('Update Sensors - Loading')

    setSensorsLoading(true)
    setSensorsUUID(v4())

    return
  }

  if (sensorsData.error ||
    sensorRecordsData.error ||
    co2SensorRecordsData.error) {
    debug('Update Sensor - Error', sensorsData.error)

    setSensorsLoading(false)
    setSensorsError(sensorsData.error)
    setSensorsUUID(v4())

    return
  }

  if (sensorsData.data &&
    sensorRecordsData.data &&
    co2SensorRecordsData.data) {
    debug('Update Sensors - Data', sensorsData.data)

    const newSensors = sensorsData.data.sensor
      .map(s => {
        const newS = { ...s };

        newS.sensorRecord = [
          ...sensorRecordsData.data.sensorRecord,
          ...co2SensorRecordsData.data.co2SensorRecord
        ]
          .filter(sr => String(sr.sensorDeviceId) === String(s.id))

        newS.spaceId = newS.sensorRecord[0]?.spaceId
        newS.sensorRecord = newS.sensorRecord
          .filter((sr: ISensorRecord) => String(sr.spaceId) === String(newS.spaceId))
        return newS
      }).filter(s => Number(s.id) !== 52)

    setSensorsLoading(false)
    setSensorsError(null)
    setSensors(newSensors)
    setSensorsUUID(v4())
  } else {
    // Case where all sensor queries successfully finish, but no sensor data exists on server
    setSensorsLoading(false)
    setSensorsError(null)
    setSensors([])
    setSensorsUUID(v4())
  }
}

const UpdateWaterSystems = ({
  waterSystemRecordsData,
  setWaterSystemsLoading,
  setWaterSystemsError,
  setWaterSystems,
  setWaterSystemsUUID
}: {
  waterSystemRecordsData: IGreenAutomationWaterSystemRecordData
  setWaterSystemsLoading: ISetStateType<boolean>
  setWaterSystemsError: ISetStateType<GraphQLError>
  setWaterSystems: ISetStateType<Array<IGreenAutomationWaterSystemRecord>>
  setWaterSystemsUUID: ISetStateType<string>
}) => {
  if (waterSystemRecordsData.loading) {
    debug('Update Water Systems - Loading')

    setWaterSystemsLoading(true)
    setWaterSystemsUUID(v4())

    return
  }

  if (waterSystemRecordsData.error) {
    debug('Update Water Systems - Error', waterSystemRecordsData.error)

    setWaterSystemsLoading(false)
    setWaterSystemsError(waterSystemRecordsData.error)
    setWaterSystemsUUID(v4())

    return
  }

  if (waterSystemRecordsData.data) {
    debug('Update Water Systems - Data', waterSystemRecordsData.data)
    const newWaterSystemRecords = waterSystemRecordsData.data.greenAutomationWaterSystemRecord

    // TODO? potentially map this data so it is structured or grouped by water system id

    setWaterSystemsLoading(false)
    setWaterSystemsError(null)
    setWaterSystems(newWaterSystemRecords)
    setWaterSystemsUUID(v4())
  }
}

const UpdateAreacamIDs = ({
  time,
  now,
  loading,
  error,
  data,
  setAreacamIDs,
  setAreacamsLoading,
  setAreacamsError,
  setAreacamsTime,
  setAreacamsUUID
}: {
  time: ITimeType
  now: Date
  loading: boolean
  error: GraphQLError
  data: IModelList<IAreaImageRecord>
  setAreacamIDs: ISetStateType<Array<ID>>
  setAreacamsLoading: ISetStateType<boolean>
  setAreacamsError: ISetStateType<GraphQLError>
  setAreacamsTime: ISetStateType<Date>
  setAreacamsUUID: ISetStateType<string>
}) => {
  if (loading) {
    debug('Update Areacam IDs - Loading')

    setAreacamsLoading(true)
    setAreacamsTime(time.now)
    setAreacamsUUID(v4())

    return
  }

  if (error) {
    debug('Update Areacam IDs - Error', error)

    setAreacamsError(error)
    setAreacamsTime(time.now)
    setAreacamsUUID(v4())

    return
  }

  if (data) {
    debug('Update Areacam IDs - Data', data)

    const newAreacamIDs = [...new Set(data.areaImageRecord.map(cur => cur.camDeviceId))];

    setAreacamIDs(newAreacamIDs)
    setAreacamsError(null)
    setAreacamsTime(time.now)
    setAreacamsUUID(v4())
  }
}

const UpdateAreacams = ({
  areacamsData,
  areaImageRecordsData,
  skipAreaCams,
  setAreacamsLoading,
  setAreacamsError,
  setAreacams,
  setAreacamsUUID
}: {
  areacamsData: IAreacamData,
  areaImageRecordsData: IAreaImageRecordData
  skipAreaCams: boolean
  setAreacamsLoading: ISetStateType<boolean>
  setAreacamsError: ISetStateType<GraphQLError>
  setAreacams: ISetStateType<Array<IAreacam>>
  setAreacamsUUID: ISetStateType<string>
}) => {
  if (areacamsData.loading) {
    debug('Update Areacams - Loading')

    setAreacamsLoading(true)
    setAreacamsUUID(v4())

    return
  }

  if (areacamsData.error) {
    debug('Update Areacams - Error', areaImageRecordsData.error)

    setAreacamsLoading(false)
    setAreacamsError(areaImageRecordsData.error)
    setAreacamsUUID(v4())

    return
  }

  if (areacamsData.data) {
    debug('Update Areacam - Data', areaImageRecordsData.data)

    const newAreacams = areacamsData.data.areacam
      .map(ac => {
        const newAc = Object.assign(
          {},
          ac
        )

        newAc.areaImageRecord = areaImageRecordsData.data?.areaImageRecord
          .filter(air => String(air.camDeviceId) === String(newAc.id))

        return newAc
      })

    setAreacamsLoading(false)
    setAreacamsError(null)
    setAreacams(newAreacams)
    setAreacamsUUID(v4())
  } else if (skipAreaCams) {
    // Case where all areacam queries successfully finish,
    // but no areacam data exists on server
    setAreacamsLoading(false)
    setAreacamsError(null)
    setAreacams([])
    setAreacamsUUID(v4())
  }
}

const UpdateGridPredictions = ({
  time,
  loading,
  error,
  data,
  setInventoryIDs,
  setInventoryWOCIDs,
  setInventoryWCIDs,
  setContainerIDs,
  setGridPredictionsLoading,
  setGridPredictionsError,
  setGridPredictions,
  setGridPredictionsTime,
  setGridPredictionsUUID
}: {
  time: ITimeType
  loading: boolean
  error: GraphQLError
  data: IModelList<IGridPrediction>
  setInventoryIDs: ISetStateType<Array<ID>>
  setInventoryWOCIDs: ISetStateType<Array<ID>>
  setInventoryWCIDs: ISetStateType<Array<ID>>
  setContainerIDs: ISetStateType<Array<ID>>
  setGridPredictionsLoading: ISetStateType<boolean>
  setGridPredictionsError: ISetStateType<GraphQLError>
  setGridPredictions: ISetStateType<Array<IGridPrediction>>
  setGridPredictionsTime: ISetStateType<Date>
  setGridPredictionsUUID: ISetStateType<String>
}) => {
  if (loading) {
    debug('Update Grid Predictions - Loading')

    setGridPredictionsLoading(true)
    setGridPredictionsTime(time.now)
    setGridPredictionsUUID(v4())

    return
  }

  if (error) {
    debug('Update Grid Predictions - Error', error)

    setGridPredictionsLoading(false)
    setGridPredictionsError(error)
    setGridPredictionsTime(time.now)
    setGridPredictionsUUID(v4())

    return
  }

  if (data) {
    debug('Update Grid Predictions - Data', data)

    const newGridPredictions = data.gridPrediction
    const newInventoryIDs = Array.from(
      newGridPredictions
        .reduce(
          (inventoryIDs, gridPrediction) => {
            Object.keys(gridPrediction.grid)
              .forEach(inventoryIDs.add, inventoryIDs)

            return inventoryIDs
          },
          new Set<ID>()
        )
    )

    const newInventoryWOCIDs = Array.from(
      newGridPredictions
        .filter(gridPrediction => 'tl' in Object.values(gridPrediction.grid)[0])
        .reduce(
          (inventoryIDs, gridPrediction) => {
            Object.keys(gridPrediction.grid)
              .forEach(inventoryIDs.add, inventoryIDs)

            return inventoryIDs
          },
          new Set<ID>()
        )
    )

    const newInventoryWCIDs = Array.from(
      newGridPredictions
        .filter(gridPrediction => !('tl' in Object.values(gridPrediction.grid)[0]))
        .reduce(
          (inventoryIDs, gridPrediction) => {
            Object.keys(gridPrediction.grid)
              .forEach(inventoryIDs.add, inventoryIDs)

            return inventoryIDs
          },
          new Set<ID>()
        )
    )

    const newContainerIDs = Array.from(
      newGridPredictions
        .filter(gridPrediction => !('tl' in Object.values(gridPrediction.grid)[0]))
        .reduce(
          (containerIDs, gridPrediction) => {
            Object.values(gridPrediction.grid)
              .forEach(grid => Object.keys(grid)
                .forEach(containerIDs.add, containerIDs))

            return containerIDs
          },
          new Set<ID>()
        )
    )

    setInventoryIDs(newInventoryIDs)
    setInventoryWOCIDs(newInventoryWOCIDs)
    setInventoryWCIDs(newInventoryWCIDs)
    setContainerIDs(newContainerIDs)
    setGridPredictionsLoading(false)
    setGridPredictionsError(null)
    setGridPredictions(newGridPredictions)
    setGridPredictionsTime(time.now)
    setGridPredictionsUUID(v4())
  }
}

const UpdateInventories = ({
  inventoryWOCIDs,
  inventoryWCIDs,
  loading,
  error,
  data,

  setInventoriesLoading,
  setInventoriesError,
  setInventories,
  setInventoriesWOC,
  setInventoriesWC,
  setInventoriesUUID
}: {
  inventoryWOCIDs: Array<ID>
  inventoryWCIDs: Array<ID>
  loading: boolean
  error: GraphQLError
  data: IModelList<IInventory>
  setInventoriesLoading: ISetStateType<boolean>
  setInventoriesError: ISetStateType<GraphQLError>
  setInventories: ISetStateType<Array<IInventory>>
  setInventoriesWOC: ISetStateType<Array<IInventory>>
  setInventoriesWC: ISetStateType<Array<IInventory>>
  setInventoriesUUID: ISetStateType<string>
}) => {
  if (
    !inventoryWOCIDs ||
    !inventoryWCIDs
  ) {
    return
  }

  if (loading) {
    debug('Update Inventories - Loading')

    setInventoriesLoading(true)
    setInventoriesUUID(v4())

    return
  }

  if (error) {
    debug('Update Inventories - Error', error)

    setInventoriesLoading(false)
    setInventoriesError(error)
    setInventoriesUUID(v4())

    return
  }

  if (data) {
    debug('Update Inventories - Data', data)

    const newInventories = data.inventory
    const newInventoriesWOC = data.inventory
      .filter(inventory => !!inventoryWOCIDs
        .find(inventoryId => String(inventoryId) === String(inventory.id)))

    const newInventoriesWC = data.inventory
      .filter(inventory => !!inventoryWCIDs
        .find(inventoryId => String(inventoryId) === String(inventory.id)))

    setInventoriesLoading(false)
    setInventoriesError(null)
    setInventories(newInventories)
    setInventoriesWC(newInventoriesWC)
    setInventoriesWOC(newInventoriesWOC)
    setInventoriesUUID(v4())
  }
}

const UpdateContainers = ({
  loading,
  error,
  data,
  setContainersLoading,
  setContainersError,
  setContainers,
  setContainersUUID
}: {
  loading: boolean
  error: GraphQLError
  data: IModelList<IContainer>
  setContainersLoading: ISetStateType<boolean>
  setContainersError: ISetStateType<GraphQLError>
  setContainers: ISetStateType<Array<IContainer>>
  setContainersUUID: ISetStateType<string>
}) => {
  if (loading) {
    debug('Update Containers - Loading')

    setContainersLoading(true)
    setContainersUUID(v4())

    return
  }

  if (error) {
    debug('Update Containers - Error', error)

    setContainersLoading(false)
    setContainersError(error)
    setContainersUUID(v4())

    return
  }

  if (data) {
    debug('Update Containers - Data', data)

    const newContainers = data.container

    setContainersLoading(false)
    setContainersError(null)
    setContainers(newContainers)
    setContainersUUID(v4())
  }
}

const UpdateHarvestGroups = ({
  loading,
  error,
  data,
  setHarvestGroupsLoading,
  setHarvestGroupsError,
  setHarvestGroups,
  setHarvestGroupsUUID
}: {
  loading: boolean
  error: GraphQLError
  data: IModelList<IHarvestGroup>
  setHarvestGroupsLoading: ISetStateType<boolean>
  setHarvestGroupsError: ISetStateType<GraphQLError>
  setHarvestGroups: ISetStateType<Array<IHarvestGroup>>
  setHarvestGroupsUUID: ISetStateType<string>
}) => {
  if (loading) {
    debug('Update HarvestGroup - Loading')

    setHarvestGroupsLoading(true)
    setHarvestGroupsUUID(v4())

    return
  }

  if (error) {
    debug('Update HarvestGroup - Error', error)

    setHarvestGroupsLoading(false)
    setHarvestGroupsError(error)
    setHarvestGroupsUUID(v4())

    return
  }

  if (data) {
    debug('Update HarvestGroup - Data', data)

    const newHGs = data.harvestGroup

    setHarvestGroupsLoading(false)
    setHarvestGroupsError(null)
    setHarvestGroups(newHGs)
    setHarvestGroupsUUID(v4())
  }
}

const UpdateInventoryStitches = ({
  time,
  loading,
  error,
  data,
  setInventoryStitchesLoading,
  setInventoryStitchesError,
  setInventoryStitches,
  setInventoryStitchesTime,
  setInventoryStitchesUUID
}: {
  time: ITimeType
  loading: boolean
  error: GraphQLError
  data: IModelList<IInventoryStitch>
  setInventoryStitchesLoading: ISetStateType<boolean>
  setInventoryStitchesError: ISetStateType<GraphQLError>
  setInventoryStitches: ISetStateType<Array<IInventoryStitch>>
  setInventoryStitchesTime: ISetStateType<Date>
  setInventoryStitchesUUID: ISetStateType<string>
}) => {
  if (loading) {
    debug('Update Inventory Stitches - Loading')

    setInventoryStitchesLoading(true)
    setInventoryStitchesTime(time.now)
    setInventoryStitchesUUID(v4())

    return
  }

  if (error) {
    debug('Update Inventory Stitches - Error', error)

    setInventoryStitchesLoading(false)
    setInventoryStitchesError(error)
    setInventoryStitchesTime(time.now)
    setInventoryStitchesUUID(v4())

    return
  }

  if (data) {
    debug('Update Inventory Stitches - Data', data)

    const newInventoryStitches = data.inventoryStitch

    setInventoryStitchesLoading(false)
    setInventoryStitchesError(null)
    setInventoryStitches(newInventoryStitches)
    setInventoryStitchesTime(time.now)
    setInventoryStitchesUUID(v4())
  }
}

const UpdateContainerStitches = ({
  time,
  loading,
  error,
  data,
  setContainerStitchesLoading,
  setContainerStitchesError,
  setContainerStitches,
  setContainerStitchesTime,
  setContainerStitchesUUID
}: {
  time: ITimeType
  loading: boolean
  error: GraphQLError
  data: IModelList<IContainerStich>
  setContainerStitchesLoading: ISetStateType<boolean>
  setContainerStitchesError: ISetStateType<GraphQLError>
  setContainerStitches: ISetStateType<Array<IContainerStich>>
  setContainerStitchesTime: ISetStateType<Date>
  setContainerStitchesUUID: ISetStateType<string>
}) => {
  if (loading) {
    debug('Update Container Stitches - Loading')

    setContainerStitchesLoading(true)
    setContainerStitchesTime(time.now)
    setContainerStitchesUUID(v4())

    return
  }

  if (error) {
    debug('Update Container Stitches - Error', error)

    setContainerStitchesLoading(false)
    setContainerStitchesError(error)
    setContainerStitchesTime(time.now)
    setContainerStitchesUUID(v4())

    return
  }

  if (data) {
    debug('Update Container Stitches - Data', data)

    const newContainerStitches = data.containerStitch

    setContainerStitchesLoading(false)
    setContainerStitchesError(null)
    setContainerStitches(newContainerStitches)
    setContainerStitchesTime(time.now)
    setContainerStitchesUUID(v4())
  }
}

const UpdateCV = ({
  time,
  loading,
  error,
  data,
  setHeatmapsLoading,
  setBudDetectionsLoading,
  setCanopyCoveragesLoading,
  setCanopyHeightsLoading,
  setFlowerCoveragesLoading,
  setGerminationRatesLoading,
  setTopCanopyCoveragesLoading,
  setTopGerminationRatesLoading,
  setPotCountsLoading,
  setReadinessCoveragesLoading,
  setYieldPredictionAveragesLoading,
  setHeatmapsError,
  setBudDetectionsError,
  setCanopyCoveragesError,
  setCanopyHeightsError,
  setFlowerCoveragesError,
  setGerminationRatesError,
  setTopCanopyCoveragesError,
  setTopGerminationRatesError,
  setPotCountsError,
  setReadinessCoveragesError,
  setYieldPredictionAveragesError,
  setHeatmaps,
  setBudDetections,
  setCanopyCoverages,
  setCanopyHeights,
  setFlowerCoverages,
  setGerminationRates,
  setTopCanopyCoverages,
  setTopGerminationRates,
  setPotCounts,
  setReadinessCoverages,
  setYieldPredictionAverages,
  setBoardCount,
  setHeatmapsTime,
  setBudDetectionsTime,
  setCanopyCoveragesTime,
  setCanopyHeightsTime,
  setFlowerCoveragesTime,
  setGerminationRatesTime,
  setTopCanopyCoveragesTime,
  setTopGerminationRatesTime,
  setPotCountsTime,
  setReadinessCoveragesTime,
  setYieldPredictionAveragesTime,
  setHeatmapsUUID,
  setBudDetectionsUUID,
  setCanopyCoveragesUUID,
  setCanopyHeightsUUID,
  setFlowerCoveragesUUID,
  setGerminationRatesUUID,
  setTopCanopyCoveragesUUID,
  setTopGerminationRatesUUID,
  setPotCountsUUID,
  setReadinessCoveragesUUID,
  setYieldPredictionAveragesUUID
}: {
  time: ITimeType
  loading: boolean
  error: GraphQLError
  data: IModelList<IHeatmap>
  setHeatmapsLoading: ISetStateType<boolean>
  setBudDetectionsLoading: ISetStateType<boolean>
  setCanopyCoveragesLoading: ISetStateType<boolean>
  setCanopyHeightsLoading: ISetStateType<boolean>
  setFlowerCoveragesLoading: ISetStateType<boolean>
  setGerminationRatesLoading: ISetStateType<boolean>
  setTopCanopyCoveragesLoading: ISetStateType<boolean>
  setTopGerminationRatesLoading: ISetStateType<boolean>
  setPotCountsLoading: ISetStateType<boolean>
  setReadinessCoveragesLoading: ISetStateType<boolean>
  setYieldPredictionAveragesLoading: ISetStateType<boolean>
  setHeatmapsError: ISetStateType<GraphQLError>
  setBudDetectionsError: ISetStateType<GraphQLError>
  setCanopyCoveragesError: ISetStateType<GraphQLError>
  setCanopyHeightsError: ISetStateType<GraphQLError>
  setFlowerCoveragesError: ISetStateType<GraphQLError>
  setGerminationRatesError: ISetStateType<GraphQLError>
  setTopCanopyCoveragesError: ISetStateType<GraphQLError>
  setTopGerminationRatesError: ISetStateType<GraphQLError>
  setPotCountsError: ISetStateType<GraphQLError>
  setReadinessCoveragesError: ISetStateType<GraphQLError>
  setYieldPredictionAveragesError: ISetStateType<GraphQLError>
  setHeatmaps: ISetStateType<Array<IHeatmap>>
  setBudDetections: ISetStateType<Array<any>>
  setCanopyCoverages: ISetStateType<Array<any>>
  setCanopyHeights: ISetStateType<Array<any>>
  setFlowerCoverages: ISetStateType<Array<any>>
  setGerminationRates: ISetStateType<Array<any>>
  setTopCanopyCoverages: ISetStateType<Array<any>>
  setTopGerminationRates: ISetStateType<Array<any>>
  setPotCounts: ISetStateType<Array<any>>
  setReadinessCoverages: ISetStateType<Array<any>>
  setYieldPredictionAverages: ISetStateType<Array<any>>
  setBoardCount: ISetStateType<Array<any>>
  setHeatmapsTime: ISetStateType<Date>
  setBudDetectionsTime: ISetStateType<Date>
  setCanopyCoveragesTime: ISetStateType<Date>
  setCanopyHeightsTime: ISetStateType<Date>
  setFlowerCoveragesTime: ISetStateType<Date>
  setGerminationRatesTime: ISetStateType<Date>
  setTopCanopyCoveragesTime: ISetStateType<Date>
  setTopGerminationRatesTime: ISetStateType<Date>
  setPotCountsTime: ISetStateType<Date>
  setReadinessCoveragesTime: ISetStateType<Date>
  setYieldPredictionAveragesTime: ISetStateType<Date>
  setHeatmapsUUID: ISetStateType<string>
  setBudDetectionsUUID: ISetStateType<string>
  setCanopyCoveragesUUID: ISetStateType<string>
  setCanopyHeightsUUID: ISetStateType<string>
  setFlowerCoveragesUUID: ISetStateType<string>
  setGerminationRatesUUID: ISetStateType<string>
  setTopCanopyCoveragesUUID: ISetStateType<string>
  setTopGerminationRatesUUID: ISetStateType<string>
  setPotCountsUUID: ISetStateType<string>
  setReadinessCoveragesUUID: ISetStateType<string>
  setYieldPredictionAveragesUUID: ISetStateType<string>
}) => {
  if (loading) {
    debug('Update CV - Loading')

    setHeatmapsLoading(true)
    setBudDetectionsLoading(true)
    setCanopyCoveragesLoading(true)
    setCanopyHeightsLoading(true)
    setFlowerCoveragesLoading(true)
    setGerminationRatesLoading(true)
    setTopCanopyCoveragesLoading(true)
    setTopGerminationRatesLoading(true)
    setPotCountsLoading(true)
    setReadinessCoveragesLoading(true)
    setYieldPredictionAveragesLoading(true)

    setHeatmapsTime(time.now)
    setBudDetectionsTime(time.now)
    setCanopyCoveragesTime(time.now)
    setCanopyHeightsTime(time.now)
    setFlowerCoveragesTime(time.now)
    setGerminationRatesTime(time.now)
    setTopCanopyCoveragesTime(time.now)
    setTopGerminationRatesTime(time.now)
    setPotCountsTime(time.now)
    setReadinessCoveragesTime(time.now)
    setYieldPredictionAveragesTime(time.now)

    setHeatmapsUUID(v4())
    setBudDetectionsUUID(v4())
    setCanopyCoveragesUUID(v4())
    setCanopyHeightsUUID(v4())
    setFlowerCoveragesUUID(v4())
    setGerminationRatesUUID(v4())
    setTopCanopyCoveragesUUID(v4())
    setTopGerminationRatesUUID(v4())
    setPotCountsUUID(v4())
    setReadinessCoveragesUUID(v4())
    setYieldPredictionAveragesUUID(v4())

    return
  }

  if (error) {
    debug('Update CV - Error', error)

    setHeatmapsLoading(false)
    setBudDetectionsLoading(false)
    setCanopyCoveragesLoading(false)
    setCanopyHeightsLoading(false)
    setFlowerCoveragesLoading(false)
    setGerminationRatesLoading(false)
    setTopCanopyCoveragesLoading(false)
    setTopGerminationRatesLoading(false)
    setPotCountsLoading(false)
    setReadinessCoveragesLoading(false)
    setYieldPredictionAveragesLoading(false)

    setHeatmapsError(error)
    setBudDetectionsError(error)
    setCanopyCoveragesError(error)
    setCanopyHeightsError(error)
    setFlowerCoveragesError(error)
    setGerminationRatesError(error)
    setTopCanopyCoveragesError(error)
    setTopGerminationRatesError(error)
    setPotCountsError(error)
    setReadinessCoveragesError(error)
    setYieldPredictionAveragesError(error)

    setHeatmapsTime(time.now)
    setBudDetectionsTime(time.now)
    setCanopyCoveragesTime(time.now)
    setCanopyHeightsTime(time.now)
    setFlowerCoveragesTime(time.now)
    setGerminationRatesTime(time.now)
    setTopCanopyCoveragesTime(time.now)
    setTopGerminationRatesTime(time.now)
    setPotCountsTime(time.now)
    setReadinessCoveragesTime(time.now)
    setYieldPredictionAveragesTime(time.now)

    setHeatmapsUUID(v4())
    setBudDetectionsUUID(v4())
    setCanopyCoveragesUUID(v4())
    setCanopyHeightsUUID(v4())
    setFlowerCoveragesUUID(v4())
    setGerminationRatesUUID(v4())
    setTopCanopyCoveragesUUID(v4())
    setTopGerminationRatesUUID(v4())
    setPotCountsUUID(v4())
    setYieldPredictionAveragesUUID(v4())

    return
  }

  if (data) {
    debug('Update CV - Data', data)

    const {
      heatmap: newHeatmaps,
      budDetection: newBudDetections,
      canopyCoverage: newCanopyCoverages,
      canopyHeight: newCanopyHeights,
      flowerCoverage: newFlowerCoverages,
      germinationRate: newGerminationRates,
      topCanopyCoverage: newTopCanopyCoverages,
      topGerminationRate: newTopGerminationRates,
      potCount: newPotCounts,
      readinessCoverage: newReadinessCoverages,
      yieldPrediction: newYieldPredictionAverages,
      boardCount: newBoardCount
    } = data

    setHeatmapsLoading(false)
    setBudDetectionsLoading(false)
    setCanopyCoveragesLoading(false)
    setCanopyHeightsLoading(false)
    setFlowerCoveragesLoading(false)
    setGerminationRatesLoading(false)
    setTopCanopyCoveragesLoading(false)
    setTopGerminationRatesLoading(false)
    setPotCountsLoading(false)
    setReadinessCoveragesLoading(false)
    setYieldPredictionAveragesLoading(false)

    setHeatmapsError(null)
    setBudDetectionsError(null)
    setCanopyCoveragesError(null)
    setCanopyHeightsError(null)
    setFlowerCoveragesError(null)
    setGerminationRatesError(null)
    setTopCanopyCoveragesError(null)
    setTopGerminationRatesError(null)
    setPotCountsError(null)
    setReadinessCoveragesError(null)
    setYieldPredictionAveragesError(null)

    setHeatmaps(newHeatmaps)
    setBudDetections(newBudDetections)
    setCanopyCoverages(newCanopyCoverages)
    setCanopyHeights(newCanopyHeights)
    setFlowerCoverages(newFlowerCoverages)
    setGerminationRates(newGerminationRates)
    setTopCanopyCoverages(newTopCanopyCoverages)
    setTopGerminationRates(newTopGerminationRates)
    setPotCounts(newPotCounts)
    setReadinessCoverages(newReadinessCoverages)
    setYieldPredictionAverages(newYieldPredictionAverages)
    setBoardCount(newBoardCount ?? [])

    setHeatmapsTime(time.now)
    setBudDetectionsTime(time.now)
    setCanopyCoveragesTime(time.now)
    setCanopyHeightsTime(time.now)
    setFlowerCoveragesTime(time.now)
    setGerminationRatesTime(time.now)
    setTopCanopyCoveragesTime(time.now)
    setTopGerminationRatesTime(time.now)
    setPotCountsTime(time.now)
    setReadinessCoveragesTime(time.now)
    setYieldPredictionAveragesTime(time.now)

    setHeatmapsUUID(v4())
    setBudDetectionsUUID(v4())
    setCanopyCoveragesUUID(v4())
    setCanopyHeightsUUID(v4())
    setFlowerCoveragesUUID(v4())
    setGerminationRatesUUID(v4())
    setTopCanopyCoveragesUUID(v4())
    setTopGerminationRatesUUID(v4())
    setPotCountsUUID(v4())
    setReadinessCoveragesUUID(v4())
    setYieldPredictionAveragesUUID(v4())
  }
}

const UpdateSpaceCharts = ({
  spacesLoading,
  spacesError,
  spaces,

  sensorsLoading,
  sensorsError,
  sensors,
  waterSystemsLoading,
  waterSystemsError,
  waterSystems,

  setSpaceChartsLoading,
  setSpaceChartsError,
  setSpaceCharts,
  setSpaceChartsUUID
}: {
  spacesLoading: boolean
  spacesError: GraphQLError
  spaces: Array<ISpace>
  sensorsLoading: boolean
  sensorsError: GraphQLError
  sensors: Array<ISensorRecord>
  waterSystemsLoading: boolean
  waterSystemsError: GraphQLError
  waterSystems: Array<IGreenAutomationWaterSystemRecord>

  setSpaceChartsLoading: ISetStateType<boolean>
  setSpaceChartsError: ISetStateType<GraphQLError>
  setSpaceCharts: ISetStateType<Array<IChart>>
  setSpaceChartsUUID: ISetStateType<string>
}) => {
  if (
    spacesLoading ||
    sensorsLoading ||
    waterSystemsLoading
  ) {
    debug('Update Space Charts - Loading')

    setSpaceChartsLoading(true)
    setSpaceChartsUUID(v4())

    return
  }

  if (
    spacesError ||
    sensorsError ||
    waterSystemsError
  ) {
    const error =
      spacesError ||
      sensorsError ||
      waterSystemsError

    debug('Update Space Charts - Error', error)

    setSpaceChartsLoading(false)
    setSpaceChartsError(error)
    setSpaceChartsUUID(v4())

    return
  }

  if (
    spaces &&
    sensors &&
    waterSystems
  ) {
    const newSpaceCharts = spaces
      .map((space: ISpace) => {
        const newSpace = { ...space }

        const spaceSensors = sensors
          .filter((sensor: ISensorRecord) => String(sensor.spaceId) === String(newSpace.id))

        if (spaceSensors.length > 0) {
          newSpace.sensor = spaceSensors
        }
        const spaceWaterSystemRecords = waterSystems
          .filter((waterSystemRecord: IGreenAutomationWaterSystemRecord): boolean =>
            newSpace.vendorCustomerFacilityIds?.includes(waterSystemRecord.vendorCustomerFacilityId)
          )
        newSpace.waterSystemRecords = spaceWaterSystemRecords

        return newSpace
      })
      .map((space: ISpace) => [
        NewChart(space, EChart.AbsoluteHumidity),
        NewChart(space, EChart.DLI),
        NewChart(space, EChart.Humidity),
        NewChart(space, EChart.Temperature),
        NewChart(space, EChart.umol),
        NewChart(space, EChart.VPD),
        NewChart(space, EChart.CO2),

        NewChart(space, EChart.GreenAutomationWaterSystemEC1),
        NewChart(space, EChart.GreenAutomationWaterSystemEC2),
        NewChart(space, EChart.GreenAutomationWaterSystemPH1),
        NewChart(space, EChart.GreenAutomationWaterSystemPH2),
        NewChart(space, EChart.GreenAutomationWaterSystemDissolvedO2),
        NewChart(space, EChart.GreenAutomationWaterSystemWaterTemp)
      ])
      .flat()
      .filter((chart: IChart) => !!chart)

    setSpaceChartsLoading(false)
    setSpaceChartsError(null)
    setSpaceCharts(newSpaceCharts)
    setSpaceChartsUUID(v4())
  }
}

const UpdateInventoryCharts = ({
  inventoryIDs,
  inventoriesLoading,
  inventoriesError,
  inventories,
  budDetectionsLoading,
  budDetectionsError,
  budDetections,
  canopyCoveragesLoading,
  canopyCoveragesError,
  canopyCoverages,
  canopyHeightsLoading,
  canopyHeightsError,
  canopyHeights,
  flowerCoveragesLoading,
  flowerCoveragesError,
  flowerCoverages,
  germinationRatesLoading,
  germinationRatesError,
  germinationRates,
  setInventoryChartsLoading,
  setInventoryChartsError,
  setInventoryCharts,
  setInventoryChartsUUID
}: {
  inventoryIDs: Array<number>
  inventoriesLoading: boolean
  inventoriesError: GraphQLError
  inventories: Array<IInventory>
  budDetectionsLoading: boolean
  budDetectionsError: GraphQLError
  budDetections: Array<IBudDetection>
  canopyCoveragesLoading: boolean
  canopyCoveragesError: GraphQLError
  canopyCoverages: Array<ICanopyCoverage>
  canopyHeightsLoading: boolean
  canopyHeightsError: GraphQLError
  canopyHeights: Array<ICanopyHeight>
  flowerCoveragesLoading: boolean
  flowerCoveragesError: GraphQLError
  flowerCoverages: Array<IFlowerCoverage>
  germinationRatesLoading: boolean
  germinationRatesError: GraphQLError
  germinationRates: Array<IGerminationRate>
  setInventoryChartsLoading: ISetStateType<boolean>
  setInventoryChartsError: ISetStateType<GraphQLError>
  setInventoryCharts: ISetStateType<Array<IChart>>
  setInventoryChartsUUID: ISetStateType<string>
}) => {
  if (
    inventoryIDs &&
    inventoryIDs.length > 0 &&
    (
      inventoriesLoading ||
      budDetectionsLoading ||
      canopyCoveragesLoading ||
      canopyHeightsLoading ||
      flowerCoveragesLoading ||
      germinationRatesLoading
    )
  ) {
    debug('Update Inventory Charts - Loading')
    setInventoryChartsLoading(true)
    setInventoryChartsUUID(v4())

    return
  }

  if (
    inventoryIDs &&
    inventoryIDs.length > 0 &&
    (
      inventoriesError ||
      budDetectionsError ||
      canopyCoveragesError ||
      canopyHeightsError ||
      flowerCoveragesError ||
      germinationRatesError
    )
  ) {
    const error =
      inventoriesError ||
      budDetectionsError ||
      canopyCoveragesError ||
      canopyHeightsError ||
      flowerCoveragesError ||
      germinationRatesError

    debug('Update Inventory Charts - Error', error)

    setInventoryChartsLoading(false)
    setInventoryChartsError(error)
    setInventoryChartsUUID(v4())

    return
  }
  if (
    inventories &&
    budDetections &&
    canopyCoverages &&
    canopyHeights &&
    flowerCoverages &&
    germinationRates
  ) {
    const newInventoryCharts = inventories
      .map(inventory => {
        const newInventory = { ...inventory }

        const inventoryBudDetection = budDetections
          .find(budDetection => String(budDetection.inventoryId) === String(newInventory.id))

        const inventoryCanopyCoverage = canopyCoverages
          .find(canopyCoverage => String(canopyCoverage.inventoryId) === String(newInventory.id))

        const inventoryCanopyHeight = canopyHeights
          .find(canopyHeight => String(canopyHeight.inventoryId) === String(newInventory.id))

        const inventoryFlowerCoverage = flowerCoverages
          .find(flowerCoverage => String(flowerCoverage.inventoryId) === String(newInventory.id))

        const inventoryGerminationRate = germinationRates
          .find(germinationRate => String(germinationRate.inventoryId) === String(newInventory.id))

        if (inventoryBudDetection) {
          newInventory.budDetection = [inventoryBudDetection]
        }

        if (inventoryCanopyCoverage) {
          newInventory.canopyCoverage = [inventoryCanopyCoverage]
        }

        if (inventoryCanopyHeight) {
          // FIXME: Converts meters to centimeters. Move logic to GraphQL
          newInventory.canopyHeight = [
            {
              ...inventoryCanopyHeight,
              data: inventoryCanopyHeight.data.map(([date, value]) => [
                date,
                value * 100
              ]),
            },
          ];
        }

        if (inventoryFlowerCoverage) {
          newInventory.flowerCoverage = [inventoryFlowerCoverage]
        }

        if (inventoryGerminationRate) {
          newInventory.germinationRate = [inventoryGerminationRate]
        }

        return newInventory
      })
      .map(inventory => [
        NewChart(inventory, EChart.BudDetectionData),
        NewChart(inventory, EChart.BudDetectionDensity),
        NewChart(inventory, EChart.CanopyCoverageData),
        NewChart(inventory, EChart.CanopyCoverageDelta),
        NewChart(inventory, EChart.CanopyHeightData),
        NewChart(inventory, EChart.CanopyHeightDelta),
        NewChart(inventory, EChart.FlowerCoverageData),
        NewChart(inventory, EChart.FlowerCoverageDelta),
        NewChart(inventory, EChart.GerminationRate),
        NewChart(inventory, EChart.GerminationRatePercent),
        NewChart(inventory, EChart.YieldPrediction)
      ])
      .flat()
      .filter(chart => !!chart)

    setInventoryChartsLoading(false)
    setInventoryChartsError(null)
    setInventoryCharts(newInventoryCharts)
    setInventoryChartsUUID(v4())
  }
}

const UpdateContainerCharts = ({
  inventoriesLoading,
  inventoriesError,
  inventories,

  containerIDs,
  containersLoading,
  containersError,
  containers,

  budDetectionsLoading,
  budDetectionsError,
  budDetections,

  canopyCoveragesLoading,
  canopyCoveragesError,
  canopyCoverages,

  canopyHeightsLoading,
  canopyHeightsError,
  canopyHeights,

  flowerCoveragesLoading,
  flowerCoveragesError,
  flowerCoverages,

  germinationRatesLoading,
  germinationRatesError,
  germinationRates,

  yieldPredictionAveragesLoading,
  yieldPredictionAveragesError,
  yieldPredictionAverages,

  topCanopyCoveragesLoading,
  topCanopyCoveragesError,
  topCanopyCoverages,

  topGerminationRatesLoading,
  topGerminationRatesError,
  topGerminationRates,

  potCountsLoading,
  potCountsError,
  potCounts,

  readinessCoveragesLoading,
  readinessCoveragesError,
  readinessCoverages,

  harvestGroupsLoading,
  harvestGroupsError,
  harvestGroups,

  boardCountLoading,
  boardCountError,
  boardCount,

  setContainerChartsLoading,
  setContainerChartsError,
  setContainerCharts,
  setContainerChartsUUID
}: {
  inventoriesLoading: boolean
  inventoriesError: GraphQLError
  inventories: Array<IInventory>

  containerIDs: Array<ID>
  containersLoading: boolean
  containersError: GraphQLError
  containers: Array<IContainer>

  budDetectionsLoading: boolean
  budDetectionsError: GraphQLError
  budDetections: Array<IBudDetection>

  canopyCoveragesLoading: boolean
  canopyCoveragesError: GraphQLError
  canopyCoverages: Array<ICanopyCoverage>

  canopyHeightsLoading: boolean
  canopyHeightsError: GraphQLError
  canopyHeights: Array<ICanopyHeight>

  flowerCoveragesLoading: boolean
  flowerCoveragesError: GraphQLError
  flowerCoverages: Array<IFlowerCoverage>

  germinationRatesLoading: boolean
  germinationRatesError: GraphQLError
  germinationRates: Array<IGerminationRate>

  yieldPredictionAveragesLoading: boolean
  yieldPredictionAveragesError: GraphQLError
  yieldPredictionAverages: Array<IYieldPredictionAverage>
  topCanopyCoveragesLoading: boolean
  topCanopyCoveragesError: GraphQLError
  topCanopyCoverages: Array<ITopCanopyCoverage>

  topGerminationRatesLoading: boolean
  topGerminationRatesError: GraphQLError
  topGerminationRates: Array<ITopGerminationRate>

  potCountsLoading: boolean
  potCountsError: GraphQLError
  potCounts: Array<IPotCount>

  readinessCoveragesLoading: boolean
  readinessCoveragesError: GraphQLError
  readinessCoverages: Array<any>

  harvestGroupsLoading: boolean
  harvestGroupsError: GraphQLError
  harvestGroups: Array<IHarvestGroup>

  boardCountLoading: boolean
  boardCountError: GraphQLError
  boardCount: Array<IBoardCount>

  setContainerChartsLoading: ISetStateType<boolean>
  setContainerChartsError: ISetStateType<GraphQLError>
  setContainerCharts: ISetStateType<Array<IChart>>
  setContainerChartsUUID: ISetStateType<string>
}) => {
  if (
    containerIDs &&
    containerIDs.length > 0 &&
    (
      inventoriesLoading ||
      containersLoading ||
      budDetectionsLoading ||
      canopyCoveragesLoading ||
      canopyHeightsLoading ||
      flowerCoveragesLoading ||
      germinationRatesLoading ||
      topCanopyCoveragesLoading ||
      topGerminationRatesLoading ||
      potCountsLoading ||
      readinessCoveragesLoading ||
      // harvestGroupsLoading ||
      yieldPredictionAveragesLoading ||
      boardCountLoading
    )
  ) {
    debug('Update Container Charts - Loading')

    setContainerChartsLoading(true)
    setContainerChartsUUID(v4())

    return
  }

  if (
    containerIDs &&
    containerIDs.length > 0 &&
    (
      inventoriesError ||
      containersError ||
      budDetectionsError ||
      canopyCoveragesError ||
      canopyHeightsError ||
      flowerCoveragesError ||
      germinationRatesError ||
      topCanopyCoveragesError ||
      topGerminationRatesError ||
      harvestGroupsError ||
      yieldPredictionAveragesError ||
      boardCountError
    )
  ) {
    const error =
      inventoriesError ||
      containersError ||
      budDetectionsError ||
      canopyCoveragesError ||
      canopyHeightsError ||
      flowerCoveragesError ||
      germinationRatesError ||
      topCanopyCoveragesError ||
      topGerminationRatesError ||
      harvestGroupsError ||
      yieldPredictionAveragesError ||
      boardCountError
    debug('Update Container Charts - Error', error)

    setContainerChartsLoading(false)
    setContainerChartsError(error)
    setContainerChartsUUID(v4())

    return
  }
  if (
    inventories &&
    containers &&
    budDetections &&
    canopyCoverages &&
    canopyHeights &&
    flowerCoverages &&
    germinationRates &&
    // harvestGroups &&
    topCanopyCoverages &&
    topGerminationRates &&
    potCounts &&
    readinessCoverages &&
    yieldPredictionAverages
  ) {
    const newContainerCharts = containers
      .map(container => {
        const newContainer = { ...container }

        const containerInventory = inventories
          .find(inventory => String(inventory.id) === String(container.inventoryId))

        const containerBudDetection = budDetections
          .find(budDetection => String(budDetection.containerId) === String(newContainer.id))

        const containerCanopyCoverage = canopyCoverages
          .find(canopyCoverage => String(canopyCoverage.containerId) === String(newContainer.id))

        const containerCanopyHeight = canopyHeights
          .find(canopyHeight => String(canopyHeight.containerId) === String(newContainer.id))

        const containerFlowerCoverage = flowerCoverages
          .find(flowerCoverage => String(flowerCoverage.containerId) === String(newContainer.id))

        const containerGerminationRate = germinationRates
          .find(germinationRate => String(germinationRate.containerId) === String(newContainer.id))

        const containerTopCanopyCoverage = topCanopyCoverages
          .find(canopyCoverage => String(canopyCoverage.inventoryId) === String(newContainer.inventoryId))

        const containerTopGerminationRate = topGerminationRates
          .find(germinationRate => String(germinationRate.inventoryId) === String(newContainer.inventoryId))

        const containerPotCount = potCounts
          .find(datum => String(datum.inventoryId) === String(newContainer.inventoryId))

        const containerReadinessCoverage = readinessCoverages
          .find(datum => String(datum.inventoryId) === String(newContainer.inventoryId))

        const containerYieldPredictionAverages = yieldPredictionAverages
          .find(datum => String(datum.inventoryId) === String(newContainer.inventoryId))

        const containerBoardCount = boardCount
          .find(datum => String(datum.inventoryId) === String(newContainer.inventoryId))

        newContainer.inventory = [containerInventory]

        if (containerBudDetection) {
          newContainer.budDetection = [containerBudDetection]
        }

        if (containerCanopyCoverage) {
          newContainer.canopyCoverage = [containerCanopyCoverage]
        }

        if (containerCanopyHeight) {
          // FIXME: Converts meters to centimeters. Move logic to GraphQL
          // https://iunuinc.atlassian.net/browse/SW-5223
          newContainer.canopyHeight = [
            {
              ...containerCanopyHeight,
              data: containerCanopyHeight.data.map(([date, value]) => [
                date,
                value * 100
              ]),
            },
          ];
        }

        if (containerFlowerCoverage) {
          newContainer.flowerCoverage = [containerFlowerCoverage]
        }

        if (containerGerminationRate) {
          newContainer.germinationRate = [containerGerminationRate]
        }

        if (containerCanopyCoverage && containerTopCanopyCoverage) {
          newContainer.topCanopyCoverage = [containerTopCanopyCoverage]
        }

        if (containerGerminationRate && containerTopGerminationRate) {
          newContainer.topGerminationRate = [containerTopGerminationRate]
        }

        if (containerPotCount) {
          newContainer.potCount = [containerPotCount]
        }

        if (containerReadinessCoverage) {
          newContainer.readinessCoverage = [containerReadinessCoverage]
        }

        if (containerYieldPredictionAverages) {
          const newArr = containerYieldPredictionAverages.data as ObjectArrayType
          newContainer.yieldPredictionAverages = [{
            count: lowestNonZeroValue(newArr, 'value')
          }]
          newContainer.totalWeight = [{
            count: lowestNonZeroValue(newArr, 'totalWeight')
          }]
        }

        if (containerBoardCount?.count != null) {
          newContainer.boardCount = [containerBoardCount]
        }

        if (harvestGroups?.length > 0 && container.inventoryId) {
          newContainer.harvestGroups = harvestGroups.reduce(
            (groups, hg) => {
              if (String(hg.inventoryId) === String(container.inventoryId)) {
                groups.push(hg)
              }
              return groups
            },
            []
          )
        }

        return newContainer
      })
      .map(container => {
        return [
          NewChart(container, EChart.BudDetectionData),
          NewChart(container, EChart.BudDetectionDensity),
          NewChart(container, EChart.CanopyCoverageData),
          NewChart(container, EChart.CanopyCoverageDelta),
          NewChart(container, EChart.CanopyHeightData),
          NewChart(container, EChart.CanopyHeightDelta),
          NewChart(container, EChart.FlowerCoverageData),
          NewChart(container, EChart.FlowerCoverageDelta),
          NewChart(container, EChart.GerminationRate),
          NewChart(container, EChart.GerminationRatePercent),
          NewChart(container, EChart.HWC),
          NewChart(container, EChart.TopCanopyCoverageData),
          NewChart(container, EChart.TopCanopyCoverageDelta),
          NewChart(container, EChart.TopGerminationRate),
          NewChart(container, EChart.PotCount),
          NewChart(container, EChart.ReadinessCoverage),
          NewChart(container, EChart.YieldPrediction),
          NewChart(container, EChart.BoardCount),
          NewChart(container, EChart.TotalWeight)
        ]
      })
      .flat()
      .filter(chart => !!chart)

    setContainerChartsLoading(false)
    setContainerChartsError(null)
    setContainerCharts(newContainerCharts)
    setContainerChartsUUID(v4())
  }
}

const UpdateTasks = ({
  time,
  loading,
  error,
  data,
  setTasksLoading,
  setTasksError,
  setTasks,
  setTasksTime,
  setTasksUUID
}: {
  time: ITimeType
  loading: boolean
  error: GraphQLError
  data: IModelList<ITask>
  setTasksLoading: ISetStateType<boolean>
  setTasksError: ISetStateType<GraphQLError>
  setTasks: ISetStateType<Array<ITask>>
  setTasksTime: ISetStateType<Date>
  setTasksUUID: ISetStateType<string>
}) => {
  if (!time) {
    return
  }

  if (loading) {
    debug('Update Tasks - Loading')

    setTasksLoading(true)
    setTasksTime(time.now)
    setTasksUUID(v4())

    return
  }

  if (error) {
    debug('Update Tasks - Error', error)

    setTasksLoading(false)
    setTasksError(error)
    setTasksTime(time.now)
    setTasksUUID(v4())

    return
  }

  if (data) {
    debug('Update Tasks - Data', data)

    const newTasks = data.task

    setTasksLoading(false)
    setTasksError(null)
    setTasks(newTasks)
    setTasksTime(time.now)
    setTasksUUID(v4())
  }
}

const UpdateTaskIssues = ({
  time,
  loading,
  error,
  data,
  setTaskIssuesLoading,
  setTaskIssuesError,
  setTaskIssues,
  setTaskIssuesTime,
  setTaskIssuesUUID
}: {
  time: ITimeType
  loading: boolean
  error: GraphQLError
  data: IModelList<ITaskIssue>
  setTaskIssuesLoading: ISetStateType<boolean>
  setTaskIssuesError: ISetStateType<GraphQLError>
  setTaskIssues: ISetStateType<Array<ITaskIssue>>
  setTaskIssuesTime: ISetStateType<Date>
  setTaskIssuesUUID: ISetStateType<string>
}) => {
  if (!time) {
    return
  }

  if (loading) {
    debug('Update TaskIssues - Loading')

    setTaskIssuesLoading(true)
    setTaskIssuesTime(time.now)
    setTaskIssuesUUID(v4())

    return
  }

  if (error) {
    debug('Update TaskIssues - Error', error)

    setTaskIssuesLoading(false)
    setTaskIssuesError(error)
    setTaskIssuesTime(time.now)
    setTaskIssuesUUID(v4())

    return
  }

  if (data) {
    debug('Update TaskIssues - Data', data)

    const newTaskIssues = data.taskIssue

    setTaskIssuesLoading(false)
    setTaskIssuesError(null)
    setTaskIssues(newTaskIssues)
    setTaskIssuesTime(time.now)
    setTaskIssuesUUID(v4())
  }
}

const LogSelectedEntities = ({
  selectedEntitiesDiff,

  facilityLoading,
  buildingsLoading,
  spacesLoading,

  sensorsLoading,
  areacamsLoading,

  inventoriesLoading,
  inventoryStitchesLoading,
  heatmapsLoading,
  budDetectionsLoading,
  canopyCoveragesLoading,
  canopyHeightsLoading,
  flowerCoveragesLoading,
  germinationRatesLoading,

  facilityError,
  buildingsError,
  spacesError,

  sensorsError,
  areacamsError,

  inventoriesError,
  inventoryStitchesError,
  heatmapsError,
  budDetectionsError,
  canopyCoveragesError,
  canopyHeightsError,
  flowerCoveragesError,
  germinationRatesError,

  facility,
  buildings,
  spaces,

  sensors,
  areacams,

  inventories,
  inventoryStitches,
  heatmaps,
  budDetections,
  canopyCoverages,
  canopyHeights,
  flowerCoverages,
  germinationRates
}: {
  selectedEntitiesDiff: Array<IDiff<IEntity>>
  facilityLoading: boolean
  buildingsLoading: boolean
  spacesLoading: boolean
  sensorsLoading: boolean
  areacamsLoading: boolean
  inventoriesLoading: boolean
  inventoryStitchesLoading: boolean
  heatmapsLoading: boolean
  budDetectionsLoading: boolean
  canopyCoveragesLoading: boolean
  canopyHeightsLoading: boolean
  flowerCoveragesLoading: boolean
  germinationRatesLoading: boolean
  facilityError: GraphQLError
  buildingsError: GraphQLError
  spacesError: GraphQLError
  sensorsError: GraphQLError
  areacamsError: GraphQLError
  inventoriesError: GraphQLError
  inventoryStitchesError: GraphQLError
  heatmapsError: GraphQLError
  budDetectionsError: GraphQLError
  canopyCoveragesError: GraphQLError
  canopyHeightsError: GraphQLError
  flowerCoveragesError: GraphQLError
  germinationRatesError: GraphQLError
  facility: IFacility
  buildings: Array<IBuilding>
  spaces: Array<ISpace>
  sensors: Array<ISensorRecord>
  areacams: Array<IAreacam>
  inventories: Array<IInventory>
  inventoryStitches: Array<IInventoryStitch>
  heatmaps: Array<IHeatmap>
  budDetections: Array<IBudDetection>
  canopyCoverages: Array<ICanopyCoverage>
  canopyHeights: Array<ICanopyHeight>
  flowerCoverages: Array<IFlowerCoverage>
  germinationRates: Array<IGerminationRate>
}): void => {
  if (
    facilityLoading ||
    buildingsLoading ||
    spacesLoading ||

    sensorsLoading ||
    areacamsLoading ||

    inventoriesLoading ||
    inventoryStitchesLoading ||
    heatmapsLoading ||
    budDetectionsLoading ||
    canopyCoveragesLoading ||
    canopyHeightsLoading ||
    flowerCoveragesLoading ||
    germinationRatesLoading
  ) {
    debug('Log Selected Entities - Loading')
    return
  }

  if (
    facilityError ||
    buildingsError ||
    spacesError ||

    sensorsError ||
    areacamsError ||

    inventoriesError ||
    inventoryStitchesError ||
    heatmapsError ||
    budDetectionsError ||
    canopyCoveragesError ||
    canopyHeightsError ||
    flowerCoveragesError ||
    germinationRatesError
  ) {
    debug('Log Selected Entities - Error')
    return
  }

  if (
    facility &&
    buildings &&
    spaces &&

    sensors &&
    areacams &&

    inventories &&
    inventoryStitches &&
    heatmaps &&
    budDetections &&
    canopyCoverages &&
    canopyHeights &&
    flowerCoverages &&
    germinationRates
  ) {
    selectedEntitiesDiff
      .filter((diff): boolean => diff.type === EDiff.Add)
      .map(diff => {
        if (
          diff.value.type === EEntity.Facility &&
          String(diff.value.id) === String(facility.id)
        ) {
          return facility
        }

        if (diff.value.type === EEntity.Building) {
          return buildings
            .find((building): boolean => String(diff.value.id) === String(building.id))
        }

        if (diff.value.type === EEntity.Space) {
          return spaces
            .find((space): boolean => String(diff.value.id) === String(space.id))
        }

        if (diff.value.type === EEntity.SensorDevice) {
          return sensors
            .find((sensor): boolean => String(diff.value.id) === String(sensor.id))
        }

        if (diff.value.type === EEntity.Areacam) {
          return areacams
            .find((areacam): boolean => String(diff.value.id) === String(areacam.id))
        }

        if (diff.value.type === EEntity.Inventory) {
          return inventories
            .find((inventory): boolean => String(diff.value.id) === String(inventory.id))
        }
      })
      .filter((entity): boolean => !!entity)
      .forEach((entity): void => {
        console.log(entity)
      })
  }
}

export {
  UpdateTime,
  UpdateSelectedEntities,
  UpdateOverlays,
  UpdateStaticData,
  UpdateSensorIDs,
  UpdateSensors,
  UpdateWaterSystems,
  UpdateAreacamIDs,
  UpdateAreacams,
  UpdateGridPredictions,
  UpdateInventories,
  UpdateContainers,
  UpdateHarvestGroups,
  UpdateInventoryStitches,
  UpdateContainerStitches,
  UpdateCV,
  UpdateSpaceCharts,
  UpdateInventoryCharts,
  UpdateContainerCharts,
  UpdateTasks,
  UpdateTaskIssues,
  LogSelectedEntities
}

// Types
import ITime from 'grid-view/interfaces/time';
import IFacility from 'graphql-lib/interfaces/IFacility';
import IBuilding from 'graphql-lib/interfaces/IBuilding';
import ISpace from 'graphql-lib/interfaces/ISpace';
import { ISensorRecord, ICO2SensorRecord, ISensor } from 'graphql-lib/interfaces/ISensorDevice';
import IGreenAutomationWaterSystemRecord from 'graphql-lib/interfaces/IGreenAutomationWaterSystemRecord';
import IAreacam from 'graphql-lib/interfaces/IAreacam';
import IAreaImageRecord from 'graphql-lib/interfaces/IAreaImageRecord';
import ID from 'graphql-lib/interfaces/ID';

// Libs
import {
  isAfter,
  isEqual,
  isValid,
  subDays
} from 'date-fns'

import { createDate } from 'utils/dates'

import { v4 } from 'uuid'
import { parse, stringify } from 'query-string'

// React Libs
import history from 'react-lib/history'
import ISetStateType from 'graphql-lib/interfaces/ISetStateType'
import IModelList from 'graphql-lib/interfaces/IModelList';

/**
 * Update URL params based on `location.search`.
 */
const Search2URLParams = ({
  search,
  setProgrammaticTransaction,
  setURLParams
}: {
  search: string,
  setProgrammaticTransaction: (newVal: boolean) => void;
  setURLParams: (newVal: any) => void;
}): void => {
  const newURLParams = parse(search)
  setProgrammaticTransaction(false)
  setURLParams(newURLParams)
}

/**
 * Update `location.search` based on URL params.
 */
const URLParams2Search = ({
  pathname,
  programmaticTransaction,
  urlParams
}: {
  pathname: string;
  programmaticTransaction: boolean;
  urlParams: any;
}): void => {
  if (programmaticTransaction) {
    const newSearch = `${pathname}?${stringify(urlParams)}`
    history.push(newSearch)
  }
}

/**
 * Update time based on URL params.
 */
const URLParams2Time = ({
  urlParams,
  time,
  setProgrammaticTransaction,
  setURLParams,
  setTime
}: {
  urlParams: any;
  time: ITime;
  setProgrammaticTransaction: (newVal: boolean) => void;
  setURLParams: (newVal: any) => void;
  setTime: (newVal: ITime) => void;
}): void => {
  if (urlParams) {
    const timeString = urlParams.time
    const timeObject = createDate(timeString)
    const now = time
      ? time.now
      : null

    const isInvalidTime =
      typeof timeString !== 'string' ||
      !isValid(timeObject)

    const isDifferentTime =
      !isInvalidTime &&
      !isEqual(timeObject, now)

    if (isInvalidTime || isDifferentTime) {
      let newNow: Date
      if (isInvalidTime) {
        newNow = new Date()
      } else if (isDifferentTime) {
        const realNow = new Date()
        const _newNow = timeObject

        const isFutureTime = isAfter(_newNow, realNow)
        if (isFutureTime) {
          console.warn('Slow down there, Mr McFly!')
          newNow = realNow
        } else {
          newNow = _newNow
        }
      }

      const newSensorRecordsLookback = subDays(newNow, 1)

      const newURLParams = Object.assign(
        {},
        urlParams,
        { time: newNow.toISOString() }
      )

      const newTime: ITime = {
        now: newNow,
        sensorRecordsLookback: newSensorRecordsLookback
      }

      setProgrammaticTransaction(true)
      setURLParams(newURLParams)
      setTime(newTime)
    }
  }
}

/**
 * Update facility, buildings, and spaces based on static data.
 */
const UpdateStaticData = ({
  staticDataLoading,
  staticData,

  setFacility,
  setBuildings,
  setSpaces,

  setSpaceIDs,
  setStaticDataUUID
}: {
  staticDataLoading: boolean;
  staticData: any;

  setFacility: (newVal: IFacility) => void;
  setBuildings: (newVal: IBuilding[]) => void;
  setSpaces: (newVal: ISpace[]) => void;

  setSpaceIDs: (newVal: ID[]) => void;
  setStaticDataUUID: (newVal: string) => void;
}): void => {
  if (!staticDataLoading && staticData) {
    const newFacility: IFacility = staticData.facility[0]
    const newBuildings: IBuilding[] = newFacility.building
    const newSpaces: ISpace[] = newBuildings.reduce(
      (acc: ISpace[], cur: IBuilding): ISpace[] => [...acc, ...cur.space],
      []
    )

    const newSpaceIDs: ID[] = newSpaces.map((s: ISpace): ID => s.id)
    const newStaticDataUUID = v4()

    setFacility(newFacility)
    setBuildings(newBuildings)
    setSpaces(newSpaces)

    setSpaceIDs(newSpaceIDs)
    setStaticDataUUID(newStaticDataUUID)
  }
}

/**
 * Update environmental zones and environmental zone IDs based on environmental zone data.
 */
const UpdateEnvironmentalZones = ({
  environmentalZonesData,
  environmentalZonesLoading,

  setEnvironmentalZones,
  setEnvironmentalZoneIDs,

  setEnvironmentalZonesUUID
}: {
  environmentalZonesData: any;
  environmentalZonesLoading: boolean;

  setEnvironmentalZones: (newVal: any[]) => void;
  setEnvironmentalZoneIDs: (newVal: ID[]) => void;

  setEnvironmentalZonesUUID: (newVal: string) => void;
}): void => {
  if (environmentalZonesData && !environmentalZonesLoading) {
    const newEnvironmentalZones: any[] = environmentalZonesData.environmentalZone
    const newEnvironmentalZoneIDs: ID[] = newEnvironmentalZones.map((e: any): ID => e.id)
    const newEnvironmentalZonesUUID = v4()

    setEnvironmentalZones(newEnvironmentalZones)
    setEnvironmentalZoneIDs(newEnvironmentalZoneIDs)
    setEnvironmentalZonesUUID(newEnvironmentalZonesUUID)
  }
}

/**
 * Update sensor records and sensor IDs based on sensor record data.
 */
const UpdateSensorRecords = ({
  co2,
  sensorRecordsData,
  sensorRecordsLoading,
  setSensorRecords,
  setSensorIDs,
  setSensorRecordsUUID
}: {
  co2: boolean
  sensorRecordsData: any
  sensorRecordsLoading: boolean
  setSensorRecords: ISetStateType<Array<ISensorRecord | ICO2SensorRecord>>
  setSensorIDs: ISetStateType<Array<ID>>
  setSensorRecordsUUID: ISetStateType<string>
}): void => {
  if (sensorRecordsData && !sensorRecordsLoading) {
    const newSensorRecords: (ISensorRecord | ICO2SensorRecord)[] = co2
      ? sensorRecordsData.co2SensorRecord
      : sensorRecordsData.sensorRecord

    const newSensorIDs: ID[] = Object.keys(
      newSensorRecords
        .map((s): ID => s.sensorDeviceId)
        .reduce((a: Record<ID, boolean>, c: ID): Record<ID, boolean> =>
          Object.assign({}, a, { [c]: true }), {})
    )

    const newSensorRecordsUUID = v4()

    setSensorRecords(newSensorRecords)
    setSensorIDs(newSensorIDs)

    setSensorRecordsUUID(newSensorRecordsUUID)
  }
}

/**
 * Update all sensor IDs based on sensor IDs and CO2 sensor IDs.
 */
const UpdateAllSensorSupdata = ({
  sensorRecords,
  co2SensorRecords,
  sensorIDs,
  co2SensorIDs,

  setAllSensorRecords,
  setAllSensorIDs
}: {
  sensorRecords: Array<ISensorRecord>;
  co2SensorRecords: Array<ICO2SensorRecord>;
  sensorIDs: ID[];
  co2SensorIDs: ID[];

  setAllSensorRecords: (newVal: (ISensorRecord | ICO2SensorRecord)[]) => void;
  setAllSensorIDs: (newVal: ID[]) => void;
}): void => {
  let newAllSensorRecords: any[] = []
  let newAllSensorIDs: any[] = []

  if (
    sensorRecords?.length > 0 &&
    sensorIDs?.length > 0
  ) {
    newAllSensorRecords = [...newAllSensorRecords, ...sensorRecords]
    newAllSensorIDs = [...newAllSensorIDs, ...sensorIDs]
  }

  if (
    co2SensorRecords?.length > 0 &&
    co2SensorIDs?.length > 0
  ) {
    newAllSensorRecords = [...newAllSensorRecords, ...co2SensorRecords]
    newAllSensorIDs = [...newAllSensorIDs, ...co2SensorIDs]
  }

  setAllSensorRecords(newAllSensorRecords)
  setAllSensorIDs(newAllSensorIDs)
}

/**
 * Update sensors base on sensor data.
 */
const UpdateSensors = ({
  sensorsData,
  sensorsLoading,
  sensorRecords,

  setSensors,
  setSensorsUUID
}: {
  sensorsData: any;
  sensorsLoading: boolean;
  sensorRecords: (ISensorRecord | ICO2SensorRecord)[];

  setSensors: (newVal: ISensor[]) => void;
  setSensorsUUID: (newVal: string) => void;
}): void => {
  if (sensorsData && !sensorsLoading) {
    const newSensors: ISensor[] = sensorsData.sensor.map((sensor: ISensor): ISensor => {
      const _sensorRecords: (ISensorRecord | ICO2SensorRecord)[] =
        sensorRecords.filter((s: ISensorRecord | ICO2SensorRecord): boolean => Number(s.sensorDeviceId) === Number(sensor.id))

      const environmentalZoneId: ID = _sensorRecords[0].environmentalZoneId

      return Object.assign(
        {},
        sensor,
        {
          sensorRecords: _sensorRecords,
          environmentalZoneId
        }
      )
    })

    const newSensorsUUID = v4()

    setSensors(newSensors)
    setSensorsUUID(newSensorsUUID)
  }
}

/**
 * Update Green Automation water system records based on sensor record data.
 */
const UpdateGreenAutomationWaterSystemRecords = ({
  greenAutomationWaterSystemRecordsData,
  greenAutomationWaterSystemRecordsLoading,

  setGreenAutomationWaterSystemRecords,
  setGreenAutomationWaterSystemRecordsUUID
}: {
  greenAutomationWaterSystemRecordsData: any;
  greenAutomationWaterSystemRecordsLoading: boolean;

  setGreenAutomationWaterSystemRecords: (newVal: (IGreenAutomationWaterSystemRecord)[]) => void;
  setGreenAutomationWaterSystemRecordsUUID: (newVal: string) => void;
}): void => {
  if (greenAutomationWaterSystemRecordsData && !greenAutomationWaterSystemRecordsLoading) {
    const newGreenAutomationWaterSystemRecords: (IGreenAutomationWaterSystemRecord)[] = greenAutomationWaterSystemRecordsData.greenAutomationWaterSystemRecord
    // potentially do a bit more parsing here
    const newGreenAutomationWaterSystemRecordsUUID = v4()

    setGreenAutomationWaterSystemRecords(newGreenAutomationWaterSystemRecords)
    setGreenAutomationWaterSystemRecordsUUID(newGreenAutomationWaterSystemRecordsUUID)
  }
}

/**
 * Update area image records and areacam IDs based on area image record data.
 */
const UpdateAreaImageRecords = ({
  areaImageRecordsData,
  areaImageRecordsLoading,

  setAreaImageRecords,
  setAreacamIDs
}: {
  areaImageRecordsData: IModelList<IAreaImageRecord>
  areaImageRecordsLoading: boolean
  setAreaImageRecords: ISetStateType<Array<IAreaImageRecord>>
  setAreacamIDs: (newVal: ID[]) => void
}): void => {
  if (areaImageRecordsData && !areaImageRecordsLoading) {
    const newAreaImageRecords: IAreaImageRecord[] = areaImageRecordsData.areaImageRecord
    const newAreacamIDs: ID[] = Object.keys(
      newAreaImageRecords
        .map((a): ID => a.camDeviceId)
        .reduce((a: Record<ID, boolean>, c: ID): Record<ID, boolean> =>
          Object.assign({}, a, { [c]: true }), {})
    )

    setAreaImageRecords(newAreaImageRecords)
    setAreacamIDs(newAreacamIDs)
  }
}

/**
 * Update areacams based on areacam data.
 */
const UpdateAreacams = ({
  areacamsData,
  areacamsLoading,
  areaImageRecords,

  setAreacams,
  setAreacamsUUID
}: {
  areacamsData: IModelList<IAreacam>;
  areacamsLoading: boolean;
  areaImageRecords: Array<IAreaImageRecord>;

  setAreacams: (newVal: IAreacam[]) => void;
  setAreacamsUUID: (newVal: string) => void;
}) => {
  if (areacamsData && !areacamsLoading) {
    const newAreacams = areacamsData.areacam.map((areacam) => {
      const _areaImageRecords: IAreaImageRecord[] =
        areaImageRecords.filter((a: IAreaImageRecord): boolean => Number(a.camDeviceId) === Number(areacam.id))

      const spaceId: ID = _areaImageRecords[0].spaceId

      return Object.assign(
        {},
        areacam,
        {
          areaImageRecords: _areaImageRecords,
          spaceId
        }
      )
    })

    const newAreacamsUUID = v4()

    setAreacams(newAreacams)
    setAreacamsUUID(newAreacamsUUID)
  }
}

export {
  Search2URLParams,
  URLParams2Search,
  URLParams2Time,
  UpdateStaticData,
  UpdateEnvironmentalZones,
  UpdateSensorRecords,
  UpdateAllSensorSupdata,
  UpdateSensors,
  UpdateGreenAutomationWaterSystemRecords,
  UpdateAreaImageRecords,
  UpdateAreacams
}

/**
 * @author Miras Absar <mabsar@iunu.com>
 */

// Types
import EInputStatus from 'ui-lib/enums/input-status'
import IInputStatus from 'ui-lib/interfaces/IInputStatus'

const Tag = (val: string): IInputStatus => {
  if (val.length === 0) {
    return {
      status: EInputStatus.None,
      message: 'Optional'
    }
  } else if (/^\s*$/.test(val)) {
    return {
      status: EInputStatus.OK,
      message: ''
    }
  } else if (!/^[0-9a-zA-Z]{6}$/.test(val)) {
    return {
      status: EInputStatus.Error,
      message: 'Invalid Tag'
    }
  } else {
    // TODO: Implement API call

    return {
      status: EInputStatus.OK,
      message: ''
    }
  }
}

const RequiredTag = (val: string): IInputStatus => {
  if (/^\s*$/.test(val)) {
    return {
      status: EInputStatus.Error,
      message: 'Required'
    }
  } else if (!/^[0-9a-zA-Z]{6}$/.test(val)) {
    return {
      status: EInputStatus.Error,
      message: 'Invalid Tag'
    }
  } else {
    // TODO: Implement API call

    return {
      status: EInputStatus.OK,
      message: ''
    }
  }
}

export {
  Tag,
  RequiredTag
}

/**
 * @author Miras Absar <mabsar@iunu.com>
 */

import { gql } from '@apollo/client'

const UpdateCrop = gql`
  mutation(
    $id: ID!,

    $startDate: DateTime,
    $transplantDate: DateTime,
    $endDate: DateTime,
    $enabled: Boolean,
    $archived: Boolean,
    $code: String,
    $name: String,
    $description: String,
    $containerSize: String,
    $containersPerLocator: Int,
    $rating: Int,

    $numberUnits: Int,
    $numberUnitsHarvested: Int
  ) {
    Crop {
      update(input: {
        id: $id,

        startDate: $startDate,
        transplantDate: $transplantDate,
        endDate: $endDate,
        enabled: $enabled,
        archived: $archived,
        code: $code,
        name: $name,
        description: $description,
        containerSize: $containerSize,
        containersPerLocator: $containersPerLocator,
        rating: $rating,

        numberUnits: $numberUnits,
        numberUnitsHarvested: $numberUnitsHarvested
      }) {
        # Default Properties
        id
        createdOn
        updatedOn
        customerId

        # Crop Properties
        startDate
        transplantDate
        endDate
        enabled
        archived
        code
        name
        description
        containerSize
        containersPerLocator
        rating

        numberUnits
        numberUnitsHarvested
      }
    }
  }
`

export { UpdateCrop }

import EInputStatus from '../enums/input-status'
import IInputCore from '../interfaces/IInputCore'
import IInputStatus from '../interfaces/IInputStatus'

const NumberInputCore: IInputCore<number, number> = {
  inputType: 'number',

  getValue: (uiValue: number | string): number =>
    typeof uiValue === 'string' ? Number.parseInt(uiValue, 10) : uiValue,

  getUIValue: (value: number | string): number =>
    typeof value === 'string' ? Number.parseInt(value, 10) : value,

  getStatus: async (): Promise<IInputStatus> => ({
    status: EInputStatus.None,
    message: ''
  })
}

const RequiredNumberInputCore = Object.assign(
  {},
  NumberInputCore,
  {
    getStatus: (value: number): IInputStatus => {
      if (value === undefined ||
        value === null) {
        return {
          status: EInputStatus.Error,
          message: 'Required'
        }
      } else if (Number.isNaN(value)) {
        return {
          status: EInputStatus.Error,
          message: 'Invalid'
        }
      } else {
        return {
          status: EInputStatus.OK,
          message: ''
        }
      }
    }
  }
)

export {
  NumberInputCore,
  RequiredNumberInputCore
}

/**
 * @author Miras Absar <mabsar@iunu.com>
 */

// Types
import IEntity from 'graphql-lib/interfaces/entity'
import ICrop from 'graphql-lib/interfaces/ICrop'
import IInventory from 'graphql-lib/interfaces/IInventory'
import IInventoryStitch from 'graphql-lib/interfaces/IInventoryStitch'

// Config
import config from 'config'

// Utils
import Has from 'utils/has'
import { EStorage } from 'utils/storage'
import {
  CompareCrops,
  Tag
} from 'crops/utils/Routes'

// Libs
import { stringify } from 'query-string'

// React
import React, {
  useCallback,
  useState
} from 'react'

// React Libs
import useStorage from 'react-lib/use-storage'
import GoTo from 'react-lib/go-to'

// UI Lib
import Tabs from 'ui-lib/components/tabs'
import { ITab } from 'ui-lib/components/tab'
import Button from 'ui-lib/components/button'
import CropHeader from './crop-header'
import InventoryList from './inventory-list'
import TaskList from './task-list'

import ID from 'graphql-lib/interfaces/ID';

export interface ICropRollupCardProps {
  outlined?: boolean;
  resting?: boolean;
  raised?: boolean;
  animated?: boolean;


  id: ID;
  readOnly?: boolean;
  selectedEntities?: IEntity[];


  onClose?: (id: ID) => void;
  onEditCrop?: (id: ID) => void;
  onLoadedInventories?: (
    crop: ICrop,
    inventories: IInventory[],
    inventoryStitchesCache: Record<ID, IInventoryStitch[]>,
    inventoryChartsCache: Record<ID, any>
  ) => void;

  onSelectInventory?: (cropID: ID, inventoryID: ID) => void;
  onSelectInventoryChart?: (cropID: ID, inventoryID: ID, chartKey: string) => void;
  onEditInventory?: (cropID: ID, inventoryID: ID) => void;
}

const CropRollupCard = ({
  outlined,
  resting,
  raised,
  animated,

  id,
  readOnly,
  selectedEntities,

  onClose,
  onEditCrop,
  onLoadedInventories,
  onSelectInventory,
  onSelectInventoryChart,
  onEditInventory
}: ICropRollupCardProps): JSX.Element => {
  const [inventoryChartsLoading, setInventoryChartsLoading] = useState<boolean>(false)
  const [inventoryLocationsLoading, setInventoryLocationsLoading] = useState<boolean>(true)
  
  const InventoryTab: ITab = {
    id: 0,
    label: 'Inventory',
    loading: inventoryLocationsLoading
  }
  
  const FlagsTab: ITab = {
    id: 1,
    label: 'Tasks'
  }
  
  // State /////////////////////////////////////////////////////////////////////
  const [allowCompareButton, setAllowCompareButton] = useState(false);

  const [facilities] = useStorage('facilities', EStorage.EphemeralStorage)
  const supportsMapView = Has(facilities[0].geojson) &&
    Has(facilities[0].dcs) &&
    facilities[0].dcs.environments?.includes(config.code)

  const [tab] = useState<ITab>(InventoryTab)

  // Callbacks /////////////////////////////////////////////////////////////////

  const $compare = useCallback(
    (): void => {
      const urlParams = {
        cropIDs: JSON.stringify([id]),
        navigationOpen: true
      }

      const to = CompareCrops + '?' + stringify(urlParams)
      GoTo(to)
    },
    [id]
  )

  const $twoTag = useCallback(
    (): void => {
      const to = Tag.replace(':id', String(id)) + '?twoTag=true'
      GoTo(to)
    },
    [id]
  )

  const $oneTag = useCallback(
    (): void => {
      const to = Tag.replace(':id', String(id))
      GoTo(to)
    },
    [id]
  )

  // Render ////////////////////////////////////////////////////////////////////
  
  let className = 'Theme__Light Card CropRollupCard'
  if (outlined) className += ' Outlined'
  if (resting) className += ' Resting'
  if (raised) className += ' Raised'
  if (animated) className += ' Animated'

  return (
    <article className={className}>
      <CropHeader
        animated={animated}

        readOnly={readOnly}
        id={id}

        onClose={onClose}
        onEdit={onEditCrop}
      />

      <InventoryList
        show={tab.id == InventoryTab.id}
        animated

        id={id}
        readOnly={readOnly}
        selectedEntities={selectedEntities}
        inventoryLocationsLoading={inventoryLocationsLoading}
        setAllowCompareButton={setAllowCompareButton}
        setInventoryChartsLoading={setInventoryChartsLoading}
        setInventoryLocationsLoading={setInventoryLocationsLoading}
        onLoaded={onLoadedInventories}
        onSelect={onSelectInventory}
        onSelectChart={onSelectInventoryChart}
        onEdit={onEditInventory}
      />

      <TaskList
        show={tab.id == FlagsTab.id}
        animated
        id={id}
      />
      {!readOnly && (
        <div className='Actions'>
          { supportsMapView && (inventoryLocationsLoading || (!inventoryLocationsLoading && allowCompareButton)) && (
              <Button
                id="compare"
                animated={animated}
                outlined
                onClick={$compare}
                loading={inventoryLocationsLoading}
                disabled={inventoryLocationsLoading}
                showTextWhileLoading={false}
              >
                Compare
              </Button>
            )
          }
          
          <Button
            id="tag"
            animated={animated}
            outlined
            onClick={$oneTag}
          >
            1 Tag
          </Button>
        </div>
      )}
    </article>
  )
}

export default CropRollupCard

// React
import React, {
  KeyboardEvent,
  useEffect,
  useRef,
  useState
} from 'react'
import { toast } from 'react-toastify';

// GraphQL
import { gql } from '@apollo/client';
import client from 'graphql-lib/index';

// Font Awesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBarcodeScan } from '@fortawesome/pro-regular-svg-icons';

// UI Lib
import EInputStatus from 'ui-lib/enums/input-status';
import Input from 'ui-lib/components/Input/Input';
import ProgressBar from '../../progress-bar';
import Button from 'ui-lib/components/button';

import GroupFacade from '../../../facades/input/group';
import ContainersFacade from '../../../facades/input/containers';
import TagFacade from '../../../facades/input/tag';

import { RequiredNumberInputCore as RequiredNumberCore }
  from 'ui-lib/cores/number-input-core';
import TagCore from '../../../cores/input/tag';

// Utils
import Has from 'utils/has';
import IsVoid from 'utils/is-void';
import IInputStatus from 'ui-lib/interfaces/IInputStatus';

// Types
import IInventory from 'graphql-lib/interfaces/IInventory';
import ID from 'graphql-lib/interfaces/ID';

const CreateInventory = gql`
  mutation($cropId: ID!, $facilityId: ID!, $code: String) {
    Inventory {
      create(input: {
        cropId: $cropId,
        facilityId: $facilityId,
        code: $code
      }) {
        id
      }
    }
  }
`

const DeprovisionLocator = gql`
  mutation($id: ID!) {
    InventoryLocator {
      delete(input: {id: $id}) {
        id
      }
    }
  }
`

const ProvisionLocator = gql`
  mutation($inventoryId: ID!, $facilityId: ID!, $locatorCode: String) {
    InventoryLocator {
      create(input: {
        inventoryId: $inventoryId,
        facilityId: $facilityId,
        locatorCode: $locatorCode
      }) {
        id
      }
    }
  }
`

enum ETagSlot {
  A = 'ETagSlot:A',
  B = 'ETagSlot:B',
}

export interface IProvisionProps {
  outlined?: boolean;
  resting?: boolean;
  raised?: boolean;
  animated?: boolean;

  id: ID;
  facilityId: ID;
  twoTag?: boolean;

  containerSize?: string;
  containersPerLocator?: number;
  currentGroup?: number;
  totalGroups?: number;

  newTagText?: string;
  newTagType?: string;

  onShowVirtualScanner: (show?: boolean) => void,

  onDone: () => void;
  onNext: () => void;
}

type ITaggedInventoryData =
    Pick<IInventory, 'containersPerLocator'> & { tagA: string, tagB: string, currentGroup?: number };

const Provision = ({
  outlined,
  resting,
  raised,
  animated,

  id,
  facilityId,
  twoTag,

  containerSize,
  containersPerLocator,
  currentGroup,
  totalGroups,

  newTagText,
  newTagType,

  onShowVirtualScanner,

  onDone,
  onNext
}: IProvisionProps): JSX.Element => {
  // Refs & State //////////////////////////////////////////////////////////////

  const [initialValue, setInitialValue] = useState<Partial<ITaggedInventoryData>>({
    tagA: '',
    tagB: ''
  })

  const value = useRef<Partial<ITaggedInventoryData>>({})
  const status = useRef<Partial<Record<keyof ITaggedInventoryData, IInputStatus>>>({})

  const [showStatusUntouched, setShowStatusUntouched] = useState(false)

  const [newTagSlot, setNewTagSlot] = useState<ETagSlot>()
  const [groceryTag, setGroceryTag] = useState<boolean>()

  // Effects ///////////////////////////////////////////////////////////////////

  useEffect((): void => {
    const newInitialValue = Object.assign(
      {},
      initialValue
    )

    if (Has(containersPerLocator)) {
      newInitialValue.containersPerLocator = containersPerLocator
    }

    if (Has(currentGroup)) {
      newInitialValue.currentGroup = currentGroup
    }

    if (Has(newTagText) && Has(newTagType)) {
      switch (newTagSlot) {
        case ETagSlot.A:
          newInitialValue.tagA = newTagText
          break
        case ETagSlot.B:
          newInitialValue.tagB = newTagText
          break
      }
    }

    setInitialValue(newInitialValue)
    setShowStatusUntouched(
      Has(newTagText) &&
      Has(newTagType) &&
      newTagText.length > 0
    )

    if (!groceryTag) {
      onShowVirtualScanner(false)
    }
  }, [
    containersPerLocator,
    currentGroup,
    newTagText,
    newTagType
  ])

  // Callbacks /////////////////////////////////////////////////////////////////

  const $prevent = (ev: KeyboardEvent<HTMLDivElement>): void => {
    ev.preventDefault()
    ev.stopPropagation()
  }

  const $showVirtualScanner =
    (tagSlot: ETagSlot = ETagSlot.A, newGroceryTag: boolean = false): void => {
      setNewTagSlot(tagSlot)
      setGroceryTag(newGroceryTag)
      onShowVirtualScanner()
    }

  const $value = (name: string, newValue: string | number): void => {
    if (Has(newValue)) {
      (value.current as Record<keyof ITaggedInventoryData, string | number>)[name as keyof ITaggedInventoryData] = newValue;
    }
  }

  const $status = (name: string, newStatus: IInputStatus): void => {
    status.current[name as keyof ITaggedInventoryData] = newStatus
  }

  const $provision = async (isNext?: boolean): Promise<void> => {
    if (IsVoid(value.current.tagA) || value.current.tagA.length === 0) {
      onDone()
      return
    }

    const hasError = Object.values(status.current).reduce(
      (acc: boolean, cur: IInputStatus): boolean =>
        acc || cur.status === EInputStatus.Error,
      false
    )

    const hasWarning = Object.values(status.current).reduce(
      (acc: boolean, cur: IInputStatus): boolean =>
        acc || cur.status === EInputStatus.Warning,
      false
    )

    if (hasError) {
      setShowStatusUntouched(true)
      return
    }

    if (IsVoid(value.current.containersPerLocator)) {
      value.current.containersPerLocator = initialValue.containersPerLocator
    }

    if (IsVoid(value.current.currentGroup)) {
      value.current.currentGroup = initialValue.currentGroup
    }

    if (IsVoid(value.current.tagA)) {
      value.current.tagA = initialValue.tagA
    }

    if (twoTag && IsVoid(value.current.tagB)) {
      value.current.tagB = initialValue.tagB
    }

    if (hasWarning) {
      const alreadyProvisionedTags: Array<IInputStatus['meta']> = []
      if (status.current.tagA.status === EInputStatus.Warning) {
        alreadyProvisionedTags.push(status.current.tagA.meta)
      }

      if (twoTag && status.current.tagB.status === EInputStatus.Warning) {
        alreadyProvisionedTags.push(status.current.tagB.meta)
      }

      /*
      let message: string
      if (alreadyProvisionedTags.length === 1) {
        const tag1 = alreadyProvisionedTags[0]
        message = `${tag1.locator.serial} is already provisioned to ${tag1.crop.name}. Automatically deprovision?`
      }
      if (alreadyProvisionedTags.length === 2) {
        const tag1 = alreadyProvisionedTags[0]
        const tag2 = alreadyProvisionedTags[1]
        message = `${tag1.locator.serial} and ${tag2.locator.serial} are already provisioned to ${tag1.crop.name} and ${tag2.crop.name}. Automatically deprovision?`
      }

      const deprovision = confirm(message)
      if (!deprovision) {
        return
      }
       */

      const deprovisionPromises = alreadyProvisionedTags.map((meta: IInputStatus['meta']) =>
        client.mutate({
          mutation: DeprovisionLocator,
          variables: { id: meta.inventoryLocator.id }
        }))

      await Promise.all(deprovisionPromises)
    }

    const {
      errors: createInventoryErrors,
      data: createInventoryData
    } = await client.mutate({
      mutation: CreateInventory,
      variables: {
        cropId: id,
        facilityId,
        code: value.current.currentGroup.toString()
      }
    })

    if (createInventoryErrors) {
      toast.error("Couldn't create inventory, please try again in a few seconds.")
      return
    }

    const { Inventory: { create: { id: inventoryId } } } = createInventoryData

    const tags = twoTag
      ? [value.current.tagA, value.current.tagB]
      : [value.current.tagA]

    const provisionPromises = tags.map((tag: string): void => {
      client.mutate({
        mutation: ProvisionLocator,
        variables: {
          inventoryId,
          facilityId,
          locatorCode: tag
        }
      })
      heap.track(`Tag Provisioned`)
    })

    await Promise.all(provisionPromises)

    if (isNext) onNext()
    else onDone()
  }

  // Render ////////////////////////////////////////////////////////////////////

  const tagActionClassName = animated
    ? 'Outlined Animated'
    : 'Outlined'

  return (
    <>
      <div
        className='Body'
        onKeyPress={$prevent}
      >
        <Input
          animated
          label='Containers per Group'
          facade={ContainersFacade}

          name='containersPerLocator'
          initialValue={initialValue.containersPerLocator}
          showStatusUntouched={showStatusUntouched}
          onValueChange={$value}
          onStatusChange={$status}
          readOnly={false}
          core={RequiredNumberCore}
        />

        <Input
          animated
          label='Group:'
          facade={GroupFacade}

          name='currentGroup'
          initialValue={initialValue.currentGroup}
          showStatusUntouched={showStatusUntouched}
          onValueChange={$value}
          onStatusChange={$status}
          readOnly={false}
          core={RequiredNumberCore}
        />
      </div>

      <div
        className='Body'
        onKeyPress={$prevent}
      >
        <ProgressBar
          animated
          currentValue={currentGroup}
          totalValue={totalGroups}
        />

        {twoTag && (
          <div className='TagActions'>
            <div>
              <button
                className={tagActionClassName}
                onClick={() => $showVirtualScanner()}
              >
                Scan
                <FontAwesomeIcon icon={faBarcodeScan} />
              </button>
            </div>
          </div>
        )}

        <Input
          key={currentGroup}

          animated
          label='Tag A'
          facade={TagFacade}

          name='tagA'
          initialValue={initialValue.tagA}
          showStatusUntouched={showStatusUntouched}
          onValueChange={$value}
          onStatusChange={$status}
          readOnly={false}
          core={TagCore}
        />

        {twoTag && (
          <>
            <div className='TagActions'>
              <div>
                <button
                  className={tagActionClassName}
                  onClick={() => $showVirtualScanner(ETagSlot.B)}
                >
                  Scan
                  <FontAwesomeIcon icon={faBarcodeScan} />
                </button>
              </div>
            </div>

            <Input
              key={currentGroup}

              animated
              label='Tag B'
              facade={TagFacade}

              name='tagB'
              initialValue={initialValue.tagB}
              showStatusUntouched={showStatusUntouched}
              onValueChange={$value}
              onStatusChange={$status}
          readOnly={false}
              core={TagCore}
            />
          </>
        )}
      </div>

      <div style={{ flex: '1 0 auto' }} />

      <div className='Actions'>
        <Button
          id="done"
          animated={animated}
          outlined
          onClick={() => $provision()}
        >
          Done
        </Button>

        <Button
          id="next"
          animated={animated}
          outlined
          onClick={() => $provision(true)}
        >
          Next
        </Button>

        {!twoTag && (
          <Button
            id="scan"
            animated={animated}
            outlined
            onClick={() => $showVirtualScanner()}
          >
            Scan
            <FontAwesomeIcon icon={faBarcodeScan} />
          </Button>
        )}
      </div>
    </>
  )
}

export default Provision

// Types
import EEntity from 'map-view/enums/entity'
import IEntity from 'map-view/interfaces/entity'
import IChart from 'map-view/interfaces/chart'

// Utils
import {
  Chartable,
  ChartAccuracy,
  ChartName,
  ChartUnit
} from 'map-view/utils/chart'

// React
import React, {
  useContext,
  useMemo
} from 'react'

// MapView
import Mobius from 'map-view/contexts/mobius'

interface IChartTileProps {
  outlined?: boolean;
  resting?: boolean
  raised?: boolean;
  animated?: boolean;

  chart: IChart;
}

const ChartTile = ({
  outlined,
  resting,
  raised,
  animated,

  chart
}: IChartTileProps): JSX.Element => {
  // Context ///////////////////////////////////////////////////////////////////

  const {
    selectedEntities: {
      selectedEntities,
      setSelectedEntities,
      selectedEntitiesUUID
    }
  } = useContext(Mobius)

  // Memos /////////////////////////////////////////////////////////////////////

  const chartName = useMemo(
    () => ChartName[chart.chart],
    [chart.chart]
  )

  const chartUnit = useMemo(
    () => chart.dynamicUnit || ChartUnit[chart.chart],
    [chart.chart, chart.dynamicUnit]
  )

  const chartValue = useMemo(
    () => {
      const chartAccuracy = ChartAccuracy[chart.chart]
      const chartValue = (Array.isArray(chart.value)
        ? chart.value[chart.value.length - 1][1]
        : chart.value)?.toFixed(chartAccuracy)

      return chartValue
    },
    [chart.chart, chart.value]
  )

  const parentSelected = useMemo(
    () => {
      const parentSelected = !!selectedEntities
        .find((entity: IEntity) =>
          entity.type === chart.parentType &&
          String(entity.id) === String(chart.parentID))

      return parentSelected
    },
    [selectedEntitiesUUID]
  )

  const selected = useMemo(
    () => {
      const selected = !!selectedEntities
        .find((entity: IEntity) =>
          entity.parentType === chart.parentType &&
          String(entity.parentID) === String(chart.parentID) &&
          entity.type === EEntity.Chart &&
          String(entity.id) === String(chart.chart))

      return selected
    },
    [selectedEntitiesUUID]
  )

  const style = useMemo(
    () => {
      if (selected) {
        return {
          border: `2px solid ${chart.color}`
        }
      }

      return {}
    },
    [selected]
  )

  // Callbacks /////////////////////////////////////////////////////////////////

  const $click = (): void => {
    if (!Chartable[chart.chart]) {
      return
    }

    let newSelectedEntities = [...selectedEntities]

    if (!parentSelected) {
      newSelectedEntities.push({
        type: chart.parentType,
        id: chart.parentID
      })
    }

    if (!selected) {
      newSelectedEntities.push({
        parentType: chart.parentType,
        parentID: chart.parentID,

        type: EEntity.Chart,
        id: chart.chart
      })
    } else {
      newSelectedEntities = newSelectedEntities
        .filter(entity =>
          entity.parentType !== chart.parentType ||
          String(entity.parentID) !== String(chart.parentID) ||
          entity.type !== EEntity.Chart ||
          entity.id !== chart.chart)
    }
    setSelectedEntities(newSelectedEntities)
  }

  // Render ////////////////////////////////////////////////////////////////////

  let className = 'ChartTile'
  if (outlined) className += ' Outlined'
  if (resting) className += ' Resting'
  if (raised) className += ' Raised'
  if (animated) className += ' Animated'

  if (chartValue == null) {
    return <></>
  }

  return (
    <div
      style={style}
      className={className}
      onClick={$click}
    >
      <div className='Name'>{chartName}</div>
      <div className='Value'>{chartValue} {chartUnit}</div>
    </div>
  )
}

export default ChartTile

/**
 * @author Miras Absar <mabsar@iunu.com>
 */

// Types
import ICustomer from 'graphql-lib/interfaces/ICustomer'

// React Libs
import { toast } from 'react-toastify'

// const migratedCustomerSubdomains = [{
//   id: '1203',
//   subdomain: 'one.luna', // Luna Lab
// }, {
//   id: '1345',
//   subdomain: 'growauto', // Grow Automation
// }]

const UpdateState = ({
  customersLoading,
  customersError,
  customersData,

  setLoading,
  setError,
  setCustomers
}: {
  customersLoading: boolean;
  customersError: Error;
  customersData: { customer: ICustomer[] };

  setLoading: (newVal: boolean) => void;
  setError: (newVal: Error) => void;
  setCustomers: (newVal: ICustomer[]) => void;
}): void => {
  if (customersLoading) {
    setLoading(true)
  } else {
    setLoading(false)
    if (customersError) {
      setError(customersError)
      toast.error("Couldn't load Customers.")
    } else if (customersData) {
      // const customerIds = customersData.customer.map((customer) => customer.id)
      // const migratedCustomer = migratedCustomerSubdomains.find((subdomainInfo) => customerIds.includes(subdomainInfo.id))

      // if (migratedCustomer?.subdomain) {
      //   // toast(`Redirecting to ${migratedCustomer.subdomain}.iunu.com ...`, {
      //   //   onClose: () => (window.location.href = `https://${migratedCustomer.subdomain}.iunu.com`),
      //   // })
      // } else {
      //   setCustomers(customersData.customer)
      // }
      
      setCustomers(customersData.customer)
    }
  }
}

export { UpdateState }

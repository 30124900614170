// Types
import IInputStatus from 'ui-lib/interfaces/IInputStatus'

// GraphQL Lib
import { RequiredProductCode } from 'graphql-lib/validators/product'
import { NotRequiredAreaName, NotRequiredZoneName, NotRequiredSubZoneName } from 'graphql-lib/validators/areas'

// UI Lib
import {
  Dat,
  RequiredDat
} from 'ui-lib/validators/date'
import { Num } from 'ui-lib/validators/number'
import {
  Text,
  RequiredText
} from 'ui-lib/validators/text';

// The product code validation is checked by using a request which makes the function async.
type IValidatorType = Record<string, (val: string, areaName?: string, zoneName?: string) => IInputStatus | Promise<IInputStatus>>
const Validators: IValidatorType = {
  'Product Code': RequiredProductCode,
  Area: NotRequiredAreaName,
  Zone: NotRequiredZoneName,
  'Sub Zone': NotRequiredSubZoneName,
  Name: RequiredText,
  Code: RequiredText,
  'Container Type': Text,
  'Containers per Group': Num,
  'Start Date': RequiredDat,
  'Transplant Date': Dat,
  'End Date': RequiredDat,
  Description: Text
}

export default Validators

/**
 * @author Miras Absar <mabsar@iunu.com>
 */

enum EColor {
  None = 'Color.None',
  Accent = 'Color.Accent',
  OK = 'Color.OK',
  Warning = 'Color.Warning',
  Error = 'Color.Error'
}

export default EColor

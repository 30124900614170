/* eslint
curly: ['error', 'multi'],
default-case: 'off',
implicit-arrow-linebreak: 'off',
no-else-return: 'off',
no-extra-boolean-cast: 'off',
nonblock-statement-body-position: ['error', 'any'],
object-curly-spacing: ['error', 'never'],

@typescript-eslint/interface-name-prefix: ['error', 'always'],

react/jsx-indent: 'off',
react/no-unescaped-entities: 'off',
react/prop-types: 'off',

jsx-a11y/control-has-associated-label: 'off',
jsx-a11y/label-has-associated-control: 'off',
jsx-a11y/label-has-for: 'off',
*/

import React, {
  KeyboardEvent,
  Ref,
  useRef
} from 'react'

import EInputStatus from 'ui-lib/enums/input-status'
import IInputFacadeProps from 'ui-lib/interfaces/IInputFacadeProps'
import Status2ClassName from 'ui-lib/utils/Status2ClassName'

export default function InputTag({
  /* Visual Properties */
  animated,
  label,

  /* Behavioral Properties */
  name,
  value,
  status,
  touched,
  dirty,
  showStatusUntouched,
  onBlur,
  onChange,
  readOnly,
}: IInputFacadeProps<string>): JSX.Element {
  const inputRef = useRef<HTMLInputElement>()

  const className = animated ? 'TagInput Animated' : 'TagInput'
  const showingStatus = (touched || dirty || showStatusUntouched)
    && status.status !== EInputStatus.Uninitialized
    && status.status !== EInputStatus.None

  const statusClassName = Status2ClassName[status.status]

  const $focus = (): void => {
    inputRef.current.focus()
  };

  const $blur = (): void => {
    if (name && onBlur) onBlur(name)
  };

  const $keyPress = ({key}: KeyboardEvent): void => {
    if (name && onChange && !readOnly) {
      let nextValue = value

      const deleteOperation = key === 'Backspace' || key === 'Delete'
      const addOperation = nextValue.length < 6 && /^[0-9a-zA-Z]$/.test(key)

      if (deleteOperation)
        nextValue = nextValue.substring(0, nextValue.length - 1)

      else if (addOperation)
        nextValue = (nextValue + key).toUpperCase()

      onChange(name, nextValue)
    }
  }

  const $ref = (ref: HTMLInputElement): void => {
    if (ref !== inputRef.current) inputRef.current = ref
  };

  const $change = ({target: {value}}: {target: {value: string}}): void => {
    if (name) onChange(name, value)
  };

  const getFieldClassName = (index: number): string => {
    if (index === 5 && value.length === 6) {
      return 'Field Focused'
    } else {
      return value.length === index ? 'Field Focused' : 'Field'
    }
  }

  value = value || ''

  return (
    <div
      className={className}
      role='textbox'
      onFocus={$focus}
      onBlur={$blur}
      onKeyPress={$keyPress}
      tabIndex={0}
    >
      <div className='Head'>
        <label>{label}</label>

        {showingStatus
          ? <p className={statusClassName}>{status.message}</p>
          : null}
      </div>

      <div className='Body'>
        <div className={getFieldClassName(0)}>{value[0]}</div>
        <div className={getFieldClassName(1)}>{value[1]}</div>
        <div className={getFieldClassName(2)}>{value[2]}</div>
        <div className='Dash'>-</div>
        <div className={getFieldClassName(3)}>{value[3]}</div>
        <div className={getFieldClassName(4)}>{value[4]}</div>
        <div className={getFieldClassName(5)}>{value[5]}</div>
      </div>

      <div className='InputContainer'>
        <input ref={$ref} onChange={$change} />
      </div>
    </div>
  )
};

/***
 * @author Miras Absar <mabsar@iunu.com>
 */

// Types
import ISpace from 'graphql-lib/interfaces/ISpace'
import IAreacam from 'graphql-lib//interfaces/IAreacam'

// React
import React, {
  useContext,
  useState
} from 'react'

// React Libs
import PinchZoomPan from 'react-responsive-pinch-zoom-pan'

// Font Awesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimes } from '@fortawesome/pro-regular-svg-icons'

// UI Lib
import Mobius from 'map-view/contexts/mobius'
import { useIter } from 'react-lib/use-iter'

// Effects
import {
  UpdateCam,
} from './effects'

const AreacamView = (): JSX.Element => {
  // Context ///////////////////////////////////////////////////////////////////

  const {
    searchParams: {
      searchParams,
      setSearchParams,
      searchParamsUUID
    },
    spaces: { spaces },
    areacams: { areacams }
  } = useContext(Mobius)

  const open =
    searchParams &&
    searchParams.viewAreacam

  // State /////////////////////////////////////////////////////////////////////

  const [areacam, setAreacam] = useState<IAreacam>()
  const [space, setSpace] = useState<ISpace>()
  const [time, setTime] = useState<string>()

  // Effects ///////////////////////////////////////////////////////////////////

  useIter(
    UpdateCam,

    { areacams,
      spaces,
      searchParams,

      setAreacam,
      setSpace,
      setTime },

    [searchParamsUUID]
  )

  // Callbacks /////////////////////////////////////////////////////////////////

  const $close = (): void => {
    const newParams = Object.assign(
      {},
      searchParams
    )

    newParams.viewAreacamTiles = undefined
    newParams.viewAreacam = undefined

    setSearchParams(newParams)
  }

  // Render ////////////////////////////////////////////////////////////////////

  if (!open || !areacam) {
    return null
  }

  return (
    <div className='Theme__Dark AreacamView'>
      <div className='CloseButton'>
        <button
          className='Resting Animated'
          onClick={$close}
        >
          <FontAwesomeIcon icon={faTimes} />
        </button>
      </div>

      <PinchZoomPan
        maxScale={10}
        position='center'
        zoomButtons={false}
      >
        <img src={areacam.areaImageRecord[0].url} />
      </PinchZoomPan>

      <div className='Metadata__Parent'>
        <div className='Metadata'>
          <div className='Head'>
            <h6 className='Primary'>
              {space
                ? space.code
                : '-'}
            </h6>

            <div style={{ flex: '1 0 auto' }} />

            <h6 className='Secondary'>
              {areacam.code}
            </h6>
          </div>

          <div className='Subhead'>
            <p>
              {time || '-'}
            </p>
          </div>
        </div>
      </div>
    </div>
  )
}

export default AreacamView

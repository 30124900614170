/**
 * @author Miras Absar <mabsar@iunu.com>
 */

/**
 * An enumeration of `Storage` types.
 */
enum EStorage {
  EphemeralStorage = 'Storage.EphemeralStorage',
  SessionStorage = 'Storage.SessionStorage',
  LocalStorage = 'Storage.LocalStorage',
}

enum EStorageAction {
  SetItem = 'StorageAction.SetItem',
  RemoveItem = 'StorageAction.RemoveItem',
  Clear = 'StorageAction.Clear'
}

/**
 * An ephemeral implementation of `Storage`.
 */
class EphemeralStorage implements Storage {
  /**
   * The internal record of a given `EphemeralStorage` object.
   */
  private record: Record<string, string> = {}

  /**
   * @returns The number of data items stored in a given `Storage` object.
   */
  public get length (): number {
    return Object.entries(this.record).length
  }

  /**
   * When passed a number n, returns the name of the nth key in a given
   * `Storage` object. The order of keys is user-agent defined, so you should
   * not rely on it.
   *
   * @param index - An integer representing the number of the key you want to
   * get the name of. This is a zero-based index.
   *
   * @returns A `DOMString` containing the name of the key. If the index does
   * not exist, `null` is returned.
   */
  public key (index: number): string {
    return Object.keys(this.record)[index]
  }

  /**
   * When passed a key name, will return that key's value, or `null` if the key
   * does not exist, in the given `Storage` object.
   *
   * @param key - A `DOMString` containing the name of the key you want to
   * retrieve the value of.
   *
   * @returns A `DOMString` containing the value of the key. If the key does not
   * exist, `null` is returned.
   */
  public getItem (key: string): string {
    return this.record[key]
  }

  /**
   * When passed a key name and value, will add that key to the given `Storage`
   * object, or update that key's value if it already exists.
   *
   * @param key - A `DOMString` containing the name of the key you want to
   * create/update.
   *
   * @param val - A `DOMString` containing the value you want to give the key
   * you are creating/updating.
   */
  public setItem (key: string, val: string): void {
    this.record[key] = val
  }

  /**
   * When passed a key name, will remove that key from the given `Storage`
   * object if it exists. If there is no item associated with the given key,
   * this method will do nothing.
   *
   * @param key - A `DOMString` containing the name of the key you want to
   * remove.
   */
  public removeItem (key: string): void {
    delete this.record[key]
  }

  /**
   * Clears all keys stored in a given `Storage` object.
   */
  public clear (): void {
    this.record = {}
  }
}

const ephemeralStorage: EphemeralStorage = new EphemeralStorage()
export const clearStorage = (): void => {
  ephemeralStorage.clear()
  sessionStorage.clear()
  localStorage.clear()

  const ev = new CustomEvent(EStorageAction.Clear)
  window.dispatchEvent(ev)
}

/**
 * @param type - An enumeration of a `Storage`.
 * @returns An instance of `Storage` matching `type`.
 */
const GetStore = (type: EStorage): Storage => {
  switch (type) {
    case EStorage.EphemeralStorage: return ephemeralStorage
    case EStorage.SessionStorage: return sessionStorage
    case EStorage.LocalStorage: return localStorage
    default: return localStorage
  }
}

/**
 * When passed a key name, will return that key's value, or `null` if the key
 * does not exist, in the given `Storage` object.
 *
 * @param key - A `DOMString` containing the name of the key you want to
 * retrieve the value of.
 *
 * @param type - An enumeration of a `Storage`.
 *
 * @returns A `DOMString` containing the value of the key. If the key does not
 * exist, `null` is returned.
 */
const GetItem = (
  key: string,
  type: EStorage = EStorage.LocalStorage
): any => {
  const store = GetStore(type)
  const valueJSON = store.getItem(key)
  let value: any
  try {
    value = JSON.parse(valueJSON as string)
  } catch (err) {
    value = valueJSON
  }

  return value
}

/**
 * When passed a key name and value, will add that key to the given `Storage`
 * object, or update that key's value if it already exists.
 *
 * @param key - A `DOMString` containing the name of the key you want to
 * create/update.
 *
 * @param val - A `DOMString` containing the value you want to give the key you
 * are creating/updating.
 *
 * @param type - An enumeration of a `Storage`.
 */
const SetItem = (
  key: string,
  value: any,
  type: EStorage = EStorage.LocalStorage
): void => {
  const store = GetStore(type)

  const previousValueJSON = store.getItem(key)
  let previousValue: any
  try {
    previousValue = JSON.parse(previousValueJSON as string)
  } catch (err) {
    previousValue = previousValueJSON
  }

  let valueJSON: string
  if (typeof value === 'string') valueJSON = value
  else valueJSON = JSON.stringify(value)

  store.setItem(key, valueJSON)

  const ev = new CustomEvent(EStorageAction.SetItem, {
    detail: {
      key,
      type: EStorageAction.SetItem,
      oldValue: previousValue,
      newValue: value
    }
  })

  window.dispatchEvent(ev)
}

/**
 * When passed a key name, will remove that key from the given `Storage` object
 * if it exists. If there is no item associated with the given key, this method
 * will do nothing.
 *
 * @param key - A `DOMString` containing the name of the key you want to remove.
 * @param type - An enumeration of a `Storage`.
 */
const RemoveItem = (
  key: string,
  type: EStorage = EStorage.LocalStorage
): void => {
  const store = GetStore(type)

  const valueJSON = store.getItem(key)
  let value: any
  try {
    value = JSON.parse(valueJSON as string)
  } catch (err) {
    value = valueJSON
  }

  store.removeItem(key)

  const ev = new CustomEvent(EStorageAction.RemoveItem, {
    detail: {
      key,
      type: EStorageAction.RemoveItem,
      oldValue: value
    }
  })

  window.dispatchEvent(ev)
}

declare global {
  interface Window {
    ephemeralStorage: any;
    clearStorage: any;
    taskDistances?: Array<any>
    supportsWebp: boolean
  }
}

window.ephemeralStorage = ephemeralStorage
window.clearStorage = clearStorage

export {
  EStorage,
  EStorageAction,
  GetItem,
  SetItem,
  RemoveItem
}

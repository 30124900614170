import { format } from 'date-fns'

import IColumn from 'arrayview/interfaces/IColumn'
import IGroup from 'arrayview/interfaces/IGroup'
import ITaskIssue from '../interfaces/ITaskIssue'

import {
  StringIncludes,
  StringCompare,
  DateIncludes,
  DateCompare
} from '../utils/safe'

import { createDate } from 'utils/dates'

const CreatedAtColumn: IColumn<ITaskIssue> = Object.freeze({
  id: 'task-issue:created-at',
  name: 'Created At',
  searchFunc: (_: IColumn<ITaskIssue>, search: string, data: ITaskIssue): boolean =>
    DateIncludes(data.createdAt, search),

  ascendingSortFunc: (_: IColumn<ITaskIssue>, first: ITaskIssue, second: ITaskIssue): number =>
    DateCompare(first.createdAt, second.createdAt),

  descendingSortFunc: (_: IColumn<ITaskIssue>, first: ITaskIssue, second: ITaskIssue): number =>
    DateCompare(second.createdAt, first.createdAt),

  groupFunc: (_: IColumn<ITaskIssue>, data: ITaskIssue): IGroup => ({
    id: `${_.id}::${data.createdAt}`,
    name: format(createDate(data.createdAt), 'PPP'),
    data: []
  })
})

const UpdatedAtColumn: IColumn<ITaskIssue> = Object.freeze({
  id: 'task-issue:updated-at',
  name: 'Updated At',
  searchFunc: (_: IColumn<ITaskIssue>, search: string, data: ITaskIssue): boolean =>
    DateIncludes(data.updatedAt, search),

  ascendingSortFunc: (_: IColumn<ITaskIssue>, first: ITaskIssue, second: ITaskIssue): number =>
    DateCompare(first.updatedAt, second.updatedAt),

  descendingSortFunc: (_: IColumn<ITaskIssue>, first: ITaskIssue, second: ITaskIssue): number =>
    DateCompare(second.updatedAt, first.updatedAt),

  groupFunc: (_: IColumn<ITaskIssue>, data: ITaskIssue): IGroup => ({
    id: `${_.id}::${data.updatedAt}`,
    name: format(createDate(data.updatedAt), 'PPP'),
    data: []
  })
})

// Priority Column
// Complete Column
// Tags Column

const DescriptionColumn: IColumn<ITaskIssue> = Object.freeze({
  id: 'task-issue:description',
  name: 'Name',
  searchFunc: (_: IColumn<ITaskIssue>, search: string, data: ITaskIssue): boolean =>
    StringIncludes(data.description, search),

  ascendingSortFunc: (_: IColumn<ITaskIssue>, first: ITaskIssue, second: ITaskIssue): number =>
    StringCompare(first.description, second.description),

  descendingSortFunc: (_: IColumn<ITaskIssue>, first: ITaskIssue, second: ITaskIssue): number =>
    StringCompare(second.description, first.description),

  groupFunc: (_: IColumn<ITaskIssue>, data: ITaskIssue): IGroup => ({
    id: `${_.id}::${data.description}`,
    name: data.description,
    data: []
  })
})

const FlagColumn: IColumn<ITaskIssue> = Object.freeze({
  id: 'task-issue:flag',
  name: 'Flag',
  searchFunc: (_: IColumn<ITaskIssue>, search: string, data: ITaskIssue): boolean =>
    StringIncludes(data.customerTaskCategory[0].name, search),

  ascendingSortFunc: (_: IColumn<ITaskIssue>, first: ITaskIssue, second: ITaskIssue): number =>
    StringCompare(first.customerTaskCategory[0].name, second.customerTaskCategory[0].name),

  descendingSortFunc: (_: IColumn<ITaskIssue>, first: ITaskIssue, second: ITaskIssue): number =>
    StringCompare(second.customerTaskCategory[0].name, first.customerTaskCategory[0].name),

  groupFunc: (_: IColumn<ITaskIssue>, data: ITaskIssue): IGroup => ({
    id: `${_.id}::${data.customerTaskCategory[0] ? data.customerTaskCategory[0].name : ''}`,
    name: data.customerTaskCategory[0] ? data.customerTaskCategory[0].name : '',
    data: []
  })
})

export {
  CreatedAtColumn,
  UpdatedAtColumn,
  DescriptionColumn,
  FlagColumn
}

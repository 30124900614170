// Types
import ISpace from 'graphql-lib/interfaces/ISpace'
import IAreacam from 'graphql-lib/interfaces/IAreacam'

// Libs
import { format } from 'date-fns'

// React
import React, {
  useCallback,
  useContext
} from 'react'

// UI Lib
import Mobius from 'map-view/contexts/mobius'
import { createDate } from 'utils/dates'

interface IAreacamTileControlProps {
  outlined?: boolean;
  resting?: boolean;
  raised?: boolean;
  animated?: boolean;

  areacam: IAreacam;
}

const AreacamTileControl = ({
  outlined,
  resting,
  raised,
  animated,

  areacam
}: IAreacamTileControlProps): JSX.Element => {
  const {
    searchParams: {
      searchParams,
      setSearchParams,
      searchParamsUUID
    },
    spaces: { spaces }
  } = useContext(Mobius)

  const active =
  searchParams.viewAreacam &&
  searchParams.viewAreacam?.toString() === String(areacam.id)

  const space = spaces.find((s: ISpace): boolean => String(areacam.spaceId) === String(s.id))

  const time =
    format(createDate(areacam.areaImageRecord[0].createdOn), 'P p')

  // Callbacks /////////////////////////////////////////////////////////////////

  const $toggleActive = useCallback(
    (): void => {
      const newParams = { ...searchParams }

      if (active) {
        newParams.viewAreacam = undefined
      } else {
        newParams.viewAreacam = String(areacam.id)
      }

      setSearchParams(newParams)
    },
    [searchParamsUUID]
  )

  // Render ////////////////////////////////////////////////////////////////////

  let className = 'Theme__Dark AreacamTileControl'

  if (outlined) className += ' Outlined'
  if (resting) className += ' Resting'
  if (raised) className += ' Raised'
  if (animated) className += ' Animated'
  if (active) className += ' Active'

  return (
    <div
      className={className}
      onClick={$toggleActive}
    >
      <img src={areacam.areaImageRecord[0].thumbUrl} />

      <div className='Metadata__Parent'>
        <div className='Metadata'>
          <div className='Head'>
            <h6 className='Primary'>
              {space
                ? space.code
                : '-'}
            </h6>

            <div style={{ flex: '1 0 auto' }} />

            <h6 className='Secondary'>
              {areacam.code}
            </h6>
          </div>

          <div className='Subhead'>
            <p>
              {time || '-'}
            </p>
          </div>
        </div>
      </div>
    </div>
  )
}

export default AreacamTileControl

import React, { MouseEvent as MouseEventReact, useCallback, useState } from 'react'
import { useApolloClient } from '@apollo/client'
import { toast } from 'react-toastify'
import { ToggleInventoryFavoriteState } from 'crops/components/crop-rollup-card/inventory-list/inventory-item/queries'
import IInventory from 'graphql-lib/interfaces/IInventory'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faStar } from '@fortawesome/pro-regular-svg-icons'
import { faStar as faStarSolid, faSpinner } from '@fortawesome/pro-solid-svg-icons'

interface FavoriteButtonProps {
  inventory: IInventory
  refetchInventories: () => void
}

const FavoriteButton = ({ inventory, refetchInventories }: FavoriteButtonProps) => {
  const [loadingUpdate, setLoadingUpdate] = useState<boolean>(false)
  const client = useApolloClient()
  const toggleInventoryFavorite = useCallback(
    async (ev: MouseEventReact<HTMLButtonElement, MouseEvent>): Promise<void> => {
      setLoadingUpdate(true)
      ev.preventDefault()
      ev.stopPropagation()
      try {
        const { data } = await client.mutate({
          mutation: ToggleInventoryFavoriteState,
          variables: { id: inventory.id, favorite: !inventory.favorite },
        })
        refetchInventories()
        const markedText = data.Inventory.update.favorite ? 'marked' : 'unmarked'
        toast.info(`The batch ID #${data.Inventory.update.id} was ${markedText} as favorite.`)
      } catch (err) {
        toast.error(`Couldn't update the favorite value of inventory ${inventory.id}.`)
      }
      setLoadingUpdate(false)
    },
    [inventory, inventory?.id]
  )
  return (
    <>
      {loadingUpdate
        ? (
            <span className="text--gray60 d-f ai-c jc-c w-l p-s">
              <FontAwesomeIcon icon={faSpinner} spin={true} />
            </span>
        ) : (
            <button className="d-f ai-c jc-c w-l p-s" onClick={(ev: MouseEventReact<HTMLButtonElement, MouseEvent>) => toggleInventoryFavorite(ev)}>
              <FontAwesomeIcon
                icon={inventory.favorite ? faStarSolid : faStar}
                className={inventory.favorite ? 'text--artYellow' : 'text--gray60'}
              />
            </button>
        )}
    </>
  );
}

export default FavoriteButton;
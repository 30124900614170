/**
 * @author Miras Absar <mabsar@iunu.com>
 */

enum EEntity {
  Facility = 'Entity.Facility',
  Building = 'Entity.Building',
  Space = 'Entity.Space',
  SensorDevice = 'Entity.SensorDevice',
  Co2SensorDevice = 'Entity.Co2SensorDevice',
  Areacam = 'Entity.Areacam',
  Inventory = 'Entity.Inventory',
  Task = 'Entity.Task',
  TaskIssue = 'Entity.TaskIssue',
  Container = 'Entity.Container',
  Chart = 'Entity.Chart',
  Tag = 'Entity.Tag'
}

export default EEntity

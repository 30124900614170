/**
 * @author Miras Absar <mabsar@iunu.com>
 */

// Types
import ISetStateType from 'graphql-lib/interfaces/ISetStateType'
import EEntity from 'map-view/enums/entity'
import IChart from 'map-view/interfaces/chart'
import IEntity from 'map-view/interfaces/entity'

// Utils
import {
  ChartColor,
  Chartable,
  ChartName,
  ChartAccuracy,
  ChartUnit
} from 'map-view/utils/chart'

interface IChartTileStyles {
  background?: string;
  borderColor?: string;
}

const UpdateSelected = ({
  selectedEntities,
  chart,
  setSelected,
  setStyle
}: {
  selectedEntities: Array<IEntity>
  chart: IChart
  setSelected: (val: boolean) => void
  setStyle: ({ background, borderColor }: IChartTileStyles) => void
}): void => {
  if (
    !selectedEntities ||
    !chart
  ) {
    return
  }

  const newSelected = !!selectedEntities
    .find(entity =>
      entity.parentType === chart.parentType &&
      String(entity.parentID) === String(chart.parentID) &&
      entity.type === EEntity.Chart &&
      entity.id === chart.chart)

  const newStyle: IChartTileStyles = {
    background: '#ffffff',
    borderColor: '#cccccc'
  }
  if (newSelected) {
    newStyle.background = chart.color || ChartColor[chart.chart]
    newStyle.borderColor = chart.color || ChartColor[chart.chart]
  }

  setSelected(newSelected)
  setStyle(newStyle)
}

const UpdateState = ({
  chart,
  loading,
  setChartable,
  setName,
  setValue,
  setUnit
}: {
  chart: IChart
  loading: boolean
  setChartable: ISetStateType<boolean>
  setName: ISetStateType<string>
  setValue: ISetStateType<string>
  setUnit: ISetStateType<string>
}): void => {
  if (!chart) {
    return
  }
  const newChartable = Chartable[chart.chart]
  const newName = ChartName[chart.chart]
  let newValue = (Array.isArray(chart.value)
    ? chart.value[chart.value.length - 1][1]
    : chart.value)?.toFixed(ChartAccuracy[chart.chart])

  let newUnit = chart.dynamicUnit || ChartUnit[chart.chart]

  newValue = loading ? 'loading data...' : newValue
  newUnit = loading ? '' : newUnit

  setChartable(newChartable)
  setName(newName)
  setValue(newValue)
  setUnit(newUnit)
}

export {
  UpdateSelected,
  UpdateState
}

// Libs
import { v4 } from 'uuid'

const Init = ({
  hookID
}: {
  hookID: { current: string };
}): void => {
  hookID.current = `hook:createRefreshToken:${v4()}`
}

export { Init }

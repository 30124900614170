/**
 * @author Miras Absar <mabsar@iunu.com>
 */

const Facility = '/grid/facility/:id'
const Building = '/grid/building/:id'
const Space = '/grid/space/:id/:timestamp?/:x?/:y?'

export {
  Facility,
  Building,
  Space
}

/**
 * @author Miras Absar <mabsar@iunu.com>
 * @file ...
 */

// Libs
import { BrowserMultiFormatReader } from '@zxing/library'
import { StringValueNode } from 'graphql'
interface SetupScannerPropsType {
  videoRef: string;
  scannerRef: {
    current: any
  };
  setValue: (param: string) => void;
}

const SetupScanner = ({
  videoRef,
  scannerRef,

  setValue
}: SetupScannerPropsType): Function | undefined => {
  if (!videoRef) {
    return
  }

  const newScanner = new BrowserMultiFormatReader()
  newScanner.decodeFromInputVideoDeviceContinuously(
    null,
    videoRef,
    result => {
      if (result) {
        const newValue = result.getText()
        setValue(newValue)
      }
    }
  )

  scannerRef.current = newScanner

  return (): void => {
    newScanner.reset()
  }
}

export { SetupScanner }

import React from 'react';
import classnames from 'classnames';

/** Icons */
import ask from 'public/assets/img/flag/icon/ask.svg';
import bactericide from 'public/assets/img/flag/icon/bactericide.svg';
import beneficialFungi from 'public/assets/img/flag/icon/beneficial-fungi.svg';
import biological from 'public/assets/img/flag/icon/biological.svg';
import checkWater from 'public/assets/img/flag/icon/check-water.svg';
import clone from 'public/assets/img/flag/icon/clone.svg';
import cooling from 'public/assets/img/flag/icon/cooling.svg';
import cull from 'public/assets/img/flag/icon/cull.svg';
import cure from 'public/assets/img/flag/icon/cure.svg';
import cut from 'public/assets/img/flag/icon/cut.svg';
import dump from 'public/assets/img/flag/icon/dump.svg';
import feed from 'public/assets/img/flag/icon/feed.svg';
import fungicide from 'public/assets/img/flag/icon/fungicide.svg';
import growth from 'public/assets/img/flag/icon/growth.svg';
import harvest from 'public/assets/img/flag/icon/harvest.svg';
import hazard from 'public/assets/img/flag/icon/hazard.svg';
import herbicide from 'public/assets/img/flag/icon/herbicide.svg';
import incomplete from 'public/assets/img/flag/icon/incomplete.svg';
import insecticide from 'public/assets/img/flag/icon/insecticide.svg';
import inventory from 'public/assets/img/flag/icon/inventory.svg';
import keepdry from 'public/assets/img/flag/icon/keepdry.svg';
import misc from 'public/assets/img/flag/icon/misc.svg';
import move from 'public/assets/img/flag/icon/move.svg';
import observe from 'public/assets/img/flag/icon/observe.svg';
import pesticide from 'public/assets/img/flag/icon/pesticide.svg';
import pinch from 'public/assets/img/flag/icon/pinch.svg';
import prevent from 'public/assets/img/flag/icon/prevent.svg';
import prune from 'public/assets/img/flag/icon/prune.svg';
import readytoplant from 'public/assets/img/flag/icon/readytoplant.svg';
import seed from 'public/assets/img/flag/icon/seed.svg';
import ship from 'public/assets/img/flag/icon/ship.svg';
import sort from 'public/assets/img/flag/icon/sort.svg';
import space from 'public/assets/img/flag/icon/space.svg';
import spray from 'public/assets/img/flag/icon/spray.svg';
import trash from 'public/assets/img/flag/icon/trash.svg';
import trellis from 'public/assets/img/flag/icon/trellis.svg';
import trial from 'public/assets/img/flag/icon/trial.svg';
import water from 'public/assets/img/flag/icon/water.svg';
import ra from 'public/assets/img/flag/icon/ra.svg';

/** Colors */
import checker4D82BF from 'public/assets/img/flag/color/checker-#4D82BF.svg';
import checker5938CA from 'public/assets/img/flag/color/checker-#5938CA.svg';
import checker5BA525 from 'public/assets/img/flag/color/checker-#5BA525.svg';
import checker7F7F7F from 'public/assets/img/flag/color/checker-#7F7F7F.svg';
import checkerB27844 from 'public/assets/img/flag/color/checker-#B27844.svg';
import checkerBD59A6 from 'public/assets/img/flag/color/checker-#BD59A6.svg';
import checkerD13B3B from 'public/assets/img/flag/color/checker-#D13B3B.svg';
import checkerDC8516 from 'public/assets/img/flag/color/checker-#DC8516.svg';
import checkerE3C100 from 'public/assets/img/flag/color/checker-#E3C100.svg';
import checkerFFFFFF from 'public/assets/img/flag/color/checker-#FFFFFF.svg';
import dot4D82BF from 'public/assets/img/flag/color/dot-#4D82BF.svg';
import dot5938CA from 'public/assets/img/flag/color/dot-#5938CA.svg';
import dot5BA525 from 'public/assets/img/flag/color/dot-#5BA525.svg';
import dot7F7F7F from 'public/assets/img/flag/color/dot-#7F7F7F.svg';
import dotB27844 from 'public/assets/img/flag/color/dot-#B27844.svg';
import dotBD59A6 from 'public/assets/img/flag/color/dot-#BD59A6.svg';
import dotD13B3B from 'public/assets/img/flag/color/dot-#D13B3B.svg';
import dotDC8516 from 'public/assets/img/flag/color/dot-#DC8516.svg';
import dotE3C100 from 'public/assets/img/flag/color/dot-#E3C100.svg';
import dotFFFFFF from 'public/assets/img/flag/color/dot-#FFFFFF.svg';
import solid000000 from 'public/assets/img/flag/color/solid-#000000.svg'; // black
import solid4D82BF from 'public/assets/img/flag/color/solid-#4D82BF.svg'; // turq 
import solid5938CA from 'public/assets/img/flag/color/solid-#5938CA.svg'; // blue
import solid5BA525 from 'public/assets/img/flag/color/solid-#5BA525.svg'; // green
import solid7F7F7F from 'public/assets/img/flag/color/solid-#7F7F7F.svg';
import solidB27844 from 'public/assets/img/flag/color/solid-#B27844.svg'; // orange
import solidBD59A6 from 'public/assets/img/flag/color/solid-#BD59A6.svg'; // purple
import solidD13B3B from 'public/assets/img/flag/color/solid-#D13B3B.svg'; // red
import solidDC8516 from 'public/assets/img/flag/color/solid-#DC8516.svg'; // brown (is orange)
import solidE3C100 from 'public/assets/img/flag/color/solid-#E3C100.svg'; // yellow
import solidFFFFFF from 'public/assets/img/flag/color/solid-#FFFFFF.svg'; // white
import stripe4D82BF from 'public/assets/img/flag/color/stripe-#4D82BF.svg';
import stripe5938CA from 'public/assets/img/flag/color/stripe-#5938CA.svg';
import stripe5BA525 from 'public/assets/img/flag/color/stripe-#5BA525.svg';
import stripe7F7F7F from 'public/assets/img/flag/color/stripe-#7F7F7F.svg';
import stripeB27844 from 'public/assets/img/flag/color/stripe-#B27844.svg';
import stripeBD59A6 from 'public/assets/img/flag/color/stripe-#BD59A6.svg';
import stripeD13B3B from 'public/assets/img/flag/color/stripe-#D13B3B.svg';
import stripeDC8516 from 'public/assets/img/flag/color/stripe-#DC8516.svg';
import stripeE3C100 from 'public/assets/img/flag/color/stripe-#E3C100.svg';
import stripeFFFFFF from 'public/assets/img/flag/color/stripe-#FFFFFF.svg';
import solid00000000 from 'public/assets/img/flag/color/solid-#00000000.svg';

const iconMap: Record<string, any> = {
  ask,
  bactericide,
  'beneficial-fungi': beneficialFungi,
  biological,
  'check-water': checkWater,
  clone,
  cooling,
  cull,
  cure,
  cut,
  dump,
  feed,
  fungicide,
  growth,
  harvest,
  hazard,
  herbicide,
  incomplete,
  insecticide,
  inventory,
  keepdry,
  misc,
  move,
  observe,
  pesticide,
  pinch,
  prevent,
  prune,
  readytoplant,
  seed,
  ship,
  sort,
  space,
  spray,
  trash,
  trellis,
  trial,
  water,
  ra
}

const colorMap: Record<string, any> = {
  'checker-#4d82bf': checker4D82BF,
  'checker-#5938ca': checker5938CA,
  'checker-#5ba525': checker5BA525,
  'checker-#7f7f7f': checker7F7F7F,
  'checker-#b27844': checkerB27844,
  'checker-#bd59a6': checkerBD59A6,
  'checker-#d13b3b': checkerD13B3B,
  'checker-#dc8516': checkerDC8516,
  'checker-#e3c100': checkerE3C100,
  'checker-#ffffff': checkerFFFFFF,
  'dot-#4d82bf': dot4D82BF,
  'dot-#5938CA': dot5938CA,
  'dot-#5ba525': dot5BA525,
  'dot-#7f7f7f': dot7F7F7F,
  'dot-#b27844': dotB27844,
  'dot-#bd59a6': dotBD59A6,
  'dot-#d13b3b': dotD13B3B,
  'dot-#dc8516': dotDC8516,
  'dot-#e3c100': dotE3C100,
  'dot-#ffffff': dotFFFFFF,
  'solid-#000000': solid000000,
  'solid-#4d82bf': solid4D82BF,
  'solid-#5938ca': solid5938CA,
  'solid-#5ba525': solid5BA525,
  'solid-#7f7f7f': solid7F7F7F,
  'solid-#b27844': solidB27844,
  'solid-#bd59a6': solidBD59A6,
  'solid-#d13b3b': solidD13B3B,
  'solid-#dc8516': solidDC8516,
  'solid-#e3c100': solidE3C100,
  'solid-#ffffff': solidFFFFFF,
  'stripe-#4d82bf': stripe4D82BF,
  'stripe-#5938ca': stripe5938CA,
  'stripe-#5ba525': stripe5BA525,
  'stripe-#7f7f7f': stripe7F7F7F,
  'stripe-#b27844': stripeB27844,
  'stripe-#bd59a6': stripeBD59A6,
  'stripe-#d13b3b': stripeD13B3B,
  'stripe-#dc8516': stripeDC8516,
  'stripe-#e3c100': stripeE3C100,
  'stripe-#ffffff': stripeFFFFFF,
  'solid-#00000000': solid00000000,
  black: solid000000,
  turq: solid4D82BF,
  blue: solid5938CA,
  green: solid5BA525,
  orange: solidB27844,
  purple: solidBD59A6,
  red: solidD13B3B,
  brown: solidDC8516, // (is orange)
  yellow: solidE3C100,
  white: solidFFFFFF
}

export interface IFlagProps {
  flag: any;
  status?: String,
  onClick?: (flag: any) => void;
}

const Flag = (props: IFlagProps): JSX.Element => {
  const { flag, status, onClick } = props;
  let { icon, color } = flag
  // hard code the "trash" icon for rejected tasks
  icon = status === 'rejected' ? 'trash' : (icon === '' ? null : icon.toLowerCase())
  color = color.toLowerCase()
  return (
    <div
      className={classnames('Theme__Dark Flag', { Fade: status === 'rejected' })}
      onClick={onClick}
      tabIndex={0}
    >
      <img className='Icon' src={iconMap[icon]} />
      <img className='Color' src={colorMap[color]} />
    </div>
  )
}

export default Flag

/**
 * @author Miras Absar <mabsar@iunu.com>
 */

// Types
import EEntity from 'map-view/enums/entity'
import ISpace from 'graphql-lib/interfaces/ISpace'
import IChart from 'map-view/interfaces/chart'

// React
import React, {
  MouseEvent,
  useCallback,
  useContext,
  useState
} from 'react'

// React Libs
import { useIter } from 'react-lib/use-iter'

// Font Awesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faWarehouseAlt } from '@fortawesome/pro-solid-svg-icons'

// Map View
import Mobius from 'map-view/contexts/mobius'

// Effects
import { UpdateState } from './effects'

// UI Lib
import SpaceTile from 'ui-lib/components/tiles/space-tile'
import ChartTile from '../../chart-tile'

interface ISpaceItemProps {
  animated?: boolean;
  space: ISpace;
}

const SpaceItem = ({
  animated,
  space
}: ISpaceItemProps): JSX.Element => {
  // Context ///////////////////////////////////////////////////////////////////

  const {
    selectedEntities: {
      selectedEntities,
      selectEntity,
      selectedEntitiesUUID
    },

    spaces: {
      spaces,
      spacesUUID
    },

    spaceCharts: {
      spaceCharts,
      spaceChartsUUID
    }
  } = useContext(Mobius)

  // State /////////////////////////////////////////////////////////////////////

  const [selected, setSelected] = useState<boolean>()
  const [charts, setCharts] = useState<IChart[]>()

  // Effects ///////////////////////////////////////////////////////////////////

  useIter(
    UpdateState,

    { selectedEntities,
      space,
      spaceCharts,

      setSelected,
      setCharts },

    [ selectedEntitiesUUID,
      space.id,
      spaceChartsUUID ]
  )

  // Callback //////////////////////////////////////////////////////////////////

  const $click = useCallback(
    (ev: MouseEvent<HTMLElement>): void =>
      selectEntity(
        { type: EEntity.Space,
          id: space.id },

        ev.ctrlKey || ev.metaKey
      ),

    [ selectEntity,
      space.id ]
  )

  // Render ////////////////////////////////////////////////////////////////////

  let className = 'SpaceItem'
  if (animated) className += ' Animated'
  if (selected) className += ' Selected'
  return (
    <section
      className={className}
      onClick={$click}
      data-type={EEntity.Space}
      data-id={space.id}
    >
      <div className='Main'>
        <SpaceTile space={space} />

        <div className='Body'>
          <h6>
            {space.name}
          </h6>
        </div>
      </div>

      {charts && charts.length > 0 && (
        <div className='ChartTiles'>
          {charts.map(chart => (
            <ChartTile
              key={chart.chart}
              animated={animated}
              chart={chart}
              space={space}
            />
          ))}
        </div>
      )}
    </section>
  )
}

export default SpaceItem

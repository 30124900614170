// React
import React, {
  useEffect,
  useRef,
  useState
} from 'react'

// React Libs
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimes } from '@fortawesome/pro-light-svg-icons'

// GraphQL
import { gql, useMutation } from '@apollo/client'

// Utils
import { EStorage, GetItem } from 'utils/storage'
import GoTo from 'react-lib/go-to'

// UI Lib
import EInputStatus from 'ui-lib/enums/input-status'
import IInputStatus from 'ui-lib/interfaces/IInputStatus'
import SearchSelect from 'ui-lib/components/search-select'
import Input from 'ui-lib/components/Input/Input'
import Button from 'ui-lib/components/button'

// UI Lib Cores
import ProductSearchCore from 'graphql-lib/cores/search-select/product'
import ContainerTypeSearchCore from '../../cores/search-select/container-type'
import {
  RequiredNumberInputCore as RequiredNumberCore
} from 'ui-lib/cores/number-input-core'
import {
  TextInputCore as TextCore,
  RequiredTextInputCore as RequiredTextCore
} from 'ui-lib/cores/text-input-core'
import {
  DateInputCore as DateCore,
  RequiredDateInputCore as RequiredDateCore
} from 'ui-lib/cores/date-input-core'

// UI Lib Facades
import SelectFacade from 'ui-lib/facades/select'
import ProductItemFacade from 'graphql-lib/facades/search-select/product'
import ContainerTypeFacade from '../../facades/search-select/container-type'
import TextFacade from 'ui-lib/facades/text-input'
import TextareaFacade from 'ui-lib/facades/textarea-input'
import DateFacade from 'ui-lib/facades/datetime-input'
import { Crops, ViewCrop } from '../../utils/Routes'
import ICrop from 'graphql-lib/interfaces/ICrop'

const CreateCrop = gql`
  mutation(
    $facilityId: ID!,
    $productId: ID,
    $name: String
    $code: String,
    $containerSize: String,
    $containersPerLocator: Int,
    $startDate: DateTime!,
    $transplantDate: DateTime,
    $endDate: DateTime!
    $description: String
  ) {
    Crop {
      create(input: {
        facilityId: $facilityId,
        productId: $productId,
        name: $name,
        code: $code,
        containerSize: $containerSize,
        containersPerLocator: $containersPerLocator,
        startDate: $startDate,
        transplantDate: $transplantDate,
        endDate: $endDate
        description: $description
      }) {
        id
        createdOn
        updatedOn
        
        startDate
        endDate
        transplantDate
        enabled
        archived
        code
        name
        containerSize
        containersPerLocator
        rating      

        speciesCode
        speciesName
        speciesIcon

        varietyCode
        varietyName
        varietyColor
        varietyPattern

        inventoryCount
      }
    }
  }
`

interface ICreateCropCardProps {
  outlined?: boolean;
  resting?: boolean;
  raised?: boolean;
  animated?: boolean;
}

export default function CreateCropCard ({
  outlined,
  resting,
  raised,
  animated
}: ICreateCropCardProps): JSX.Element {
  // Refs
  const productSearchCore = useRef(new ProductSearchCore(true))
  const containerTypeSearchCore = useRef(new ContainerTypeSearchCore(false))
  const value = useRef<Record<string, any>>({})
  const status = useRef<Record<string, any>>({})

  // State
  const [initialValue, setInitialValue] = useState<Partial<ICrop>>({})
  const [showStatusUntouched, setShowStatusUntouched] = useState(false)

  // Create a Crop
  const [
    createCrop,
    {
      loading,
      data
    }
  ] = useMutation(CreateCrop)

  // If a Crop has been created,
  // open it.
  useEffect((): void => {
    if (data) {
      const ev = new CustomEvent(
        'create_crop',
        { detail: {
          dispatcherID: null,
          create: data.Crop.create
        } }
      )

      window.dispatchEvent(ev)

      const id = data.Crop.create.id
      const to = ViewCrop.replace(':id', id)

      GoTo(to)
    }
  }, [data])

  const $close = (): void => GoTo(Crops)
  const $value = (name: string, newValue: any): void => {
    if (newValue !== undefined &&
      newValue !== undefined &&
      !Number.isNaN(newValue)) {
      value.current[name] = newValue

      if (name === 'product') {
        const nextInitialValue = Object.assign(
          {},
          initialValue,
          {
            name: newValue.nickname,
            code: newValue.code
          }
        )

        setInitialValue(nextInitialValue)
      }
    }
  }

  const $status = (name: string, newStatus: IInputStatus): void => {
    status.current[name] = newStatus
  }

  const $save = (): void => {
    const hasError = Object.values(status.current).reduce(
      (acc: boolean, cur: IInputStatus): boolean =>
        acc || cur.status === EInputStatus.Error,
      false
    )

    if (hasError) {
      setShowStatusUntouched(true)
    } else {
      if (!value.current.containerSize) {
        value.current.containerSize = undefined
      }

      if (!value.current.containersPerLocator) {
        value.current.containersPerLocator = undefined
      }

      const [{ id: facilityId }] = GetItem('facilities', EStorage.EphemeralStorage)

      createCrop({
        variables: {
          facilityId: Number(facilityId),
          productId: value.current.product.id,
          name: value.current.name,
          code: value.current.code,
          containerSize: value.current.containerSize,
          containersPerLocator: value.current.containersPerLocator,
          startDate: value.current.startDate,
          transplantDate: value.current.transplantDate,
          endDate: value.current.endDate,
          description: value.current.description
        }
      })
    }
  }

  let className = 'Theme__Light Card CreateCropCard'
  if (outlined) className += ' Outlined'
  if (resting) className += ' Resting'
  if (raised) className += ' Raised'
  if (animated) className += ' Animated'

  return (
    <article className={className}>
      <div className='Head'>
        <div className='Row'>
          <button onClick={$close}>
            <FontAwesomeIcon icon={faTimes} />
          </button>

          <h6 className='Primary'>Create Crop</h6>
        </div>
      </div>

      <div className='Body'>
        <SearchSelect
          animated
          label='Product'
          inputFacade={TextFacade}
          selectFacade={SelectFacade}
          itemFacade={ProductItemFacade}

          name='product'
          showStatusUntouched={showStatusUntouched}
          onValueChange={$value}
          onStatusChange={$status}
          core={productSearchCore.current}
        />

        <Input
          animated
          label='Name'
          facade={TextFacade}

          name='name'
          initialValue={initialValue.name}
          showStatusUntouched={showStatusUntouched}
          onValueChange={$value}
          onStatusChange={$status}
          readOnly={false}
          core={RequiredTextCore}
        />

        <Input
          animated
          label='Code'
          facade={TextFacade}

          name='code'
          initialValue={initialValue.code}
          showStatusUntouched={showStatusUntouched}
          onValueChange={$value}
          onStatusChange={$status}
          readOnly={false}
          core={RequiredTextCore}
        />

        <SearchSelect
          animated
          label='Container Type'
          inputFacade={TextFacade}
          selectFacade={SelectFacade}
          itemFacade={ContainerTypeFacade}

          name='containerSize'
          initialValue=''
          showStatusUntouched={showStatusUntouched}
          onValueChange={$value}
          onStatusChange={$status}
          readOnly={false}
          core={containerTypeSearchCore.current}
        />

        <Input
          animated
          label='Containers per Group'
          facade={TextFacade}

          name='containersPerLocator'
          initialValue=''
          showStatusUntouched={showStatusUntouched}
          onValueChange={$value}
          onStatusChange={$status}
          readOnly={false}
          core={RequiredNumberCore}
        />

        <Input
          animated
          label='Start Date'
          facade={DateFacade}

          name='startDate'
          initialValue={undefined}
          showStatusUntouched={showStatusUntouched}
          onValueChange={$value}
          onStatusChange={$status}
          readOnly={false}
          core={RequiredDateCore}
        />

        <Input
          animated
          label='Transplant Date'
          facade={DateFacade}

          name='transplantDate'
          initialValue={undefined}
          showStatusUntouched={showStatusUntouched}
          onValueChange={$value}
          onStatusChange={$status}
          readOnly={false}
          core={DateCore}
        />

        <Input
          animated
          label='End Date'
          facade={DateFacade}

          name='endDate'
          initialValue={undefined}
          showStatusUntouched={showStatusUntouched}
          onValueChange={$value}
          onStatusChange={$status}
          readOnly={false}
          core={RequiredDateCore}
        />

        <Input
          animated
          label='Description'
          facade={TextareaFacade}

          name='description'
          initialValue={undefined}
          showStatusUntouched={showStatusUntouched}
          onValueChange={$value}
          onStatusChange={$status}
          readOnly={false}
          core={TextCore}
        />
      </div>

      <div style={{ flex: '1 0 auto' }} />

      <div className='Actions'>
        <Button
          animated={animated}
          outlined
          loading={loading}
          onClick={$save}
        >
          Save
        </Button>
      </div>
    </article>
  )
}

// Types
import ICrop from 'graphql-lib/interfaces/ICrop'

// React
import React, {
  ChangeEvent,
  useCallback,
  useState
} from 'react'

// React Libs
import { useDebounce } from 'use-debounce'
import { useIter } from 'react-lib/use-iter'
import Fit from 'react-fit'

// GraphQL
import { useQuery } from '@apollo/client'
import { QueryCrops } from './queries'

// Font Awesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSearch } from '@fortawesome/pro-light-svg-icons'

// Effects
import { SetCrops } from './effects'

// UI Lib
import CropItem from 'ui-lib/components/crop-item'
import { parseUrl, stringifyUrl } from 'query-string'

import ID from 'graphql-lib/interfaces/ID';
export interface ICropsSearchProps {
  animated: boolean;
  onSelect: (id: ID) => void;
}

const CropsSearch = ({
  animated,
  onSelect
}: ICropsSearchProps): JSX.Element => {
  // State /////////////////////////////////////////////////////////////////////

  const [search, setSearch] = useState<string>()
  const [debouncedSearch] = useDebounce<string>(search, 300)
  const [crops, setCrops] = useState<ICrop[]>()
  
  // Queries ///////////////////////////////////////////////////////////////////
  const { query } = parseUrl(window.location.search)
  const facilityId = parseFloat(query.fid as string)
  
  const {
    loading: cropsLoading,
    error: cropsError,
    data: cropsData
  } = useQuery(QueryCrops, {
    skip: !debouncedSearch,
    variables: { query: debouncedSearch, facilityIds: [facilityId] },
    fetchPolicy: 'no-cache'
  })

  
  // Effects ///////////////////////////////////////////////////////////////////

  useIter(
    SetCrops,

    { cropsData,
      setCrops },

    [cropsData]
  )

  // Callbacks /////////////////////////////////////////////////////////////////

  const search$change = useCallback(
    (ev: ChangeEvent<HTMLInputElement>) => {
      const newSearch = ev.target.value
      setSearch(newSearch)
    },
    [search]
  )

  const crop$select = useCallback(
    (id: ID): void => {
      onSelect?.(id)
      setSearch('')
    },

    [ onSelect,
      setSearch ]
  )

  // Render ////////////////////////////////////////////////////////////////////

  let className = 'CropsSearch'
  if (animated) className += ' Animated'

  return (
    <div className={className}>
      <FontAwesomeIcon icon={faSearch} />

      <input
        type='text'
        placeholder='Search'
        value={search}

        onChange={search$change}
      />

      {debouncedSearch && crops && (
        <Fit>
          <div className='List'>
            {crops.map((c): JSX.Element => (
              <CropItem
                key={c.id}

                crop={c}
                onSelect={crop$select}
                readOnly
              />
            ))}
          </div>
        </Fit>
      )}
    </div>
  )
}

export default CropsSearch

// Utils
import { format } from 'date-fns'

// React
import React, {
  useCallback,
  useMemo
} from 'react'

// Components
import ChartTile from './chart-tile'
import { createDate } from 'utils/dates'
import IAreacam from 'graphql-lib/interfaces/IAreacam'

interface IStaticCameraItemProps {
  outlined?: boolean;
  resting?: boolean
  raised?: boolean;
  animated?: boolean;
  areacam: IAreacam;
  setSelectedAreacam: (newVal: any) => void;
}

const DateFormat = 'M/d/yy p'
const StaticCameraItem = ({
  outlined,
  resting,
  raised,
  animated,

  areacam,
  setSelectedAreacam
}: IStaticCameraItemProps): JSX.Element => {
  // Memos /////////////////////////////////////////////////////////////////////

  const imageSrc = useMemo(
    (): string => {
      if (
        !areacam.areaImageRecord ||
        areacam.areaImageRecord.length === 0
      ) {
        return undefined
      }

      const latestAreaImageRecord = areacam.areaImageRecord[0]
      return latestAreaImageRecord.thumbUrl
    },
    [areacam]
  )

  const areacamCode = useMemo(
    (): string => {
      const areacamCode = areacam.code.toLocaleUpperCase()
      return areacamCode
    },
    [areacam]
  )

  const lastSeen = useMemo(
    (): string => {
      if (
        !areacam.areaImageRecord ||
        areacam.areaImageRecord.length === 0 ||
        !areacam.space ||
        areacam.space.length === 0
      ) {
        return 'No Data'
      }

      const space = areacam.space[0]
      const latestAreaImageRecord = areacam.areaImageRecord[0]
      const lastSeen =
        (space.name || space.code) +
        ' - ' +
        format(createDate(latestAreaImageRecord.createdOn), DateFormat)

      return lastSeen
    },
    [areacam]
  )

  // Callbacks /////////////////////////////////////////////////////////////////

  const $click =
    (): void => setSelectedAreacam(areacam)

  // Render ////////////////////////////////////////////////////////////////////

  let className = 'StaticCameraItem'
  if (outlined) className += ' Outlined'
  if (resting) className += ' Resting'
  if (raised) className += ' Raised'
  if (animated) className += ' Animated'

  return (
    <div
      className={className}
      onClick={$click}
    >
      <img src={imageSrc} />

      <div className='Body'>
        <h6>{areacamCode}</h6>
        <p>{lastSeen}</p>

        {areacam.chart && areacam.chart.length > 0 && (
          <div className='ChartTiles'>
            {areacam.chart.map(chart => (
              <ChartTile
                key={chart.chart}
                animated
                chart={chart}
              />
            ))}
          </div>
        )}
      </div>
    </div>
  )
}

export default StaticCameraItem

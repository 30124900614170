// React
import React, {
  useEffect,
  useState
} from 'react'

// React Libs
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimes } from '@fortawesome/pro-regular-svg-icons'
import { toast } from 'react-toastify'

// GraphQL
import { gql, useQuery } from '@apollo/client'

// Utils
import GoTo from 'react-lib/go-to'

// UI Lib
import Tabs from 'ui-lib/components/tabs'
import Details from './details'
import InventoryGroups from './inventory-groups'
import { Crops } from '../../utils/Routes'

// Types
import ICrop from 'graphql-lib/interfaces/ICrop'
import ID from 'graphql-lib/interfaces/ID'
import { ITab } from 'ui-lib/components/tab'

const DetailsTab: ITab = {
  id: 0,
  label: 'Details'
}

const InventoryGroupsTab: ITab = {
  id: 1,
  label: 'Inventory Groups'
}

const _Tabs = [
  DetailsTab,
  // InventoryGroupsTab
]

const QueryCrop = gql`
  query($id: ID) {
    crop(id: $id) {
      id
      name
    }
  }
`

export interface IViewCropCardProps {
  outlined?: boolean;
  resting?: boolean;
  raised?: boolean;
  animated?: boolean;
  id: ID
}

const ViewCropCard = ({
  outlined,
  resting,
  raised,
  animated,
  id
}: IViewCropCardProps): JSX.Element => {
  const [selectedTab, setSelectedTab] = useState<ITab>(DetailsTab)
  const [crop, setCrop] = useState<ICrop>()

  const {
    error,
    data
  } = useQuery(QueryCrop, { variables: { id } })

  useEffect((): void => {
    if (error) {
      toast.error("Couldn't load crop; please try again in a few seconds.")
    }
  }, [error])

  useEffect((): void => {
    if (data) {
      const newCrop = data.crop[0]
      setCrop(newCrop)
    }
  }, [data])

  const $close = (): void => GoTo(Crops)

  let className = 'Theme__Light Card ViewCropCard'
  if (outlined) className += ' Outlined'
  if (resting) className += ' Resting'
  if (raised) className += ' Raised'
  if (animated) className += ' Animated'

  return (
    <article className={className}>
      <div className='Head Main'>
        <div className='Row'>
          <button onClick={$close}>
            <FontAwesomeIcon icon={faTimes} />
          </button>
          {crop
            ? <h6 className='Primary'>{crop.name}</h6>
            : <h6 className='Loading' />}
        </div>
      </div>

      <div className='Body'>
        <Tabs
          animated={animated}

          tabs={_Tabs}
          selectedTab={selectedTab}
          onSelect={setSelectedTab}
        />
      </div>

      {selectedTab.id === DetailsTab.id && (
        <Details
          animated={animated}
          id={id}
        />
      )}

      {selectedTab.id === InventoryGroupsTab.id && (
        <InventoryGroups
          animated={animated}
          id={id}
        />
      )}
    </article>
  )
}

export default ViewCropCard

import React, {
  KeyboardEvent,
} from 'react'

import ISpace from 'graphql-lib/interfaces/ISpace'
import IItemFacadeProps from 'ui-lib/interfaces/IItemFacadeProps'

export default function SpaceItem({
  /* Visual Properties */
  animated,
  /* Behavioral Properties */
  value,
  onSelect
}: IItemFacadeProps<ISpace>): JSX.Element {
  const className = animated
    ? 'SpaceItem Animated'
    : 'SpaceItem'

  const $keyPress = (ev: KeyboardEvent): void => {
    if (ev.key === 'Enter') {
      onSelect(value)
    }
  }

  const $click = (): void => onSelect(value)

  return (
    <div
      className={className}
      onKeyPress={$keyPress}
      onClick={$click}
      tabIndex={0}
    >
      <p className='Title'>{value.name}</p>
    </div>
  )
}

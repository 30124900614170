// Types
import EEntity from 'graphql-lib/enums/entity'
import IEntity from 'graphql-lib/interfaces/entity'
import ICrop from 'graphql-lib/interfaces/ICrop'
import IInventory from 'graphql-lib/interfaces/IInventory'
import IInventoryStitch from 'graphql-lib/interfaces/IInventoryStitch'

// Libs
import { v4 } from 'uuid'

// OpenLayers
import { Extent } from 'ol/extent'
import View from 'ol/View'
import Layer from 'ol/layer/Layer'
import Collection from 'ol/Collection'
import { Map } from 'ol'

import ID from 'graphql-lib/interfaces/ID';
import ISetStateType from 'graphql-lib/interfaces/ISetStateType';

const UpdateSize = ({
  mapRef
}: {
  mapRef: { current: Map};
}): void => {
  if (mapRef.current) {
    setTimeout((): void => {
      mapRef.current.updateSize()
    }, 16)
  }
}

const UpdateSelectedInventories = ({
  selectedEntities,
  inventories,

  setSelectedInventories,
  setSelectedInventoriesUUID
}: {
  selectedEntities: IEntity[];
  inventories: IInventory[];

  setSelectedInventories: (newVal: IInventory[]) => void;
  setSelectedInventoriesUUID: (newVal: string) => void;
}): void => {
  if (selectedEntities && inventories) {
    const newSelectedInventories = selectedEntities
      .filter((e): boolean => e.type === EEntity.Inventory)
      .map((e): IInventory | undefined => inventories.find((i): boolean => i.id == e.id))
      .filter((i): boolean => !!i) as IInventory[]

    const newSelectedInventoriesUUID = v4()

    setSelectedInventories(newSelectedInventories)
    setSelectedInventoriesUUID(newSelectedInventoriesUUID)
  }
}

const UpdateCurrentInventoryStitches = ({
  selectedInventories,
  inventoryStitchesCache,

  setCurrentInventoryStitches,
  setCurrentInventoryStitchesUUID
}: {
  selectedInventories: IInventory[];
  inventoryStitchesCache: Record<ID, IInventoryStitch[]>;

  setCurrentInventoryStitches: (newVal: IInventoryStitch[]) => void;
  setCurrentInventoryStitchesUUID: (newVal: string) => void;
}): void => {
  if (selectedInventories && inventoryStitchesCache) {
    const newCurrentInventoryStitches = selectedInventories
      .filter((i): boolean => !!inventoryStitchesCache[i.id])
      .map((i): IInventoryStitch => inventoryStitchesCache[i.id][0])
      .filter((is): boolean => !!is)

    const newCurrentInventoryStitchesUUID = v4()

    setCurrentInventoryStitches(newCurrentInventoryStitches)
    setCurrentInventoryStitchesUUID(newCurrentInventoryStitchesUUID)
  }
}

const UpdateLaidOutStitches = ({
  currentInventoryStitches,

  setLaidOutInventoryStitches,
  setLaidOutInventoryStitchesUUID
}: {
  currentInventoryStitches: Array<IInventoryStitch>;

  setLaidOutInventoryStitches: ISetStateType<Array<IInventoryStitch>>;
  setLaidOutInventoryStitchesUUID: ISetStateType<string>;
}): void => {
  // TODO: Why does this function returns just before it does anything

  return;
  // if (currentInventoryStitches) {
  //   const rootNode = Node.create()
  //   rootNode.setDisplay(DISPLAY_FLEX)
  //   rootNode.setFlexDirection(FLEX_DIRECTION_ROW)

  //   const newLaidOutInventoryStitches = currentInventoryStitches
  //     .map((is: {
  //       space: Array<ISpace>
  //       cell: {
  //         br: [number, number],
  //         tl: [number, number]
  //       }
  //     }) => {
  //       const spaceWidth = is.space[0].dcs.main.head.metadata.width
  //       const spaceHeight = is.space[0].dcs.main.head.metadata.height

  //       const relativeStitchWidth = is.cell.br[0] - is.cell.tl[0]
  //       const relativeStitchHeight = is.cell.br[1] - is.cell.tl[1]

  //       const stitchWidth = spaceWidth * relativeStitchWidth * 4000
  //       const stitchHeight = spaceHeight * relativeStitchHeight * 4000

  //       const inventoryNode = Node.create()
  //       inventoryNode.setWidth(stitchWidth)
  //       inventoryNode.setHeight(stitchHeight)
  //       rootNode.insertChild(inventoryNode, rootNode.getChildCount())
  //       Object.assign(
  //         is,
  //         { node: inventoryNode }
  //       )

  //       return is
  //     })

  //   rootNode.calculateLayout()
  //   const newLaidOutInventoryStitchesUUID = v4()

  //   setLaidOutInventoryStitches(newLaidOutInventoryStitches)
  //   setLaidOutInventoryStitchesUUID(newLaidOutInventoryStitchesUUID)
  // }
}

const UpdateOpenLayers = ({
  crops,
  inventories,
  laidOutInventoryStitches,
  view,

  setView,
  layers,
  setOpenLayersUUID
}: {
  crops: ICrop[];
  inventories: Array<IInventory>;
  laidOutInventoryStitches: null;
  view: View;

  setView: (newVal: View) => void;
  layers: Collection<Layer>;
  setOpenLayersUUID: (newVal: string) => void;
}): void => {
  // TODO: The laidOutInventoryStitches property is always null.
  // The next validation never occurs. The reason is unclear.
  // (See UpdateLaidOutStitches above)
  if (crops && inventories && laidOutInventoryStitches) {
    // Delete the old view
    const oldView = view
    if (oldView) {
      setView(undefined)
      setTimeout((): void => {
        oldView.dispose()
      }, 16)
    }

    // Delete the old layers
    const oldLayers = layers
    while (oldLayers.getLength() > 0) {
      const layer = oldLayers.pop()
      const source = layer.getSource()
      layer.dispose()
      source.dispose()
    }

    // Start creating the new view
    const viewExtent: Extent = [
      0,
      0,
      0,
      0
    ]

    // Create the new layers
    // laidOutInventoryStitches
    //   .forEach((is): void => {
    //     // Metadata Stuff
    //     const inventory = inventories.find((i): boolean => Number(i.id) === Number(is.inventoryId))
    //     const crop = crops.find((c: ICrop): boolean => Number(c.id) === Number(inventory.cropId))

    //     // Layout Stuff
    //     const layout = is.node.getComputedLayout()
    //     const minX = layout.left
    //     const minY = layout.top
    //     const maxX = minX + layout.width
    //     const maxY = minY + layout.height

    //     // Projection Stuff
    //     const srcPts = [
    //       [0, 0],
    //       [is.width, 0],
    //       [is.width, is.height],
    //       [0, is.height]
    //     ]

    //     const dstPts = [
    //       [minX, minY],
    //       [maxX, minY],
    //       [maxX, maxY],
    //       [minX, maxY]
    //     ]

    //     const projectiveTransformation = new ProjectiveTransformation(srcPts, dstPts)
    //     const projectionCode = v4()
    //     const projection = new Projection({
    //       code: projectionCode,
    //       units: 'pixels',
    //       extent: [
    //         0,
    //         0,
    //         is.width,
    //         is.height
    //       ],
    //       getPointResolution: (resolution: any): any => resolution
    //     })

    //     addProjection(projection)
    //     addCoordinateTransforms(
    //       'EPSG:3857',
    //       projectionCode,
    //       (coordinate: number[]): number[] => projectiveTransformation.inverseTransform(coordinate),
    //       (coordinate: number[]): number[] => projectiveTransformation.transform(coordinate)
    //     )

    //     // OpenLayers Stuff
    //     const newSource = new ZoomifySource({
    //       crossOrigin: 'anonymous',
    //       projection: projectionCode,
    //       url: is.tilesUrl + '/{TileGroup}/{z}-{x}-{y}.webp',
    //       size: [
    //         is.width,
    //         is.height
    //       ],
    //       extent: [
    //         0,
    //         0,
    //         is.width,
    //         is.height
    //       ],
    //       tileSize: is.tileSize
    //     })

    //     if (!window.supportsWebp) {
    //       newSource.setTileLoadFunction(tileLoadFunction)
    //     }

    //     const newLayer = new TileLayer({
    //       extent: [
    //         minX,
    //         minY,
    //         maxX,
    //         maxY
    //       ],
    //       source: newSource
    //     })

    //     newLayer.set('cropName', crop.name)
    //     newLayer.set('inventoryCode', inventory.code)
    //     layers.push(newLayer)
    //     extend(
    //       viewExtent,
    //       [
    //         minX,
    //         minY,
    //         maxX,
    //         maxY
    //       ]
    //     )
    //   })

    // Finish creating the new view
    const newView = new View({
      enableRotation: false,
      extent: viewExtent,
      constrainOnlyCenter: true,
      maxZoom: 31,
      minZoom: 7
    })

    const newOpenLayersUUID = v4()

    setView(newView)
    setOpenLayersUUID(newOpenLayersUUID)

    setTimeout((): void => {
      newView.fit(viewExtent)
    }, 16)
  }
}

export {
  UpdateSize,
  UpdateSelectedInventories,
  UpdateCurrentInventoryStitches,
  UpdateLaidOutStitches,
  UpdateOpenLayers
}

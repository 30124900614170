// Types
import IRefreshToken from 'graphql-lib/interfaces/IRefreshToken';
import IAccessToken from 'graphql-lib/interfaces/IAccessToken';
import IUser from 'graphql-lib/interfaces/IUser';
import ICustomer from 'graphql-lib/interfaces/ICustomer';
import IFacility from 'graphql-lib/interfaces/IFacility';
import ID from 'graphql-lib/interfaces/ID';

// Utils
import Has from 'utils/has';
import { isNil } from 'utils/ts-utils';
import config from 'config';

// Libs
import JWTDecode from 'jwt-decode';

// React Libs
import { toast } from 'react-toastify';
import ReactGA from 'react-ga'

const SignInWithToken = ({
  token,
  refreshTokenCreate
}: {
  token: string;
  refreshTokenCreate: (
    create: any
  ) => Promise<{ error: Error; refreshToken: IRefreshToken }>
}): void => {
  if (token) {
    refreshTokenCreate({ apiKey: token })
  }
}

const SignInWithAccessToken = ({
  accessToken,

  setAccessToken,
  setUserID,
  setCustomerID
}: {
  accessToken?: string;

  setAccessToken: (newVal: string) => void;
  setUserID: (newVal: ID) => void;
  setCustomerID: (newVal: ID) => void;
}): void => {
  if (accessToken) {
    const decodedAccessToken: { userId: number; customerId: number; } = JWTDecode(accessToken)

    setAccessToken(accessToken)
    setUserID(decodedAccessToken.userId)
    setCustomerID(decodedAccessToken.customerId)
  }
}

const UpdateRefreshToken = ({
  refreshTokenLoading,
  refreshTokenError,
  newRefreshToken,

  setLoading,
  setError,
  setRefreshToken,
  setUserID,
  setCustomerID
}: {
  refreshTokenLoading: boolean;
  refreshTokenError: Error;
  newRefreshToken: IRefreshToken;

  setLoading: (newVal: boolean) => void;
  setError: (newVal: Error) => void;
  setRefreshToken: (newVal: string) => void;
  setUserID: (newVal: ID) => void;
  setCustomerID: (newVal: ID) => void;
}): void => {
  if (refreshTokenLoading) {
    setLoading(true)
  } else {
    if (refreshTokenError) {
      setLoading(false)
      setError(refreshTokenError)
      setRefreshToken(null)
      setUserID(null)
      setCustomerID(null)

      toast.error("We couldn't find an account with that email & password.")
    } else if (newRefreshToken) {
      if (!!newRefreshToken.isApi) {
        toast.error("API accounts cannot use LUNA Web.")
        setLoading(false)
        setError(refreshTokenError)
        setRefreshToken(null)
        setUserID(null)
        setCustomerID(null)
        return
      }

      setLoading(false)
      setError(null)
      setRefreshToken(newRefreshToken.refreshToken)
      setUserID(newRefreshToken.userId)
      if (Has(newRefreshToken.customerId)) {
        setCustomerID(newRefreshToken.customerId)
      }
    }
  }
}

const CreateAccessToken = ({
  cid,
  refreshToken,
  customerID,

  accessTokenCreate
}: {
  cid: ID,
  refreshToken: string;
  customerID: ID;

  accessTokenCreate: (create: any) => Promise<void>;
}): void => {
  if (refreshToken) {
    const decodedRefreshToken: { admin?: boolean; } = JWTDecode(refreshToken)
    const actualCustomerID =
      decodedRefreshToken.admin && cid
        ? Number(cid)
        : (isNil(customerID) ? null : Number(customerID))

    accessTokenCreate({
      refreshToken,
      customerId: actualCustomerID
    })
  }
}

const UpdateAccessToken = ({
  accessTokenLoading,
  accessTokenError,
  newAccessToken,

  setLoading,
  setError,
  setAccessToken,
  setUserID,
  setCustomerID
}: {
  accessTokenLoading: boolean;
  accessTokenError: Error;
  newAccessToken: IAccessToken;

  setLoading: (newVal: boolean) => void;
  setError: (newVal: Error) => void;
  setAccessToken: (newVal: string) => void;
  setUserID: (newVal: ID) => void;
  setCustomerID: (newVal: ID) => void;
}): void => {
  if (accessTokenLoading) {
    setLoading(true)
  } else {
    if (accessTokenError) {
      setLoading(false)
      setError(accessTokenError)
      setAccessToken(null)
      setUserID(null)
      setCustomerID(null)

      toast.error("We couldn't find an account with that email & password.")
    } else if (newAccessToken) {
      setLoading(false)
      setError(null)
      setAccessToken(newAccessToken.accessToken)
      setUserID(newAccessToken.userId)
      if (Has(newAccessToken.customerId)) {
        setCustomerID(newAccessToken.customerId)
      }
    }
  }
}

const DecodeRefreshToken = ({
  refreshToken,
  setDecodedRefreshToken
}: {
  refreshToken: string;
  setDecodedRefreshToken: (newVal: any) => void;
}): void => {
  if (refreshToken) {
    const newDecodedRefreshToken = JWTDecode(refreshToken)
    setDecodedRefreshToken(newDecodedRefreshToken)
  }
}

const DecodeAccessToken = ({
  accessToken,
  setDecodedAccessToken
}: {
  accessToken: string;
  setDecodedAccessToken: (newVal: any) => void;
}): void => {
  if (accessToken) {
    const newDecodedAccessToken = JWTDecode(accessToken)
    setDecodedAccessToken(newDecodedAccessToken)
  }
}

const UpdateUser = ({
  accessToken,
  userLoading,
  userError,
  newUser,

  setLoading,
  setError,
  setUser
}: {
  accessToken: string;
  userLoading: boolean;
  userError: Error;
  newUser: IUser;

  setLoading: (newVal: boolean) => void;
  setError: (newVal: Error) => void;
  setUser: (newVal: IUser) => void;
}): void => {
  if (userLoading) {
    setLoading(true)
  } else {
    if (userError) {
      setLoading(false)
      setError(userError)
      setUser(null)

      toast.error("Couldn't load User.")
    } else if (newUser) {
      const decodedAccessToken: { admin?: boolean; } = JWTDecode(accessToken)
      if (decodedAccessToken.admin) {
        newUser.admin = true
      } else {
        newUser.admin = false
      }

      setLoading(false)
      setError(null)
      setUser(newUser)

      // Heap, Google Analytics, and Help Scout
      heap.identify?.(newUser.id)
      heap.addUserProperties?.({
        email: newUser.email,
        handle: newUser.name,
        Admin: newUser.admin,
        Title: newUser.title,
        'Test User': newUser.isTest
      })

      setTimeout(() => {
        ReactGA.set({ userId: newUser.id })
        ReactGA.set({ dimension1: newUser.isTest || newUser.admin })
      }, 0)

      if (config.zenDeskId && global.zE) {
        global.zE('webWidget', 'identify', {
          name: newUser.name,
          email: newUser.email,
          jobTitle: newUser.title
        })
      }
    }
  }
}

const UpdateCustomer = ({
  searchParams,
  customerLoading,
  customerError,
  newCustomer,

  setLoading,
  setError,
  setCustomer,
  setFacilities
}: {
  searchParams: any;
  customerLoading: boolean;
  customerError: Error;
  newCustomer: ICustomer;

  setLoading: (newVal: boolean) => void;
  setError: (newVal: Error) => void;
  setCustomer: (newVal: ICustomer) => void;
  setFacilities: (newVal: IFacility[]) => void;
}): void => {
  if (customerLoading) {
    setLoading(true)
  } else {
    if (customerError) {
      setLoading(false)
      setError(customerError)
      setCustomer(null)
      setFacilities(null)

      toast.error("Couldn't load Customer.")
    } else if (newCustomer) {
      setLoading(false)
      setError(null)
      setCustomer(newCustomer)

      if (searchParams?.fid) {
        const newFacilities = [
          newCustomer.facility.find((f) => f.id == searchParams.fid)
        ]
        setFacilities(newFacilities)
      } else {
        setFacilities([newCustomer.facility[0]])
      }

      heap.addUserProperties?.({
        'Customer Name': newCustomer.name,
        'Customer Segment': newCustomer.segment
      })

      // if (config.zenDeskId && global.zE) {
      //   global.zE('webWidget', 'identify', {
      //     company: newCustomer.name,
      //     'Customer Segment': newCustomer.segment
      //   })
      // }
    }
  }
}

export {
  SignInWithToken,
  SignInWithAccessToken,
  UpdateRefreshToken,
  CreateAccessToken,
  UpdateAccessToken,
  DecodeRefreshToken,
  DecodeAccessToken,
  UpdateUser,
  UpdateCustomer
}

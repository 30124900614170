import EColor from 'ui-lib/enums/color'
import IFilter from 'arrayview/interfaces/IFilter'
import ICrop from 'graphql-lib/interfaces/ICrop'

export const All: IFilter<ICrop> = Object.freeze({
  id: 'crop:filter:all',
  icon: undefined,
  name: 'All',
  color: EColor.None,
  func: (_: IFilter<ICrop>, crop: ICrop): boolean => true
});

export const Active: IFilter<ICrop> = Object.freeze({
  id: 'crop:filter:active',
  icon: undefined,
  name: 'Active',
  color: EColor.None,
  func: (_: IFilter<ICrop>, crop: ICrop): boolean => crop.enabled
});

export const Inactive: IFilter<ICrop> = Object.freeze({
  id: 'crop:filter:inactive',
  icon: undefined,
  name: 'Inactive',
  color: EColor.None,
  func: (_: IFilter<ICrop>, crop: ICrop): boolean => !crop.enabled
});

export const Archived: IFilter<ICrop> = Object.freeze({
  id: 'crop:filter:archived',
  icon: undefined,
  name: 'Archived',
  color: EColor.None,
  func: (_: IFilter<ICrop>, crop: ICrop): boolean => crop.archived
});

export const HasInventory: IFilter<ICrop> = Object.freeze({
  id: 'crop:filter:hasInventory',
  icon: undefined,
  name: 'Has Inventory',
  color: EColor.None,
  func: (_: IFilter<ICrop>, crop: ICrop): boolean => crop.inventoryCount > 0
});

// Types
import EEntity from 'graphql-lib/enums/entity';
import IEntity from 'graphql-lib/interfaces/entity';
import ICrop from 'graphql-lib/interfaces/ICrop';
import IInventory from 'graphql-lib/interfaces/IInventory';
import IInventoryStitch from 'graphql-lib/interfaces/IInventoryStitch';
import ID from 'graphql-lib/interfaces/ID';

// Config
import config from 'config';

// Utils
import Has from 'utils/has';
import { EStorage } from 'utils/storage';
import { CompareCrops } from '../../utils/Routes';

// Libs
import { v4 } from 'uuid';

// React
import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState
} from 'react';

// React Libs
import AutoSizer from 'react-virtualized-auto-sizer';
import useStorage from 'react-lib/use-storage';
import { toast } from 'react-toastify';
import { useIter } from 'react-lib/use-iter';

// Contexts, Actions, & Navigation
import NavigationContext from 'context/navigation';
import {
  MapRoute,
  FacilityRoute,
  CropsRoute,
  TasksRoute
} from 'utils/routes';
import { SwitchCustomer } from 'utils/actions';

// Effects
import {
  Search2URLParams,
  URLParams2Search,
  URLParams2SelectedEntities,
  URLParams2CropIDs
} from './effects';

// UI Lib
import Navigation from 'ui-lib/components/navigation';
import ChartsCanvas from 'ui-lib/components/charts-canvas';
import ErrorBoundary from 'ui-lib/components/error-boundary';
import CropsSearch from './crops-search';
import CropRollupCard from '../crop-rollup-card';
import CropsCanvas from './crops-canvas';

const Routes = [CompareCrops];

interface ICropsComparisonProps {
  match: {
    params: any;
    isExact: boolean;
    path: string;
    url: string;
  };
}

const CropsComparison = ({
  match: {
    params: { id }
  }
}: ICropsComparisonProps): JSX.Element => {
  // Contexts //////////////////////////////////////////////////////////////////

  const {
    setPrimaryAction,
    setSecondaryActions,

    setRoutes
  } = useContext(NavigationContext)

  // Refs & State //////////////////////////////////////////////////////////////

  const [facilities] = useStorage('facilities', EStorage.EphemeralStorage)
  const supportsMapView = Has(facilities[0].geojson) &&
    Has(facilities[0].dcs) &&
    facilities[0].dcs.environments?.includes(config.code)

  const [urlParams2Search, setURLParams2Search] = useState<any>()
  const [urlParams, setURLParams] = useState<any>()

  const [cropIDs, setCropIDs] = useState<ID[]>()
  const [selectedEntities, setSelectedEntities] = useState<IEntity[]>()

  const [crops, setCrops] = useState<ICrop[]>([])
  const [inventories, setInventories] = useState<IInventory[]>([])

  const cropsCache = useRef<Record<ID, ICrop>>({})
  const inventoriesCache = useRef<Record<ID, IInventory[]>>({})
  const inventoryStitchesCache = useRef<Record<ID, IInventoryStitch[]>>({})
  const inventoryChartsCache = useRef<Record<ID, any>>({})

  const [urlParamsUUID, setURLParamsUUID] = useState<string>()
  const [cropIDs_UUID, setCropIDs_UUID] = useState<string>()
  const [selectedEntitiesUUID, setSelectedEntitiesUUID] = useState<string>()
  const [inventoriesUUID, setInventoriesUUID] = useState<string>()

  // Effects ///////////////////////////////////////////////////////////////////

  useEffect((): void => {
    const newRoutes = [
      FacilityRoute,
      CropsRoute,
      TasksRoute
    ]

    if (supportsMapView) {
      newRoutes.unshift(MapRoute)
    }

    setPrimaryAction(SwitchCustomer)
    setSecondaryActions([])

    setRoutes(newRoutes)
  }, [])

  // Update URL params based on `location.search`.
  useIter(
    Search2URLParams,

    { search: location.search,
      setURLParams2Search,
      setURLParams,
      setURLParamsUUID },

    [location.search]
  )

  // Update `location.search` based on URL params.
  useIter(
    URLParams2Search,

    { pathname: location.pathname,
      urlParams2Search,
      urlParams },

    [urlParams]
  )

  // Update selected entities based on URL params.
  useIter(
    URLParams2SelectedEntities,

    { urlParams,
      selectedEntities,

      setSelectedEntities,
      setSelectedEntitiesUUID },

    [urlParams, urlParams?.selectedEntities]
  )

  // Update crop IDs based on URL params.
  useIter(
    URLParams2CropIDs,

    { urlParams,
      cropIDs,

      setCropIDs,
      setCropIDs_UUID },

    [urlParams, urlParams?.cropIDs]
  )

  // Callbacks /////////////////////////////////////////////////////////////////

  const _setURLParams = useCallback(
    (newVal: any): void => {
      setURLParams2Search(true)
      setURLParams(newVal)
    },
    [urlParamsUUID]
  )

  const _setSelectedEntities = useCallback(
    (newVal: IEntity[]): void => {
      const numberOfSelectedCharts = newVal
        .filter(entity => entity.type == EEntity.Chart)
        .length

      if (numberOfSelectedCharts > 4) {
        toast.error('More than 4 metrics may not be selected.')
        return
      }

      const newValJSON = newVal.length > 0
        ? JSON.stringify(newVal)
        : undefined

      const newURLParams = Object.assign(
        {},
        urlParams,
        { selectedEntities: newValJSON }
      )

      _setURLParams(newURLParams)
    },

    [ urlParamsUUID,
      _setURLParams ]
  )

  const _setCropIDs = useCallback(
    (newVal: ID[]): void => {
      const newValJSON = newVal.length > 0
        ? JSON.stringify(newVal)
        : undefined

      const newURLParams = Object.assign(
        {},
        urlParams,
        { cropIDs: newValJSON }
      )

      _setURLParams(newURLParams)
    },

    [ urlParamsUUID,
      _setURLParams ]
  )

  const navigation$toggleOpen = useCallback(
    (): void => {
      const newURLParams = Object.assign(
        {},
        urlParams,
        { navigationOpen: urlParams?.navigationOpen
          ? undefined
          : true }
      )

      _setURLParams(newURLParams)

      setTimeout((): void => {
        window.dispatchEvent(new Event('resize'))
      }, 600)
    },

    [ urlParamsUUID,
      _setURLParams ]
  )

  const cropsSearch$select = useCallback(
    (cropID: ID): void => {
      const alreadySelected = !!cropIDs.find((id): boolean => id == cropID)
      if (alreadySelected) {
        return
      }

      const newCropIDs = [
        ...cropIDs,
        cropID
      ]

      _setCropIDs(newCropIDs)
    },

    [ cropIDs_UUID,
      _setCropIDs ]
  )

  const cropRollupCard$close = useCallback(
    (cropID: ID): void => {
      const newSelectedEntities = selectedEntities.filter((e): boolean =>
        !(e.metadata?.cropID == cropID))

      const newCropIDs = cropIDs.filter((id): boolean => id != cropID)
      const newSelectedEntitiesJSON = newSelectedEntities.length > 0
        ? JSON.stringify(newSelectedEntities)
        : undefined

      const newCropIDs_JSON = newCropIDs.length > 0
        ? JSON.stringify(newCropIDs)
        : undefined

      const newURLParams = Object.assign(
        {},
        urlParams,
        { selectedEntities: newSelectedEntitiesJSON,
          cropIDs: newCropIDs_JSON }
      )


      const newCrops = crops.filter((c): boolean => c.id != cropID)
      const newInventories = inventories.filter((i): boolean => i.cropId != cropID)


      const newCropsCache = Object.assign(
        {},
        cropsCache.current
      )

      const newInventoriesCache = Object.assign(
        {},
        inventoriesCache.current
      )

      const newInventoryStitchesCache = Object.assign(
        {},
        inventoryStitchesCache.current
      )

      const newInventoryChartsCache = Object.assign(
        {},
        inventoryChartsCache.current
      )

      delete newCropsCache[cropID]
      delete newInventoriesCache[cropID]
      inventories
        .filter((i): boolean => i.cropId == cropID)
        .forEach((i): void => {
          delete newInventoryStitchesCache[id]
          delete newInventoryChartsCache[id]
        })


      const newInventoriesUUID = v4()


      _setURLParams(newURLParams)
      setCrops(newCrops)
      setInventories(newInventories)
      cropsCache.current = newCropsCache
      inventoriesCache.current = newInventoriesCache
      inventoryStitchesCache.current = newInventoryStitchesCache
      inventoryChartsCache.current = newInventoryChartsCache
      setInventoriesUUID(newInventoriesUUID)
    },

    [ urlParamsUUID,
      selectedEntitiesUUID,
      cropIDs_UUID,
      inventoriesUUID,
      _setURLParams ]
  )

  const cropRollupCard$loadedInventories = useCallback(
    (
      crop: ICrop,
      _inventories: IInventory[],
      _inventoryStitchesCache: Record<ID, IInventoryStitch[]>,
      _inventoryChartsCache: Record<ID, any>
    ): void => {
      if (!inventoriesCache.current?.[crop.id]) {
        const newCrops = [...crops, crop]
        const newInventories = [...inventories, ..._inventories]


        const newCropsCache = Object.assign(
          {},
          cropsCache.current,
          { [crop.id]: crop }
        )

        const newInventoriesCache = Object.assign(
          {},
          inventoriesCache.current,
          { [crop.id]: inventories }
        )

        const newInventoryStitchesCache = Object.assign(
          {},
          inventoryStitchesCache.current,
          _inventoryStitchesCache
        )

        const newInventoryChartsCache = Object.assign(
          {},
          inventoryChartsCache.current,
          _inventoryChartsCache
        )


        const newInventoriesUUID = v4()


        setCrops(newCrops)
        setInventories(newInventories)
        cropsCache.current = newCropsCache
        inventoriesCache.current = newInventoriesCache
        inventoryStitchesCache.current = newInventoryStitchesCache
        inventoryChartsCache.current = newInventoryChartsCache
        setInventoriesUUID(newInventoriesUUID)
      }
    },
    [inventoriesUUID]
  )

  const cropRollupCard$onSelectInventory = useCallback(
    (cropID: ID, inventoryID: ID): void => {

      const isAlreadySelected = selectedEntities?.find((e): boolean =>
        e.type == EEntity.Inventory && e.id == inventoryID)

      let newSelectedEntities: IEntity[]

      if (isAlreadySelected) {
        newSelectedEntities = selectedEntities
          .filter((e): boolean => !(e.type == EEntity.Inventory && e.id == inventoryID))
          .filter((e): boolean => !(
            e.type == EEntity.Chart &&
            e.metadata?.inventoryID == inventoryID
          ))
      } else {
        newSelectedEntities = [
          ...(selectedEntities as IEntity[]),
          {
            type: EEntity.Inventory,
            id: inventoryID,
            metadata: { cropID }
          }
        ]
      }

      _setSelectedEntities(newSelectedEntities)
    },

    [ selectedEntitiesUUID,
      _setSelectedEntities ]
  )

  const cropRollupCard$onSelectInventoryChart = useCallback(
    (cropID: ID, inventoryID: ID, chartKey: string): void => {
      const isAlreadySelected = selectedEntities?.find((e): boolean =>
        e.type == EEntity.Chart &&
        e.metadata?.inventoryID == inventoryID &&
        e.metadata?.chartKey == chartKey)

      let newSelectedEntities: IEntity[]

      if (isAlreadySelected) {
        newSelectedEntities = selectedEntities.filter((e): boolean =>
          !(
            e.type == EEntity.Chart &&
            e.metadata?.inventoryID == inventoryID &&
            e.metadata?.chartKey == chartKey
          ))
      } else {
        newSelectedEntities = [
          ...(selectedEntities as IEntity[]),
          {
            type: EEntity.Chart,
            id: `${inventoryID}:${chartKey}`,
            metadata: {
              cropID,
              inventoryID,
              chartKey
            }
          }
        ]
      }

      _setSelectedEntities(newSelectedEntities)
    },

    [ selectedEntitiesUUID,
      _setSelectedEntities ]
  )

  // Render ////////////////////////////////////////////////////////////////////

  let chartsCanvasContainerClassName = 'ChartsCanvasContainer Animated'
  if (urlParams?.navigationOpen) chartsCanvasContainerClassName += ' NavigationOpen'

  const head = (
    <CropsSearch
      animated
      onSelect={cropsSearch$select}
    />
  )

  const body = (
    <div className='CropsComparisonList'>
      {cropIDs?.map((id): JSX.Element => (
        <CropRollupCard
          key={id}

          outlined
          animated

          id={id}
          readOnly
          selectedEntities={selectedEntities}

          onClose={cropRollupCard$close}
          onLoadedInventories={cropRollupCard$loadedInventories}
          onSelectInventory={cropRollupCard$onSelectInventory}
          onSelectInventoryChart={cropRollupCard$onSelectInventoryChart}
        />
      ))}
    </div>
  )

  return (
    <main className='Theme__Light CropsComparison'>
      <AutoSizer className='AutoSizer'>
        {({ width, height }: { width?: number, height?: number}): JSX.Element => (
          <CropsCanvas
            width={width}
            height={height}

            selectedEntities={selectedEntities}
            crops={crops}
            inventories={inventories}
            inventoryStitchesCache={inventoryStitchesCache.current}

            selectedEntitiesUUID={selectedEntitiesUUID}
            inventoriesUUID={inventoriesUUID}
          />
        )}
      </AutoSizer>

      <Navigation
        resting
        animated

        open={urlParams?.navigationOpen}
        toggleOpen={navigation$toggleOpen}

        head={head}
        body={body}
      />

      <div className={chartsCanvasContainerClassName}>
        <ErrorBoundary>
          <ChartsCanvas
            relativeCharting
            selectedEntities={selectedEntities}
            cropIDs={cropIDs}

            crops={crops}
            inventories={inventories}

            cropsCache={cropsCache.current}
            inventoriesCache={inventoriesCache.current}
            inventoryChartsCache={inventoryChartsCache.current}

            selectedEntitiesUUID={selectedEntitiesUUID}
            inventoriesUUID={inventoriesUUID}
          />
        </ErrorBoundary>
      </div>
    </main>
  )
}

export { Routes as CropsComparisonRoutes }
export default CropsComparison

// Types
import ICrop from 'graphql-lib/interfaces/ICrop'

// Font Awesome
import { IconDefinition } from '@fortawesome/fontawesome-svg-core'
import {
  faSeedling,
  faCannabis
} from '@fortawesome/pro-solid-svg-icons'
import ISetStateType from 'graphql-lib/interfaces/ISetStateType'

const SpeciesIconMap: Record<string, IconDefinition> = {
  seedling: faSeedling,
  cannabis: faCannabis
}

const SetRatingColor = ({
  crop,
  setRatingColor
}: {
  crop: ICrop
  setRatingColor: ISetStateType<string>
}): void => {
  if (crop) {
    let newColor: string

    if (crop.rating > 0) {
      newColor = 'gold'
    } else {
      newColor = 'gray'
    }

    setRatingColor(newColor)
  }
}

const SetSpeciesIcon = ({
  crop,
  setSpeciesIcon
}: {
  crop: ICrop
  setSpeciesIcon: ISetStateType<IconDefinition>
}): void => {
  if (crop) {
    let newIcon: IconDefinition

    if (crop.speciesIcon && SpeciesIconMap[crop.speciesIcon]) {
      newIcon = SpeciesIconMap[crop.speciesIcon]
    } else {
      newIcon = faSeedling
    }

    setSpeciesIcon(newIcon)
  }
}

const SetVarietyColor = ({
  crop,
  setVarietyColor
}: {
  crop: ICrop
  setVarietyColor: ISetStateType<string>
}): void => {
  if (crop) {
    let newColor: string

    if (crop.varietyColor) {
      newColor = crop.varietyColor
    } else {
      newColor = '#ffffff'
    }

    setVarietyColor(newColor)
  }
}

export {
  SetRatingColor,
  SetSpeciesIcon,
  SetVarietyColor
}

/**
 * @author Miras Absar <mabsar@iunu.com>
 */

// Types
import ICrop from 'graphql-lib/interfaces/ICrop'

const SetCrops = ({
  cropsData,
  setCrops
}: {
  cropsData: any;
  setCrops: (newVal: ICrop[]) => void;
}): void => {
  if (cropsData) {
    const newCrops = cropsData.search[0].crop
    setCrops(newCrops)
  }
}

export { SetCrops }

// Types
import ITask from 'graphql-lib/interfaces/ITask'
import IFlag from 'graphql-lib/interfaces/IFlag'
import EEntity from 'map-view/enums/entity'

// Libs
import { format } from 'date-fns'
import { cloneDeep } from 'lodash'

// React
import React, {
  MouseEvent,
  useContext,
  useEffect,
  useState,
  CSSProperties
} from 'react'

// FontAwesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faCheckCircle,
  faExclamationCircle
} from '@fortawesome/pro-solid-svg-icons'

// Contexts
import Mobius from 'map-view/contexts/mobius'

// UI Lib
import Flag from 'ui-lib/components/flag'
import { createDate } from 'utils/dates'

// GraphQL
import { useMutation } from '@apollo/client'
import { UpdateTask } from './queries'
import { EStorage, GetItem } from 'utils/storage'

import ID from 'graphql-lib/interfaces/ID';

export interface ITaskProps {
  outlined?: boolean;
  resting?: boolean;
  raised?: boolean;
  animated?: boolean;
  selected?: boolean;
  task: ITask;
  style?: CSSProperties;
}

const Task = ({
  outlined,
  resting,
  raised,
  animated,
  task,
  style,
}: ITaskProps): JSX.Element => {
  // Get info on the current logged in user. 
  const user = GetItem('user', EStorage.EphemeralStorage)

  // Contexts //////////////////////////////////////////////////////////////////

  const {
    selectedEntities: {
      selectedEntities,
      selectEntity,
      selectedEntitiesUUID
    },
    tasks: {
      setTasks,
      tasksUUID
    }
  } = useContext(Mobius)

  // GraphQL Mutations /////////////////////////////////////////////////////////
  const [
    updateTask,
    {
      error: updateTaskError,
      data: updateTaskData
    }
  ] = useMutation(UpdateTask)

  // State /////////////////////////////////////////////////////////////////////

  const [flag, setFlag] = useState<IFlag>()
  const [assignedTo, setAssignedTo] = useState<string>()
  const [dueBy, setDueBy] = useState<string>()
  const [complete, setComplete] = useState<boolean>()
  const [urgent, setUrgent] = useState<boolean>()
  const [selected, setSelected] = useState<boolean>()

  // Callbacks /////////////////////////////////////////////////////////////////

  const $click = (ev: MouseEvent<HTMLElement>): void => {
    selectEntity({
      type: EEntity.Task,
      id: task.id
    })
  }

  // Effects ///////////////////////////////////////////////////////////////////

  useEffect((): void => {
    if (task) {
      const newFlag = task.flag[0]
      setFlag(newFlag)
    }
  }, [task])

  useEffect((): void => {
    if (task && task.taskAssignee.length > 0 && task.taskAssignee[0].customerUser) {
      let newAssignedTo: string

      const assignees = task.taskAssignee
        .map(a => a.customerUser[0])
        .filter(a => !!a)

      if (assignees.length > 0) {
        const assignee = assignees[0]
        newAssignedTo =
          assignee.name ||
          assignee.username ||
          assignee.email

        if (assignees.length > 1) {
          newAssignedTo += '...'
        }
      } else {
        newAssignedTo = 'No Assignees'
      }

      setAssignedTo(newAssignedTo)
    }
  }, [task])

  useEffect((): void => {
    if (task) {
      let newDueBy: string

      if (task.dueDate) {
        newDueBy = format(createDate(task.dueDate), 'P p')
      } else {
        newDueBy = 'No Due Date'
      }

      setDueBy(newDueBy)
    }
  }, [task])

  useEffect((): void => {
    if (task) {
      const newComplete = task.complete
      const newUrgent = task.priority > 0

      setComplete(newComplete)
      setUrgent(newUrgent)
    }
  }, [task, tasksUUID])

  useEffect(
    (): void => {
      const newSelected = !!selectedEntities
        .find(entity =>
          entity.type === EEntity.Task &&
          entity.id == task?.id)

      setSelected(newSelected)
    },

    [ selectedEntitiesUUID,
      task?.id ]
  )

  // Keep track of when a task is clicked on and update the field for the seen/not seen notification
  useEffect((): void => {
    // Only update reviewed field if the logged in user is a customer
    if (user.admin) return

    if (selected) {
      // Make call to update issueReviewedOn date when user selects a new issue
      const currentDateTime = new Date().toISOString()
      updateTask({
        variables: {
          id: task.id
        },
      })

      // Update our react context's tasks object locally on the frontend
      setTasks((prevState) => {
        const currentTasks = cloneDeep(prevState)
        return currentTasks
      })
    }
  }, [task, selected])
  
  // Render ////////////////////////////////////////////////////////////////////

  outlined = true

  let className = 'TaskItem'
  if (outlined) className += ' Outlined'
  if (resting) className += ' Resting'
  if (raised) className += ' Raised'
  if (animated) className += ' Animated'
  if (selected) className += ' Selected'

  return (
    <>
      <section
        style={style}
        className={className}
        onClick={$click}
      >
      <div className='FlagContainer'>
        {flag && (
          <Flag flag={flag} status={status} />
        )}

        {!flag && (
          <div className='FlagPlaceholder' />
        )}
      </div>

      <div className='Body'>
        <div className='Head'>
          {
            urgent && (<FontAwesomeIcon
                  className='Red'
                  icon={faExclamationCircle}
                />) 
          } 
          {/* Show issue types for issues. Otherwise show flag names */}
          <h6 className='Primary'>{task.name}</h6>
        </div>

        <div className='Subhead'>
          <p>{assignedTo}</p>
        </div>

        <div className='Subhead'>
          <p>{dueBy}</p>
        </div>
      </div>

      <div className='Icons'>
        {complete && (
          <FontAwesomeIcon
            className='Green'
            icon={faCheckCircle}
          />
        )}

      </div>
      </section>
    </>
  )
}

export default Task

import { createContext } from 'react'
import IEntity from '../interfaces/entity'
import IFacility from 'graphql-lib/interfaces/IFacility'
import IBuilding from 'graphql-lib/interfaces/IBuilding'
import ISpace from 'graphql-lib/interfaces/ISpace'
import { ISensorRecord } from 'graphql-lib/interfaces/ISensorDevice'
import IAreacam from 'graphql-lib/interfaces/IAreacam'
import IGridPrediction from 'graphql-lib/interfaces/IGridPrediction'
import IInventory from 'graphql-lib/interfaces/IInventory'
import IInventoryStitch from 'graphql-lib/interfaces/IInventoryStitch'
import IContainerStitch from 'graphql-lib/interfaces/IContainerStitch'
import ITask from 'graphql-lib/interfaces/ITask'
import IContainer from 'map-view/interfaces/container'
import ITaskIssue from 'graphql-lib/interfaces/ITaskIssue'
import IChart from 'map-view/interfaces/chart'
import IYieldPredictionAverage from 'graphql-lib/interfaces/IYieldPredictionAverage'
import IDiff from 'map-view/interfaces/diff'
import EOverlay from 'map-view/enums/overlay'
import IGreenAutomationWaterSystemRecord from 'graphql-lib/interfaces/IGreenAutomationWaterSystemRecord'
import ITime from 'map-view/interfaces/time'
import IHeatmap from 'graphql-lib/interfaces/IHeatmap'
import ISetStateType from 'graphql-lib/interfaces/ISetStateType'

import ID from 'graphql-lib/interfaces/ID';
import { QueryResult } from '@apollo/client'
export type ITaskOrTaskIssueModel = ITask | ITaskIssue
export type IFunctionTask = (tasks: Array<ITaskOrTaskIssueModel>) => Array<ITaskOrTaskIssueModel>

interface IMobiusContext {
  searchParams: {
    searchParams: {
      navigationTab?: string
      sensorChartsCarouselOpen?: boolean
      timeTravelOpen?: boolean
      overlaysOpen?: boolean
      navigationOpen?: boolean
      viewAreacam?: string
      inventoryId?: number
      containerId?: number
      positionX?: number
      positionY?: number
      creatingTask?: boolean
      creatingTaskIssue?: boolean
      selectInventoryId?: string
      selectedEntities?: string
      viewAreacamTiles?: number
    }
    setSearchParams: (newParams: Record<string, any>) => void,
    setSearchParam: (name: string, value: any) => void,
    searchParamsUUID: string
  }
  selectedEntities: {
    selectedEntities: Array<IEntity>
    selectedEntitiesDiff: Array<IDiff<IEntity>>
    setSelectedEntities: (newVal: Array<IEntity>) => void
    selectedEntitiesUUID: string
    selectEntity: (value: IEntity, multiselect?: boolean) => void
  }
  spaceCharts: {
    spaceChartsLoading: boolean
    spaceChartsError: Error
    spaceCharts: Array<IChart>
    setSpaceCharts: (space: Array<IChart>) => void
    spaceChartsUUID: string
  }
  inventoryCharts: {
    inventoryChartsLoading: boolean
    inventoryCharts: Array<IChart>
    inventoryChartsUUID: string
  }
  containerCharts: {
    containerChartsLoading: boolean
    containerChartsError: Error
    containerCharts: Array<IChart>
    containerChartsUUID: string
  }
  time: {
    time: ITime,
    setTime: (newTime: Date) => void
  }
  visibleEntities: {
    visibleEntities: Array<IEntity>
    visibleEntitiesDiff: Array<IDiff<IEntity>>
    visibleEntitiesUUID: string
    setVisibleEntities: ISetStateType<Array<IEntity>>
  }
  overlays: {
    overlays: Array<EOverlay>
    overlaysDiff: Array<IDiff<EOverlay>>
    setOverlays: (val: Array<EOverlay>) => void
    selectOverlay: (val: EOverlay) => void
    overlaysUUID: string
  }
  zoom: {
    zoom: number
    setZoom: (val: number) => void
    zoomUUID: string
  }
  facility: {
    facilityID: ID
    facilityLoading: boolean
    facilityError: Error
    facility: IFacility
    facilityUUID: string
  }
  buildings: {
    buildingIDs: Array<ID>
    buildingsLoading: boolean
    buildingsError: Error
    buildings: Array<IBuilding>
    buildingsUUID: string
  }
  spaces: {
    spaceIDs: Array<ID>
    spacesLoading: boolean
    spacesError: Error
    spaces: Array<ISpace>
    spacesUUID: string
  }
  sensors: {
    sensorIDs: Array<ID>
    sensorsLoading: boolean
    sensorsError: Error
    sensors: Array<ISensorRecord>
    sensorsTime: Date
    sensorsUUID: string
  }
  waterSystems: {
    waterSystemsLoading: boolean
    waterSystemsError: Error
    waterSystems: Array<IGreenAutomationWaterSystemRecord>
    waterSystemsUUID: string
  },
  areacams: {
    areacamIDs: Array<ID>
    areacamsLoading: boolean
    areacamsError: Error
    areacams: Array<IAreacam>
    areacamsTime: Date
    areacamsUUID: string
  },
  gridPredictions: {
    gridPredictionsLoading: boolean
    gridPredictionsError: Error
    gridPredictions: Array<IGridPrediction>
    gridPredictionsUUID: string
    gridPredictionsTime: Date
  }
  inventories: {
    inventoryIDs: Array<ID>
    inventoryWOCIDs: Array<ID>
    inventoryWCIDs: Array<ID>
    inventoriesLoading: boolean
    inventoriesError: Error
    inventories: Array<IInventory>
    inventoriesWOC: Array<IInventory>
    inventoriesWC: Array<IInventory>
    inventoriesUUID: string
    inventoriesData: QueryResult<Array<IInventory>>
  }
  containers: {
    containerIDs: Array<ID>
    containersLoading: boolean
    containersError: Error
    containers: Array<IContainer>
    containersUUID: string
  }
  inventoryStitches: {
    inventoryStitchesLoading: boolean
    inventoryStitchesError: Error
    inventoryStitches: Array<IInventoryStitch>
    inventoryStitchesTime: Date
    inventoryStitchesUUID: string
  }
  containerStitches: {
    containerStitchesLoading: boolean
    containerStitchesError: Error
    containerStitches: Array<IContainerStitch>
    containerStitchesUUID: string
    containerStitchesTime: Date
  }
  heatmaps: {
    heatmapsLoading: boolean
    heatmapsError: Error
    heatmaps: Array<IHeatmap>
    heatmapsUUID: string
    heatmapsTime: Date
  }
  yieldPredictionAverages: {
    yieldPredictionAveragesLoading: boolean
    yieldPredictionAveragesError: Error
    yieldPredictionAverages: Array<IYieldPredictionAverage>
    yieldPredictionAveragesUUID: string
    yieldPredictionAveragesTime: Date
  }
  tasks: {
    tasksLoading: boolean;
    tasksError: Error;
    tasks: Array<ITask>;
    tasksTime: any;
    tasksUUID: string | null;
    setTasks: ISetStateType<IFunctionTask>
  }
  taskIssues: {
    taskIssuesLoading: boolean;
    taskIssuesError: Error;
    taskIssues: Array<ITaskIssue>;
    taskIssuesTime: any;
    taskIssuesUUID: string | null;
    setTaskIssues: ISetStateType<IFunctionTask>
  };
}

const MobiusContext = createContext<IMobiusContext>(
  {
    searchParams: {
      searchParams: {
        navigationTab: null
      },
      setSearchParams: () => null,
      setSearchParam: () => null,
      searchParamsUUID: null
    },
    selectedEntities: {
      selectedEntities: [],
      selectedEntitiesDiff: null,
      setSelectedEntities: () => null,
      selectedEntitiesUUID: null,
      selectEntity: () => null
    },
    spaceCharts: {
      spaceChartsError: null,
      spaceChartsLoading: true,
      spaceCharts: null,
      setSpaceCharts: (space) => null,
      spaceChartsUUID: null
    },
    inventoryCharts: {
      inventoryChartsLoading: true,
      inventoryCharts: [],
      inventoryChartsUUID: null
    },
    containerCharts: {
      containerChartsLoading: true,
      containerCharts: [],
      containerChartsUUID: null,
      containerChartsError: null
    },
    time: {
      time: {
        now: null,
        dayLookforward: null,
        dayLookback: null,
        tenDayLookback: null,
        weekLookback: null,
        twoWeekLookback: null
      },
      setTime: () => null
    },
    visibleEntities: {
      visibleEntities: null,
      visibleEntitiesUUID: null,
      visibleEntitiesDiff: null,
      setVisibleEntities: () => null
    },
    overlays: {
      overlays: [],
      overlaysDiff: [],
      setOverlays: () => null,
      selectOverlay: () => null,
      overlaysUUID: null
    },
    zoom: {
      zoom: null,
      setZoom: (val: number) => null,
      zoomUUID: null
    },
    facility: {
      facilityID: null,
      facilityError: null,
      facility: null,
      facilityLoading: true,
      facilityUUID: null
    },
    buildings: {
      buildingIDs: null,
      buildingsLoading: true,
      buildings: [],
      buildingsUUID: null,
      buildingsError: null
    },
    spaces: {
      spaceIDs: null,
      spacesError: null,
      spacesLoading: true,
      spaces: [],
      spacesUUID: null
    },
    sensors: {
      sensorIDs: null,
      sensorsError: null,
      sensorsTime: null,
      sensorsLoading: true,
      sensors: [],
      sensorsUUID: null
    },
    areacams: {
      areacamIDs: null,
      areacamsError: null,
      areacamsLoading: true,
      areacams: [],
      areacamsTime: null,
      areacamsUUID: null
    },
    waterSystems: {
      waterSystemsLoading: true,
      waterSystemsError: null,
      waterSystems: [],
      waterSystemsUUID: null
    },
    gridPredictions: {
      gridPredictionsLoading: true,
      gridPredictionsError: null,
      gridPredictions: [],
      gridPredictionsUUID: null,
      gridPredictionsTime: null
    },
    inventories: {
      inventoryIDs: null,
      inventoryWOCIDs: null,
      inventoryWCIDs: null,
      inventoriesLoading: true,
      inventoriesError: null,
      inventories: [],
      inventoriesWOC: [],
      inventoriesWC: [],
      inventoriesUUID: null,
      inventoriesData: null
    },
    containers: {
      containerIDs: [],
      containersLoading: true,
      containersError: null,
      containers: [],
      containersUUID: null
    },
    inventoryStitches: {
      inventoryStitchesLoading: true,
      inventoryStitchesError: null,
      inventoryStitches: [],
      inventoryStitchesTime: null,
      inventoryStitchesUUID: null
    },
    containerStitches: {
      containerStitchesLoading: true,
      containerStitches: [],
      containerStitchesUUID: null,
      containerStitchesError: null,
      containerStitchesTime: null,
    },
    heatmaps: {
      heatmapsLoading: true,
      heatmaps: [],
      heatmapsUUID: null,
      heatmapsError: null,
      heatmapsTime: null
    },
    yieldPredictionAverages: {
      yieldPredictionAveragesLoading: true,
      yieldPredictionAverages: [],
      yieldPredictionAveragesUUID: null,
      yieldPredictionAveragesError: null,
      yieldPredictionAveragesTime: null
    },
    tasks: {
      tasksLoading: true,
      tasksError: null,
      tasks: null,
      tasksTime: null,
      tasksUUID: null,
      setTasks: () => null
    },
    taskIssues: {
      taskIssuesLoading: true,
      taskIssuesError: null,
      taskIssues: null,
      taskIssuesTime: null,
      taskIssuesUUID: null,
      setTaskIssues: () => null
    }
  }
);

export default MobiusContext;

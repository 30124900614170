/**
 * @author Miras Absar <mabsar@iunu.com>
 * @file ...
 */

// Types
import EOverlay from '../../../enums/overlay'

// React
import React, {
  useCallback,
  useContext
} from 'react'

// Font Awesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faLayerGroup,
  faTimes,

  faCctv,
  faAnalytics,
  faTag,
  faBraille,
  faFlag
} from '@fortawesome/pro-regular-svg-icons'

// MapView
import Mobius from '../../../contexts/mobius'
import Overlay from './overlay'

interface IOverlaysProps {
  outlined?: boolean;
  resting?: boolean;
  raised?: boolean;
  animated?: boolean;
}

const _Overlays = [
  { type: EOverlay.Areacam,
    icon: faCctv,
    name: 'Areacams' },

  { type: EOverlay.CO2,
    icon: faAnalytics,
    name: 'CO2' },
  
  { type: EOverlay.DLI,
    icon: faAnalytics,
    name: 'DLI' },

  { type: EOverlay.Humidity,
    icon: faAnalytics,
    name: 'Humidity' },

  { type: EOverlay.MMol,
    icon: faAnalytics,
    name: 'MMol' },

  { type: EOverlay.Temperature,
    icon: faAnalytics,
    name: 'Temperature' },

  { type: EOverlay.VPD,
    icon: faAnalytics,
    name: 'VPD' },

  { type: EOverlay.Tag,
    icon: faTag,
    name: 'Tags' },

  { type: EOverlay.Heatmap,
    icon: faBraille,
    name: 'Canopy Gaps' },

  { type: EOverlay.Task,
    icon: faFlag,
    name: 'Flags' },

  { type: EOverlay.TaskIssue,
    icon: faFlag,
    name: 'Issues' }
]

const Overlays = ({
  outlined,
  resting,
  raised,
  animated
}: IOverlaysProps): JSX.Element => {
  // Context ///////////////////////////////////////////////////////////////////

  const {
    searchParams: {
      searchParams,
      setSearchParams,
      searchParamsUUID
    }
  } = useContext(Mobius)

  // Callbacks /////////////////////////////////////////////////////////////////

  const $toggleOpen = useCallback(
    (): void => {
      const newOpen = !searchParams.overlaysOpen
        ? true
        : undefined

      const newSearchParams = Object.assign(
        {},
        searchParams,
        { overlaysOpen: newOpen }
      )

      setSearchParams(newSearchParams)
    },
    [searchParamsUUID]
  )

  // Render ////////////////////////////////////////////////////////////////////

  let className = 'Overlays'
  if (outlined) className += ' Outlined'
  if (resting) className += ' Resting'
  if (raised) className += ' Raised'
  if (animated) className += ' Animated'
  if (searchParams.overlaysOpen) className += ' Open'
  else className += ' Closed'

  const toggleIcon = searchParams.overlaysOpen
    ? faTimes
    : faLayerGroup
  return (
    <div className={className}>
      <button
        className='Main'
        onClick={$toggleOpen}
      >
        <FontAwesomeIcon icon={toggleIcon} />
      </button>
      {_Overlays.map(o => (
        <Overlay
          key={o.type}

          animated={animated}

          type={o.type}
          icon={o.icon}
          name={o.name}
        />
      ))}
    </div>
  )
}

export default Overlays

import IColumn from 'arrayview/interfaces/IColumn';
import IFilter from 'arrayview/interfaces/IFilter';
import IGroup from 'arrayview/interfaces/IGroup';
import ISetStateType from 'graphql-lib/interfaces/ISetStateType';

function UpdateData<T> ({
  offset,
  limit,
  data,

  gqlLoading,
  gqlData,
  gqlKey,

  setData,
  setAtEnd
}: {
  offset: number;
  limit: number;
  data: T[];

  gqlLoading: boolean;
  gqlData: {[gqlKey: string]: Array<T>};
  gqlKey: string;

  setData: ISetStateType<Array<T>>
  setAtEnd: ISetStateType<boolean>
}): void {
  if (!gqlLoading && gqlData) {
    let newData = gqlData[gqlKey]
    const newAtEnd = newData.length === 0

    if (offset > 0) {
      newData = [...data, ...newData]
    }

    setData(newData)
    setAtEnd(newAtEnd)
  }
}

function SearchData<T> ({
  columns,
  data,
  search,
  setSearchedData
}: {
  columns: IColumn<T>[];
  data: Array<T>;
  search: string;
  setSearchedData: ISetStateType<Array<T>>;
}): void {
  if (data) {
    let newSearchedData = data

    if (search.length > 0) {
      newSearchedData = newSearchedData
        .filter((d) =>
          columns.some((c) =>
            c.searchFunc(c, search, d)))
    }

    setSearchedData(newSearchedData)
  }
}

function FilterData<T> ({
  searchedData,
  filter,

  setFilteredData
}: {
  searchedData: T[];
  filter: IFilter<T>;

  setFilteredData: (newVal: T[]) => void;
}): void {
  if (searchedData) {
    const newFilteredData: T[] = searchedData
      .filter((d): boolean =>
        filter.func(filter, d))

    setFilteredData(newFilteredData)
  }
}

function SortData<T> ({
  filteredData,
  column,
  descending,

  setSortedData
}: {
  filteredData: T[];
  column: IColumn<T>;
  descending: boolean;

  setSortedData: (newVal: T[]) => void;
}): void {
  if (filteredData) {
    const sortFunc = descending
      ? column.descendingSortFunc
      : column.ascendingSortFunc

    const newSortedData = [...filteredData]
      .sort((a, b): number =>
        sortFunc(column, a, b))

    setSortedData(newSortedData)
  }
}

function GroupData<T> ({
  sortedData,
  column,
  setGroupedData
}: {
  sortedData: Array<T>;
  column: IColumn<T>;
  setGroupedData: ISetStateType<Array<IGroup>>
}): void {
  if (sortedData) {
    const newGroupedData = sortedData
      .reduce((acc, cur, i, arr) => {
        const curGroup: IGroup = column.groupFunc(column, cur)

        const prev = arr[i - 1]
        const prevGroup: IGroup = prev
          ? column.groupFunc(column, prev)
          : undefined

        if (!prevGroup || Number(curGroup.id) !== Number(prevGroup.id)) {
          return [
            ...acc,
            Object.assign(
              { __typename: 'Group' },
              curGroup
            ),
            cur
          ]
        } else {
          return [
            ...acc,
            cur
          ]
        }
      }, [])

    setGroupedData(newGroupedData)
  }
}

export {
  UpdateData,
  SearchData,
  FilterData,
  SortData,
  GroupData
}

// Libs
import { v4 } from 'uuid'

// React
import {
  useCallback,
  useRef,
  useState
} from 'react'

// React Libs
import { useIter } from 'react-lib/use-iter'

// GraphQL
import client from 'graphql-lib/index'
import { CreateAccessToken } from './queries'

// Effects
import { Init } from './effects'
import { FetchResult } from '@apollo/client'
import ID from 'graphql-lib/interfaces/ID'

const createAccessToken = (): {
  loading: boolean;
  error: Error;
  accessToken: string;
  uuid: string;
  create: (create: any) => Promise<Partial<{accessToken: string}>>;
} => {
  // State /////////////////////////////////////////////////////////////////////

  const hookID = useRef<string>()
  const [loading, setLoading] = useState<boolean>()
  const [error, setError] = useState<Error>()
  const [accessToken, setAccessToken] = useState<string>()
  const [uuid, setUUID] = useState<string>()

  // Effects ///////////////////////////////////////////////////////////////////

  useIter(
    Init,
    { hookID },
    []
  )

  // Callbacks /////////////////////////////////////////////////////////////////

  const create = useCallback(
    async (create: { refreshToken: string, $customerId: ID }) => {
      setLoading(true)
      setUUID(v4())

      let error: Error
      let data
      try {
        const response: FetchResult<{AccessToken: { create: string }}> = await client.mutate({
          fetchPolicy: 'no-cache',
          mutation: CreateAccessToken,
          variables: create
        })

        data = response.data
      } catch (err) {
        error = err as Error
      }

      setLoading(false)
      if (error) {
        setError(error)
        setUUID(v4())

        return { error }
      } else if (data) {
        const newAccessToken = data.AccessToken.create

        setError(undefined)
        setAccessToken(newAccessToken)
        setUUID(v4())

        const ev = new CustomEvent(
          'create_access_token',
          {
            detail: {
              dispatcherID: hookID.current,
              create: newAccessToken
            }
          }
        )

        window.dispatchEvent(ev)

        return { accessToken: newAccessToken }
      }

      return {}
    },
    [uuid]
  )

  // Return ////////////////////////////////////////////////////////////////////

  return {
    loading,
    error,
    accessToken,
    uuid,

    create
  }
}

export default createAccessToken

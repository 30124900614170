// Types
import IChart from 'map-view/interfaces/chart';
import ID from 'graphql-lib/interfaces/ID';

// Utils
import { isEqual, subDays } from 'date-fns'
import { v4 } from 'uuid'
import {
  EStorage,
  GetItem
} from 'utils/storage'
import {
  StaticCameraList as StaticCameraListRoute,
  StaticCameraView as StaticCameraViewRoute
} from 'static-camera/utils/routes'

// React
import React, {
  useState
} from 'react'
import { useIter } from 'react-lib/use-iter'

// Font Awesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faExclamationCircle,
  faSpinnerThird
} from '@fortawesome/pro-regular-svg-icons'

// GraphQL
import { useQuery } from '@apollo/client'
import {
  QuerySpaces,
  QuerySensors,
  QueryAreacams,
  QuerySensorRecords,
  QueryCO2SensorRecords,
  QueryAreaImageRecords
} from './queries'

// Effects
import {
  UpdateDates,
  UpdateSpaces,
  UpdateSensorIDs,
  UpdateSensors,
  UpdateCharts,
  UpdateAreacamIDs,
  UpdateAreacams
} from './effects'

// Components
import FacilityHeader from 'grid-view/components/facility-header'
import StaticCameraItem from './static-camera-item'
import StaticCameraView from '../static-camera-view'
import IAreacam from 'graphql-lib/interfaces/IAreacam'

const Routes = [
  StaticCameraListRoute,
  StaticCameraViewRoute
]

const StaticCameraList = (): JSX.Element => {
  // State /////////////////////////////////////////////////////////////////////

  const id = GetItem('facilities', EStorage.EphemeralStorage)[0].id

  const [startDate, setStartDate] = useState<Date>()
  const [endDate, setEndDate] = useState<Date>()
  const [dateUUID, setDateUUID] = useState<string>()

  const [spaceIDs, setSpaceIDs] = useState<ID[]>()
  const [spaces, setSpaces] = useState<[]>()
  const [spacesUUID, setSpacesUUID] = useState<string>()

  const [sensorIDs, setSensorIDs] = useState<ID[]>()
  const [sensors, setSensors] = useState<[]>()
  const [sensorsEndDate, setSensorsEndDate] = useState<Date>()
  const [sensorsUUID, setSensorsUUID] = useState<string>()

  const [charts, setCharts] = useState<IChart[]>()
  const [chartsEndDate, setChartsEndDate] = useState<Date>()
  const [chartsUUID, setChartsUUID] = useState<string>()

  const [areacamIDs, setAreacamIDs] = useState<ID[]>()
  const [areacams, setAreacams] = useState<Array<IAreacam>>()
  const [areacamsEndDate, setAreacamsEndDate] = useState<Date>()
  const [areacamsUUID, setAreacamsUUID] = useState<string>()

  const [selectedAreacam, setSelectedAreacam] = useState()
  const [selectedCharts, setSelectedCharts] = useState<IChart[]>([])
  const [selectedChartsUUID, setSelectedChartsUUID] = useState<string>()

  // GraphQL ///////////////////////////////////////////////////////////////////

  // Query Spaces
  const spacesQuery = useQuery(QuerySpaces, {
    skip: !id,
    variables: { facilityId: id },
    fetchPolicy: 'no-cache'
  })

  // Query Sensors
  const sensorsQuery = useQuery(QuerySensors, {
    skip: !sensorIDs || sensorIDs.length === 0,
    variables: { sensorIds: sensorIDs },
    fetchPolicy: 'no-cache'
  })

  // Query Sensor Records  console.log(areacamsUUID)
  const sensorRecordsQuery = useQuery(QuerySensorRecords, {
    skip: !id || !startDate || !endDate,
    variables: {
      facilityIds: [id],
      startDate,
      endDate
    },
    fetchPolicy: 'no-cache'
  })

  // Query CO2 Sensor Records
  const co2SensorRecordsQuery = useQuery(QueryCO2SensorRecords, {
    skip: !id || !startDate || !endDate,
    variables: {
      facilityIds: [id],
      startDate,
      endDate
    },
    fetchPolicy: 'no-cache'
  })

  // Query Areacams
  const areacamsQuery = useQuery(QueryAreacams, {
    skip: !areacamIDs || areacamIDs.length === 0,
    variables: { areacamIds: areacamIDs },
    fetchPolicy: 'no-cache'
  })

  // Query Area Image Records
  const areaImageRecordsQuery = useQuery(QueryAreaImageRecords, {
    skip: !id || !startDate || !endDate,
    variables: {
      facilityIds: [id],
      startDate,
      endDate
    },
    fetchPolicy: 'no-cache'
  })

  // Effects ///////////////////////////////////////////////////////////////////

  useIter(
    UpdateDates,

    { setStartDate,
      setEndDate,
      setDateUUID },

    []
  )

  useIter(
    UpdateSpaces,

    { query: spacesQuery,

      setSpaceIDs,
      setSpaces,
      setSpacesUUID },

    [ spacesQuery.loading,
      spacesQuery.error,
      spacesQuery.data ]
  )

  useIter(
    UpdateSensorIDs,

    { endDate,

      sensorRecordsQuery,
      co2SensorRecordsQuery,

      setSensorIDs,
      setSensorsUUID },

    [ sensorRecordsQuery.loading,
      sensorRecordsQuery.error,
      sensorRecordsQuery.data,

      co2SensorRecordsQuery.loading,
      co2SensorRecordsQuery.error,
      co2SensorRecordsQuery.data ]
  )

  useIter(
    UpdateSensors,

    { endDate,

      sensorRecordsQuery,
      co2SensorRecordsQuery,
      sensorsQuery,

      setSensors,
      setSensorsEndDate,
      setSensorsUUID },

    [ sensorRecordsQuery.loading,
      sensorRecordsQuery.error,
      sensorRecordsQuery.data,

      co2SensorRecordsQuery.loading,
      co2SensorRecordsQuery.error,
      co2SensorRecordsQuery.data,

      sensorsQuery.loading,
      sensorsQuery.error,
      sensorsQuery.data ]
  )

  useIter(
    UpdateCharts,

    { endDate,

      spaces,
      sensors,
      sensorsEndDate,

      setCharts,
      setChartsEndDate,
      setChartsUUID },

    [ spacesUUID,
      sensorsUUID ]
  )

  useIter(
    UpdateAreacamIDs,

    { endDate,

      query: areaImageRecordsQuery,

      setAreacamIDs,
      setAreacamsUUID },

    [ areaImageRecordsQuery.loading,
      areaImageRecordsQuery.error,
      areaImageRecordsQuery.data ]
  )

  useIter(
    UpdateAreacams,

    { endDate,

      spaces,

      charts,
      chartsEndDate,

      areaImageRecordsQuery,
      areacamsQuery,

      setAreacams,
      setAreacamsEndDate,
      setAreacamsUUID,

      selectedAreacam,
      setSelectedAreacam },

    [ spacesUUID,
      chartsUUID,

      areaImageRecordsQuery.loading,
      areaImageRecordsQuery.error,
      areaImageRecordsQuery.data,

      areacamsQuery.loading,
      areacamsQuery.error,
      areacamsQuery.data ]
  )

  // Callbacks /////////////////////////////////////////////////////////////////

  const _setSelectedCharts =
    (newVal: IChart[]): void => {
      setSelectedCharts(newVal)
      setSelectedChartsUUID(v4())
    }

  const _setEndDate =
    (newVal: Date): void => {
      const newStartDate = subDays(newVal, 7)
      setEndDate(newVal)
      setStartDate(newStartDate)
    }

  // Render ////////////////////////////////////////////////////////////////////

  const isLoading =
    areaImageRecordsQuery.loading ||
    areacamsQuery.loading

  const hasData =
    !!areacams &&
    areacams.length > 0 &&
    isEqual(endDate, areacamsEndDate)

  let content = (
    <div className='Status'>
      <FontAwesomeIcon icon={faExclamationCircle} />
      <div className='Status'>No Data</div>
    </div>
  )

  if (isLoading) {
    content = (
      <div className='Status'>
        <FontAwesomeIcon icon={faSpinnerThird} spin />
        <div className='Status'>Loading</div>
      </div>
    )
  }

  if (hasData) {
    content = (
      <>
        {areacams.map(areacam => (
          <StaticCameraItem
            key={areacam.id}

            resting
            animated

            areacam={areacam}
            setSelectedAreacam={setSelectedAreacam}
          />
        ))}
      </>
    )
  }

  let overlay
  if (selectedAreacam) {
    overlay = (
      <StaticCameraView
        animated

        uuid={areacamsUUID}
        endDate={endDate}
        areacam={selectedAreacam}
        selectedCharts={selectedCharts}
        selectedChartsUUID={selectedChartsUUID}

        setEndDate={_setEndDate}
        setSelectedAreacam={setSelectedAreacam}
        setSelectedCharts={_setSelectedCharts}
      />
    )
  }

  return (
    <main className='StaticCameraList'>
      <FacilityHeader id={id} />
      {content}
      {overlay}
    </main>
  )
}

export { Routes }
export default StaticCameraList
